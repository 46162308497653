<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/admin/course/courseList">Course</a></li>
            <li class="breadcrumb-item"><a routerLink="/admin/course/activity/{{assesmentId}}">Assesment</a></li>
            <li class="breadcrumb-item">Questions</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card">

      <p-dialog header="Add Assesment Questions" [(visible)]="displayModel" [modal]="true" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false" (onHide)="closeModel()">

        <div class="card">

          <form [formGroup]="questionForm" (ngSubmit)="onSearch()">

            <div class="card-body pb-0">
              <span class="col-md-4 col-sm-12 float-left pl-0">
                <select class="form-control select2  ml-1" formControlName="questionType"
                  (change)="onQuestionTypeFilter()" style="width:100%;">
                  <option selected="selected" [value]="0">Select Type</option>
                  <option *ngFor="let items of questionTypeList" [value]="items.id">{{items.title}}</option>
                </select>
              </span>
              <span class="col-md-4 col-sm-12 float-left pl-0">
                <select class="form-control select2  ml-1" formControlName="questionCategory"
                  (change)="onQuestionCategoryFilter()" style="width:100%;">
                  <option selected="selected" [value]="0">Select Category</option>
                  <option *ngFor="let items of questionCategoryList" [value]="items.id">{{items.title}}</option>
                </select>
              </span>
              <span class="col-md-4 col-sm-12 float-left pl-0">
                <select class="form-control select2  ml-1" formControlName="difficultyLevel"
                  (change)="onDifficultyLevelFilter()" style="width:100%;">
                  <option selected="selected" [value]="0">Select Difficulty Level</option>
                  <option *ngFor="let items of difficultyLevelList" [value]="items.id">{{items.level}}</option>
                </select>
                <!--</span>-->
              </span>
            </div>

          </form>

          <div class="card-body table-responsive table_width_a">
            <ngx-datatable #qTable class="material stripe" [rows]="questionPool" 
              [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [externalPaging]="true" [count]="qPage.total"
              [offset]="qPage.current_page" [limit]="qPage.per_page" (page)="qPageNav($event)">

              <ngx-datatable-column [sortable]="false">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                  <input type="checkbox" [(ngModel)]="selected.indexOf(row.id) == -1 ? checked : !checked" (change)="onSelectCheckbox(row.id)" />
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="300" name="Title" prop="title">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div title="{{row.title}}" class="widthnew"><a (click)="viewQuestion(row.id)"
                      href="Javascript:void(0);" style="color: black; text-decoration: none;">{{row.title}}</a></div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Question Type" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.question_type_name}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Difficulty Level" prop="difficulty">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.difficulty_level}}
                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>

          </div>

          <div class="d-flex justify-content-between">
            <div>
              <p>{{selected.length}} Questions selected</p>
            </div>
            <div class="p-2">
              <button type="button" title="{{ selected.length == 0 ? 'Select any questions first' : 'Click to add'}}" [attr.disabled]="selected.length == 0 ? true : null" class="btn btn-secondary btn-dark float-right add_country ml-1"
                (click)="saveQuestions()">Add to Assessment</button>
              <button type="button" class="btn btn-secondary btn-dark float-right add_country"
                (click)="closeModel()">Cancel</button>
            </div>
          </div>
        </div>
      </p-dialog>


      <div class="card-header">
        <h1>Assessment Questions</h1>
        <!-- <h4>{{courceName}}</h4>
          <h5>Minimum No.of Questions : {{minimum_qstns}}</h5>
          <span *ngIf="msg != ''" title="Not enough question are added." class="badge badge-danger">Not enough questions added</span> -->
      </div>

      <div class="card-body pb-0">

        <a style="margin-right:10px" (click)="loadAllQuestions({ offset: 1 })"
          class="btn btn-secondary btn-dark float-right add_question">
          <i class="fas fa-plus mr-2"></i>Add Question
        </a>
        <a style="margin-right:10px" (click)="showModalDialog()"
          class="btn btn-secondary btn-dark float-right add_question">
          <i class="fas fa-plus mr-2"></i>Add Random Question
        </a>

      </div>

      <div class="card-body table-responsive">

        <ngx-datatable #myTable class="material striped" [rows]="questionList" [columnMode]="'force'"
          [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [externalPaging]="true" [count]="page.total"
          [offset]="page.current_page" [limit]="page.per_page" (page)="setPagetest($event)">
          <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
              <input type="checkbox" (change)="onDeleteCheckbox(row.id)" />
            </ng-template>
          </ngx-datatable-column>
          <!-- <ngx-datatable-column name="Sl No" [sortable]="false">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column> -->
          <ngx-datatable-column [width]="300" name="Title" prop="row.question_title">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a (click)="viewQuestion(row.question_id)" href="Javascript:void(0);"
                style="color: black; text-decoration: none;">{{row.question_title}}</a>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [width]="300" name="Question Type" prop="type">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.type}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Action" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a href="Javascript:void(0);" (click)="deleteCourse(row.id)" matTooltip="Delete" class="close_button"><i
                  class="far fa-times-circle"></i></a>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>

      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div>
            <p>{{deleteList.length}} Questions selected</p>
          </div>
          <div class="p-2">
            <button (click)="bulkDelete()" [disabled]="deleteList.length<=0" type="button"
              class="btn btn-secondary btn-dark float-right add_country">Delete</button>
          </div>
        </div>
      </div>

    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
</div>

<p-dialog header="Add Random Question" [(visible)]="displayModal_" [modal]="true" [style]="{width: '50vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary p-3 pb-5 add_contry_">
          <form [formGroup]="randomQuestionForm" (ngSubmit)="getQsPoolAndSaveRandomQs()">
            <div class="col-md-6 float-left">
              <div class="form-group">
                <label>Number Of Questions</label>
                <input type="number" min="1" formControlName="no_of_questions" class="form-control selact_">
                <div *ngIf="submitted_ && randomQuestionForm.get('no_of_questions').invalid">
                  <small class="text-danger">
                    Enter valid number of questions</small>
                </div>
              </div>
              <div class="form-group">
                <label>Question Type</label>
                <select formControlName="question_type" class="form-control select2 selact_" style="width: 100%;">
                  <option value="">--Select Question Type --</option>
                  <option value="1">MCQ</option>
                  <option value="2">Drag and drop</option>
                  <option value="3">Match the following</option>
                  <option value="4">Fill in the blanks</option>
                </select>
                <div *ngIf="submitted_ && randomQuestionForm.get('question_type').invalid">
                  <small *ngIf="randomQuestionForm.get('question_type').errors?.required" class="text-danger">
                    Question Type is required</small>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="p-3">
              <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
  <!-- <div>
  <label>number of questions</label>
  <input type="number" [(ngModel)]="numberOfQs">
</div>
<div class="m-3">
  <a (click)="getQsPoolAndSaveRandomQs('1')"  class="btn btn-app"> <i
      class="fas fa-question-circle"></i> MCQ</a>
  <a (click)="getQsPoolAndSaveRandomQs('4')" class="btn btn-app"> <i
      class="fas fa-file-signature"></i> Fill in the blanks</a>
  <a (click)="getQsPoolAndSaveRandomQs('2')" class="btn btn-app"> <i
      class="fas fa-file-import"></i> Drag and drop</a>
  <a (click)="getQsPoolAndSaveRandomQs('3')" class="btn btn-app"> <i
      class="fas fa-copy"></i> Match the following</a>
</div> -->
</p-dialog>
