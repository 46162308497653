<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/team']">Teams</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add Team</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit Team</li>

          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add Team</h1>
        <h1 *ngIf="isEdit">Edit Team</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="teamForm" (ngSubmit)="onSubmit()">
                <div class="col-md-6 float-left">
                  <div class="form-group">
                    <label>Team *</label>
                    <input type="text" formControlName="teamName" class="form-control selact_" placeholder="Team"
                      [ngClass]="{ 'is-invalid': submitted && f.teamName.errors }">
                    <div *ngIf="submitted && f.teamName.errors" class="invalid-feedback">
                      <div *ngIf="f.teamName.errors.required">
                        Team is
                        required
                      </div>
                      <div *ngIf="f.teamName.errors.validspace">
                        Invalid Team
                      </div>
                      <div *ngIf="!f.teamName.errors.required && f.teamName.errors.maxlength">
                        The maximum 60 characters required for Team. </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Position  *</label>
                    <input type="text" formControlName="position" class="form-control selact_" placeholder="Position"
                      [ngClass]="{ 'is-invalid': submitted && f.position.errors }">
                    <div *ngIf="submitted && f.position.errors" class="invalid-feedback">
                      <div *ngIf="f.position.errors.required">
                        Position is
                        required
                      </div>
                      <div *ngIf="f.position.errors.validspace">
                        Invalid Position
                      </div>
                      <div *ngIf="!f.position.errors.required && f.position.errors.maxlength">
                        The maximum 60 characters required for Position. </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Description *</label>
                    <textarea class="form-control selact_" formControlName="description" placeholder="Enter Description"
                      [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                      <div *ngIf="f.description.errors.required">
                        Description is
                        required
                      </div>
                      <div *ngIf="f.description.errors.validspace">
                        Invalid Description
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Sort Order *</label>

                    <input type="text" formControlName="sort_order" class="form-control selact_"
                      placeholder="Sort Order" [ngClass]="{ 'is-invalid': submitted && f.sort_order.errors }"
                      NumberOnly />
                    <div *ngIf="submitted && f.sort_order.errors" class="invalid-feedback">
                      <div *ngIf="f.sort_order.errors.required">
                        Sort Order is
                        required
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Status *</label>
                    <select formControlName="status" class="form-control select2 selact_" style="width: 100%;"
                      [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                      <option value="1">Active</option>
                      <option value="2">Disabled</option>
                    </select>
                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                      <div *ngIf="f.status.errors.required">
                        Status is
                        required
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="clearfix"></div> -->
                <div class="col-md-4 float-left">
                  <div class="form-group wid_75">
                    <label>Team Image *</label>
                    <div class="uploadedImg team_image_a">
                      <input type="file" class="mediaFile" formControlName="fileData" accept="image/*"
                        id="exampleInputFile" (change)="onFileChanged($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }">
                      <span class="up_img">Upload image</span>

                      <img [src]="imageSrc" *ngIf="imageSrc">
                    </div>
                    <div *ngIf="submitted && f.fileData.errors" class="text-danger">
                      <small *ngIf="f.fileData.errors.required">
                        Team Image is
                        required
                      </small>
                    </div>
                    <div class="note_a p-3"><label>* Preffered image resolution 300x300px</label></div>
                  </div>

                </div>
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>
