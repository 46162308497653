import { Component, OnInit } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
declare var $: any;
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';
import { maxLength } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-add-eligibility-test',
  templateUrl: './add-eligibility-test.component.html',
  styleUrls: ['./add-eligibility-test.component.css']
})
export class AddEligibilityTestComponent implements OnInit {

  session_id: string;
  CourceList: any = [];
  stateList: any = [];
  isEdit: boolean = false;
  districtId: any;
  constructor(public api: ApiService, private router: Router, private formBuilder: FormBuilder, private popup: PopupService, private route: ActivatedRoute,private spinner: NgxSpinnerService) {

   }
  eligibilityForm: FormGroup;
  submitted=false;

  ngOnInit(): void {

    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.init();
    this.eligibilityForm = this.formBuilder.group({
      course_id: ['', Validators.required],
      title: ['', Validators.required],
      welcome_text: ['', Validators.required],
      no_of_questions: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      grade_per_question: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      pass_percentage: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]?$|^100$/)]],
      success_feedback: ['', Validators.required],
      failure_feedback: ['', Validators.required],
      re_attempt: ['0',Validators.required],
      time_limit: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      status_id: ['1', Validators.required],
    });

    
    // this.districtForm.reset();

  }
  get f() { return this.eligibilityForm.controls; }

  init() {
    this.spinner.show();

    this.CourceList = [];
    var course_fetch_url = "getCourseListWithoutEligibilityTest";
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.courses[0]) {
        this.CourceList = data.courses;
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  onSubmit() {
    debugger
    this.submitted=true;
    if (this.eligibilityForm.invalid) {
      return;
    }

    console.log(this.eligibilityForm.value);

      this.spinner.show();

      var body = {"course_id": this.f.course_id.value, "title": this.f.title.value, "welcome_text": this.f.welcome_text.value,
       'no_of_questions': this.f.no_of_questions.value, 'grade_per_question': this.f.grade_per_question.value,
       "pass_percentage": this.f.pass_percentage.value, "success_feedback": this.f.success_feedback.value, "failure_feedback": this.f.failure_feedback.value,
       "re_attempt": this.f.re_attempt.value, "time_limit": this.f.time_limit.value, "status_id": this.f.status_id.value};

       var url = "save_eligibility_test";
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "TRUE") {
          this.spinner.hide();


          this.eligibilityForm.reset();
          this.stateList = [];
          this.submitted=false;
          this.popup.sucessMessage = data.message;
          this.popup.sucesspopup();
          this.router.navigate(['/admin/eligibility-test/list']);

        }
        else {
          this.spinner.hide();
          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }


}
