import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PopupService } from 'src/app/services/popup.service';
import { UploadService } from 'src/app/services/upload.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { MatDialog } from '@angular/material/dialog';
import { WebComponent } from '../../web.component';
import { Validatespace } from "src/app/validation/spacevalidation";
import { phoneNumberValidator } from "../../../validation/phone-validator";

const PROFILE_PIC_PATH: string = 'aljamia-media-files/profileImage';
const IDPROOF_PATH: string = 'aljamia-media-files/idproof';

@Component({
  selector: 'app-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.css']
})
export class ProfileUpdateComponent implements OnInit {
  profileformGroup: FormGroup;
  accountDeleteForm: FormGroup
  s3FilePath: string;
  registrationDropdowns: any;
  qualifications: any
  streams: any;
  countries: any;
  states: any;
  districts: any;
  selectedFile: any;
  imageSrc: any;
  idImageSrc: any;
  session_id: any;
  userId: any;
  todayDate: any;
  issubmitted: boolean = false;
  idProofPath: any = "";
  imagePath: any = "";
  idProofFileName: any;
  ImageFileName: any;
  filterstreams: any;
  filterstates: any;
  filterdistricts: any;
  userName: any;
  dltSubmitted: boolean = false;
  isOtherQualification: boolean = false;

  @ViewChild("confirmPopup") confirm: TemplateRef<any>;

  constructor(private fb: FormBuilder,
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private PopupService: PopupService,
    private uploadService: UploadService,
    private ToasterService: ToasterService,
    private rote: ActivatedRoute,
    private constantService: ConstantsService,
    public dialog: MatDialog,
    private WebComponent: WebComponent) {
    this.profileformGroup = fb.group({
      userName: ['', [Validators.required, Validators.maxLength(60), Validators.minLength(2), Validators.pattern('[a-zA-Z ]*'), Validatespace]],
      // phoneNumber: ['', [Validators.pattern('[0-9]*'), Validators.required, Validators.minLength(8), Validators.maxLength(13)]],
      phoneNumber: ['',
        [
          Validators.required,
          Validatespace,
          phoneNumberValidator,
          Validators.minLength(8),
          Validators.maxLength(12),
        ]],
      emailAddress: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(80), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
      dateOfBirth: ['', [Validators.required, Validators.maxLength(10)]],
      photo: [''],
      identityProof: [''],
      qualification: ['', [Validators.required]],
      // stream: ['0', [Validators.required]],
      // otherQualification: ['', [ Validators.maxLength(60), Validatespace]],
      // score: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]?$|^100$/)]],
      address: ['', [Validators.required, Validators.maxLength(80)]],
      country: ['0', [Validators.required]],
      // state: [''],
      // district: [''],
      whatsAppNumber: [''],
      nationality: ['0']
    });
    this.accountDeleteForm = fb.group({
      passWord: ['', [Validators.required,
        Validatespace, Validators.minLength(6), Validators.maxLength(20)]]
    })
  }

  getRegistrationParam() {
    return {
      name: null,
      // country_code: null,
      phone_number: null,
      email: null,
      date_of_birth: null,
      photo: null, identity_proof: null,
      qualification: null,
      // qualification_id: null,
      // stream_id: null,
      // score: null,
      address: null,
      country_id: null,
      // state_id: null,
      // district_id: null,
      // other_qualification_stream: null,
      whatsapp_number: null,
      nationality: null
    }
  }

  async ngOnInit() {
    this.spinner.show();
    debugger
    this.s3FilePath = this.constantService.s3FilePath;
    this.userId = localStorage["user_Id"];
    if (!this.userId) {
      this.router.navigate(['/login'])
    }
    this.getregistrationDropdowns();
    this.editUserProfile();
    this.todayDate = await this.convert();
  }

  getregistrationDropdowns() {
    var list_fetch_url = "student/fillregistrationDropdowns";
    this.api.detailsGet(list_fetch_url).subscribe((data: any) => {
      if (data.status == "true" && data) {
        debugger
        this.registrationDropdowns = data.data;
        this.qualifications = this.registrationDropdowns.qualifications;
        var others = {id: "0", title: "Others"};
        this.qualifications.push(others);
        this.streams = this.registrationDropdowns.streams;
        this.filterstreams = this.registrationDropdowns.streams;
        this.countries = this.registrationDropdowns.countries;
        this.states = this.registrationDropdowns.states;
        this.filterstates = this.registrationDropdowns.states;
        this.districts = this.registrationDropdowns.districts;
        this.filterdistricts = this.registrationDropdowns.districts;
        // this.profileformGroup.patchValue({
        //   qualification: 0,
        //   stream: 0,
        //   country: 0,
        //   state: 0,
        //   district: 0
        // })
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });
  }

  editUserProfile() {
    debugger
    // this.rote.params.subscribe(params => {
    //   this.userId = params.user_id;
    // })
    // if (this.userId) {
    debugger
    this.spinner.show();

    this.session_id = localStorage.getItem('access_token')
    var fetch_userDetails_url = "edit_student_profile";
    this.api.detailsGetFetch(fetch_userDetails_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.student_details) {
        debugger
        this.profileformGroup.get('userName').setValue(data.student_details.name);
        this.profileformGroup.get('phoneNumber').setValue(data.student_details.phone_number);
        this.profileformGroup.get('whatsAppNumber').setValue(data.student_details.whatsapp_number);
        this.profileformGroup.get('emailAddress').setValue(data.student_details.email);
        this.profileformGroup.get('dateOfBirth').setValue(data.student_details.date_of_birth);
        // this.profileformGroup.get('qualification').setValue(data.student_details.qualification_id);
      //   if(data.student_details.qualification_id == null){
      //     this.isOtherQualification = true;
      //   this.profileformGroup.get('qualification').setValue("0");
      //   this.profileformGroup.get('otherQualification').setValue(data.student_details.other_qualification_stream);
      // }
      // else{
      //   this.isOtherQualification = false;
      //   this.profileformGroup.get('qualification').setValue(data.student_details.qualification_id);
      //   this.profileformGroup.get('stream').setValue(data.student_details.stream_id);
      // }
      this.profileformGroup.get('qualification').setValue(data.student_details.qualification);
        
        // this.profileformGroup.get('score').setValue(data.student_details.score);
        this.profileformGroup.get('address').setValue(data.student_details.address);
        this.profileformGroup.get('country').setValue(data.student_details.country_id);
        this.profileformGroup.get('nationality').setValue(data.student_details.nationality);
        // this.profileformGroup.get('state').setValue(data.student_details.state_id);
        // this.profileformGroup.get('district').setValue(data.student_details.district_id);
        if(data.student_details.profile_pic != null){
        this.imageSrc = this.s3FilePath + data.student_details.profile_pic;
      }
        this.idProofFileName = data.student_details.identity_proof;
        this.ImageFileName = data.student_details.profile_pic;
        if(data.student_details.identity_proof != null){
        this.idImageSrc = this.s3FilePath + data.student_details.identity_proof;
      }
        this.userName = data.student_details.name;

        this.spinner.hide();
      }
    })
    // }
  }

  convert() {
    var date = new Date(),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear() - 10, mnth, day].join("-");
  }

  onIdProofFileChange(event) {
    debugger
    this.idProofFileUpload(event, IDPROOF_PATH)
  }

  idProofFileUpload(event, folderPath) {
    debugger
    var reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.selectedFile = event.target.files[0];
      // reader.onload = () => {
      //   this.idProofSrc = reader.result as string;
      // }
      if (event.target.files[0].size < 3000000) {
      this.idProofPath = this.selectedFile.name;
      let imagePath_ = this.selectedFile.type;
      var ext = imagePath_.split('/');
      if (ext[1] == "jpeg" || ext[1] == "png"|| ext[1] == "jpg") {
        reader.onload = () => {
          debugger
          this.idImageSrc = reader.result as string;
        }
        reader.readAsDataURL(this.selectedFile);
        if (folderPath === IDPROOF_PATH) {
          if (this.selectedFile != null) {
            this.idProofFileName = this.uploadService.fileUpload(this.selectedFile, folderPath).then(res => {
              if (res) {
                this.idProofFileName = res;
              }
            });
          }
        }
      } else {
        this.profileformGroup.get('identityProof').setValue('');
        // this.profileformGroup.get('identityProof').setErrors({ required: true });
        this.idImageSrc = "";
        this.ToasterService.openSnackBar('Unsupported file format', '', 'warning');
      }
      }
      else {
        this.profileformGroup.get('identityProof').setValue('');
        // this.profileformGroup.get('identityProof').setErrors({ required: true });
        this.idImageSrc = "";
        this.ToasterService.openSnackBar('Size limit exceeded', '', 'warning');
      }
    }
  }

  onImageFileChange(event) {
    this.imageFileUpload(event, PROFILE_PIC_PATH);
  }

  imageFileUpload(event, folderPath) {
    debugger
    var reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      debugger
      if (event.target.files[0].size < 3000000) {
      debugger
      this.selectedFile = event.target.files[0];
      this.imagePath = this.selectedFile.name;
      let imagePath_ = this.selectedFile.type;
      var ext = imagePath_.split('/');
      if (ext[1] == "jpeg" || ext[1] == "png"|| ext[1] == "jpg") {
        reader.onload = () => {
          debugger
          this.imageSrc = reader.result as string;
        }
        reader.readAsDataURL(this.selectedFile);
        if (folderPath === PROFILE_PIC_PATH) {
          if (this.selectedFile != null) {

            this.ImageFileName = this.uploadService.fileUpload(this.selectedFile, folderPath).then(res => {
              if (res) {
                this.ImageFileName = res;
              }
            });
          }
        }
      } else {
        this.profileformGroup.get('photo').setValue('');
        // this.profileformGroup.get('photo').setErrors({ required: true });
        this.imageSrc = "";
        this.ToasterService.openSnackBar('Unsupported file format', '', 'warning');
      }
      }
      else {
        this.profileformGroup.get('photo').setValue('');
        // this.profileformGroup.get('photo').setErrors({ required: true });
        this.imageSrc = "";
        this.ToasterService.openSnackBar('Size limit exceeded', '', 'warning');
      }
    }
  }

  qualificationsChange() {
    debugger
    if(this.profileformGroup.value.qualification == "0"){
      this.isOtherQualification = true;
      this.profileformGroup.get('otherQualification').setValidators([Validators.required,Validators.maxLength(60), Validatespace]);
      this.profileformGroup.get('stream').setValidators(null);
      
    }else{
      this.isOtherQualification = false;
      this.profileformGroup.get('otherQualification').setErrors(null);
      this.profileformGroup.get('stream').setErrors({ required: true });
    this.filterstreams = this.streams.filter(option => option.qualification_id == this.profileformGroup.value.qualification);
    this.profileformGroup.patchValue({
      stream: 0,
    })
  }
  }


  streamChange() {
    debugger

  }
  countryChange() {
    debugger
    this.filterstates = this.states.filter(option => option.country_id == this.profileformGroup.value.country);
    this.profileformGroup.patchValue({
      state: 0,
      district: 0
    })
    this.filterdistricts = null;

  }
  stateChange() {
    debugger
    this.filterdistricts = this.districts.filter(option => option.state_id == this.profileformGroup.value.state);
    this.profileformGroup.patchValue({
      district: 0
    })

  }
  districtChange() {

  }

  onSubmit() {
    debugger
    this.issubmitted = true;
    if (new Date(this.profileformGroup.value.dateOfBirth) > new Date((this.todayDate))) {
      this.profileformGroup
        .get("dateOfBirth")
        .setErrors({ dateError: true });

    }
    // if(this.isOtherQualification==false && this.profileformGroup.value.stream == "0")
    // {
    //   return;
    // }
    if (this.profileformGroup.status == "VALID") {
      debugger
      let param = this.getRegistrationParam();
      param.name = this.profileformGroup.value.userName;
      // param.country_code = 91;
      param.phone_number = this.profileformGroup.value.phoneNumber;
      param.whatsapp_number = this.profileformGroup.value.whatsAppNumber;
      param.email = this.profileformGroup.value.emailAddress;
      param.date_of_birth = this.profileformGroup.value.dateOfBirth;
      param.photo = this.ImageFileName;
      // param.photo = this.profileformGroup.value.photo;
      // param.photo = "test.jpg";
      param.identity_proof = this.idProofFileName;
      // param.identity_proof = this.profileformGroup.value.identityProof;
      // param.identity_proof = "test1.jpg";
      // if(this.profileformGroup.value.qualification == "0"){
      //   param.qualification_id = null;
      //   param.other_qualification_stream = this.profileformGroup.value.otherQualification;
      //   param.stream_id = null;
      // }else{
      // param.qualification_id = this.profileformGroup.value.qualification;
      // param.other_qualification_stream = null;
      // param.stream_id = this.profileformGroup.value.stream;
      // }
      // param.score = this.profileformGroup.value.score;
      param.qualification = this.profileformGroup.value.qualification;
      param.address = this.profileformGroup.value.address;
      param.country_id = this.profileformGroup.value.country;
      param.nationality = this.profileformGroup.value.nationality;
      // param.state_id = this.profileformGroup.value.state.id;
      // param.district_id = this.profileformGroup.value.district.id;
      this.saveData(param);

    }
  }

  saveData(param) {
    this.spinner.show();
    debugger
    let fetch_url = 'update_student_profile'
    this.api.detailsUploadFetch(param, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "TRUE" && data) {
        debugger
        localStorage.setItem('user_name', param.name);
        localStorage.setItem('profilepic', this.ImageFileName);
        this.WebComponent.islogin = true;
        this.LocaldataService.updateUser();
        this.PopupService.sucessMessage = data.message
        this.PopupService.sucesspopup()
        // this.router.navigate([''])
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        // this.PopupService.failureMessage = data.message[0]
        // this.PopupService.failurepopup()
        this.ToasterService.openSnackBar(data.message[0], '', 'warning');
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        //localStorage.clear()
        this.router.navigate([''])
      }
    });
  }


  confirmDeletion() {
    this.dialog.open(this.confirm);
  }
  onDelete() {
    this.dltSubmitted = true;
    if (this.accountDeleteForm.invalid) {
      return
    }

    this.spinner.show();
    let param = {
      password: this.accountDeleteForm.value.passWord
    }
    let fetch_url = 'delete_account'
    this.api.detailsUploadFetch(param, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data) {
        this.dialog.closeAll();
        this.PopupService.sucessMessage = data.message
        this.PopupService.sucesspopup()
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate([''])
      }
      else {
        this.accountDeleteForm.get("passWord")
          .setErrors({ incorrect: true });
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        //localStorage.clear()
        this.router.navigate([''])
      }
    });

  }
  removeSelectedImage(imageField: string){
    debugger
    if(imageField == 'photo'){
    this.profileformGroup.get('photo').setValue('');
    this.imageSrc = "";
    this.ImageFileName = "";
  }else if(imageField == 'identityProof'){
    this.profileformGroup.get('identityProof').setValue('');
    this.idImageSrc = "";
    this.idProofFileName = "";
  }
  }
}
