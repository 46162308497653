import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { MustMatch } from '../../helpers/must-match.validator';
import { NgxSpinnerService } from "ngx-spinner";
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  isError: boolean = false;
  isInvalid: boolean = false;
  errMsg: string;
  oldpassword: any;
  newpassword: any;
  confirmpassword: any;
uid:any;
session_id:string;
message:any;
isUpdated:boolean=false;
  constructor(private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthService,private spinner: NgxSpinnerService,private popup: PopupService) { }
  passwordForm: FormGroup;
  isSubmitted = false;

  ngOnInit(): void {
    this.uid= localStorage.getItem('user_id');
    this.session_id= localStorage.getItem('JWT_TOKEN')

    this.passwordForm = this.formBuilder.group({
      currentPwd: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    },
      {
        validator: MustMatch('newpassword', 'confirmpassword')
      });
  }

  get g() { return this.passwordForm.controls; }
  onSubmit() {
    this.isSubmitted = true;
    this.isUpdated=false
    console.log(this.g)

    this.isInvalid = false;
    if (this.passwordForm.invalid) {
      this.isError = true;
      return;
    }
    this.oldpassword = this.passwordForm.value.currentPwd;
    this.newpassword = this.passwordForm.value.newpassword;
    this.confirmpassword = this.passwordForm.value.confirmPassword;
    console.log(this.g)
    this.isError = false;
    this.spinner.show();
     var pwd_body={"userID": +this.uid,"old_password":this.oldpassword,"new_password":this.newpassword}
    this.apiService.updatePassword(pwd_body,this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        this.spinner.hide();
        
        this.message=data.message;
        this.isUpdated=true;
        this.passwordForm.reset()
        this.isSubmitted = false;
        localStorage.clear();
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        this.router.navigate(['admin/login'])

      }
      else{
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup()
        this.isUpdated=false;
      }
    });
  }

}
