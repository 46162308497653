<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Subscriptions</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="sub_banner  text-center">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>Subscriptions</h1>
      </div>
    </div>
  </div>
</section>
<section class="sub_page_content team_content sign_up_container">
  <div class="container">
    <div class="row">
      <div class="col-md-12 card border-0">
        <div class="card-body">

          <!--<h2 class="card-title text-center">Payment Report</h2>-->
          <div class="table-responsive-md">
            <table class="table table-bordered">

              <thead>
                <tr>

                  <th>Transaction Date</th>
                  <th>Course Name</th>
                  <!-- <th>Payment Mode</th> -->
                  <th>Tracking ID</th>
                  <!-- <th>Card Name</th> -->
                  <th>Amount</th>
                  <th>Discount Value</th>
                  <th>Order Status</th>
                  <!-- <th>Action</th> -->

                </tr>
              </thead>

              <tbody>
                <ng-container *ngIf="paymentList && paymentList.length > 0">
                  <tr *ngFor="let item of paymentList">
                    <!-- 1 -->
                    <td> {{item.payment_date}} </td>
                    <!-- 2 -->
                    <td> {{item.course_name}} </td>
                    <!-- 3 -->
                    <!-- <td> N/A </td> -->
                    <!-- 4 -->
                    <td> {{item.transaction_id}} </td>
                    <!-- 5 -->
                    <!-- <td> N/A </td> -->
                    <!-- 6 -->
                    <td> {{item.course_currency_price.amount}} </td>
                    <!-- 7 -->
                    <td> {{item.discount ? item.discount : 00}} </td>
                    <!-- 8 -->
                    <td> {{item.payment_status}}</td>
                    <!-- 9 -->
                    <!-- <td> <button type="button" (click)="onInvoiceDetails(item.payment_id)">invoice</button> </td> -->

                  </tr>
                </ng-container>

                <tr *ngIf="paymentList && paymentList.length == 0">
                  <td colspan="7"> No transactions history </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
