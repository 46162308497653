<section class="bread_sec shadow-sm">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <ol class="breadcrumb mb-0 pl-0">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">My Referral Points</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="sub_banner team_banner text-left">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>My Referral Points</h1>
        </div>
      </div>
    </div>
  </section>
  <section class="courses_pages">
    <div class="container">
      <div class="row">
<div class="col-md-12">
<h3>Now earn referral points by sharing referral codes to friends. Points can be used for rewards or offers in next purchase. View your earned points here.</h3>
<div class="input-group col-md-6 col-sm-12 mt-4 mx-auto raferral_points">
  <input type="text" name="cpybox" class="form-control"
    value="{{referralDetail.referral_code}}" disabled>
  <div class="input-group-append">
    <button class="waiting_stud_ btn btn-info" value="Copy Url" type="button"
     (click)= "onCopyUrl(referralDetail.referral_code, 1)"  id=1>Copy Referral Code</button>
  </div>
</div>
<hr class="py-3 mt-5">
</div>

        
            <div class="col-lg-6 col-md-6 mb-4 mx-auto points">
              <div class="col-lg-6 col-md-6 float-left" >
              <div  class="text-center content_">
                <h3>Referral Point</h3>
                <h4 class="mt-3">{{referralDetail.total_active_points}}</h4></div>          
            </div>
            <div class="col-lg-6 col-md-6 float-left border-left" >
              <div  class="text-center content_">
                <h3>Used Point</h3>
                <h4 class="mt-3">{{referralDetail.used_points}}</h4></div>          
            </div>
         </div>
         <div class="clearfix"></div>
         <div class="col-lg-12 col-md-12 mt-2 mx-auto" *ngIf="referralDetail && referralDetail.users.length != 0">
           <h4>Referral History</h4>
          <div class="table-responsive mt-2">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">User</th>
                <th scope="col">Date</th>
                <th scope="col">Points</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of referralDetail.users; let i = index">
                <th scope="row">{{i+1}}</th>
                <td>{{item.name}}</td>
                <td>{{item.date}}</td>
                <td>{{item.points_earned}}</td>
              </tr>
            </tbody>
          </table>
          </div>
         </div>
      
     </div>
      </div>
    
  </section>