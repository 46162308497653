<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Profile</li>
          </ol>
      </div>
    </div>
  </div>
</section>
<section class="sub_page_content team_content sign_up_container">
  <div class="container">
    <div class="row">

      <div class="col-md-12 card  my-5 border-0 shadow-sm">
        <div class="card-body">
          <!-- <div class="alert alert-danger text-center" role="alert"> Passwords not match</div>-->
          <h2 class="card-title text-center">Update your Profile Details</h2>
          <form class="form-signup" name="signupform" [formGroup]="profileformGroup" (ngSubmit)="onSubmit()">
            <!--    <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Sign up with Google</button>
                  <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Sign up with Facebook</button>-->
            <hr class="my-3 mt-4 pb-1">
            <div class="col-md-8 float-left">
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Name (As in passport or Aadhaar card) *</label>
                <input type="text" id="name" class="form-control" formControlName="userName" placeholder="Name"
                  required>
                <div *ngIf="issubmitted && profileformGroup.controls.userName.errors" class="error_signup">
                  <div *ngIf="issubmitted && profileformGroup.controls.userName.errors.required"> Name is required.
                  </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.userName.errors.pattern"> Invalid Name. </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.userName.errors.validspace"> Invalid Name. </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.userName.errors.maxlength"> Invalid Name. </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.userName.errors.minlength"> Invalid Name. </div>
                </div>
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Email Address *</label>
                <input type="email" id="inputEmail" class="form-control" formControlName="emailAddress"
                  placeholder="Email Address">
                <div *ngIf="issubmitted && profileformGroup.controls.emailAddress.errors" class="error_signup">
                  <div *ngIf="issubmitted && profileformGroup.controls.emailAddress.errors.required"> Email address is
                    required. </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.emailAddress.errors.pattern"> Invalid Email
                    Address. </div>
                </div>
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Mobile Number (with country code)*</label>
                <input id="phone" name="phone" type="tel" class="form-control" formControlName="phoneNumber"
                  placeholder="eg:91XXXXXXXXXX" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>

                  <div *ngIf="issubmitted  && profileformGroup.get('phoneNumber').invalid ">
                    <small *ngIf="profileformGroup.get('phoneNumber').errors?.required" class="text-danger">Mobile
                      Number is required</small>
                    <small
                      *ngIf="!profileformGroup.get('phoneNumber').errors.invalidNumber && (profileformGroup.get('phoneNumber').minLength || !profileformGroup.get('phoneNumber').maxLength)"
                      class="text-danger">Mobile number should be between 8-12 numbers</small>

                    <small
                      *ngIf="!profileformGroup.get('phoneNumber').errors?.required && profileformGroup.get('phoneNumber').errors.invalidNumber"
                      class="text-danger">Invalid
                      Mobile Number</small>
                  </div>
                <!-- <div *ngIf="issubmitted && profileformGroup.controls.phoneNumber.errors" class="error_signup">
                  <div *ngIf="issubmitted && profileformGroup.controls.phoneNumber.errors.required"> Phone Number is
                    required. </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.phoneNumber.errors.minlength"> Invalid Phone
                    Number. Minimum 8 Digit required. </div>

                  <div *ngIf="issubmitted && profileformGroup.controls.phoneNumber.errors.maxlength"> Invalid Phone
                    Number. Maximum 13 Digit is Possible. </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.phoneNumber.errors.pattern"> Invalid Phone
                    Number. </div>
                </div> -->
              </div>
              <!-- <div class="clearfix"></div> -->
              

              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>WhatsApp Number (with country code)</label>
                <input id="whatsAppNumber" name="whatsAppNumber" type="tel" class="form-control" formControlName="whatsAppNumber"
                  placeholder="eg:91XXXXXXXXXX" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>

                <div *ngIf="issubmitted  && profileformGroup.get('whatsAppNumber').invalid ">
                  <!-- <small *ngIf="profileformGroup.get('whatsAppNumber').errors?.required" class="text-danger">WhatsApp
                    Number is required</small> -->
                  <small
                    *ngIf="!profileformGroup.get('whatsAppNumber').errors.invalidNumber && (profileformGroup.get('whatsAppNumber').minLength || !profileformGroup.get('whatsAppNumber').maxLength)"
                    class="text-danger">WhatsApp number should be between 8-12 numbers</small>

                  <small
                    *ngIf="!profileformGroup.get('whatsAppNumber').errors?.required && profileformGroup.get('whatsAppNumber').errors.invalidNumber"
                    class="text-danger">Invalid
                    WhatsApp Number</small>
                </div>
              </div>
              <div class="clearfix"></div>

              <div class="form-label-group mb-2 col-md-6 float-left">
                <label>Date of Birth *</label>
                <input type="date" [max]=todayDate id="birthDate" class="form-control" formControlName="dateOfBirth"
                  placeholder="Date of Birth">
                <div *ngIf="issubmitted && profileformGroup.controls.dateOfBirth.errors" class="error_signup">
                  <div *ngIf="issubmitted && profileformGroup.controls.dateOfBirth.errors.required"> Date of Birth is required. </div>
                  <div *ngIf="profileformGroup.get('dateOfBirth').errors?.dateError">
                    Date of Birth must be 10 year lesser than the current date
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>
              <hr class="mt-4 pb-1">
              <div class="clearfix"></div>
              <div class="form-label-group mb-2  col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Educational Qualification *</label>
                <!-- <select class="form-control" formControlName="qualification" (change)="qualificationsChange()">
                  <option value="">Educational Qualification</option>
                  <option  *ngFor="let option of qualifications" value="{{option.id}}">{{option.title}}</option>
                </select> -->
                <input type="text" id="qualification" class="form-control" formControlName="qualification" placeholder="Qualification"
                  required>
                <div *ngIf="issubmitted && profileformGroup.controls.qualification.errors" class="error_signup">
                  Qualification is required.
                </div>
                <!-- <div *ngIf="issubmitted && profileformGroup.controls.qualification.value==0" class="error_signup">
                  Select Qualification. </div> -->
              </div>

              <!-- <div class="form-label-group mb-2  col-md-6 float-left" *ngIf="!isOtherQualification">
                <label>Stream *</label>
                <select class="form-control" formControlName="stream" (change)="streamChange()">
                  <option value="0">Stream</option>
                  <option value="{{option.id}}" *ngFor="let option of filterstreams">{{option.title}}</option>
                </select>
                <div *ngIf="issubmitted && profileformGroup.controls.stream.errors" class="error_signup"> Stream is
                  required.
                </div>
                <div *ngIf="issubmitted && profileformGroup.controls.stream.value==0" class="error_signup"> Select
                  Stream. </div>
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left" *ngIf="isOtherQualification">

                <label>Enter Your Qualification and Stream *</label>
                <input type="text" id="name" class="form-control" formControlName="otherQualification" placeholder="Qualification Name"
                  required>
                <div *ngIf="issubmitted && profileformGroup.controls.otherQualification.errors" class="error_signup">
                  <div *ngIf="issubmitted && profileformGroup.controls.otherQualification.errors.required">Qualification and Stream is required.
                  </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.otherQualification.errors.pattern"> Invalid Qualification and Stream. </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.otherQualification.errors.validspace"> Invalid Qualification and Stream. </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.otherQualification.errors.maxlength"> Invalid Qualification and Stream. </div>
                </div>
              </div> -->
              <div class="clearfix"></div>
              <!-- <div class="form-label-group mb-2  col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Percentage Score *</label>
                <input type="number" id="score" min="0" class="form-control" formControlName="score" placeholder="Score"
                  required>
                <div *ngIf="issubmitted && profileformGroup.controls.score.errors" class="error_signup">
                  <div *ngIf="issubmitted && profileformGroup.controls.score.errors.required"> Percentage Score is required. </div>
                  <div *ngIf="issubmitted && profileformGroup.controls.score.errors.pattern"> Invalid Percentage Score. </div>
                </div>
              </div> -->

              <hr class="mt-4 pb-1">
              <div class="form-label-group mb-2  col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Postal Address *</label>
                <textarea id="address" name="address" cols="30" rows="3" class="form-control" placeholder="Postal Address"
                  formControlName="address"></textarea>
                <div *ngIf="issubmitted && profileformGroup.controls.address.errors" class="error_signup">Postal Address is
                  required.
                  <!-- <div *ngIf="issubmitted && profileformGroup.controls.address.errors.required"> Address is required. </div> -->
                </div>
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Nationality </label>
                <select class="form-control" formControlName="nationality">
                  <option value="0">--Select Nationality--</option>
                  <option value="{{option.id}}" *ngFor="let option of countries">{{option.country_name}}</option>
                </select>
                <!-- <div *ngIf="issubmitted && profileformGroup.controls.nationality.errors" class="error_signup"> Nationality is
                  required.
                </div>
                <div *ngIf="issubmitted && profileformGroup.controls.nationality.value==0" class="error_signup"> Select
                  Nationality. </div> -->
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Country of Residence *</label>
                <select class="form-control" formControlName="country" >
                  <option value="0">--Select Country--</option>
                  <option value="{{option.id}}" *ngFor="let option of countries">{{option.country_name}}</option>
                </select>
                <div *ngIf="issubmitted && profileformGroup.controls.country.errors" class="error_signup"> Country is
                  required.
                </div>
                <div *ngIf="issubmitted && profileformGroup.controls.country.value==0" class="error_signup"> Select
                  Country. </div>
              </div>
              <!-- <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>State </label>
                <select class="form-control" formControlName="state" (change)="stateChange()">
                  <option value=""> State/Emirate/Province </option>
                  <option value="{{option}}" *ngFor="let option of filterstates">{{option.state_name}}</option>
                </select>
              </div>
              <div class="clearfix"></div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>District </label>
                <select class="form-control" formControlName="district" (change)="districtChange()">
                  <option value="">District</option>
                  <option value="{{option}}" *ngFor="let option of filterdistricts">{{option.title}}</option>
                </select>
              </div> -->



            </div>

            <div class="col-md-4 float-left mb-2">


              <div class="col-md-12 float-left">
                <div class="form-group">
                  <label>Profile Image </label>
                  <label class="text-warning"> Accept only jpg, png, jpeg extensions (Maximum size: 3 MB)</label>
                  <div class="uploadedImg profile_pic profile-image-uplode text-center">
                    <input type="file" class="mediaFile" formControlName="photo" id="exampleInputFile"
                      (change)="onImageFileChange($event)" accept="image/*">
                    <span class="up_img">Upload image</span>

                    <img [src]="imageSrc" *ngIf="imageSrc">
                   
                    <!-- <div *ngIf="issubmitted && profileformGroup.controls.photo.errors" class="text-danger">
                      <small *ngIf="issubmitted && profileformGroup.controls.photo.errors">Profile Image is
                        required
                      </small>
                    </div> -->
                  </div>
                  <div *ngIf="imageSrc">
                    <label > <a class="under_line_blue_link" (click)="removeSelectedImage('photo')">Remove Selected Profile Image</a></label>
                    </div>
                </div>
              </div>


              <div class="col-md-12 mt-2 float-left">
                <div class="form-group">
                  <label>Identity Proof </label>
                  <label class="text-warning"> Accept only jpg, png, jpeg extensions (Maximum size: 3 MB)</label>
                  <div class="uploadedImg id_pic profile-id-uplode text-center">
                    <input type="file" class="mediaFile" formControlName="identityProof" id="exampleInputFile"
                      (change)="onIdProofFileChange($event)"
                      [ngClass]="{ 'is-invalid': issubmitted && profileformGroup.controls.identityProof.errors }">
                    <span class="up_img">Upload File</span>

                    <img [src]="idImageSrc" *ngIf="idImageSrc">
                  </div>
                  <div *ngIf="idImageSrc">
                    <label>
                    <a class="under_line_blue_link" (click)="removeSelectedImage('identityProof')">Remove Selected Identity Proof</a>
                  </label>
                  </div>
                  <!-- <div *ngIf="issubmitted && profileformGroup.controls.identityProof.errors" class="text-danger">
                    <small *ngIf="issubmitted && profileformGroup.controls.identityProof.errors">ID proof Image is
                      required
                    </small>
                  </div> -->
                </div>
              </div>

              <div class="clearfix"></div>
              <div class="col-md-12">
                <div class="note_a p-3">
                  <label>* Preffered resolution 50x50</label>
                </div>
              </div>

            </div>

            <div class="clearfix"></div>
            <div class="col-md-12 border-top pt-3 mt-3">
             <a href="Javascript:void(0);" (click)="confirmDeletion()" class="btn btn-danger btn-outline-secondary btn-sm float-left text-uppercase "><i class="far fa-trash-alt mr-1"></i> delete my account</a>
              <button class="btn btn-dark sign_up_submit btn-lg btn-primary float-right text-uppercase"
                type="submit">save</button>
            </div>
            <div class="clearfix"></div>
          </form>
          <div class="clearfix"></div>
         <!-- <hr class="my-3"> -->
          
        </div>
      </div>

    </div>
  </div>
</section>

<ng-template #confirmPopup let-modal>
  <div class="full_width">
    <div class="modal-header">

      <h2 class="modal-title mb-0">Delete Your Account</h2>
      <button type="button" class="close" aria-describedby="modal-title" matDialogClose >
        <span aria-hidden="true">&times;</span>
      </button>

    </div>
    <div class="modal-body">
      <p>Hi {{userName}},</p>
      <p> You can delete your account. This means your account will be delete permently.</p>

      <p> <b> To continue, Please re-enter your password</b></p>
      <form [formGroup]="accountDeleteForm">
        <div class="form-label-group mb-2">
          <input type="password" id="password-new" class="form-control" placeholder="Password" formControlName="passWord">
        </div>
        <small *ngIf="dltSubmitted && accountDeleteForm.controls.passWord.errors" class="error_signup">
          <div *ngIf="dltSubmitted && accountDeleteForm.controls.passWord.errors.required"> Password is required</div>
          <div *ngIf="dltSubmitted && !accountDeleteForm.controls.passWord.errors.validspace && accountDeleteForm.controls.passWord.errors.minlength"> Invalid Password </div>
          <div *ngIf="dltSubmitted && !accountDeleteForm.controls.passWord.errors.validspace && accountDeleteForm.controls.passWord.errors.maxlength"> Invalid Password </div>
          <div *ngIf="dltSubmitted && accountDeleteForm.controls.passWord.errors.validspace"> Invalid Password </div>
          <div *ngIf="dltSubmitted && accountDeleteForm.controls.passWord.errors.incorrect"> Sorry, your password was incorrect. Please double-check your password </div>
      </small>
      </form>
      
    </div>
    <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" matDialogClose >Cancel</button>
    <button type="button" class="btn btn-danger" (click)="onDelete()" >Delete Account</button>
  </div>
  </div>
</ng-template>
