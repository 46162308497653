import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { UploadService } from 'src/app/services/upload.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toaster.service';
import { MatDialog } from '@angular/material/dialog';
import { AddcourseWeekPopupComponent } from '../addcourse-week-popup/addcourse-week-popup.component';
import { PopupService } from 'src/app/services/popup.service';
import { Page } from 'src/app/models/pageDto';
import { Validatespace } from "src/app/validation/spacevalidation";
import { DomSanitizer } from '@angular/platform-browser';

const MEDIA_FILE_DATA: string = 'mediaFileData';
const FILE_OPTIONS: string = 'fileOptions';
const OPTION_TEXT: string = 'optionText';
const OPTION_LINK: string = 'optionLink';
const OPTION_TYPE: string = 'optionType';


@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  @ViewChild('qTable') qtable: ResourcesComponent;
  courseId: any;
  //resourceType: any;
  courseContentTypeId: any;
  weekId: any;
  resourceForm: FormGroup;
  liveClassForm: FormGroup;
  submitted: boolean = false;
  session_id: any;
  s3FilePath: any;
  selectedFile: any;
  selectedReferanceFile: any;
  s3CourseVideoFileName: any;
  s3CourseReferanceFileName: any;
  isProgresBar: boolean = false;
  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'indeterminate';
  progresValue = 0;
  subjects: any;
  chapters: any;
  chapter: any;
  isChapter: boolean = false;
  concepts: any;
  concept: any;
  isConcept: boolean = false;
  modules: any;
  module: any;
  isModule: boolean = false;
  referenceModel: ReferenceModel;
  referances: referances;
  course_element_type: number = 0;
  resourceHeadding: string;
  contentTypeName: string;
  isResourceTypeVideo: boolean = false;
  isResourceTypeAudio: boolean = false;
  isResourceTypeRead: boolean = false;
  resources: any;
  ckeConfig: any;
  isEdit: boolean = false;
  course_content_type_id: any;
  courseContentId: any;
  courseCountentDetails: any;
  pdfSrc: any;
  displayModal: boolean = false;
  imageSrc: any;
  audioSrc: any;
  videoSrc: any;
  docSrc: any;
  batches: any = [];
  public displayModel: boolean = false;
  qPage: Page;
  selected: any = [];
  dropdownSettings: any = {};
  selectedBatch: any = [];
  liveClassSubmitted: boolean = false;
  liveClassdisplayModel: boolean = false;
  selectedBatches: any =  [];
  safeURL;
  vimeoLink: boolean = false;


  //fileName = '';
  //p: number = 1;
  selectedMediaFileOptions: any[] = [];
  selectedOptionFileOptions: any[] = [];
  //selectedChildQuestionFileOptions: any[] = [];
  //selectedChildOptionFileOptions: any[] = [];

  //selectedMediaFileType: any[] = [];
  //selectedOptionFileType: any[] = [];
  //selectedChildQuestionFileType: any[] = [];
  //selectedChildOptionFileType: any[] = [];

  mediaFileCounter: number = 0;
  contentCounter: number = 0;
  //optionFileCounter: number = 0;
  //childQuestionAndOptionFileCounter: number = 0;
  //questionModel: QuestionModel;

  VIDEOFILE_PATH: string;
  REFERANCEFILE_PATH: string;

  dayWeekLists: any = [
    { value: 1, title: "Sunday" },
    { value: 2, title: "Monday" },
    { value: 3, title: "Tuesday" },
    { value: 4, title: "Wednesday" },
    { value: 5, title: "Thursday" },
    { value: 6, title: "Friday" },
    { value: 7, title: "Saturday" },
  ];

  // new test upload


  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private rote: ActivatedRoute,
    private constantService: ConstantsService,
    private uploadService: UploadService,
    private spinner: NgxSpinnerService,
    private ToasterService: ToasterService,
    public dialog: MatDialog,
    private popup: PopupService,
    private _sanitizer: DomSanitizer
    ) {
    this.liveClassForm = this.formBuilder.group({
      classLink: ['', [
        Validators.required,
        Validatespace,
        Validators.pattern(
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        ),
      ],],
      batch: ['', Validators.required],
      classDescription: ['', [Validators.required, Validatespace]],
    });


    this.resourceForm = this.formBuilder.group({
      title: ['', [Validators.required, Validatespace]],
      subject: ['', Validators.required],
      chapter: [''],
      concept: [''],
      module: [''],
      videoFile: ['', Validators.required],
      unlockAfter: ['', Validators.required],
      transcript: [''],
      // duration: ['', Validators.required],
      textDescription: ['', Validators.required],
      livedescription: ['', Validators.required],
      // isFreeOnTrial: [''],
      // date: [''],
      // time: ['', Validators.required],
      referance: this.formBuilder.array([])

    });

  }
  public hasErrorlive = (controlName: string, errorName: string) => {
    return this.liveClassForm.get(controlName).hasError(errorName);
  };


  getCourseContentObj() {
    return {
      course_content_id: null, resource_type: null, course_element_type: null, course_id: null, week_id: null, course_content_type_id: null, title: null, subject_id: null, chapter_id: null, concept_id: null, module_id: null, content: null,
      unlock_after: null, transcript: null, free_on_trial: null, references: [{ type: null, contents: [{ content: null }], text: null }], grade_per_question: null, number_of_questions: null, time_limit: null, minimum_grade_percentage: null,
      final_assessment: null, maximum_grade: null, submission_format: null, maximum_files_submit: null, success_feedback: null, failure_feedback: null,
    }; // live_class_date: null, live_class_time: null,
  }
  async ngOnInit() {
    this.s3FilePath = this.constantService.s3FilePath;
    this.VIDEOFILE_PATH = this.constantService.VIDEOFILE_PATH;
    this.REFERANCEFILE_PATH = this.constantService.REFERANCEFILE_PATH;
    await this.getSessionId();

    this.rote.params.subscribe(params => {
      this.courseId = params['courseId'];
      this.courseContentTypeId = params['courseContentTypeId'];
      // this.resourceType = this.rote.snapshot.paramMap.get("resource_type");
      this.weekId = params['week_id'];
      this.courseContentId = params['course_content_id'];

      // this.getContentType();
      // this.getCourseelements();
    });

    // if (this.courseContentTypeId == 8) {
    //   this.resourceForm = this.formBuilder.group({
    //     title: ['', Validators.required],
    //     subject: [''],
    //     chapter: [''],
    //     concept: [''],
    //     module: [''],
    //     // liveContentLink: [''],
    //     unlockAfter: ['', Validators.required],
    //     // transcript: [''],
    //     // duration: ['', Validators.required],
    //     textDescription: [''],
    //     livedescription: ['', Validators.required],
    //     // isFreeOnTrial: [''],
    //     // date: [''],
    //     // time: ['', Validators.required],
    //     referance: this.formBuilder.array([])

    //   });
    // } else {
    //   this.resourceForm = this.formBuilder.group({
    //     title: ['', Validators.required],
    //     subject: [''],
    //     chapter: [''],
    //     concept: [''],
    //     module: [''],
    //     videoFile: ['', Validators.required],
    //     unlockAfter: ['', Validators.required],
    //     transcript: [''],
    //     // duration: ['', Validators.required],
    //     textDescription: [''],
    //     // isFreeOnTrial: [''],
    //     // liveContentLink: [''],
    //     livedescription: [''],
    //     // date: ['', Validators.required],
    //     // time: ['', Validators.required],
    //     referance: this.formBuilder.array([])

    //   });
    // }

    this.getContentType();
    this.getCourseelements();

    this.referenceModel = {
      id: null,
      title: null,
      text: null,
      type: null,
      referances: []
    };
    this.referances = {
      id: null,
      file: null,
      path: null,
      text: null,
      type: null,
      contents: []
    }
    this.ckeConfig = {
      allowedContent: true,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        // { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About,Styles,Font,FontSize'
      // removePlugins: 'horizontalrule,about,list,others',
      // removeButtons: 'Save,NewPage,Preview,Print,Templates,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Find,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,PageBreak,Iframe,Font,TextColor,BGColor,ShowBlocks,Cut,Copy,Paste,Table,Image,Format,Maximize,Styles,Anchor,SpecialChar,PasteFromWord,PasteText,Scayt,Undo,Redo,Strike,RemoveFormat,Indent,Outdent,Blockquote,Link,Unlink,Subscript,Superscript'
    };

    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "start_date",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: true,
      allowSearchFilter: true,
    };
  }

  async getCourseelements() {
    this.spinner.show();
    var fetch_url = "getCourseelements";
    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe(async (data: any) => {

      if (data.status == "true" && data.course_elements) {
        //this.subject = data.course_elements
        this.subjects = data.course_elements.filter(option => option.type_id === 1 && option.status_id === 1);
        this.chapters = data.course_elements.filter(option => option.type_id === 2 && option.status_id === 1);
        this.concepts = data.course_elements.filter(option => option.type_id === 3 && option.status_id === 1);
        this.modules = data.course_elements.filter(option => option.type_id === 4 && option.status_id === 1);
        //this.resourceForm.get('subject').setValue(this.subjects[0]);
        this.spinner.hide();
        await this.subjectChange();
        if (this.courseContentId) {
          this.isEdit = true;
          await this.getCourseCountentDetails();
        }
        //this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  allowedImageTypes: any;
  getContentType() {
    this.spinner.show();
    var fetch_url = "get_content_types";
    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.resources) {
        this.resources = data.resources;
        this.setResourceTypes();
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }

  setResourceTypes() {

    this.resources.forEach(element => {
      if (this.courseContentTypeId == element.id) {
        this.resourceHeadding = element.content_type;
        this.contentTypeName = element.media_type
        this.course_content_type_id = element.id;
        this.allowedImageTypes = [];
      }
    });
  }

  getCourseCountentDetails() {

    this.spinner.show();
    this.session_id
    var fetch_url = "get_course_content_detail?course_content_id=" + this.courseContentId;
    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {

      if (data.status == "true" && data.course_content_detail) {
        this.courseCountentDetails = data.course_content_detail;
        this.courseId = this.courseCountentDetails.course_id;
        this.weekId = this.courseCountentDetails.week_id;
        this.courseContentTypeId = this.courseCountentDetails.course_content_type_id;
        this.setResourceTypes();
        this.resourceForm.get('title').setValue(this.courseCountentDetails.title);
        if (this.courseCountentDetails.subject_id) {
          this.resourceForm.get('subject').setValue(this.courseCountentDetails.subject_id);
          this.subjectChange();
        }
        if (this.courseCountentDetails.chapter_id) {
          this.resourceForm.get('chapter').setValue(this.courseCountentDetails.chapter_id);
          this.chapterChange();
        }
        if (this.courseCountentDetails.concept_id) {
          this.resourceForm.get('concept').setValue(this.courseCountentDetails.concept_id);
          this.conceptChange();
        }
        if (this.courseCountentDetails.module_id) {
          this.resourceForm.get('module').setValue(this.courseCountentDetails.module_id);
          this.moduleChange();
        }
        this.resourceForm.get('unlockAfter').setValue(this.courseCountentDetails.unlock_after);
        this.resourceForm.get('transcript').setValue(this.courseCountentDetails.transcript);
        if (this.courseContentTypeId === 3) {
          this.resourceForm.get('textDescription').setValue(this.courseCountentDetails.content);
          this.resourceForm
            .get("videoFile")
            .setErrors(null);
        }
        else if (this.courseContentTypeId === 8) {
          this.resourceForm.get('livedescription').setValue(this.courseCountentDetails.content);
          this.resourceForm
            .get("videoFile")
            .setErrors(null);
          // if (this.courseCountentDetails.live_session_time) {
          //   // this.resourceForm.get('time').setValue(this.courseCountentDetails.live_session_time);
          // }

          // this.resourceForm.get('date').setValue(this.courseCountentDetails.live_session_date);
        } else {
          if (this.courseCountentDetails.content) {
            this.pdfSrc = this.s3FilePath + this.courseCountentDetails.content
            this.course_content_type_id
            this.viewFile(this.pdfSrc, this.course_content_type_id + "");
            this.s3CourseVideoFileName = this.courseCountentDetails.content
            this.resourceForm.get('videoFile').setValue(this.courseCountentDetails.content);
            this.resourceForm
              .get("textDescription")
              .setErrors(null);
            this.resourceForm
              .get("livedescription")
              .setErrors(null);
            // this.resourceForm.get('videoFile').setErrors(null);

          }
          // this.courseCountentDetails.content

        }
        let i = 0;


        for (let referanceList of this.courseCountentDetails.references) {

          // if (referanceList.type == "1"){
          let referanceListTypeList = this.resourceForm.get(
            "referance"
          ) as FormArray;
          referanceListTypeList.push(this.addReference());

          (this.resourceForm.get("referance") as FormArray).controls[i]
            .get("text")
            .setValue(referanceList.text);

          (this.resourceForm.get("referance") as FormArray).controls[i]
            .get("type")
            .setValue(parseInt(referanceList.type));
          let j = 0;
          for (let item of referanceList.contents) {

            let type_ = (this.resourceForm.value.referance)[i].type;
            let type = parseInt(type_)
            let referanceListTypeList = ((this.resourceForm.get(
              "referance"
            ) as FormArray).at(i) as FormGroup).get("referances") as FormArray;

            if (type == 2) {
              referanceListTypeList.push(this.addReferenceLink());

              (((this.resourceForm.get("referance") as FormArray).at(
                i
              ) as FormGroup).get("referances") as FormGroup).controls[j]
                .get("text")
                .setValue(item.content);

            } else {

              referanceListTypeList.push(this.addReferenceFile());

              (((this.resourceForm.get("referance") as FormArray).at(
                i
              ) as FormGroup).get("referances") as FormGroup).controls[j]
                .get("path")
                .setValue(item.content);

              (((this.resourceForm.get("referance") as FormArray).at(
                i
              ) as FormGroup).get("referances") as FormGroup).controls[j]
                .get("changeflag")
                .setValue(false);
            }
            j++
          }
          i++;

        }
        // this.courseCountentDetails.references.forEach(element => {
        //   let controlNameText = OPTION_TEXT + i;
        //   let controlNameOption = FILE_OPTIONS + i;
        //   this.incrementMediaFileCounter(1, element.type_id);
        //   this.resourceForm.get(controlNameText).setValue(element.text);
        //   this.resourceForm.get(controlNameOption).setValue(element.type.toString());
        //   //this.deletecontent(i);
        //   this.referenceModel.referances.find(x => x.id === i).text = element.text;
        //   let first = true;
        //   element.contents.forEach(elementCountents => {
        //     if (!first) {
        //       this.incrementcontent(i);
        //     }
        //     let controlName = null;
        //     if (element.type == 1) {
        //       controlName = MEDIA_FILE_DATA + j;
        //       this.referances.contents.find(x => x.id === j).name = elementCountents.content;
        //       this.referances.contents.find(x => x.id === j).type = element.type + "";
        //       this.referances.contents.find(x => x.id === j).path = elementCountents.content;
        //     } else if (element.type == 2) {
        //       controlName = OPTION_LINK + j;
        //       this.referances.contents.find(x => x.id === j).type = element.type + "";
        //       this.referances.contents.find(x => x.id === j).text = elementCountents.content;
        //       this.resourceForm.addControl(OPTION_LINK + j, this.formBuilder.control(elementCountents.content));
        //       if (!first) {
        //         this.resourceForm.get(controlName).setValue(elementCountents.content);
        //       }
        //       //  this.resourceForm.get(controlName).setValue(elementCountents.content);
        //     }
        //
        //     first = false;
        //     j++;
        //   });
        //   i++;
        // });

        // if (this.courseCountentDetails) {
        //   this.resourceForm.get('isFreeOnTrial').setValue(true);
        // } else {
        //   this.resourceForm.get('isFreeOnTrial').setValue(false);
        // }

        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  subjectChange() {

    this.isChapter = false;
    this.isConcept = false;
    this.isModule = false;
    this.course_element_type = 1;
    if (this.resourceForm.value.subject) {
      this.chapter = this.chapters.filter(option => option.parent_id === this.resourceForm.value.subject);
      //this.resourceForm.get('chapter').setValue(this.chapter[0]);
      if (this.chapter.length > 0) {
        this.isChapter = true;
        this.resourceForm.get('chapter').setValue("");
        this.resourceForm.get('concept').setValue("");
        this.resourceForm.get('module').setValue("");
      }
      //this.chapterChange()
    }
  }
  chapterChange() {
    this.isConcept = false;
    this.isModule = false;
    this.course_element_type = 2;
    if (this.resourceForm.value.chapter == "") {
      this.course_element_type = 1
    }
    if (this.resourceForm.value.chapter) {
      this.concept = this.concepts.filter(option => option.parent_id === this.resourceForm.value.chapter);
      // this.resourceForm.get('concept').setValue(this.concept[0]);
      if (this.concept.length > 0) {
        this.isConcept = true;
        this.resourceForm.get('concept').setValue("");
        this.resourceForm.get('module').setValue("");
      }
      // this.conceptChange();
    }
  }
  conceptChange() {
    this.isModule = false;
    this.course_element_type = 3;
    if (this.resourceForm.value.concept == ""){
      this.course_element_type = 2
    }
    if (this.resourceForm.value.concept) {
      this.module = this.modules.filter(option => option.parent_id === this.resourceForm.value.concept);
      //this.resourceForm.get('module').setValue(this.module[0]);
      if (this.module.length > 0) {
        this.isModule = true;
        this.resourceForm.get('module').setValue("");
      }
    }
  }
  moduleChange() {
    if (this.resourceForm.value.module == "") {
      this.course_element_type = 3
    }
    this.course_element_type = 4;
  }
  onMediaFileOptionsChanged(id) {

    let controlName: any = FILE_OPTIONS + id;
    this.referances.contents.forEach(element => {
      if (element.itemId === id) {
        element.text = null;
        element.path = null;
        element.file = null;
        element.type = this.resourceForm.value[controlName];
        this.resourceForm.removeControl(MEDIA_FILE_DATA + element.id);
        this.resourceForm.removeControl(OPTION_LINK + element.id);
      }
    })
    this.referances.contents = this.referances.contents.filter(item => item.itemId != id)
    this.incrementcontent(id);

  }

  onMediaFileChanged(event, id) {

    if (event.target.files && event.target.files.length) {

      let controlName: any = FILE_OPTIONS + id;

      this.referances.contents.find(x => x.id === id).file = event.target.files[0];
      this.referances.contents.find(x => x.id === id).name = event.target.files[0].name;

      this.s3CourseReferanceFileName = this.referances.contents.find(x => x.id === id).path
      this.referances.contents.find(x => x.id === id).path = this.uploadFile(event, this.REFERANCEFILE_PATH);

    }
  }

  ontextChanged(event, id) {

    this.referenceModel.referances.find(x => x.id === id).text = event.currentTarget.value;
  }
  onOptionTextChanged(event, id) {

    this.referances.contents.find(x => x.id === id).text = event.currentTarget.value;
  }

  incrementMediaFileCounter(mediaType, type?: string) {
    this.mediaFileCounter++;

    let ReferenceModelNew: ReferenceModel = { id: this.mediaFileCounter, title: null, text: null, type: mediaType, referances: [] };
    this.addMediaTable(ReferenceModelNew, this.mediaFileCounter, type);
  }
  addMediaTable(mediaFile, controlId, type) {
    this.referenceModel.referances.push(mediaFile);
    if (type) {
      this.resourceForm.addControl(FILE_OPTIONS + controlId, this.formBuilder.control(type.toString()));
    } else {
      this.resourceForm.addControl(FILE_OPTIONS + controlId, this.formBuilder.control('1'));
    }
    this.resourceForm.addControl(OPTION_TEXT + controlId, this.formBuilder.control('', Validators.required));
    // this.resourceForm.addControl(OPTION_TYPE + controlId, this.formBuilder.control('', Validators.required));
    this.incrementcontent(controlId);

  }

  deleteMediaRow(id) {
    var delBtn = confirm(" Do you want to delete ?");
    if (delBtn == true) {
      this.referenceModel.referances = this.referenceModel.referances.filter(item => item.id !== id);
      this.resourceForm.removeControl(FILE_OPTIONS + id);
      this.resourceForm.removeControl(OPTION_TEXT + id);
      this.resourceForm.removeControl(MEDIA_FILE_DATA + id);
      this.resourceForm.removeControl(OPTION_LINK + id);
    }
  }
  incrementcontent(itemId) {

    this.contentCounter++;
    let controlName: any = FILE_OPTIONS + itemId;
    let contentsNew: contents = { id: this.contentCounter, itemId: itemId, name: null, text: null, path: null, file: null, type: this.resourceForm.value[controlName] };
    this.addContentTable(contentsNew, this.contentCounter, false);
  }
  addContentTable(mediaFile, controlId, fileUploadRequireValidation) {

    this.referances.contents.push(mediaFile);
    let controlName = FILE_OPTIONS + mediaFile.itemId;
    if (this.resourceForm.get(controlName).value == 1) {
      this.resourceForm.addControl(MEDIA_FILE_DATA + controlId, this.formBuilder.control(''));
    } else {
      this.resourceForm.addControl(OPTION_LINK + controlId, this.formBuilder.control(''));
    }
    // this.resourceForm.addControl(OPTION_TEXT + controlId, this.formBuilder.control('', Validators.required));
  }
  deletecontent(itemId) {

    var delBtn = true
    if (delBtn == true) {
      let deletedContent = this.referances.contents.filter(item => item.id === itemId)
      if (deletedContent && deletedContent[0].path) {
        this.uploadService.fileDelete(deletedContent[0].path, null);
      }
      this.referances.contents = this.referances.contents.filter(item => item.id != itemId)
      // this.referenceModel.referances = this.referenceModel.referances.filter(item => item.id !== id);
      this.resourceForm.removeControl(MEDIA_FILE_DATA + itemId);
      this.resourceForm.removeControl(OPTION_LINK + itemId);

      // this.resourceForm.removeControl(OPTION_TEXT + id);
    }
  }


  updateMediaFileAcceptType(id, controlId) {

    let controlName = FILE_OPTIONS + id;
    this.selectedMediaFileOptions[id];
    if (this.resourceForm.get(controlName).value == 1) {
      this.resourceForm.addControl(MEDIA_FILE_DATA + controlId, this.formBuilder.control(''));
    } else {
      this.resourceForm.addControl(OPTION_LINK + controlId, this.formBuilder.control(''));
    }
  }



  isFieldValid(field: string) {
    return !this.resourceForm.get(field).valid && this.resourceForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  get f() {
    return this.resourceForm.controls;
  }
  async onSubmit() {
    if (this.courseContentTypeId == 8) {
      this.resourceForm
        .get("videoFile")
        .setErrors(null);
      this.resourceForm
        .get("textDescription")
        .setErrors(null);
    } else if (this.courseContentTypeId == 3) {
      this.resourceForm
        .get("videoFile")
        .setErrors(null);
      this.resourceForm
        .get("livedescription")
        .setErrors(null);
    } else {
      this.resourceForm
        .get("livedescription")
        .setErrors(null);
      this.resourceForm
        .get("textDescription")
        .setErrors(null);
    }
    this.submitted = true;
    let addObj = this.getCourseContentObj();
    this.resourceForm
    this.s3CourseVideoFileName
    debugger
    if (this.resourceForm.invalid) {
      return;
    }
    this.spinner.show();

    await this.pushDataToS3FromActionList()
    if (this.courseContentTypeId != '3') {
      if (this.courseContentTypeId != '8' || this.courseContentTypeId != '9') {
        if (this.progresValue != 100 && (this.courseContentTypeId != '3' || this.courseContentTypeId != '7')) {
          if (this.progresValue != 0 && (this.courseContentTypeId != '3' || this.courseContentTypeId != '7') && this.s3CourseVideoFileName) {
            this.ToasterService.openSnackBar('File not completely uploaded', '', 'warning');
            return;
          }


        }
      }
    }
    addObj.course_content_id = this.courseContentId;
    addObj.resource_type = this.courseContentTypeId;
    addObj.course_element_type = this.course_element_type;
    addObj.course_id = this.courseId;
    addObj.week_id = this.weekId;
    addObj.course_content_type_id = this.course_content_type_id;
    addObj.title = this.resourceForm.value.title;
    addObj.subject_id = this.resourceForm.value.subject;
    addObj.chapter_id = this.resourceForm.value.chapter;
    addObj.concept_id = this.resourceForm.value.concept;
    addObj.module_id = this.resourceForm.value.module;
    if (this.courseContentTypeId == 3) {
      addObj.content = this.resourceForm.value.textDescription;
    } else if (this.courseContentTypeId == 8) {
      addObj.content = this.resourceForm.value.livedescription;
      // addObj.live_class_time = this.resourceForm.value.time;
      // addObj.live_class_date = this.resourceForm.value.date;
    }else if (this.courseContentTypeId == 9) {
      addObj.content = this.resourceForm.value.videoFile;
      // addObj.live_class_time = this.resourceForm.value.time;
      // addObj.live_class_date = this.resourceForm.value.date;
    }
    else {
      addObj.content = this.s3CourseVideoFileName;
    }
    addObj.unlock_after = this.resourceForm.value.unlockAfter;
    addObj.transcript = this.resourceForm.value.transcript;
    // if (this.resourceForm.value.isFreeOnTrial) {
    //   addObj.free_on_trial = 1;
    // } else {
    //   addObj.free_on_trial = 2;
    // }

    addObj.references = [];

    this.resourceForm.value.referance;

    for (let refList of this.resourceForm.value.referance) {
      let ref = { text: refList.text, type: refList.type, contents: [] };
      let content = [];
      for (let referance of refList.referances) {
        let cont = { content: null };
        if (referance.type == '1') {

          cont.content = referance.path;

          content.push(cont);
        } else {
          ref.type = 2;
          cont.content = referance.text;
          content.push(cont);
        }
        ref.contents = content;
      }
      addObj.references.push(ref);

    }

    // this.referenceModel.referances.forEach(element => {
    //   let ref = { text: element.text, type: element.type, contents: [] };
    //   let content = [];
    //   this.referances.contents.forEach(contentelement => {
    //
    //     if (contentelement.itemId === element.id) {
    //       let cont = { content: null };
    //       if (contentelement.type == '1') {

    //         if (typeof (contentelement.path) == "object") {
    //           cont.content = contentelement.path;
    //           cont.content = cont.content.__zone_symbol__value
    //         } else {
    //           cont.content = contentelement.path;
    //         }
    //         content.push(cont);
    //       } else {
    //         ref.type = 2;
    //         cont.content = contentelement.text;
    //         content.push(cont);
    //       }
    //       ref.contents = content;
    //     }
    //   });
    //   addObj.references.push(ref);
    // })




    var url = "";
    if (this.isEdit) {
      url = "update_course_content";
    } else {
      url = "add_course_content";
    }

    this.api.detailsAddFetch(addObj, url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true") {
        this.spinner.hide();
        this.ToasterService.openSnackBar(data.message, '', 'success');
        this.router.navigate(['admin/course/editcourse/' + this.courseId]);
      }
      else {
        this.ToasterService.openSnackBar(data.message, '', 'warning');
        this.spinner.hide();
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
    this.submitted = false;


  }

  onVideoFileChanged(event,file_type) {
    debugger

    if (event.target.files && event.target.files.length) {
      if(file_type == "video"){
        let videoPath_ = event.target.files[0].type;
      var ext = videoPath_.split('/');
      if (ext[0] == "video") {

      this.isProgresBar = true;
      this.mode = 'indeterminate';
      this.progresValue = 0
      this.uploadFile(event, this.VIDEOFILE_PATH);
      this.pdfSrc =""
    }
    else {
      this.ToasterService.openSnackBar('Unsupported file format', '', 'warning');
      this.selectedFile = null;
      this.resourceForm.get('videoFile').setValue('');
      this.pdfSrc ="";
      // this.resourceForm.get('videoFile').setValue({ required: true });
    }
  } else if(file_type == "audio"){
    let audioPath_ = event.target.files[0].type;
      var ext = audioPath_.split('/');
      if (ext[0] == "audio") {

      this.isProgresBar = true;
      this.mode = 'indeterminate';
      this.progresValue = 0
      this.uploadFile(event, this.VIDEOFILE_PATH);
      this.pdfSrc =""
    }
    else {
      this.ToasterService.openSnackBar('Unsupported file format', '', 'warning');
      this.selectedFile = null;
      this.resourceForm.get('videoFile').setValue('');
      this.pdfSrc ="";
      // this.resourceForm.get('videoFile').setValue({ required: true });
    }
  }else if(file_type == "pdf"){
    let documentPath_ = event.target.files[0].name;
      var ext = documentPath_.split('.').pop();
      if (ext == "pdf") {

      this.isProgresBar = true;
      this.mode = 'indeterminate';
      this.progresValue = 0
      this.uploadFile(event, this.VIDEOFILE_PATH);
      this.pdfSrc =""
    }
    else {
      this.ToasterService.openSnackBar('Unsupported file format', '', 'warning');
      this.selectedFile = null;
      this.resourceForm.get('videoFile').setValue('');
      this.pdfSrc ="";
      // this.resourceForm.get('videoFile').setValue({ required: true });
      // this.pdfSrc =null;

    }
  }

  }
  }
  uploadFile(event, folderPath) {

    const reader = new FileReader();

    if (folderPath === this.VIDEOFILE_PATH) {
      this.selectedFile = event.target.files[0];
      reader.onload = () => {
        // this.imageSrc = reader.result as string;
      }
      reader.readAsDataURL(this.selectedFile);
      if (this.s3CourseVideoFileName) {
        var s3CoursVideoFileNamedelete = this.uploadService.fileDelete(this.s3CourseVideoFileName, folderPath);
      }
      if (this.selectedFile != null) {
        this.s3CourseVideoFileName = null
        this.uploadService.fileUpload(this.selectedFile, folderPath).then(res => {

          if (res) {
            this.s3CourseVideoFileName = res
            this.mode = 'determinate';
            this.progresValue = 100;
            this.resourceForm.get('videoFile').setValue(this.s3CourseVideoFileName);
          }
        })
      }
    } else if (folderPath === this.REFERANCEFILE_PATH) {
      this.selectedReferanceFile = event.target.files[0];
      reader.onload = () => {
        // this.imageSrc = reader.result as string;
      }
      reader.readAsDataURL(this.selectedReferanceFile);
      if (this.s3CourseReferanceFileName) {
        var s3CoursePDFFileNamedelete = this.uploadService.fileDelete(this.s3CourseReferanceFileName, folderPath);
      }
      if (this.selectedReferanceFile != null) {
        this.s3CourseReferanceFileName = null
        this.s3CourseReferanceFileName = this.uploadService.fileUpload(this.selectedReferanceFile, folderPath);
        // this.s3CourseReferanceFileName = this.selectedReferanceFile.name;
      }
      return this.s3CourseReferanceFileName;
    }
  }

  resourceShow() {

    const dialogRef = this.dialog.open(AddcourseWeekPopupComponent, {
      height: '400px',
      width: '600px',
      data: { title: 'Add Activity or Resources', Week: null, isActivityResourse: true }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        if (result.isResource) {
          this.router.navigate(['admin/course/resource/' + this.courseId + '/' + result.resource.id + '/' + this.weekId]);
        } else {
          this.router.navigate(['admin/course/activity/' + this.courseId + '/' + result.activity.id + '/' + this.weekId]);
        }
      }
    });
  }

  async getSessionId() {
    try {
      this.session_id = localStorage.getItem('JWT_TOKEN');
    } catch {
      this.router.navigate(['admin/login']);
    }


  }

  openLink() {

    if(this.courseContentTypeId == '9'){
      if(this.resourceForm.value.videoFile){
        /* for iframe data uncomment below code and use iframe in html */
        // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl( this.resourceForm.value.videoFile);

        /* for loading innerHTML content use below code */
      this.safeURL = this._sanitizer.bypassSecurityTrustHtml( this.resourceForm.value.videoFile);
      this.displayModal = true;
      this.vimeoLink = true;
    }

    }else{

    if (this.docSrc != undefined) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = this.docSrc;
      link.setAttribute("visibility", "hidden");
      link.click();
    } else {
      this.displayModal = true;
    }
  }


  }

  viewFile(src, fileType) {
    this.docSrc = undefined;
    this.audioSrc = undefined;
    this.videoSrc = undefined;
    fileType

    switch (fileType) {
      // case "": {
      //   this.imageSrc = src;
      //   break;
      // }
      case "1": {
        this.videoSrc = src;
        break;
      }

      case "2": {
        this.audioSrc = src;
        break;
      }

      case "7": {
        this.docSrc = src;
        break;
      }
      default: {
        break;
      }
    }

  }

  sentliveClassLink(): void {


    this.spinner.show();
    this.batches = [];
    var fetch_url = "get_batches?course_id=" + this.courseId + "?enrollment_status=1";

    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {

      if (data.status == "true" && data.batches) {

        this.batches = data.batches;
        this.showModel();
        this.spinner.hide();

      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message;
        this.popup.failurepopup();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });


  }
  showModel(): void {
    this.liveClassdisplayModel = true;
  }

  closeModel(): void {

    this.liveClassdisplayModel = false;
    this.liveClassForm.reset();
  }

  sendLink() {
    this.liveClassForm
    debugger

    this.liveClassSubmitted = true;
    // if(this.selected.length<=0){
    //   return;
    // }
    if (this.liveClassForm.status == "INVALID") {

      return;
    }

    // if (this.selected.length == 0) {
    //   return;
    // }




    this.spinner.show();
    this.selected = []
    for (let item of this.liveClassForm.value.batch){
      this.selected = [...this.selected, item.id]
    }

    // this.selectedBatch = this.liveClassForm.value.batch;
    let url = "add_live_class_url";
    var body = { "batch_ids": this.selected, 'course_content_id': this.courseContentId, 'comments': this.liveClassForm.value.classDescription, 'live_url': this.liveClassForm.value.classLink };
    this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {

      if (data.status == "true" && data.message) {
        this.spinner.hide();
        this.closeModel();
        this.popup.sucessMessage = data.message;
        this.popup.sucesspopup();

      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message;
        this.popup.failurepopup();
        this.closeModel();
      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }

  onSelectCheckbox(batch): void {
    debugger

    // let found: boolean = false;
    // for (let i = 0; i < this.selected.length; i++) {
    //   if (this.selected[i] == batch.id) {
    //     this.selected.splice(i, 1);
    //     found = true
    //   }
    // }

    // if (!found) {
    //   this.selected.push(batch.id)
    // }
    // console.log(this.selected);
    // console.log(batch);
  }



  // test upload new

  public hasError = (controlName: string, errorName: string) => {
    return this.resourceForm.get(controlName).hasError(errorName);
  };


  // 1st array
  addNewReference() {
    let referanceListTypeList = this.resourceForm.get(
      "referance"
    ) as FormArray;
    referanceListTypeList.push(this.addReference());
    this.addNewReferenceMedia(referanceListTypeList.length - 1)
    this.resourceForm;

  }

  addReference() {
    return this.formBuilder.group({
      id: [""],
      text: ["", [Validators.required, Validatespace]],
      referances: this.formBuilder.array([]),
      type: ["1", [Validators.required]],

    });


  }

  // 2nd array
  addNewReferenceMedia(index) {

    let type = (this.resourceForm.value.referance)[index].type;
    let referanceListTypeList = ((this.resourceForm.get(
      "referance"
    ) as FormArray).at(index) as FormGroup).get("referances") as FormArray;

    if (type == 2) {
      referanceListTypeList.push(this.addReferenceLink());
    } else {
      referanceListTypeList.push(this.addReferenceFile());
    }

    setTimeout(() => {
      let focusdiv: HTMLElement = <HTMLElement>(
        window.document.querySelector("#focusurl" + index)
      );
      focusdiv && focusdiv.focus();
    }, 100);

  }

  addReferenceFile() {
    return this.formBuilder.group({
      id: [""],
      file: [""],
      path: ["", Validators.required],
      type: ["1"],
      contents: [""],
      changeflag: [false]
      // text: [""]

    });

  }

  addReferenceLink() {
    return this.formBuilder.group({
      id: [""],
      text: ["", [
        Validators.required,
        Validatespace,
        Validators.pattern(
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        ),
      ]],
      type: ["2"],
      contents: [""],
      // file: [""],
      // path: [""],

    });

  }
  get getresourceList() {
    return this.resourceForm.get("referance") as FormArray;
  }

  referanceListdelete(index) {
    this.getresourceList.removeAt(index);
  }

  referenceMediadelete(index1, index2) {

    (((this.resourceForm.get("referance") as FormArray).at(
      index1
    ) as FormGroup).get("referances") as FormArray).removeAt(index2);
  }

  onReferenceMediaTypeChanged(type, index) {

    let referanceListTypeList = ((this.resourceForm.get(
      "referance"
    ) as FormArray).at(index) as FormGroup).get("referances") as FormArray;

    while (referanceListTypeList.length !== 0) {
      referanceListTypeList.removeAt(0)
    }

    if (type == 2) {
      referanceListTypeList.push(this.addReferenceLink());
    } else {
      referanceListTypeList.push(this.addReferenceFile());
    }
  }

  onReferanceFileChanged(e, index1, index2) {
    var file = e.target.files[0];
    var mimeType = file.type;
    var extn = mimeType.split("/");

    (((this.resourceForm.get("referance") as FormArray).at(
      index1
    ) as FormGroup).get("referances") as FormGroup).controls[index2]
      .get("file")
      .setValue(file);

    (((this.resourceForm.get("referance") as FormArray).at(
      index1
    ) as FormGroup).get("referances") as FormGroup).controls[index2]
      .get("changeflag")
      .setValue(true);

    (((this.resourceForm.get("referance") as FormArray).at(
      index1
    ) as FormGroup).get("referances") as FormGroup).controls[index2]
      .get("path")
      .setValue(file.name);
  }

  async pushDataToS3FromActionList() {
    let i = 0;
    for (let content of this.resourceForm.value.referance) {
      if (content.type == '1') {
        let j = 0
        for (let mediaContent of content.referances) {
          if (mediaContent.changeflag) {

            let url = await this.uploadToS3(mediaContent.file, this.REFERANCEFILE_PATH);
            (((this.resourceForm.get("referance") as FormArray).at(
              i
            ) as FormGroup).get("referances") as FormGroup).controls[j]
              .get("path")
              .setValue(url);

            (((this.resourceForm.get("referance") as FormArray).at(
              i
            ) as FormGroup).get("referances") as FormGroup).controls[j]
              .get("changeflag")
              .setValue(false);
          }
          j++;
        }
      }
      i++;
    }
  }

  async uploadToS3(file, folderPath) {

    const reader = new FileReader();

    this.selectedReferanceFile = file;
    reader.onload = () => {
      // this.imageSrc = reader.result as string;
    }
    reader.readAsDataURL(this.selectedReferanceFile);
    if (this.s3CourseReferanceFileName) {
      var s3CoursePDFFileNamedelete = await this.uploadService.fileDelete(this.s3CourseReferanceFileName, folderPath);
    }
    if (this.selectedReferanceFile != null) {
      this.s3CourseReferanceFileName = null
      this.s3CourseReferanceFileName = await this.uploadService.fileUpload(this.selectedReferanceFile, folderPath);
      // this.s3CourseReferanceFileName = this.selectedReferanceFile.name;
    }
    return await this.s3CourseReferanceFileName;

  }

}

export interface ReferenceModel {
  id: number;
  title: string;
  text: string;
  type: number;
  referances: Array<referances>;
}

export interface referances {
  id: number;
  file: File;
  path: string;
  text: string;
  type: number;
  contents: Array<contents>;
}

export interface contents {
  id: number;
  itemId: number;
  name: string;
  text: string;
  path: string;
  type: string;
  file: string;
}

