<section class="bread_sec shadow-sm">
    <div class="container">
      <div class="row">
        <!-- <div class="col-md-12">
          <xng-breadcrumb></xng-breadcrumb>
        </div> -->
      </div>
    </div>
  </section>
  <section class="sub_page_content team_content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-7 col-lg-5 mx-auto">
          <div class="card my-5 border-0 shadow-sm">
            <div class="card-body">
              <div class="alert alert-danger text-center" *ngIf="false" role="alert"> You entered email or password incorrect. </div>
              <h2 class="card-title text-center">Log In</h2>
              <form class="form-login" name="loginform" [formGroup]="loginformGroup"
              (ngSubmit)="login()">
                <div class="form-label-group mb-2">
                  <input type="email" id="inputEmail" class="form-control" placeholder="Email address" formControlName="emailaddress" required autofocus>
                  <div *ngIf="issubmitted && loginformGroup.controls.emailaddress.errors"> 
                    <div *ngIf="issubmitted && loginformGroup.controls.emailaddress.errors.required" class="error_signup"> Email is required. </div></div>
                </div>
                <div class="form-label-group mb-2">
                  <input type="password" id="inputPassword" class="form-control" placeholder="Password" formControlName="password" required>
                  <div *ngIf="issubmitted && loginformGroup.controls.password.errors"> 
                   <div *ngIf="issubmitted && loginformGroup.controls.password.errors.required" class="error_signup"> Password is required. </div></div>
                </div>
                <button class="btn btn-dark btn-sm btn-block text-uppercase" type="submit">Sign in</button>
              </form>
              <hr class="my-3">
              <div class="mt-2">
                <div class="d-flex justify-content-center links"> Don't have an account? <a (click)="signUp()" class="ml-2">Sign Up</a> </div>
                <div class="d-flex justify-content-center mt-2 forgot-pass links"> <a (click)="forgotpassword()">Forgot your password?</a> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>