import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StudentComponent } from './student.component';
import { StudentRoutingModule } from './student-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [StudentComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    StudentRoutingModule,
    NgxDatatableModule,
    NgxPaginationModule
   ]
})
export class StudentModule { }  
