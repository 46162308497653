import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { NgxSpinnerService } from "ngx-spinner";
import { LocaldataService } from 'src/app/services/localdata.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isError: boolean = false;
  isInvalid: boolean = false;
  errMsg: string;
  private loggedInUser: string;
  private JWT_TOKEN = 'JWT_TOKEN';
  errormessage: any;
  constructor(private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private LocaldataService: LocaldataService, private authService: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, RxwebValidators.email()]],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }
  login() {
    this.isInvalid = false;
    if (this.loginForm.invalid) {
      this.isError = true;
      return;
    }
    this.isError = false;
    this.spinner.show();
    
    this.apiService.login(
      {
        email: this.loginForm.value.username,
        password: this.loginForm.value.password,
      }
    )
      .subscribe((data: any) => {
        
        console.log(data)
        if (data.status == "true") {
          debugger
          this.spinner.hide();

          
          this.loggedinUser(data.email, data.user_id, data.access_token, data.role)
          this.authService.username = data.username;
          localStorage.setItem("username", data.name);
          localStorage.setItem("profile_pic", data.profile_pic);
          
          this.LocaldataService.updateUser();
          if(data.role == "sme" && data.password_reset == "true"){
            this.router.navigate(['/admin/changePassword']);
          }
          else{
            this.router.navigate(['/admin']);
          }
        }
        else {
          this.spinner.hide();
          this.isInvalid = true;
          this.errormessage = data.message;
        }
      });
  }
  loggedinUser(username: string, userID: any, tokens: string, role: string) {
    this.loggedInUser = username;
    this.storeTokenDetails(tokens, userID, role);
  }
  storeTokenDetails(tokens: string, userID: any, role: string) {
    localStorage.setItem(this.JWT_TOKEN, tokens);
    localStorage.setItem('user_id', userID)
    localStorage.setItem('user_role', role)
    this.router.navigate(['/admin']);
  }
  
}
