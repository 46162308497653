import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.css']
})
export class QuickLinksComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, public api: ApiService, private router: Router, private rote: ActivatedRoute, private titleService: Title) {

  }
  quicklinkSlug: any;
  quicklinkTitle: any;
  quicklinkDiscription: string;
  ngOnInit(): void {

    this.spinner.show();
    this.rote.params.subscribe(params => {
      this.quicklinkSlug = params['quicklink_slug'];
      this.setTitle(this.quicklinkSlug);
      this.getQuickLink();
    });

  }
  getQuickLink() {
    var quicklink_url = "student/getPageByslug?slug=" + this.quicklinkSlug;
    this.api.detailsGet(quicklink_url).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data) {
        if (data.page.length == 0) {
          this.quicklinkTitle = "No content to show";
          this.quicklinkDiscription = "";
          this.spinner.hide();
        } else {
          this.quicklinkTitle = data.page[0].title;
          this.quicklinkDiscription = data.page[0].description;
          this.spinner.hide();
        }
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });


  }
  public setTitle(newTitle) {
    debugger
    if (newTitle === 'about-us')
      this.titleService.setTitle('About Us | Al Jamia');
    else if (newTitle === 'contact-us')
      this.titleService.setTitle('Contact Us | Al Jamia');
    else if (newTitle === 'help-and-support')
      this.titleService.setTitle('Help And Support | Al Jamia');
    else if (newTitle === 'terms-conditions-1')
      this.titleService.setTitle('Terms And Conditions | Al Jamia');
    else if (newTitle === 'Sitemap')
      this.titleService.setTitle('Sitemap | Al Jamia');
  }

}
