import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  session_id: any;
  dashboardData: any;
  user_role: any;
  tableData:any;

  constructor(public api: ApiService,
    private router: Router,
    private popup: PopupService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.user_role = localStorage.getItem('user_role');
    this. getDashboardData();
    this.getTableData();
  }

  getDashboardData() {
    this.spinner.show();
    var list_fetch_url = "dashboard";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.data) {
       this.dashboardData = data.data;
       debugger
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
        this.router.navigate(['/'])
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });
  }
  getTableData() {
    var list_fetch_url="dashboard";
    this.api.detailsGetFetch(list_fetch_url,this.session_id).subscribe((data:any)=>{
      debugger
      if(data.status="true" && data.data){
        this.tableData= data.data.enrolled_course_dta;
        console.log(this.tableData)
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
        this.router.navigate(['/'])
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    })
  }

  goToEnrollments(){
    this.router.navigate(['admin/enrollments/list'])
  }

  goToNotifications(){
    this.router.navigate(['admin/notifications'])
  }

  goToCourses(){
    this.router.navigate(['admin/course/courseList'])
  }

  goToStudents(){
    this.router.navigate(['admin/student'])
  }

  goToSME(){
    this.router.navigate(['admin/sme'])
  }


}
