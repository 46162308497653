import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserReportsRoutingModule } from './user-reports-routing.module';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { InvoiceComponent } from './invoice/invoice.component';


@NgModule({
  declarations: [PaymentListComponent, InvoiceComponent],
  imports: [
    CommonModule,
    UserReportsRoutingModule
  ]
})
export class UserReportsModule { }
