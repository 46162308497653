<div class="container-fluid py-4 overview_course">
  <div class="pb-3">
    <h1 *ngIf="sme">Chat With {{sme.sme_name}}</h1>
   
  </div>
  <div class="messaging">
    <div class="inbox_msg">

      <div class="mesgs">
        <div class="msg_history" #scrollMe>
          <ng-container *ngFor="let chat of chatHistory">
            <div class="incoming_msg" *ngIf="chat.from == sme.sme_id">
              <div class="incoming_msg_img rounded-circle"> <img src="{{s3FilePath}}{{sme.profile_pic}}" alt="name">
              </div>
              <div class="received_msg">
                <div class="received_withd_msg">
                  <p> {{chat.message}}</p>
                  <span class="time_date"> {{this.chatDate}}</span>
                </div>
              </div>
            </div>
            <div class="outgoing_msg" *ngIf="chat.from == userId">
              <div class="sent_msg">
                <p>{{chat.message}}</p>
                <span class="time_date"> {{this.chatDate}}</span>
              </div>
            </div>
          </ng-container>

        </div>
        <div class="type_msg">
          <div class="input_msg_write">
            <form [formGroup]="messageForm" role="form" class="p-3 width_ add_course_">
              <input type="text" class="write_msg" placeholder="Type a message" formControlName="message"/>
            </form>
            <button class="msg_send_btn" type="button" (click)="onSubmit()"><i class="fa fa-paper-plane-o"
                aria-hidden="true"></i></button>

                <a href="javascript:void(0)" (click)="getChatHistory()" class="refreshicon_rght msg_refresh_btn"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
