import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BannerAddComponent } from './banner-add.component';
import { BannerAddRoutingModule } from './banner-add-routing.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ng2-ckeditor';



@NgModule({
  declarations: [BannerAddComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    BannerAddRoutingModule,
    AngularEditorModule,
    CKEditorModule
   ]
})
export class BannerAddModule { }
