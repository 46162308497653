<div class="wrapper">
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/faq']">FAQ</a></li>
              <li *ngIf="!isEdit" class="breadcrumb-item active">Add FAQ</li>
              <li *ngIf="isEdit" class="breadcrumb-item active">Edit FAQ</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="card elevation-0">
        <div class="card-header">
          <h1 *ngIf="!isEdit">Add FAQ</h1>
          <h1 *ngIf="isEdit">Edit FAQ</h1>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-primary p-3 pb-5">
                <form [formGroup]="faqForm" (ngSubmit)="onSubmit()">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Category *</label>
                      <select class="form-control select2 " placeholder="Select Category" formControlName="category" style="width: 100%;"
                        [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                        <option [value]="0">Select Category</option>
                        <option *ngFor="let items of categoryList" [value]="items.category_id">{{items.category_name}}
                        </option>

                      </select>
                      <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                        <div *ngIf="f.category.errors.required  ">Category is
                          required
                        </div>
                      </div>
                      <div debugger *ngIf="submitted && invalidDropdown" class="invalid-feedback">Category is
                        required
                      </div>
                    </div>
                     <div class="form-group">
                      <label>Question *</label>
                      <input type="text" class="form-control " formControlName="question" placeholder="Add question "
                        [ngClass]="{ 'is-invalid': submitted && f.question.errors }">
                      <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
                        <div *ngIf="f.question.errors.required">Question is
                          required
                        </div>
                      </div>
                    </div>
                      <div class="mb-3 mt-3">
                      <label>Answer *</label>
                      <!-- <textarea class="textarea" placeholder="Place some text here" formControlName="answer" style="width: 100%; height: 200px; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;" [ngClass]="{ 'is-invalid': submitted && f.answer.errors }"></textarea> -->
                      <ckeditor name="answer" formControlName="answer" [config]=ckeConfig
                        [ngClass]="{ 'is-invalid': submitted && f.answer.errors }">
                      </ckeditor>
                      <div *ngIf="submitted && f.answer.errors" class="invalid-feedback">
                        <div *ngIf="f.answer.errors.required">Answer is
                          required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="p-3">
                    <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                  </div>
                  <div class="clearfix"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</div>
