<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item active"><a [routerLink]="['/admin/setting/general-setting']">General Setting</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>General Setting</h1>
        <!-- <h1 *ngIf="isEdit">Edit SME</h1> -->
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3">
              <form [formGroup]="generalSettingForm" (ngSubmit)="onSubmit()">
                <div class="clearfix"></div>
                <mat-tab-group>

                  <mat-tab label="General Settings">
                    <ng-template mat-tab-label>
                      <span>General Settings</span>
                      <span
                        *ngIf="submitted && (f.email.errors || f.phone_number.errors || f.address.errors || f.facebookLink.errors || f.twitterLink.errors || f.instaLink.errors || f.youtubeLink.errors || f.androidLink.errors || f.iosLink.errors)"
                        matBadge="1" matBadgeOverlap="false" matBadgeColor="accent"></span>
                    </ng-template>

                    <div class="col-md-6 float-left">
                      <div class="form-group">
                        <label>Email Address *</label>
                        <input type="text" formControlName="email" class="form-control selact_"
                          placeholder="Enter Email Address" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">
                            Email Address is
                            required
                          </div>
                          <div *ngIf="f.email.errors.pattern">
                            Invalid email address</div>

                        </div>
                      </div>

                      <div class="form-group">
                        <label>Phone Number</label>
                        <input type="text" formControlName="phone_number" class="form-control selact_"
                          placeholder="Enter Phone Number"
                          [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }" >
                        <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                          <div *ngIf="f.phone_number.errors.required">
                            Phone Number is
                            required
                          </div>
                          <div *ngIf="f.phone_number.errors.validspace || f.phone_number.errors.invalidNumber"> Invalid Phone Number  
                          </div>
                          <div *ngIf="f.phone_number.errors.maxlength || f.phone_number.errors.minlength"> Phone number should be between 8-12 numbers  
                          </div>
                          
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Address *</label>
                        <textarea class="form-control selact_" formControlName="address"
                          placeholder="Enter Address"
                          [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                          <div *ngIf="f.address.errors.required">
                            Address is required.
                          </div>
                          <div *ngIf="f.address.errors.validspace"> Invalid Address
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Faceook Link</label>
                        <input type="text" formControlName="facebookLink" class="form-control selact_"
                          placeholder="Enter Facebook Link"
                          [ngClass]="{ 'is-invalid': submitted && f.facebookLink.errors }">
                        <div *ngIf="submitted && f.facebookLink.errors" class="invalid-feedback">
                          <div *ngIf="f.facebookLink.errors.required">
                            Facebook Link is
                            required
                          </div>
                          <div *ngIf="f.facebookLink.errors.pattern"> Invalid URL (eg : https://www.google.com/)
                          </div>
                          <!-- <div *ngIf="f.facebookLink.errors.email">
                          Email must be a valid email address</div> -->
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Twitter Link</label>
                        <input type="text" formControlName="twitterLink" class="form-control selact_"
                          placeholder="Enter Twitter Link"
                          [ngClass]="{ 'is-invalid': submitted && f.twitterLink.errors }" >
                        <div *ngIf="submitted && f.twitterLink.errors" class="invalid-feedback">
                          <div *ngIf="f.twitterLink.errors.required">
                            Twitter Link is
                            required
                          </div>
                          <div *ngIf="f.twitterLink.errors.pattern"> Invalid URL (eg : https://www.google.com/)
                          </div>
                          <!-- <div *ngIf="f.email.errors.email">
                          Email must be a valid email address</div> -->
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Instagram Link</label>
                        <input type="text" formControlName="instaLink" class="form-control selact_"
                          placeholder="Enter Insta Link" [ngClass]="{ 'is-invalid': submitted && f.instaLink.errors }"
                          >
                        <div *ngIf="submitted && f.instaLink.errors" class="invalid-feedback">
                          <div *ngIf="f.instaLink.errors.required">
                            Insta Link is
                            required
                          </div>
                          <div *ngIf="f.instaLink.errors.pattern"> Invalid URL (eg : https://www.google.com/)
                          </div>
                          <!-- <div *ngIf="f.email.errors.email">
                          Email must be a valid email address</div> -->
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Youtube Link</label>
                        <input type="text" formControlName="youtubeLink" class="form-control selact_"
                          placeholder="Enter Youtube Link"
                          [ngClass]="{ 'is-invalid': submitted && f.youtubeLink.errors }" [readonly]="!editMode">
                        <div *ngIf="submitted && f.youtubeLink.errors" class="invalid-feedback">
                          <div *ngIf="f.youtubeLink.errors.required">
                            Youtube Link is
                            required
                          </div>
                          <div *ngIf="f.youtubeLink.errors.pattern"> Invalid URL (eg : https://www.google.com/)
                          </div>
                          <!-- <div *ngIf="f.email.errors.email">
                          Email must be a valid email address</div> -->
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Android Link</label>
                        <input type="text" formControlName="androidLink" class="form-control selact_"
                          placeholder="Enter Android Link"
                          [ngClass]="{ 'is-invalid': submitted && f.androidLink.errors }">
                        <div *ngIf="submitted && f.androidLink.errors" class="invalid-feedback">
                          <div *ngIf="f.androidLink.errors.required">
                            Android Link is
                            required
                          </div>
                          <div *ngIf="f.androidLink.errors.pattern"> Invalid URL (eg : https://www.google.com/)
                          </div>
                          <!-- <div *ngIf="f.email.errors.email">
                          Email must be a valid email address</div> -->
                        </div>
                      </div>
                      <div class="form-group">
                        <label>iOS Link</label>
                        <input type="text" formControlName="iosLink" class="form-control selact_"
                          placeholder="Enter iOS Link" [ngClass]="{ 'is-invalid': submitted && f.iosLink.errors }">
                        <div *ngIf="submitted && f.iosLink.errors" class="invalid-feedback">
                          <div *ngIf="f.iosLink.errors.required">
                            iOS Link is
                            required
                          </div>
                          <div *ngIf="f.iosLink.errors.pattern"> Invalid URL (eg : https://www.google.com/)
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab label="Refer a Friend Settings">
                    <ng-template mat-tab-label>
                      <span>Refer a Friend Settings</span>
                      <span *ngIf="submitted && (f.referal_points.errors ||  f.useReferralPoint.errors || f.checkout_points.errors || f.discount_type.errors || f.discount_amount.errors || f.minimum_course_amount.errors )" matBadge="1"
                        matBadgeOverlap="false" matBadgeColor="accent"></span>
                    </ng-template>
                    <div class="pad p-3">
                      <div class="col-md-6 float-left">

                        <div class="form-group">
                          <label>Referral Points *</label>
                          <input type="number" formControlName="referal_points" class="form-control selact_"
                            placeholder="Enter Referral points"
                            [ngClass]="{ 'is-invalid': submitted && f.referal_points.errors }">
                          <div *ngIf="submitted && f.referal_points.errors" class="invalid-feedback">
                            <div *ngIf="f.referal_points.errors.required">
                              Refferal Points is
                              required
                            </div>
                          </div>
                        </div>

                        <div class="form-check" >
                          <input class="form-check-input"  formControlName="useReferralPoint" id="useReferralPoint"
                            type="checkbox" value="referalPointTrue">
                            <label class="pt-1">Use referral points in checkout *</label>
                        </div>

                        <div class="form-group">
                          <label>Course Checkout Point *</label>
                          <input type="number" formControlName="checkout_points" class="form-control selact_"
                            placeholder="Enter Course checkout point"
                            [ngClass]="{ 'is-invalid': submitted && f.checkout_points.errors }">
                          <div *ngIf="submitted && f.checkout_points.errors" class="invalid-feedback">
                            <div *ngIf="f.checkout_points.errors.required">
                              Course Checkout Points is
                              required
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <label>Discount Type *</label>
                          <select formControlName="discount_type" class="form-control select2 selact_"
                              style="width: 100%;"
                              [ngClass]="{ 'is-invalid': submitted && f.discount_type.errors }">
                              <option value="">--Select Discount Type--</option>
                              <option value="1">Amount</option>
                              <option value="2">Percentage</option>
                          </select>
                          <div *ngIf="submitted && f.discount_type.errors" class="invalid-feedback">Discount Type is
                            required
                              <!-- <div *ngIf="f.discount_type.errors.">
                              </div> -->
                          </div>
                      </div>

                        <div class="form-group">
                          <label>Discount Amount *</label>
                          <input type="number" formControlName="discount_amount" class="form-control selact_"
                            placeholder="Enter Discount Amount"
                            [ngClass]="{ 'is-invalid': submitted && f.discount_amount.errors }">
                          <div *ngIf="submitted && f.discount_amount.errors" class="invalid-feedback">
                            <div *ngIf="f.discount_amount.errors.required">
                              Discount Amount is
                              required
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <label>Minimum Course Amount *</label>
                          <input type="number" formControlName="minimum_course_amount" class="form-control selact_"
                            placeholder="Enter Minimum Course Amount"
                            [ngClass]="{ 'is-invalid': submitted && f.minimum_course_amount.errors }">
                          <div *ngIf="submitted && f.minimum_course_amount.errors" class="invalid-feedback">
                            <div *ngIf="f.minimum_course_amount.errors.required">
                              Minimum Course Amount is
                              required
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>

                <div class="clearfix"></div>
                <span class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Save</button>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

    <!-- /.modal -->



  </section>
  <!-- /.content -->
</div>