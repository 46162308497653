import { Component, OnInit, ViewChild } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PopupService } from 'src/app/services/popup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { Page } from 'src/app/models/pageDto';

@Component({
  selector: 'app-list-eligibility-test',
  templateUrl: './list-eligibility-test.component.html',
  styleUrls: ['./list-eligibility-test.component.css']
})
export class ListEligibilityTestComponent implements OnInit {

  @ViewChild('myTable') table: ListEligibilityTestComponent;
  session_id: any;
  searchTerm: any = '';
  selected = [];
  temp = [];
  eligibilityList: any = [];
  page: Page;
  deleteList: any = [];
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService, private ToasterService: ToasterService) { }
  eligibilityListForm: FormGroup;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.eligibilityListForm = this.formBuilder.group({
      search: ['']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    this.setPage({ offset: 1 });
    //this.init();
  }

  onSearch() {
    this.searchTerm = this.eligibilityListForm.get('search').value.toString();
    this.setPage({ offset: 1 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  getNumber(data): any{
    return Number.parseInt(data);
  }

  setPage(pageInfo) {
    debugger
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.eligibilityList = [];
    var course_fetch_url = "getEligibilitytests?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    if (this.searchTerm.length > 0)
      course_fetch_url += "&searchTerm=" + this.searchTerm;
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
    //  debugger
      if (data.status == "true" && data.eligibility_tests[0]) {
        this.temp = data.eligibility_tests;
        this.eligibilityList = data.eligibility_tests;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSelect({ selected }) {

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  deleteCourse(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var body = { "eligibility_test_id": +id };
        var del_url = "delete_eligibility_test?eligibility_test_id=" + `${id}`;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          debugger
          if (data.status == "TRUE") {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'success');
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }

  deleteMultipleItem(){
    debugger
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteList.push(element.id);
      });
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          var body = { "ids": this.deleteList };
          var del_url = "eligibility_test_multi_delete";
          this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
            debugger
            this.deleteList = [];
            if (data.status == "True") {
              this.spinner.hide();
              this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
              this.setPage({ offset: 0 });
            }
            else {
              this.spinner.hide();
              this.popup.failureMessage = data.message
              this.popup.failurepopup()
            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
  
                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            }); 
        }else{
          this.deleteList = [];
        }
      })
    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    } 
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || status_name;
    });

    this.eligibilityList = temp;
  }

  editTest(id) {

    this.router.navigate(['/admin/eligibility-test/edit/'+id]);

  }
}
