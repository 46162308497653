import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BatchesRoutingModule } from './batches-routing.module';
import { BatchEditComponent } from './batch-edit/batch-edit.component';
import { BatchListComponent } from './batch-list/batch-list.component';
import { BatchAddComponent } from './batch-add/batch-add.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";


@NgModule({
  declarations: [BatchEditComponent, BatchListComponent, BatchAddComponent],
  imports: [
    CommonModule,
    BatchesRoutingModule,
    NgxDatatableModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule

  ]
})
export class BatchesModule { }
