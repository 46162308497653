<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li  class="breadcrumb-item active">Change Password</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>Change Password</h1>

      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" name="myform" id="myForm">
                <div class="col-md-6 float-left">
                  <div class="form-group">
                    <label>Current Password</label>
                    <input type="password" formControlName="currentPwd" class="form-control selact_"
                           placeholder="Enter Current Password" [ngClass]="{ 'is-invalid': isSubmitted && g.currentPwd.errors }">
                    <div *ngIf="isSubmitted && g.currentPwd.errors" class="invalid-feedback">
                      <div *ngIf="g.currentPwd.errors.required">
                        Current Password is
                        required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>New Password</label>
                    <input type="password" formControlName="newpassword" class="form-control selact_"
                           placeholder="Enter New Password" [ngClass]="{ 'is-invalid': isSubmitted && g.newpassword.errors }">
                    <div *ngIf="isSubmitted && g.newpassword.errors" class="invalid-feedback">
                      <div *ngIf="g.newpassword.errors.required">
                        New Password is
                        required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Confirm New Password</label>
                    <input type="password" formControlName="confirmpassword" class="form-control selact_"
                           placeholder="Enter Confirm Password" [ngClass]="{ 'is-invalid': isSubmitted && g.confirmpassword.errors }">
                    <div *ngIf="isSubmitted && g.confirmpassword.errors" class="invalid-feedback">
                      <div *ngIf="g.confirmpassword.errors.required">
                        Confirm New Password is
                        required
                      </div>
                      <div *ngIf="g.confirmpassword.errors.mustMatch==true">
                        Please make sure your password match
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="form-group">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>>
