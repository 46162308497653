import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-mycourses',
  templateUrl: './mycourses.component.html',
  styleUrls: ['./mycourses.component.css']
})
export class MycoursesComponent implements OnInit {

  constructor(public api: ApiService, private spinner: NgxSpinnerService, private LocaldataService: LocaldataService, private router: Router, private ToasterService: ToasterService, private constantService: ConstantsService) { }

  userId: any;
  myCourseDetails: any =[];
  session_id: any;
  s3FilePath: any;

  ngOnInit(): void {   
    this.s3FilePath = this.constantService.s3FilePath;
    this.userId = localStorage["user_Id"];
    this.session_id = localStorage.getItem('access_token');
    if (!this.userId) {
      this.router.navigate(['/login'])
    } else {
      this.getmycourses();
    }
   
  }

  getmycourses() {
      this.spinner.show(); 
      let param = { user_id: this.userId };
      let mycourse_fetch_url = 'my_courses';
      this.api.detailsUploadFetch(param, mycourse_fetch_url, this.session_id).subscribe((data: any) => {
        if (data.status == "true" && data.courses) { 
          this.myCourseDetails = data.courses;
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'warning');
        }
      }, (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          //localStorage.clear()
          this.router.navigate([''])
        }
      });   
  }
  courseOverview(myCourseDetail){
    debugger 
    this.router.navigate(['mycourses/overview/detail/'+ myCourseDetail.enrollment_id])
  }
}
