import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service'
import { Validatespace } from "src/app/validation/spacevalidation";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-testimonial-add',
  templateUrl: './testimonial-add.component.html',
  styleUrls: ['./testimonial-add.component.css']
})
export class TestimonialAddComponent implements OnInit {
  selectedFile: File;
  isEdit: boolean = false;
  session_id: any;
  imageSrc: any;
  testimonialId: any;
  fileName = '';
  p: number = 1;
  s3FilePath: string;
  s3FileName: any;

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService, private uploadService: UploadService, private constantService: ConstantsService, private title: Title, private meta: Meta,) { }
  testimonialForm: FormGroup;
  submitted = false;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;

    this.testimonialForm = this.formBuilder.group({
      title: ['', [Validators.required, Validatespace]],
      status: ['', Validators.required],
      description: ['', [Validators.required, Validatespace]],
      fileData: ['', Validators.required]
    });
    this.testimonialForm.get('status').setValue('1');
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.selectedFile = null;
        this.testimonialId = params.dist;
        this.spinner.show();

        var single_url = "edit_testimonial?testimonial_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          debugger
          console.log(data)
          if (data.status == "true" && data.testimonial[0]) {
            this.spinner.hide();

            this.testimonialForm.get('title').setValue(data.testimonial[0].title);
            this.testimonialForm.get('description').setValue(data.testimonial[0].description);
            this.testimonialForm.get('status').setValue(data.testimonial[0].status_id);
            this.testimonialForm.get('fileData').setValidators(null);
            this.testimonialForm.get('fileData').setValue("");
            this.imageSrc = this.s3FilePath + data.testimonial[0].image;
            console.log(this.testimonialForm);
            console.log(this.testimonialForm);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
        this.title.setTitle("Edit Testimonial | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Edit Testimonial | Admin",
        });
      }
      else {
        this.isEdit = false;
        this.spinner.hide();

      }
    }
    );
  }



  get f() { return this.testimonialForm.controls; }
  onFileChanged(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      console.log(this.selectedFile);

      this.selectedFile = event.target.files[0];
      console.log(this.selectedFile.name);
      let imagePath_ = this.selectedFile.type;
      var ext = imagePath_.split('/');
      if (ext[0] == "image") {

        reader.onload = () => {

          this.imageSrc = reader.result as string;


        }
        reader.readAsDataURL(this.selectedFile);
        console.log(this.selectedFile)
      } else {
        this.testimonialForm.get('fileData').setValue('');
        this.testimonialForm.get('fileData').setErrors({ required: true });
        this.imageSrc = ""
      }


    }
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.f)

    if (this.testimonialForm.invalid) {
      return;
    }
    this.spinner.show();
    const uploadData = new FormData();
    uploadData.append('title', this.f.title.value);
    uploadData.append('description', this.f.description.value);
    uploadData.append('status_id', this.f.status.value);
    if (this.selectedFile == null) {
      uploadData.append('image', this.imageSrc.replace(this.s3FilePath, ''));
      this.addEditSubmit(uploadData);
    }
    else {
      this.uploadService.fileUpload(this.selectedFile, 'testimonial').then(res => {
        if (res) {
          this.s3FileName = res
          uploadData.append('image', this.s3FileName);
          this.addEditSubmit(uploadData);
        }
      });
    }
  }


  addEditSubmit(uploadData) {
    var fetch_url = null;
    if (this.isEdit) {
      fetch_url = "update_testimonial";
      uploadData.append('testimonial_id', this.testimonialId);
    }
    else {
      fetch_url = "add_testimonial";
    }
    this.api.detailsUploadFetch(uploadData, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      console.log(data)
      if (data.status == "True") {
        this.imageSrc = "";
        this.testimonialForm.reset();
        this.submitted = false;
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        this.router.navigate(['admin/testimonial'])

      }
      else {
        this.spinner.hide();

      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });


  }

}
