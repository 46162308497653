import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from "moment";

@Component({
  selector: 'app-referral-point',
  templateUrl: './referral-point.component.html',
  styleUrls: ['./referral-point.component.css']
})
export class ReferralPointComponent implements OnInit {

  session_id: any;
  referralDetail: any;
  currentCopyUrlIndex = -1;

  constructor(public api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private rote: ActivatedRoute,) { }

  ngOnInit(): void {
    this.session_id = localStorage.getItem('access_token');
    this.getPageData();
    //getPoints
  }

  async getPageData() {

    var thread_url = 'getPoints';

    await this.api.detailsGetFetch(thread_url, this.session_id).subscribe(
      (data: any) => {
        if (data.status == 'True' && data.referrals) {
          debugger;
          this.referralDetail = data.referrals;
          for(let item of this.referralDetail.users){
            item.date =  moment(item.date + '+00:00').local().format("DD-MM-YYYY hh:mm A");
          }
          this.spinner.hide();

        } else {
          this.spinner.hide();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear();
          this.router.navigate(['']);
        }
      }
    );
  }

  onCopyUrl(link, index) {
    if (this.currentCopyUrlIndex != -1) {
      let oldDom = document.getElementById("" + this.currentCopyUrlIndex);
      if (oldDom) {
        oldDom.innerHTML = "Copy Referral Code";
      }
    }
    let newDom = document.getElementById(index);
    if (newDom) {
      newDom.innerHTML = "Copied";
    }
    this.currentCopyUrlIndex = index;
    var dummy = document.createElement("input"),
      text =  link;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

}
