<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item active"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <section class="content">
    <div class="card elevation-0" *ngIf="dashboardData">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="coun_dash_box1 coun_dash_box_b mb-2" (click)="goToCourses()">
              <div class="coun_dasBox_left p-2">
                {{dashboardData.no_of_courses}}
              </div>
              <div class="coun_dasBox_grht">
                <i class="fas fa-swatchbook"></i>
                <!--<img src="assets/web/assets/images/syllabus.png" alt="">-->
              </div>
              <div class="coun_dasBox_btm">Number of Courses</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="coun_dash_box1 coun_dash_box_g mb-2" (click)="goToEnrollments()">
              <div class="coun_dasBox_left p-2">
                {{dashboardData.active_enrollments}}
              </div>
              <div class="coun_dasBox_grht">
                <i class="fas fa-book-reader"></i>
              </div>
              <div class="coun_dasBox_btm">Total Enrollments </div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="user_role != 'sme'">
            <div class="coun_dash_box1 coun_dash_box_g mb-2" (click)="goToStudents()">
              <div class="coun_dasBox_left p-2">
                {{dashboardData.total_no_of_students}}
              </div>
              <div class="coun_dasBox_grht">
                <i class="fas fa-user-graduate"></i>
              </div>
              <div class="coun_dasBox_btm"> Number of Students </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="coun_dash_box1 coun_dash_box_r mb-2" (click)="goToNotifications()">
              <div class="coun_dasBox_left p-2">
                {{dashboardData.notification_count}}
              </div>
              <div class="coun_dasBox_grht">
                <i class="fas fa-bell"></i>
              </div>
              <div class="coun_dasBox_btm">Notifications </div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="user_role != 'sme'">
            <div class="coun_dash_box1 coun_dash_box_normal mb-2" (click)="goToSME()">
              <div class="coun_dasBox_left p-2">
                {{dashboardData.total_no_of_instructors}}
              </div>
              <!-- <div class="coun_dasBox_grht">
                <i class="fas fa-book-open"></i>
              </div> -->
              <div class="coun_dasBox_btm">Instructors </div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="user_role != 'sme'">
            <div class="coun_dash_box1 coun_dash_box_normal mb-2">
              <div class="coun_dasBox_left p-2">
                {{dashboardData.course_purchase_current_month}}
              </div>
              <!-- <div class="coun_dasBox_grht">
                <i class="fas fa-atlas"></i>
              </div> -->
              <div class="coun_dasBox_btm">Purchase Current Month </div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="user_role != 'sme'">
            <div class="coun_dash_box1 coun_dash_box_normal mb-2">
              <div class="coun_dasBox_left p-2">
                {{dashboardData.no_of_support_enquiries}}
              </div>
              <!-- <div class="coun_dasBox_grht">
                <i class="fas fa-atlas"></i>
              </div> -->
              <div class="coun_dasBox_btm">Number of Support Enquiries </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div  class="dashboard_table">
    <table class="table table-bordered">
      <tr>        
        <th>Course</th>
        <th>Batch</th>
        <th>Enrolled Candidates</th>
       
      </tr>
      <tr *ngFor="let data of tableData">
        <td>{{ data.course}}</td>
        <td>{{ data.batch}}</td>
        <td>{{ data.enrolled_students }}</td>
       
        
      </tr>
      
    </table>

  </div>
</div>
