<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/discussion-forum']">Discussion Forums</a></li>
            <li class="breadcrumb-item active">View Forum</li>

          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>View Forum</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <!-- <form [formGroup]="viewForm" > -->
              <form>
                <div class="col-md-6 float-left">
                  <div class="profile_img rounded-circle float-left">
                    <img src="{{s3FilePath}}{{profilePic}}" alt="name">
                </div>
                  <div class="form-group">
                    <h1>{{title}}</h1>
                    <!-- <input type="text" formControlName="name" class="form-control selact_"> -->
                  </div>
                  <div class="form-group">
                    <p>{{description}}</p>
                    <!-- <label>Tite</label>
                                  <input type="text" formControlName="title" class="form-control selact_"> -->
                  </div>
                  <!-- <ng-container *ngFor="let comment of comments">
                    <div class="form-group">
                      <p>Comment: {{comment.comment}}</p>
                     
                    </div>
                    <div>
                      <p>By: {{comment.comment_by}}</p>
                      <p>Time: {{comment.comment_time}}</p><br>
                     
                    </div>
                  </ng-container> -->
                </div>

                <div class="replays_list_cont">
                  <div class="clearfix"></div>
                  <div class="pt-3 pb-3">
                      <h5>{{comments.length}} comments found.</h5>
                  </div>
                  <div class="clearfix"></div>
                  <ng-container *ngFor="let comment of threadCommentLimitedList">
                      <div class="thred_replay list-group-item">
                          <ng-container *ngIf="correspondingEditCommentId === comment.id">
                              <form class="form-signup" [formGroup]="threadCommentEditFormGroup"
                                  (ngSubmit)="threadCommentEditAction()">
                                  <ng-container *ngIf="commentDetails">
                                      <div class="form-label-group mb-2">
                                          <textarea id="comments" formControlName="comment" cols="30"
                                              rows="3" class="form-control"></textarea>

                                          <div *ngIf="isCommentUpdateSubmitted && threadCommentEditFormGroup.controls.comment.errors"
                                              class="invalid-feedback">
                                              <div
                                                  *ngIf="threadCommentEditFormGroup.controls.comment.errors.required">
                                                  comment is
                                                  required
                                              </div>
                                          </div>
                                      </div>
                                      <button
                                          class="btn btn-sm btn-outline-secondary p-2 mt-2 float-right text-uppercase"
                                          type="submit">Update</button>
                                  </ng-container>
                              </form>
                          </ng-container>
                          <ng-container *ngIf="correspondingEditCommentId != comment.id">
                              <p>{{comment.comment}}</p>
                          </ng-container>
                            <div class="chat_ib float-right col-8 py-3 pl-0">
                               
                                  <span class="float-right delete_comment" (click)="onThreadCommentDelete(comment.id)"> <i class="far fa-trash-alt mr-1"></i> Delete
                                  </span>
                                <ng-container *ngIf="userId == comment.comment_by_id">
                                  <span class="float-right mr-2 edit_comment" *ngIf="correspondingEditCommentId != comment.id"
                                      (click)="onThreadCommentEdit(comment.id)"><i class="far fa-edit mr-1"></i> Edit
                                  </span>
                              </ng-container>
                           </div>
                           <div class="clearfix"></div>
                           <div class="col-md-12 float-left px-0 pt-2">
                          <div class="profile_img rounded-circle">
                              <img src="{{s3FilePath}}{{comment.comment_user_image}}">
                          </div>
                          <div class="chat_ib float-left pl-3">
                              <h5>{{comment.comment_by}}</h5>
                              <!-- <span class="time_date"> {{ comment.comment_time }} |
                                  {{ comment.comment_date | date }}</span> -->
                                  <span class="time_date"> {{ comment.utc_created_at.date }} </span>

                                  

                          </div>
                      </div>

                      </div>

                  </ng-container>
                  <div>
                      <span class="moreaboutus py-3 col-md-12 float-left">
                          <a href="JavaScript:Void(0);" class="view_more_button btn btn-outline-secondary btn-sm float-right" (click)="threadCommentViewMore()"
                              *ngIf="threadCommentViewMoreButtonActive">
                              View More
                          </a>
                      </span>
                  </div>
                  <div class="clearfix"></div>
              </div>
              <div class="clearfix"></div>


              </form>
            </div>
            <div class="write_replay">
              <h5>Comments</h5>
              <form class="form-signup" [formGroup]="threadCommentAddFormGroup" (ngSubmit)="threadCommentAddAction()">
                <div class="form-label-group mb-2">
                  <textarea id="comments" formControlName="comment" cols="30" rows="3" class="form-control"
                    placeholder="Comments"></textarea>

                  <div *ngIf="isCommentAddSubmitted && threadCommentAddFormGroup.controls.comment.errors"
                    class="invalid-feedback">
                    <div *ngIf="threadCommentAddFormGroup.controls.comment.errors.required">
                      comment is
                      required
                    </div>
                  </div>
                </div>
                <button class="btn btn btn-dark btn-primary float-right text-uppercase" type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
