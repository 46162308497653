<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 pl-0">
            <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
            <li class="breadcrumb-item active">Notifications</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<section class="sub_banner team_banner text-left">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>Notifications</h1>
      </div>
    </div>
  </div>
</section>

<section class="courses_pages notification_page">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-info alert-dismissible fade show mb-2" role="alert" *ngFor="let item of notifications">
         {{item.notification}}

          <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="readNotification(item.id)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="clearfix"></div>
      </div>
      <!-- <div class="col-md-12">
        <a href="#" class="btn btn-sm float-right view_more_note">+2 More Notification</a>

      </div> -->

    </div>
  </div>
</section>
