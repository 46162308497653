<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item active">Enrollments</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="card">
        <div class="card-header">
          <h1>Enrollment Score</h1>
          <ng-container *ngIf="!!csvData">
            <a csvLink [data]="csvData" filename="Enrollment Score" class="btn btn-secondary ml-2 btn-dark float-right add_country">Export</a>
          </ng-container>
        </div>
        <form [formGroup]="CourseListForm" class="form-inline filter_list">
          <div class="card-body pb-0">
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <select class="form-control select2  ml-1" formControlName="course" (change)="getDownloadData()" style="width:100%;">
                <option value="">Select Course</option>
                <option *ngFor="let items of courseList" [value]="items.id">{{items.name}}</option>
              </select>
            </span>
            
          </div>
        </form>
        
        
  
        
  
  
  
        
        
      </div>
      <!-- /.card -->
    </section>
    <!-- /.content -->
  </div>

