<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Courses</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="sub_banner team_banner text-left">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>{{title}}</h1>
      </div>
    </div>
  </div>
</section>
<section class="courses_pages">
  <div class="container">
    <div class="row">

      <ng-container *ngIf="courses.length==0">
        <div class="col-md-12 text-center mt-5 mb-3 ng-star-inserted"><img src='../../../assets/web/assets/images/no_data/no_courses.png'
            class="img-fluid mt-5" alt="no data"> </div>
      </ng-container>
      <ng-container *ngIf="courses.length > 0">
        <ng-container *ngFor="let course of courses">
          <div class="col-lg-4 col-md-6 mb-2 float-left"> <a class="card shadow-sm courses_card">
              <div class="image_cou" (click)="courseSelect(course)">
                <div class="price rounded" *ngIf="course.subscription_type_id == 2">
                  <!-- <img src="{{course.image}}">  -->
                  {{course.currency_label}} {{course.cost}}</div>
                <div class="price rounded" *ngIf="course.subscription_type_id == 1">Free</div>
                <img src="{{s3FilePath}}{{course.image}}"
                  onerror="this.src = '../../../../assets/web/assets/images/courses-01.svg'" class="card-img-top"
                  alt="c">
              </div>
              <div class="card-body" (click)="courseSelect(course)">
                <h2>{{course.name}}</h2>
                <p>{{course.short_description}}</p>
              </div>
              <div class="course_in" (click)="smeSelect(course)">{{course.sme_name}}</div>
            </a> </div>
        </ng-container>
      </ng-container>

      <div class="clearfix"></div>
      <div class="col-md-12" *ngIf="courses.length > 0">
        <nav aria-label="Page navigation" class="float-right">
          <ul class="pagination">
            <li class="page-item"> <a class="page-link" (click)="previousPageSelect()" aria-label="Previous"> <span
                  aria-hidden="previousShow">&laquo;</span> <span class="sr-only">Previous</span> </a> </li>
            <ng-container *ngFor="let number of numberpages">
              <li class="page-item" id="pages{{number}}"><a class="page-link"
                  (click)="pageselect(number)">{{number}}</a></li>
            </ng-container>
            <li class="page-item"> <a class="page-link" (click)="nextPageSelect()" aria-label="Next"> <span
                  aria-hidden="nextShow">&raquo;</span> <span class="sr-only">Next</span> </a> </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</section>
