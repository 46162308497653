// import { Component, OnInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toaster.service';
import { EligibilityTestComponent } from '../eligibility-test/eligibility-test.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentComponent } from '../payments/payment/payment.component';
import { ConstantsService } from '../../../services/constants.service';
import { EMPTY, Subscription } from "rxjs";

const LOGIN: string = 'Log In To Enroll';
const ENROLL_NOW: string = 'Enroll Now';
const ELIGIBILITY_TEST: string = 'Take a test to enroll';

@Component({
  selector: 'app-courses-details',
  templateUrl: './courses-details.component.html',
  styleUrls: ['./courses-details.component.css']
})
export class CoursesDetailsComponent implements OnInit {

  constructor(
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private rote: ActivatedRoute,
    private ToasterService: ToasterService,
    public dialog: MatDialog,
    private constantService: ConstantsService) { }

  course_slug: any;
  about_course: any = this.getaboutcourse();;
  course: any = this.getcourseObj();
  instructor: any = this.getinstructorObj();
  syllabuses: any = this.getsyllabusesObj();
  currencyId: null;
  priceData: any;
  subscribeLocaldataService: any;
  batchs: any;
  selectedBatchId: any;
  userId: any;
  enrollButton: string;
  session_id: any;
  courseDescription: string;
  s3FilePath: string;
  clickEventsubscription: Subscription;
  smeDescription: any;

  getaboutcourse() {
    return { course_overview: null, course_structure_description: null, description: null, learn_from_course: null, short_description: null };
  }
  getcourseObj() {
    return { course_id: null, slug: null, course_name: null, learning_type: null, subscription_type: null, category_id: null, course_category: null, sme_name: null, batch: [{ batch_id: null, start_date: null, end_date: null }], cost: [{ currency_id: null, amount: null }], duration: null, image: null };
  }
  getinstructorObj() {
    return { profile_picture: null, sme_degree: null, sme_id: null, sme_name: null };
  }
  getsyllabusesObj() {
    return { course_objectives: null }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.LocaldataService.updateUser();
    this.session_id = localStorage.getItem('access_token');
    this.course_slug = this.rote.snapshot.paramMap.get("course_slug");
    this.userId = localStorage["user_Id"];
    this.s3FilePath = this.constantService.s3FilePath;
    if (!this.userId) {
      this.enrollButton = LOGIN
    }
    this.getCourseDetails();
    let currency = this.LocaldataService.getcurrencyInfo();
    this.currencyId = currency.id
    this.subscribeLocaldataService = this.LocaldataService.CurrencyChange.subscribe(selectedData => {
      this.currencyId = selectedData.id
    });
    this.clickEventsubscription = this.LocaldataService.elegibiltyTestPopUpCloseUpdate().subscribe(() => {
      this.getCourseDetails();
    })
  }

  ngOnDestroy() {
    // You can also do whatever you need here
    this.subscribeLocaldataService.unsubscribe();
  }

  getCourseDetails() {
    var list_fetch_url = "student/get_course";
    let param = { slug: this.course_slug, user_id: this.userId };
    this.api.postDetails(list_fetch_url, param).subscribe(async (data: any) => {
      debugger
      if (data.status == "true" && data.contents) {
        this.about_course = data.contents.about_course;
        this.course = data.course[0];
        debugger
        this.instructor = data.contents.instructor;

        var str = this.instructor.sme_description;
        var re = /\n/gi; 
        var newstr = str.replace(re, "<br />"); 
        console.log(newstr)
        this.smeDescription = newstr;

        this.syllabuses = this.s3FilePath + data.contents.syllabus;
        debugger
        this.batchs = data.course[0].batch;
        this.courseDescription = data.contents.about_course.description;
        if (this.course.learning_type === 1 && this.batchs.length > 0) {
          this.selectedBatchId = this.batchs[0].batch_id;
        } else {
          this.selectedBatchId = 0
        }

        if (this.course.subscription_type === 2) {
          this.priceData = await this.course.cost.filter((x) => x.currency_id === this.currencyId)
        }
        debugger
        if (this.userId && this.course.is_eligibilitytest) {
          this.enrollButton = ELIGIBILITY_TEST;
        } else if (this.userId) {
          this.enrollButton = ENROLL_NOW;
        }
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate([''])
      }
    });

  }

  batchchange(batch) {
    this.selectedBatchId = batch.batch_id;
  }
  enrollNow() {
    debugger
    if (this.userId) {
      if (this.course.is_eligibilitytest) {
        const dialogRef = this.dialog.open(EligibilityTestComponent, {
          width: '1400px',
          data: { title: ELIGIBILITY_TEST, course_id: this.course.course_id }
        });

        dialogRef.afterClosed().subscribe(result => {
          debugger
          if (result) {
            if (this.course.subscription_type === 1) { // free course
              this.enrollButton = ENROLL_NOW;
              this.course.is_eligibilitytest = false;
            } else if (this.course.subscription_type === 2) { // paid course
              this.enrollButton = ENROLL_NOW;
              this.course.is_eligibilitytest = false;
              const dialogRefpayment = this.dialog.open(PaymentComponent, {
                width: '1400px',
                data: { title: ELIGIBILITY_TEST, PymentId: this.course.course_id }
              });

              dialogRefpayment.afterClosed().subscribe(result => {
                debugger
              });
            }
          }
        });

        // this.router.navigate(['course/eligibilityTest/' + this.course.course_id]);
      } else if (this.course.subscription_type === 1) {
        this.enroll();
      } else if (this.course.subscription_type === 2) {
        this.router.navigate(['/course/details/' + this.course.course_id + '/' + this.selectedBatchId + '/' + this.priceData[0].course_curency_price_id])

        // this.onstaMojoTrigger = true;
        // this.loadingUrl = "https://test.instamojo.com/@sarathc_seeroo/";
        // console.log("---Loading--URl---", this.loadingUrl);

        // const dialogRefpayment = this.dialog.open(PaymentComponent, {
        //   width: '1400px',
        //   data: { title: ELIGIBILITY_TEST, PymentId: this.course.course_id }
        // });

        // dialogRefpayment.afterClosed().subscribe(result => {
        //   debugger
        // });

      }
    } else {
      this.router.navigate(['/login/course/' + this.course_slug])
    }
  }

  enroll() {
    this.spinner.show();
    var list_fetch_url = "enroll";
    let param = { user_id: this.userId, course_id: this.course.course_id, batch_id: this.selectedBatchId };
    this.api.detailsUploadFetch(param, list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data) {
        this.ToasterService.openSnackBar(data.message, '', 'success');
        this.spinner.hide();
        this.router.navigate(['mycourses'])
      }
      else {
        this.ToasterService.openSnackBar(data.message, '', 'warning');
        this.getCourseDetails();
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['/login'])
      }
    });
  }
  goToCourse() {
    debugger
    this.router.navigate(['mycourses/overview/detail/' + this.course.enrollment_id])

  }

}

export interface DialogDataEligibilityTest {
  title: string;
  course_id: any;
}

export interface DialogDataPayment {
  title: string;
  PymentId: any;
}
