import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-live-class',
  templateUrl: './view-live-class.component.html',
  styleUrls: ['./view-live-class.component.css']
})
export class ViewLiveClassComponent implements OnInit {

  viewLiveClassForm: FormGroup;
  Id: any;
  session_id: any;
  liveClassDetails: any;
  batches: any;
  constructor( public fb: FormBuilder, private router: Router, public api: ApiService, public spinner: NgxSpinnerService, public route: ActivatedRoute ) { }

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.viewLiveClassForm = this.fb.group({
      courseName: [''],
      classLink: [''],
      comment: [''],
      batchStartDate: [''],
      createdDate: ['']
    });

    this.spinner.show();
    this.route.params.subscribe(params =>{
      this.Id = params.Id;
    });
    let url = "student_live_url_send_list?id=" + this.Id;
    this.api.detailsGetFetch(url,this.session_id ).subscribe((data: any)=>{
      if(data.status == "true" && data.send_live_url_data){
        debugger
        this.spinner.hide();
        this.viewLiveClassForm.get('courseName').setValue(data.send_live_url_data[0].course_name);
        this.viewLiveClassForm.get('classLink').setValue(data.send_live_url_data[0].live_url);
        this.viewLiveClassForm.get('comment').setValue(data.send_live_url_data[0].comments);
        let batchStartDate = data.send_live_url_data[0].batch_start_dates
        this.batches = batchStartDate.join(',');
        this.viewLiveClassForm.get('batchStartDate').setValue(this.batches);
        this.viewLiveClassForm.get('createdDate').setValue(data.send_live_url_data[0].created_at);

      }
    },(err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    
    });
  }

}
