<section class="bread_sec shadow-sm">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 pl-0">
              <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
              <li class="breadcrumb-item active">Support Enquiry</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  <section class="sub_banner team_banner text-left">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Support Enquiry</h1>
        </div>
      </div>
    </div>
  </section>
  
  <section class="courses_pages notification_page">
    <div class="container">
      <div class="row">
        
        <div class="col-md-12">
            <form [formGroup]="supportformGroup" (ngSubmit)="onSubmit()">
          <!-- <div class="alert alert-info alert-dismissible fade show mb-2" role="alert" >
  
            
          </div> -->
          <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6">
            <label>Title *</label>
            <input type="text" id="name" class="form-control" formControlName="title" placeholder="Title"
              required>
            <div *ngIf="issubmitted && supportformGroup.controls.title.errors" class="error_signup">
              <div *ngIf="issubmitted && supportformGroup.controls.title.errors.required"> Title is required.
              </div>
              <div *ngIf="issubmitted && supportformGroup.controls.title.errors.pattern"> Invalid Title. </div>
              <div *ngIf="issubmitted && supportformGroup.controls.title.errors.validspace"> Invalid Title. </div>
              <div *ngIf="issubmitted && supportformGroup.controls.title.errors.maxlength"> Invalid Title. </div>
            </div>
          </div>

          <div class="form-label-group mb-2  col-sm-12 col-md-6 col-lg-6">
            <label>Message *</label>
            <textarea id="address" name="address" cols="30" rows="3" class="form-control" placeholder="Message"
              formControlName="message"></textarea>
            <div *ngIf="issubmitted && supportformGroup.controls.message.errors" class="error_signup">
              <div *ngIf="issubmitted && supportformGroup.controls.message.errors.required"> Message is required.
              </div>
              <div *ngIf="issubmitted && supportformGroup.controls.message.errors.validspace"> Invalid Message.
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <button class="btn btn-dark sign_up_submit btn-lg btn-primary float-right text-uppercase"
              type="submit">Submit</button>
          </div>
  
          <div class="clearfix"></div>
        </form>
        </div>
        
  
      </div>
    </div>
  </section>
  