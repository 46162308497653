import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from '../../../models/pageDto';
import { ToasterService } from 'src/app/services/toaster.service';


@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  @ViewChild('myTable') table: CoursesComponent;
  session_id: any;
  searchTerm: any = '';
  selected = [];
  temp = [];
  courseList: any = [];
  page: Page;
  deleteList: any = [];
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService, private ToasterService: ToasterService) { }
  courseListForm: FormGroup;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.courseListForm = this.formBuilder.group({
      search: ['']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    this.setPage({ offset: 1 });
    //this.init();
  }

  onSearch() {
    this.searchTerm = this.courseListForm.get('search').value.toString();
    this.setPage({ offset: 1 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  setPage(pageInfo) {
    debugger
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.courseList = [];
    var course_fetch_url = "getCourselist?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    if (this.searchTerm.length > 0)
      course_fetch_url += "&searchTerm=" + this.searchTerm;
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.course[0]) {
        this.temp = data.course;
        this.courseList = data.course;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSelect({ selected }) {

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  deleteCourse(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var body = { "course_id": +id };
        var del_url = "delete_course?course_id=" + `${id}`;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          debugger
          if (data.status == "true") {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'success');
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup()
            

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }

  deleteMultipleItem(){
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteList.push(element.id);
      });
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          var body = { "course_ids": this.deleteList };
          var del_url = "delete_multiple_courses";
          this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
            debugger
            this.deleteList = [];
            if (data.status == "true") {
              this.spinner.hide();
              this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
              this.setPage({ offset: 0 });
            }
            else {
              this.spinner.hide();
              this.popup.failureMessage = data.message
              this.popup.failurepopup()
              
  
            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
  
                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            });
  
  
        }else{
          this.deleteList = [];
        }
      })


    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    }
    
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || status_name;
    });

    this.courseList = temp;
  }
  editCourse(id) {
    this.router.navigate(['admin/course/editcourse/' + `${id}`]);

  }

  onStatusChange(courseId ,crrstatus) {
    debugger
    let status_ = 1
    if (crrstatus == 1) {
      status_ = 2
    }
    let value = {
      course_id: courseId,
      status_id: status_
    }
    var url = "publishCourse?course_id="+ courseId +"&status_id=" + status_;
    this.api.detailsUploadFetch(value, url, this.session_id).subscribe((data: any) => {
      debugger

      console.log(data)
      if (data.status == "true") {
        this.spinner.hide();
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        this.setPage({ offset: 0 });
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }
}
