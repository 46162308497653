<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
              <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
              <li class="breadcrumb-item active">Payment</li>
            </ol>
      </div>
    </div>
  </div>
</section>
<div class="container" *ngIf="payment_status == 'Credit'">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="invoice_section mt-5 mb-4">
        <div class="invoice-box">
          <div class="paymentsuccess_sec text-center"> <span> <img
                src="../../../../assets/web/assets/images/payment_success.png" width="90px" height="90px"
                class=" mb-3" /> </span>
            <h2>AWESOME!!! </h2>
            <h3> Your Payment is <span class="psymntsccess"> Successful </span>
            </h3>
            <p> Your Payment has been processed successfully and your
              subscription is confirmed. You can find the invoice in the subscription detail page.</p>
            <div class="clearfix"></div>
            <div class="button_sec  mt-4">
              <a class="paylink bluebutton cour-button" href="Javascript:void(0);" (click)="onContinue()">Continue</a>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="payment_status != 'Credit'">
  <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="invoice_section mt-5 mb-4">
          <div class="invoice-box">
            <div class="paymentsuccess_sec text-center"> 
              <span> <img src="../../../../assets/web/assets/images/payment_failed.png" width="90px" height="90px"  class=" mb-3" /> </span>
              <h2>DONT WORRY, TRY AGAIN!!! </h2>
              <h3> Your Payment is <span class="psymntfaild"> failed </span> </h3>
              <p>Please process the payment again to complete your subscription</p>
            </div>
          </div>
        </div>
      </div>
  </div>
