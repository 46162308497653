import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { stringify } from 'querystring';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-course-payment-status-free-charge',
  templateUrl: './course-payment-status-free-charge.component.html',
  styleUrls: ['./course-payment-status-free-charge.component.css']
})
export class CoursePaymentStatusFreeChargeComponent implements OnInit {

  transaction_id: any;
  session_id: any;
  paymentStatus: boolean;
  loadCompleted: boolean = false;
  errorPage: boolean = false;
  errorData: any;

  constructor(
    private router: Router,
    private rote: ActivatedRoute,
    public api: ApiService,
    private spinner: NgxSpinnerService,
  ) { }

 

  ngOnInit(): void {
    this.spinner.show();
    this.rote.queryParams.subscribe(queryParams => {
      debugger
      this.transaction_id = queryParams.transaction_id;
      this.session_id = localStorage.getItem('access_token');
      console.log(this.session_id);
      let url = "check_fc_payment_status?merchantTxnId=" + this.transaction_id
      var body = { "merchantTxnId": this.transaction_id };
        this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        debugger
        console.log(data);
        if(data.status == "true"){
          if(data.payment_status == "TRUE"){
          debugger
          this.paymentStatus = true;
          this.loadCompleted = true;
          this.spinner.hide();
          // setTimeout(() => {
          //   this.router.navigate(['/mycourses'])
          // }, 5000);
        }else{
          this.paymentStatus = false;
          this.loadCompleted = true;
          this.spinner.hide();
          this.saveError("payment_status false of merchantTxnId: " + this.transaction_id + " in api side, api response message: " + data.message);
        }
      }else{
        this.paymentStatus = false;
        this.loadCompleted = true;
          this.spinner.hide();
          this.saveError("status false of merchantTxnId: " + this.transaction_id + " in api side, api response message: " + data.message);
      }
          
      }, (err: HttpErrorResponse) => {
        debugger
        console.log(err);
        this.saveError("Message: "+ err.message +
        ", Name: "+err.name+
        ", status: "+err.status+
        ", statusText: " +err.statusText+
        ", url: "+ err.url);
        this.spinner.hide();
          localStorage.clear();
          this.router.navigate(['/login']);
        // if (err.status == 403) {
        //   this.spinner.hide();
        //   localStorage.clear()
        //   this.router.navigate(['/login'])
        // }else{
        //   this.errorData = err;
        //   this.errorPage = true;
        //   this.spinner.hide();
        // }
      });
    });
  }

  saveError(error){
    debugger
    let url = "save_error" ;
      var body = { "description": error };
        this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        debugger
        console.log(data);
          if(data.status == "true"){
          debugger
          console.log(data.message);
          
          
        }else{
          console.log(data.message)
          this.spinner.hide();
        }
          
      }, (err: HttpErrorResponse) => {
        debugger
        if (err.status == 403) {
         
        }
      });
  }

  onContinue() { 
    this.router.navigate(['/mycourses'])
  }

}
