import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Title, Meta } from "@angular/platform-browser";
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-category-add',
  templateUrl: './course-category-add.component.html',
  styleUrls: ['./course-category-add.component.css']
})
export class CourseCategoryAddComponent implements OnInit {
  isEdit: boolean = false;
  session_id: any;
  categoryId: any;
  categoryList = [];
  categoryForm: FormGroup;

  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private popup: PopupService,
    private spinner: NgxSpinnerService,
    private title: Title,
    private meta: Meta,) {
    this.categoryForm = this.formBuilder.group({
      parentCategory: [''],
      category: ['', [Validators.required, Validatespace]],
      status: ['', Validators.required],
      description: [''],

    });
  }

  submitted = false;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')


    this.categoryForm.get('status').setValue('1');
    // this.categoryForm.get('parentCategory').setValue("0");

    this.route.params.subscribe(params => {

      if (params.dist) {
        this.isEdit = true;
        this.categoryId = +params.dist;
        this.init();
        this.spinner.show();
        var single_url = "course_category_edit?course_category_id=" + this.categoryId;
        var edit_body = { "course_category_id": this.categoryId };
        this.api.detailsAddFetch(edit_body, single_url, this.session_id).subscribe((data: any) => {

          console.log(data)
          if (data.status == "true" && data.course_category[0]) {
            this.spinner.hide();

            this.isEdit = true;
            // $("html, body").animate({ scrollTop: 0 });
            this.categoryForm.get('parentCategory').setValue(data.course_category[0].parent_id);
            this.categoryForm.get('category').setValue(data.course_category[0].title);
            this.categoryForm.get('status').setValue(data.course_category[0].status_id);
            this.categoryForm.get('description').setValue(data.course_category[0].description);


          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });

        this.title.setTitle("Edit Course Category | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Edit Course Category | Admin",
        });

      }
      else {
        // this.spinner.hide();
        this.title.setTitle("Add Course Category | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Add Course Category | Admin",
        });
        this.isEdit = false;
      }
    }
    );

    if (!this.isEdit) {
      this.init();
    }

  }
  get f() { return this.categoryForm.controls; }
  
  init() {
    this.spinner.show();
    this.categoryList = [];
    var category_fetch_url = "getCoursecategories?sortby=asc&status_id=1";
    if (this.isEdit) {
      category_fetch_url = "getCoursecategories?exclude_children=" + this.categoryId + "&sortby=asc";
    }

    this.api.detailsGetFetch(category_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.course_categories[0]) {
        this.spinner.hide();


        this.categoryList = data.course_categories;
      }
      else {
        this.categoryForm.get('parentCategory').setValue("");
        this.categoryForm.get('parentCategory').setErrors(null);
        this.categoryForm.get('parentCategory').setValidators(null);
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.f)

    if (this.categoryForm.invalid) {
      return;
    }
    if (!this.isEdit) {
      this.spinner.show();


      var body = { "status_id": +this.f.status.value, "title": this.f.category.value, "description": this.f.description.value, "parent_id": this.f.parentCategory.value };
      var url = "add_course_category?title=" + `${this.f.category.value}` + "&status_id=" + `${this.f.status.value}` + "&description=" + `${this.f.description.value}` + "&parent_id=" + `${this.f.parentCategory.value}`;
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {

        console.log(data)
        if (data.status == "True") {
          this.spinner.hide();

          this.categoryForm.reset();


          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup()
          this.router.navigate(['admin/courseCategory'])

        }
        else {
          this.spinner.hide();

          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else {
      if (this.isEdit) {
        this.spinner.show();

        var update_body = { "course_category_id": this.categoryId, "status_id": +this.f.status.value, "title": this.f.category.value, "description": this.f.description.value, "parent_id": this.f.parentCategory.value };
        var update_url = "update_course_category?course_category_id=" + `${this.categoryId}` + "&title=" + `${this.f.category.value}` + "&status_id=" + `${this.f.status.value}` + "&description=" + `${this.f.description.value}` + "&parent_id=" + `${this.f.parentCategory.value}`;
        this.api.detailsAddFetch(update_body, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "True") {
            this.spinner.hide();

            this.categoryForm.reset();
            this.submitted = false;

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/courseCategory'])

          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
  }

}
