import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Page } from 'src/app/models/pageDto';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-enrollment-score',
  templateUrl: './enrollment-score.component.html',
  styleUrls: ['./enrollment-score.component.css']
})
export class EnrollmentScoreComponent implements OnInit {
  @ViewChild('myTable') table: EnrollmentScoreComponent;

  session_id: string;
  selected=[];
  temp=[];
  countryList:any=[];
  courseList:any=[];
  csvData: any[] = [];
  districtList:any=[];
  countryVal:any;
  stateVal: any;
  searchTerm: any = '';
  filterCountry: any = ''
  filterState: any = ''
  page: Page;

  constructor( public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService,) { }
  CourseListForm: FormGroup;  
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.CourseListForm= this.formBuilder.group({
      course: ['']
    });
    
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    this.getCourseList()
    // this.setPage({ offset: 1 });
    // this.getDownloadData()
    
  }
  
  // setPage(pageInfo) {
  //   debugger
  //   this.spinner.show();
  //   this.page.current_page = pageInfo.offset;
  //   this.courseList = [];
  //   var course_fetch_url = "getCourselist?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
  //   if (this.searchTerm.length > 0)
  //     course_fetch_url += "&searchTerm=" + this.searchTerm;
  //   this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {      
  //     debugger
  //     if (data.status == "true" && data.course[0]) {
  //       this.temp = data.course;
  //       this.courseList = data.course;
  //       console.log(this.courseList)
  //       data.pagination.current_page = data.pagination.current_page - 1;
  //       this.page = data.pagination;
  //       this.spinner.hide();
  //     }
  //     else {
  //       this.spinner.hide();
  //     }
  //   }, (err: HttpErrorResponse) => {
  //     if (err.status == 403) {
  //       this.spinner.hide();

  //       localStorage.clear()
  //       this.router.navigate(['admin/login'])
  //     }
  //   });
   
  // }
  getCourseList() {
    debugger
    // console.log(this.enrollmentListForm.value.range)
    this.spinner.show();
    // this.courseList = [];
    var course_fetch_url = "getCourselist";
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.course[0]) {
        this.courseList = data.course;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  } 

  getDownloadData(){
    debugger
    this.spinner.show();

    // this.page.current_page = pageInfo.offset;
    // var course_fetch_url = "getEnrollments?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    // var course_fetch_url = "getEnrollmentExport?course_id="+ this.CourseListForm.value.course;
    
    let param={"course_id":this.CourseListForm.value.course}
    let list_fetch_url = "getEnrollmentExport"
    this.api.detailsAddFetch(param, list_fetch_url, this.session_id).subscribe((data: any) => {
     debugger
      if (data.status == "true" && data.enrollments) {
      
        this.csvData = data.enrollments;
        console.log(this.csvData)
        // this.downLoadData = data.enrollments;
        // for (let i = 0; i < data.enrollments.length; i++) {
        //   debugger
        //   let item = {
        //     name: this.downLoadData[i].name,
        //     course_name: this.downLoadData[i].course_name,
        //     learning_type:this.downLoadData[i].value,
        //     enrolled_date:this.downLoadData[i].enrolled_date,
        //     complete_percentage: this.downLoadData[i].complete_percentage,
        //     status:this.downLoadData[i].status

        //   }
        //   this.downLoadData.push(item)

        // }
        
        // this.csvData = this.downLoadData;
        // console.log(this.csvData);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

}
