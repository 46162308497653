import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Validatespace } from "src/app/validation/spacevalidation";
@Component({
  selector: 'app-category-add',
  templateUrl: './course-element-add.component.html',
  styleUrls: ['./course-element-add.component.css']
})
export class CourseElementAddComponent implements OnInit {
  isEdit: boolean = false;
  session_id: any;
  elementId: any;
  subjectList = [];
  chapterFullList = [];
  chapterFilteredList = [];
  conceptFullList = [];
  conceptFilteredList = [];
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService) { }
  courseElementForm: FormGroup;
  submitted = false;
  courseElementType: any = 1;
  courseElementTypeName: any = 'Subject';
  getCourseElementType(type: any) {
    debugger
    this.courseElementType = type;
    switch (type) {
      case 1: {
        this.courseElementTypeName = 'Subject';
        break;
      }
      case 2: {
        this.courseElementTypeName = 'Chapter';
        this.courseElementForm.controls['subject'].setValidators([Validators.required]);
        break;
      }
      case 3: {
        this.courseElementTypeName = 'Concept';
        this.courseElementForm.controls['subject'].setValidators(Validators.required);
        this.courseElementForm.controls['chapter'].setValidators(Validators.required);
        break;
      }
      case 4: {
        this.courseElementTypeName = 'Module';
        this.courseElementForm.controls['subject'].setValidators(Validators.required);
        this.courseElementForm.controls['chapter'].setValidators(Validators.required);
        this.courseElementForm.controls['concept'].setValidators(Validators.required);
        break;
      }
    }
    this.courseElementForm.controls['subject'].updateValueAndValidity();
    this.courseElementForm.controls['chapter'].updateValueAndValidity();
    this.courseElementForm.controls['concept'].updateValueAndValidity();
  }


  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')

    this.courseElementForm = this.formBuilder.group({
      title: ['', [Validators.required, Validatespace]],
      subject: [''],
      chapter: [''],
      concept: [''],
      status: ['', Validators.required]
    });
    this.init();
    this.spinner.show();
    this.courseElementForm.get('status').setValue("1");

  }
  get f() { return this.courseElementForm.controls; }
  init() {
    this.spinner.show();
    this.getSubjects();
    this.getChapters();
    this.getConcepts();
    debugger
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.id) {
        this.isEdit = true;

        this.elementId = +params.id;
        this.spinner.show();
        var single_url = "getCourseelementById?course_element_id=" + this.elementId;
        //var edit_body = { "course_element_id": this.elementId };

        this.api.detailsGetFetch( single_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "true" && data.course_element[0]) {

            debugger
            this.isEdit = true;
            this.courseElementForm.get('title').setValue(data.course_element[0].title);
            this.courseElementForm.get('status').setValue(data.course_element[0].status_id);

            this.getCourseElementType(data.course_element[0].type_id);
            switch (data.course_element[0].type_id) {

              case 2: {
                var subject = data.parent_details.filter(item => item.type_id == 1);
                this.courseElementForm.get('subject').setValue(subject[0].id);
                this.chapterFilteredList = this.chapterFullList.filter(item => item.parent_id == subject[0].id);
                break;
              }
              case 3: {
                var subject = data.parent_details.filter(item => item.type_id == 1);
                this.courseElementForm.get('subject').setValue(subject[0].id);
                this.chapterFilteredList = this.chapterFullList.filter(item => item.parent_id == subject[0].id);

                var chapter = data.parent_details.filter(item => item.type_id == 2);
                this.courseElementForm.get('chapter').setValue(chapter[0].id);
                this.conceptFilteredList = this.conceptFullList.filter(item => item.parent_id == chapter[0].id);
                break;
              }
              case 4: {
                var subject = data.parent_details.filter(item => item.type_id == 1);
                this.courseElementForm.get('subject').setValue(subject[0].id);
                this.chapterFilteredList = this.chapterFullList.filter(item => item.parent_id == subject[0].id);
                console.log(subject[0].id);

                var chapter = data.parent_details.filter(item => item.type_id == 2);
                this.courseElementForm.get('chapter').setValue(chapter[0].id);
                this.conceptFilteredList = this.conceptFullList.filter(item => item.parent_id == chapter[0].id);
                console.log(chapter[0].id);

                var concept = data.parent_details.filter(item => item.type_id == 3);
                this.courseElementForm.get('concept').setValue(concept[0].id);
                console.log(concept[0].id);
                break;
              }
            }
            this.spinner.hide();
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });

      }
      else {
        this.spinner.hide();
        debugger
        this.route.queryParams.subscribe(queryParams => {
          debugger
          this.getCourseElementType(+queryParams.type_id);
        });
        this.isEdit = false;
      }
    }
    );
  }

  getSubjects() {
    this.subjectList = [];
    var courseElements_fetch_url = "getCourseelements?type_id=1&status_id=1";
    this.api.detailsGetFetch(courseElements_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.course_elements[0]) {
        this.spinner.hide();
        this.subjectList = data.course_elements;
       // this.subjectList =this.subjectList.filter(item => item.id == 90)
       console.log(this.subjectList);
      
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getChapters() {
    this.chapterFullList = [];
    var courseElements_fetch_url = "getCourseelements?type_id=2&status_id=1";
    this.api.detailsGetFetch(courseElements_fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.course_elements[0]) {
        this.spinner.hide();
        this.chapterFullList = data.course_elements;
        console.log(this.chapterFullList);
        
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getConcepts() {
    this.conceptFullList = [];
    var courseElements_fetch_url = "getCourseelements?type_id=3&status_id=1";
    this.api.detailsGetFetch(courseElements_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.course_elements[0]) {
        this.spinner.hide();
        this.conceptFullList = data.course_elements;
        console.log(this.conceptFullList);
        
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSubjectChanged(parentId) {
    this.chapterFilteredList = this.chapterFullList.filter(item => item.parent_id == parentId.target.value);
    
    this.courseElementForm.get('chapter').setValue("");
    this.courseElementForm.get('concept').setValue("");
    this.conceptFilteredList = null;

    // if (this.chapterFilteredList.length == 1) {
    //   this.conceptFilteredList = this.conceptFullList.filter(item => item.parent_id == this.chapterFilteredList[0].id);
    // }
  }
  onChapterChanged(parentId) {
    
    this.conceptFilteredList = this.conceptFullList.filter(item => item.parent_id == parentId.target.value);
    this.courseElementForm.get('concept').setValue("");

  }
  onSubmit() {
    debugger
    this.submitted = true;
    console.log(this.f)

    if (this.courseElementForm.invalid) {
      return;
    }

    

    var parentId = 0;

    switch (this.courseElementType) {
      case 1: {
        parentId = 0;
        break;
      }
      case 2: {
        parentId = this.f.subject.value
        break;
      }
      case 3: {
        parentId = this.f.chapter.value
        break;
      }
      case 4: {
        parentId = this.f.concept.value
        break;
      }
    }


    if (!this.isEdit) {
      this.spinner.show();
      
      var body = { "title": this.f.title.value, "type_id": this.courseElementType, "parent_id": parentId, "status_id": +this.f.status.value };
      var url = "add_course_element?title=" + `${this.f.title.value}` + "&type_id=" + `${this.courseElementType}` + "&parent_id=" + `${parentId}` + "&status_id=" + `${this.f.status.value}`  ;
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status.toLowerCase() == "true") {
          this.spinner.hide();

          this.courseElementForm.reset();


          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup()
          this.router.navigate(['admin/courseElement'])

        }
        else {
          this.spinner.hide();

          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else {
      if (this.isEdit) {
        this.spinner.show();
        var update_body = { "course_element_id": this.elementId, "title": this.f.title.value, "type_id": this.courseElementType, "parent_id": parentId, "status_id": +this.f.status.value };
        var update_url = "update_course_element?course_element_id=" + `${this.elementId}` + "&title=" + `${this.f.title.value}` + "&type_id=" + `${this.courseElementType}` + "&parent_id=" + `${parentId}` + "&status_id=" + `${this.f.status.value}`  ;
        this.api.detailsAddFetch(update_body, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status.toLowerCase() == "true") {
            this.spinner.hide();

            this.courseElementForm.reset();
            this.submitted = false;

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/courseElement'])

          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
  }

}
