import { Component } from '@angular/core';
import Swal from 'sweetalert2'
import { HostListener, Inject } from "@angular/core";
import { PopupService } from './services/popup.service';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';  
import { filter } from 'rxjs/operators'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // public setTitle(newTitle: string) {
  //   this.titleService.setTitle(newTitle);
  // }
  // title = 'jamia';
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    Swal.close();
  }
  constructor(public popupService:PopupService, private titleService: Title, private router: Router,  
    private activatedRoute: ActivatedRoute, private metaService:Meta){}
  ngOnInit() {  
    this.router.events.pipe(  
        filter(event => event instanceof NavigationEnd),  
      ).subscribe(() => {  
        const rt = this.getChild(this.activatedRoute);  
        rt.data.subscribe(data => {  
          console.log(data);  
          this.titleService.setTitle(data.title + '| Al Jamia')});  
      });  
      this.metaService.addTags([
        {name: 'keywords', content: 'certificate courses in Maqasid Al Sharia and Ulum Al Qurán'},
        {name: 'description', content: 'premium Islamic University offering online courses'},
        {name: 'robots', content: 'index, follow'}
        
      ]);
  }  
  
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  }  
}
