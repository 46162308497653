<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item active"><a>Payment Reports</a></li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card">

      <div class="card-header">
        <h1>Payment Reports</h1>
      </div>
      <form [formGroup]="paymentReportForm" (ngSubmit)="onSearch()" class="form-inline filter_list">
        <div class="card-body pb-0">
          <span class="col-md-2 col-sm-4 float-left pl-0 payment_report_date">
            <label>From Date *</label>
            <input type="date" [max]=todayDate formControlName="from_date" class="form-control selact_" placeholder="Search text">

            <div *ngIf="submitted && paymentReportForm.get('from_date').invalid">
                <small *ngIf="paymentReportForm.get('from_date').errors?.required" class="text-danger">From Date
                  is
                  required</small>
              </div>

          </span>
          <span class="col-md-2 col-sm-4 float-left pl-0 payment_report_date">
            <label>To Date *</label>
            <input type="date" [max]=todayDate formControlName="to_date" class="form-control selact_" placeholder="Search text">

            <div *ngIf="submitted && paymentReportForm.get('to_date').invalid">
                <small *ngIf="paymentReportForm.get('to_date').errors?.required" class="text-danger">To Date
                  is
                  required</small>
                <small *ngIf="paymentReportForm.get('to_date').errors?.dateError" class="text-danger">
                  Enter a valid To Date
                </small>
              </div>

          </span>
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <button class="btn btn-dark ml-2 mt-3 float-left" type="submit"> <i class="fas fa-search mr-1"></i> Search
            </button>
          </span>
          <ng-container *ngIf="!!csvData">
            <a csvLink [data]="csvData" filename="Payment Reports" class="btn btn-secondary btn-dark float-right add_country">Export</a>
          </ng-container>
        </div>
      </form>

     
      <div class="card-body table-responsive" id="contentToConvert"> 

        <ngx-datatable #myTable class="material striped" [rows]="paymentList" [columnMode]="'force'" [headerHeight]="50"
          [footerHeight]="50" [rowHeight]="50" [sorts]="[{prop: 'name', dir: 'desc'}]" [selected]="selected"
          [selectionType]="'checkbox'"  [externalPaging]="true" [count]="page.total"
          [offset]="page.current_page" [limit]="page.per_page" (page)="setPagetest($event)">
         
          <ngx-datatable-column name="Sl No" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
              {{rowIndex+1}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [width]="300" name="Student Name" prop="title">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.student_name}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [width]="300" name="Course Name" prop="coursetitle">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.course_name}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="batch ID" prop="batchid">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.batch_id}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [width]="300" name="Transaction ID" prop="transaction_id">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.transaction_id}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Payment Date" prop="date">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.date}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Status" prop="status">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.payment_status}}
            </ng-template>
          </ngx-datatable-column>
          <!-- <ngx-datatable-column name="Action" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a (click)="editbatch(row.id)" href="Javascript:void(0);" class="edit_button mr-2"><i class="far fa-edit"></i></a> <a
                (click)="deletebatch(row.id)" href="Javascript:void(0);" class="close_button"><i class="far fa-times-circle"></i></a>
            </ng-template>
          </ngx-datatable-column> -->
        </ngx-datatable>
      </div>
    </div>
  </section>
</div>
