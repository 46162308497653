<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/testimonial']">Testimonials</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add Testimonial</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit Testimonial</li>

          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add Testimonial</h1>
        <h1 *ngIf="isEdit">Edit Testimonial</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="testimonialForm" (ngSubmit)="onSubmit()">
                <div class="col-md-6 float-left">
                  <div class="form-group">
                    <label>Name of Student *</label>
                    <input type="text" formControlName="title" class="form-control selact_" placeholder="Name of Student"
                      [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                      <div *ngIf="f.title.errors.required">
                        Name of Student is
                        required
                      </div>
                      <div *ngIf="f.title.errors.validspace">
                        Invalid Name of Student
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Testimonial *</label>
                    <textarea class="form-control selact_" formControlName="description" placeholder="Enter Testimonial"
                      [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                      <div *ngIf="f.description.errors.required">
                        Testimonial is
                        required
                      </div>
                      <div *ngIf="f.description.errors.validspace">
                        Invalid Testimonial
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Status *</label>
                    <select formControlName="status" class="form-control select2 selact_" style="width: 100%;"
                      [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                      <option value="1">Active</option>
                      <option value="2">Disabled</option>
                    </select>
                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                      <div *ngIf="f.status.errors.required">
                        Status is
                        required
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="clearfix"></div> -->
                <div class="col-md-4 float-left">
                  <div class="form-group wid_75">
                    <label>Student Image</label>
                    <div class="uploadedImg test_image_a">
                      <input type="file" class="mediaFile" formControlName="fileData" id="exampleInputFile"
                        (change)="onFileChanged($event)" [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }"
                        accept="image/*">
                      <span class="up_img">Upload image</span>

                      <img [src]="imageSrc" *ngIf="imageSrc">
                    </div>
                    <div *ngIf="submitted && f.fileData.errors" class="text-danger">
                      <small *ngIf="f.fileData.errors.required">Student Image is
                        required
                      </small>
                    </div>
                    <div class="note_a p-3"><label>* Preffered image resolution 300x300px</label></div>
                  </div>

                </div>
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>
