import { AbstractControl, ValidationErrors } from '@angular/forms';



export class SpaceValidator {

  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
   
    if (control.value.trim() == '') {
      return { cannotContainSpace: true }

    }



    return null;

  }

}
