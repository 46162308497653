import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private rote: ActivatedRoute) { }
  isFirstLoad: boolean = true

  ngOnInit(): void {
    // this.rote.params.subscribe(params=>{
    //   if(this.isFirstLoad){
    //   window.location.reload();
    //   this.isFirstLoad= false;
    //   }

    // });
  
   
  }

}