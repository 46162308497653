import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
// import * as $ from 'jquery';
import { Router } from '@angular/router';
import { LocaldataService } from 'src/app/services/localdata.service'
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ConstantsService } from 'src/app/services/constants.service';
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private LocaldataService: LocaldataService,
    private titleService: Title,
    private constantService: ConstantsService) { }

  customOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 2000,
    navText: [
      '<i class="fa-chevron-left"></i>',
      '<i class="fa-chevron-right></i>"',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  instructOptions: OwlOptions = {
    // loop: true,
    // autoplay: true,
    // center: true,
    // dots: false,
    // autoHeight: true,
    // autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 2000,
    // navText: [
    //   '<i class="fa-chevron-left"></i>',
    //   '<i class="fa-chevron-right></i>"',
    // ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  exploreOptions: OwlOptions = {
    // loop: true,
    // autoplay: true,
    // center: true,
    dots: false,
    // autoHeight: true,
    // autoWidth: true,
    nav: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 2000,
    //  navText: [
    //  '<i class="fa-chevron-left"></i>',
    //  '<i class="fa-chevron-right></i>"',
    // ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  testimonialOptions: OwlOptions = {
    // loop: true,
    // autoplay: true,
    // center: true,
    // dots: false,
    // autoHeight: true,
    // autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 2000,
    // navText: [
    //   '<i class="fa-chevron-left"></i>',
    //   '<i class="fa-chevron-right></i>"',
    // ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };

  teamsOptions: OwlOptions = {
    // loop: true,
    // autoplay: true,
    // center: true,
    // dots: false,
    // autoHeight: true,
    // autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 2000,
    // navText: [
    //   '<i class="fa-chevron-left"></i>',
    //   '<i class="fa-chevron-right></i>"',
    // ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  homeData: any;
  subscribeLocaldataService: any;
  subscribeAfterHomeDataLoad: any;
  whyAlJamia: any;
  banners: any = [];
  courseCategoriesWithCounts: any = [];
  testimonials: any = [];
  instructors: any = [];
  courses: any = [];
  teams: any = [];
  currencyId: number = null;
  categorySlug: string;
  s3FilePath: string;


  ngOnInit(): void {
    //  this.spinner.show();
    this.s3FilePath = this.constantService.s3FilePath;
    let currency = this.LocaldataService.getcurrencyInfo();
    this.LocaldataService.updateUser();
    if (currency) {
      this.currencyId = currency.id
    }

    this.subscribeLocaldataService = this.LocaldataService.CurrencyChange.subscribe(selectedData => {
      this.currencyId = selectedData.id
    });
    this.subscribeAfterHomeDataLoad = this.LocaldataService.AfterHomeDataLoad.subscribe(selectedHomeData => {
      this.homeInfoLoad(selectedHomeData)
    });
    if (this.LocaldataService.getHomeInfo()) {
      this.homeInfoLoad(this.LocaldataService.getHomeInfo());
    }

  }

  // ngAfterViewInit() {

  //   $(document).ready(function () {
  //     (function ($) {
  //       (<any>$('.instr_list')).owlCarousel({
  //         loop: true,
  //         margin: 30,
  //         responsiveClass: true,
  //         autoplayTimeout: 3000,
  //         nav: false,
  //         autoplay: false,
  //         responsive: {
  //           0: {
  //             items: 2,
  //             nav: true
  //           },
  //           600: {
  //             items: 2,
  //             nav: false
  //           },
  //           1000: {
  //             items: 4,
  //             nav: true,
  //             loop: false
  //           }
  //         }
  //       })

  //     })(jQuery);
  //   });
  //   $(document).ready(function () {
  //     (function ($) {
  //       (<any>$('.cat_list')).owlCarousel({
  //         loop: true,
  //         margin: 30,
  //         responsiveClass: true,
  //         nav: false,
  //         autoplay: false,
  //         autoplayTimeout: 3000,
  //         responsive: {
  //           0: {
  //             items: 1,
  //             nav: true
  //           },
  //           600: {
  //             items: 2,
  //             nav: false
  //           },
  //           1000: {
  //             items: 4,
  //             nav: true,
  //             loop: false
  //           }
  //         }
  //       })
  //     })(jQuery);
  //   });
  //   $(document).ready(function () {
  //     (function ($) {
  //       (<any>$('.student_sa')).owlCarousel({
  //         loop: true,
  //         margin: 30,
  //         responsiveClass: true,
  //         nav: false,
  //         autoplay: false,
  //         autoplayTimeout: 3000,
  //         responsive: {
  //           0: {
  //             items: 1,
  //             nav: true
  //           },
  //           600: {
  //             items: 1,
  //             nav: false
  //           },
  //           1000: {
  //             items: 2,
  //             nav: true,
  //             loop: false
  //           }
  //         }
  //       })
  //     })(jQuery);
  //   });
  //   $(document).ready(function () {
  //     (function ($) {
  //       (<any>$('.team_c')).owlCarousel({
  //         loop: true,
  //         margin: 30,
  //         responsiveClass: true,
  //         autoplayTimeout: 3000,
  //         nav: false,
  //         autoplay: false,
  //         responsive: {
  //           0: {
  //             items: 2,
  //             nav: true
  //           },
  //           600: {
  //             items: 2,
  //             nav: false
  //           },
  //           1000: {
  //             items: 4,
  //             nav: true,
  //             loop: false
  //           }
  //         }
  //       })

  //     })(jQuery);
  //   });

  // }

  ngOnDestroy() {
    // You can also do whatever you need here
    this.subscribeLocaldataService.unsubscribe();
    this.subscribeAfterHomeDataLoad.unsubscribe();
  }

  homeInfoLoad(selectedHomeData) {
    this.banners = selectedHomeData.banners;
    this.courseCategoriesWithCounts = selectedHomeData.course_categories_with_count;
    this.testimonials = selectedHomeData.testimonials;
    this.instructors = selectedHomeData.instructors;
    this.teams = selectedHomeData.teams;
    this.courses = selectedHomeData.courses;
    if (this.courses.length != 0) {
      this.categorySlug = this.courses[0].slug;
    }

    if (selectedHomeData.why_aljamia) {
      this.whyAlJamia = selectedHomeData.why_aljamia;
    }
  }
  catogoryChnge(course) {
    this.categorySlug = course.slug;
  }
  courseListByCatogory() {

    debugger
    if (this.categorySlug)
      this.router.navigate(['courses/category/' + this.categorySlug])
  }
  courseSelect(course) {
    if (course.course_slug)
      this.router.navigate(['course/' + course.course_slug])
  }
  smeSelect(instructor) {
    debugger
    if (instructor.slug) {
      this.router.navigate(['instructor/' + instructor.slug])
      // this.setTitle('Good evening!');
    }
  }

  courseCategorySelection(courseCategoriesWithCount) {
    // const url = 'courses/category/' + courseCategoriesWithCount.slug;
    this.router.navigate(['courses/category/' + courseCategoriesWithCount.slug])
    // window.open(url, '_blank');
    setTimeout(() => {
      this.LocaldataService.courseSearch(null);
    }, 5);
  }
  // public setTitle(newTitle: string) {
  //   this.titleService.setTitle(newTitle);
  // }
  teamSelect(team){
    debugger
    console.log(team);
    if(team){
      this.router.navigate(['team/' + team.id])
    }
  }
}
