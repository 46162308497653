import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title, Meta } from "@angular/platform-browser";


import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-qualification-add',
  templateUrl: './qualification-add.component.html',
  styleUrls: ['./qualification-add.component.css']
})
export class QualificationAddComponent implements OnInit {
  isEdit: boolean = false;
  session_id: any;
  qualificationId:any;
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService,private spinner: NgxSpinnerService, private title: Title, private meta: Meta ) { }
  qualificationForm: FormGroup;
  submitted = false;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')

    this.qualificationForm = this.formBuilder.group({
      qualification: ['', [Validators.required, Validatespace]],
      status: ['', Validators.required],
      description: [''],

     });
     this.qualificationForm.get('status').setValue('1');
     this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;

        this.qualificationId = +params.dist;
        this.spinner.show();

        var single_url = "qualification_edit?qualification_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "true" && data.qualification[0]) {
            this.spinner.hide();

            this.isEdit = true;
            // $("html, body").animate({ scrollTop: 0 });
            this.qualificationForm.get('qualification').setValue(data.qualification[0].title);
            this.qualificationForm.get('status').setValue(data.qualification[0].status_id);
            this.qualificationForm.get('description').setValue(data.qualification[0].description);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
    
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
          this.title.setTitle("Edit Qualification | Admin | Al Jamia");
          this.meta.addTag({
          name: "description",
          content: "Edit Qualification | Admin",
        });
      
    
      }
      else {
        this.spinner.hide();

        this.isEdit = false;
        this.title.setTitle("Add Qualification | Admin | Al Jamia");
          this.meta.addTag({
          name: "description",
          content: "Add Qualification | Admin",
        });
      
      }
    }
    );
  }
  get f() { return this.qualificationForm.controls; }


  onSubmit() {
    this.submitted = true;
    console.log(this.f)

    if (this.qualificationForm.invalid) {
      return;
    }
    if (!this.isEdit) {
      this.spinner.show();

      var body = { "status_id": +this.f.status.value, "title": this.f.qualification.value, "description": this.f.description.value, };
      var url = "add_qualification?status_id=" + `${this.f.status.value}` + "&title=" + `${this.f.qualification.value}` + "&description=" + `${this.f.description.value}`;
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "True") {
          this.spinner.hide();

          this.qualificationForm.reset();


          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup()
          this.router.navigate(['admin/qualification'])

        }
        else {
          this.spinner.hide();

          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else{
      if(this.isEdit){
        this.spinner.show();

        var edit_body ={ "status_id": +this.f.status.value, "title": this.f.qualification.value, "description": this.f.description.value,"qualification_id":this.qualificationId };
        var update_url = "update_qualification?status_id=" + `${this.f.status.value}` + "&title=" + `${this.f.qualification.value}` + "&description=" + `${this.f.description.value}` + "&qualification_id=" + this.qualificationId;
        this.api.detailsAddFetch(edit_body, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "True") {
            this.spinner.hide();

            this.qualificationForm.reset();
            this.submitted = false;

             this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/qualification'])

          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
  }

}
