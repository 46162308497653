import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { PopupService } from 'src/app/services/popup.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UploadService } from 'src/app/services/upload.service';
import { Page } from 'src/app/models/pageDto';
import * as moment from "moment";

@Component({
  selector: 'app-discussion-forum-list',
  templateUrl: './discussion-forum-list.component.html',
  styleUrls: ['./discussion-forum-list.component.css'],
})
export class DiscussionForumListComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private PopupService: PopupService,
    private uploadService: UploadService,
    private ToasterService: ToasterService,
    private rote: ActivatedRoute,
    private constantService: ConstantsService
  ) {
    this.threadForm = this.fb.group({
      search: [''],
    });
  }
  numberpages = [];
  searchTerm: any = '';
  session_id: any = '';
  threadForm: FormGroup;
  selectedpage: number = 1;
  totalPages: number = 1;
  initialselectedPage: number = 1;
  startIndex: number = 1;
  endIndex: number = 3;
  nextShow = false;
  enrollmentId: any;

  temp: any = [];
  threadList: any = [];
  ngOnInit(): void {
    this.session_id = localStorage.getItem('access_token');
    this.enrollmentId = this.rote.snapshot.paramMap.get("enrollment_id");
    this.getAllThreads();
  }

  getGridParam() {
    return { per_page_count: null, page: null };
  }

  getAllThreads() {
    let searchTerm = this.getGridParam();
    searchTerm.per_page_count = 6;
    searchTerm.page = this.selectedpage;
    var thread_url =
      'getThreads?enrollment_id='
      + this.enrollmentId +
      '&page=' +
      searchTerm.page +
      '&per_page_count=' +
      searchTerm.per_page_count;

    if (this.searchTerm.length > 0)
      thread_url += '&searchTerm=' + this.searchTerm;

    this.api.detailsGetFetch(thread_url, this.session_id).subscribe(
      (data: any) => {
        if (data.status == 'true' && data.threads) {
          this.spinner.hide();

          this.temp = [...data.threads];
          this.threadList = data.threads;
          debugger
          for (let doc of this.threadList) {
            doc.created_date = moment(doc.utc_created_date + '+00:00').local().format("MM-DD-YYYY");
            doc.time = moment(doc.utc_created_date + '+00:00').local().format("hh:mm A");
          }
          
          this.totalPages = data.pagination.total_pages;
          this.initialselectedPage = data.pagination.current_page;
          this.startIndex = 1;
          if (data.pagination.total > this.endIndex) {
            // this.startIndex = data.pagination.total - this.endIndex;
          }
          this.generatePageNumber(this.startIndex, data.pagination.total);
        } else {
          this.spinner.hide();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear();
          this.router.navigate(['']);
        }
      }
    );
  }

  generatePageNumber(startIndex, endIndex) {
    this.numberpages = [];
    if (startIndex + endIndex > this.totalPages) {
      endIndex = this.totalPages - startIndex + 1;
    }
    for (let i = startIndex; i < startIndex + endIndex; i++) {
      this.numberpages.push(i);
    }
    setTimeout(() => {
      if (document.getElementById('pages' + this.selectedpage)) {
        document.getElementById('pages' + this.selectedpage).className =
          'selected_page';
      }
    }, 5);
  }

  onSearch() {
    this.searchTerm = this.threadForm.get('search').value.toString();
    this.getAllThreads();
  }

  previousPageSelect() {
    if (this.selectedpage > 1) {
      setTimeout(() => {
        if (document.getElementById('pages' + this.selectedpage)) {
          if (this.selectedpage <= this.totalPages) {
            this.nextShow = true;
            document
              .getElementById('pages' + this.selectedpage)
              .classList.remove('selected_page');
          } else {
            this.nextShow = false;
          }
          this.selectedpage = this.selectedpage - 1;
          if (this.selectedpage < this.startIndex) {
            this.startIndex = this.startIndex - this.endIndex;
            this.generatePageNumber(this.startIndex, this.endIndex);
          }
          setTimeout(() => {
            document.getElementById('pages' + this.selectedpage).className =
              'selected_page';
          }, 7);
        }
        this.getAllThreads();
      }, 2);
    }
  }

  nextPageSelect() {
    if (this.selectedpage < this.totalPages) {
      setTimeout(() => {
        if (document.getElementById('pages' + this.selectedpage)) {
          if (this.selectedpage >= this.totalPages) {
            this.nextShow = true;
          } else {
            this.nextShow = false;
            document
              .getElementById('pages' + this.selectedpage)
              .classList.remove('selected_page');
          }
          this.selectedpage = this.selectedpage + 1;
          if (this.selectedpage >= this.startIndex + this.endIndex) {
            this.startIndex = this.startIndex + this.endIndex;
            this.generatePageNumber(this.startIndex, this.endIndex);
          }
          setTimeout(() => {
            document.getElementById('pages' + this.selectedpage).className =
              'selected_page';
          }, 7);
        }
        this.getAllThreads();
      }, 2);
    }
  }

  pageselect(selectedpage) {
    if (document.getElementById('pages' + this.selectedpage)) {
      document
        .getElementById('pages' + this.selectedpage)
        .classList.remove('selected_page');
    }
    this.selectedpage = selectedpage;
    setTimeout(() => {
      if (document.getElementById('pages' + this.selectedpage)) {
        document.getElementById('pages' + this.selectedpage).className =
          'selected_page';
      }
    }, 5);
    this.getAllThreads();
  }
}
