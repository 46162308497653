<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/admin/course/courseList">Course List</a></li>
            <li *ngIf="courseId" class="breadcrumb-item"><a routerLink="/admin/course/editcourse/{{courseId}}">Course
                Detail</a></li>
            <!-- <li class="breadcrumb-item"><a (click)="resourceShow()">Resource</a></li> -->
            <li class="breadcrumb-item active">{{resourceHeadding}}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>

  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>{{resourceHeadding}} content</h1>
      </div>

      <!-- /.card-header -->
      <div class="card-body">




        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3">

              <div class="pl-3 pr-3 pt-0 pb-0">
                <button type="button" *ngIf="isEdit && courseContentTypeId == '8'"
                  class="btn btn-primary btn-sm float-right" (click)="sentliveClassLink()">Send live class
                  links</button>
              </div>

              <form [formGroup]="resourceForm" (ngSubmit)="onSubmit()" role="form" class="p-3 width_ add_course_">
                <div class="col-md-8 float-left">
                  <div class="form-group text_field_width">
                    <label>Title *</label>
                    <input type="text" class="form-control" placeholder="Title" formControlName="title">
                    <div *ngIf="submitted && f.title.errors" class="text-danger">
                      <small *ngIf="f.title.errors.required">Title is
                        required
                      </small>
                      <small *ngIf="f.title.errors.validspace">Invalid Title
                      </small>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="form-group float-left  text_field_width mr-2">
                    <label>Subject *</label>
                    <select class="form-control" style="width: 100%;" formControlName="subject"
                      (change)="subjectChange()">
                      <option value="">--Select Subject--</option>
                      <option [ngValue]="option.id" *ngFor="let option of subjects">{{option.title}}</option>
                    </select>
                    <div *ngIf="submitted && f.subject.errors" class="text-danger">
                      <small *ngIf="f.subject.errors.required">Subject is
                        required
                      </small>
                    </div>
                  </div>
                  <div class="form-group float-left  text_field_width mr-2" *ngIf="isChapter">
                    <label>Chapter</label>
                    <select class="form-control" style="width: 100%;" formControlName="chapter"
                      (change)="chapterChange()">
                      <option value="">--Select Chapter--</option>
                      <option [ngValue]="option.id" *ngFor="let option of chapter">{{option.title}}</option>
                    </select>
                  </div>
                  <div class="form-group float-left  text_field_width mr-2" *ngIf="isConcept">
                    <label>Concept</label>
                    <select class="form-control" style="width: 100%;" formControlName="concept"
                      (change)="conceptChange()">
                      <option value="">--Select Concept--</option>
                      <option [ngValue]="option.id" *ngFor="let option of concept">{{option.title}}</option>
                    </select>
                  </div>
                  <div class="form-group float-left  text_field_width mr-2" *ngIf="isModule">
                    <label>Module</label>
                    <select class="form-control ml-1" style="width: 100%;" formControlName="module"
                      (change)="moduleChange()">
                      <option value="">--Select Module--</option>
                      <option [ngValue]="option.id" *ngFor="let option of module">{{option.title}}</option>
                    </select>
                  </div>

                  <!-- content -->
                  <div class="clearfix"></div>
                  <div class="form-group ">

                    <!-- files type -->

                     <!-- Vimeo Link -->

                     <div class="input-group float-left "
                     *ngIf="courseContentTypeId == '9' ">
                     <label > Vimeo Video Link *</label>
 
                     <div class="input-group-prepend">
                       <span class="input-group-text" id="">
                         <i class="fas"
                           [ngClass]="{ 'fa-keyboard':courseContentTypeId =='9'}"></i>
                       </span>
                     </div>
                     <div class="custom-file">
                       <input type="text" class="form-control" id="exampleInputFile" formControlName="videoFile">
                     </div>
                     <label class="text-warning">* Accept only embed Vimeo video link</label>
                   </div>
                   <div class="clearfix"></div>
                   <div *ngIf="submitted && f.videoFile.errors" class="text-danger w-100 text-left">
                     <small *ngIf="f.videoFile.errors.required && courseContentTypeId == '9'">Link is
                       required
                     </small>
                     <small *ngIf="f.videoFile.errors.validspace && courseContentTypeId == '9'">Invalid Link
                     </small>
                   </div>

                    <div class="input-group float-left text_field_width"
                      *ngIf="courseContentTypeId != '3' && courseContentTypeId != '8' && courseContentTypeId != '9'">
                      <label *ngIf="courseContentTypeId != '7'" >{{contentTypeName}} *</label>
                      <label *ngIf="courseContentTypeId == '7'" >File *</label>

                      <div class="input-group-prepend">
                        <span class="input-group-text" id="">
                          <i class="fas"
                            [ngClass]="{'fa-image':courseContentTypeId =='3', 'fa-volume-up':courseContentTypeId =='2', 'fa-video':courseContentTypeId =='1', 'fa-keyboard':courseContentTypeId =='7'}"></i>
                        </span>
                      </div>
                      <div class="custom-file" *ngIf="courseContentTypeId == '1'">
                        <input type="file" class="custom-file-input" id="exampleInputFile"
                          (change)="onVideoFileChanged($event,'video')" accept="video/*">
                        <label class="custom-file-label field_break_" for="exampleInputFile"
                          accept="allowedImageTypes">{{selectedFile==null?'Choose file':selectedFile.name}}</label>
                      </div>
                      <div class="custom-file" *ngIf="courseContentTypeId == '2'">
                        <input type="file" class="custom-file-input" id="exampleInputFile"
                          (change)="onVideoFileChanged($event,'audio')" accept="audio/*">
                        <label class="custom-file-label field_break_" for="exampleInputFile"
                          accept="allowedImageTypes">{{selectedFile==null?'Choose file':selectedFile.name}}</label>
                      </div>
                      <div class="custom-file" *ngIf="courseContentTypeId == '7'">
                        <input type="file" class="custom-file-input" id="exampleInputFile"
                          (change)="onVideoFileChanged($event,'pdf')" accept="application/pdf">
                        <label class="custom-file-label field_break_" for="exampleInputFile"
                          accept="allowedImageTypes">{{selectedFile==null?'Choose file':selectedFile.name}}</label>
                      </div>
                    </div>
                    <div *ngIf="pdfSrc" class="form-group float-left mb-0 mt-4 pt-1">
                      <div class="input-group">
                        <a (click)="openLink()" class="btn btn-block btn-info btn-sm ml-2 view_video_">
                          <i class="far fa-eye"></i> View {{resourceHeadding}}
                        </a>
                      </div>
                    </div>
                    <mat-progress-bar *ngIf="isProgresBar" class="example-margin" [mode]="mode" [value]="value"
                      [bufferValue]="bufferValue">
                    </mat-progress-bar>
                    <div class="clearfix"></div>
                    <div *ngIf="submitted && f.videoFile.errors" class="text-danger w-100 text-left">
                      <small *ngIf="f.videoFile.errors.required && courseContentTypeId != '7' && courseContentTypeId != '9'">{{contentTypeName}} is
                        required
                      </small>
                      <small *ngIf="f.videoFile.errors.required && courseContentTypeId == '7'">File is
                        required
                      </small>
                    </div>


                    <!-- reading type -->

                    <div class="input-group" *ngIf="courseContentTypeId == '3'">
                      <label>{{contentTypeName}} *</label>
                      <ckeditor formControlName="textDescription" style="width: 100%;" [config]="ckeConfig"></ckeditor>
                      <div *ngIf="submitted && f.textDescription.errors" class="text-danger">
                        <small *ngIf="f.textDescription.errors.required">{{contentTypeName}} is
                          required
                        </small>
                      </div>
                    </div>

                    <!-- Live  -->

                    <div class="form-group" *ngIf="courseContentTypeId == '8'">
                      <label>Description *</label>
                      <textarea class="form-control" rows="3" placeholder="Enter ..." autocomplete="off"
                        formControlName="livedescription"></textarea>
                      <div *ngIf="submitted && f.livedescription.errors" class="text-danger">
                        <small *ngIf="f.livedescription.errors.required">Description is
                          required
                        </small>
                      </div>
                    </div>

                  </div>



                </div>
                <div class="clearfix"></div>


                <div class="col-md-8">
                  <div class="card card-primary my-4">
                    <div class="form-group mt-0 mb-0">
                      <div class="card-header">
                        <h4 class="border_bottom_inner_after">Add References <a (click)="addNewReference()"
                            class="preposr_series_add"> <i class="fa fa-plus" aria-hidden="true"></i> </a></h4>
                      </div>
                      <div formArrayName="referance"
                        *ngFor="let itemrow of resourceForm.get('referance')['controls']; let i=index;"
                        class="newform mt-0 ng-dirty ng-invalid ng-star-inserted ng-touched">
                        <ng-container [formGroupName]="i">
                          <div class="col_templ_add_close">
                            <input class="txtarea_closeicon" type="button" (click)="referanceListdelete(i)">
                          </div>


                          <div class="form-group">
                            <label>Text *</label>
                            <input type="number" [ngModel]="i + 1" formControlName="id" hidden>
                            <textarea class="form-control" rows="3" placeholder="Enter ..." autocomplete="off"
                              formControlName="text" id='focusurl{{i}}'></textarea>
                            <div *ngIf="submitted && resourceForm.get('referance').at(i).get('text').invalid">
                              <small *ngIf="resourceForm.get('referance').at(i).get('text').errors?.required"
                                class="text-danger">Text is required</small>
                              <small *ngIf="resourceForm.get('referance').at(i).get('text').errors?.validspace"
                                class="text-danger">Invalid Text</small> </div>
                          </div>
                          <div class="form-group mb-4">
                            <label> Type *</label>
                            <select class="form-control select2 selact_" style="width: 100%;" #mySelect
                              (change)="onReferenceMediaTypeChanged(mySelect.value, i)" formControlName="type">
                              <option value='1'>File</option>
                              <option value='2'>Link</option>
                            </select>
                          </div>

                          <div formArrayName="referances"
                            *ngFor="let itemrow of resourceForm.get('referance').at(i).get('referances')['controls']; let j=index;">
                            <ng-container [formGroupName]="j">
                              <div class="col_templ_add_close"
                                *ngIf="resourceForm.get('referance').at(i).get('referances')['controls'] && resourceForm.get('referance').at(i).get('referances')['controls'].length>1">
                                <input class="small_close" type="button" (click)="referenceMediadelete(i, j)">
                              </div>
                              <input type="number" [ngModel]="j + 1" formControlName="id" hidden>
                              <div class="form-group padding_top_ mt-2"
                                *ngIf="resourceForm.get('referance').at(i).get('type').value == '2'">
                                <!-- <label>Link *</label>-->
                                <input type="text" formControlName="text" class="form-control selact_"
                                  placeholder="Link">

                                <div
                                  *ngIf="submitted && resourceForm.get('referance').at(i).get('referances').at(j).get('text').invalid ">
                                  <small
                                    *ngIf="resourceForm.get('referance').at(i).get('referances').at(j).get('text').errors?.required"
                                    class="text-danger">
                                    Link is required
                                  </small>
                                  <small
                                    *ngIf="resourceForm.get('referance').at(i).get('referances').at(j).get('text').errors?.whitespace"
                                    class="text-danger">
                                    Link is required
                                  </small>
                                  <small
                                    *ngIf="resourceForm.get('referance').at(i).get('referances').at(j).get('text').errors?.pattern"
                                    class="text-danger">
                                    Invalid URL (eg : https://www.google.com/)
                                  </small>
                                </div>
                              </div>
                              <div class="form-group"
                                *ngIf="resourceForm.get('referance').at(i).get('type').value == '1'">
                                <div class="custom-file file-upload-wrapper" class="form-group">
                                  <div class="form-control file_uplode_a mt-2">
                                    <input type="file" value="" class="" name="path" id="video{{j}}"
                                      (change)="onReferanceFileChanged($event, i, j)">
                                    <label for="" class="file_name_">
                                      {{resourceForm.get('referance').at(i).get('referances').at(j).get('path').value == "" ?'Choose file':resourceForm.get('referance').at(i).get('referances').at(j).get('path').value}}</label>

                                    <a *ngIf="(resourceForm.get('referance').at(i).get('referances').at(j).get('path').value != '') &&  (resourceForm.get('referance').at(i).get('referances').at(j).get('changeflag').value == false)"
                                      href="{{s3FilePath}}{{resourceForm.get('referance').at(i).get('referances').at(j).get('path').value}}"
                                      class="viewfile_list btn btn-primary btn-sm" target="_blank" download> <i
                                        class="far fa-eye"></i></a>
                                  </div>
                                  <div
                                    *ngIf="submitted && resourceForm.get('referance').at(i).get('referances').at(j).get('path').invalid ">
                                    <small
                                      *ngIf="resourceForm.get('referance').at(i).get('referances').at(j).get('path').errors?.required"
                                      class="text-danger">
                                      File is required
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                          <a (click)=" addNewReferenceMedia(i)" class="preposr_series_add small_add"> <i
                              class="fa fa-plus" aria-hidden="true"></i> </a>
                        </ng-container>
                      </div>

                      <!-- <table id="myTable" class=" table order-list_1 mb-0">
                      <thead>
                        <tr>
                          <td>No.</td>
                          <td>Text</td>
                          <td>Type</td>
                          <td>Contents</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of referenceModel.referances; let i = index">
                          <td><span class="pt-3">{{ i+1 }}</span></td>
                          <td class="col-md-3" style="width:20%;">
                            <input type="text" class="form-control" (change)="ontextChanged($event, item.id)"
                              formControlName="optionText{{item.id}}"> </td>
                          <td class="col-md-3" style="width:20%;">
                            <div class="form-group mb-0">
                              <select class="form-control select2 selact_" style="width: 100%;"
                                (change)="onMediaFileOptionsChanged(item.id)" formControlName="fileOptions{{item.id}}">
                                <option value='1' selected="selected">File</option>
                                <option value='2'>Link</option>
                              </select>
                            </div>
                          </td>
                          <td class="col-md-4" style="width:70%;">
                            <div class="form-group  mb-0">
                              <div class="input-group">

                        <tr *ngFor="let content of referances.contents; let j = index" class="w-100">
                          <ng-container *ngIf="content.itemId==item.id">
                            <div class="input-group-prepend float-left icon_wid">
                              <span class="input-group-text" id="">
                                <i class="fas" [ngClass]="{'fa-image':content.type === '1',
                'fa-keyboard':content.type === '2'}"></i>
                              </span>
                            </div>
                            <div *ngIf="content.type != '2'" class="custom-file float-left fid-wid">
                              <input type="file" class="custom-file-input" formControlName="mediaFileData{{content.id}}"
                                accept="" id="exampleInputFile1" (change)="onMediaFileChanged($event, content.id)">
                              <label class="custom-file-label  field_break_" for="exampleInputFile"
                                accept="">{{content.name==null?'Choose file':content.name}}</label>
                              <app-field-error-display [displayError]="isFieldValid('mediaFileData'+content.id)"
                                errorMsg="File is required">
                              </app-field-error-display>
                            </div>
                            <div *ngIf="content.type == '2'" class="custom-file fid-wid">
                              <input type="text" class="form-control" formControlName="optionLink{{content.id}}"
                                placeholder="Enter..." (change)="onOptionTextChanged($event, content.id)">
                              <app-field-error-display [displayError]="isFieldValid('optionLink'+content.id)"
                                errorMsg="Text is required">
                              </app-field-error-display>
                            </div>
                            <span colspan="5" style="text-align: left;"><button type="button"
                                class="btn btn-default btn-sm float-right" id="addrow_1" value="Add Row"
                                (click)="deletecontent(content.id)"><i class="fas fa-minus"></i></button></span>
                          </ng-container>

                        </tr>


                  </div>




                </div>


                </td>
               
                <td colspan="5" style="text-align: left;"><button type="button"
                    class="btn btn-default btn-sm float-right" id="addrow_1" value="Add Row"
                    (click)="incrementcontent(item.id)"><i class="fas fa-plus"></i></button></td>


                <td class="col-sm-2"><button (click)="deleteMediaRow(item.id)" type="button"
                    class="ibtnDel btn btn-md btn-danger float-right"><i class="far fa-trash-alt"></i></button></td>
                </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6" style="text-align: left;"><button type="button"
                        class="btn btn-success btn-sm float-right" id="addrow_1" value="Add Row"
                        (click)="incrementMediaFileCounter(1)">Add New</button></td>
                  </tr>
                  <tr> </tr>
                </tfoot>
                </table> -->




                    </div>
                  </div>
                </div>



                <div class="col-md-8 float-left">
                  <div class="form-group float-left text_field_width">
                    <label>Day * </label>
                    <!-- <input type="text" class="form-control float-left" placeholder=" number of days "
                formControlName="unlockAfter" style="width:90%;"> -->
                    <select class="form-control " formControlName="unlockAfter"
                      [attr.disabled]="courseCountentDetails && courseCountentDetails.active_enrollment_exist == true ? true : null">
                      <option value="">-- Select Day --</option>
                      <option *ngFor="let day of dayWeekLists" value="{{day.value}}">
                        {{day.value}}
                      </option>
                    </select>
                    <!-- <span class="p-2 float-left">Days</span> -->
                    <div class="clearfix"></div>
                    <div *ngIf="submitted && f.unlockAfter.errors" class="text-danger">
                      <small *ngIf="f.unlockAfter.errors.required">Days is
                        required
                      </small>
                    </div>
                  </div>

                  <!-- <div class="form-group float-left w-100" *ngIf="courseContentTypeId=='8'">
              <label>Time * </label>
              <input type="text" class="form-control float-left" placeholder="time" formControlName="time"
                style="width:90%;">
              <div class="clearfix"></div>
              <div *ngIf="submitted && f.time.errors" class="invalid-feedback">
                <div *ngIf="f.time.errors.required">Time is
                  required
                </div>
              </div>
            </div> -->

                  <!-- <div class="form-group float-left w-100" *ngIf="courseContentTypeId=='8'">
              <label>Date * </label>
              <input type="date" class="form-control float-left" placeholder=" date "
                formControlName="date" style="width:90%;">
              <div class="clearfix"></div>
              <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                <div *ngIf="f.date.errors.required">Date is
                  required
                </div>
              </div>
            </div> -->

                  <div class="form-group" *ngIf="courseContentTypeId == '1' || courseContentTypeId == '2'">
                    <label>Transcript </label>
                    <textarea class="form-control" rows="3" placeholder="Enter ..." autocomplete="off"
                      formControlName="transcript"></textarea>
                  </div>

                  <!-- <div class="form-group">
              <label>Duration</label>
              <input type="text" class="form-control" placeholder="in days " formControlName="duration">
              <div *ngIf="submitted && f.duration.errors" class="invalid-feedback">
                <div *ngIf="f.duration.errors.required">Duration is
                  required
                </div>
              </div>
            </div> -->

                  <!-- 
                  
                  <div class="form-group">
                    <label>Difficulty level</label>
                    <select class="form-control select2 selact_" style="width: 100%;">
                      <option selected="selected">level 1</option>
                    </select>
                  </div> -->

                  <!-- <div class="custom-control custom-checkbox  mr-3">
                    <mat-slide-toggle  formControlName="isFreeOnTrial">Free On Trial</mat-slide-toggle>
                  </div> -->

                </div>
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Save</button>
                </div>
              </form>



            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

  </section>
</div>

<p-dialog header="" [(visible)]="displayModal" [modal]="true" [style]="{width: '30vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">

  <div class="m-3 ">

    <div *ngIf="vimeoLink">
     <!-- for iframe data uncomment below code -->
      <!-- <iframe [src]='safeURL' title="Vimeo video player" frameborder="0"></iframe> -->

      <!-- for innerHTML data use below code -->
      <div style="width:100%;" [innerHTML]="safeURL "></div>
      </div>

    <div class="uploadedImg" *ngIf="imageSrc">
      <img [src]="imageSrc" />
    </div>

    <div *ngIf="audioSrc">
      <audio controls>
        <source src="{{ audioSrc }}" type="audio/mpeg">
      </audio>
    </div>

    <div *ngIf="videoSrc">
      <video width="320" height="240" controls>
        <source src="{{ videoSrc }}">
      </video>

      <!-- <video   width="320" height="240" controls preload="metadata">
<source src="{{ videoSrc }}"/>
Video not supported.
</video> -->
    </div>
  </div>

</p-dialog>

<p-dialog header="Add Live Class URL" [(visible)]="liveClassdisplayModel" [modal]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">

  <div class="card">


    <form [formGroup]="liveClassForm" role="form" class="p-3 width_b add_course_" (ngSubmit)="sendLink()">

      <div class="form-group" *ngIf="isEdit && courseContentTypeId == '8'">
        <label *ngIf="courseCountentDetails.learning_type == 1">Batch *</label>
        <label *ngIf="courseCountentDetails.learning_type == 2">Enrollment Date *</label>
        <ng-multiselect-dropdown name="Batch" [placeholder]="'Select Batch'" [data]="batches" formControlName="batch"
          (onSelect)="onSelectCheckbox($event)" [settings]="dropdownSettings"
          [ngClass]="{ 'is-invalid': liveClassSubmitted && selected.length == 0 }">
        </ng-multiselect-dropdown>
        <small *ngIf="liveClassSubmitted && liveClassForm.controls.batch.errors" class="text-danger">
         <ng-container *ngIf="liveClassSubmitted && liveClassForm.controls.batch.errors.required" >
          {{courseCountentDetails.learning_type == 1 ? 'Batch is required' : 'Enrollment Date is required'}}
         </ng-container> 

        </small>
        <!-- <div *ngIf="courseCountentDetails.learning_type == 1 && liveClassSubmitted && selected.length == 0" class="invalid-feedback">
          Batch is required

        </div>
        <div *ngIf="courseCountentDetails.learning_type == 2 && liveClassSubmitted && selected.length == 0" class="invalid-feedback">
          Enrollment Date is required

        </div> -->
      </div>


      <div class="form-group">
        <label>Class Link *</label>
        <input type="text" class="form-control" placeholder="Class Link" formControlName="classLink"
          [ngClass]="{ 'is-invalid': liveClassSubmitted && liveClassForm.controls.classLink.errors }">
        <!-- <div *ngIf="liveClassSubmitted && liveClassForm.controls.classLink.errors " class="invalid-feedback"> -->
          
          <div *ngIf="liveClassSubmitted && liveClassForm.get('classLink').invalid ">
            <small *ngIf="hasErrorlive('classLink','required')" class="text-danger">
              Link is required
            </small>
            <small *ngIf="liveClassSubmitted && hasErrorlive('classLink','whitespace')" class="text-danger">
              Link is required
            </small>
            <small *ngIf="hasErrorlive('classLink','pattern')" class="text-danger">
              Invalid URL (eg : https://www.google.com/)
            </small>
            <small *ngIf="hasErrorlive('classLink','invalidLink')" class="text-danger">
              Please add valid url
            </small>
          </div>
        <!-- </div> -->

      </div>


      <div class="form-group">
        <label>Description *</label>
        <textarea class="form-control" rows="3" placeholder="Enter ..." autocomplete="off"
          formControlName="classDescription"
          [ngClass]="{ 'is-invalid': liveClassSubmitted && liveClassForm.controls.classDescription.errors }"></textarea>
        
        <div *ngIf="liveClassSubmitted && liveClassForm.controls.classDescription.errors" class="invalid-feedback">
          <div *ngIf="liveClassForm.get('classDescription').errors.required" >Description
            is
            required</div>
            <div *ngIf="liveClassForm.get('classDescription').errors.validspace">Invalid Description</div>
        </div>
        
      </div>

      <div class="p-2">
        <button type="submit" class="btn btn-secondary btn-dark float-right add_country ml-1">Send</button>
        <button type="button" class="btn btn-secondary btn-dark float-right add_country ml-1" (click)="closeModel()">Cancel</button>
      </div>

    </form>

  </div>
</p-dialog>
