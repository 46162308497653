import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChangepasswordComponent } from './changepassword.component';
import { ChangepasswordRoutingModule } from './changepassword-routing.module';


@NgModule({
  declarations: [ChangepasswordComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    ChangepasswordRoutingModule,
   ]
})
export class ChangepasswordModule { }
