import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { Page } from '../../models/pageDto';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent implements OnInit {
  @ViewChild('myTable') table: CurrencyComponent;
  session_id: any;
  p: number = 1;
  selected = [];

  currencyList: any = [];
  temp = [];
  searchTerm: any = '';
  page: Page;
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService) { }
  currencyForm: FormGroup;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.currencyForm = this.formBuilder.group({
      search: ['']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    this.setPage({ offset: 0 });
  }

  onSearch() {
    this.searchTerm = this.currencyForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  setPage(pageInfo) {
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.currencyList = [];

    var state_fetch_url = "currency?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;

    if (this.searchTerm.length > 0)
      state_fetch_url += "&searchTerm=" + this.searchTerm;

    this.api.detailsGetFetch(state_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.currency[0]) {
        this.spinner.hide();

        this.temp = [...data.currency];
        this.currencyList = data.currency;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate([''])
      }
    });
  }



  onSelect({ selected }) {
     this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  deleteCurrency(id) {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        var body = { "currency_id": +id };
        var del_url = "delete_currency?currency_id=" + id;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
      const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const country_name = d.country_name.toLowerCase().indexOf(val) !== -1 || !val;

      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || status_name || country_name;
    });

     this.currencyList = temp;
   }
   editCurrency(id) {
     this.router.navigate(['admin/add-currency', { dist: `${id}` }]);

  }
}
