import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionCategoryComponent } from './question-category.component';


const routes: Routes = [

  {
    path: '', component: QuestionCategoryComponent
},


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuestionCategoryRoutingModule { }
