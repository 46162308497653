import { Component, OnInit } from '@angular/core';
// import html2canvas from "html2canvas";
// // import * as jspdf from "jspdf";
// import jspdf from 'jspdf';


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // async pdfdownload(order_id) {
  //   var data = document.getElementById("contentToConvert");
  //   html2canvas(data).then((canvas) => {
  //     // Few necessary setting options
  //     var imgWidth = 208;
  //     var pageHeight = 310;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     var heightLeft = imgHeight;

  //     const contentDataURL = canvas.toDataURL("image/png");
  //     let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
  //     pdf.save("aljamia-invoice-" + order_id + ".pdf"); // Generated PDF
  //   });
  // }

}
