import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service';
import { QuestionModel, MediaFiles, Options } from '../../models/questionDto';

const MEDIA_FILE_OPTIONS: string = 'mediaFileOptions';
const OPTION_FILE_OPTIONS: string = 'optionFileOptions'

const MEDIA_FILE_DATA: string = 'mediaFileData'
const OPTION_FILE_DATA: string = 'optionFileData'
const OPTION_TEXT: string = 'optionText'
const OPTION_IS_ANSWER: string = 'optionIsAnswer'

@Component({
  selector: 'app-question-mcq-add',
  templateUrl: './question-mcq-add.component.html',
  styleUrls: ['./question-mcq-add.component.css']
})
export class QuestionMCQAddComponent implements OnInit {

  object: { [key: number]: File } = {};
  mcqFile: any = {}
  isEdit: boolean = false;
  session_id: any;
  questionCategoryList: any = [];
  difficultyLevelList: any = [];
  questionMCQId: any;
  fileName = '';
  p: number = 1;
  s3FilePath: string;
  selectedMediaFileOptions: any[] = [];
  selectedOptionFileOptions: any[] = [];

  selectedMediaFileType: any[] = [];
  selectedOptionFileType: any[] = [];
  mediaFileCounter: number = 0;
  optionFileCounter: number = 0;
  questionModel: QuestionModel;



  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService, private uploadService: UploadService, private constantService: ConstantsService) { }
  questionMCQForm: FormGroup;
  submitted = false;

  ngOnInit(): void {

    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;

    this.questionMCQForm = this.formBuilder.group({
      questionTitle: ['', Validators.required],
      questionText: ['', Validators.required],
      answerExplanation: [''],
      questionCategory: ['', Validators.required],
      difficultyLevel: ['', Validators.required],
      status: ['1', Validators.required]
    });
    this.selectedMediaFileType[0] = "image/*"
    this.selectedMediaFileOptions[0] = "1"

    this.selectedOptionFileType[0] = "image/*"
    this.selectedOptionFileOptions[0] = "4"

    this.questionModel = {
      title: this.f.questionTitle.value,
      status_id: 1,
      question_category_id: this.f.questionCategory.value,
      question_text: this.f.questionText.value,
      question_type: 1,
      answer_explanation: this.f.answerExplanation.value,
      difficulty_level: this.f.difficultyLevel.value,
      media_files: [],
      options: []
    };

    this.init();
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.questionMCQId = params.dist;
        this.spinner.show();

        var single_url = "getQuestionById?question_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "true" && data.question[0]) {
            this.spinner.hide();
            this.questionMCQForm.get('questionTitle').setValue(data.question[0].title);
            this.questionMCQForm.get('questionText').setValue(data.question[0].question_text);
            this.questionMCQForm.get('answerExplanation').setValue(data.question[0].answer_explanation);
            this.questionMCQForm.get('questionCategory').setValue(data.question[0].category_id);
            this.questionMCQForm.get('difficultyLevel').setValue(data.question[0].difficulty_level);

            for (let i = 0; i < data.question[0].media_files.length; i++) {
              this.mediaFileCounter = data.question[0].media_files[i].id

              let mediaFilesExisting: MediaFiles = { id: this.mediaFileCounter, file: null, path: data.question[0].media_files[i].path, media_type: data.question[0].media_files[i].media_type, question_id: data.question[0].media_files[i].question_id };
              this.addMediaTable(mediaFilesExisting, this.mediaFileCounter, false);

              let controlName: any = MEDIA_FILE_OPTIONS + this.mediaFileCounter;
              this.questionMCQForm.get(controlName).setValue(data.question[0].media_files[i].media_type);
              this.updateMediaFileAcceptType(this.mediaFileCounter);
            }

            for (let i = 0; i < data.question[0].options.length; i++) {
              this.optionFileCounter = data.question[0].options[i].id
              let optionExisting: Options = { id: this.optionFileCounter, option: data.question[0].options[i].option, option_media_type: data.question[0].options[i].option_media_type, file: null, is_answer: data.question[0].options[i].is_answer, question_id: data.question[0].options[i].question_id, group: null };
              this.addOptionTable(optionExisting, this.optionFileCounter, false, data.question[0].options[i].is_answer);

              let controlName: any = OPTION_FILE_OPTIONS + this.optionFileCounter;
              this.questionMCQForm.get(controlName).setValue(data.question[0].options[i].option_media_type);
              if (data.question[0].options[i].option_media_type == 4) {
                let controlText: any = OPTION_TEXT + this.optionFileCounter;
                this.questionMCQForm.get(controlText).setValue(data.question[0].options[i].option);
              }

              this.updateOptionFileAcceptType(this.optionFileCounter);
            }

            console.log(this.questionMCQForm);
            console.log(this.questionMCQForm);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
      else {
        let mediaFilesNew: MediaFiles = { id: 0, path: null, media_type: 1, file: null, question_id: null };
        this.addMediaTable(mediaFilesNew, 0, true);

        let optionNew: Options = { id: 0, option: null, option_media_type: 4, file: null, is_answer: 0, question_id: null, group: null };
        this.addOptionTable(optionNew, 0, false, 0)

        this.isEdit = false;
        this.spinner.hide();
      }
    }
    );
  }


  isFieldValid(field: string) {
    return !this.questionMCQForm.get(field).valid && this.questionMCQForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  incrementMediaFileCounter(mediaType) {
    this.mediaFileCounter++;

    let mediaFilesNew: MediaFiles = { id: this.mediaFileCounter, path: null, media_type: mediaType, file: null, question_id: null };
    this.addMediaTable(mediaFilesNew, this.mediaFileCounter, true);
  }

  addMediaTable(mediaFile, controlId, fileUploadRequireValidation) {
    this.questionModel.media_files.push(mediaFile);

    this.questionMCQForm.addControl(MEDIA_FILE_OPTIONS + controlId, this.formBuilder.control('1'));
    if (fileUploadRequireValidation == true)
      this.questionMCQForm.addControl(MEDIA_FILE_DATA + controlId, this.formBuilder.control('', Validators.required));
    else
      this.questionMCQForm.addControl(MEDIA_FILE_DATA + controlId, this.formBuilder.control(''));
    this.selectedMediaFileOptions[controlId] = this.questionMCQForm.get(MEDIA_FILE_OPTIONS + controlId).value;
    this.updateMediaFileAcceptType(controlId);
  }

  incrementOptionFileCounter(mediaType) {
    this.optionFileCounter++;

    let optionNew: Options = { id: this.optionFileCounter, option: null, option_media_type: mediaType, file: null, is_answer: 0, question_id: null, group: null };
    this.addOptionTable(optionNew, this.optionFileCounter, false, 0);
  }

  addOptionTable(option, controlId, fileUploadRequireValidation, checked) {
    this.questionModel.options.push(option);

    this.questionMCQForm.addControl(OPTION_FILE_OPTIONS + controlId, this.formBuilder.control('4'));
    this.questionMCQForm.addControl(OPTION_TEXT + controlId, this.formBuilder.control('', Validators.required));
    if (fileUploadRequireValidation == true)
      this.questionMCQForm.addControl(OPTION_FILE_DATA + controlId, this.formBuilder.control('', Validators.required));
    else
      this.questionMCQForm.addControl(OPTION_FILE_DATA + controlId, this.formBuilder.control(''));
    this.questionMCQForm.addControl(OPTION_IS_ANSWER + controlId, this.formBuilder.control(checked));
    this.selectedOptionFileOptions[controlId] = this.questionMCQForm.get(OPTION_FILE_OPTIONS + controlId).value;
  }

  deleteMediaRow(id) {
    var delBtn = confirm(" Do you want to delete ?");
    if (delBtn == true) {
      this.questionModel.media_files = this.questionModel.media_files.filter(item => item.id !== id);
      this.questionMCQForm.removeControl(MEDIA_FILE_DATA + id);
      this.questionMCQForm.removeControl(MEDIA_FILE_OPTIONS + id);
    }
  }

  deleteOptionRow(id) {
    var delBtn = confirm(" Do you want to delete ?");
    if (delBtn == true) {
      this.questionModel.options = this.questionModel.options.filter(item => item.id !== id);
      this.questionMCQForm.removeControl(OPTION_FILE_OPTIONS + id);
      this.questionMCQForm.removeControl(OPTION_FILE_DATA + id);
      this.questionMCQForm.removeControl(OPTION_TEXT + id);
    }
  }

  init() {
    this.spinner.show();
    this.getQuestionCategories();
    this.getDifficultyLevels();
  }

  getQuestionCategories() {
    this.questionCategoryList = [];
    var list_fetch_url = "getQuestioncategories";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.question_categories[0]) {
        this.questionCategoryList = data.question_categories;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getDifficultyLevels() {
    this.difficultyLevelList = [];
    var list_fetch_url = "getDifficultylevels";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.difficulty_levels[0]) {
        this.difficultyLevelList = data.difficulty_levels;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  get f() { return this.questionMCQForm.controls; }
  onMediaFileChanged(event, id) {
    if (event.target.files && event.target.files.length) {

      let controlName: any = MEDIA_FILE_OPTIONS + id;

      this.questionModel.media_files.find(x => x.id === id).file = event.target.files[0];
      this.questionModel.media_files.find(x => x.id === id).path = event.target.files[0].name;
    }
  }

  onOptionFileChanged(event, id) {
    if (event.target.files && event.target.files.length) {

      let controlName: any = OPTION_FILE_OPTIONS + id;

      this.questionModel.options.find(x => x.id === id).file = event.target.files[0];
      this.questionModel.options.find(x => x.id === id).option = event.target.files[0].name;
    }
  }

  onOptionTextChanged(event, id) {
    this.questionModel.options.find(x => x.id === id).option = event.currentTarget.value;
  }

  onOptionIsAnswerChanged(event, id) {
    this.questionModel.options.find(x => x.id === id).is_answer = event.currentTarget.checked ? 1 : 0;
  }


  onMediaFileOptionsChanged(id) {
    //Delete the existing data of this row
    this.questionModel.media_files.find(x => x.id === id).path = null;
    this.questionModel.media_files.find(x => x.id === id).file = null;

    let controlMediaFileDataName: any = MEDIA_FILE_DATA + id;
    this.questionMCQForm.controls[controlMediaFileDataName].setValue(null);
    this.updateMediaFileAcceptType(id);
  }

  updateMediaFileAcceptType(id) {
    let controlName: any = MEDIA_FILE_OPTIONS + id;

    let controlMediaFileDataName: any = MEDIA_FILE_DATA + id;

    this.selectedMediaFileOptions[id] = this.questionMCQForm.get(controlName).value.toString();
    this.questionModel.media_files.find(x => x.id === id).media_type = this.questionMCQForm.get(controlName).value;

    if (this.questionModel.media_files.find(x => x.id === id).path != null)
      this.questionMCQForm.controls[controlMediaFileDataName].clearValidators();
    else
      this.questionMCQForm.controls[controlMediaFileDataName].setValidators(Validators.required);

    this.questionMCQForm.controls[controlMediaFileDataName].updateValueAndValidity();

    switch (this.questionMCQForm.get(controlName).value.toString()) {
      case "1": {
        this.selectedMediaFileType[id] = "image/*";
        break;
      }
      case "2": {
        this.selectedMediaFileType[id] = "audio/*";
        break;
      }
      case "3": {
        this.selectedMediaFileType[id] = "video/*";
        break;
      }
      default: {
        this.selectedMediaFileType[id] = "*/*";
        break;
      }
    }
  }


  onOptionFileOptionsChanged(id) {
    //Delete the existing data of this row
    this.questionModel.options.find(x => x.id === id).option = null;
    this.questionModel.options.find(x => x.id === id).file = null;
    let controloptionTextName: any = OPTION_TEXT + id;
    this.questionMCQForm.controls[controloptionTextName].setValue('');

    let controlOptionFileDataName: any = OPTION_FILE_DATA + id;
    this.questionMCQForm.controls[controlOptionFileDataName].setValue(null);

    this.updateOptionFileAcceptType(id);
  }

  updateOptionFileAcceptType(id) {
    let controlName: any = OPTION_FILE_OPTIONS + id;
    let controlOptionFileDataName: any = OPTION_FILE_DATA + id;
    let controloptionTextName: any = OPTION_TEXT + id;

    this.selectedOptionFileOptions[id] = this.questionMCQForm.get(controlName).value.toString();
    this.questionModel.options.find(x => x.id === id).option_media_type = this.questionMCQForm.get(controlName).value;

    this.questionMCQForm.controls[controlOptionFileDataName].setValidators(Validators.required);
    this.questionMCQForm.controls[controloptionTextName].setValidators(Validators.required);

    switch (this.questionMCQForm.get(controlName).value.toString()) {
      case "1": {
        this.selectedOptionFileType[id] = "image/*";
        if (this.questionModel.options.find(x => x.id === id).option != null)
          this.questionMCQForm.controls[controlOptionFileDataName].clearValidators();
        this.questionMCQForm.controls[controloptionTextName].clearValidators();
        break;
      }
      case "2": {
        this.selectedOptionFileType[id] = "audio/*";
        if (this.questionModel.options.find(x => x.id === id).option != null)
          this.questionMCQForm.controls[controlOptionFileDataName].clearValidators();
        this.questionMCQForm.controls[controloptionTextName].clearValidators();
        break;
      }
      case "3": {
        this.selectedOptionFileType[id] = "video/*";
        if (this.questionModel.options.find(x => x.id === id).option != null)
          this.questionMCQForm.controls[controlOptionFileDataName].clearValidators();
        this.questionMCQForm.controls[controloptionTextName].clearValidators();
        break;
      }
      default: {
        this.selectedOptionFileType[id] = "*/*";
        this.questionMCQForm.controls[controlOptionFileDataName].clearValidators();
        break;
      }
    }
    this.questionMCQForm.controls[controlOptionFileDataName].updateValueAndValidity();
    this.questionMCQForm.controls[controloptionTextName].updateValueAndValidity()
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  onSubmit() {

    this.submitted = true;
    console.log(this.f)

    const invalid = [];
    const controls = this.questionMCQForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    if (this.questionMCQForm.invalid) {
      this.validateAllFormFields(this.questionMCQForm);
      return;
    }

    this.questionModel.title = this.f.questionTitle.value;
    this.questionModel.status_id = this.f.status.value;
    this.questionModel.question_category_id = this.f.questionCategory.value;
    this.questionModel.question_text = this.f.questionText.value;
    this.questionModel.question_type = 1;
    this.questionModel.answer_explanation = this.f.answerExplanation.value;
    this.questionModel.difficulty_level = this.f.difficultyLevel.value;

    for (let i = 0; i < this.questionModel.media_files.length; i++) {
      if (this.questionModel.media_files[i].file != undefined) {
        this.uploadService.fileUpload(this.questionModel.media_files[i].file, 'questionMCQ').then(res => {
          if (res) {
            this.questionModel.media_files[i].path = res as any;
          }
        });
      }
    }

    for (let i = 0; i < this.questionModel.options.length; i++) {
      if (this.questionModel.options[i].option_media_type != 4)
        if (this.questionModel.options[i].file != undefined) {
          this.uploadService.fileUpload(this.questionModel.options[i].file, 'questionMCQ').then(res => {
            if (res) {
              this.questionModel.options[i].option = res as any;
            }
          });
        }
    }

    if (!this.isEdit) {
      this.spinner.show();
      const uploadData = new FormData();

      var url = "add_question";
      this.api.detailsUploadFetch(this.questionModel, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "TRUE") {
          this.spinner.hide();

          this.questionMCQForm.reset();
          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup();
          this.router.navigate(['question'])
        }
        else {
          this.spinner.hide();

        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else {
      if (this.isEdit) {
        this.spinner.show();

        var update_url = "update_question?question_id=" + this.questionMCQId;
        this.api.detailsUploadFetch(this.questionModel, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.questionMCQForm.reset();
            this.submitted = false;

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['question'])
          }
          else {
            this.spinner.hide();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
  }
}
