import { NgModule, } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DiscussionForumComponent } from './discussion-forums/discussion-forum.component';
import { AddForumComponent } from './add-forum/add-forum.component';
import { ViewForumComponent } from './view-forum/view-forum.component';



const routes: Routes = [

  {
    path: '', component: DiscussionForumComponent
},
{
  path: 'addforum', component: AddForumComponent 
},
{
  path: 'view-forum/:threadId', component: ViewForumComponent 
},


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DiscussionRoutingModule { }
