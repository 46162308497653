<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/sme']">SME</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add SME</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit SME</li>

          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add SME</h1>
        <h1 *ngIf="isEdit">Edit SME</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="smeForm" (ngSubmit)="onSubmit()">
                <div class="col-md-6 float-left">
                  <div class="form-group float-left" style="width: 30%;">
                    <label>Prefix *</label>
                    <select class="form-control" formControlName="namePrefixes"
                      [ngClass]="{ 'is-invalid': submitted && f.namePrefixes.value == 0 }">
                      <option value="0"> Select Prefix </option>
                      <option [ngValue]="option.title" *ngFor="let option of namePrefix">{{option.title}}</option>
                    </select>
                    <div *ngIf="submitted && f.namePrefixes.value == 0" class="invalid-feedback">

                      Prefix is
                      required

                    </div>
                  </div>
                  <div class="form-group float-left pl-2" style="width: 70%;">
                    <label>First Name *</label>
                    <input type="text" formControlName="first_name" class="form-control selact_"
                      placeholder="Enter First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                    <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                      Enter A Valid First Name
                      <!-- <div *ngIf="f.first_name.errors.required">
                        First Name is
                        required
                      </div> -->
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Last Name *</label>
                    <input type="text" formControlName="last_name" class="form-control selact_"
                      placeholder="Enter Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                    <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                      Enter A Valid Last Name
                      <!-- <div *ngIf="f.last_name.errors.required">
                        Last Name is
                        required
                      </div> -->
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Email Address *</label>
                    <input type="text" formControlName="email" class="form-control selact_"
                      placeholder="Enter Email Address" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">
                        Email Address is
                        required
                      </div>
                      <div *ngIf="f.email.errors.email">
                        Email must be a valid email address</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Phone Number *</label>
                    <input type="text" formControlName="phone_number" class="form-control selact_"
                      placeholder="Enter Phone Number" [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }">

                    <div *ngIf="submitted && smeForm.get('phone_number').invalid ">
                      <small *ngIf="smeForm.get('phone_number').errors?.required" class="text-danger">Phone
                        Number is required</small>
                      <small
                        *ngIf="!smeForm.get('phone_number').errors.invalidNumber && (smeForm.get('phone_number').minLength || !smeForm.get('phone_number').maxLength)"
                        class="text-danger">Phone number should be between 8-12 numbers</small>

                      <small
                        *ngIf="!smeForm.get('phone_number').errors?.required && smeForm.get('phone_number').errors.invalidNumber"
                        class="text-danger">Invalid
                        Phone Number</small>
                    </div>
                    <!-- <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                                    <div *ngIf="f.phone_number.errors.required">
                                      Phone Number is
                                      required
                                    </div>
                                  </div> -->
                  </div>
                  <div *ngIf="!isEdit" class="form-group">
                    <label>Password *</label>
                    <input type="password" formControlName="password" class="form-control selact_"
                      placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">
                        Password isrequired</div>
                      <div *ngIf="f.password.errors.minlength">
                        Password must be at least 6 characters</div>
                      <div *ngIf="f.password.errors.maxlength">
                        Password must be maximum 20 characters</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Description *</label>
                    <textarea class="form-control selact_" formControlName="description" placeholder="Enter Description"
                      [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                      Description is
                      required
                      <!-- <div *ngIf="f.description.errors.required">
                        Description is
                        required
                      </div> -->
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Status *</label>
                    <select formControlName="status" class="form-control select2 selact_" style="width: 100%;"
                      [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                      <option value="1">Active</option>
                      <option value="2">Disabled</option>
                    </select>
                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                      <div *ngIf="f.status.errors.required">
                        Status is
                        required
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="clearfix"></div> -->
                <div class="col-md-4 float-left">
                  <div class="form-group wid_75">
                    <label>SME Image</label>
                    <div class="uploadedImg student-image-profile sme_image_a">
                      <input type="file" class="mediaFile" formControlName="fileData" id="exampleInputFile"
                        (change)="onFileChanged($event)" [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }"
                        accept="image/*">
                      <span class="up_img">Upload image</span>

                      <img [src]="imageSrc" *ngIf="imageSrc">
                    </div>
                    <div *ngIf="submitted && f.fileData.errors" class="text-danger">
                      <small *ngIf="f.fileData.errors.required">SME Image is
                        required
                      </small>
                     </div>
                     <div class="note_a p-3"><label>* Preffered image resolution 300x300px</label></div>
                  </div>

                </div>
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Save</button>
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>
