<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-sm-left">
                        <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/admin/country']">Countries</a></li>
                        <li *ngIf="!isEdit" class="breadcrumb-item active">Add Country</li>
                        <li *ngIf="isEdit" class="breadcrumb-item active">Edit Country</li>

                    </ol>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="card elevation-0">
            <div class="card-header">
                <h1 *ngIf="!isEdit">Add Country</h1>
                <h1 *ngIf="isEdit">Edit Country</h1>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-primary p-3 pb-5 add_contry_">
                            <form [formGroup]="countryForm" (ngSubmit)="onSubmit()">
                                <div class="col-md-6 float-left">
                                    <div class="form-group">
                                        <label>Country *</label>
                                        <input type="text" formControlName="countryName" class="form-control selact_"
                                            placeholder="Country"
                                            [ngClass]="{ 'is-invalid': submitted && f.countryName.errors }">
                                        <div *ngIf="submitted && f.countryName.errors" class="invalid-feedback">
                                            <div *ngIf="f.countryName.errors.required">Country is
                                                required
                                            </div>
                                            <div *ngIf="f.countryName.errors.pattern">Valid country name is
                                                required
                                            </div>
                                            <div *ngIf="f.countryName.errors.validspace"> Invalid country name 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Status *</label>
                                        <select formControlName="status" class="form-control select2 selact_"
                                            style="width: 100%;"
                                            [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                            <option value="1" >Active</option>
                                            <option value="2">Disabled</option>
                                        </select>
                                        <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                            <div *ngIf="f.status.errors.required">Status is
                                                required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- <div class="clearfix"></div> -->
                                <!-- <div class="col-md-4 float-left">
                                    <div class="form-group">
                                        <label>Country Image</label>
                                        <div class="uploadedImg">
                                            <input type="file" class="mediaFile" formControlName="fileData"
                                                id="exampleInputFile" (change)="onFileChanged($event)"
                                                [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }">
                                            <span class="up_img">Upload image</span>

                                            <img [src]="imageSrc" *ngIf="imageSrc">
                                        </div>
                                         <div *ngIf="submitted && f.fileData.errors" class="invalid-feedback">
                                            <div *ngIf="f.fileData.errors.required">Country Image is
                                                required
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div> -->

                                <div class="clearfix"></div>
                                <div class="p-3">
                                    <button type="submit"
                                        class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </section>
</div>
