import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { Page } from '../../../models/pageDto';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToasterService } from 'src/app/services/toaster.service';
import { PopupService } from 'src/app/services/popup.service';



@Component({
  selector: 'app-discussion-forum',
  templateUrl: './discussion-forum.component.html',
  styleUrls: ['./discussion-forum.component.css']
})
export class DiscussionForumComponent implements OnInit {

  session_id: any;
  page: Page;
  forumDetais: any = [];
  temp = [];
  threadId: any;
  @ViewChild('myTable') table: DiscussionForumComponent;
  searchTerm: any = '';
  discussionForumForm: FormGroup;
  deleteList: any = [];
  selected = [];


  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private spinner: NgxSpinnerService, private ToasterService: ToasterService,private popup: PopupService,) { }

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.discussionForumForm = this.formBuilder.group({
      search: ['']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    this.setPage({ offset: 1 });
  }
  setPage(pageInfo) {
    debugger
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.forumDetais = [];
    var forum_fetch_url = "getAllThreads?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    if (this.searchTerm.length > 0)
      forum_fetch_url += "&searchTerm=" + this.searchTerm;
    this.api.detailsGetFetch(forum_fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.threads[0]) {
        this.temp = data.threads;
        this.forumDetais = data.threads;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }
  onSearch() {
    this.searchTerm = this.discussionForumForm.get('search').value.toString();
    this.setPage({ offset: 1 });
  }
  viewForum(threadId) {
   
    this.router.navigate(['admin/discussion-forum/view-forum/'+threadId]);
  }
  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  deleteForum(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var body = { "thread_id": +id };
        var del_url = "deleteAllThreadById?thread_id=" + `${id}`;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          debugger
          if (data.status == "True") {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'success');
            this.setPage({ offset: 1 });
          }
          else {
            this.spinner.hide();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }

  deleteMultipleItem(){
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteList.push(element.id);
      });
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          var body = { "ids": this.deleteList };
          var del_url = "discussion_forum_multi_delete";
          this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
            debugger
            this.deleteList = [];
            if (data.status == "True") {
              this.spinner.hide();
              this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
              this.setPage({ offset: 0 });
            }
            else {
              this.spinner.hide();
              this.popup.failureMessage = data.message
              this.popup.failurepopup()
            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
  
                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            }); 
        }else{
          this.deleteList = [];
        }
      })
    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    } 
  }
}
