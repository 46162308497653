<div id="wrapper">
  <!-- Page Content -->
  <div id="page-content-wrapper_a">
      <button type="button" class="btn-close close_button_ float-right" (click)="closepopup()"
      aria-label="Close"></button>
    <form [formGroup]="mycourcefeedbackForm" role="form" class="">
      <div class="container-fluid py-4 overview_course" *ngIf="!data.isNote">

        <div class="pt-2 pb-2 mt-2 text-left btns float-left widht_100">
          <div class="video_rating text-center py-2">
            <div class="mb-3 pb-2">
              <h2>Rate this section</h2>
            </div>

            <div class="mx-auto">
              <mat-radio-group aria-label="Select an option"  class="rating">
                <input type="radio" value="5" id="5" [checked] = "ratingVal == 5 ? true : false">
                <label for="5" (click)=ratingValue(5)>☆</label>
                <input type="radio" value="4" id="4" [checked] = "ratingVal == 4 ? true : false">
                <label for="4" (click)=ratingValue(4)>☆</label>
                <input type="radio" value="3" id="3" [checked] = "ratingVal == 3 ? true : false">
                <label for="3" (click)=ratingValue(3)>☆</label>
                <input type="radio" value="2" id="2" [checked] = "ratingVal == 2 ? true : false">
                <label for="2" (click)=ratingValue(2)>☆</label>
                <input type="radio" value="1" id="1" [checked] = "ratingVal == 1 ? true : false">
                <label for="1" (click)=ratingValue(1)>☆</label>
              </mat-radio-group>
            </div>
            <!-- <div *ngIf="submitted && !ratingVal" class="text-danger">
              <small>
                Rating is required
              </small>
            </div> -->

          </div>
          <div class="clearfix"></div>
          <div class="form-group col-md-12 float-left">
            <h3>Comment</h3>
            <textarea formControlName="comment" class="form-control" placeholder="Comment"></textarea>
            <div *ngIf="submitted && f.comment.errors" class="text-danger">
              <!-- <small *ngIf="f.comment.errors.required">
                Comment is required
              </small> -->
              <small *ngIf="f.comment.errors.validspace"> Invalid Comment. </small>
            </div>
          </div>
          <div *ngIf="submitted && this.ratingVal == 0 && f.comment.value ==''" class="text-danger form-group col-md-12 float-left" >
            Give Rating or Comment
          </div>
          <div class="clearfix"></div>

          <div mat-dialog-actions class="modal-footer">
            <button mat-button type="button" class="btn btn-outline-danger btn-sm" (click)="skipFeedback()">Skip rating and mark as completed</button>
            <button mat-button type="button" class="btn btn-outline-success btn-sm" cdkFocusInitial (click)="updateFeedBack()">Save</button>
          </div>


          <!-- <div class="pt-3 pb-3 mt-4 text-left border-top">
            <a class="btn btn-dark float-right ml-1" (click)="skipFeedback()">Skip</a>
          </div>
          <div class="pt-3 pb-3 mt-4 text-left border-top">
            <a class="btn btn-dark float-right ml-1" (click)="updateFeedBack()">Ok</a>
          </div> -->
        </div>

      </div>

      <!-- <div class="container-fluid py-4 overview_course" *ngIf="data.isNote">

        <div class="pt-2 pb-2 mt-2 text-left btns float-left widht_100">

          <div class="clearfix"></div>
          <div class="form-group col-md-12 float-left">
            <h3>Description</h3>
            <textarea formControlName="description" class="form-control" placeholder="Enter Description"></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">
                Description is required
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="pt-3 pb-3 mt-4 text-left border-top">
            <a class="btn btn-dark float-right ml-1" (click)="updateNotes()">Ok</a>
          </div>
        </div>

      </div> -->



    </form>
  </div>
</div>