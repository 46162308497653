import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-web-resetpassword',
  templateUrl: './web-resetpassword.component.html',
  styleUrls: ['./web-resetpassword.component.css']
})
export class WebResetpasswordComponent implements OnInit {
  webResetPasswordForm: FormGroup;
  isSubmit: boolean = false;
  tokenValue: any;


  constructor(private formBuilder: FormBuilder, public router: Router, private route: ActivatedRoute, private popup: PopupService, public api: ApiService, private spinner: NgxSpinnerService, private ToasterService: ToasterService) { }

  ngOnInit(): void {
    this.webResetPasswordForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      });

    this.route.params.subscribe(params=>{
      console.log(params);
      if(params.token)
      this.tokenValue=params.token
      else
      {
        this.ToasterService.openSnackBar('Unauthorized Entry', '', 'warning');
        this.router.navigate(['login']);
      }
    });
  }

  webResetPassword() {
    debugger
    this.isSubmit = true;
    if (this.webResetPasswordForm.invalid) {
      return;
    }
    var fetch_url="student/resetpassword";
    let param = { password: this.webResetPasswordForm.value.newPassword,
      password_confirmation: this.webResetPasswordForm.value.confirmPassword,
      token: this.tokenValue
       }
       debugger
     this.api.postDetails(fetch_url,param).subscribe((data: any) => {
       debugger
       if(data.result.status == "true" && data.result){
        
        // this.popup.sucessMessage = data.message;
        // this.popup.sucesspopup();
        this.spinner.hide();
        this.ToasterService.openSnackBar('Password Changed successfully', '', 'success');
          // localStorage.setItem('user_Id', data.user_id);
          // localStorage.setItem('user_name', data.name);
          // localStorage.setItem('access_token', data.access_token);
          // localStorage.setItem('refresh_token', data.refresh_token);


          // this.WebComponent.islogin = true;
          // this.LocaldataService.updateUser();   
          this.router.navigate(['login']);
       }
       else {
        this.spinner.hide();
        this.ToasterService.openSnackBar(data.result.message, '', 'warning');
        // this.WebComponent.islogin = false;
      }
     });
    
  }

}
