import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service'
import { Title, Meta } from "@angular/platform-browser";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-banner-add',
  templateUrl: './banner-add.component.html',
  styleUrls: ['./banner-add.component.css']
})
export class BannerAddComponent implements OnInit {
  selectedWebFile: File;
  selectedMobileFile: File;
  isEdit: boolean = false;
  session_id: any;
  webImageSrc: any;
  mobileImageSrc: any;
  bannerId: any;
  fileName = '';
  p: number = 1;
  s3FilePath: string;
  s3FileName: any;
  s3WebFileName: any;
  s3MobileFileName: any;
  ckeConfig: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute,
    private popup: PopupService, private spinner: NgxSpinnerService, private uploadService: UploadService,
    private constantService: ConstantsService, private title: Title, private meta: Meta,) { 
      
  
     }
  bannerForm: FormGroup;
  submitted = false;

  ngOnInit(): void {

    this.ckeConfig = {
      allowedContent: true,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        // { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About,Styles,Font,FontSize'
      // removePlugins: 'horizontalrule,about,list,others',
      // removeButtons: 'Save,NewPage,Preview,Print,Templates,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Find,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,PageBreak,Iframe,Font,TextColor,BGColor,ShowBlocks,Cut,Copy,Paste,Table,Image,Format,Maximize,Styles,Anchor,SpecialChar,PasteFromWord,PasteText,Scayt,Undo,Redo,Strike,RemoveFormat,Indent,Outdent,Blockquote,Link,Unlink,Subscript,Superscript'
    };
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;

    this.bannerForm = this.formBuilder.group({
      bannerText: ['', [Validators.required, Validatespace]],
      status: ['', Validators.required],
      link: ['', [
        Validators.required,
        Validatespace,
        Validators.pattern(
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        ),
      ],],
      webFileData: ['', Validators.required],
      mobileFileData: ['', Validators.required],
      description: ['', [Validators.required, Validatespace]]
    });
    this.bannerForm.get('status').setValue('1');
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.selectedWebFile = null;
        this.selectedMobileFile = null;
        this.bannerId = params.dist;
        this.spinner.show();

        var single_url = "edit_banner?banner_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          debugger
          console.log(data)
          if (data.status == "true" && data.banner[0]) {
            this.spinner.hide();

            this.bannerForm.get('bannerText').setValue(data.banner[0].banner_text);
            this.bannerForm.get('link').setValue(data.banner[0].link);
            this.bannerForm.get('description').setValue(data.banner[0].description);
            this.bannerForm.get('status').setValue(data.banner[0].status_id);
            this.bannerForm.get('webFileData').setValidators(null);
            this.bannerForm.get('webFileData').setValue("");
            this.bannerForm.get('mobileFileData').setValidators(null);
            this.bannerForm.get('mobileFileData').setValue("");
            this.webImageSrc = this.s3FilePath + data.banner[0].web_image;
            this.mobileImageSrc = this.s3FilePath + data.banner[0].mobile_image;
            console.log(this.bannerForm);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
        this.title.setTitle("Edit Banner | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Edit Banner | Admin",
        });
      }
      else {
        this.isEdit = false;
        this.title.setTitle("Add Banner | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Edit Banner| Admin",
        });
        this.spinner.hide();

      }
    }
    );
  }


  public hasError = (controlName: string, errorName: string) => {
    return this.bannerForm.get(controlName).hasError(errorName);
  };

  get f() { return this.bannerForm.controls; }
  onWebFileChanged(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      console.log(this.selectedWebFile);

      this.selectedWebFile = event.target.files[0];
      console.log(this.selectedWebFile.name);
      let imagePath_ = this.selectedWebFile.type;
      var ext = imagePath_.split('/');
      if (ext[0] == "image" ) {

      reader.onload = () => {

        this.webImageSrc = reader.result as string;


      }
      reader.readAsDataURL(this.selectedWebFile);
      console.log(this.selectedWebFile)
    } else {
      this.bannerForm.get('webFileData').setValue('');
      this.bannerForm.get('webFileData').setErrors({ required: true });
      this.webImageSrc = ""
    }
    }

  }
  onMobileFileChanged(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      console.log(this.selectedMobileFile);

      this.selectedMobileFile = event.target.files[0];
      console.log(this.selectedMobileFile.name);
      let imagePath_ = this.selectedMobileFile.type;
      var ext = imagePath_.split('/');
      if (ext[0] == "image") {

      reader.onload = () => {

        this.mobileImageSrc = reader.result as string;


      }
      reader.readAsDataURL(this.selectedMobileFile);
      console.log(this.selectedMobileFile)
    } else {
      this.bannerForm.get('mobileFileData').setValue('');
      this.bannerForm.get('mobileFileData').setErrors({ required: true });
      this.mobileImageSrc = ""
    }

    }
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.f)

    if (this.bannerForm.invalid) {
      return;
    }

    this.spinner.show();
    const uploadData = new FormData();
    // uploadData.append('web_image', s3WebFileName);
    // uploadData.append('mobile_image', s3MobileFileName);
    uploadData.append('banner_text', this.f.bannerText.value);
    uploadData.append('link', this.f.link.value);
    uploadData.append('description', this.f.description.value);
    uploadData.append('status_id', this.f.status.value);
    if (this.selectedWebFile == null) {
      uploadData.append('web_image', this.webImageSrc.replace(this.s3FilePath, ''));
      this.addEditSubmit(uploadData);
    } else {
      this.uploadService.fileUpload(this.selectedWebFile, 'banner').then(res => {
        if (res) {
          this.s3WebFileName = res
          uploadData.append('web_image', this.s3WebFileName);
          this.addEditSubmit(uploadData);
        }
      });
    }
    if (this.selectedMobileFile == null) {
      uploadData.append('mobile_image', this.mobileImageSrc.replace(this.s3FilePath, ''));
      this.addEditSubmit(uploadData);
    } else {
      this.uploadService.fileUpload(this.selectedMobileFile, 'banner').then(res => {
        if (res) {
          this.s3MobileFileName = res
          uploadData.append('mobile_image', this.s3MobileFileName);
          this.addEditSubmit(uploadData);
        }
      });
    }
  }

  addEditSubmit(uploadData) {
    var fetch_url = null;
    if (this.isEdit) {
      fetch_url = "update_banner";
      uploadData.append('banner_id', this.bannerId);
    }
    else {
      fetch_url = "add_banner";
    }
    this.api.detailsUploadFetch(uploadData, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      console.log(data)
      if (data.status == "TRUE") {
        this.webImageSrc = "";
        this.bannerForm.reset();
        this.submitted = false;
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        this.router.navigate(['admin/banner'])

      }
      else {
        this.spinner.hide();

      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });


  }
}


// const webFile = this.selectedWebFile;
// const mobileFile = this.selectedMobileFile;

// if (webFile != null)
//   var s3WebFileName
// this.uploadService.fileUpload(webFile, 'banner').then(res => {
//   if (res) {
//     s3WebFileName = res

//     if (mobileFile != null)
//       var s3MobileFileName
//     this.uploadService.fileUpload(mobileFile, 'banner').then(res => {
//       if (res) {
//         s3MobileFileName = res


//         if (!this.isEdit) {
//           this.spinner.show();
//           const uploadData = new FormData();
//           uploadData.append('web_image', s3WebFileName);
//           uploadData.append('mobile_image', s3MobileFileName);
//           uploadData.append('banner_text', this.f.bannerText.value);
//           uploadData.append('link', this.f.link.value);
//           uploadData.append('status_id', this.f.status.value);

//           var url = "add_banner";
//           this.api.detailsUploadFetch(uploadData, url, this.session_id).subscribe((data: any) => {
//             console.log(data)
//             if (data.status == "TRUE") {
//               this.spinner.hide();

//               this.webImageSrc = "";
//               this.mobileImageSrc = "";
//               this.bannerForm.reset();
//               this.submitted = false;
//               this.popup.sucessMessage = data.message
//               this.popup.sucesspopup();
//               this.router.navigate(['admin/banner'])
//             }
//             else {
//               this.spinner.hide();

//             }
//           },
//             (err: HttpErrorResponse) => {
//               if (err.status == 403) {
//                 this.spinner.hide();

//                 localStorage.clear()
//                 this.router.navigate(['admin/login'])
//               }
//             });
//         }
//         else {
//           if (this.isEdit) {
//             this.spinner.show();

//             const uploadData = new FormData();
//             console.log(this.selectedWebFile)
//             console.log(this.selectedMobileFile)
//             if (this.selectedWebFile == null) {
//               uploadData.append('web_image', this.webImageSrc.replace(this.s3FilePath, ''));
//             }
//             else {
//               uploadData.append('web_image', s3WebFileName);
//             }

//             if (this.selectedMobileFile == null) {
//               uploadData.append('mobile_image', this.mobileImageSrc.replace(this.s3FilePath, ''));
//             }
//             else {
//               uploadData.append('mobile_image', s3MobileFileName);
//             }
//             uploadData.append('banner_id', this.bannerId);
//             uploadData.append('banner_text', this.f.bannerText.value);
//             uploadData.append('link', this.f.link.value);
//             uploadData.append('status_id', this.f.status.value);

//             var update_url = "update_banner";
//             this.api.detailsUploadFetch(uploadData, update_url, this.session_id).subscribe((data: any) => {
//               console.log(data)
//               if (data.status == "TRUE") {
//                 this.spinner.hide();

//                 this.webImageSrc = "";
//                 this.mobileImageSrc = "";
//                 this.bannerForm.reset();
//                 this.submitted = false;

//                 this.popup.sucessMessage = data.message
//                 this.popup.sucesspopup()
//                 this.router.navigate(['admin/banner'])
//               }
//               else {
//                 this.spinner.hide();

//               }
//             },
//               (err: HttpErrorResponse) => {
//                 if (err.status == 403) {
//                   this.spinner.hide();

//                   localStorage.clear()
//                   this.router.navigate(['admin/login'])
//                 }
//               });
//           }
//         }
//       }
//     });
//   }
// });
//   }
// }
