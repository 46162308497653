import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-page-add',
  templateUrl: './page-add.component.html',
  styleUrls: ['./page-add.component.css']
})
export class PageAddComponent implements OnInit {
  isEdit: boolean = false;
  session_id: any;
  pageId: any;
  pageList = [];
  ckeConfig: any;
  
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService) { }
  pageForm: FormGroup;
  submitted = false;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')

    this.pageForm = this.formBuilder.group({
      title: ['', Validators.required],
      status: ['', Validators.required],
      description: [''],
      metakeyword: ['', Validators.required],
      metadescription: ['', Validators.required],
      
    });
    this.ckeConfig = {
      allowedContent: true,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        // { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About,Styles,Font,FontSize'
      // removePlugins: 'horizontalrule,about,list,others',
      // removeButtons: 'Save,NewPage,Preview,Print,Templates,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Find,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,PageBreak,Iframe,Font,TextColor,BGColor,ShowBlocks,Cut,Copy,Paste,Table,Image,Format,Maximize,Styles,Anchor,SpecialChar,PasteFromWord,PasteText,Scayt,Undo,Redo,Strike,RemoveFormat,Indent,Outdent,Blockquote,Link,Unlink,Subscript,Superscript'
    };
    this.pageForm.get('status').setValue('1');
    this.init();
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.pageId = +params.dist;
        this.spinner.show();

        var single_url = "page_edit?page_id=" + this.pageId;
        debugger;
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "true" && data.page[0]) {
            this.spinner.hide();

            this.isEdit = true;
            // $("html, body").animate({ scrollTop: 0 });
            this.pageForm.get('title').setValue(data.page[0].title);
            this.pageForm.get('status').setValue(data.page[0].status_id);
            this.pageForm.get('description').setValue(data.page[0].description);
            this.pageForm.get('metakeyword').setValue(data.page[0].meta_keyword);
            this.pageForm.get('metadescription').setValue(data.page[0].meta_description);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });

      }
      else {
        // this.spinner.hide();

        this.isEdit = false;
      }
    }
    );
  }
  get f() { return this.pageForm.controls; }
  init() {
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.f)

    if (this.pageForm.invalid) {
      return;
    }
    if (!this.isEdit) {
      this.spinner.show();
      debugger
      const body = new FormData();
      // body.append('page_id', this.pageId);
      body.append('title', this.f.title.value);
      body.append('status_id', this.f.status.value);
      body.append('description', this.f.description.value);
      body.append('meta_keyword', this.f.metakeyword.value);
      body.append('meta_description', this.f.metadescription.value);

      var url = "add_page";

    
      this.api.detailsUploadFetch(body, url, this.session_id).subscribe((data: any) => {
        debugger
        console.log(data)
        if (data.status == "True") {
          this.spinner.hide();
          this.pageForm.reset();
          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup()
          this.router.navigate(['admin/page'])
          
        }
        else {
          this.spinner.hide();

          this.popup.failureMessage = data.error.title[0];
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else {
      if (this.isEdit) {
        debugger
        this.spinner.show();

        var update_body = {"title": this.f.title.value,  "status_id": this.f.status.value,  "description": this.f.description.value, "meta_keyword": this.f.metakeyword.value, "meta_description": this.f.metadescription.value, "page_id": this.pageId};
        var update_url = "update_page" ;
        debugger;
        this.api.detailsAddFetch(update_body, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          debugger
          if (data.status == "true") {
            this.spinner.hide();
            debugger
            this.pageForm.reset();
            this.submitted = false;
            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/page'])
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            debugger;
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
  }

}
