import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { ConstantsService } from './constants.service';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private constantService: ConstantsService) { }
  fileUpload(file, folderName) {
    return new Promise((resolve, reject) => {
      debugger
      console.log(file);
      var fileName = folderName + "/" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.' + file.name.split('.').pop();
      var newFileName = "public/" + fileName;
      const contentType = file.type;
      const bucket = new S3(
        {
          accessKeyId: this.constantService.s3AccessKeyId,
          secretAccessKey: this.constantService.s3SecretAccessKey,
          region: this.constantService.s3Region,
        }
      );
      const params = {
        Bucket: this.constantService.s3Bucket,
        Key: newFileName,
        ACL: 'public-read',
        Body: file,
        ContentType: contentType,
      };
      bucket.upload(params, function (err, data) {
        if (err) {
          console.log('ERROR: ', JSON.stringify(err));
          resolve(false);
          //return '';
        }
        console.log('File Uploaded.', data);
        resolve(fileName);
        //return fileName;
      });
      //return fileName;
    });
  }

  fileDelete(file, folderName): string {
    console.log(file);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: this.constantService.s3AccessKeyId,
        secretAccessKey: this.constantService.s3SecretAccessKey,
        region: this.constantService.s3Region,
      }
    );
    const params = {
      Bucket: this.constantService.s3Bucket,
      Key: folderName + file
    };
    bucket.deleteObject(params, function (err, data) {
      if (err) {
        console.log('There was an error deleting your file: ', err.message);
        return;
      }
      console.log('Successfully deleted file.');
      return file;
    });
    return file;
  }


}
