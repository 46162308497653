import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Page } from 'src/app/models/pageDto';
import { ToasterService } from 'src/app/services/toaster.service';
import Swal from 'sweetalert2';
import { PopupService } from 'src/app/services/popup.service';


@Component({
  selector: 'app-list-live-class',
  templateUrl: './list-live-class.component.html',
  styleUrls: ['./list-live-class.component.css']
})
export class ListLiveClassComponent implements OnInit {

  @ViewChild('myTable') table: ListLiveClassComponent;
  session_id: any;
  liveClassList: any = [];
  page: Page;
  deleteList: any = [];
  deleteListLiveClass: any = [];
  selected = [];
  batchesArray: any = [];
  batches: any;

  constructor(private router:Router, private api: ApiService, private spinner: NgxSpinnerService, private ToasterService: ToasterService, private popup: PopupService,) { 
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    
   }

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN'); 
    this.setPage({ offset: 1 });

  }

  setPagetest(pageInfo) {
    debugger
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  
  setPage(pageInfo) {
    this.spinner.show();
    debugger
     this.page.current_page = pageInfo.offset;
     let url="student_live_url_send_list?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
     this.api.detailsGetFetch(url, this.session_id).subscribe((data: any)=>{
       debugger
       if(data.status == "true" && data.send_live_url_data){
        this.spinner.hide();
         this.liveClassList = data.send_live_url_data;
        //  this.resources.forEach(element => {
        //   if (this.courseContentTypeId == element.id) {
        //     this.resourceHeadding = element.content_type;
        //     this.contentTypeName = element.media_type
        //     this.course_content_type_id = element.id;
        //     this.allowedImageTypes = [];
        //   }
        // });
        
        for (let content of this.liveClassList){
          this.batches = content.batch_start_dates.join(',');
          console.log(this.batches);
          this.batchesArray.push(this.batches);
          console.log(this.batchesArray);
        }

        console.log(this.batchesArray);
         
         data.pagination.current_page = data.pagination.current_page - 1;
          this.page = data.pagination;
       }else{
 
       }
     },(err: HttpErrorResponse) => {
       if (err.status == 403) {
         this.spinner.hide();
         localStorage.clear()
         this.router.navigate(['admin/login'])
       }
     
     });
  }

  onDeleteCheckbox(id) : void{
    debugger
    let found : boolean = false;
    for(let i=0;i<this.deleteList.length; i++){
      if(this.deleteList[i] == id){
        this.deleteList.splice(i,1);
        found = true
      }
    }

    if(!found){
      this.deleteList.push(id)
    }
    console.log(this.deleteList);
  }

  // bulkDelete() : void{
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.spinner.show();
  //       var body = { "live_url_id": this.deleteList};
  //       var del_url = "send_live_url_data_delete";
  //       this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
  //         debugger
  //         if (data.status == "true") {
  //           this.deleteList = [];
  //           this.spinner.hide();
  //           this.ToasterService.openSnackBar(data.message, '', 'success');
  //           this.setPage({ offset: 0 });
  //         }
  //         else {
  //           this.spinner.hide();
  //           this.ToasterService.openSnackBar(data.message, '', 'failed');
  //         }
  //       },
  //         (err: HttpErrorResponse) => {
  //           if (err.status == 403) {
  //             this.spinner.hide();

  //             localStorage.clear()
  //             this.router.navigate(['admin/login'])
  //           }
  //         });


  //     }
  //   })
  // }

  deleteCourse(id) {
    // this.deleteList.push(id);
    // this.bulkDelete();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var body = { "live_url_id": id};
        var del_url = "send_live_url_data_delete";
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          debugger
          if (data.status == "True") {
            this.deleteList = [];
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'success');
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'failed');
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }

  deleteMultipleItem(){
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteListLiveClass.push(element.id);
      });
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          var body = { "ids": this.deleteListLiveClass };
          var del_url = "live_class_multi_delete";
          this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
            debugger
            this.deleteListLiveClass = [];
            if (data.status == "True") {
              this.spinner.hide();
              this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
              this.setPage({ offset: 0 });
            }
            else {
              this.spinner.hide();
              this.popup.failureMessage = data.message
              this.popup.failurepopup()
            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
  
                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            }); 
        }else{
          this.deleteListLiveClass = [];
        }
      })
    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    } 
  }

  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  viewLiveClass(Id){
    this.router.navigate(['/admin/course/viewLiveClass/'+ Id]);
  }

}
