import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  readonly s3Bucket: string = environment.S3_BUCKET_NAME;
  // readonly s3BucketMedia: string = environment.S3_BUCKET_NAME_MEDIA;
  readonly s3Region: string = environment.S3_REGION;
  readonly s3AccessKeyId: string = environment.S3_ACCESS_KEY_ID;
  readonly s3SecretAccessKey: string = environment.S3_SECRET_ACCESS_KEY;
  readonly s3FilePath: string = 'https://' + this.s3Bucket + '.s3.' + this.s3Region + '.amazonaws.com/public/';
  // readonly s3FilePathMedia: string = 'https://' + this.s3BucketMedia + '.s3.' + this.s3Region + '.amazonaws.com/';
  
  readonly COURSE_IMAGE_FILE_PATH: string = 'aljamia-media-files/courseImage';
  readonly COURSE_PDF_FILE_PATH: string = 'aljamia-media-files/coursepdf';
  readonly VIDEOFILE_PATH: string = 'aljamia-media-files/videofiles';
  readonly REFERANCEFILE_PATH: string = 'aljamia-media-files/referancefiles';
  constructor() { }
}
