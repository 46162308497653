import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toaster.service';
import { WebComponent } from '../web.component';

@Component({
  selector: 'app-weblogin',
  templateUrl: './weblogin.component.html',
  styleUrls: ['./weblogin.component.css']
})
export class WebloginComponent implements OnInit {

  constructor(private fb: FormBuilder, public api: ApiService, private spinner: NgxSpinnerService, private LocaldataService: LocaldataService, private router: Router, private ToasterService: ToasterService, private WebComponent: WebComponent, private rote: ActivatedRoute) {
    this.loginformGroup = fb.group({
      emailaddress: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
    });
  }

  loginformGroup: FormGroup;
  issubmitted: boolean = false;
  courseSlug: any = null;

  ngOnInit(): void {
    this.rote.params.subscribe(params => {
      this.courseSlug = params.course_slug;
    });
  }

  login() {
    this.issubmitted = true;
    if (this.loginformGroup.status == "VALID") {
      this.spinner.show();
      let param = { email: this.loginformGroup.value.emailaddress, password: this.loginformGroup.value.password };
      let list_fetch_url = 'student/signIn'
      this.api.postDetails(list_fetch_url, param).subscribe((data: any) => {
        if (data.status == "true" && data) {
          this.ToasterService.openSnackBar(data.message, '', 'success');
          localStorage.setItem('user_Id', data.user_id);
          localStorage.setItem('user_name', data.name);
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('refresh_token', data.refresh_token);
          localStorage.setItem('profilepic', data.profile_pic);
          this.WebComponent.islogin = true;
          this.LocaldataService.updateUser();
          this.spinner.hide();
          if (this.courseSlug) {
            this.router.navigate(['course/' + this.courseSlug]);
          } else {
              this.router.navigate(['mycourses'])
          }
        }
        else {
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'warning');
          this.WebComponent.islogin = false;
        }
      }, (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          this.WebComponent.islogin = false;
          //localStorage.clear()
          //this.router.navigate([''])
        }
      });

    }
  }
  signUp() {
    this.router.navigate(['register'])
  }
  forgotpassword() {
    this.router.navigate(['login/forgotpassword'])
  }
}
