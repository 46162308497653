import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from '../../shared/alert/alert.service';
import { Title, Meta } from "@angular/platform-browser";
import { Validatespace } from "src/app/validation/spacevalidation";

declare var $: any;
@Component({
  selector: 'app-state-add',
  templateUrl: './state-add.component.html',
  styleUrls: ['./state-add.component.css']
})
export class StateAddComponent implements OnInit {
  session_id: string;
  countryList: any = [];
  isEdit = false;
  stateId: any;
  options = {
    autoClose: false,
    keepAfterRouteChange: false
};
  constructor(public api: ApiService, private router: Router, private formBuilder: FormBuilder, private popup: PopupService,private route: ActivatedRoute,private spinner: NgxSpinnerService,public alertService: AlertService, private title: Title, private meta: Meta,) { }
  stateForm: FormGroup;
  submitted:boolean = false;


  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')

    this.stateForm = new FormGroup({
      country: new FormControl ('0', [Validators.required]),
      state:new FormControl ( '', [Validators.required, Validators.pattern('[a-zA-Z ]*'), Validatespace]),
      status:new FormControl ( '', [Validators.required]),
    });
    this.stateForm.reset(); 
    this.stateForm.get('status').setValue('1');
    this.stateForm.get('country').setValue('0');
    this.route.params.subscribe(params => {
      debugger
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.init();
        this.spinner.show();

        this.stateId = params.dist;
        var single_url = "state_edit?state_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "true") {
            debugger
            this.isEdit = true;
            // $("html, body").animate({ scrollTop: 0 });
            this.stateForm.get('country').setValue(data.state[0].country_id);
            this.stateForm.get('status').setValue(data.state[0].status_id);
            this.stateForm.get('state').setValue(data.state[0].title);
            this.spinner.hide();
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
    
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
          this.title.setTitle("Edit State | Admin | Al Jamia");
          this.meta.addTag({
          name: "description",
          content: "Edit State | Admin",
        });
    
      }
      else {
        // this.spinner.hide();
        this.isEdit = false;
        this.init();
        this.title.setTitle("Add State | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Add State | Admin",
        });
      }
    }
    );
  }
  init() {
    this.spinner.show();
    debugger
    this.countryList = [];
    if(!this.isEdit){
    var countries_fetch_url = "country?status_id=1";
  }else{
    var countries_fetch_url = "country";
  }
    this.api.detailsGet(countries_fetch_url).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.countries[0]) {
        this.countryList = data.countries;
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    },
    // var body = { "status_id": "1" };
    // this.api.detailsFetch(body, countries_fetch_url).subscribe((data: any) => {
    //   debugger
    //   console.log(data)
    //   if (data.status == "true" && data.countries[0]) {
        
    //     this.countryList = data.countries;
    //     this.spinner.hide();
    //   }
    //   else {
    //     this.spinner.hide();
       

    //     this.popup.failureMessage = data.message
    //     this.popup.failurepopup()
    //   }
    // },
     (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  get f() { return this.stateForm.controls; }
  onSubmit() {
    debugger
    console.log(this.stateForm)
    this.submitted = true;
    console.log(this.submitted)
    if (this.stateForm.invalid) {
      return;
    }
    if (this.f.country.value == 0 ) {
      debugger
      return;
      
    }

    if (!this.isEdit) {
      console.log("FFf")
      // this.spinner.show();

      var body = { "country_id": +this.f.country.value, "title": this.f.state.value, "status_id": +this.f.status.value, };
      var url = "add_state?country_id=" + `${this.f.country.value}` + "&title=" + `${this.f.state.value}` + "&status_id=" + `${this.f.status.value}`;
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "TRUE") {
          // this.spinner.hide();

          this.stateForm.reset();


          this.submitted = false;
          
          this.popup.sucessMessage = data.message
          // this.alertService.success('Success!!', this.options)
          // this.alertService.loading$.next(true);
          this.popup.sucesspopup()
          this.router.navigate(['admin/state'])
        }
        else {
          this.spinner.hide();
          // this.alertService.loading$.next(false);

          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
        
    }
    else {
      if (this.isEdit) {
        
        this.spinner.show();

        var edit_body = { "country_id": this.f.country.value, "title": this.f.state.value, "status_id": +this.f.status.value, "state_id": this.stateId };
        var update_url = "update_state?state_id=" + this.stateId + "&title=" + `${this.f.state.value}` + "&status_id=" + `${this.f.status.value}` + "&country_id=" + `${this.f.country.value}`;
        this.api.detailsAddFetch(edit_body, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();


            this.stateForm.reset();
            this.submitted = false;

             this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/state'])

          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }


  }
  getState(id) {
    this.init();
 
  }

}
