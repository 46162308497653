import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { PopupService } from 'src/app/services/popup.service';
import { MustMatch } from '../../../helpers/must-match.validator';
import { Title, Meta } from "@angular/platform-browser";
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  isError: boolean = false;
  isInvalid: boolean = false;
  errMsg: string;
  oldpassword: any;
  newpassword: any;
  confirmpassword: any;
  uid: any;
  session_id: string;
  message: any;
  isUpdated: boolean = false;
  changePasswordForm: FormGroup;
  isSubmitted = false;

  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private popup: PopupService,
    private title: Title,
    private meta: Meta) {
    this.changePasswordForm = this.formBuilder.group({
      currentPwd: ['', [Validators.required,
        Validatespace, Validators.minLength(6), Validators.maxLength(20)]],
      newpassword: ['', [Validators.required,
        Validatespace, Validators.minLength(6), Validators.maxLength(20)]],
      confirmpassword: ['', Validators.required]
    },
      {
        validator: MustMatch('newpassword', 'confirmpassword')
      });
  }


  async ngOnInit() {

    this.uid = localStorage.getItem('user_id');
    this.session_id = localStorage.getItem('access_token');


    this.title.setTitle("Change Password | Al Jamia");
    this.meta.addTag({
      name: "description",
      content: "Change Password | Al Jamia",
    });
  }

  get g() { return this.changePasswordForm.controls; }
  getRegistrationParam() {
    return {
      old_password: null,
      new_password: null,
      confirm_password: null

    }
  }
  onSubmit() {
    debugger
    this.isSubmitted = true;
    this.isUpdated = false;
    console.log(this.g)

    this.isInvalid = false;
    if (this.changePasswordForm.invalid) {
      this.isError = true;
      return;
    }
    let param = this.getRegistrationParam();
    param.old_password = this.changePasswordForm.value.currentPwd;
    param.new_password = this.changePasswordForm.value.newpassword;
    param.confirm_password = this.changePasswordForm.value.confirmpassword;
    console.log(this.g)
    this.isError = false;
    this.spinner.show();
    let fetch_url = "reset_student_password";
    this.session_id
    debugger
    this.apiService.detailsUploadFetch(param, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true") {
        this.spinner.hide();

        this.message = data.message;
        this.isUpdated = true;
        this.changePasswordForm.reset()
        this.isSubmitted = false;
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        this.router.navigate([''])

      }
      else {
        this.changePasswordForm.get("currentPwd")
          .setErrors({ incorrect: true });
        this.spinner.hide();
        // this.popup.failureMessage = data.message
        // this.popup.failurepopup()
        this.isUpdated = false;
      }
    });
  }




}
