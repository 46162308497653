import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service'

@Component({
  selector: 'app-student-view',
  templateUrl: './student-view.component.html',
  styleUrls: ['./student-view.component.css']
})
export class StudentViewComponent implements OnInit {
  selectedFile: File;
  isEdit: boolean = false;
  session_id: any;
  imageSrc: any;
  idProof: any;
  studentId: any;
  fileName = '';
  p: number = 1;
  s3FilePath: string;
  isOtherQualification: boolean = false;

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService, private uploadService: UploadService, private constantService: ConstantsService) { }
  studentForm: FormGroup;
  submitted = false;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;
    this.studentForm = this.formBuilder.group({
      name: [''],
      email: [''],
      address: [''],
      date_of_birth: [''],
      phone_number: [''],
      whatsapp_number: [''],
      status: [''],
      qualification: [''],
      // stream: [''],
      // score: [''],
      // district: [''],
      // state: [''],
      country: [''],
      nationality: [''],
      fileData: [''],
      // otherQualification: [''],
    });
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.id) {
        this.isEdit = true;
        this.selectedFile=null;
        this.studentId = params.id;
        this.spinner.show();

        var single_url = "student_info?student_id=" + `${params.id}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "True" && data.student_details) {
            this.spinner.hide();
            this.studentForm.get('name').setValue(data.student_details.name);
            this.studentForm.get('email').setValue(data.student_details.email);
            this.studentForm.get('date_of_birth').setValue(data.student_details.date_of_birth);
            this.studentForm.get('phone_number').setValue(data.student_details.phone_number);
            this.studentForm.get('whatsapp_number').setValue(data.student_details.whatsapp_number);
            this.studentForm.get('nationality').setValue(data.student_details.nationality);
            this.studentForm.get('status').setValue(data.student_details.status_id);
            // this.studentForm.get('qualification').setValue(data.student_details.qualification);
            // this.studentForm.get('stream').setValue(data.student_details.stream);

          //   if(data.student_details.qualification_id == null){
          //     this.isOtherQualification = true;
          //   this.studentForm.get('qualification').setValue("Others");
          //   this.studentForm.get('otherQualification').setValue(data.student_details.other_qualification_stream);
          // }
          // else{
          //   this.isOtherQualification = false;
          //   this.studentForm.get('qualification').setValue(data.student_details.qualification);
          //   this.studentForm.get('stream').setValue(data.student_details.stream);
          // }
          this.studentForm.get('qualification').setValue(data.student_details.qualification);
            // this.studentForm.get('score').setValue(data.student_details.score);
            // this.studentForm.get('district').setValue(data.student_details.district);
            // this.studentForm.get('state').setValue(data.student_details.state);
            this.studentForm.get('country').setValue(data.student_details.country);
            this.studentForm.get('fileData').setValidators(null);
            this.studentForm.get('fileData').setValue("");
            this.studentForm.get('address').setValue(
              data.student_details.address );
              if(data.student_details.profile_pic){
            this.imageSrc = this.s3FilePath + data.student_details.profile_pic;
          }
          if(data.student_details.identity_proof){
            this.idProof = data.student_details.identity_proof;
          }
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
      else {
        this.isEdit = false;
        this.spinner.hide();

      }
    }
    );
  }



  get f() { return this.studentForm.controls; }
 
  
}
