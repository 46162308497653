import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqAddComponent } from './faq-add.component';
import { FaqAddRoutingModule } from './faq-add-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';

@NgModule({
  declarations: [FaqAddComponent],
  imports: [
    CommonModule,
    FaqAddRoutingModule,
    FormsModule, ReactiveFormsModule,CKEditorModule
   ]
})
export class FaqAddModule { }
