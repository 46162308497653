import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { Page } from '../../models/pageDto';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.css']
})
export class QualificationComponent implements OnInit {
  @ViewChild('myTable') table: QualificationComponent;
  searchTerm: any = '';
  session_id:any;
  selected=[];
  temp=[];
  p: number = 1;
  qualificationList: any = [];
  page: Page;
  
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router,private popup: PopupService,private spinner: NgxSpinnerService) { }
  qualificationForm: FormGroup;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.qualificationForm = this.formBuilder.group({
      search: ['']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    this.setPage({ offset: 0 });
  }

  onSearch() {
    this.searchTerm = this.qualificationForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }


  setPage(pageInfo) {

    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.qualificationList = [];

    var country_fetch_url = "qualification?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    if (this.searchTerm.length > 0)
      country_fetch_url += "&searchTerm=" + this.searchTerm;

    this.api.detailsGetFetch(country_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.qualifications[0]) {
        this.spinner.hide();

        this.temp = [...data.qualifications];
        this.qualificationList = data.qualifications;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
 
  deleteQualification(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        var body = { "qualification_id": +id };
        var del_url = "delete_qualification?qualification_id=" + `${id}`;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "True") {
            this.spinner.hide();

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.setPage({ offset: 0 });

          }
          else {
            this.spinner.hide();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
       return title_name || status_name;
    });

    // update the rows
    this.qualificationList = temp;
    // this.table.offset = 0;
  }

  editQualification(id){
    this.router.navigate(['admin/add-qualification',{dist:`${id}`}]);

  }
}
