import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PopupService } from '../../../services/popup.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {

  session_id: any;
  notifications: any = [];

  constructor(public api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private popup: PopupService,) { }

  ngOnInit(): void {
    this.session_id = localStorage.getItem('access_token');
    this.getNotification();
  }


  getNotification() {
    this.spinner.show();
    var list_fetch_url = "getNotifications";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.notifications) {
        this.notifications = data.notifications;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
        this.router.navigate(['/'])
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });
  }

  readNotification(id) {
    this.spinner.show();
    var fetch_url = "readNotification";
    let param = {
      notification_id: id
    }
    this.api.detailsUploadFetch(param, fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        this.getNotification();
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
        this.router.navigate(['/'])
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });
  }


}
