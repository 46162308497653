<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item active"><a>Course Element</a></li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <!-- /.content -->
  <section class="content">
    <div class="card">

      <p-dialog header="Add Course Element" [(visible)]="displayModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
                [draggable]="false" [resizable]="false">

        <div class="m-3">
        <a [routerLink]="['/admin/courseElementAdd']" [queryParams]="{type_id:1}" class="btn btn-app"> <i class="fas fa-align-justify"></i> Subject</a>
        <a [routerLink]="['/admin/courseElementAdd']" [queryParams]="{type_id:2}" class="btn btn-app"> <i class="fas fa-file"></i> Chapter</a>
        <a [routerLink]="['/admin/courseElementAdd']" [queryParams]="{type_id:3}" class="btn btn-app"> <i class="fas fa-lightbulb"></i> Concept</a>
        <a [routerLink]="['/admin/courseElementAdd']" [queryParams]="{type_id:4}" class="btn btn-app"> <i class="fas fa-th"></i> Module</a> </div>


      </p-dialog>

      <div class="card-header">
        <h1>Course Element</h1>
      </div>

      <form [formGroup]="courseElementForm" (ngSubmit)="onSearch()">

        <!--<div class="card-body pb-0">
          
        </div>-->

        <div class="card-body pb-0">
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <select class="form-control select2  ml-1" formControlName="courseElementType" (change)="onCourseElementTypeFilter()" style="width:100%;">
              <option selected="selected" [value]="0">Select Type</option>
              <option value="1">Subject</option>
              <option value="2">Chapter</option>
              <option value="3">Concept</option>
              <option value="4">Module</option>
            </select>
          </span>
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <select class="form-control select2  ml-1" formControlName="courseElementStatus" (change)="onCourseElementStatusFilter()" style="width:100%;">
              <option selected="selected" [value]="0">Select Status</option>
              <option value="1">Active</option>
              <option value="2">Disabled</option>
            </select>
          </span>
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <input type="text" formControlName="search" class="form-control selact_"
                   placeholder="Search text">
          </span>
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <button class="btn btn-dark ml-2 float-left" type="submit"> <i class="fas fa-search mr-1"></i> Search </button>
          </span>
          <a [routerLink]="" (click)="showModalDialog()" class="btn btn-secondary btn-dark float-right add_question">
            <i class="fas fa-plus mr-2"></i>Add Course Element
          </a>
        </div>
      </form>
      <div class="card-body table-responsive">

        <div>

          <ngx-datatable #myTable class="material striped" [rows]="course_elementsList" [columnMode]="'force'"
                         [headerHeight]="50" [footerHeight]="50" [rowHeight]="50"
                         [sorts]="[{prop: 'name', dir: 'desc'}]" [selected]="selected" [selectionType]="'checkbox'"
                         [externalPaging]="true" [count]="page.total" [offset]="page.current_page" [limit]="page.per_page"
                         (select)="onSelect($event)" (page)="setPagetest($event)">
            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                  [draggable]="false" [resizeable]="false">
              <ng-template ngx-datatable-header-template let-value="value"
                           let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                <input type="checkbox" [checked]="allRowsSelected"
                       (change)="selectFn(!allRowsSelected)" />
              </ng-template>
              <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                           let-onCheckboxChangeFn="onCheckboxChangeFn">
                <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column  name="Sl No" [sortable]="false">
              <ng-template  ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column  [width]="250" name="Title" prop="title">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.title}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Element Type" prop="type">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.type}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Parent" prop="parent">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.parent}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.status}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Action" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                 <a href="Javascript:void(0);" title="Edit" (click)="editCourseElement(row.id)" class="edit_button mr-2">
                  <i class="far fa-edit"></i>
                </a> <a href="Javascript:void(0);" title="Delete" (click)="deleteCourseElement(row.id)"
                        class="close_button"><i class="far fa-times-circle"></i></a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
          <a (click)="deleteMultipleItem()" class="btn btn-secondary btn-dark float-right add_country" style="margin-top:2%">
            Delete Selected Items
           </a>
        </div>
      </div>
      <!-- <pagination-controls style="text-align:center;" (pageChange)="p = $event">
      </pagination-controls> -->
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>
