import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-question-sub',
  templateUrl: './question-sub.component.html',
  styleUrls: ['./question-sub.component.css']
})
export class QuestionSubComponent implements OnInit {
  isEdit: boolean = false;
  session_id: any;
  categoryId: any;
  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private popup: PopupService,
    private spinner: NgxSpinnerService,
    private title: Title,
    private meta: Meta) { }
  questionCategoryForm: FormGroup;
  submitted = false;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')

    this.questionCategoryForm = this.formBuilder.group({
      category: ['', Validators.required],
      status: ['', Validators.required],
    });
    this.questionCategoryForm.get('status').setValue('1');
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;

        this.categoryId = +params.dist;
        this.spinner.show();

        var single_url = "getQuestioncategoryById?question_category_id=" + `${params.dist}`;
        var edit_body = { "question_category_id": this.categoryId };

        this.api.detailsAddFetch(edit_body, single_url, this.session_id).subscribe((data: any) => {
          if (data.status == "true" && data.question_category[0]) {
            this.spinner.hide();

            this.isEdit = true;
            this.questionCategoryForm.get('category').setValue(data.question_category[0].title);
            this.questionCategoryForm.get('status').setValue(data.question_category[0].status_id);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });

        this.title.setTitle("Edit Question Category | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Edit Question Category | Admin",
        });

      }
      else {
        this.spinner.hide();

        this.isEdit = false;
      }
    }
    );
  }
  get f() { return this.questionCategoryForm.controls; }
  onSubmit() {
    this.submitted = true;
    console.log(this.f)

    if (this.questionCategoryForm.invalid) {
      return;
    }
    if (!this.isEdit) {
      this.spinner.show();

      let url = "question_category_add?title=" + `${this.f.category.value}` + "&status_id=" + `${this.f.status.value}`;
      let body = { "title": `${this.f.category.value}`, "status_id": +this.f.status.value };
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "TRUE") {
          this.spinner.hide();

          this.questionCategoryForm.reset();


          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup()
          this.router.navigate(['admin/questionCategory'])

        }
        else {
          this.spinner.hide();

          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else {
      if (this.isEdit) {
        this.spinner.show();

        var update_body = { "status_id": +this.f.status.value, "title": this.f.category.value, "question_category_id": this.categoryId };
        var update_url = "question_category_update?title=" + `${this.f.category.value}` + "&status_id=" + `${this.f.status.value}` + "&question_category_id=" + this.categoryId;
        this.api.detailsAddFetch(update_body, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "True") {
            this.spinner.hide();

            this.questionCategoryForm.reset();
            this.submitted = false;

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/questionCategory'])

          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
  }


}
