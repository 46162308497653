import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, TemplateRef, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { MatDialog } from '@angular/material/dialog';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-enrollment-details',
  templateUrl: './enrollment-details.component.html',
  styleUrls: ['./enrollment-details.component.css']
})
export class EnrollmentDetailsComponent implements OnInit {

  session_id: string;
  enrollmentDetails: any = [];
  studentDetails: any = [];
  instructorDetails: any = [];
  courseOverviewDetails: any = [];
  s3FilePath: any;

  enrollmentId: any;
  ratings: number = 0;
  comments: string;
  selectedContent: any;

  scoreAndComment: any = {};
  isEdit: boolean = false;
  isOtherQualification: boolean = false;
  examResultData: any = [];

  @ViewChild("feedBack") feedBack: TemplateRef<any>;
  @ViewChild("detailPage") detailPage: TemplateRef<any>;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private constantService: ConstantsService,
    public dialog: MatDialog,
    private popup: PopupService,
    private ToasterService: ToasterService,) {
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.s3FilePath = this.constantService.s3FilePath;

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get('enrollmentId');
      this.enrollmentId = id;
      this.getEnrollmentDetails();
    })
  }



  ngOnInit(): void {
  }

  openDialog(rating: number, comment: string) {
    this.ratings = rating;
    this.comments = comment;
    this.dialog.open(this.feedBack);
  }

  openDetailDialog(content: any) {
    this.isEdit = false;
    this.scoreAndComment = {};
    this.selectedContent = content;
    if(this.selectedContent.course_content_type_id == 5){
      this.detailedAttemptView(this.selectedContent.enrollment_course_content_id);
    }
    this.dialog.open(this.detailPage);
  }


  getEnrollmentDetails(): void {
    this.spinner.show();
    var course_fetch_url = "getEnrollmentById?enrollment_id=" + this.enrollmentId;
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        debugger
        this.enrollmentDetails = data.enrollment.enrollment_details;
        this.studentDetails = data.enrollment.student_details;
        // if(this.studentDetails.qualification == null){
        //   this.isOtherQualification = true;
        // }else{
        //   this.isOtherQualification = false;
        // }
        this.instructorDetails = data.enrollment.instructor_details;
        this.courseOverviewDetails = data.enrollment.course_overview_status;

        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  onSubmit(id) {
    this.spinner.show();
    this.scoreAndComment.enrollment_activity_id = id;
    var url = "addEnrollmentactivityComment";
    this.api.detailsUploadFetch(this.scoreAndComment, url, this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        this.spinner.hide();
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup()
        this.getEnrollmentDetails()
        this.dialog.closeAll();
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }

  onEdit(score, sme_comment) {
    this.scoreAndComment.score = score;
    this.scoreAndComment.comment = sme_comment
    this.isEdit = true;
  }

  generateCertificate() {
    this.spinner.show();
    let param = {
      enrollment_id: this.enrollmentId
    };
    let fetch_url = 'certificate_generate';
    this.api
      .detailsUploadFetch(param, fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true') {
            this.getEnrollmentDetails();
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'warning');
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }

  detailedAttemptView(id){
    this.spinner.show();
    var body = {enrollment_content_id: id};
    var url = "get_exam_results";
    this.api.detailsUploadFetch(body, url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true") {
        this.spinner.hide();
        console.log(data.attempts);
        this.examResultData = data.attempts;
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }

  markAsComplete(){
    debugger
    this.spinner.show();
    let param = {
      enrollment_id: this.enrollmentId
    };
    let fetch_url = 'mark_course_complete';
    this.api
      .detailsUploadFetch(param, fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          debugger
          if (data.status == 'true') {
            this.spinner.hide();
            this.getEnrollmentDetails();
            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            
          } else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'warning');
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }

}
