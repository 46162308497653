import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CurrencyAddComponent } from './currency-add.component';


const routes: Routes = [

  {
    path: '', component: CurrencyAddComponent,
    data:{title:'Add Currency | Admin'}
},


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CurrencyAddRoutingModule { }
