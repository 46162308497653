<div class="modal-header">
    <h1 mat-dialog-title>Do you have a referral code..?</h1>
    <button type="button" class="close" data-dismiss="modal" (click)="onNoClick()" aria-label="Close"> <span
        aria-hidden="true">&times;</span> </button>
      </div>
    
    
    <div class="clearfix"></div>
   
      <form [formGroup]="referralForm" (ngSubmit)="refferalCodeSubmit()">
        <div class="form-group modal-body">
          <label>If you have referral code,use it now to obtain discounts</label>
          <input type="text" formControlName="refferalCode" class="form-control selact_" placeholder="Referral Code">
          <div *ngIf="submitted && f.refferalCode.errors" class="error_signup">
            <div *ngIf="f.refferalCode.errors.required">Enter Referral Code or Press Skip Button
            </div>
          </div>
        </div>
    
        <div mat-dialog-actions class="modal-footer">
          <button mat-button type="button" class="btn btn-outline-danger btn-sm" (click)="onNoClick()">Skip</button>
          <button mat-button type="submit" class="btn btn-outline-success btn-sm" cdkFocusInitial>Submit</button>
        </div>
      </form>

    
    