import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TestimonialAddComponent } from './testimonial-add.component';
import { TestimonialAddRoutingModule } from './testimonial-add-routing.module';


@NgModule({
  declarations: [TestimonialAddComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    TestimonialAddRoutingModule,
   ]
})
export class TestimonialAddModule { }
