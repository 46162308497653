import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnrollStudentAddComponent } from './enroll-student-add/enroll-student-add.component';
import { EnrollStudentImportComponent } from './enroll-student-import/enroll-student-import.component';

const routes: Routes = [
  {
    path: 'import',
    component: EnrollStudentImportComponent,
    data: { title: 'Enroll Students- Import ' }
  },
  {
    path: 'add',
    component: EnrollStudentAddComponent,
    data: { title: 'Enroll Student ' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnrollStudentsRoutingModule { }
