import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
    import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from '../../models/pageDto';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {
  @ViewChild('myTable') table: StudentComponent;
  session_id: any;
  searchTerm: any = '';
  selected=[];
  temp=[];
  studentList:any=[];
  page: Page;
  deleteList:any=[];
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router,private popup: PopupService,private spinner: NgxSpinnerService) { }
  studentForm: FormGroup;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.studentForm = this.formBuilder.group({
      search: ['']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page:10
    };
    this.setPage({ offset: 0 });
    //this.init();
  }

  onSearch() {
    this.searchTerm = this.studentForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  setPage(pageInfo) {
    debugger
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.studentList = [];
    var student_fetch_url = "student_list?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    if (this.searchTerm.length > 0)
      student_fetch_url += "&searchTerm=" + this.searchTerm;
    this.api.detailsGetFetch(student_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        this.temp = data.students;
        this.studentList = data.students;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
 
  
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
       return title_name || status_name;
    });

    this.studentList = temp;
   }
   viewStudent(id){
     this.router.navigate(['admin/studentView',{id:`${id}`}]);

  }
  deleteStudent(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var body = { "student_id": +id };
        var del_url = "delete_student";
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "true") {
            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.spinner.show();
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })

 }

 deleteMultipleItem(){
  if(this.selected.length>0){
    this.selected.forEach(element => {
      this.deleteList.push(element.student_id);
    });
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var body = { "ids": this.deleteList };
        var del_url = "student_multi_delete";
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          debugger
          this.deleteList = [];
          if (data.status == "true") {
            this.spinner.hide();
            this.popup.sucessMessage = data.message
          this.popup.sucesspopup()
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          }); 
      }else{
        this.deleteList = [];
      }
    })
  }else{
    this.popup.failureMessage = "Select atleast one Enrollment";
    this.popup.failurepopup()
  } 
}

resetStudentPassword(id: any,name: string){
  debugger
 this.router.navigate(['admin/password',{id:`${id}`,name:`${name}`}]);
}
 
}
