<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Change Password</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="sub_banner  text-center">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>Change your password </h1>
      </div>
    </div>
  </div>
</section>
<section class="sub_page_content team_content sign_up_container">
  <div class="container">
    <div class="row">
      <div class="col-md-12 card  my-0 border-0 card-body ">
        <!--<h2 class="card-title text-center">Change your password </h2>-->
        <div class="p-3 pb-5 add_contry_">
          <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" name="myform" id="myForm">
            <div class="col-md-6 float-left password_change_image">
              <img src='../../../assets/web/assets/images/password.png' class="img-fluid my-2" alt="password">
            </div>
            <div class="col-md-6 float-left">
              <div class="form-group">
                <label>Current Password</label>
                <input type="password" formControlName="currentPwd" class="form-control selact_"
                  placeholder="Enter Current Password" [ngClass]="{ 'is-invalid': isSubmitted && g.currentPwd.errors }">
                <!-- <div *ngIf="isSubmitted && g.currentPwd.errors" class="invalid-feedback">
                          <div *ngIf="g.currentPwd.errors.required">
                            Current Password is
                            required
                          </div>
                        </div> -->
                <small *ngIf="isSubmitted && changePasswordForm.controls.currentPwd.errors" class="error_signup">
                  <div *ngIf="isSubmitted && changePasswordForm.controls.currentPwd.errors.required"> Current Password
                    is
                    required</div>
                  <div
                    *ngIf="isSubmitted && !changePasswordForm.controls.currentPwd.errors.validspace && changePasswordForm.controls.currentPwd.errors.minlength">
                    Invalid Current Password </div>
                  <div
                    *ngIf="isSubmitted && !changePasswordForm.controls.currentPwd.errors.validspace && changePasswordForm.controls.currentPwd.errors.maxlength">
                    Invalid Current Password </div>
                  <div *ngIf="isSubmitted && changePasswordForm.controls.currentPwd.errors.validspace"> Invalid Current
                    Password </div>
                  <div *ngIf="isSubmitted && changePasswordForm.controls.currentPwd.errors.incorrect"> Sorry, your
                    Current Password was incorrect. Please double-check your password </div>
                </small>
              </div>
              <div class="form-group">
                <label>New Password</label>
                <input type="password" formControlName="newpassword" class="form-control selact_"
                  placeholder="Enter New Password" [ngClass]="{ 'is-invalid': isSubmitted && g.newpassword.errors }">
                  <label>Note: You can select any password of your choice, not necessarily the password used for your official email.</label>
                <div *ngIf="isSubmitted && g.newpassword.errors" class="invalid-feedback">
                  <div *ngIf="g.newpassword.errors.required">
                    New Password is
                    required
                  </div>

                  <div
                    *ngIf="isSubmitted && !changePasswordForm.controls.newpassword.errors.validspace && changePasswordForm.controls.newpassword.errors.minlength">
                    Minimum 6 Characters
                    required </div>
                  <div
                    *ngIf="isSubmitted && !changePasswordForm.controls.newpassword.errors.validspace && changePasswordForm.controls.newpassword.errors.maxlength">
                    Maximum 20 Characters
                    is Possible </div>
                  <div *ngIf="isSubmitted && changePasswordForm.controls.newpassword.errors.validspace"> Invalid New
                    Password </div>
                </div>
              </div>
              <div class="form-group">
                <label>Confirm New Password</label>
                <input type="password" formControlName="confirmpassword" class="form-control selact_"
                  placeholder="Enter Confirm Password"
                  [ngClass]="{ 'is-invalid': isSubmitted && g.confirmpassword.errors }">
                <div *ngIf="isSubmitted && g.confirmpassword.errors" class="invalid-feedback">
                  <div *ngIf="g.confirmpassword.errors.required">
                    Confirm New Password is
                    required
                  </div>
                  <div *ngIf="g.confirmpassword.errors.mustMatch==true">
                    Please make sure your password match
                  </div>
                </div>
              </div>
            </div>



            <div class="clearfix"></div>
            <div class="form-group">
              <div class="col-md-12">
                <button class="btn btn-dark sign_up_submit btn-primary float-right text-uppercase"
                  type="submit">save</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</section>
