import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { DialogData } from '../addcourse/addcourse.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-addcourse-week-popup',
  templateUrl: './addcourse-week-popup.component.html',
  styleUrls: ['./addcourse-week-popup.component.css']
})
export class AddcourseWeekPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddcourseWeekPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder, public api: ApiService, private router: Router, private spinner: NgxSpinnerService, private constantService: ConstantsService) { }

  weekForm: FormGroup;
  submitted = false;
  session_id: any;
  resources: any;
  activities: any;
  s3FilePath: any;
  courseId: any;

  ngOnInit(): void {
    this.s3FilePath = this.constantService.s3FilePath;
    this.weekForm = this.formBuilder.group({
      weekName: ['', Validators.required],
    });
    debugger
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.courseId = this.data.course_Id;
    if (this.data.isActivityResourse) {
      this.getContentType()
    } else if (this.data.Week.id) {
      this.weekForm.get('weekName').setValue(this.data.Week.title);
    }
    

  }

  get f() {
    return this.weekForm.controls;
  }

  weekSubmit() {
    debugger
    this.submitted = true;
    if (this.weekForm.invalid) {
      return;
    }
    let week = { status: true, message: null, week_id: null, title: null }
    if (this.data.Week.id) {
      week.week_id = this.data.Week.id;
    }
    week.title = this.weekForm.value.weekName;
    this.dialogRef.close(week);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getContentType() {
    debugger
    this.spinner.show();
    var fetch_url = "get_content_types?course_id=" + this.courseId;
    
    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.resources) {
        this.resources = data.resources;
        this.activities = data.activities;
        //this.courseForm.get('courceCategory').setValue(data.course_categories[0].id);

        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }
  tabChange(tabChangeEvent: MatTabChangeEvent) {

  }

  resourceSelect(resource) {
    debugger
    this.dialogRef.close({ resource: resource, isResource: true });
  }
  activitySelect(activity) {
    this.dialogRef.close({ activity: activity, isResource: false });
  }

}