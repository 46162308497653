<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item active">Notifications</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>Notifications</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-info alert-dismissible fade show mb-2" role="alert" *ngFor="let item of notifications">
             {{item.notification}}
    
              <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="readNotification(item.id)">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
    
            <div class="clearfix"></div>
          </div>
          <!-- <div class="col-md-12">
            <a href="#" class="btn btn-sm float-right view_more_note">+2 More Notification</a>
    
          </div> -->
    
        </div>
      </div>
    </div>
  </section>
</div>
