import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { QuestionAddComponent } from './question-add.component';
import { QuestionAddRoutingModule } from './question-add-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';  
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  declarations: [QuestionAddComponent, FieldErrorDisplayComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    QuestionAddRoutingModule,
    NgxDatatableModule,
    NgxPaginationModule,
    DialogModule,
    ButtonModule,
    MatTooltipModule,
    MatProgressBarModule
   ]
})
export class QuestionAddModule { }
