import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, mapTo, map, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private loggedInUser: string;
  private JWT_TOKEN = 'JWT_TOKEN';
  constructor(private http: HttpClient) {

  }

  private baseurl =  "https://api.aljamiaworldcampus.net/api/" ;
  // private baseurl =  "https://apiuat.aljamiaworldcampus.net/api/" ;
  // private baseurl =  "http://3.108.42.5/api/" ;
  // private baseurl =  "http://13.126.31.238/api/" ;
  // private baseurl = "https://cors-anywhere.herokuapp.com/" + "https://api.aljamiaworldcampus.net/api/" ;
  // private baseurl = "https://cors-anywhere.herokuapp.com/" + "http://13.126.31.238/api/";

  // private baseurl =  "http://13.126.31.238/api/";

  private loginApi = this.baseurl + 'signin';
  private changePassword = this.baseurl + 'change_password';
  private editProfile = this.baseurl + 'edit_profile';
  private forgotPasswordApi = this.baseurl + 'forgotpassword';
  private logoutApi= this.baseurl + 'logout';
  htmlData: any;


  login(user: { email, password }) {
    console.log(user)
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     "Access-Control-Allow-Origin": "*"
    //   })
    // }
    return this.http.post(this.loginApi, user).pipe(map(data => (data)));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }
  isLoggedIn() {
    return !!this.getJwtToken();
  }

  private loggedinUser(username: string, tokens: string) {
    this.loggedInUser = username;
    this.storeTokenDetails(tokens);
  }
  private storeTokenDetails(tokens: string) {
    localStorage.setItem(this.JWT_TOKEN, tokens['access_token']);
  }
  logout(session_token: string, uid: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + session_token,
      })
    }
    var body = { "user_id": uid };
    return this.http.post(this.logoutApi, body, httpOptions).pipe(map(data => (data)));
  }
  detailsGet(url) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "GET"
    //    "Authorization": "Bearer " + session_token,
    //   })
    // }
    return this.http.get(this.baseurl + url, ).pipe(map(res => res));
  }
  countryDelete(id: any, url) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'country_id': `${id}`
      }),
    }
    return this.http.delete(this.baseurl + url, httpOptions).pipe(map(data => (data)));
  }
  detailsAddFetch(body, url, session_token) {
    console.log(url)
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + session_token,
      })
    }
    return this.http.post(this.baseurl + url, body, httpOptions).pipe(map(data => (data)));
  }
  detailsUploadFetch(body, url, session_token) {
    console.log(url)
    const httpOptions = {
      headers: new HttpHeaders({
        //  "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + session_token,
      })
    }
    return this.http.post(this.baseurl + url, body, httpOptions).pipe(map(data => (data)));
  }
  detailsGetFetch(url, session_token) {
    console.log(url)
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + session_token,
      })
    }
    return this.http.get(this.baseurl + url, httpOptions).pipe(map(data => (data)));
  }
  detailsFetch(body, url) {
    console.log(url)
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     "Access-Control-Allow-Origin": "*",
    //   })
    // }
    return this.http.get(this.baseurl + url, body).pipe(map(data => (data)));
  }
  detailsFetchDel(body, url, session_token) {
    console.log(url)
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + session_token,

      })
    }
    return this.http.post(this.baseurl + url, body, httpOptions).pipe(map(data => (data)));
  }
  updatePassword(body, session_token) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + session_token,

      })
    }
    return this.http.post(this.changePassword, body, httpOptions).pipe(map(data => (data)));

  }
  updateProfile(body, session_token) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + session_token,

      })
    }
    return this.http.post(this.editProfile, body, httpOptions).pipe(map(data => (data)));

  }
  sendDetails(body) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     "Access-Control-Allow-Origin": "*",

    //   })
    // }
    return this.http.post(this.forgotPasswordApi, body).pipe(map(data => (data)));

  }
  detailsAddInfo(url, session_token) {
    console.log(url)
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + session_token,
      })
    }
    return this.http.post(this.baseurl + url, httpOptions).pipe(map(data => (data)));
  }
  postDetails(url, body) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     "Access-Control-Allow-Origin": "*",

    //   })
    // }
    return this.http.post(this.baseurl + url, body).pipe(map(data => (data)));

  }
  
  postPaymentDetails(url, body): Observable<any> {

    debugger

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     "Access-Control-Allow-Origin": "*",

    //   })
    // }
    return this.http.post(url, body, {headers :{ 'responseType': 'text' }}).pipe(map(data => 
      
      {
        data
      }));
    

  }

  postDetailData(url:string,body: any) {
    return this.http.post(url, body, { 'responseType': 'text'})
    .pipe(map(data => {
      debugger
      this.htmlData = data;
      
    }))
  }

  setHTMLData(data){
    this.htmlData = data;
  }
  getHTMLData(){
    return this.htmlData
  }
}
