import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service';
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-team-add',
  templateUrl: './team-add.component.html',
  styleUrls: ['./team-add.component.css']
})
export class TeamAddComponent implements OnInit {
  selectedFile: File;
  isEdit: boolean = false;
  session_id: any;
  imageSrc: any;
  teamId: any;
  fileName = '';
  p: number = 1;
  s3FilePath: string;
  s3FileName: any;

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService, private uploadService: UploadService, private constantService: ConstantsService) { }
  teamForm: FormGroup;
  submitted = false;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;

    this.teamForm = this.formBuilder.group({
      teamName: ['', [Validators.required, Validatespace, Validators.maxLength(50)]],
      status: ['1', [Validators.required]],
      description: ['', [Validators.required, Validatespace]],
      position: ['', [Validators.required, Validatespace, Validators.maxLength(50)]],
      sort_order: ['', Validators.required],
      fileData: ['', Validators.required]
    });
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.selectedFile = null;
        this.teamId = params.dist;
        this.spinner.show();

        var single_url = "edit_team?team_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          debugger
          console.log(data)
          if (data.status == "true" && data.Team[0]) {
            this.spinner.hide();

            this.teamForm.get('teamName').setValue(data.Team[0].name);
            this.teamForm.get('position').setValue(data.Team[0].position);
            this.teamForm.get('description').setValue(data.Team[0].description);
            this.teamForm.get('sort_order').setValue(data.Team[0].sort_order);
            this.teamForm.get('status').setValue(data.Team[0].status_id);
            this.teamForm.get('fileData').setValidators(null);
            this.teamForm.get('fileData').setValue("");
            this.imageSrc = this.s3FilePath + data.Team[0].image;
            console.log(this.teamForm);
            console.log(this.teamForm);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
      else {
        this.isEdit = false;
        this.spinner.hide();

      }
    }
    );
  }



  get f() { return this.teamForm.controls; }
  onFileChanged(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      console.log(this.selectedFile);

      this.selectedFile = event.target.files[0];
      console.log(this.selectedFile.name);
      let imagePath_ = this.selectedFile.type;
      var ext = imagePath_.split('/');
      if (ext[0] == "image") {

      reader.onload = () => {

        this.imageSrc = reader.result as string;


      }
      reader.readAsDataURL(this.selectedFile);
      console.log(this.selectedFile)
    } else {
      this.teamForm.get('fileData').setValue('');
      this.teamForm.get('fileData').setErrors({ required: true });
      this.imageSrc = ""
    }

    }
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.f)

    if (this.teamForm.invalid) {
      return;
    }
    this.spinner.show();
    const uploadData = new FormData();
    // uploadData.append('image', s3FileName);
    uploadData.append('name', this.f.teamName.value);
    uploadData.append('position', this.f.position.value);
    uploadData.append('description', this.f.description.value);
    uploadData.append('sort_order', this.f.sort_order.value);
    uploadData.append('status_id', this.f.status.value);
    if (this.selectedFile == null) {
      uploadData.append('image', this.imageSrc.replace(this.s3FilePath, ''));
      this.addEditSubmit(uploadData);
    } else {
      this.uploadService.fileUpload(this.selectedFile, 'team').then(res => {
        if (res) {
          this.s3FileName = res
          uploadData.append('image', this.s3FileName);
          this.addEditSubmit(uploadData);
        }
      });
    }
  }

  addEditSubmit(uploadData) {
    var fetch_url = null;
    if (this.isEdit) {
      fetch_url = "update_team";
      uploadData.append('team_id', this.teamId);
    }
    else {
      fetch_url = "add_team";
    }
    this.api.detailsUploadFetch(uploadData, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      console.log(data)
      if (data.status == "true") {
        this.imageSrc = "";
        this.teamForm.reset();
        this.submitted = false;
        this.spinner.show();
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        this.router.navigate(['admin/team'])

      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });


  }
}
