import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';

import { DashboardComponent } from './dashboard.component';
import { AddEligibilityTestComponent } from './Eligibility-Test/add-eligibility-test/add-eligibility-test.component';
import { EditEligibilityTestComponent } from './Eligibility-Test/edit-eligibility-test/edit-eligibility-test.component';
import { ListEligibilityTestComponent } from './Eligibility-Test/list-eligibility-test/list-eligibility-test.component';
import { ListTestQuestionsComponent } from './Eligibility-Test/Questions/list-test-questions/list-test-questions.component';
import { EnrollmentDetailsComponent } from './Enrollments/enrollment-details/enrollment-details.component';
import { EnrollmentScoreComponent } from './Enrollments/enrollment-score/enrollment-score.component';
import { ListEnrollmentsComponent } from './Enrollments/list-enrollments/list-enrollments.component';
import { HomeComponent } from './home/home.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { StudentResetPasswordComponent } from './student-reset-password/student-reset-password.component';


const routes: Routes = [

  {
    path: 'admin', component: DashboardComponent, canActivate: [AuthGuard],
    data: { title: 'Admin Home ' },
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: { title: 'Admin Home ' }
      },
      {
        path: 'country',
        loadChildren: './country/country.module#CountryModule', canActivate: [AuthGuard],
        data: { title: ' Country | Admin ' },

      },
      {
        path: 'state',
        loadChildren: './state/state.module#StateModule', canActivate: [AuthGuard],
        data: { title: ' States | Admin ' },

      },
      {
        path: 'district',
        loadChildren: './district/district.module#DistrictModule', canActivate: [AuthGuard],
        data: { title: ' District | Admin ' },

      },
      {
        path: 'add-district',
        loadChildren: './district-add/district-add.module#DistrictAddModule', canActivate: [AuthGuard],
        data: { title: ' Add District | Admin ' },

      },
      {
        path: 'add-country',
        loadChildren: './country-add/country-add.module#CountryAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Country | Admin ' },

      }
      ,
      {
        path: 'add-state',
        loadChildren: './state-add/state-add.module#StateAddModule', canActivate: [AuthGuard],
        data: { title: ' Add State | Admin ' },

      },
      {
        path: 'add-qualification',
        loadChildren: './qualification-add/qualification-add.module#QualificationAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Qualification | Admin ' },

      },
      {
        path: 'qualification',
        loadChildren: './qualification/qualification.module#QualificationModule', canActivate: [AuthGuard],
        data: { title: ' Qualification | Admin ' },

      },
      {
        path: 'stream',
        loadChildren: './stream/stream.module#StreamModule', canActivate: [AuthGuard],
        data: { title: ' Stream | Admin ' },

      },
      {
        path: 'add-stream',
        loadChildren: './stream-add/stream-add.module#StreamAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Stream | Admin ' },

      },
      {
        path: 'changePassword',
        loadChildren: './changepassword/changepassword.module#ChangepasswordModule', canActivate: [AuthGuard],
        data: { title: ' Change Password | Admin ' },

      },
      {
        path: 'add-Profile',
        loadChildren: './profile-add/profile-add.module#ProfileAddModule', canActivate: [AuthGuard],
        data: { title: ' Edit Profile | Admin ' },
      },

      {
        path: 'faq',
        loadChildren: './faq/faq.module#FaqModule', canActivate: [AuthGuard],
        data: { title: ' FAQ | Admin ' },

      },
      {
        path: 'add-faq',
        loadChildren: './faq-add/faq-add.module#FaqAddModule', canActivate: [AuthGuard],
        data: { title: ' Add FAQ | Admin ' },

      },
      {
        path: 'courseCategory',
        loadChildren: './course-category/course-category.module#CourseCategoryModule', canActivate: [AuthGuard],
        data: { title: ' Course Category | Admin ' },

      },
      {
        path: 'courseCategoryAdd',
        loadChildren: './course-category-add/course-category-add.module#CourseCategoryAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Course Category | Admin ' },

      },
      {
        path: 'courseElement',
        loadChildren: './course-element/course-element.module#CourseElementModule', canActivate: [AuthGuard],
        data: { title: ' Course Element | Admin ' },

      },
      {
        path: 'courseElementAdd',
        loadChildren: './course-element-add/course-element-add.module#CourseElementAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Course Element | Admin ' },

      },
      {
        path: 'currency',
        loadChildren: './currency/currency.module#CurrencyModule', canActivate: [AuthGuard],
        data: { title: ' Currency | Admin ' },

      },
      {
        path: 'add-currency',
        loadChildren: './currency-add/currency-add.module#CurrencyAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Currecy | Admin ' },

      },
      {
        path: 'question-add',
        loadChildren: './question-add/question-add.module#QuestionAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Question | Admin ' },

      },
      {
        path: 'question-import',
        loadChildren: './question-import/question-import.module#QuestionImportModule', canActivate: [AuthGuard],
        data: { title: ' Import Question | Admin ' },

      },
      {
        path: 'add-mcq-question',
        loadChildren: './question-mcq-add/question-mcq-add.module#QuestionMCQAddModule', canActivate: [AuthGuard],
        data: { title: ' Add MCQ Question | Admin ' },

      },
      {
        path: 'question',
        loadChildren: './question/question.module#QuestionModule', canActivate: [AuthGuard],
        data: { title: ' Question | Admin ' },

      },
      {
        path: 'questionCategory',
        loadChildren: './question-category/question-category.module#QuestionCategoryModule', canActivate: [AuthGuard],
        data: { title: '  Question Categories | Admin ' },

      },
      {
        path: 'questionCategoryAdd',
        loadChildren: './question-sub/question-sub.module#QuestionSubModule', canActivate: [AuthGuard],
        data: { title: ' Add Question Category | Admin ' },

      },
      {
        path: 'page',
        loadChildren: './page/page.module#PageModule', canActivate: [AuthGuard],
        data: { title: ' Page  Management | Admin ' },
      },
      {
        path: 'add-page',
        loadChildren: './page-add/page-add.module#PageAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Page | Admin ' },
      },
      {
        path: 'team',
        loadChildren: './team/team.module#TeamModule', canActivate: [AuthGuard],
        data: { title: ' Teams | Admin ' },
      },
      {
        path: 'add-team',
        loadChildren: './team-add/team-add.module#TeamAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Team | Admin ' },
      },
      {
        path: 'banner',
        loadChildren: './banner/banner.module#BannerModule', canActivate: [AuthGuard],
        data: { title: 'Banners | Admin ' },
      },
      {
        path: 'add-banner',
        loadChildren: './banner-add/banner-add.module#BannerAddModule', canActivate: [AuthGuard],
        // data: { title: ' Add Banner| Admin ' },
      },
      {
        path: 'testimonial',
        loadChildren: './testimonial/testimonial.module#TestimonialModule', canActivate: [AuthGuard],
        data: { title: ' Testimonials | Admin ' },
      },
      {
        path: 'add-testimonial',
        loadChildren: './testimonial-add/testimonial-add.module#TestimonialAddModule', canActivate: [AuthGuard],
        data: { title: ' Add Testimonial | Admin ' },
      },
      {
        path: 'sme',
        loadChildren: './sme/sme.module#SmeModule', canActivate: [AuthGuard],
        data: { title: ' SME | Admin ' },
      },
      {
        path: 'smeAdd',
        loadChildren: './sme-add/sme-add.module#SmeAddModule', canActivate: [AuthGuard],
        data: { title: ' Add SME | Admin ' },
      },
      {
        path: 'smeEdit',
        loadChildren: './sme-add/sme-add.module#SmeAddModule', canActivate: [AuthGuard],
        data: { title: ' Add SME | Admin ' },
      },
      {
        path: 'course',
        loadChildren: './course/course.module#CourseModule', canActivate: [AuthGuard],
        data: { title: ' Course | Admin ' },

      },
      {
        path: 'enrollments/list',
        component: ListEnrollmentsComponent,
        data: { title: 'Enrollments | Admin' }
      },
      {
        path: 'enrollments/details/:enrollmentId',
        component: EnrollmentDetailsComponent,
        data: { title: 'Enrollments | Admin' }
      },
      {
        path: 'enrollments/score',
        component: EnrollmentScoreComponent,
        data: { title: 'Enrollments | Admin' }
      },

      {
        path: 'discussion-forum',
        loadChildren: './discussion-forum/discussion-forum.module#DiscussionForumModule', canActivate: [AuthGuard],
        data: { title: ' Discussion Forum | Admin ' },
      },
      {
        path: 'student',
        loadChildren: './student/student.module#StudentModule', canActivate: [AuthGuard],
        data: { title: 'Students | Admin' }
      },
      {
        path: 'studentView',
        loadChildren: './student-view/student-view.module#StudentViewModule', canActivate: [AuthGuard],
        data: { title: ' Student View | Admin ' }
      },
      {
        path: 'eligibility-test/list',
        component: ListEligibilityTestComponent,
        canActivate: [AuthGuard],
        data: { title: ' Eligibility Test | Admin ' },
      },
      {
        path: 'eligibility-test/add',
        component: AddEligibilityTestComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Eligibility Test | Admin ' },

      },
      {
        path: 'eligibility-test/edit/:testId',
        component: EditEligibilityTestComponent,
        canActivate: [AuthGuard],
        data: { title: 'Edit Eligibility Test | Admin ' },


      },
      {
        path: 'eligibility-test/questions/list/:testId',
        component: ListTestQuestionsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Eligibility Test Questions | Admin ' },


      },
      {
        path: 'batch',
        loadChildren: './batches/batches.module#BatchesModule', canActivate: [AuthGuard],
        data: { title: ' Batches | Admin ' },

      },
      {
        path: 'reports',
        loadChildren: './reports/reports.module#ReportsModule', canActivate: [AuthGuard],
        data: { title: ' Reports | Admin ' },

      },
      {
        path: 'setting',
        loadChildren: './setting/setting.module#SettingModule', canActivate: [AuthGuard],
        data: { title: ' Reports | Admin ' },

      },
      {
        path: 'message',
        loadChildren: './messages/messages.module#MessagesModule', canActivate: [AuthGuard],
        data: { title: ' Messages | Admin ' },

      },
      {
        path: "notifications",
        component: NotificationsComponent,
        
        data: { title: ' Notifications | Admin ' },
      },
      {
        path: "password",
        component: StudentResetPasswordComponent, 
        data: { title: ' Reset Student Password | Admin ' },
      },
      {
        path: 'enroll-student',
        loadChildren: './enroll-students/enroll-students.module#EnrollStudentsModule', 
        // canActivate: [AuthGuard],
        data: { title: ' Enroll Student | Admin ' },

      },
    ]
  },


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
