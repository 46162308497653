import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  @ViewChild('myTable') table: CategoryComponent;
  session_id: any;
  selected=[];
  categoryList: any = [];
  temp = [];
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')

    this.init();
  }
  init() {
    this.spinner.show();
    this.categoryList = [];
    var category_fetch_url = "course_category";
    this.api.detailsGetFetch(category_fetch_url,this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.course_category[0]) {
        this.spinner.hide();
       
        this.temp = [...data.course_category];
        this.categoryList = data.course_category;
       }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  deleteCategory(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        var body = { "course_category_id": +id };
        var del_url = "delete_course_category?course_category_id=" + id;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
             this.init();
          }
          else {
            this.spinner.hide();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
       return title_name || status_name;
    });

    // update the rows
    this.categoryList = temp;
    // this.table.offset = 0;
  }
  editCategory(id) {
    this.router.navigate(['admin/add-category', { dist: `${id}` }]);

  }
}
