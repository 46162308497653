import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CurrencyComponent } from './currency.component';


const routes: Routes = [

  {
    path: '', component: CurrencyComponent,
    data: { title: 'Currencies | Admin ' }
  },


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CurrencyRoutingModule { }
