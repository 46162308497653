import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toaster.service';
import { MatDialog } from '@angular/material/dialog';
import { ConstantsService } from '../../../../../services/constants.service';
import { EMPTY, Subscription } from "rxjs";
import { Validatespace } from "src/app/validation/spacevalidation";
import * as moment from "moment";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  enrollmentId: any;
  session_id: any;
  sme: any;
  s3FilePath: string;
  message: any = null;
  userId: any;
  chatHistory: any = [];
  messageForm: FormGroup;
  chatDate: any;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private rote: ActivatedRoute,
    private ToasterService: ToasterService,
    public dialog: MatDialog,
    private constantService: ConstantsService,
    private formBuilder: FormBuilder,) {
    this.messageForm = this.formBuilder.group({
      message: ['', [Validators.required, Validatespace]]

    });
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.onSubmit();
  }

  ngOnInit(): void {
    this.rote.params.subscribe((params) => {
      this.enrollmentId = params['enrollment_id'];
    });
    this.session_id = localStorage.getItem('access_token');
    this.s3FilePath = this.constantService.s3FilePath;
    this.userId = localStorage['user_Id'];
    this.getSme();
  }
  getSme() {
    this.spinner.show();
    let param = { enrollment_id: this.enrollmentId };
    let mycourse_fetch_url = 'getCourseSmedetailsByEnrollmentId';
    this.api
      .detailsUploadFetch(param, mycourse_fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true') {
            this.sme = data.sme_details
            this.getChatHistory();
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'warning');
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  getChatHistory() {
    this.spinner.show();
    let param = {
      userID: this.userId, chatWithID: this.sme.sme_id
    };
    let mycourse_fetch_url = 'getChatHistory';
    this.api
      .detailsUploadFetch(param, mycourse_fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true') {
            debugger
            this.chatHistory = data.chatHistory
            for(let item of this.chatHistory){
              // this.chatDate = item.time + item.date;
              this.chatDate = moment(item.utc_created_date + '+00:00').local().format("DD-MM-YYYY hh:mm A");
            }
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'warning');
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }

  onSubmit() {
    if (this.messageForm.invalid) {
      return;
    }
    this.spinner.show();
    let param = {
      userID: this.userId, chatWithID: this.sme.sme_id, message: this.messageForm.value.message
    };
    let mycourse_fetch_url = 'saveChatMessage';
    this.api
      .detailsUploadFetch(param, mycourse_fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true') {
            this.messageForm.reset();
            this.getChatHistory();
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'warning');
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

}
