<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/discussion-forum/addforum']">Discussion Forum</a></li>
              <li class="breadcrumb-item active">Add Forum</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <!-- <section class="content">
      <div class="card elevation-0">
        <div class="card-header">
          <h1>Add Forum</h1>
        </div>
        
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-primary p-3">
                <form [formGroup]="courseForm" >
                  <div class="clearfix"></div>
                  <mat-tab-group (selectedTabChange)="tabChange($event)">
  
                    <mat-tab label="General">
                      <ng-template mat-tab-label>
                        <span>General</span>
                        <span
                          *ngIf="submitted && (f.courceName.errors || f.courceCategory.errors || f.learningtype.errors || f.duration.errors || f.fileData.errors)"
                          matBadge="1" matBadgeOverlap="false" matBadgeColor="accent"></span>
                      </ng-template>
  
                      <div class="col-md-6 float-left">
  
                        <div class="form-group">
                          <label>Cource Name</label>
                          <input type="text" formControlName="courceName" class="form-control selact_"
                            placeholder="Cource Name">
                          <div *ngIf="submitted && f.courceName.errors" class="invalid-feedback">
                            <div *ngIf="f.courceName.errors.required">Country is
                              required
                            </div>
                          </div>
                        </div>
  
                        <div class="form-group">
                          <label>Cource Category</label>
                          <select class="form-control select2 selact_" formControlName="courceCategory"
                            style="width: 100%;">
                            <ng-container *ngFor="let courseCategory of courseCategoryList">
                              <option [value]="courseCategory.id">{{courseCategory.title}}</option>
                            </ng-container>
                          </select>
                          <div *ngIf="submitted && f.courceCategory.errors" class="invalid-feedback">
                            <div *ngIf="f.courceCategory.errors.required">Cource Category is
                              required
                            </div>
                          </div>
                        </div>
  
                        <div class="form-group">
                          <label>Learning Type</label>
                          <select class="form-control select2 selact_" formControlName="learningtype"
                            style="width: 100%;">
                            <option [value]='1'>Batch wise learning</option>
                            <option [value]='2'>Self based learning</option>
                          </select>
                          <div *ngIf="submitted && f.learningtype.errors" class="invalid-feedback">
                            <div *ngIf="f.learningtype.errors.required">Learning Type is
                              required
                            </div>
                          </div>
                        </div>
  
                        <div class="form-group">
                          <label>Duration ( number of weeks )</label>
                          <input type="number" class="form-control selact_" formControlName="duration"
                            placeholder="Duration">
                          <div *ngIf="submitted && f.duration.errors" class="invalid-feedback">
                            <div *ngIf="f.duration.errors.required">Duration is required</div>
                          </div>
                        </div>
                      </div>
  
                      <div class="col-md-4 float-left">
                        <div class="form-group">
                          <label>Cource Image</label>
                          <div class="uploadedImg">
                            <input type="file" class="mediaFile" formControlName="fileData" id="exampleInputFile"
                              (change)="onImageFileChanged($event)"
                              [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }">
                            <span class="up_img">Upload image</span>
  
                            <img [src]="imageSrc" *ngIf="imageSrc">
                          </div>
                          <div *ngIf="submitted && f.fileData.errors" class="invalid-feedback">
                            <div *ngIf="f.fileData.errors.required">Cource Image is
                              required
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </mat-tab>
                    <mat-tab label="Description">
                      <ng-template mat-tab-label>
                        <span>Description</span>
                        <span *ngIf="submitted && (f.shortDescription.errors ||  f.description.errors)" matBadge="1"
                          matBadgeOverlap="false" matBadgeColor="accent"></span>
                      </ng-template>
                      <div class="pad p-3">
                        <div class="col-md-6 float-left">
                          <div class="form-group">
                            <label>Course Description</label>
                            <input type="text" class="form-control selact_" formControlName="shortDescription"
                              placeholder="Course Description">
                            <div *ngIf="submitted && f.shortDescription.errors" class="invalid-feedback">
                              <div *ngIf="f.shortDescription.errors.required">Description is
                                required
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            
                            <ckeditor *ngIf="istexteditor" formControlName="description" [config]="ckeConfig"></ckeditor>
                            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                              <div *ngIf="f.description.errors.required">Course Description is
                                required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Files and Uploads">
                      <ng-template mat-tab-label>
                        <span>Files and Uploads</span>
                        <span *ngIf="submitted && (f.fileDataSyllabus.errors)" matBadge="1" matBadgeOverlap="false"
                          matBadgeColor="accent"></span>
                      </ng-template>
                      <div class="col-md-4 float-left">
  
                        <div class="form-group">
                          <label>Syllabus</label>
                          <input type="file" class=" file_selact_a mt-4" formControlName="fileDataSyllabus"
                            id="exampleInputFile">
                          <div *ngIf="submitted && f.fileDataSyllabus.errors" class="invalid-feedback">
                            <div *ngIf="f.fileDataSyllabus.errors.required">Syllabus File is
                              required
                            </div>
                          </div>
                        </div>
  
                      </div>
                    </mat-tab>
                    <mat-tab label="Price">
                      <ng-template mat-tab-label>
                        <span>Price</span>
                        <span *ngIf="submitted && (f.subscriptionType.errors)" matBadge="1" matBadgeOverlap="false"
                          matBadgeColor="accent"></span>
                      </ng-template>
                      <div class="col-md-6 float-left pt-3">
                       
                      </div>
                      <div class="clearfix"></div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Subscription Type</label>
                          <select class="form-control select2" formControlName="subscriptionType"
                            (change)="subscriptionTypeChange()" style="width: 100%;">
                            <option value="1">Free</option>
                            <option value="2">paid</option>
                          </select>
                          <div *ngIf="submitted && f.subscriptionType.errors" class="invalid-feedback">
                            <div *ngIf="f.subscriptionType.errors.required">Subscription Type is
                              required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 ">
                        <ng-container [formGroup]="costForm" novalidate>
                          <div *ngIf="cost" formArrayName="cost">
                            <div *ngFor="let p of cost.controls; let i=index">
                              <div [formGroupName]="i">
                                <label>Price in {{p.controls.currency_name.value}}</label>
                                <div class="form-group">
                                  <input type="text" class="form-control" formControlName="currency_id" hidden
                                    placeholder="Price">
                                  <input type="text" class="form-control" formControlName="amount" placeholder="Price">
                                </div>
  
                              </div>
                            </div>
                          </div>
                        </ng-container>
  
  
  
                      </div>
                      <div class="clearfix"></div>
                    </mat-tab>
                    <mat-tab label="Settings">
                      <div class="col-md-6 float-left pt-3">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox  mr-3">
  
                            <input class="custom-control-input" type="checkbox" id="Checkbox1"
                              formControlName="eligibilityTest" value="option1">
                            <label for="Checkbox1" class="custom-control-label pt-1">Eligibility Test</label>
  
                          </div>
                          <div class="custom-control custom-checkbox  mr-3">
                            <input class="custom-control-input" type="checkbox" id="Checkbox2" checked
                              formControlName="collateralCource">
                            <label for="Checkbox2" class="custom-control-label pt-1">Collateral Cource</label>
                          </div>
                          <div class="custom-control custom-checkbox  mr-3">
                            <mat-slide-toggle *ngIf="isEdit" formControlName="isCourseActive">Active</mat-slide-toggle>
                          </div>
  
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Content" disabled='{{!isEdit}}'>
                      <div class="col-md-8 card mt-3  p-3">
  
  
  
                        <div cdkDropListGroup>
                          <div class="example-container content_list">
                            <ng-container *ngFor="let week of weeks; let i =index">
  
                              <div>
                                <h2 class="card-title">
                                  <label class="week_c mr-3">Week Name : </label>
                                  <label class="week_tittle">{{week.title}} </label>
                                </h2>
                                <button mat-button type="button" class="btn btn-outline-success btn-xs mr-1 float-right"
                                  (click)="addActivityResourse(week)">Add Activity/Resourse</button>
                                <button mat-button type="button" class="btn btn-outline-primary btn-xs mr-1 float-right"
                                  (click)="editWeek(week)">Edit</button>
                                <button *ngIf="contents[i].length === 0" mat-button type="button"
                                  class="btn btn-outline-danger btn-xs mr-1 float-right"
                                  (click)="deleteWeek(week)">Delete</button>
                              </div>
  
                              <div cdkDropList [cdkDropListData]="contents[i]" class="example-list mb-4"
                                (cdkDropListDropped)="drop($event)">
                                <ng-container *ngFor="let content of contents[i]; let j =index ">
                                  <div class="example-box" cdkDrag>{{content.course_content_type}}
                                    {{content.course_content_id}}
                                    <div class="btn-group btn_content float-right">
                                      <button mat-button type="button" class="btn btn-primary btn-xs"
                                        (click)="editContents(week, content)"><i class="far fa-edit"></i></button>
                                      <button mat-button type="button" class="btn btn-danger btn-xs"
                                        (click)="DeleteContents(content)"><i class="far fa-trash-alt"></i></button>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </ng-container>
                          </div>
  
                        </div>
  
  
                        <div class="card-footer">
                          <a (click)="addnewweek()" class="btn bg-gradient-success btn-sm float-right "
                            data-toggle="modal" data-target="#modal-lg">ADD NEW WEEK
                          </a>
                        </div>
  
                      </div>
  
  
  
  
  
                    </mat-tab>
                  </mat-tab-group>
  
                  <div class="clearfix"></div>
                  <div class="p-3">
                    <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
       
      </div>
     
  
  
    </section> -->
   
  </div>
  
