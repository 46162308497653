import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from '../../models/pageDto';



declare var $: any;
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  @ViewChild('myTable') table: PageComponent;
  session_id: any;
  p: number = 1;
  selected = [];
  pageList: any = [];
  temp = [];
  pageForm: FormGroup;
  searchTerm: any = '';
  page: Page;

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.pageForm = this.formBuilder.group({
      search: ['']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    this.setPage({ offset: 0 });

    this.init();
  }

  init() {
    this.spinner.show();
    this.pageList = [];
    var pages_fetch_url = "pages";
    this.api.detailsGetFetch(pages_fetch_url, this.session_id).subscribe((data: any) => {
      debugger;
      if (data.status == "true" && data.pages[0]) {
        this.spinner.hide();

        this.temp = [...data.pages];
        this.pageList = data.pages;
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  deletePage(id) {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        debugger
        var body = { "page_id": +id };
        var del_url = "delete_page?page_id=" + id;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          debugger
          console.log(data)
          if (data.status == "True") {
            this.spinner.hide();

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.init();
          }
          else {
            this.spinner.hide();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    debugger;
    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const meta_keyword_name = d.meta_keyword.toLowerCase().indexOf(val) !== -1 || !val;
      const meta_description_name = d.meta_description.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || meta_keyword_name || meta_description_name || status_name;
    });

    // update the rows
    this.pageList = temp;
    // this.table.offset = 0;
  }
  editPage(id) {
    this.router.navigate(['admin/add-page', { dist: `${id}` }]);

  }

  onSearch() {
    this.searchTerm = this.pageForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  setPage(pageInfo) {
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.pageList = [];


    var state_fetch_url = "pages?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;


    if (this.searchTerm.length > 0)
      state_fetch_url += "&searchTerm=" + this.searchTerm;

    this.api.detailsGetFetch(state_fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.pages[0]) {
        debugger
        this.spinner.hide();

        this.temp = [...data.pages];
        this.pageList = data.pages;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
}
