import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CourseCategoryComponent } from './course-category.component';
import { CourseCategoryRoutingModule } from './course-category-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [CourseCategoryComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    CourseCategoryRoutingModule,
    NgxDatatableModule,
    NgxPaginationModule
   ]
})
export class CourseCategoryModule { }  
