import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { PopupService } from '../../../services/popup.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Validatespace } from "src/app/validation/spacevalidation";
import { phoneNumberValidator } from "../../../validation/phone-validator";

@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.css']
})
export class SettingsGeneralComponent implements OnInit {
  isEdit: boolean = false;
  session_id: any;
  submitted: boolean = false;
  editMode: boolean = true;

  constructor(private formBuilder: FormBuilder, public api: ApiService, private router: Router, private spinner: NgxSpinnerService, private popup: PopupService) { }
  generalSettingForm: FormGroup;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.generalSettingForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone_number: ['',[
        Validators.required,
        Validatespace,
        phoneNumberValidator,
        Validators.minLength(8),
        Validators.maxLength(12),
      ]],
      address: ['', [Validators.required, Validatespace ]],
      facebookLink: ['', [
        Validatespace,
        Validators.pattern(
          /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        ),
      ]],
      twitterLink: ['', [
        Validatespace,
        Validators.pattern(
          /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        ),
      ]],
      instaLink: ['', [
        Validatespace,
        Validators.pattern(
          /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        ),
      ]],
      youtubeLink: ['', [
        Validatespace,
        Validators.pattern(
          /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        ),
      ]],
      androidLink: ['', [
        Validatespace,
        Validators.pattern(
          /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        ),
      ]],
      iosLink: ['', [
        Validatespace,
        Validators.pattern(
          /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        ),
      ]],
      useReferralPoint: ['',Validators.required],
      referal_points: ['',Validators.required],
      checkout_points:['',Validators.required],
      discount_type:['',Validators.required],
      discount_amount:['',Validators.required],
      minimum_course_amount:['',Validators.required],

    });
    this.spinner.show();
    debugger
    var url = "getAdminSettings";
    this.api.detailsGetFetch(url, this.session_id).subscribe((data: any) => {
      debugger
      console.log(data);
      if (data.status == "true" && data.settings) {

        this.generalSettingForm.get('email').setValue(data.settings.email);
        this.generalSettingForm.get('phone_number').setValue(data.settings.phone_number);
        this.generalSettingForm.get('address').setValue(data.settings.address);
        this.generalSettingForm.get('facebookLink').setValue(data.settings.soc_media_link_facebook);
        this.generalSettingForm.get('twitterLink').setValue(data.settings.soc_media_link_twitter);
        this.generalSettingForm.get('instaLink').setValue(data.settings.soc_media_link_instagram);
        this.generalSettingForm.get('youtubeLink').setValue(data.settings.soc_media_link_youtube);
        this.generalSettingForm.get('androidLink').setValue(data.settings.app_link_android);
        this.generalSettingForm.get('iosLink').setValue(data.settings.app_link_ios);
        this.generalSettingForm.get('referal_points').setValue(data.settings.refferalcode_point);
        if(data.settings.is_enable_points_checkout == 0){
          this.generalSettingForm.get('useReferralPoint').setValue(false);
        }else{
          this.generalSettingForm.get('useReferralPoint').setValue(true);
        }
        this.generalSettingForm.get('checkout_points').setValue(data.settings.course_checkout_point);
        this.generalSettingForm.get('discount_type').setValue(data.settings.discount_type);
        this.generalSettingForm.get('discount_amount').setValue(data.settings.discount_amount);
        this.generalSettingForm.get('minimum_course_amount').setValue(data.settings.minimun_course_amount);
        this.spinner.hide();


      } else {

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }

  getContentParam() {
    return {
      email: null, address: null, phone_number: null, soc_media_link_facebook: null, soc_media_link_twitter: null, soc_media_link_instagram: null, soc_media_link_youtube: null, app_link_android: null, app_link_ios: null,
      is_enable_points_checkout: null, refferalcode_point: null, course_checkout_point: null, discount_type: null, discount_amount: null,  minimun_course_amount: null
      
    };
  }
  get f() { return this.generalSettingForm.controls }
  onSubmit() {
    // this.editMode = true;
    debugger
    this.submitted = true;
    if (this.generalSettingForm.invalid) {
      return;
    }
    
    let param = this.getContentParam();
    param.email = this.generalSettingForm.value.email;
    param.phone_number = this.generalSettingForm.value.phone_number;
    param.address = this.generalSettingForm.value.address;
    param.soc_media_link_facebook = this.generalSettingForm.value.facebookLink;
    param.soc_media_link_instagram = this.generalSettingForm.value.instaLink;
    param.soc_media_link_twitter = this.generalSettingForm.value.twitterLink;
    param.soc_media_link_youtube = this.generalSettingForm.value.youtubeLink;
    param.app_link_android = this.generalSettingForm.value.androidLink;
    param.app_link_ios = this.generalSettingForm.value.iosLink;
    param.refferalcode_point = this.generalSettingForm.value.referal_points;
    if(this.generalSettingForm.value.useReferralPoint){
      param.is_enable_points_checkout = 1;
    }else{
      param.is_enable_points_checkout = 0;
    }
    param.course_checkout_point = this.generalSettingForm.value.checkout_points;
    param.discount_type = this.generalSettingForm.value.discount_type;
    param.discount_amount = this.generalSettingForm.value.discount_amount;
    param.minimun_course_amount = this.generalSettingForm.value.minimum_course_amount;
    this.spinner.show();
    var url = "update_settings";
    debugger
    this.api.detailsAddFetch(param,url,this.session_id).subscribe((data: any) =>{
      debugger
      console.log(data);
      if(data.status=="True" && data.message){
        this.spinner.hide();
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        this.router.navigate(['admin/setting/general-setting'])
      }else{
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup()
      }
    },(err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }
  

}
