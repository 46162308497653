<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/district']">Districts</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add District</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit District</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add District</h1>
        <h1 *ngIf="isEdit">Edit District</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="districtForm" (ngSubmit)="onSubmit()">
                <div class="col-md-6 float-left">
                  <div class="form-group">
                    <label>District *</label>
                    <input type="text" formControlName="district" class="form-control selact_" placeholder="District"
                      [ngClass]="{ 'is-invalid': submitted && f.district.errors }">
                    <div *ngIf="submitted && f.district.errors" class="invalid-feedback">
                      <div *ngIf="f.district.errors.required">District is
                        required
                      </div>
                      <div *ngIf="f.district.errors.validspace"> Invalid district name 
                      </div>
                    </div>
                  </div>
                <div class="form-group">
                  <label>Country *</label>
                  <select class="form-control select2 selact_" formControlName="country" (change)="getState($event)"
                    style="width: 100%;" [ngClass]="{ 'is-invalid': submitted && f.country.value==0 }">
                    <option value="">--Select Country--</option>
                     <option *ngFor="let items of countryList" value="{{items.id}}">{{items.title}}</option>

                  </select>
                  <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                    <div *ngIf="f.country.errors.required">Country is
                      required
                    </div>
                  </div>
                  <!-- <div class="invalid-feedback"  *ngIf="submitted && f.country.value==0">
                    Country is
                    required
                  </div> -->
                </div>
                <div class="form-group">
                  <label>State *</label>
                  <select class="form-control select2 selact_" formControlName="state" style="width: 100%;"
                    [ngClass]="{ 'is-invalid': submitted && f.state.value==0 }">
                    <option value="">-- Select State --</option>
                    <option *ngFor="let items of stateList" [ngValue]="items.id">{{items.title}}</option>
                  </select>
                  <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                    <div *ngIf="f.state.errors.required">State is
                      required
                    </div>
                  </div>
                  <!-- <div class="invalid-feedback" *ngIf="submitted && f.state.value==0">State is
                    required
                  </div> -->
                </div>
                <div class="form-group">
                  <label>Status *</label>
                  <select class="form-control select2 selact_" formControlName="status" style="width: 100%;"
                    [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                    <option value="1">Active</option>
                    <option value="2">Disabled</option>
                  </select>
                  <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                    <div *ngIf="f.status.errors.required">Status is
                      required
                    </div>
                  </div>
                </div>
                </div>
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
</div>
