import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DistrictComponent } from './district.component';
import { DistrictRoutingModule } from './district-routing.module';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@NgModule({
  declarations: [DistrictComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    DistrictRoutingModule,
    NgxPaginationModule,NgxDatatableModule
   ]
})
export class DistrictModule { }
