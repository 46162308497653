 <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/student']">Students</a></li>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/student-reset-password']">Student Reset password</a></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="card elevation-0">
        <div class="card-header">
          <h1>Reset Password Of {{studentName}}</h1>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-primary p-3 pb-5 add_contry_">
                <form [formGroup]="studentResetPasswordForm"  (ngSubmit)="onSubmit()">
                  
  
                  <div class="col-md-6 float-left">
                     
                    <div class="form-group">
                      <label>New Password</label>
                      <input type="password" formControlName="Password" class="form-control selact_" >
                    </div>
                    <div *ngIf="issubmitted && studentResetPasswordForm.controls.Password.errors" class="error_signup">
                        <small *ngIf="issubmitted && studentResetPasswordForm.controls.Password.errors.required"> Password is required.
                        </small>
                        <small *ngIf="issubmitted && studentResetPasswordForm.controls.Password.errors.minlength"> Minimum 6 Characters
                          required. </small>
                        <small *ngIf="issubmitted && studentResetPasswordForm.controls.Password.errors.maxlength"> Maximum 20 Characters
                          is Possible. </small>
                      </div>
                    <div class="form-group">
                      <label>Confirm Password</label>
                      <input type="password" formControlName="confirmPassword" class="form-control selact_" >
                    </div>
                    <div *ngIf="issubmitted && studentResetPasswordForm.controls.confirmPassword.errors" class="error_signup">
                        <small *ngIf="issubmitted && studentResetPasswordForm.controls.confirmPassword.errors.required"> Confirm Password
                          is required. </small>
      
                        <small *ngIf="issubmitted && studentResetPasswordForm.controls.confirmPassword.errors.mustMatch">Password and
                          Confirm Password is not Matching. </small>
                      </div>
                  </div>
                  <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                </div>
                </form>
                  </div>
                  <div class="clearfix"></div>
              
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      <!-- </div> -->
      <!-- /.card -->
    </section>
  </div>
  
