import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service';
import { QuestionModel, MediaFiles, Options, ChildQuestion } from '../../models/questionDto';
import { PrimeNGConfig } from 'primeng/api';
import { SpaceValidator } from '../../helpers/space.validator';
import { ToasterService } from 'src/app/services/toaster.service';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Title, Meta } from "@angular/platform-browser";


const MEDIA_FILE_OPTIONS: string = 'mediaFileOptions';
const OPTION_FILE_OPTIONS: string = 'optionFileOptions';
const OPTION_GROUP_OPTIONS: string = 'optionGroupOptions';
const CHILD_QUESTION_FILE_OPTIONS: string = 'childQuestionFileOptions';
const CHILD_OPTION_FILE_OPTIONS: string = 'childOptionFileOptions';

const MEDIA_FILE_DATA: string = 'mediaFileData';

const OPTION_FILE_DATA: string = 'optionFileData';
const OPTION_TEXT: string = 'optionText';

const CHILD_QUESTION_FILE_DATA: string = 'childQuestionFileData';
const CHILD_OPTION_FILE_DATA: string = 'childOptionFileData';
const CHILD_QUESTION_TEXT: string = 'childQuestionText';
const CHILD_OPTION_TEXT: string = 'childOptionText';

const OPTION_IS_ANSWER: string = 'optionIsAnswer';
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-question-add',
  templateUrl: './question-add.component.html',
  styleUrls: ['./question-add.component.css']
})
export class QuestionAddComponent implements OnInit {

  object: { [key: number]: File } = {};
  mcqFile: any = {}
  isEdit: boolean = false;
  session_id: any;
  questionCategoryList: any = [];
  difficultyLevelList: any = [];
  questionId: any;
  questionType: any = 1;
  questionTypeName: any = 'MCQ';
  isProgresBar: boolean = false;
  progresValue: any;
  selectedFile: any;
  mode: ProgressBarMode = 'indeterminate';
  fileElement = 0;
  optionElement = 0;
  isOptionProgresBar: boolean = false;
  optionProgresValue: any;
  addFileEnable: boolean = false;
  addOptionEnable: boolean;
  getQuestionType(qType: any) {
    this.questionType = qType;
    switch (qType) {
      case 1: {
        this.questionTypeName = 'MCQ';
        break;
      }
      case 2: {
        this.questionTypeName = 'Drag and drop';
        break;
      }
      case 3: {
        this.questionTypeName = 'Match the following';
        break;
      }
      case 4: {
        this.questionTypeName = 'Fill in the blanks';
        break;
      }
    }
  }
  fileName = '';
  p: number = 1;
  s3FilePath: string;
  selectedMediaFileOptions: any[] = [];
  selectedOptionFileOptions: any[] = [];
  selectedChildQuestionFileOptions: any[] = [];
  selectedChildOptionFileOptions: any[] = [];

  selectedMediaFileType: any[] = [];
  selectedOptionFileType: any[] = [];
  selectedChildQuestionFileType: any[] = [];
  selectedChildOptionFileType: any[] = [];

  mediaFileCounter: number = 0;
  optionFileCounter: number = 0;
  childQuestionAndOptionFileCounter: number = 0;
  questionModel: QuestionModel;
  questionForm: FormGroup;

  questionTxt: string = "";



  constructor(public primengConfig: PrimeNGConfig,
    public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private popup: PopupService,
    private spinner: NgxSpinnerService,
    private uploadService: UploadService,
    private constantService: ConstantsService,
    private ToasterService: ToasterService,
    private title: Title,
    private meta: Meta) {
    this.questionForm = this.formBuilder.group({
      questionTitle: ['', [Validators.required, Validatespace]],
      questionText: ['', [Validators.required, Validatespace]],
      answerExplanation: [''],
      questionCategory: ['', Validators.required],
      difficultyLevel: ['', Validators.required],
      status: ['1', Validators.required],
      qsFiles: this.formBuilder.array([]),
      options: this.formBuilder.array([])
    });
  }

  submitted = false;

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;


    this.selectedMediaFileType[0] = "image/*"
    this.selectedMediaFileOptions[0] = "1"

    this.selectedOptionFileType[0] = "image/*"
    this.selectedOptionFileOptions[0] = "4"
    this.addOptionEnable = true;


    this.questionModel = {
      title: this.f.questionTitle.value,
      status_id: 1,
      question_category_id: this.f.questionCategory.value,
      question_text: this.f.questionText.value,
      question_type: 1,
      answer_explanation: this.f.answerExplanation.value,
      difficulty_level: this.f.difficultyLevel.value,
      media_files: []
    };

    this.init();
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.id) {
        this.isEdit = true;
        this.addFileEnable = true;
        this.questionId = params.id;
        this.spinner.show();

        var single_url = "getQuestionById?question_id=" + `${params.id}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          debugger
          console.log(data)
          if (data.status == "true" && data.question[0]) {
            this.spinner.hide();
            this.questionForm.get('questionTitle').setValue(data.question[0].title);
            this.questionForm.get('questionText').setValue(data.question[0].question_text);
            this.questionForm.get('answerExplanation').setValue(data.question[0].answer_explanation);
            this.questionForm.get('questionCategory').setValue(data.question[0].category_id);
            this.questionForm.get('difficultyLevel').setValue(data.question[0].difficulty_level);
            this.getQuestionType(data.question[0].question_type);
            //this.questionType = data.question[0].question_type;

            if (data.question[0].media_files != undefined) {

              let i = 0;


              for (let fileList of data.question[0].media_files) {

                let fileListTypeList = this.questionForm.get(
                  "qsFiles"
                ) as FormArray;
                fileListTypeList.push(this.addFile());

                (this.questionForm.get("qsFiles") as FormArray).controls[i]
                  .get("media_type")
                  .setValue(parseInt(fileList.media_type));

                (this.questionForm.get("qsFiles") as FormArray).controls[i]
                  .get("path")
                  .setValue(fileList.path);

                (this.questionForm.get("qsFiles") as FormArray).controls[i]
                  .get("changeflag")
                  .setValue(false);

                (this.questionForm.get("qsFiles") as FormArray).controls[i]
                  .get("file")
                  .setValue({});

                i++;

              }

              // for (let i = 0; i < data.question[0].media_files.length; i++) {
              //   this.mediaFileCounter = data.question[0].media_files[i].id

              //   let mediaFilesExisting: MediaFiles = { id: this.mediaFileCounter, file: null, path: data.question[0].media_files[i].path, media_type: data.question[0].media_files[i].media_type, question_id: data.question[0].media_files[i].question_id };
              //   this.addMediaTable(mediaFilesExisting, this.mediaFileCounter, false);

              //   let controlName: any = MEDIA_FILE_OPTIONS + this.mediaFileCounter;
              //   this.questionForm.get(controlName).setValue(data.question[0].media_files[i].media_type);
              //   this.updateMediaFileAcceptType(this.mediaFileCounter);
              // }

            }


            if (data.question[0].options != undefined) {

              let i = 0;


              for (let optionList of data.question[0].options) {
                optionList
                debugger

                let optionTypeList = this.questionForm.get(
                  "options"
                ) as FormArray;
                optionTypeList.push(this.addOption());

                (this.questionForm.get("options") as FormArray).controls[i]
                  .get("option_media_type")
                  .setValue(optionList.option_media_type);

                (this.questionForm.get("options") as FormArray).controls[i]
                  .get("option")
                  .setValue(optionList.option);

                (this.questionForm.get("options") as FormArray).controls[i]
                  .get("changeflag")
                  .setValue(false);

                (this.questionForm.get("options") as FormArray).controls[i]
                  .get("file")
                  .setValue({});

                (this.questionForm.get("options") as FormArray).controls[i]
                  .get("is_answer")
                  .setValue(optionList.is_answer);

                (this.questionForm.get("options") as FormArray).controls[i]
                  .get("is_answer_")
                  .setValue(optionList.is_answer == 1 ? true : false);


                i++;

              }


              // for (let i = 0; i < data.question[0].options.length; i++) {
              //   this.optionFileCounter = data.question[0].options[i].id
              //   let optionExisting: Options = { id: this.optionFileCounter, option: data.question[0].options[i].option, option_media_type: data.question[0].options[i].option_media_type, file: null, is_answer: data.question[0].options[i].is_answer, question_id: data.question[0].options[i].question_id, group: data.question[0].options[i].group };
              //   this.addOptionTable(optionExisting, this.optionFileCounter, false, data.question[0].options[i].is_answer);

              //   let controlName: any = OPTION_FILE_OPTIONS + this.optionFileCounter;
              //   this.questionForm.get(controlName).setValue(data.question[0].options[i].option_media_type);
              //   if (data.question[0].options[i].option_media_type == 4) {
              //     let controlText: any = OPTION_TEXT + this.optionFileCounter;
              //     this.questionForm.get(controlText).setValue(data.question[0].options[i].option);
              //   }

              //   this.updateOptionFileAcceptType(this.optionFileCounter);
              // }
            }

            if (data.question[0].child_questions != undefined) {
              for (let i = 0; i < data.question[0].child_questions.length; i++) {
                this.childQuestionAndOptionFileCounter = data.question[0].child_questions[i].id
                let childQuestionExisting: ChildQuestion = {
                  id: this.childQuestionAndOptionFileCounter,
                  question_text: data.question[0].child_questions[i].question_text,
                  question_media_type: data.question[0].child_questions[i].question_media_type,
                  question_file: null,
                  option: data.question[0].child_questions[i].option,
                  option_media_type: data.question[0].child_questions[i].option_media_type,
                  option_file: null
                };
                this.addChildQuestionTable(childQuestionExisting, this.childQuestionAndOptionFileCounter, false);

                let controlName: any = CHILD_QUESTION_FILE_OPTIONS + this.childQuestionAndOptionFileCounter;
                this.questionForm.get(controlName).setValue(data.question[0].child_questions[i].question_media_type);
                if (data.question[0].child_questions[i].question_media_type == 4) {
                  let controlText: any = CHILD_QUESTION_TEXT + this.childQuestionAndOptionFileCounter;
                  this.questionForm.get(controlText).setValue(data.question[0].child_questions[i].question_text);
                }

                this.updateChildQuestionFileAcceptType(this.childQuestionAndOptionFileCounter);
                controlName = CHILD_OPTION_FILE_OPTIONS + this.childQuestionAndOptionFileCounter;
                this.questionForm.get(controlName).setValue(data.question[0].child_questions[i].option_media_type);
                if (data.question[0].child_questions[i].option_media_type == 4) {
                  let controlText: any = CHILD_OPTION_TEXT + this.childQuestionAndOptionFileCounter;
                  this.questionForm.get(controlText).setValue(data.question[0].child_questions[i].option);
                }

                this.updateChildOptionFileAcceptType(this.childQuestionAndOptionFileCounter);

              }
            }
            if (data.question[0].blanks != undefined) {
              for (let h = 0; h < data.question[0].blanks.length; h++) {
                for (let i = 0; i < data.question[0].blanks[h].options.length; i++) {
                  this.optionFileCounter = data.question[0].blanks[h].options[i].id
                  let optionExisting: Options = { id: this.optionFileCounter, option: data.question[0].blanks[h].options[i].option, option_media_type: data.question[0].blanks[h].options[i].option_media_type, file: null, is_answer: data.question[0].blanks[h].options[i].is_answer, question_id: data.question[0].blanks[h].options[i].question_id, group: data.question[0].blanks[h].options[i].group };
                  this.addOptionTable(optionExisting, this.optionFileCounter, false, data.question[0].blanks[h].options[i].is_answer);

                  let controlName: any = OPTION_FILE_OPTIONS + this.optionFileCounter;
                  this.questionForm.get(controlName).setValue(data.question[0].blanks[h].options[i].option_media_type);

                  controlName = OPTION_GROUP_OPTIONS + this.optionFileCounter;
                  this.questionForm.get(controlName).setValue(data.question[0].blanks[h].options[i].group);

                  if (data.question[0].blanks[h].options[i].option_media_type == 4) {
                    let controlText: any = OPTION_TEXT + this.optionFileCounter;
                    this.questionForm.get(controlText).setValue(data.question[0].blanks[h].options[i].option);
                  }

                  this.updateOptionFileAcceptType(this.optionFileCounter);
                }
              }
            }



            console.log(this.questionForm);
            console.log(this.addOptionEnable);
            console.log(this.addFileEnable);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
          this.title.setTitle("Edit Question | Admin | Al Jamia");
          this.meta.addTag({
            name: "description",
            content: "Edit Question | Admin",
          });
      }
      else {
        this.route.queryParams.subscribe(queryParams => {
          this.getQuestionType(+queryParams.type);
        });

        // let mediaFilesNew: MediaFiles = { id: 0, path: null, media_type: 1, file: null, question_id: null };
        // this.addMediaTable(mediaFilesNew, 0, false);

        switch (this.questionType) {
          case 1: {
            // let optionNew: Options = { id: 0, option: null, option_media_type: 4, file: null, is_answer: 0, question_id: null, group: null };
            // this.addOptionTable(optionNew, 0, false, 0)
            this.addNewOptions();
            this.addNewOptions();
            break;
          }
          case 2:{
            let optionNew: Options = { id: 0, option: null, option_media_type: 4, file: null, is_answer: 0, question_id: null, group: 1 };
            this.addOptionTable(optionNew, 0, false, 0)
            if (this.questionType == 2){
              this.incrementOptionFileCounter(4, 1, 1);
            }
            break;
          }
          case 4: {
            let optionNew: Options = { id: 0, option: null, option_media_type: 4, file: null, is_answer: 1, question_id: null, group: 1 };
            this.addOptionTable(optionNew, 0, false, 0)
            // if (this.questionType == 2){
            //   this.incrementOptionFileCounter(4, 1, 1);
            // }
            break;
          }
          case 3: {
            let childQuestionNew: ChildQuestion = { id: 0, question_text: null, question_media_type: 4, option: null, option_media_type: 4, question_file: null, option_file: null };
            this.addChildQuestionTable(childQuestionNew, 0, false)
            this.incrementChildQuestionFileCounter(4);
            break;
          }
        }

        this.isEdit = false;
        this.spinner.hide();
      }
    }
    );
  }

  displayModal: boolean;
  imageSrc: any;
  videoSrc: any;
  audioSrc: any;
  viewFile(src, fileType) {
    debugger
    this.imageSrc = undefined;
    this.audioSrc = undefined;
    this.videoSrc = undefined;
    switch (fileType) {
      case "1": {
        this.imageSrc = this.s3FilePath + src;
        break;
      }
      case "2": {
        this.audioSrc = this.s3FilePath + src;
        break;
      }
      case "3": {
        this.videoSrc = this.s3FilePath + src;
        break;
      }
      default: {
        break;
      }
    }
    // this.videoSrc = "https://r1---sn-h557sns7.c.drive.google.com/videoplayback?expire=1619116744&ei=iIqBYPXKFIKAlAedvIDwCw&ip=43.229.89.161&cp=QVRGQUJfVVdSRVhPOkFqRzFmS05KT3NzaFkwdU9LOVpNT0lpRktrZHFhYnRYWEluaDdPdks3WE4&id=d7e8ca24ca188d57&itag=18&source=webdrive&requiressl=yes&mh=EI&mm=32&mn=sn-h557sns7&ms=su&mv=m&mvi=1&pl=24&sc=yes&ttl=transient&susc=dr&driveid=1re9fncwiNIv41XvxeXt0vDmXPwPupfPq&app=explorer&mime=video/mp4&vprv=1&prv=1&dur=30.557&lmt=1619102296339443&mt=1619101940&sparams=expire,ei,ip,cp,id,itag,source,requiressl,ttl,susc,driveid,app,mime,vprv,prv,dur,lmt&sig=AOq0QJ8wRQIhANNebp0t_nzm-jf1HTIhP72LTnPj3MBK0YOAL513hB_XAiBxg8oB7IaQ6m-HGB5DsilnGnpFzXUhP8YGttx-uAsxIA==&lsparams=mh,mm,mn,ms,mv,mvi,pl,sc&lsig=AG3C_xAwRgIhAMH-pbtf_3MzxSwlwr6UZs0h7EXiwM-L-mOdB3tLRY-0AiEAxIbmOEcTtFj_dozmji7dhvTbCq2zQTYn17pnJf2q7DE=&cpn=rcWO14hPfUgDCsSY&c=WEB_EMBEDDED_PLAYER&cver=1.20210419.1.0"
    debugger
    this.displayModal = true;
  }

  isFieldValid(field: string) {
    // return !this.questionForm.get(field).valid && this.questionForm.get(field).touched;
    return !this.questionForm.get(field).valid && this.submitted;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }


  incrementMediaFileCounter(mediaType) {
    this.addFileEnable = false;
    this.progresValue = 0;
    this.mediaFileCounter++;

    let mediaFilesNew: MediaFiles = { id: this.mediaFileCounter, path: null, media_type: mediaType, file: null, question_id: null };
    this.addMediaTable(mediaFilesNew, this.mediaFileCounter, false);
  }

  addMediaTable(mediaFile, controlId, fileUploadRequireValidation) {
    this.questionModel.media_files.push(mediaFile);

    this.questionForm.addControl(MEDIA_FILE_OPTIONS + controlId, this.formBuilder.control('1'));
    if (fileUploadRequireValidation == true)
      this.questionForm.addControl(MEDIA_FILE_DATA + controlId, this.formBuilder.control('', Validators.required));
    else
      this.questionForm.addControl(MEDIA_FILE_DATA + controlId, this.formBuilder.control(''));
    this.selectedMediaFileOptions[controlId] = this.questionForm.get(MEDIA_FILE_OPTIONS + controlId).value;
    this.updateMediaFileAcceptType(controlId);
  }

  deleteMediaRow(id) {
    var delBtn = confirm(" Do you want to delete ?");
    if (delBtn == true) {
      this.questionModel.media_files = this.questionModel.media_files.filter(item => item.id !== id);
      this.questionForm.removeControl(MEDIA_FILE_DATA + id);
      this.questionForm.removeControl(MEDIA_FILE_OPTIONS + id);
    }
  }


  incrementOptionFileCounter(mediaType, group, checked) {
    this.optionFileCounter++;

    let optionNew: Options = { id: this.optionFileCounter, option: null, option_media_type: mediaType, file: null, is_answer: 1, question_id: null, group: group };
    this.addOptionTable(optionNew, this.optionFileCounter, false, checked);
  }

  addOptionTable(option, controlId, fileUploadRequireValidation, checked) {
    this.questionModel.options == undefined ? this.questionModel.options = [] : this.questionModel.options;
    this.questionModel.options.push(option);

    this.questionForm.addControl(OPTION_FILE_OPTIONS + controlId, this.formBuilder.control('4'));
    this.questionForm.addControl(OPTION_GROUP_OPTIONS + controlId, this.formBuilder.control('1'));
    this.questionForm.addControl(OPTION_TEXT + controlId, this.formBuilder.control('', Validators.compose([Validators.required, SpaceValidator.cannotContainSpace])));
    if (fileUploadRequireValidation == true)
      this.questionForm.addControl(OPTION_FILE_DATA + controlId, this.formBuilder.control('', Validators.required));
    else
      this.questionForm.addControl(OPTION_FILE_DATA + controlId, this.formBuilder.control(''));
    this.questionForm.addControl(OPTION_IS_ANSWER + controlId, this.formBuilder.control(checked));
    this.selectedOptionFileOptions[controlId] = this.questionForm.get(OPTION_FILE_OPTIONS + controlId).value;
  }

  deleteOptionRow(id) {
    var delBtn = confirm(" Do you want to delete ?");
    if (delBtn == true) {
      if ((this.questionModel.options.length <= 2) && (this.questionType == 2)) {
        this.popup.failureMessage = "Atleast two options required, cannot be deleted.";
        this.popup.failurepopup();;
        return;
      }
      if ((this.questionModel.options.length <= 1) && (this.questionType == 4)) {
        this.popup.failureMessage = "Atleast one options required, cannot be deleted.";
        this.popup.failurepopup();;
        return;
      }
      this.questionModel.options = this.questionModel.options.filter(item => item.id !== id);
      this.questionForm.removeControl(OPTION_FILE_OPTIONS + id);
      this.questionForm.removeControl(OPTION_FILE_DATA + id);
      this.questionForm.removeControl(OPTION_TEXT + id);
    }
  }


  incrementChildQuestionFileCounter(mediaType) {
    this.childQuestionAndOptionFileCounter++;

    let childQuestionNew: ChildQuestion = { id: this.childQuestionAndOptionFileCounter, question_text: null, question_media_type: mediaType, option: null, option_media_type: mediaType, question_file: null, option_file: null };
    this.addChildQuestionTable(childQuestionNew, this.childQuestionAndOptionFileCounter, false);
  }

  addChildQuestionTable(childQuestion, controlId, fileUploadRequireValidation) {
    this.questionModel.child_questions == undefined ? this.questionModel.child_questions = [] : this.questionModel.child_questions;
    this.questionModel.child_questions.push(childQuestion);

    this.questionForm.addControl(CHILD_QUESTION_FILE_OPTIONS + controlId, this.formBuilder.control('4'));
    this.questionForm.addControl(CHILD_OPTION_FILE_OPTIONS + controlId, this.formBuilder.control('4'));

    this.questionForm.addControl(CHILD_QUESTION_TEXT + controlId, this.formBuilder.control('', Validators.compose([Validators.required, SpaceValidator.cannotContainSpace])));
    this.questionForm.addControl(CHILD_OPTION_TEXT + controlId, this.formBuilder.control('', Validators.compose([Validators.required, SpaceValidator.cannotContainSpace])));
    if (fileUploadRequireValidation == true) {
      this.questionForm.addControl(CHILD_QUESTION_FILE_DATA + controlId, this.formBuilder.control('', Validators.required));
      this.questionForm.addControl(CHILD_OPTION_FILE_DATA + controlId, this.formBuilder.control('', Validators.required));
    }
    else {
      this.questionForm.addControl(CHILD_QUESTION_FILE_DATA + controlId, this.formBuilder.control(''));
      this.questionForm.addControl(CHILD_OPTION_FILE_DATA + controlId, this.formBuilder.control(''));
    }

    this.selectedChildQuestionFileOptions[controlId] = this.questionForm.get(CHILD_QUESTION_FILE_OPTIONS + controlId).value;
    this.selectedChildOptionFileOptions[controlId] = this.questionForm.get(CHILD_OPTION_FILE_OPTIONS + controlId).value;
  }

  deleteChildQuestionRow(id) {
    var delBtn = confirm(" Do you want to delete ?");
    if (delBtn == true) {
      this.questionModel.child_questions = this.questionModel.child_questions.filter(item => item.id !== id);
      this.questionForm.removeControl(CHILD_QUESTION_FILE_OPTIONS + id);
      this.questionForm.removeControl(CHILD_QUESTION_FILE_DATA + id);
      this.questionForm.removeControl(CHILD_QUESTION_TEXT + id);

      this.questionForm.removeControl(CHILD_OPTION_FILE_OPTIONS + id);
      this.questionForm.removeControl(CHILD_OPTION_FILE_DATA + id);
      this.questionForm.removeControl(CHILD_OPTION_TEXT + id);
    }
  }



  init() {
    this.spinner.show();
    this.getQuestionCategories();
    this.getDifficultyLevels();
  }

  getQuestionCategories() {
    this.questionCategoryList = [];
    var list_fetch_url = "getQuestioncategories";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.question_categories[0]) {
        this.questionCategoryList = data.question_categories;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getDifficultyLevels() {
    this.difficultyLevelList = [];
    var list_fetch_url = "getDifficultylevels";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.difficulty_levels[0]) {
        this.difficultyLevelList = data.difficulty_levels;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  get f() { return this.questionForm.controls; }
  onMediaFileChanged(event, id) {
    if (event.target.files && event.target.files.length) {
      debugger
      this.isProgresBar = true;
      this.isOptionProgresBar = false;
      this.mode = 'indeterminate';
      this.progresValue = 0;

      let controlName: any = MEDIA_FILE_OPTIONS + id;

      this.questionModel.media_files.find(x => x.id === id).file = event.target.files[0];
      this.questionModel.media_files.find(x => x.id === id).path = event.target.files[0].name;
      this.uploadFile(event, 'questionMCQ', 'file');

    }
  }

  onOptionFileChanged(event, id) {
    if (event.target.files && event.target.files.length) {

      debugger
      this.isProgresBar = false;
      this.isOptionProgresBar = true;
      this.mode = 'indeterminate';
      this.optionProgresValue = 0;


      let controlName: any = OPTION_FILE_OPTIONS + id;

      this.questionModel.options.find(x => x.id === id).file = event.target.files[0];
      this.questionModel.options.find(x => x.id === id).option = event.target.files[0].name;
      this.uploadFile(event, 'questionMCQ', 'option');
      // this.uploadOptionFile(event, 'questionMCQ');
    }
  }

  onOptionTextChanged(event, id) {
    this.questionModel.options.find(x => x.id === id).option = event.currentTarget.value;
  }

  onOptionIsAnswerChanged(event, id) {
    debugger
    this.questionModel.options.find(x => x.id === id).is_answer = event.currentTarget.checked ? 1 : 0;
  }

  onChildQuestionFileChanged(event, id) {
    if (event.target.files && event.target.files.length) {

      let controlName: any = CHILD_QUESTION_FILE_OPTIONS + id;

      this.questionModel.child_questions.find(x => x.id === id).question_file = event.target.files[0];
      this.questionModel.child_questions.find(x => x.id === id).question_text = event.target.files[0].name;
    }
  }

  onChildQuestionTextChanged(event, id) {
    this.questionModel.child_questions.find(x => x.id === id).question_text = event.currentTarget.value;
  }

  onChildOptionFileChanged(event, id) {
    if (event.target.files && event.target.files.length) {

      let controlName: any = CHILD_OPTION_FILE_OPTIONS + id;

      this.questionModel.child_questions.find(x => x.id === id).option_file = event.target.files[0];
      this.questionModel.child_questions.find(x => x.id === id).option = event.target.files[0].name;
    }
  }

  onChildOptionTextChanged(event, id) {
    this.questionModel.child_questions.find(x => x.id === id).option = event.currentTarget.value;
  }




  onMediaFileOptionsChanged(id) {
    debugger
    //Delete the existing data of this row
    this.questionModel.media_files.find(x => x.id === id).path = null;
    this.questionModel.media_files.find(x => x.id === id).file = null;

    let controlMediaFileDataName: any = MEDIA_FILE_DATA + id;
    this.questionForm.controls[controlMediaFileDataName].setValue(null);
    this.updateMediaFileAcceptType(id);
  }

  updateMediaFileAcceptType(id) {
    let controlName: any = MEDIA_FILE_OPTIONS + id;

    let controlMediaFileDataName: any = MEDIA_FILE_DATA + id;

    this.selectedMediaFileOptions[id] = this.questionForm.get(controlName).value.toString();
    this.questionModel.media_files.find(x => x.id === id).media_type = this.questionForm.get(controlName).value;


    this.questionForm.controls[controlMediaFileDataName].updateValueAndValidity();

    switch (this.questionForm.get(controlName).value.toString()) {
      case "1": {
        this.selectedMediaFileType[id] = "image/*";
        break;
      }
      case "2": {
        this.selectedMediaFileType[id] = "audio/*";
        break;
      }
      case "3": {
        this.selectedMediaFileType[id] = "video/*";
        break;
      }
      default: {
        this.selectedMediaFileType[id] = "*/*";
        break;
      }
    }
  }


  onOptionFileOptionsChanged(id, event) {
    debugger
    //Delete the existing data of this row
    console.log(event.currentTarget.options.selectedIndex);
    if (event.currentTarget.options.selectedIndex == 3) {
      this.addOptionEnable = true;
    } else {
      this.addOptionEnable = false;
    }

    this.questionModel.options.find(x => x.id === id).option = null;
    this.questionModel.options.find(x => x.id === id).file = null;
    let controloptionTextName: any = OPTION_TEXT + id;
    this.questionForm.controls[controloptionTextName].setValue('');

    let controlOptionFileDataName: any = OPTION_FILE_DATA + id;
    this.questionForm.controls[controlOptionFileDataName].setValue(null);
    console.log(this.questionForm.controls.value);
    this.updateOptionFileAcceptType(id);
  }

  updateOptionFileAcceptType(id) {
    debugger
    let controlName: any = OPTION_FILE_OPTIONS + id;
    let controlOptionFileDataName: any = OPTION_FILE_DATA + id;
    let controloptionTextName: any = OPTION_TEXT + id;

    this.selectedOptionFileOptions[id] = this.questionForm.get(controlName).value.toString();
    // console.log(this.selectedOptionFileOptions[id]);
    // if(this.selectedOptionFileOptions[id] == 4){
    //   this.addOptionEnable = true;
    // }else{
    //   this.addOptionEnable = false;
    // }

    this.questionModel.options.find(x => x.id === id).option_media_type = this.questionForm.get(controlName).value;

    this.questionForm.controls[controlOptionFileDataName].setValidators(Validators.required);
    this.questionForm.controls[controloptionTextName].setValidators(Validators.required);

    switch (this.questionForm.get(controlName).value.toString()) {
      case "1": {
        this.selectedOptionFileType[id] = "image/*";
        if (this.questionModel.options.find(x => x.id === id).option != null)
          this.questionForm.controls[controlOptionFileDataName].clearValidators();
        this.questionForm.controls[controloptionTextName].clearValidators();
        break;
      }
      case "2": {
        this.selectedOptionFileType[id] = "audio/*";
        if (this.questionModel.options.find(x => x.id === id).option != null)
          this.questionForm.controls[controlOptionFileDataName].clearValidators();
        this.questionForm.controls[controloptionTextName].clearValidators();
        break;
      }
      case "3": {
        this.selectedOptionFileType[id] = "video/*";
        if (this.questionModel.options.find(x => x.id === id).option != null)
          this.questionForm.controls[controlOptionFileDataName].clearValidators();
        this.questionForm.controls[controloptionTextName].clearValidators();
        break;
      }
      default: {
        this.selectedOptionFileType[id] = "*/*";
        this.questionForm.controls[controlOptionFileDataName].clearValidators();
        break;
      }
    }
    this.questionForm.controls[controlOptionFileDataName].updateValueAndValidity();
    this.questionForm.controls[controloptionTextName].updateValueAndValidity()
  }

  onOptionGroupOptionsChanged(id) {
    let controlName: any = OPTION_GROUP_OPTIONS + id;
    this.questionModel.options.find(x => x.id === id).group = this.questionForm.get(controlName).value;
  }


  onChildQuestionFileOptionsChanged(id) {
    //Delete the existing data of this row
    this.questionModel.child_questions.find(x => x.id === id).question_text = null;
    this.questionModel.child_questions.find(x => x.id === id).question_file = null;
    let controlChildQuestionTextName: any = CHILD_QUESTION_TEXT + id;
    this.questionForm.controls[controlChildQuestionTextName].setValue('');

    let controlChildQuestionFileDataName: any = CHILD_QUESTION_FILE_DATA + id;
    this.questionForm.controls[controlChildQuestionFileDataName].setValue(null);

    this.updateChildQuestionFileAcceptType(id);
  }

  updateChildQuestionFileAcceptType(id) {
    let controlName: any = CHILD_QUESTION_FILE_OPTIONS + id;
    let controlChildQestionFileDataName: any = CHILD_QUESTION_FILE_DATA + id;
    let controlChildQuestionTextName: any = CHILD_QUESTION_TEXT + id;

    this.selectedChildQuestionFileOptions[id] = this.questionForm.get(controlName).value.toString();
    this.questionModel.child_questions.find(x => x.id === id).question_media_type = this.questionForm.get(controlName).value;

    this.questionForm.controls[controlChildQestionFileDataName].setValidators(Validators.required);
    this.questionForm.controls[controlChildQuestionTextName].setValidators(Validators.required);

    switch (this.questionForm.get(controlName).value.toString()) {
      case "1": {
        this.selectedChildQuestionFileType[id] = "image/*";
        if (this.questionModel.child_questions.find(x => x.id === id).question_text != null)
          this.questionForm.controls[controlChildQestionFileDataName].clearValidators();
        this.questionForm.controls[controlChildQuestionTextName].clearValidators();
        break;
      }
      case "2": {
        this.selectedChildQuestionFileType[id] = "audio/*";
        if (this.questionModel.child_questions.find(x => x.id === id).question_text != null)
          this.questionForm.controls[controlChildQestionFileDataName].clearValidators();
        this.questionForm.controls[controlChildQuestionTextName].clearValidators();
        break;
      }
      case "3": {
        this.selectedChildQuestionFileType[id] = "video/*";
        if (this.questionModel.child_questions.find(x => x.id === id).question_text != null)
          this.questionForm.controls[controlChildQestionFileDataName].clearValidators();
        this.questionForm.controls[controlChildQuestionTextName].clearValidators();
        break;
      }
      default: {
        this.selectedChildQuestionFileType[id] = "*/*";
        this.questionForm.controls[controlChildQestionFileDataName].clearValidators();
        break;
      }
    }
    this.questionForm.controls[controlChildQestionFileDataName].updateValueAndValidity();
    this.questionForm.controls[controlChildQuestionTextName].updateValueAndValidity()
  }

  onChildOptionFileOptionsChanged(id) {
    //Delete the existing data of this row
    this.questionModel.child_questions.find(x => x.id === id).option = null;
    this.questionModel.child_questions.find(x => x.id === id).option_file = null;
    let controlChildOptionTextName: any = CHILD_OPTION_TEXT + id;
    this.questionForm.controls[controlChildOptionTextName].setValue('');

    let controlChildOptionFileDataName: any = CHILD_OPTION_FILE_DATA + id;
    this.questionForm.controls[controlChildOptionFileDataName].setValue(null);

    this.updateChildOptionFileAcceptType(id);
  }

  updateChildOptionFileAcceptType(id) {
    let controlName: any = CHILD_OPTION_FILE_OPTIONS + id;
    let controlChildOptionFileDataName: any = CHILD_OPTION_FILE_DATA + id;
    let controlChildOptionTextName: any = CHILD_OPTION_TEXT + id;

    this.selectedChildOptionFileOptions[id] = this.questionForm.get(controlName).value.toString();
    this.questionModel.child_questions.find(x => x.id === id).option_media_type = this.questionForm.get(controlName).value;

    this.questionForm.controls[controlChildOptionFileDataName].setValidators(Validators.required);
    this.questionForm.controls[controlChildOptionTextName].setValidators(Validators.required);

    switch (this.questionForm.get(controlName).value.toString()) {
      case "1": {
        this.selectedChildOptionFileType[id] = "image/*";
        if (this.questionModel.child_questions.find(x => x.id === id).option != null)
          this.questionForm.controls[controlChildOptionFileDataName].clearValidators();
        this.questionForm.controls[controlChildOptionTextName].clearValidators();
        break;
      }
      case "2": {
        this.selectedChildOptionFileType[id] = "audio/*";
        if (this.questionModel.child_questions.find(x => x.id === id).option != null)
          this.questionForm.controls[controlChildOptionFileDataName].clearValidators();
        this.questionForm.controls[controlChildOptionTextName].clearValidators();
        break;
      }
      case "3": {
        this.selectedChildOptionFileType[id] = "video/*";
        if (this.questionModel.child_questions.find(x => x.id === id).option != null)
          this.questionForm.controls[controlChildOptionFileDataName].clearValidators();
        this.questionForm.controls[controlChildOptionTextName].clearValidators();
        break;
      }
      default: {
        this.selectedChildOptionFileType[id] = "*/*";
        this.questionForm.controls[controlChildOptionFileDataName].clearValidators();
        break;
      }
    }
    this.questionForm.controls[controlChildOptionFileDataName].updateValueAndValidity();
    this.questionForm.controls[controlChildOptionTextName].updateValueAndValidity()
  }



  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  async onSubmit() {
    this.submitted = true;

    // const invalid = [];
    // const controls = this.questionForm.controls;
    // for (const name in controls) {
    //   if (controls[name].invalid) {
    //     invalid.push(name);
    //   }
    // }
    this.questionForm
    debugger

    if (this.questionForm.invalid) {
      // this.validateAllFormFields(this.questionForm);
      return;
    }
    this.spinner.show();
    // if (this.progresValue == 0) {
    //   this.ToasterService.openSnackBar('File not completely uploaded', '', 'warning');
    //   return;
    // }
    if (this.optionProgresValue == 0) {
      this.ToasterService.openSnackBar('Option Files not completely uploaded', '', 'warning');
      return;
    }

    await this.pushQsFilesToS3();
    await this.pushoptionFilesToS3();

    this.questionModel.title = this.f.questionTitle.value;
    this.questionModel.status_id = this.f.status.value;
    this.questionModel.question_category_id = this.f.questionCategory.value;
    this.questionModel.question_text = this.f.questionText.value;
    this.questionModel.question_type = this.questionType;
    this.questionModel.answer_explanation = this.f.answerExplanation.value;
    this.questionModel.difficulty_level = this.f.difficultyLevel.value;




    // this.questionModel.media_files = this.questionModel.media_files.filter(item => item.path !== null);
    debugger

    this.questionModel.media_files = this.questionForm.value.qsFiles;
    if(this.questionType == 1){
      this.questionModel.options = this.questionForm.value.options;
    }
    

    if (this.questionModel.child_questions != undefined) {
      for (let i = 0; i < this.questionModel.child_questions.length; i++) {
        if (this.questionModel.child_questions[i].option_media_type != 4) {
          if (this.questionModel.child_questions[i].question_file != undefined) {
            this.uploadService.fileUpload(this.questionModel.child_questions[i].question_file, 'questionMCQ').then(res => {
              if (res) {
                this.questionModel.child_questions[i].question_text = res as any;
              }
            });
          }
          if (this.questionModel.child_questions[i].option_file != undefined) {
            this.uploadService.fileUpload(this.questionModel.child_questions[i].option_file, 'questionMCQ').then(res => {
              if (res) {
                this.questionModel.child_questions[i].option = res as any;
              }
            });
          }
        }
      }
    }

    if (!this.isEdit) {
      // this.spinner.show();
      const uploadData = new FormData();

      var url = "add_question";
      this.api.detailsUploadFetch(this.questionModel, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "TRUE") {
          this.spinner.hide();

          //this.questionForm.reset();
          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup();
          this.router.navigate(['admin/question'])
        }
        else {
          this.spinner.hide();
          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else {
      if (this.isEdit) {
        // this.spinner.show();

        var update_url = "update_question?question_id=" + this.questionId;
        this.api.detailsUploadFetch(this.questionModel, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            //this.questionForm.reset();
            this.submitted = false;

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/question'])
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }

  }

  uploadFile(event, folderPath, type) {
    debugger
    const reader = new FileReader();

    this.selectedFile = event.target.files[0];

    reader.readAsDataURL(this.selectedFile);
    // if (this.s3CourseVideoFileName) {
    //   var s3CoursVideoFileNamedelete = this.uploadService.fileDelete(this.s3CourseVideoFileName, folderPath);
    // }
    if (this.selectedFile != null) {
      this.uploadService.fileUpload(this.selectedFile, folderPath).then(res => {
        debugger

        if (res) {
          if (type == 'file') {

            this.questionModel.media_files[this.fileElement].path = res as any;
            this.fileElement++;
            console.log(this.questionModel.media_files);
            this.mode = 'determinate';
            this.progresValue = 100;
            this.addFileEnable = true;
          } else if (type == 'option') {
            this.questionModel.options[this.optionElement].option = res as any;
            this.optionElement++;
            console.log(this.questionModel.media_files);
            this.mode = 'determinate';
            this.optionProgresValue = 100;
            this.addOptionEnable = true;

          }
        }
      })
    }

  }


  // uploadOptionFile(event, folderPath){
  //   debugger
  //   const reader = new FileReader();

  //     this.selectedFile = event.target.files[0];

  //     reader.readAsDataURL(this.selectedFile);

  //     if (this.selectedFile != null) {
  //       this.uploadService.fileUpload(this.selectedFile, folderPath).then(res => {
  //         debugger

  //         if (res) {

  //           this.questionModel.options[this.optionElement].option = res as any;
  //           this.optionElement++;
  //           console.log(this.questionModel.media_files);
  //           this.mode = 'determinate';
  //           this.progresValue = 100;
  //         }
  //       })
  //     }

  // }


  // new media files

  addNewQsFile() {
    let fileListTypeList = this.questionForm.get(
      "qsFiles"
    ) as FormArray;
    fileListTypeList.push(this.addFile());
  }

  addFile() {
    return this.formBuilder.group({
      // id: [""],
      media_type: ["1"],
      file: [""],
      path: ["", Validators.required],
      changeflag: [false]
    });
  }

  onQsMediaTypeChanged(i) {
    // ((this.questionForm.get(
    //   "qsFiles"
    // ) as FormArray).at(i) as FormGroup).get("media_type").setValue(value);
    ((this.questionForm.get(
      "qsFiles"
    ) as FormArray).at(i) as FormGroup).get("path").setValue("");
  }

  get getFileList() {
    return this.questionForm.get("qsFiles") as FormArray;
  }

  fileListdelete(index) {
    this.getFileList.removeAt(index);
  }

  onQsFileChanged(e, index, type) {
    debugger
    var file = e.target.files[0];
    let imagePath_ = file.type;
      var ext = imagePath_.split('/');
      if(type == "image"){
      if (ext[0] == "image") {

        (this.questionForm.get("qsFiles") as FormArray).controls[index]
        .get("file")
        .setValue(file);
      (this.questionForm.get("qsFiles") as FormArray).controls[index]
        .get("path")
        .setValue(file.name);
      (this.questionForm.get("qsFiles") as FormArray).controls[index]
        .get("changeflag")
        .setValue(true);
      }else {
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("file").setValue('');
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("file").setErrors({ required: true });
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("path").setValue('');
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("path").setErrors({ required: true });
        this.ToasterService.openSnackBar('Invalid file type', '', 'warning');
      }
    }

    if(type == "audio"){
      if (ext[0] == "audio") {

        (this.questionForm.get("qsFiles") as FormArray).controls[index]
        .get("file")
        .setValue(file);
      (this.questionForm.get("qsFiles") as FormArray).controls[index]
        .get("path")
        .setValue(file.name);
      (this.questionForm.get("qsFiles") as FormArray).controls[index]
        .get("changeflag")
        .setValue(true);
      }else {
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("file").setValue('');
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("file").setErrors({ required: true });
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("path").setValue('');
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("path").setErrors({ required: true });
        this.ToasterService.openSnackBar('Invalid file type', '', 'warning');
      }
    }

    if(type == "video"){
      if (ext[0] == "video") {

        (this.questionForm.get("qsFiles") as FormArray).controls[index]
        .get("file")
        .setValue(file);
      (this.questionForm.get("qsFiles") as FormArray).controls[index]
        .get("path")
        .setValue(file.name);
      (this.questionForm.get("qsFiles") as FormArray).controls[index]
        .get("changeflag")
        .setValue(true);
      }else {
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("file").setValue('');
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("file").setErrors({ required: true });
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("path").setValue('');
        (this.questionForm.get("qsFiles") as FormArray).controls[index].get("path").setErrors({ required: true });
        this.ToasterService.openSnackBar('Invalid file type', '', 'warning');
      }
    }


    

  }

  async pushQsFilesToS3() {
    debugger
    let i = 0;
    for (let content of this.questionForm.value.qsFiles) {
      if (content.media_type) {
        if (content.changeflag) {

          let url = await this.uploadToS3(content.file, 'questionMCQ');
          (this.questionForm.get("qsFiles") as FormArray).controls[i]
            .get("path")
            .setValue(url);

          (this.questionForm.get("qsFiles") as FormArray).controls[i]
            .get("changeflag")
            .setValue(false);

          (this.questionForm.get("qsFiles") as FormArray).controls[i]
            .get("file")
            .setValue({});
        }

      }
      i++;
    }
  }

  async uploadToS3(file, folderPath) {
    debugger
    const reader = new FileReader();

    this.selectedFile = file;

    reader.readAsDataURL(this.selectedFile);

    if (this.selectedFile != null) {
      let s3FileName = await this.uploadService.fileUpload(this.selectedFile, folderPath);
      return await s3FileName;
    }

  }

  // new options

  addNewOptions() {
    let fileListTypeList = this.questionForm.get(
      "options"
    ) as FormArray;
    fileListTypeList.push(this.addOption());
  }

  addOption() {
    if (this.questionType == 1) {
      return this.formBuilder.group({
        // id: [""],
        option_media_type: ["4"],
        file: [""],
        option: ["", [Validators.required, Validators.maxLength(1000), Validatespace]],
        is_answer: ["0"],
        is_answer_: [false],
        changeflag: [false]
      });
    } else if (this.questionType == 3) {

    }

  }

  get getOptionList() {
    return this.questionForm.get("options") as FormArray;
  }

  optionListdelete(index) {
    this.getOptionList.removeAt(index);
  }

  onOptionTypeChanged(index) {
    // ((this.questionForm.get(
    //   "qsFiles"
    // ) as FormArray).at(i) as FormGroup).get("media_type").setValue(value);
    (this.questionForm.get("options") as FormArray).controls[index]
      .get("file")
      .setValue("");

    (this.questionForm.get("options") as FormArray).controls[index]
      .get("option")
      .setValue("");

    (this.questionForm.get("options") as FormArray).controls[index]
      .get("is_answer")
      .setValue("0");
  }

  onOptionFleChanged(e, index, type) {
    debugger
    var file = e.target.files[0];
    let imagePath_ = file.type;
      var ext = imagePath_.split('/');
      if(type == "image"){
      if (ext[0] == "image") {

    (this.questionForm.get("options") as FormArray).controls[index]
      .get("file")
      .setValue(file);
    (this.questionForm.get("options") as FormArray).controls[index]
      .get("option")
      .setValue(file.name);
    (this.questionForm.get("options") as FormArray).controls[index]
      .get("changeflag")
      .setValue(true);
      }else {
        (this.questionForm.get("options") as FormArray).controls[index].get("file").setValue('');
        (this.questionForm.get("options") as FormArray).controls[index].get("file").setErrors({ required: true });
        (this.questionForm.get("options") as FormArray).controls[index].get("option").setValue('');
        (this.questionForm.get("options") as FormArray).controls[index].get("option").setErrors({ required: true });
        this.ToasterService.openSnackBar('Invalid file type', '', 'warning');
      }
    }

    else if(type == "audio"){
      if (ext[0] == "audio") {

    (this.questionForm.get("options") as FormArray).controls[index]
      .get("file")
      .setValue(file);
    (this.questionForm.get("options") as FormArray).controls[index]
      .get("option")
      .setValue(file.name);
    (this.questionForm.get("options") as FormArray).controls[index]
      .get("changeflag")
      .setValue(true);
      }else {
        (this.questionForm.get("options") as FormArray).controls[index].get("file").setValue('');
        (this.questionForm.get("options") as FormArray).controls[index].get("file").setErrors({ required: true });
        (this.questionForm.get("options") as FormArray).controls[index].get("option").setValue('');
        (this.questionForm.get("options") as FormArray).controls[index].get("option").setErrors({ required: true });
        this.ToasterService.openSnackBar('Invalid file type', '', 'warning');
      }
    }

    else if(type == "video"){
      if (ext[0] == "video") {

    (this.questionForm.get("options") as FormArray).controls[index]
      .get("file")
      .setValue(file);
    (this.questionForm.get("options") as FormArray).controls[index]
      .get("option")
      .setValue(file.name);
    (this.questionForm.get("options") as FormArray).controls[index]
      .get("changeflag")
      .setValue(true);
      }else {
        (this.questionForm.get("options") as FormArray).controls[index].get("file").setValue('');
        (this.questionForm.get("options") as FormArray).controls[index].get("file").setErrors({ required: true });
        (this.questionForm.get("options") as FormArray).controls[index].get("option").setValue('');
        (this.questionForm.get("options") as FormArray).controls[index].get("option").setErrors({ required: true });
        this.ToasterService.openSnackBar('Invalid file type', '', 'warning');
      }
    }

  }

  onOptionAnswerChanged(value, index) {
    debugger
    let val = value.currentTarget.checked ? 1 : 0;
    (this.questionForm.get("options") as FormArray).controls[index]
      .get("is_answer")
      .setValue(val);
  }

  async pushoptionFilesToS3() {
    debugger
    let i = 0;
    for (let content of this.questionForm.value.options) {
      if (content.option_media_type != 4) {
        if (content.changeflag) {

          let url = await this.uploadToS3(content.file, 'questionMCQ');
          (this.questionForm.get("options") as FormArray).controls[i]
            .get("option")
            .setValue(url);

          (this.questionForm.get("options") as FormArray).controls[i]
            .get("changeflag")
            .setValue(false);

          (this.questionForm.get("options") as FormArray).controls[i]
            .get("file")
            .setValue({});
        }

      }
      i++;
    }
  }

  addBlank1(){
    this.questionTxt = this.questionTxt + "%blank-1%"
  }

  addBlank2(){
    this.questionTxt = this.questionTxt + "%blank-2%"
  }

  addBlank3(){
    this.questionTxt = this.questionTxt + "%blank-3%"
  }

}
