import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, Event, NavigationEnd } from '@angular/router'
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  user_role: any;
  user_id: any;

  constructor(private router: Router) { }

  ngOnInit(): void {

    this.user_id = localStorage.getItem('user_id');
    this.user_role = localStorage.getItem('user_role');
    
    $('[data-widget="treeview"]').Treeview('init');
    //  $(function(){ $('#sideView').Treeview('init'); });
    // var url = window.location;
     // $('ul.nav-treeview a').filter(function () {
    //   return this.href == url;
    // }).parentsUntil(".sidebar-menu > .nav-treeview").addClass('menu-open');
    //   $('ul.nav-treeview a').filter(function() {
    //     return this.href == url;
    // }).parentsUntil(".nav-sidebar > .nav-treeview").addClass('menu-open').prev('a').addClass('active');
    // $('ul.nav-sidebar a').removeClass('active').parent().siblings().removeClass('menu-open');
    /*find active element add active class ,if it is inside treeview element, expand its elements and select treeview*/
    // $('ul.nav-sidebar a').filter(function () {
    //   return this.href == url;
    // }).addClass('active');

    //  $('ul.nav-treeview a').filter(function () {
    //   return this.navLink == url;
    // }).parentsUntil(".nav-sidebar > .nav-treeview").addClass('menu-open').prev('a').addClass('active');
  
}
ngAfterViewInit(): void {
  // const trees: any = $('[data-widget = "treeview"]');
  // trees.Treeview();
  // $('[data-widget="treeview"]').each(function () {
  //   trees.Treeview('init');
  // });
}

}
