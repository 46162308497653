import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.css']
})
export class TeamDetailsComponent implements OnInit {

  constructor(public api: ApiService, private spinner: NgxSpinnerService, private LocaldataService: LocaldataService, private router: Router, private rote: ActivatedRoute, private constantService: ConstantsService) { }
  teamSlug: any;
  teamDetails: any;
  courseList: any = [];
  currencyObj: any;
  currencyId: number = null;
  selectedpage: number = 1;
  courses = [];
  s3FilePath: string;

  ngOnInit(): void {
    // this.spinner.show();
    this.teamSlug = this.rote.snapshot.paramMap.get("team_slug");
    this.s3FilePath = this.constantService.s3FilePath;
    this.getTeamDetailById();
    // this.getCourses();
  }

  getTeamDetailById() {
    debugger
    let body = {team_id:this.teamSlug}
    var list_fetch_url = "temById";
    this.api.postDetails(list_fetch_url,body).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data) {
        this.spinner.hide();
        debugger
        this.teamDetails = data.team[0];
        // this.instructorFirstName = data.instructor.first_name
        // this.instructorLastName = data.instructor.last_name;
        // this.instructorEmail = data.instructor.email;
        // this.instructorQualification = data.instructor.qualification;
        // this.instructorProfilePic = data.instructor.profile_pic;
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
      }
    });

  }

  // getCourceParam() {
  //   return { currency_id: null, per_page_count: null, page: null, category_id: null, category_slug: null }
  // }
  // getCourceList() {
  //   return { id: null, title: null, description: null }
  // }
  // getCourses() {
  //   debugger
  //   this.spinner.show();
  //   var list_fetch_url = "student/course_list?";
  //   let searchTerm = this.getCourceParam();
  //   this.currencyObj = this.LocaldataService.getcurrencyInfo();
  //   this.currencyId = this.currencyObj.id;
  //   searchTerm.currency_id = this.currencyId;
  //   searchTerm.per_page_count = 6
  //   searchTerm.page = this.selectedpage;

  //   this.api.postDetails(list_fetch_url, searchTerm).subscribe((data: any) => {
  //     if (data.status == "true" && data) {
  //       debugger
  //       this.courses = data.courses;
  //       let i;
  //       for (i = 0; i < this.courses.length - 1; i++)
  //         console.log(this.courses[i].cost)
        
  //       this.spinner.hide();
  //     }
  //     else {
  //       this.spinner.hide();
  //     }
  //   }, (err: HttpErrorResponse) => {
  //     if (err.status == 403) {
  //       this.spinner.hide();
  //     }
  //   });
  // }

  // courseSelect(course) {
  //   debugger
  //   this.router.navigate(['course/' + course.slug])

  // }




}
