import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestimonialAddComponent } from './testimonial-add.component';


const routes: Routes = [

  {
    path: '', component: TestimonialAddComponent
},


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestimonialAddRoutingModule { }
