import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SmeAddComponent } from './sme-add.component';
import { SmeAddRoutingModule } from './sme-add-routing.module';


@NgModule({
  declarations: [SmeAddComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    SmeAddRoutingModule,
   ]
})
export class SmeAddModule { }
