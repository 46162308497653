import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { DialogData } from '../registeration/registeration.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-referalcode-popup',
  templateUrl: './referalcode-popup.component.html',
  styleUrls: ['./referalcode-popup.component.css']
})
export class ReferalcodePopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ReferalcodePopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder, public api: ApiService, private router: Router, private spinner: NgxSpinnerService, private constantService: ConstantsService, private ToasterService: ToasterService) { }

  referralForm: FormGroup;
  submitted = false;
  session_id: any;
  s3FilePath: any;
  refferal_code: any;
  user_Id: any;

  ngOnInit(): void {

    this.s3FilePath = this.constantService.s3FilePath;
    this.referralForm = this.formBuilder.group({
      refferalCode: ['', Validators.required],
    });
    debugger
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.user_Id = this.data.userId;

    // if (this.data.isActivityResourse) {
    //   this.getContentType()
    // } else if (this.data.Week.id) {
    //   this.referralForm.get('weekName').setValue(this.data.Week.title);
    // }

  }

  get f() {
    return this.referralForm.controls;
  }

  refferalCodeSubmit() {
    debugger
    this.submitted = true;
    if (this.referralForm.invalid) {
      return;
    }

    this.refferal_code = this.referralForm.value.refferalCode;
    let list_fetch_url = 'student/addReferralcode';
    var body = { "user_id": this.user_Id, "referral_code": this.refferal_code }
    this.api.postDetails(list_fetch_url, body).subscribe((data: any) => {
      debugger
      if (data.status == "True" && data) {
        debugger
        // this.spinner.hide();
        // localStorage.setItem('user_details', data);
        // this.ToasterService.openSnackBar(data.message, '', 'success');
          // window.location.reload();
          this.dialogRef.close('success');
        // this.router.navigate(['']);

      }
      else {
        this.spinner.hide();
        // this.PopupService.failureMessage = data.message[0]
        // this.PopupService.failurepopup()
        this.ToasterService.openSnackBar(data.message, '', 'warning');
        return;
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        //localStorage.clear()
        this.router.navigate([''])
      }
    });
    // this.dialogRef.close(this.refferal_code);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }


  // tabChange(tabChangeEvent: MatTabChangeEvent) {

  // }


}








