import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MessagesRoutingModule } from './messages-routing.module';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatWindowComponent } from './chat-window/chat-window.component';


@NgModule({
  declarations: [ChatListComponent, ChatWindowComponent],
  imports: [
    CommonModule,
    MessagesRoutingModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class MessagesModule { }
