import { Component, ElementRef, Inject, OnInit, Pipe, ViewChild, PipeTransform } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDataPayment } from '../../courses-details/courses-details.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  @ViewChild('dataContainer') dataContainer: ElementRef;
  
  constructor(public dialogRef: MatDialogRef<PaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPayment) {
      
     }

  ngOnInit(): void {
   // this.generatePDFReport();
   // this.loadData('https://uat-etendering.axisbank.co.in/easypay2.0/frontend/api/sdkpayment');
  }
  

  generatePDFReport() {
    //this.isReportButton = true;
    //this.isSpinnerShow = true;
 debugger
      var url = 'https://www.google.co.in/';
  
      this.createPDf(url);
      document.getElementById("reportArea").hidden = false;
    
  }

  createPDf(url) {
    document.getElementById("reportArea").innerHTML = "";
    var element = document.createElement("object");
    element.setAttribute("data", url);
    element.setAttribute("type", 'text/html');
    element.setAttribute("width", '1000');
    element.setAttribute("height", '591');
    var doc = document.getElementById("reportArea");
    doc.appendChild(element);
  }

 

  

    loadData(data) {
        this.dataContainer.nativeElement.innerHTML = data;
    }

}
