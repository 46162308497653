import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from '../../services/constants.service'
import { Page } from '../../models/pageDto';
import { PrimeNGConfig } from 'primeng/api';
declare var $: any;


@Component({
  selector: 'app-course-element',
  templateUrl: './course-element.component.html',
  styleUrls: ['./course-element.component.css'],
 
})
export class CourseElementComponent implements OnInit {
  display: boolean = false;

  showDialog() {
    this.display = true;
  }
  @ViewChild('myTable') table: CourseElementComponent;
  session_id: any;
  searchTerm: any = '';
  filterCourseElementType: any = ''
  filterCourseElementStatus: any = ''
  courseElementTypeList: any = [];
  p: number = 1;
  selected = [];
  s3FilePath: string; 
  course_elementsList: any = [];
  temp = [];
  deleteList: any = [];
  page: Page;

  //constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService, private constantService: ConstantsService) { }

  constructor(public primengConfig: PrimeNGConfig, public api: ApiService, private formBuilder: FormBuilder, private router: Router,  private popup: PopupService, private spinner: NgxSpinnerService, private constantService: ConstantsService) { }
  courseElementForm: FormGroup;
  ngOnInit() {
    this.primengConfig.ripple = true;
    
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.init();
    this.courseElementForm = this.formBuilder.group({
      search: [''],
      courseElementType: ['0'],
      courseElementStatus: ['0'],

    });
    this.s3FilePath = this.constantService.s3FilePath;
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page:10
    };
    this.setPage({ offset: 0 });
    //this.init();
  }
  displayModal: boolean;

  showModalDialog() {
    this.displayModal = true;
  }

  init() {
    this.spinner.show();
  }

  onSearch() {
    this.searchTerm = this.courseElementForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

  onCourseElementTypeFilter() {
    this.filterCourseElementType = this.courseElementForm.get('courseElementType').value > 0 ? this.courseElementForm.get('courseElementType').value.toString() : '';
    this.setPage({ offset: 0 });
  }

  onCourseElementStatusFilter() {
    this.filterCourseElementStatus = this.courseElementForm.get('courseElementStatus').value > 0 ? this.courseElementForm.get('courseElementStatus').value.toString() : '';
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  //init() {
  setPage(pageInfo) {
    
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.course_elementsList = [];

    var courseElement_fetch_url = "getCourseelements?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page ;
    if (this.searchTerm.length > 0)
      courseElement_fetch_url += "&searchTerm=" + this.searchTerm;

    if (this.filterCourseElementType.length > 0)
      courseElement_fetch_url += "&type_id=" + this.filterCourseElementType;

    if (this.filterCourseElementStatus.length > 0)
      courseElement_fetch_url += "&status_id=" + this.filterCourseElementStatus;

    this.api.detailsGetFetch(courseElement_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.course_elements[0]) {
        this.spinner.hide();
        
        this.temp = [...data.course_elements];
        this.course_elementsList = data.course_elements;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
       }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  
  deleteCourseElement(id) {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        var body = { "course_element_id": +id };
        var del_url = "delete_course_element";
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            //this.init();
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }

  deleteMultipleItem(){
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteList.push(element.id);
      });
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          var body = { "ids": this.deleteList };
          var del_url = "course_element_multi_delete";
          this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
            debugger
            this.deleteList = [];
            if (data.status == "True") {
              this.spinner.hide();
              this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
              this.setPage({ offset: 0 });
            }
            else {
              this.spinner.hide();
              this.popup.failureMessage = data.message
              this.popup.failurepopup()
            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
  
                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            }); 
        }else{
          this.deleteList = [];
        }
      })
    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    } 
  }
  
  editCourseElement(id) {
    this.router.navigate(['admin/courseElementAdd', { id: `${id}` }]);

  }
}
