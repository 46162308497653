<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-sm-left">
                        <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/admin/currency']">Currencies</a></li>
                        <li *ngIf="!isEdit" class="breadcrumb-item active">Add Currency</li>
                        <li *ngIf="isEdit" class="breadcrumb-item active">Edit Currency</li>

                    </ol>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="card elevation-0">
            <div class="card-header">
                <h1 *ngIf="!isEdit">Add Currency</h1>
                <h1 *ngIf="isEdit">Edit Currency</h1>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-primary p-3 pb-5 add_contry_">
                            <form [formGroup]="currencyForm" (ngSubmit)="onSubmit()">
                              <div class="col-md-6 float-left">
                                <div class="form-group">
                                  <label>Currency *</label>
                                  <input type="text" formControlName="currency" class="form-control selact_"
                                         placeholder="Currency"
                                         [ngClass]="{ 'is-invalid': submitted && f.currency.errors }">
                                  <div *ngIf="submitted && f.currency.errors" class="invalid-feedback">
                                    <div *ngIf="f.currency.errors.required">
                                      Currency is
                                      required
                                    </div>
                                    <div *ngIf="f.currency.errors.pattern">Valid currency name is
                                      required
                                  </div>
                                  <div *ngIf="f.currency.errors.validspace"> Invalid currency name 
                                  </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>Symbol *</label>
                                  <input type="text" formControlName="symbol" class="form-control selact_"
                                         placeholder="$"
                                         [ngClass]="{ 'is-invalid': submitted && f.symbol.errors }">
                                  <div *ngIf="submitted && f.symbol.errors" class="invalid-feedback">
                                    <div *ngIf="f.symbol.errors.required">
                                      Symbol is
                                      required
                                    </div>
                                    <div *ngIf="f.symbol.errors.validspace"> Invalid Symbol
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label>Currency Code *</label>
                                  <input type="text" formControlName="currency_code" class="form-control selact_"
                                         placeholder="Currency Code"
                                         [ngClass]="{ 'is-invalid': submitted && f.currency_code.errors }">
                                  <div *ngIf="submitted && f.currency_code.errors" class="invalid-feedback">
                                    <div *ngIf="f.currency_code.errors.required">
                                      Currency is
                                      required
                                    </div>
                                    <div *ngIf="f.currency_code.errors.pattern">Valid Currency Code is
                                      required
                                  </div>
                                  <div *ngIf="f.currency_code.errors.validspace"> Invalid Currency Code
                                  </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label>Country *</label>
                                  <select name="country" formControlName="country" id="countySel" size="1"
                                          class="form-control select2 selact_" style="width: 100%;" placeholder="Select Country" [ngClass]="{ 'is-invalid': submitted && f.country.value==0 }">
                                          <option [value]="0">--Select Country--</option>
                                    <option *ngFor="let item of countryList" [value]="item.id">{{item.title}}</option>
                                    
                                  </select>
                                  <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                    <div *ngIf="f.country.errors.required">
                                      Country is
                                      required
                                    </div>

                                  </div>
                                  <div class="invalid-feedback" *ngIf="submitted && f.country.value==0">
                                    Country is
                                    required
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label>Is Default *</label>
                                  <select formControlName="default" class="form-control select2 selact_"
                                          style="width: 100%;" [ngClass]="{ 'is-invalid': submitted && f.default.errors }">
                                    <option [value]="1">Yes</option>
                                    <option [value]="0">No</option>
                                  </select>
                                  <div *ngIf="submitted && f.default.errors" class="invalid-feedback">
                                    <div *ngIf="f.default.errors.required">
                                      Is Default is
                                      required
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>Status *</label>
                                  <select formControlName="status" class="form-control select2 selact_"
                                          style="width: 100%;" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                    <option [value]="1">Active</option>
                                    <option [value]="2">Disabled</option>
                                  </select>
                                  <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                    <div *ngIf="f.status.errors.required">
                                      Status is
                                      required
                                    </div>
                                  </div>
                                </div>
                              </div>
                                <!-- <div class="col-md-4 float-left">
                                  <div class="form-group">
                                    <label>Currency Image</label>
                                    <div class="uploadedImg">
                                      <input type="file" class="mediaFile" formControlName="fileData"
                                             id="exampleInputFile" (change)="onFileChanged($event)"
                                             [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }">
                                      <span class="up_img">Upload image</span>

                                      <img [src]="imageSrc" *ngIf="imageSrc">
                                    </div>
                                    <div *ngIf="submitted && f.fileData.errors" class="invalid-feedback">
                                      <div *ngIf="f.fileData.errors.required">
                                        Currency Image is
                                        required
                                      </div>
                                    </div>
                                  </div>

                                </div> -->
                             
                                 <div class="clearfix"></div>
                                <div class="p-3">
                                    <button type="submit"
                                        class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </section>
</div>
