import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-course-payment-status',
  templateUrl: './course-payment-status.component.html',
  styleUrls: ['./course-payment-status.component.css']
})
export class CoursePaymentStatusComponent implements OnInit {
  payment_status: any;

  constructor(
    private router: Router,
    private rote: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.rote.queryParams.subscribe(queryParams => {
      this.payment_status = queryParams.payment_status;

    });
  }

  onContinue() { 
    this.router.navigate(['/mycourses'])
  }

}
