import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-course-overview',
  templateUrl: './course-overview.component.html',
  styleUrls: ['./course-overview.component.css'],
})
export class CourseOverviewComponent implements OnInit {
  constructor(private rote: ActivatedRoute, private router: Router) {}

  enrollmentId: any;

  ngOnInit(): void {
    let routeParamList = this.router.url.split('/');
    if(routeParamList.length>3){
      this.enrollmentId = routeParamList[(routeParamList.length) - 1];
    }

    // this.rote.params.subscribe(params => {
    //   this.enrollmentId = params["enrollment_id"];
    // });

    // const urlTree = this.router.parseUrl(this.router.url);
    // const urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
  }
}
