import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QualificationAddComponent } from './qualification-add.component';


const routes: Routes = [

  {
    path: '', component: QualificationAddComponent,
    data: { title: 'Add Qualification | Admin ' }

  },


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QualificationAddRoutingModule { }
