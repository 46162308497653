<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/course/courseList']">Course</a></li>
            <li class="breadcrumb-item active" *ngIf="!isEdit">Add Course</li>
            <li class="breadcrumb-item active" *ngIf="isEdit && coursedetails">{{coursedetails.name}}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add Course</h1>
        <h1 *ngIf="isEdit">Edit Course</h1>
        <small class="text-primary" *ngIf="coursedetails && coursedetails.active_enrollment_exist">
          * {{coursedetails.enroll_count}} active enrollments in this course
        </small>
        <small class="text-primary" *ngIf="coursedetails && !coursedetails.active_enrollment_exist">
          * No active enrollments in this course</small>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3">
              <form [formGroup]="courseForm" (ngSubmit)="onSubmit()">
                <div class="clearfix"></div>
                <mat-tab-group (selectedTabChange)="tabChange($event)">

                  <mat-tab label="General">
                    <ng-template mat-tab-label>
                      <span>General</span>
                      <span
                        *ngIf="submitted && (f.courceName.errors || f.courceCategory.errors || f.learningtype.errors || f.duration.errors || f.fileData.errors || f.sme_id.errors)"
                        matBadge="1" matBadgeOverlap="false" matBadgeColor="accent"></span>
                    </ng-template>

                    <div class="col-md-6 float-left">

                      <div class="form-group">
                        <label>Course Name *</label>
                        <input type="text" formControlName="courceName" class="form-control selact_"
                          placeholder="Course Name">
                        <div *ngIf="submitted && f.courceName.errors" class="text-danger">
                          <small *ngIf="f.courceName.errors.required">Course Name is
                            required
                          </small>
                          <small *ngIf="f.courceName.errors.validspace">Invalid Course Name
                          </small>
                        </div>
                      </div>

                      <div class="form-group">
                        <label>Course Category *</label>
                        <select class="form-control select2 selact_" formControlName="courceCategory"
                          style="width: 100%;">
                          <option value="">--Select Course Category-- </option>
                          <option *ngFor="let courseCategory of courseCategoryList" value="{{courseCategory.id}}">
                            {{courseCategory.title}}</option>
                        </select>
                        <div *ngIf="submitted && f.courceCategory.errors" class="text-danger">
                          <small *ngIf="f.courceCategory.errors.required">Course Category is
                            required
                          </small>
                        </div>
                      </div>

                      <div class="form-group">
                        <label>Learning Type *</label>
                        <select class="form-control select2 selact_" formControlName="learningtype" style="width: 100%;"
                          [attr.disabled]="isEdit == true ? true : null">
                          <option value=''>--Select Learning Type--</option>
                          <option [value]='1'>Batch wise learning</option>
                          <option [value]='2'>Self based learning</option>
                        </select>

                        <div *ngIf="submitted && f.learningtype.errors" class="text-danger">
                          <small *ngIf="f.learningtype.errors.required">Learning Type is
                            required
                          </small>
                        </div>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" formControlName="unlock_on_completion" id="unlock_on_completion"
                          type="checkbox" value="unlock_on_completion" [attr.disabled]="isEdit == true ? true : null">
                        <label class="pt-1"> unlock course content on completion of previous course content</label>
                      </div>
                      <label *ngIf="!isEdit" class="text-warning">* Note: Learning Type & Unlock course content field
                        once saved cannot be changed</label>

                      <div class="form-group" *ngIf="user_role != 'sme'">
                        <label>SME *</label>
                        <select class="form-control select2 selact_" formControlName="sme_id">
                          <option value="" selected>--Select SME-- </option>
                          <option *ngFor="let sme of smeList" value="{{sme.user_id}}">{{sme.first_name}}
                            {{sme.last_name}}</option>
                        </select>
                        <div *ngIf="submitted && f.sme_id.errors" class="text-danger">
                          <small *ngIf="f.sme_id.errors.required">SME is
                            required
                          </small>
                        </div>
                      </div>

                      <div class="form-group">
                        <label>Duration ( number of weeks ) *</label>
                        <input type="number" min="1" class="form-control selact_" formControlName="duration"
                          placeholder="Duration">
                        <div *ngIf="submitted && f.duration.errors" class="text-danger">
                          <small *ngIf="f.duration.errors.required">Duration is required</small>
                          <small *ngIf="f.duration.errors.pattern">
                            Invalid Duration </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 float-left">
                      <div class="form-group course_img">
                        <label>Course Image *</label>
                        <div class="uploadedImg course_image_">
                          <input type="file" class="mediaFile" formControlName="fileData" id="exampleInputFile"
                            (change)="onImageFileChanged($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }" accept="image/*">
                          <span class="up_img">Upload image</span>

                          <img [src]="imageSrc" *ngIf="imageSrc">
                        </div>
                        <div *ngIf="submitted && f.fileData.errors" class="text-danger">
                          <small *ngIf="f.fileData.errors.required">Course Image is
                            required
                          </small>
                        </div>
                        <div class="note_a p-3"><label>*Preferred resolution 600x340</label></div>
                      </div>
                    </div>

                  </mat-tab>
                  <mat-tab label="Description">
                    <ng-template mat-tab-label>
                      <span>Description</span>
                      <span *ngIf="submitted && (f.shortDescription.errors ||  f.description.errors)" matBadge="1"
                        matBadgeOverlap="false" matBadgeColor="accent"></span>
                    </ng-template>
                    <div class="pad p-3">
                      <div class="col-md-8 float-left">
                        <div class="form-group">
                          <label>Short Description *</label>
                          <textarea type="text" class="form-control selact_" formControlName="shortDescription"
                            placeholder="Short Description"></textarea>
                          <div *ngIf="submitted && f.shortDescription.errors" class="text-danger">
                            <small *ngIf="f.shortDescription.errors.required">Short Description is
                              required
                            </small>
                            <small *ngIf="f.shortDescription.errors.validspace">Invalid Short Description
                            </small>
                          </div>
                        </div>
                        <div class="mb-3">
                          <!-- <textarea class="textarea" placeholder="Place some text here"
                            style="width: 100%; height: 200px; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;"></textarea>
                        -->
                          <label>Description *</label>
                          <ckeditor *ngIf="istexteditor" formControlName="description" [config]="ckeConfig"></ckeditor>
                          <div *ngIf="submitted && f.description.errors" class="text-danger">
                            <small *ngIf="f.description.errors.required"> Description is
                              required
                            </small>
                            <small *ngIf="f.description.errors.validspace">Invalid Description
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab label="Files and Uploads">
                    <ng-template mat-tab-label>
                      <span>Files and Uploads</span>
                      <span *ngIf="submitted && (f.fileDataSyllabus.errors)" matBadge="1" matBadgeOverlap="false"
                        matBadgeColor="accent"></span>
                    </ng-template>
                    <div class="col-md-4 float-left">

                      <div class="form-group">
                        <label>Syllabus *</label>
                        <div *ngIf="pdfSrc && isEdit" class="form-group">
                          <div class="input-group">
                            <a href='{{pdfSrc}}' target="_blank" download>
                              <i class="far fa-eye"></i>View File
                            </a>
                          </div>
                        </div>
                        <input type="file" class=" file_selact_a mt-4" formControlName="fileDataSyllabus"
                          id="exampleInputFile" (change)="onfileDataSyllabusChanged($event)">
                        <!-- <p>{{pdfSrc}}</p> -->

                        <div *ngIf="submitted && f.fileDataSyllabus.errors" class="text-danger">
                          <small *ngIf="f.fileDataSyllabus.errors.required">Syllabus File is
                            required
                          </small>
                        </div>
                      </div>

                    </div>
                  </mat-tab>
                  <mat-tab label="Price">
                    <ng-template mat-tab-label>
                      <span>Price</span>
                      <span *ngIf="submitted && ((f.subscriptionType.errors) || costForm.get('cost').invalid)" matBadge="1" matBadgeOverlap="false"
                        matBadgeColor="accent"></span>
                    </ng-template>
                    <div class="col-md-6 float-left pt-3">
                      <!--  <div class="form-group">
                               <label>Cource duration</label>
                               <input type="text" class="form-control selact_" placeholder="Cource duration">
                             </div>-->
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Subscription Type *</label>
                        <select class="form-control select2" formControlName="subscriptionType"
                          (change)="subscriptionTypeChange()" style="width: 100%;">
                          <option value="">--Select Subscription Type-- </option>
                          <option value="1">Free</option>
                          <option value="2">Paid</option>
                        </select>
                        <div *ngIf="submitted && f.subscriptionType.errors" class="text-danger">
                          <small *ngIf="f.subscriptionType.errors.required">Subscription Type is
                            required
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 ">
                      <ng-container [formGroup]="costForm" novalidate>
                        <div *ngIf="cost" formArrayName="cost">
                          <div *ngFor="let p of cost.controls; let i=index">
                            <div [formGroupName]="i">
                              <label>Price in {{p.controls.currency_name.value}}</label>
                              <div class="form-group">
                                <input type="text" class="form-control" formControlName="currency_id" hidden
                                  placeholder="Price">
                                <input type="number" min="1" class="form-control" formControlName="amount" placeholder="Price">
                                <!-- <div *ngIf="submitted && p.amount.errors" class="text-danger">
                                  <small *ngIf="p.amount.errors.required">Subscription Type is
                                    required
                                  </small>
                                </div> -->
                                <div *ngIf="submitted && costForm.get('cost').at(i).get('amount').invalid">
                                  <small *ngIf="costForm.get('cost').at(i).get('amount').errors?.required"
                                    class="text-danger">Price is required</small> </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </ng-container>



                    </div>
                    <div class="clearfix"></div>
                  </mat-tab>
                  <mat-tab label="Settings">
                    <div class="col-md-6 float-left pt-3">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox  mr-3">

                          <input class="custom-control-input" type="checkbox" id="Checkbox1"
                            formControlName="eligibilityTest" value="option1">
                          <label for="Checkbox1" class="custom-control-label pt-1">Eligibility Test</label>

                        </div>
                        <div class="custom-control custom-checkbox  mr-3">
                          <input class="custom-control-input" type="checkbox" id="Checkbox2"
                            formControlName="collateralCource">
                          <label for="Checkbox2" class="custom-control-label pt-1">Deny student to enroll this course when he/she all ready enrolled another course.</label>
                        </div>

                        <!-- <div class="custom-control custom-checkbox  mr-3">
                          <mat-slide-toggle *ngIf="isEdit" formControlName="isCourseActive">Active</mat-slide-toggle>
                        </div> -->

                      </div>
                      <!-- <div class="form-group" *ngIf="isEdit">
                        <label>Course Status *</label>
                        <select formControlName="isCourseActive" class="form-control select2 selact_"
                            style="width: 100%;"
                            [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                            <option value="1" >Active</option>
                            <option value="2">Disabled</option>
                        </select>
                        <div *ngIf="submitted && f.isCourseActive.errors" class="invalid-feedback">
                            <div *ngIf="f.isCourseActive.errors.required">Status is
                                required
                            </div>
                        </div>
                    </div> -->
                    </div>
                  </mat-tab>
                  <mat-tab label="Content" disabled='{{!isEdit}}'>
                    <div class="col-md-8 card mt-3  p-3">



                      <div cdkDropListGroup>
                        <div class="example-container content_list">
                          <ng-container *ngFor="let week of weeks; let i =index">

                            <div>
                              <h2 class="card-title">
                                <label class="week_c mr-3">Week {{week.week}} : </label>
                                <label class="week_tittle">{{week.title}} </label>
                              </h2>
                              <button mat-button type="button" class="btn btn-outline-success btn-xs mr-1 float-right" *ngIf="coursedetails && !coursedetails.active_enrollment_exist"
                                (click)="addActivityResourse(week)">Add Activity/Resourse</button>
                              <button mat-button type="button" class="btn btn-outline-primary btn-xs mr-1 float-right"
                                (click)="editWeek(week)">Edit</button>
                              <button *ngIf="contents[i].length === 0" mat-button type="button"
                                class="btn btn-outline-danger btn-xs mr-1 float-right"
                                (click)="deleteWeek(week)">Delete</button>
                            </div>

                            <div cdkDropList [cdkDropListData]="contents[i]" class="example-list mb-4"
                              (cdkDropListDropped)="drop($event)">
                              <ng-container *ngFor="let content of contents[i]; let j =index ">
                                <div class="example-box" cdkDrag>{{content.unlock_after }}
                                  {{ content.course_content_type}} :
                                  {{content.title}}
                                  <div class="btn-group btn_content float-right">
                                    <button *ngIf="content.course_content_type_id == 5" mat-button type="button"
                                      class="btn btn-primary btn-xs" (click)="goToQuestion(content.course_content_id)">
                                      <i class="far fa-eye"></i>
                                    </button>
                                    <button mat-button type="button" class="btn btn-primary btn-xs"
                                      (click)="editContents(week, content)"><i class="far fa-edit"></i></button>
                                    <button mat-button type="button" class="btn btn-danger btn-xs"
                                      (click)="DeleteContents(content)"><i class="far fa-trash-alt"></i></button>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>

                      </div>


                      <div class="card-footer" *ngIf="coursedetails && !coursedetails.active_enrollment_exist">
                        <a (click)="addnewweek()" class="btn bg-gradient-success btn-sm float-right "
                          data-toggle="modal" data-target="#modal-lg">ADD NEW WEEK
                        </a>
                      </div>

                    </div>





                  </mat-tab>
                </mat-tab-group>

                <div class="clearfix"></div>
                <span class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Save</button>
                </span> <span class="p-3" *ngIf="isEdit && coursedetails">
                  <button *ngIf="coursedetails.status_id == 1" (click)="onStatusChange('2')" type="button"
                    class="btn btn-secondary btn-dark bor-rad_ float-right mr-2">Draft</button>
                  <button *ngIf="coursedetails.status_id == 2" (click)="onStatusChange('1')" type="button"
                    class="btn btn-secondary btn-dark bor-rad_ float-right mr-2">Publish</button>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

    <!-- /.modal -->



  </section>
  <!-- /.content -->
</div>
