import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { PopupService } from 'src/app/services/popup.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UploadService } from 'src/app/services/upload.service';
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-thread-add',
  templateUrl: './thread-add.component.html',
  styleUrls: ['./thread-add.component.css'],
})
export class ThreadAddComponent implements OnInit {
  threadAddformGroup: FormGroup;
  isSubmitted: boolean = false;
  userId: any = '';
  session_id: any = '';
  enrollmentId: any;

  myCourseDetails: any = [];

  constructor(
    private fb: FormBuilder,
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private PopupService: PopupService,
    private uploadService: UploadService,
    private ToasterService: ToasterService,
    private rote: ActivatedRoute,
    private constantService: ConstantsService
  ) {
    this.threadAddformGroup = fb.group({
      course_id: ['', Validators.compose([Validators.required])],
      title: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3), Validatespace]),
      ],
      description: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3), Validatespace]),
      ],
    });
  }

  async ngOnInit() {
    // await this.getUserCourseList();
    this.rote.params.subscribe((params) => {
      this.enrollmentId = params['enrollment_id'];
    });
    await this.getCourseIdbyEnrollmentId();
  }

  // async getUserCourseList() {
  //   this.session_id = localStorage.getItem('access_token');
  //   this.userId = localStorage['user_Id'];
  //   this.spinner.show();

  //   let param = { user_id: this.userId };
  //   let mycourse_fetch_url = 'my_courses';
  //   this.api
  //     .detailsUploadFetch(param, mycourse_fetch_url, this.session_id)
  //     .subscribe(
  //       (data: any) => {
  //         if (data.status == 'true' && data.courses) {
  //           debugger;
  //           this.myCourseDetails = data.courses;
  //           this.spinner.hide();
  //         } else {
  //           this.spinner.hide();
  //           this.ToasterService.openSnackBar(data.message, '', 'warning');
  //         }
  //       },
  //       (err: HttpErrorResponse) => {
  //         if (err.status == 403) {
  //           this.spinner.hide();
  //           //localStorage.clear()
  //           this.router.navigate(['']);
  //         }
  //       }
  //     );
  // }

  async getCourseIdbyEnrollmentId() {
    this.spinner.show();
    this.session_id = localStorage.getItem('access_token');
    let param = { enrollment_id: this.enrollmentId };
    let mycourse_fetch_url = 'getCourseSmedetailsByEnrollmentId';
    this.api
      .detailsUploadFetch(param, mycourse_fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true') {
           this.threadAddformGroup.controls.course_id.setValue( data.course_id);
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'warning');
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }
  getThreadParams() {
    return { course_id: null, title: null, description: null };
  }

  threadAddAction() {
    this.isSubmitted = true;
    debugger;
    if (this.threadAddformGroup.status == 'VALID') {
      let param = this.getThreadParams();
      param.course_id = this.threadAddformGroup.value.course_id;
      param.title = this.threadAddformGroup.value.title;
      param.description = this.threadAddformGroup.value.description;
      debugger
      this.saveData(param);
    }
  }

  saveData(param) {
    this.spinner.show();
    let list_fetch_url = 'add_thread';
    this.api.detailsAddFetch(param, list_fetch_url, this.session_id).subscribe(
      (data: any) => {
        if (data.status == 'True' && data) {
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'success');
          this.router.navigateByUrl('/mycourses/overview/discuss_forum/'+  this.enrollmentId);
        } else {
          this.ToasterService.openSnackBar(data.message, '', 'failed');
          this.spinner.hide();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          //localStorage.clear()
          //this.router.navigate([''])
        }
      }
    );
  }
}
