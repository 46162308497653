import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CountryAddComponent } from './country-add.component';
import { CountryAddRoutingModule } from './country-add-routing.module';


@NgModule({
  declarations: [CountryAddComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    CountryAddRoutingModule,
   ]
})
export class CountryAddModule { }
