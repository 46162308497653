<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item active">Enrollments</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="card">
  
        <div class="card-header">
          <h1>Enrollments</h1>
        </div>
  
  
  
        <form [formGroup]="enrollmentListForm" (ngSubmit)="onSearch()" class="form-inline filter_list">
          <div class="card-body pb-0">
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <input type="text" formControlName="search" class="form-control selact_ w-100" placeholder="Search text">
            </span>
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <button class="btn btn-dark ml-2 float-left" type="submit"> <i class="fas fa-search mr-1"></i> Search
              </button>
            </span>
            <!-- <a (click)="deleteMultipleItem()" class="btn btn-secondary btn-dark float-right add_country">
              Delete Selected Items 
            </a> -->
            <ng-container *ngIf="!!csvData">
              <a csvLink [data]="csvData" filename="Enrollment Details" class="btn btn-secondary ml-2 btn-dark float-right add_country">Export</a>
            </ng-container>
            <ng-container>
              <a [routerLink]="['/admin/enrollments/score']"class="btn btn-secondary btn-dark ml-2 float-right add_country" >Export Enrollment Score</a>
            </ng-container>
          </div>
        </form>
        <form [formGroup]="enrollmentListFilterForm" class="form-inline filter_list">
          <div class="card-body pb-0">
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <label class="float-left">Enrollment From Date</label>
              <input type="date" id="enrollmentFrom" [max]=todayDate [min]="startDateFrom" (change)="setToStartDate()" class="form-control" formControlName="enrollmentFrom"
              placeholder="Enrollment From Date">
            </span>
              <!-- <div class="form-label-group mb-2 col-md-6 float-left">
                <label>Date of Birth *</label>
                <input type="date" [max]=todayDate id="birthDate" class="form-control" formControlName="dateOfBirth"
                  placeholder="Date of Birth">
                <div *ngIf="issubmitted && signUpformGroup.controls.dateOfBirth.errors" class="error_signup"> 
                  <div *ngIf="issubmitted && signUpformGroup.controls.dateOfBirth.errors.required"> Date of Birth is required. </div>
                  <div *ngIf="signUpformGroup.get('dateOfBirth').errors?.dateError">
                    Date of Birth must be 10 year lesser than the current date
                  </div>
                </div>
              </div> -->
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <label class="float-left">Enrollment To Date</label>
              <input type="date" id="enrollmentTo" [max]=todayDate [min]="startDateTo" formControlName="enrollmentTo" class="form-control selact_ w-100" placeholder="Enrollment To Date">
            </span>
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <label class="float-left">Course</label>
              <select class="form-control select2  ml-1" formControlName="course" style="width:100%;">
                <option selected="selected" [value]="0">--Select Course--</option>
                <option *ngFor="let items of courseList" [value]="items.id">{{items.name}}</option>
              </select>
            </span>
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <label class="float-left">Status</label>
              <select class="form-control select2  ml-1" formControlName="status" style="width:100%;">
                <option selected="selected" [value]="0">--Select Status--</option>
                <option [value]="1">Undergoing</option>
                <option [value]="2">Discontinued</option>
                <option [value]="3">Completed</option>
              </select>
            </span>
            <span class="col-md-2 col-sm-4 float-left pl-0 mt-3">
              <button class="btn btn-dark ml-2 float-left" (click)="onFilter()">
                Filter
              </button>
              <button class="btn btn-dark ml-2 float-left" (click)="onReset()">
                Reset
              </button>
            </span>
            <!-- <span class="col-md-2 col-sm-4 float-left pl-0">
              
            </span> -->
           <!-- <a (click)="deleteMultipleItem()" class="btn btn-secondary btn-dark float-right add_country">
              Delete Selected Items 
            </a> -->
          </div>
        </form>
        <div class="card-body table-responsive">
  
          <ngx-datatable #myTable class="material striped" [rows]="enrollmentList" [columnMode]="'force'"
            [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" 
              [externalPaging]="true"
            [count]="page.total" [offset]="page.current_page" [limit]="page.per_page" (page)="setPagetest($event)" [selected]="selected" [selectionType]="'checkbox'" (select)="onSelect($event)">
            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
              [resizeable]="false">
              <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn">
                <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
              </ng-template>
              <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn">
                <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="Sl No" [sortable]="false">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column [width]="200" name="Name" prop="name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.name}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [width]="200" name="Course" prop="course_name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.course_name}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [width]="300" name="Enrolled Date" prop="enrolled_date">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.enrolled_date}}
              </ng-template>
            </ngx-datatable-column>
              
            <ngx-datatable-column name="Status" prop="status">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.status}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Action" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a matTooltip="Details" title="Enrollment detail" routerLink="/admin/enrollments/details/{{row.id}}" class="active_button mr-2"><i class="far fa-eye"></i></a>
                <!-- <a matTooltip="Edit" (click)="editTest(row.id)" class="edit_button mr-2"><i class="far fa-edit"></i></a>
                  <a matTooltip="Delete" class="close_button"><i class="far fa-times-circle"></i></a> -->
                  <a href="Javascript:void(0);" (click)="deleteSingleItem(row.id)" class="close_button"><i class="far fa-times-circle"></i></a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
          <a (click)="deleteMultipleItem()" class="btn btn-secondary btn-dark float-right add_country" style="margin-top:2%">
            Delete Selected Course
           </a>
        </div>
      </div>
      <!-- /.card -->
    </section>
    <!-- /.content -->
  </div>
