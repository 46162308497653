import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocaldataService } from 'src/app/services/localdata.service'
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { EMPTY, Subscription } from "rxjs";
import { ConstantsService } from 'src/app/services/constants.service';



@Component({
  selector: 'app-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.css']
})
export class WebComponent implements OnInit {

  constructor(public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private location: PlatformLocation,
    private rote: ActivatedRoute,
    private constantService: ConstantsService,) { }

  formCurrencyList = new FormControl('');
  formCourseSearch = new FormControl('');

  currencyList: any = [];
  courseList: any = [];
  homeData: any;
  loadComponent: boolean = false;
  islogin: boolean = false;
  username: any;
  facebookLink: any;
  instaLink: any;
  youtubeLink: any;
  twitterLink: any;
  email: any;
  address: any;
  phone: any;
  userSlug: any;
  user_Id: any;
  clickEventsubscription: Subscription;
  isPaymentScreen: boolean = false;
  href: any;
  profilePic: any;
  s3FilePath: string;




  async ngOnInit() {
    this.s3FilePath = this.constantService.s3FilePath;
    await this.getUser();
    //this.getCurrencies();
    this.clickEventsubscription = this.LocaldataService.getUpdatedUser().subscribe(() => {
      this.getUser();
    })
  }

  async getUser() {
    this.spinner.show();
    await this.getHomePage();
    this.username = await localStorage["user_name"];
    this.profilePic = this.s3FilePath + await localStorage["profilepic"];
    debugger
    if (this.username) {
      this.islogin = true;
    }

    this.href = await this.router.url;
    if (this.href.indexOf("course/details/") >= 0) {
      this.isPaymentScreen = true;
    } else {
      this.isPaymentScreen = false;
    }
  }

  onActivate(event) {
    window.scroll(0, 0)
  }

  ngAfterViewInit() {

  }


  getCurrencies() {
    var list_fetch_url = "student/currencies";
    this.api.detailsGet(list_fetch_url).subscribe((data: any) => {
      if (data.status == "true" && data.currencies) {
        this.currencyList = data.currencies;
        this.formCurrencyList.setValue(data.currencies[0]);
        this.LocaldataService.setcurrencyInfo(this.formCurrencyList.value);
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });
  }

  getHomePage() {
    var list_fetch_url = "student/getHomepage";
    this.api.detailsGet(list_fetch_url).subscribe((data: any) => {
      if (data.status == "true" && data) {
        this.homeData = data.data;
        this.courseList = this.homeData.course_categories;
        this.currencyList = this.homeData.currencies;
        this.formCurrencyList.setValue(this.homeData.currencies[0]);
        this.LocaldataService.setcurrencyInfo(this.formCurrencyList.value);
        this.LocaldataService.afterHomeDataLoad(this.homeData);
        this.loadComponent = true;
        this.socialMediaWebsite();
        this.spinner.hide();

      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });
  }


  currencyListChange() {
    this.LocaldataService.currencyChange(this.formCurrencyList.value);
  }

  courseSearch() {
    if (this.formCourseSearch.value) {
      this.router.navigate(['courses/search/' + this.formCourseSearch.value])
      setTimeout(() => {
        this.LocaldataService.courseSearch(this.formCourseSearch.value);
      }, 5);

      this.formCourseSearch.reset();
    }
  }

  courseListByCatogory(courseGroup) {
    if(courseGroup == 'all'){
      this.router.navigate(['courses'])
    }else{
    this.router.navigate(['courses/category/' + courseGroup.slug])
  }
    // const url='courses/category/' + courseGroup.slug;
    // window.open(url, '_blank');
    setTimeout(() => {
      this.LocaldataService.courseSearch(null);
    }, 5);
  }

  courseListBySubCatogory(courseSubGroup) {
    courseSubGroup
    debugger
    this.router.navigate(['courses/category/' + courseSubGroup.slug])

    setTimeout(() => {
      this.LocaldataService.courseSearch(null);
    }, 5);
  }
  courseSelect(course) {
    this.router.navigate(['course/' + course.slug])
    setTimeout(() => {
      this.LocaldataService.courseSearch(null);
    }, 5);

  }

  signUp() {
    this.router.navigate(['register'])
  }
  logIn() {
    this.router.navigate(['login'])
  }
  logOut() {
    localStorage.removeItem('user_Id');
    localStorage.removeItem('user_name');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    this.islogin = false;
    localStorage.clear();
    this.router.navigate([''])

  }
  gotoMyCourses() {
    this.router.navigate(['mycourses'])
  }
  gotoHome() {
    const link = document.createElement("a");
    link.href = "";
    link.setAttribute("visibility", "hidden");
    link.click()
    // this.router.navigate([''])
  }
  socialMediaWebsite() {
    this.facebookLink = this.homeData.settings.soc_media_link_facebook;
    this.instaLink = this.homeData.settings.soc_media_link_instagram;
    this.youtubeLink = this.homeData.settings.soc_media_link_youtube;
    this.twitterLink = this.homeData.settings.soc_media_link_twitter;
    this.email = this.homeData.settings.email;
    this.address = this.homeData.settings.address;
    this.phone = this.homeData.settings.phone_number;
  }
  async editProfile() {
    if (this.islogin == true) {
      // this.rote.params.subscribe(params=>{
      //   console.log(params)
      //   if (params.id) 
      this.user_Id = await localStorage["user_id"];
      this.router.navigate(['profile'])

      // })

    }
  }


}



