import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { UploadService } from 'src/app/services/upload.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ConstantsService } from 'src/app/services/constants.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AddcourseWeekPopupComponent } from '../addcourse-week-popup/addcourse-week-popup.component';
import { PopupService } from '../../../services/popup.service';
import { Validatespace } from "src/app/validation/spacevalidation";
import { identifierModuleUrl } from '@angular/compiler';



@Component({
  selector: 'app-addcourse',
  templateUrl: './addcourse.component.html',
  styleUrls: ['./addcourse.component.css']
})
export class AddcourseComponent implements OnInit {


  courseForm: FormGroup;
  session_id: any;
  s3FilePath: any;
  courseCategoryList: any = [];
  selectedFile: File;
  imageSrc: any;
  pdfSrc: any;
  submitted = false;
  currencies: any;
  isSubscriptionTypeIsFree: boolean = true;
  costForm: FormGroup;
  costs: any = [];
  s3CourseImageFileName: any;
  s3CoursePDFFileName: any;
  ckeConfig: any;
  courseId: any;
  isEdit: boolean = false;
  coursedetails: any;
  istexteditor: boolean = false;
  COURSE_IMAGE_FILE_PATH: string;
  COURSE_PDF_FILE_PATH: string;
  ImageProgresValue: number = 0;
  PDFProgresValue: number = 0;
  smeList: any = [];
  user_role: any;
  user_id: any;

  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ToasterService: ToasterService,
    private spinner: NgxSpinnerService,
    private uploadService: UploadService,
    private constantService: ConstantsService,
    private rote: ActivatedRoute,
    public dialog: MatDialog,
    private popup: PopupService,) {
    this.courseForm = this.formBuilder.group({
      courceName: ['', [Validators.required, Validatespace]],
      courceCategory: ['', Validators.required],
      learningtype: ['', Validators.required],
      duration: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]?$|^100$/)]],
      fileData: ['', Validators.required],
      shortDescription: ['', [Validators.required, Validatespace]],
      description: ['', [Validators.required, , Validatespace]],
      fileDataSyllabus: ['', Validators.required],
      subscriptionType: ['', Validators.required],
      sme_id: ['', Validators.required],
      // currencyList: this.formBuilder.group({ products: this.formBuilder.array([]) }),
      eligibilityTest: [''],
      collateralCource: [''],
      isCourseActive: [''],
      unlock_on_completion: [''],

    });
  }

  get cost() { return this.costForm.get('cost'); }

  getaddcourceObj() {
    return { course_id: null, sme_id: null, name: null, category_id: null, description: null, syllabus: null, duration: null, cost: [{ currency_id: null, currency_name: null, amount: null }], image: null, entrance_test: null, collateral_course: null, subscription_type: null, status_id: null, short_description: null, learning_type: null, course_overview: null, course_objectives: null, learn_from_course: null, course_structure_description: null, sort_order: [{ week_id: null, course_content_id: null, sort_order: null, }], unlock_on_completion: null };
  }

  async ngOnInit() {
    debugger
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;
    this.COURSE_IMAGE_FILE_PATH = this.constantService.COURSE_IMAGE_FILE_PATH;
    this.COURSE_PDF_FILE_PATH = this.constantService.COURSE_PDF_FILE_PATH;
    this.courseId = this.rote.snapshot.paramMap.get("courseId");
    this.user_role = localStorage.getItem('user_role');
    this.user_id = localStorage.getItem('user_id');
    debugger
    if (this.user_role == "sme") {
      this.courseForm.get('sme_id').setValue(this.user_id);
    } else {
      await this.getSme();
    }
    await this.getCourseCategory();
    await this.getCurrency();

    //this.isEdit = true;
    // this.courseForm.get('subscriptionType').setValue('1');
    // this.courseForm.get('learningtype').setValue('1');
    this.courseForm.get('isCourseActive').setValue('1');
    this.costForm = this.formBuilder.group({
      cost: this.formBuilder.array(this.costs)
    });
    this.ckeConfig = {
      allowedContent: true,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        // { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About,Styles,Font,FontSize'
      ,
      //     removePlugins: 'horizontalrule,about,list,others',
      // removeButtons: 'Save,NewPage,Preview,Print,Templates,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Find,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,PageBreak,Iframe,Font,TextColor,BGColor,ShowBlocks,Cut,Copy,Paste,Table,Image,Format,Maximize,Styles,Anchor,SpecialChar,PasteFromWord,PasteText,Scayt,Undo,Redo,Strike,RemoveFormat,Indent,Outdent,Blockquote,Link,Unlink,Subscript,Superscript'
    };

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.costForm.get(controlName).hasError(errorName);
  };

  async getCourseCategory() {
    this.spinner.show();
    var category_fetch_url = "getCoursecategories?status_id=1&sortby=asc";
    this.api.detailsGetFetch(category_fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.course_categories) {
        this.courseCategoryList = data.course_categories;
        // this.courseForm.get('courceCategory').setValue(data.course_categories[0].id);
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }


  async getCurrency() {
    this.spinner.show();
    var category_fetch_url = "currency";
    this.api.detailsGetFetch(category_fetch_url, this.session_id).subscribe((data: any) => {
      this.spinner.hide();
      if (data.status == "true" && data.currency) {
        this.currencies = data.currency;
        if (this.courseId) {
          this.isEdit = true;
          this.getCourseDetails();
        }
        //this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getCourseDetails() {
    this.spinner.show();
    var category_fetch_url = "edit_course?course_id=" + this.courseId;
    this.api.detailsGetFetch(category_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.course) {
        console.log(data);

        this.coursedetails = data.course;
        this.courseForm.get('courceName').setValue(this.coursedetails.name);
        this.courseForm.get('courceCategory').setValue(this.coursedetails.category_id);
        let selectedCategory = this.courseCategoryList.filter((x) => x.id == this.coursedetails.category_id);
        if (selectedCategory.length == 0) {
          this.courseForm.get('courceCategory').setValue("");
        }
        this.courseForm.get('learningtype').setValue(this.coursedetails.learning_type_id);
        this.courseForm.get('duration').setValue(this.coursedetails.duration);
        this.courseForm.get('sme_id').setValue(this.coursedetails.sme_id);
        this.s3CourseImageFileName = this.coursedetails.image;
        this.imageSrc = this.s3FilePath + this.s3CourseImageFileName;
        //this.courseForm.get('fileData').setValue(this.coursedetails.image);
        this.courseForm.get('fileData').setValidators(null);
        this.courseForm.get('fileData').setValue("");
        this.courseForm.get('shortDescription').setValue(this.coursedetails.short_description);
        this.courseForm.get('description').setValue(this.coursedetails.description);
        //this.courseForm.get('fileDataSyllabus').setValue(this.s3FilePath + this.coursedetails.syllabus);
        this.s3CoursePDFFileName = this.coursedetails.syllabus;
        this.pdfSrc = this.s3FilePath + this.s3CoursePDFFileName;
        this.courseForm.get('fileDataSyllabus').setValidators(null);
        this.courseForm.get('fileDataSyllabus').setValue("");
        this.courseForm.get('subscriptionType').setValue(this.coursedetails.subscription_type);
        if (this.coursedetails.subscription_type === 2) {
          this.subscriptionTypeChange();
        }
        if (this.coursedetails.entrance_test == 2) {
          this.courseForm.get('eligibilityTest').setValue(false);

        } else {
          this.courseForm.get('eligibilityTest').setValue(true);
        }
        if (this.coursedetails.collateral_course == 2) {
          this.courseForm.get('collateralCource').setValue(false);
        } else {

          this.courseForm.get('collateralCource').setValue(true);
        }
        this.courseForm.get('isCourseActive').setValue(this.coursedetails.status_id);
        if (this.coursedetails.unlock_on_completion == false) {
          this.courseForm.get('unlock_on_completion').setValue(false);
        } else {
          this.courseForm.get('unlock_on_completion').setValue(true);
        }


        // if (this.coursedetails.status_id === 1) {
        //   this.courseForm.get('isCourseActive').setValue(true);
        // } else {
        //   this.courseForm.get('isCourseActive').setValue(false);
        // }
        this.weeks = this.coursedetails.week_data;
        this.contents = [];
        this.coursedetails.week_data.forEach(element => {
          // let sortedContents = element.course_contents.sort((val1, val2) => { return val1.sort_order - val2.sort_order })
          this.contents.push(element.course_contents);
        });

        var ext = this.imageSrc.split('.').pop();
        debugger

        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  contents: any;
  get f() {
    return this.courseForm.controls;
  }
  onImageFileChanged(event) {
    debugger
    this.uploadFile(event, this.COURSE_IMAGE_FILE_PATH);
  }

  onfileDataSyllabusChanged(event) {
    debugger
    this.uploadFile(event, this.COURSE_PDF_FILE_PATH);
  }

  uploadFile(event, folderPath) {

    debugger
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.selectedFile = event.target.files[0];
      if (folderPath === this.COURSE_IMAGE_FILE_PATH) {
        let imagePath_ = this.selectedFile.type;
        var ext = imagePath_.split('/');
        if (ext[0] == "image") {
          reader.onload = () => {
            this.imageSrc = reader.result as string;
          }
        } else {
          this.ToasterService.openSnackBar('Unsupported file format', '', 'warning');
          this.selectedFile = null;
          this.courseForm.get('fileData').setValue('');
          this.courseForm.get('fileData').setErrors({ required: true });
          this.imageSrc = "";
          return
        }
      }

      else if (folderPath === this.COURSE_PDF_FILE_PATH) {
        debugger
        let pdfPath_ = this.selectedFile.name;
        var ext1 = pdfPath_.split('.').pop();
        if (ext1 == "pdf") {
          reader.onload = () => {
            this.pdfSrc = reader.result as string;
          }
        } else {
          this.ToasterService.openSnackBar('Unsupported file format', '', 'warning');
          this.selectedFile = null;
          this.courseForm.get('fileDataSyllabus').setValue('');
          this.courseForm.get('fileDataSyllabus').setErrors({ required: true });
          this.pdfSrc = "";
          return
        }
        

      }
      reader.readAsDataURL(this.selectedFile);
      if (folderPath === this.COURSE_IMAGE_FILE_PATH) {
        if (this.s3CourseImageFileName) {
          var s3CourseImageFileNamedelete = this.uploadService.fileDelete(this.s3CourseImageFileName, folderPath);
        }
        debugger
        if (this.selectedFile != null) {
          this.uploadService.fileUpload(this.selectedFile, folderPath).then(res => {
            debugger
            if (res) {
              this.s3CourseImageFileName = res;
              this.ImageProgresValue = 100;
            }
          });
          //this.s3CourseImageFileName = this.selectedFile.name;
        }
      }
      else if (folderPath === this.COURSE_PDF_FILE_PATH) {
        if (this.s3CoursePDFFileName) {
          var s3CoursePDFFileNamedelete = this.uploadService.fileDelete(this.s3CoursePDFFileName, folderPath);
        }
        if (this.selectedFile != null) {
          this.uploadService.fileUpload(this.selectedFile, folderPath).then(res => {
            debugger
            if (res) {
              this.s3CoursePDFFileName = res;
              this.PDFProgresValue = 100;
            }
          });
          //this.s3CoursePDFFileName = this.selectedFile.name;
        }
      }
    }

  }

  subscriptionTypeChange() {
    debugger
    if (this.courseForm.value.subscriptionType == 1) {
      this.isSubscriptionTypeIsFree = true;
      this.costForm = this.formBuilder.group({
        cost: this.formBuilder.array([])
      });

    } else {
      this.costs = [];
      this.currencies.forEach(element => {
        debugger
        if (this.isEdit && this.coursedetails.cost.length > 0) {
          debugger
          this.coursedetails.cost.forEach(element1 => {
            debugger
            if (element1.currency_id === element.id) {
              this.costs.push(this.formBuilder.group({
                currency_id: element1.currency_id,
                currency_name: element1.currency_name,
                amount: element1.amount
              }));
            }
          });
        } else {
          this.costs.push(this.formBuilder.group({
            currency_id: element.id,
            currency_name: element.title,
            amount: ['', Validators.required]
          }));
        }

      });

      this.costForm = this.formBuilder.group({
        cost: this.formBuilder.array(this.costs)
      });
      this.isSubscriptionTypeIsFree = false;
    }
  }
  onSubmit() {
    this.submitted = true;
    this.courseForm
    debugger
    let addObj = this.getaddcourceObj();
    if (this.courseForm.invalid || this.costForm.invalid) {
      return;
    }
    if (this.ImageProgresValue != 100 || this.PDFProgresValue != 100) {
      if (!this.s3CourseImageFileName || !this.s3CoursePDFFileName) {
        return;
      }
    }
    this.spinner.show();
    addObj.name = this.courseForm.value.courceName;
    addObj.category_id = this.courseForm.value.courceCategory;
    addObj.duration = this.courseForm.value.duration;
    addObj.image = this.s3CourseImageFileName;
    addObj.description = this.courseForm.value.description;
    addObj.short_description = this.courseForm.value.shortDescription;
    addObj.syllabus = this.s3CoursePDFFileName;
    // if (this.courseForm.value.isCourseActive) {
    //   addObj.status_id = 1;
    // } else {
    //   addObj.status_id = 2;
    // }
    addObj.status_id = this.courseForm.value.isCourseActive;
    addObj.subscription_type = this.courseForm.value.subscriptionType;
    addObj.cost = this.costForm.value.cost;
    addObj.sme_id = parseInt(this.courseForm.value.sme_id);
    if (this.courseForm.value.eligibilityTest) {
      addObj.entrance_test = 1;
    } else {
      addObj.entrance_test = 2;
    }
    if (this.courseForm.value.collateralCource) {
      addObj.collateral_course = 1;
    } else {
      addObj.collateral_course = 2;
    }
    addObj.learning_type = this.courseForm.value.learningtype;
    addObj.course_overview = '';
    addObj.course_objectives = '';
    addObj.learn_from_course = '';
    addObj.sort_order = [];
    this.weeks.forEach(elements => {
      let i = 1;
      elements.course_contents.forEach(element => {
        let sort = { week_id: elements.id, course_content_id: element.course_content_id, sort_order: i };
        i++;
        addObj.sort_order.push(sort);
      });
    })
    if (this.courseForm.value.unlock_on_completion) {
      addObj.unlock_on_completion = 1;
    } else {
      addObj.unlock_on_completion = 2;
    }
    var url = "";
    if (this.isEdit) {
      url = "update_course";
      addObj.course_id = this.coursedetails.id
    } else {
      url = "course_add";
    }

    this.api.detailsAddFetch(addObj, url, this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        this.spinner.hide();
        this.ToasterService.openSnackBar(data.message, '', 'success');
        this.router.navigate(['admin/course/courseList']);
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }
  tabChange(tabChangeEvent: MatTabChangeEvent) {
    if (tabChangeEvent.index == 1) {
      this.istexteditor = true;
    } else {
      this.istexteditor = false;
    }
  }

  weeks = [];
  resourse = [];
  week = { course_id: null, week: null, title: 'Week' }

  addnewweek() {
    this.week.course_id = this.courseId;
    this.week.week = this.weeks.length + 1;
    this.week.title = this.week.title + (this.weeks.length + 1);
    const dialogRef = this.dialog.open(AddcourseWeekPopupComponent, {
      height: '400px',
      width: '600px',
      data: { title: 'Add New Week', Week: this.week, isActivityResourse: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        let url = "add_week";
        let addWeekObj = { course_id: this.courseId, week: this.weeks.length + 1, title: result.title }
        this.api.detailsAddFetch(addWeekObj, url, this.session_id).subscribe((data: any) => {
          this.ToasterService.openSnackBar(data.message, '', 'success');
          this.getCourseDetails();
        }, (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
      }
    });
  }

  editWeek(week) {
    const dialogRef = this.dialog.open(AddcourseWeekPopupComponent, {
      height: '400px',
      width: '600px',
      data: { title: 'Edit Week', Week: week, isActivityResourse: false }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let url = "update_week_title";
        let addWeekObj = { week_id: result.week_id, title: result.title }
        this.api.detailsAddFetch(addWeekObj, url, this.session_id).subscribe((data: any) => {
          this.ToasterService.openSnackBar(data.message, '', 'success');
          this.getCourseDetails();
        }, (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
      }
    });
  }
  deleteWeek(week) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        let url = "delete_week";
        let addWeekObj = { week_id: week.id }
        this.api.detailsAddFetch(addWeekObj, url, this.session_id).subscribe((data: any) => {
          this.ToasterService.openSnackBar(data.message, '', 'success');
          this.getCourseDetails();
        }, (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
      }
    });
  }

  addActivityResourse(week) {
    const dialogRef = this.dialog.open(AddcourseWeekPopupComponent, {
      height: '400px',
      width: '600px',
      data: {
        title: 'Add Activity or Resourse', Week: week, isActivityResourse: true,
        // learningType: this.coursedetails.learning_type_id,
        course_Id: this.courseId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.isResource) {
          this.router.navigate(['admin/course/resource/' + this.courseId + '/' + result.resource.id + '/' + week.id]);
        } else {
          this.router.navigate(['admin/course/activity/' + this.courseId + '/' + result.activity.id + '/' + week.id]);
        }
        //  this.resourse.push(result);
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  editContents(week, content) {
    if (content.resource_type === 1) {
      this.router.navigate(['admin/course/resource/' + content.course_content_id]);
    } else {
      this.router.navigate(['admin/course/activity/' + content.course_content_id]);
    }

  }
  DeleteContents(content) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        let url = "delete_course_content";
        let addContentObj = { course_content_id: content.course_content_id }
        this.api.detailsAddFetch(addContentObj, url, this.session_id).subscribe((data: any) => {
          this.ToasterService.openSnackBar(data.message, '', 'success');
          this.getCourseDetails();
        }, (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
      }
    });
  }

  onStatusChange(id: any) {
    id
    debugger
    let value = {
      course_id: this.courseId,
      status_id: id
    }
    var url = "publishCourse?course_id=" + this.courseId + "&status_id=" + id;
    this.api.detailsUploadFetch(value, url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true") {
        this.spinner.hide();
        this.getCourseDetails()
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }

  goToQuestion(id) {
    this.router.navigate(['admin/course/activity/' + id + '/questions']);
  }

  async getSme() {
    var fetch_url = "sme_list?status_id=1&sortby=asc";
    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.sme) {
        this.smeList = data.sme;
      }
      else {

      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
}




// @Component({
//   selector: 'addcourse-week-dialog',
//   templateUrl: 'addcourse-week-dialog.html',
// })
// export class DialogAddCourseWeekDialog {

//   constructor(
//     public dialogRef: MatDialogRef<DialogAddCourseWeekDialog>,
//     @Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder, public api: ApiService, private router: Router, private spinner: NgxSpinnerService, private constantService: ConstantsService) { }

//   weekForm: FormGroup;
//   submitted = false;
//   session_id: any;
//   resources: any;
//   activities: any;
//   s3FilePath: any;

//   ngOnInit(): void {
//     this.s3FilePath = this.constantService.s3FilePath;
//     this.weekForm = this.formBuilder.group({
//       weekName: ['', Validators.required],
//     });
//     debugger
//     this.session_id = localStorage.getItem('JWT_TOKEN')
//     if (this.data.Week.id) {
//       this.weekForm.get('weekName').setValue(this.data.Week.title);
//     }
//     if (this.data.isActivityResourse) {
//       this.getContentType()
//     }
//   }

//   get f() {
//     return this.weekForm.controls;
//   }

//   weekSubmit() {
//     debugger
//     this.submitted = true;
//     if (this.weekForm.invalid) {
//       return;
//     }
//     let week = { status: true, message: null, week_id: null, title: null }
//     if (this.data.Week.id) {
//       week.week_id = this.data.Week.id;
//     }
//     week.title = this.weekForm.value.weekName;
//     this.dialogRef.close(week);
//   }

//   onNoClick(): void {
//     this.dialogRef.close(false);
//   }

//   getContentType() {
//     debugger
//     this.spinner.show();
//     var fetch_url = "get_content_types";
//     this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {
//       if (data.status == "true" && data.resources) {
//         this.resources = data.resources;
//         this.activities = data.activities;
//         //this.courseForm.get('courceCategory').setValue(data.course_categories[0].id);
//         this.spinner.hide();
//       }
//       else {
//         this.spinner.hide();
//       }
//     }, (err: HttpErrorResponse) => {
//       if (err.status == 403) {
//         this.spinner.hide();
//         localStorage.clear()
//         this.router.navigate(['admin/login'])
//       }
//     });

//   }
//   tabChange(tabChangeEvent: MatTabChangeEvent) {

//   }

//   resourceSelect(resource) {
//     debugger
//     this.dialogRef.close({ resource: resource, isResource: true });
//   }
//   activitySelect(activity) {
//     this.dialogRef.close({ activity: activity, isResource: false });
//   }

// }

export interface DialogData {
  title: string;
  Week: { status: null, message: null, id: null, title: null };
  isActivityResourse: boolean;
  // learningType: null;
  course_Id: null;
}

export interface Week {
  status: boolean,
  message: string,
  week_id: string,
  title: string
}



