import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from "moment";

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css']
})
export class PaymentListComponent implements OnInit {

  paymentList: any = [];
  session_id: any;

  constructor(
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getPaymentList();
  }

  getPaymentList() {
    this.spinner.show();

    this.session_id = localStorage.getItem('access_token')
    var fetch_userDetails_url = "payment_history";
    this.api.detailsGetFetch(fetch_userDetails_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.payment_history) {
        debugger
        this.paymentList = data.payment_history;
        for(let item of this.paymentList){
          item.payment_date =  moment(item.payment_date + '+00:00').local().format("DD-MM-YYYY hh:mm A");
        }
        this.spinner.hide();

      } else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        //localStorage.clear()
        this.router.navigate([''])
      }
    })
  }

  onInvoiceDetails(id: any) {
    this.router.navigate(["/subscription/invoice/" + id]);
  }

}
