import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import { PopupService } from '../../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../../services/upload.service';
import { ConstantsService } from '../../../services/constants.service'

@Component({
  selector: 'app-batch-add',
  templateUrl: './batch-add.component.html',
  styleUrls: ['./batch-add.component.css']
})
export class BatchAddComponent implements OnInit {
  batchAddFormGroup: FormGroup;
  submitted: any = false;
  todayDate: any;
  dropdownSettings: any = {};
  courseList: any = [];
  session_id: any;

  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private popup: PopupService,
    private spinner: NgxSpinnerService,
    private uploadService: UploadService,
    private constantService: ConstantsService) {
    this.batchAddFormGroup = this.formBuilder.group({
      course_id: ["", [Validators.required]],
      start_date: ["", [Validators.required]],
      end_date: ["", [Validators.required]],
      status_id: [1],
    });
  }


  async ngOnInit() {
    this.spinner.show();
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.dropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "course_name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: "All",
      allowSearchFilter: true,
    };

    await this.getCourses();
    this.todayDate = await this.convert();

    debugger
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.batchAddFormGroup
      .get(controlName)
      .hasError(errorName);
  };

  onSubmit() {
    this.submitted = true;
    this.batchAddFormGroup;

    if (this.batchAddFormGroup.valid) {
      if (new Date(this.batchAddFormGroup.value.start_date) > new Date((this.batchAddFormGroup.value.end_date))) {
        this.batchAddFormGroup
          .get("end_date")
          .setErrors({ dateError: true });
      }
      if (this.batchAddFormGroup.valid) {

        let value = {
          course_id: this.batchAddFormGroup.value.course_id[0].id,
          start_date: this.batchAddFormGroup.value.start_date,
          end_date: this.batchAddFormGroup.value.end_date,
          status_id: this.batchAddFormGroup.value.status_id

        }
        this.spinner.show();
        var url = "add_batch";
        this.api.detailsUploadFetch(value, url, this.session_id).subscribe((data: any) => {
          debugger

          console.log(data)
          if (data.status == "true") {
            this.spinner.hide();
            this.batchAddFormGroup.reset();
            this.submitted = false;
            this.popup.sucessMessage = data.message
            this.popup.sucesspopup();
            this.router.navigate(['admin/batch'])
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup();
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
    debugger
  }

  getCourses() {
    this.courseList = [];
    var batch_fetch_url = "getBatchWiseCourselist";
    this.api.detailsGetFetch(batch_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data) {
        this.courseList = data.courses;
        debugger
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }

  convert() {
    var date = new Date(),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + (date.getDate() + 1)).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  onItemSelect(item: any) {
    console.log("onItemSelect", item);
  }

  onSelectAll(items: any) {
    console.log("onSelectAll", items);
  }

}
