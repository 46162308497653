<div class="container-fluid py-4 overview_course">
  <div class="pb-3">
    <h1>Thread</h1>
  </div>
  <div class="discussion_box">
    <div class="discussion_header">
        <a routerLink="/mycourses/overview/discuss_forum/{{enrollmentId}}"
          class="btn btn-dark float-right">Back</a>
      </div>
    <div class="discussion_content">

      <div class="col-sm-12 col-md-10 col-lg-10 mx-auto">
        <div class="card  my-5 border-0 shadow-sm">
          <div class="card-body" *ngIf="threadDetails">
            <div class="discussion_header thread_replay_head">
              <div class="profile_img rounded-circle float-left">
                <img src="{{s3FilePath}}{{threadDetails.created_user_image}}" alt="name">
              </div>
              <div class="chat_ib float-left pl-3">
                <h5>{{threadDetails.created_by}}</h5>
                <!-- <span class="time_date"> {{ threadDetails.thread_created_time }} |
                  {{ threadDetails.thread_created_date | date }}</span> -->
                  <span class="time_date"> {{ threadDetails.utc_created_date.date }} </span>
              </div>
            </div>

            <ng-container *ngIf="isThreadEdit">
              <form class="form-signup" [formGroup]="threadEditFormGroup" (ngSubmit)="threadEditAction()">
                <ng-container *ngIf="threadData">
                  <div class="form-label-group mb-2">
                    <label>Title *</label>
                    <input type="text" id="title" class="form-control" formControlName="title" placeholder="Title"
                      required>
                    <div *ngIf="isThreadUpdateSubmitted && threadEditFormGroup.controls.title.errors"
                      class="text-danger">
                      <div *ngIf="isThreadUpdateSubmitted && threadEditFormGroup.controls.title.errors.required">
                        Thread title is required. </div>
                      <div
                        *ngIf="!threadEditFormGroup.controls.title.errors.required && threadEditFormGroup.controls.title.errors.minlength">
                        The minimum 3 characters required for thread title. </div>
                      <div
                        *ngIf="threadEditFormGroup.controls.title.errors.validspace && !threadEditFormGroup.controls.title.errors.minlength">
                        Invalid Thread title </div>
                    </div>
                  </div>
                  <div class="form-label-group mb-2">
                    <label>Description *</label>

                    <textarea id="description" formControlName="description" cols="30" rows="3" class="form-control"
                      placeholder="Description"></textarea>

                    <div *ngIf="isThreadUpdateSubmitted && threadEditFormGroup.controls.description.errors"
                      class="text-danger">
                      <div *ngIf="isThreadUpdateSubmitted && threadEditFormGroup.controls.description.errors.required">
                        Description is required. </div>
                      <div
                        *ngIf="!threadEditFormGroup.controls.description.errors.required && threadEditFormGroup.controls.description.errors.minlength">
                        The minimum 3 characters required for description. </div>
                      <div
                        *ngIf="threadEditFormGroup.controls.description.errors.validspace && !threadEditFormGroup.controls.description.errors.minlength">
                        Invalid Description </div>
                    </div>
                  </div>
                  <button class="btn btn btn-dark btn-primary float-right text-uppercase" type="submit">Update</button>
                </ng-container>
              </form>
            </ng-container>

            <ng-container *ngIf="!isThreadEdit">
              <div class="thred_list mb-3">
                <h3>{{threadDetails.title}} </h3>
                <p>{{threadDetails.description}}</p>
              </div>
            </ng-container>
            <div class="col-md-12">
              <ng-container *ngIf="userId == threadDetails.created_by_id">
                <span class="mr-2 edit_comment" *ngIf="!isThreadEdit" (click)="onThreadEdit(threadDetails.id)"> <i
                    class="far fa-edit mr-1"></i> Edit
                </span>
                <span class="" (click)="onThreadDelete(threadDetails.id)"> <i class="far fa-trash-alt mr-1"></i> delete
                </span>
              </ng-container>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>


            <div class="replays_list_cont">
              <div class="clearfix"></div>
              <div class="pt-3 pb-3">
                <h5>{{threadDetails.comments.length}} comments found.</h5>
              </div>
              <div class="clearfix"></div>
              <ng-container *ngFor="let comment of threadCommentLimitedList">
                <div class="thred_replay list-group-item">
                  <ng-container *ngIf="correspondingEditCommentId === comment.id">
                    <form class="form-signup" [formGroup]="threadCommentEditFormGroup"
                      (ngSubmit)="threadCommentEditAction()">
                      <ng-container *ngIf="commentDetails">
                        <div class="form-label-group mb-2">
                          <textarea id="comments" formControlName="comment" cols="30" rows="3"
                            class="form-control"></textarea>

                          <div *ngIf="isCommentUpdateSubmitted && threadCommentEditFormGroup.controls.comment.errors"
                            class="text-danger">
                            <div *ngIf="threadCommentEditFormGroup.controls.comment.errors.required">
                              comment is
                              required
                            </div>
                            <div *ngIf="threadCommentEditFormGroup.controls.comment.errors.validspace">
                              Invalid comment
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-sm btn-outline-secondary p-2 mt-2 float-right text-uppercase"
                          type="submit">Update</button>
                      </ng-container>
                    </form>
                  </ng-container>
                  <ng-container *ngIf="correspondingEditCommentId != comment.id">
                    <p>{{comment.comment}}</p>
                  </ng-container>
                  <div class="chat_ib float-right col-8 py-3 pl-0">
                    <ng-container *ngIf="userId == comment.comment_by_id">
                      <span class="float-right delete_comment" (click)="onThreadCommentDelete(comment.id)"> <i
                          class="far fa-trash-alt mr-1"></i> Delete
                      </span>
                      <span class="float-right mr-2 edit_comment" *ngIf="correspondingEditCommentId != comment.id"
                        (click)="onThreadCommentEdit(comment.id)"><i class="far fa-edit mr-1"></i> Edit
                      </span>
                    </ng-container>
                  </div>
                  <div class="clearfix"></div>
                  <div class="col-md-12 float-left px-0 pt-2">
                    <div class="profile_img rounded-circle">
                      <img src="{{s3FilePath}}{{comment.commment_user_image}}">
                    </div>
                    <div class="chat_ib float-left pl-3">
                      <h5>{{comment.comment_by}}</h5>
                      <!-- <span class="time_date"> {{ comment.comment_time }} |
                        {{ comment.comment_date | date }}</span> -->
                      
                        <span class="time_date"> {{ comment.utc_created_date.date }} </span>

                    </div>
                  </div>

                </div>

              </ng-container>
              <div>
                <span class="moreaboutus py-3 col-md-12 float-left">
                  <a href="JavaScript:Void(0);" class="view_more_button btn btn-outline-secondary btn-sm float-right"
                    (click)="threadCommentViewMore()" *ngIf="threadCommentViewMoreButtonActive">
                    View More
                  </a>
                </span>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>

            <div class="write_replay">
              <h5>Comments</h5>
              <form class="form-signup" [formGroup]="threadCommentAddFormGroup" (ngSubmit)="threadCommentAddAction()">
                <div class="form-label-group mb-2">
                  <textarea id="comments" formControlName="comment" cols="30" rows="3" class="form-control"
                    placeholder="Comments"></textarea>

                  <div *ngIf="isCommentAddSubmitted && threadCommentAddFormGroup.controls.comment.errors"
                    class="text-danger">
                    <div *ngIf="threadCommentAddFormGroup.controls.comment.errors.required">
                      comment is
                      required
                    </div>
                    <div *ngIf="threadCommentAddFormGroup.controls.comment.errors.validspace">
                      Invalid comment
                    </div>
                  </div>
                </div>
                <button class="btn btn btn-dark btn-primary float-right text-uppercase" type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
