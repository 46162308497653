<div class="modal-header">
<h1 mat-dialog-title>{{data.title}}</h1>
<button type="button" class="close" data-dismiss="modal" (click)="onNoClick()" aria-label="Close"> <span
    aria-hidden="true">&times;</span> </button>
  </div>


<div class="clearfix"></div>
<ng-container *ngIf="!data.isActivityResourse">
  <form [formGroup]="weekForm" (ngSubmit)="weekSubmit()">
    <div class="form-group modal-body">
      <label>Week Name</label>
      <input type="text" formControlName="weekName" class="form-control selact_" placeholder="Week Name">
      <div *ngIf="submitted && f.weekName.errors" class="invalid-feedback">
        <div *ngIf="f.weekName.errors.required">Week name is
          required
        </div>
      </div>
    </div>

    <div mat-dialog-actions class="modal-footer">
      <button mat-button type="button" class="btn btn-outline-danger btn-sm" (click)="onNoClick()">Cancel</button>
      <button mat-button type="submit" class="btn btn-outline-success btn-sm" cdkFocusInitial>Ok</button>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="data.isActivityResourse">
  <mat-tab-group (selectedTabChange)="tabChange($event)">

    <mat-tab label="Resourse">
      <div class="m-3">
        <ng-container *ngFor="let resource of resources;">
        <a class="btn btn-app" *ngIf="resource.status_id ===1 " (click)="resourceSelect(resource)"> <div class="pop_up_icon"><img src="{{s3FilePath + resource.icon}}" class=""></div> {{resource.content_type}}</a>
        <!-- <a class="btn btn-app" *ngIf="resource.status_id ===1 && learningType === 1" (click)="resourceSelect(resource)"> <div class="pop_up_icon"><img src="{{s3FilePath + resource.icon}}" class=""></div> {{resource.content_type}}</a> -->

        <!-- <a class="btn btn-app" *ngIf="resource.media_type_id ===2 && resource.status_id ===1" (click)="resourceSelect(resource)"> <i class="fas fa-volume-off"></i> {{resource.content_type}}</a>
        <a class="btn btn-app" *ngIf="resource.media_type_id ===1 && resource.status_id ===1"> <i class="fas fa-file-alt"></i> {{resource.content_type}}</a>  -->
      </ng-container>
      </div>
    </mat-tab>

    <mat-tab label="Activity">
      <div class="m-3">
      <ng-container *ngFor="let activity of activities;">
        <a class="btn btn-app" *ngIf="activity.status_id ===1" (click)="activitySelect(activity)"> <div class="pop_up_icon"><img src="{{s3FilePath + activity.icon}}" class="" aria-hidden="true"></div> {{activity.content_type}} </a>
        <!-- <a class="btn btn-app" *ngIf="activity.content_type =='Assignment' && activity.status_id ===1"> <i class="fa fa-object-group" aria-hidden="true"></i> {{activity.content_type}} </a>
        <a class="btn btn-app" *ngIf="activity.content_type =='Exercise' && activity.status_id ===1"> <i class="fa fa-object-ungroup" aria-hidden="true"></i> {{activity.content_type}}</a>  -->
      </ng-container>
      </div>
    </mat-tab>

  </mat-tab-group>
</ng-container>

