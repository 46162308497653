<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/question']">Questions</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add {{ questionTypeName }} Question</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit {{ questionTypeName }} Question</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">

      <p-dialog header="" [(visible)]="displayModal" [modal]="true" [style]="{width: '30vw'}" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">

        <div class="m-3 ">

          <div class="uploadedImg" *ngIf="imageSrc">
            <img [src]="imageSrc" />
          </div>

          <div *ngIf="audioSrc">
            <audio controls>
              <source src="{{ audioSrc }}" type="audio/mpeg">
            </audio>
          </div>

          <div *ngIf="videoSrc">
            <video width="320" height="240" controls>
              <source src="{{ videoSrc }}">
            </video>
            <!-- <video   width="320" height="240" controls preload="metadata">
              <source src="{{ videoSrc }}"/>
              Video not supported.
          </video> -->
          </div>
        </div>

      </p-dialog>


      <div class="card-header">
        <h1 *ngIf="!isEdit">Add {{ questionTypeName }} Question</h1>
        <h1 *ngIf="isEdit">Edit {{ questionTypeName }} Question</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3">
              <form [formGroup]="questionForm" (ngSubmit)="onSubmit()">
                <div class="col-md-8 float-left">
                  <div class="form-group">
                    <label>Question Title *</label>
                    <input type="text" formControlName="questionTitle" class="form-control selact_"
                      placeholder="Enter Title" [ngClass]="{ 'is-invalid': submitted && f.questionTitle.errors }">
                    <div *ngIf="submitted && f.questionTitle.errors" class="invalid-feedback">
                      <div *ngIf="f.questionTitle.errors.required">
                        Question Title is
                        required
                      </div>
                      <div *ngIf="f.questionTitle.errors.validspace">Invalid Question Title
                      </div>
                    </div>

                  </div>
                  <div class="form-group">
                    <label class="w-100">Question Text * <ng-container *ngIf="questionType == 2 || questionType == 4" >   <button type="button" class="float-right btn btn-primary btn-sm ml-1" (click)="addBlank3()">Add Blank Group 3</button> <button  type="button" class="float-right btn btn-primary btn-sm ml-1" (click)="addBlank2()">Add Blank Group 2</button> <button type="button" class="float-right btn btn-primary btn-sm ml-1" (click)="addBlank1()" >Add Blank Group 1</button></ng-container> </label>
                    <textarea class="form-control selact_" formControlName="questionText"
                      placeholder="Enter Question Text"
                      [ngClass]="{ 'is-invalid': submitted && f.questionText.errors }" [(ngModel)]="questionTxt"></textarea>
                    <div *ngIf="submitted && f.questionText.errors" class="invalid-feedback">
                      <div *ngIf="f.questionText.errors.required">
                        Question Text is
                        required
                      </div>
                      <div *ngIf="f.questionText.errors.validspace">Invalid Question Text
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Answer Explanation</label>
                    <textarea class="form-control selact_" formControlName="answerExplanation"
                      placeholder="Enter Answer Explanation"
                      [ngClass]="{ 'is-invalid': submitted && f.answerExplanation.errors }"></textarea>
                    <!-- <div *ngIf="submitted && f.answerExplanation.errors" class="invalid-feedback">
                      <div *ngIf="f.answerExplanation.errors.required">
                        Answer Explanation is
                        required
                      </div>
                    </div> -->
                  </div>

                  <div class="form-group">
                    <label>Question Category *</label>
                    <select name="questionCategory" formControlName="questionCategory" size="1"
                      class="form-control select2 selact_" style="width: 100%;" placeholder="Select Question Category"
                      [ngClass]="{ 'is-invalid': submitted && f.questionCategory.errors }">
                      <option value=""> --Select Question Category-- </option>
                      <option *ngFor="let item of questionCategoryList" [value]="item.id">{{item.title}}</option>
                    </select>
                    <div *ngIf="submitted && f.questionCategory.errors" class="invalid-feedback">
                      <div *ngIf="f.questionCategory.errors.required">
                        Question Category is
                        required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Difficulty Level *</label>
                    <select name="difficultyLevel" formControlName="difficultyLevel" size="1"
                      class="form-control select2 selact_" style="width: 100%;" placeholder="Select Difficulty Level"
                      [ngClass]="{ 'is-invalid': submitted && f.difficultyLevel.errors }">
                      <option value=""> --Select Difficulty Level-- </option>
                      <option *ngFor="let item of difficultyLevelList" [value]="item.id">{{item.level}}</option>
                    </select>
                    <div *ngIf="submitted && f.difficultyLevel.errors" class="invalid-feedback">
                      <div *ngIf="f.difficultyLevel.errors.required">
                        Difficulty Level is
                        required
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Status *</label>
                    <select class="form-control selact_" formControlName="status" style="width: 100%;"
                      placeholder="Select Status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                      <option value="1">Active</option>
                      <option value="2">Disabled</option>
                    </select>
                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                      <div *ngIf="f.status.errors.required">
                        Status is
                        required
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-md-12 float-left">

                  <!-- media file -->
                  <div class="card mb-4 mt-4">
                    <div class="card-header">
                      <h4 class="mb-0">Add Files <a (click)="addNewQsFile()"
                          class="preposr_series_add float-right btn btn-success btn-sm"> <i class="fa fa-plus"
                            aria-hidden="true"></i> </a></h4>
                    </div>
                    <div class="form-group mb-0">
                      <div class="border-bottom float-left clearfix w-100 py-2"
                        *ngIf="questionForm.get('qsFiles')['controls'].length !=0">
                        <div class="col-md-1 float-left">No</div>
                        <div class="col-md-4 float-left">Type</div>
                        <div class="col-md-7 float-left">File</div>
                      </div>
                      <div formArrayName="qsFiles"
                        *ngFor="let itemrow of questionForm.get('qsFiles')['controls']; let i=index;"
                        class="newform mt-0 ng-dirty ng-invalid ng-star-inserted ng-touched">
                        <ng-container [formGroupName]="i">
                          <!-- <input type="number" [ngModel]="i" formControlName="id" hidden> -->
                          <div class="border-bottom float-left clearfix w-100 py-2">
                            <div class="col-md-1 float-left">{{i + 1}}</div>
                            <div class="col-md-4 float-left">
                              <div class="form-group mb-0"><select class="form-control select2 selact_ "
                                  formControlName="media_type" style="width: 100%;" (change)="onQsMediaTypeChanged( i)">
                                  <option value='1'>Image</option>
                                  <option value='2'>Audio</option>
                                  <option value='3'>Video</option>
                                </select></div>
                            </div>

                            <div class="col-md-4 float-left">
                              <div class="form-group mb-0">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span id="" class="input-group-text">
                                      <i class="fas fa-image"
                                        *ngIf="questionForm.get('qsFiles').at(i).get('media_type').value == '1'"></i>
                                      <i class="fas fa-video"
                                        *ngIf="questionForm.get('qsFiles').at(i).get('media_type').value == '3'"></i>
                                      <i class="fas fa-volume-up"
                                        *ngIf="questionForm.get('qsFiles').at(i).get('media_type').value == '2'"></i>
                                    </span></div>
                                  <div class="custom-file">
                                    <ng-container
                                      *ngIf="questionForm.get('qsFiles').at(i).get('media_type').value == '1'">
                                      <input type="file" class="custom-file-input" id="InputFile{{i}}"
                                        (change)="onQsFileChanged($event, i, 'image')" accept="image/*">
                                    </ng-container>
                                    <ng-container
                                      *ngIf="questionForm.get('qsFiles').at(i).get('media_type').value == '2'">
                                      <input type="file" class="custom-file-input" id="InputFile{{i}}"
                                        (change)="onQsFileChanged($event, i, 'audio')" accept="audio/*">
                                    </ng-container>
                                    <ng-container
                                      *ngIf="questionForm.get('qsFiles').at(i).get('media_type').value == '3'">
                                      <input type="file" class="custom-file-input" id="InputFile{{i}}"
                                        (change)="onQsFileChanged($event, i, 'video')" accept="video/*">
                                    </ng-container>

                                    <label class="custom-file-label field_break_" for="InputFile"
                                      accept="allowedImageTypes">{{questionForm.get('qsFiles').at(i).get('path').value == "" ?'Choose file':questionForm.get('qsFiles').at(i).get('path').value}}</label>
                                  </div>
                                  <a *ngIf="(questionForm.get('qsFiles').at(i).get('path').value != '') &&  (questionForm.get('qsFiles').at(i).get('changeflag').value == false)"
                                    href="{{s3FilePath}}{{questionForm.get('qsFiles').at(i).get('path').value}}"
                                    class="viewfile_list btn btn-primary btn-sm" target="_blank" download> <i
                                      class="far fa-eye"></i></a>
                                </div>
                                <div *ngIf="submitted && questionForm.get('qsFiles').at(i).get('path').invalid ">
                                  <small *ngIf="questionForm.get('qsFiles').at(i).get('path').errors?.required"
                                    class="text-danger">
                                    File is required
                                  </small>
                                </div>
                              </div>

                            </div>
                            <div class="col-md-3 float-left"><button type="button"
                                class=" btn btn-md btn-danger float-right" (click)="fileListdelete(i)"><i
                                  class="far fa-trash-alt"></i></button></div>
                          </div>
                        </ng-container>
                      </div>
                      <!-- <div class="border-bottom float-left clearfix w-100 p-2"><button type="button" id="addrow_1"
                        value="Add Row" class="btn btn-success btn-sm btn-sm float-right" (click)="addNewQsFile()">Add
                        New</button></div> -->

                    </div>
                  </div>

                  <div class="card" *ngIf="questionType == 1">
                    <div class="card-header">
                      <h4 class="mb-0">Add Options</h4>
                    </div>
                    <div class="border-bottom float-left clearfix w-100 py-2">
                      <div class="col-md-12 float-left p-0">
                        <div class="col-md-1 float-left">No</div>
                        <div class="col-md-4 float-left">Type</div>
                        <div class="col-md-4 float-left">Option</div>
                        <div class="col-md-3 float-left">Is Correct</div>
                      </div>
                    </div>

                    <div formArrayName="options"
                      *ngFor="let itemrow of questionForm.get('options')['controls']; let i=index;"
                      class="newform mt-0 ng-dirty ng-invalid ng-star-inserted ng-touched">
                      <ng-container [formGroupName]="i">
                        <div class="border-bottom float-left clearfix w-100 py-2">
                          <div class="col-md-1 float-left">{{i + 1}}</div>
                          <div class="col-md-4 float-left">
                            <div class="form-group mb-0">
                              <select class="form-control select2 selact_ " formControlName="option_media_type"
                                (change)="onOptionTypeChanged(i)" style="width: 100%;">
                                <option value='1'>Image</option>
                                <option value='2'>Audio</option>
                                <option value='3'>Video</option>
                                <option value='4'>Text</option>
                              </select></div>
                          </div>

                          <div class="col-md-4 float-left">
                            <div class="form-group mb-0">
                              <div class="input-group"
                                *ngIf="questionForm.get('options').at(i).get('option_media_type').value == '1'">
                                <div class="input-group-prepend"><span id="" class="input-group-text">
                                    <i class="fas fa-image"></i></span></div>
                                <div class="custom-file">
                                  <input type="file" class="custom-file-input" id="InputFile{{i}}"
                                    (change)="onOptionFleChanged($event, i,'image')" accept="image/*">
                                  <label class="custom-file-label field_break_" for="InputFile">
                                    {{questionForm.get('options').at(i).get('option').value == "" ?'Choose file':questionForm.get('options').at(i).get('option').value}}</label>
                                </div>
                                <a *ngIf="(questionForm.get('options').at(i).get('option').value != '') &&  (questionForm.get('options').at(i).get('changeflag').value == false)"
                                  href="{{s3FilePath}}{{questionForm.get('options').at(i).get('option').value}}"
                                  class="viewfile_list btn btn-primary btn-sm" target="_blank" download> <i
                                    class="far fa-eye"></i></a>
                              </div>
                              <div class="input-group"
                                *ngIf="questionForm.get('options').at(i).get('option_media_type').value == '2'">
                                <div class="input-group-prepend"><span id="" class="input-group-text">
                                    <i class="fas fa-volume-up"></i>
                                  </span>
                                </div>

                                <div class="custom-file">
                                  <input type="file" class="custom-file-input" id="InputFile{{i}}"
                                    (change)="onOptionFleChanged($event, i,'audio')" accept="audio/*">
                                  <label class="custom-file-label field_break_"
                                    for="InputFile">{{questionForm.get('options').at(i).get('option').value == "" ?'Choose file':questionForm.get('options').at(i).get('option').value}}</label>
                                </div>
                                <a *ngIf="(questionForm.get('options').at(i).get('option').value != '') &&  (questionForm.get('options').at(i).get('changeflag').value == false)"
                                  href="{{s3FilePath}}{{questionForm.get('options').at(i).get('option').value}}"
                                  class="viewfile_list btn btn-primary btn-sm" target="_blank" download> <i
                                    class="far fa-eye"></i></a>
                              </div>
                              <div class="input-group"
                                *ngIf="questionForm.get('options').at(i).get('option_media_type').value == '3'">
                                <div class="input-group-prepend"><span id="" class="input-group-text">

                                    <i class="fas fa-video"></i>

                                  </span></div>

                                <div class="custom-file">
                                  <input type="file" class="custom-file-input" id="InputFile{{i}}"
                                    (change)="onOptionFleChanged($event, i,'video')" accept="video/*">
                                  <label class="custom-file-label field_break_"
                                    for="InputFile">{{questionForm.get('options').at(i).get('option').value == "" ?'Choose file':questionForm.get('options').at(i).get('option').value}}</label>
                                </div>
                                <a *ngIf="(questionForm.get('options').at(i).get('option').value != '') &&  (questionForm.get('options').at(i).get('changeflag').value == false)"
                                  href="{{s3FilePath}}{{questionForm.get('options').at(i).get('option').value}}"
                                  class="viewfile_list btn btn-primary btn-sm" target="_blank" download> <i
                                    class="far fa-eye"></i></a>

                              </div>
                              <div class="input-group"
                                *ngIf="questionForm.get('options').at(i).get('option_media_type').value == '4'">
                                <div class="input-group-prepend"><span id="" class="input-group-text">

                                    <i class="fas fa-keyboard"></i>
                                  </span></div>

                                <div class="custom-file">
                                  <input type="text" placeholder="Enter..." class="form-control" autocomplete="off"
                                    formControlName="option">
                                </div>
                              </div>
                              <div class="text-danger"
                                *ngIf="submitted && questionForm.get('options').at(i).get('option').invalid">
                                <small *ngIf="questionForm.get('options').at(i).get('option').errors?.required">Option
                                  is required</small>
                                  <small *ngIf="questionForm.get('options').at(i).get('option').errors?.validspace">Invalid Option
                                    </small></div>
                            </div>

                          </div>
                          <div class="col-md-1 float-left">
                            <div class="form-group mb-0">
                              <div class="input-group">
                                <input type="checkbox" id="checkboxPrimary1{{i}}" formControlName="is_answer_"
                                  (change)="onOptionAnswerChanged($event, i)">
                              </div>
                            </div>

                          </div>
                          <div class="col-md-2 float-left" *ngIf="questionForm.get('options')['controls'].length > 2">
                            <button type="button" class=" btn btn-md btn-danger float-right"
                              (click)="optionListdelete(i)"><i class="far fa-trash-alt"></i></button></div>
                        </div>
                      </ng-container>
                    </div>

                    <div class="border-bottom float-left clearfix w-100 p-2"><button type="button" id="addrow_1"
                        value="Add Row" class="btn btn-success btn-sm btn-sm float-right" (click)="addNewOptions()">Add
                        New</button></div>
                  </div>


                  <!-- <div class="card" *ngIf="questionType == 3">
                    <div class="card-header">
                      <h4 class="mb-0">Add Options</h4>
                    </div>
                    <div class="border-bottom float-left clearfix w-100 py-2">
                      <div class="col-md-9 float-left p-0">
                        <div class="col-md-4 float-left">No</div>
                        <div class="col-md-4 float-left">Type</div>
                        <div class="col-md-4 float-left">Option</div>
                      </div>
                    </div>

                    <div class="border-bottom float-left clearfix w-100 py-2">
                      <div class="col-md-9 float-left p-0">
                        <div class="col-md-4 float-left">Question 1</div>
                        <div class="col-md-4 float-left">
                          <div class="form-group mb-0"><select class="form-control select2 selact_ "
                              style="width: 100%;">
                              <option value="4" selected="selected">Text</option>
                            </select></div>
                        </div>

                        <div class="col-md-4 float-left">
                          <div class="form-group mb-0">
                            <div class="input-group">
                              <div class="input-group-prepend"><span id="" class="input-group-text"><i
                                    class="fas fa-keyboard"></i></span></div>
                              <div class="custom-file"><input type="text" placeholder="Enter..." class="form-control"
                                  autocomplete="off"></div>
                            </div>
                            <div class="error-msg"> Text is required </div>
                          </div>

                        </div>


                        <div class="col-md-4 float-left">Question 1</div>
                        <div class="col-md-4 float-left">
                          <div class="form-group mb-0"><select class="form-control select2 selact_ "
                              style="width: 100%;">
                              <option value="4" selected="selected">Text</option>
                            </select></div>
                        </div>

                        <div class="col-md-4 float-left">
                          <div class="form-group mb-0">
                            <div class="input-group">
                              <div class="input-group-prepend"><span id="" class="input-group-text"><i
                                    class="fas fa-keyboard"></i></span></div>
                              <div class="custom-file"><input type="text" placeholder="Enter..." class="form-control"
                                  autocomplete="off"></div>
                            </div>
                            <div class="error-msg"> Text is required </div>
                          </div>

                        </div>
                      </div>



                      <div class="col-md-3 float-left"><button type="button"
                          class=" btn btn-md btn-danger float-right"><i class="far fa-trash-alt"></i></button></div>

                    </div>

                    <div class="border-bottom float-left clearfix w-100 p-2"><button type="button" id="addrow_1"
                        value="Add Row" class="btn btn-default btn-sm float-right">Add New</button></div>
                  </div> -->



                  <div *ngIf="questionType == 2 || questionType == 4" class="form-group">
                    <h4>Add Options</h4>

                    <table id="myTable" class=" table order-list_1 mb-0">
                      <thead>
                        <tr>
                          <td>No.</td>
                          <!--<td>Type</td>-->
                          <td>Answer</td>
                          <td>Group</td>
                          <td *ngIf="questionType == 2">Is Correct</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of questionModel.options; let j = index">
                          <td><span class="pt-3">{{ j+1 }}</span></td>
                          <!--<td class="col-md-3" style="width:25%;">
    <div class="form-group mb-0">
      <select class="form-control select2 selact_" style="width: 100%;" (change)="onOptionFileOptionsChanged(item.id)" formControlName="optionFileOptions{{item.id}}">
        <option value='1'>Image</option>
        <option value='2'>Audio</option>
        <option value='3'>Video</option>
        <option value='4' selected="selected">Text</option>
      </select>
    </div>
  </td>-->
                          <td class="col-md-4" style="width:60%;">
                            <div class="form-group  mb-0">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="">
                                    <i class="fas" [ngClass]="{
      'fa-image':selectedOptionFileOptions[item.id] === '1',
      'fa-volume-up':selectedOptionFileOptions[item.id] === '2',
      'fa-video':selectedOptionFileOptions[item.id] === '3',
      'fa-keyboard':selectedOptionFileOptions[item.id] === '4'
    }"></i>
                                  </span>
                                </div>

                                <div *ngIf="selectedOptionFileOptions[item.id] != '4'" class="custom-file">
                                  <input type="file" class="custom-file-input"
                                    formControlName="optionFileData{{item.id}}"
                                    accept={{selectedOptionFileType[item.id]}} id="exampleInputFile1"
                                    (change)="onOptionFileChanged($event, item.id)"
                                    [ngClass]="{ 'is-invalid': submitted  }">
                                  <label class="custom-file-label" for="exampleInputFile"
                                    accept="">{{item.option==null?'Choose file':item.option}}</label>

                                </div>
                                <app-field-error-display [displayError]="isFieldValid('optionFileData'+item.id)"
                                  errorMsg="File is required">
                                </app-field-error-display>
                                <div *ngIf="selectedOptionFileOptions[item.id] == '4'" class="custom-file">
                                  <input type="text" class="form-control" formControlName="optionText{{item.id}}"
                                    placeholder="Enter..." maxlength="50"
                                    (change)="onOptionTextChanged($event, item.id)">
                                  <app-field-error-display [displayError]="isFieldValid('optionText'+item.id)"
                                    errorMsg="Text is required">
                                  </app-field-error-display>
                                </div>
                                <div>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td class="col-md-3" style="width:25%;">
                            <div class="form-group mb-0">
                              <select class="form-control select2 selact_" style="width: 100%;"
                                (change)="onOptionGroupOptionsChanged(item.id)"
                                formControlName="optionGroupOptions{{item.id}}">
                                <option value='1' selected="selected">1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <!-- <option value='0' *ngIf="questionType == 2">Wrong</option> -->
                              </select>
                            </div>
                          </td>
                          <td class="col-sm-3" style="width:25%;" *ngIf="questionType == 2">
                            <div class="icheck-primary d-inline float-left col-1">
                              <input type="checkbox" id="checkboxPrimary1{{item.id}}"
                                formControlName="optionIsAnswer{{item.id}}"
                                (change)="onOptionIsAnswerChanged($event, item.id)">
                              <label for="checkboxPrimary1{{item.id}}"> </label>

                            </div>
                          </td>
                          <td class="col-sm-2" *ngIf="(this.questionModel.options.length > 2) && (questionType == 2)"><button matTooltip="Delete"
                              (click)="deleteOptionRow(item.id)" type="button"
                              class="ibtnDel btn btn-md btn-danger float-right"><i
                                class="far fa-trash-alt"></i></button></td>
                          <td class="col-sm-2" *ngIf="(this.questionModel.options.length > 1) && (questionType == 4)"><button matTooltip="Delete"
                              (click)="deleteOptionRow(item.id)" type="button"
                              class="ibtnDel btn btn-md btn-danger float-right"><i
                                class="far fa-trash-alt"></i></button></td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="5" style="text-align: left;"><button type="button"
                              class="btn btn-default btn-sm float-right" id="addrow_1" value="Add Row"
                              (click)="incrementOptionFileCounter(4, 1, 1)">Add New</button></td>
                        </tr>
                        <tr> </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div *ngIf="questionType == 3" class="form-group">
                    <h4>Add Options</h4>

                    <table id="myTable" class=" table order-list_1 mb-0">
                      <thead>
                        <tr>
                          <td>No.</td>
                          <td>Type</td>
                          <td>Option</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let item of questionModel.child_questions; let j = index">
                          <tr>
                            <td class="col-md-3" style="width:20%;"><span class="pt-3">Question {{ j+1 }}</span></td>
                            <td class="col-md-3" style="width:25%;">
                              <div class="form-group mb-0">
                                <select class="form-control select2 selact_" style="width: 100%;"
                                  (change)="onChildQuestionFileOptionsChanged(item.id)"
                                  formControlName="childQuestionFileOptions{{item.id}}">
                                  <!-- <option value='1'>Image</option>
                                    <option value='2'>Audio</option>
                                    <option value='3'>Video</option> -->
                                  <option value='4' selected="selected">Text</option>
                                </select>
                              </div>
                            </td>
                            <td class="col-md-4" style="width:60%;">
                              <div class="form-group  mb-0">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text" id="">
                                      <i class="fas" [ngClass]="{
      'fa-image':selectedChildQuestionFileOptions[item.id] === '1',
      'fa-volume-up':selectedChildQuestionFileOptions[item.id] === '2',
      'fa-video':selectedChildQuestionFileOptions[item.id] === '3',
      'fa-keyboard':selectedChildQuestionFileOptions[item.id] === '4'
    }"></i>
                                    </span>
                                  </div>

                                  <div *ngIf="selectedChildQuestionFileOptions[item.id] != '4'" class="custom-file">
                                    <input type="file" class="custom-file-input"
                                      formControlName="childQuestionFileData{{item.id}}"
                                      accept={{selectedChildQuestionFileType[item.id]}} id="exampleInputFile1"
                                      (change)="onChildQuestionFileChanged($event, item.id)"
                                      [ngClass]="{ 'is-invalid': submitted  }">
                                    <label class="custom-file-label" for="exampleInputFile"
                                      accept="">{{item.question_text==null?'Choose file':item.question_text}}</label>

                                  </div>
                                  <app-field-error-display
                                    [displayError]="isFieldValid('childQuestionFileData'+item.id)"
                                    errorMsg="File is required">
                                  </app-field-error-display>
                                  <div *ngIf="selectedChildQuestionFileOptions[item.id] == '4'" class="custom-file">
                                    <input type="text" class="form-control"
                                      formControlName="childQuestionText{{item.id}}" placeholder="Enter..."
                                      (change)="onChildQuestionTextChanged($event, item.id)" maxlength="60">
                                    <app-field-error-display [displayError]="isFieldValid('childQuestionText'+item.id)"
                                      errorMsg="Text is required">
                                    </app-field-error-display>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="col-sm-2">
                              <a *ngIf="item.file==null && item.question_text!=null && selectedChildQuestionFileOptions[item.id]!=4"
                                [routerLink]="" matTooltip="View"
                                (click)="viewFile(item.question_text,selectedChildQuestionFileOptions[item.id])"
                                class="btn btn-secondary btn-dark float-right add_question">
                                <i class="far fa-eye"></i>
                              </a>
                            </td>
                            <td class="col-sm-2" rowspan="2" style="vertical-align: middle;" *ngIf="this.questionModel.child_questions.length > 2"><button matTooltip="Delete"
                                (click)="deleteChildQuestionRow(item.id)" type="button"
                                class="ibtnDel btn btn-md btn-danger float-right"><i
                                  class="far fa-trash-alt"></i></button></td>
                          </tr>
                          <tr>
                            <td class="col-md-3" style="width:20%;"><span class="pt-3">Answer {{ j+1 }}</span></td>
                            <td class="col-md-3" style="width:25%;">
                              <div class="form-group mb-0">
                                <select class="form-control select2 selact_" style="width: 100%;"
                                  (change)="onChildOptionFileOptionsChanged(item.id)"
                                  formControlName="childOptionFileOptions{{item.id}}">
                                  <!-- <option value='1'>Image</option>
                                    <option value='2'>Audio</option>
                                    <option value='3'>Video</option> -->
                                  <option value='4' selected="selected">Text</option>
                                </select>
                              </div>
                            </td>
                            <td class="col-md-4" style="width:60%;">
                              <div class="form-group  mb-0">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text" id="">
                                      <i class="fas" [ngClass]="{
      'fa-image':selectedChildOptionFileOptions[item.id] === '1',
      'fa-volume-up':selectedChildOptionFileOptions[item.id] === '2',
      'fa-video':selectedChildOptionFileOptions[item.id] === '3',
      'fa-keyboard':selectedChildOptionFileOptions[item.id] === '4'
    }"></i>
                                    </span>
                                  </div>

                                  <div *ngIf="selectedChildOptionFileOptions[item.id] != '4'" class="custom-file">
                                    <input type="file" class="custom-file-input"
                                      formControlName="childOptionFileData{{item.id}}"
                                      accept={{selectedChildOptionFileType[item.id]}} id="exampleInputFile1"
                                      (change)="onChildOptionFileChanged($event, item.id)"
                                      [ngClass]="{ 'is-invalid': submitted  }">
                                    <label class="custom-file-label" for="exampleInputFile"
                                      accept="">{{item.option==null?'Choose file':item.option}}</label>

                                  </div>
                                  <app-field-error-display [displayError]="isFieldValid('childOptionFileData'+item.id)"
                                    errorMsg="File is required">
                                  </app-field-error-display>
                                  <div *ngIf="selectedChildOptionFileOptions[item.id] == '4'" class="custom-file">
                                    <input type="text" class="form-control" formControlName="childOptionText{{item.id}}"
                                      placeholder="Enter..." (change)="onChildOptionTextChanged($event, item.id)"
                                      maxlength="60">
                                    <app-field-error-display [displayError]="isFieldValid('childOptionText'+item.id)"
                                      errorMsg="Text is required">
                                    </app-field-error-display>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="col-sm-2">
                              <a *ngIf="item.file==null && item.option!=null && selectedChildOptionFileOptions[item.id]!=4"
                                [routerLink]="" matTooltip="View"
                                (click)="viewFile(item.option,selectedChildOptionFileOptions[item.id])"
                                class="btn btn-secondary btn-dark float-right add_question">
                                <i class="far fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="5" style="text-align: left;"><button type="button"
                              class="btn btn-default btn-sm float-right" id="addrow_1" value="Add Row"
                              (click)="incrementChildQuestionFileCounter(4)">Add New</button></td>
                        </tr>
                        <tr> </tr>
                      </tfoot>
                    </table>
                  </div>


                </div>
                <!-- </div> -->
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

  </section>
  <!-- /.content -->
</div>
