import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoursesDetailsComponent } from './course/courses-details/courses-details.component';
import { CoursesListComponent } from './course/courses-list/courses-list.component';
import { CourseOverviewComponent } from './course/mycourse/courseoverviews/course-overview/course-overview.component';
import { EligibilityTestComponent } from './course/eligibility-test/eligibility-test.component';
import { HomeComponent } from './home/home.component';
import { InstructorDetailsComponent } from './instructor/instructor-details/instructor-details.component';
import { RegisterationComponent } from './registeration/registeration.component';
import { WebloginComponent } from './weblogin/weblogin.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { MyclassesComponent } from './course/mycourse/myclasses/myclasses.component';
import { MycoursesComponent } from './course/mycourse/mycourses/mycourses.component';
import { OverviewsComponent } from './course/mycourse/courseoverviews/overviews/overviews.component';
import { GradesComponent } from './course/mycourse/courseoverviews/grades/grades.component';
import { ThreadAddComponent } from './course/mycourse/courseoverviews/discussion-forum/thread-add/thread-add.component';
import { DiscussionForumListComponent } from './course/mycourse/courseoverviews/discussion-forum/discussion-forum-list/discussion-forum-list.component';
import { ThreadViewComponent } from './course/mycourse/courseoverviews/discussion-forum/thread-view/thread-view.component';
import { WebForgotpasswordComponent } from './web-forgotpassword/web-forgotpassword.component';
import { WebResetpasswordComponent } from './web-resetpassword/web-resetpassword.component';
import { CoursePriceDetailComponent } from './course/course-price-detail/course-price-detail.component';
import { CoursePaymentStatusComponent } from './course/course-payment-status/course-payment-status.component';
import { CourseInfoComponent } from './course/mycourse/courseoverviews/course-info/course-info.component';
import { MessagesComponent } from './course/mycourse/courseoverviews/messages/messages.component';
import { CertificateComponent } from './course/mycourse/courseoverviews/certificate/certificate.component';
import { TeamDetailsComponent } from './team-details/team-details.component';
import { ReferralPointComponent } from './referral-point/referral-point.component';
import { SupportEnquiryComponent } from './support-enquiry/support-enquiry.component';
import { CoursePaymentStatusFreeChargeComponent } from './course/course-payment-status-free-charge/course-payment-status-free-charge.component';

const routes: Routes = [

  { path: '', component: HomeComponent, data: { title: ' Home ' } },
  { path: 'register', component: RegisterationComponent, data:  { title: ' Register ', breadcrumb: { label: 'Register', info: { myData: { icon: 'home', iconType: 'material' } } } } },
  { path: 'register/refferalLink/:refferalCode', component: RegisterationComponent, data:  { title: ' Register ', breadcrumb: { label: 'Register', info: { myData: { icon: 'home', iconType: 'material' } } } } },

  { path: 'login', component: WebloginComponent, data: { title: ' Login ', breadcrumb: { label: 'Login', info: { myData: { icon: 'home', iconType: 'material',  } } } } },

  { path: 'login/course/:course_slug', component: WebloginComponent, data: { title: ' Login ', breadcrumb: { label: 'Login', info: { myData: { icon: 'home', iconType: 'material',  } } } } },

  {
    path: 'mycourses',
    children: [

      { path: '', component: MycoursesComponent, data: { title: ' My Courses ', breadcrumb: { label: 'My Account', info: { myData: { icon: 'home', iconType: 'material' } } } } },
      {
        path: 'overview', component: CourseOverviewComponent, data: { title: ' Overview ', breadcrumb: { label: 'Eligibility Test', info: { myData: { icon: 'home', iconType: 'material' } } } },
        children: [
          { path: 'detail/:enrollment_id', component: OverviewsComponent, data: { title: ' Overview ', breadcrumb: { label: 'My Account', info: { myData: { icon: 'home', iconType: 'material' } } } } },
          { path: 'grade/:enrollment_id', component: GradesComponent, data: { title: ' My Courses ', breadcrumb: { label: 'My Account', info: { myData: { icon: 'home', iconType: 'material' } } } } },
          { 
            path: 'discuss_forum/:enrollment_id' , component:DiscussionForumListComponent,data: { title: ' Discussion Forum ', breadcrumb: { label: 'Eligibility Test', info: { myData: { icon: 'home', iconType: 'material' } } } },
          },
          { 
            path : "thread_add/:enrollment_id", component: ThreadAddComponent,  data: { title: ' Thread Add ', breadcrumb: { label: 'Thread Add', info: { myData: { icon: 'home', iconType: 'material' } } } } 
          },
          {
            path:'thread_details/:threadid/:enrollment_id', component:ThreadViewComponent , data: { title: ' Thread Add ', breadcrumb: { label: 'Thread Add', info: { myData: { icon: 'home', iconType: 'material' } } } } 
          },
          {
            path:'courseinfo/:enrollment_id', component:CourseInfoComponent , data: { title: ' Course Info ', breadcrumb: { label: 'Course info', info: { myData: { icon: 'home', iconType: 'material' } } } } 
          },
          {
            path:'messages/:enrollment_id', component:MessagesComponent, data: { title: ' Messages ', breadcrumb: { label: 'Messages', info: { myData: { icon: 'home', iconType: 'material' } } } } 
          },
          {
            path:'certificate/:enrollment_id', component:CertificateComponent, data: { title: ' Certificate ', breadcrumb: { label: 'Certificate', info: { myData: { icon: 'home', iconType: 'material' } } } } 
          }
        ],
      },
      { path: 'myClass/:enrollment_id/:enrollment_course_content_id/:video_slug', component: MyclassesComponent, data: { title: 'My Class ', breadcrumb: { label: 'Player Test', info: { myData: { icon: 'home', iconType: 'material' } } } }, }

    ]
  },
  {
    path: 'courses',
    children: [
      { path: '', component: CoursesListComponent, data: { title: ' Courses ', breadcrumb: { skip: true, alias: 'courses' } } },
      { path: 'search/:search_data', component: CoursesListComponent, data: { title: ' Search ', breadcrumb: { label: 'search results', info: { myData: { icon: 'home', iconType: 'material' } } } } },
      { path: 'category/:category_slug', component: CoursesListComponent, data: { title: ' Courses ', breadcrumb: { alias: 'categoryname', info: { myData: { icon: 'home', iconType: 'material' } } } } },
    ]
  },
  {
    path: 'course',
    children: [
      { path: '', component: CoursesListComponent, data: { title: 'Course ', breadcrumb: { alias: 'categoryname', info: { myData: { icon: 'home', iconType: 'material' } } } } },
      { path: ':course_slug', component: CoursesDetailsComponent, data: { title: 'Course Details ', breadcrumb: { label: 'Course Details', info: { myData: { icon: 'home', iconType: 'material' } } } } },
      { path: 'eligibilityTest/:course_id', component: EligibilityTestComponent, data: { title: ' Eligibility Test ', breadcrumb: { label: 'Eligibility Test', info: { myData: { icon: 'home', iconType: 'material' } } } } },
      { path: 'details/:course_id/:batch_id/:course_curency_price_id', component: CoursePriceDetailComponent, data: { title: 'Course Details ', breadcrumb: { label: 'Course Details', info: { myData: { icon: 'home', iconType: 'material' } } } } },
      { path: 'payment/response', component: CoursePaymentStatusComponent, data: { title: 'Course Payment ', breadcrumb: { label: 'Course Payment', info: { myData: { icon: 'home', iconType: 'material' } } } } },
      { path: 'payment/response/freeCharge', component: CoursePaymentStatusFreeChargeComponent, data: { title: 'Course Payment ', breadcrumb: { label: 'Course Payment', info: { myData: { icon: 'home', iconType: 'material' } } } } },
    ]
  },
  { path: 'instructor/:instructor_slug', component: InstructorDetailsComponent, data: { title: ' Instructor ', breadcrumb: { label: 'Instructor Details', info: { myData: { icon: 'home', iconType: 'material' } } } } },
  { path: 'page/:quicklink_slug', component: QuickLinksComponent, },

  { path: 'login/forgotpassword', component: WebForgotpasswordComponent, 
  data: { title: ' Forgot Password ', breadcrumb: { label: 'Forgot Password', info: { myData: { icon: 'home', iconType: 'material' } } } } },

  { path: 'login/resetpassword/:token', component: WebResetpasswordComponent, data: { title: ' Reset Password ', breadcrumb: { label: 'Reset Password', info: { myData: { icon: 'home', iconType: 'material' } } } } },

  { path: 'profile', loadChildren: './profile/profile.module#ProfileModule', data:  { title: ' Update your Profile Details ', breadcrumb: { label: 'Profile', info: { myData: { icon: 'home', iconType: 'material' } } } } },
  { path: 'subscription', loadChildren: './user-reports/user-reports.module#UserReportsModule', data:  { title: ' Subscriptions ', breadcrumb: { label: 'Report', info: { myData: { icon: 'home', iconType: 'material' } } } } },
  { path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsModule', data:  { title: ' Notifications ', breadcrumb: { label: 'Notifications', info: { myData: { icon: 'home', iconType: 'material' } } } } },

  { path: 'team/:team_slug', component: TeamDetailsComponent, data: { title: ' Team ', breadcrumb: { label: 'Team Details', info: { myData: { icon: 'home', iconType: 'material' } } } } },

  { path: 'referralpoints', component: ReferralPointComponent, data: { title: ' Referral Points ', breadcrumb: { label: 'Referral Points', info: { myData: { icon: 'home', iconType: 'material' } } } } },

  { path: 'support', component: SupportEnquiryComponent, data: { title: ' Support Enquiry ', breadcrumb: { label: 'Support Enquiry', info: { myData: { icon: 'home', iconType: 'material' } } } } },


]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebRoutingModule { }
