import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service'
import { Validatespace } from "src/app/validation/spacevalidation";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-currency-add',
  templateUrl: './currency-add.component.html',
  styleUrls: ['./currency-add.component.css']
})
export class CurrencyAddComponent implements OnInit {
  selectedFile: File;
  isEdit: boolean = false;
  imageSrc: any;
  session_id: any;
  currencyId: any;
  countryList: any = [];
  fileName = '';
  s3FilePath: string;

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService, private uploadService: UploadService, private constantService: ConstantsService, private title: Title, private meta: Meta) { }
  currencyForm: FormGroup;
  submitted = false;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    // this.s3FilePath = this.constantService.s3FilePath;

    this.currencyForm = new FormGroup({
      country: new FormControl('',[Validators.required]),
      currency: new FormControl('',[Validators.required,Validators.pattern('[a-zA-Z ]*'), Validatespace]) ,
      status: new FormControl('',[Validators.required]) ,
      default: new FormControl('',[Validators.required]) ,
      // fileData: ['', Validators.required],
      symbol: new FormControl('',[Validators.required, Validatespace]),
      currency_code: new FormControl('',[Validators.required, Validatespace])
    });
    debugger
    this.currencyForm.reset();
    this.currencyForm.get('country').setValue('0');
    this.currencyForm.get('status').setValue('1');
    this.currencyForm.get('default').setValue('0');
    this.init();
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.currencyId = params.dist;
        this.spinner.show();

        var single_url = "edit_currency?currency_id=" + `${params.dist}`;
        var edit_body = { "currency_id": this.currencyId };
        this.api.detailsAddFetch(edit_body, single_url, this.session_id).subscribe((data: any) => {
          debugger
          if (data.status == "true" && data.currency[0]) {
            this.spinner.hide();

            this.currencyForm.get('currency').setValue(data.currency[0].title);
            this.currencyForm.get('symbol').setValue(data.currency[0].currency_label);
            this.currencyForm.get('country').setValue(data.currency[0].country_id);
            this.currencyForm.get('status').setValue(data.currency[0].status_id);
            this.currencyForm.get('default').setValue(data.currency[0].default_currency);
            this.currencyForm.get('currency_code').setValue(data.currency[0].currency_code);
            // this.currencyForm.get('fileData').setValidators(null);
            // this.currencyForm.get('fileData').setValue("");
            // this.imageSrc = data.currency[0].image;
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
          this.title.setTitle("Edit Currency | Admin | Al Jamia");
          this.meta.addTag({
          name: "description",
          content: "Edit Currency| Admin",
        });
      }
      else {
        this.isEdit = false;
        this.spinner.hide();
        this.title.setTitle("Add Currency | Admin | Al Jamia");
          this.meta.addTag({
          name: "description",
          content: "Add Currency| Admin",
        });

      }
    }
    );
  }
  onFileChanged(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      console.log(this.selectedFile);

      this.selectedFile = event.target.files[0];
      console.log(this.selectedFile.name);

      reader.onload = () => {

        this.imageSrc = reader.result as string;


      }
      reader.readAsDataURL(this.selectedFile);
      console.log(this.selectedFile)

    }
  }
  init() {
    this.spinner.show();
debugger
    this.countryList = [];
    var countries_fetch_url = "country?status_id=1";
    this.api.detailsGet(countries_fetch_url).subscribe((data: any) => {
      if (data.status == "true" && data.countries[0]) {
        debugger
        this.countryList = data.countries;
        this.spinner.hide();

      }
      else {
        this.spinner.hide();
        
        // this.popup.failureMessage = data.message
        // this.popup.failurepopup()

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  get f() { return this.currencyForm.controls; }

  onSubmit() {
    this.submitted = true;
    debugger

    if (this.currencyForm.invalid) {
      return;
    }
    if (this.f.country.value == 0) {
      debugger
      return;
      
    }

    // const file = this.selectedFile;

    // if (file != null)
    //   var s3FileName
    // this.uploadService.fileUpload(file, 'currency').then(res => {
      // if (res) {
      //   s3FileName = res
        if (!this.isEdit) {
          this.spinner.show();
          debugger


          var body = { "title": this.f.currency.value, "status_id": +this.f.status.value, "country_id": +this.f.country.value, "default": +this.f.default.value, "currency_label":this.f.symbol.value, "image": "dummy", currency_code: this.f.currency_code.value};
          var url = "add_currency?title=" + `${this.f.currency.value}` + "&status_id=" + `${this.f.status.value}` + "&country_id=" + `${this.f.country.value}` + "&default=" + `${this.f.default.value}` + "&image=" + "dummy";
          this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
            debugger
            console.log(data)
            if (data.status == "TRUE") {
              this.spinner.hide();

              this.imageSrc = "";
              this.currencyForm.reset();
              this.submitted = false;
              this.popup.sucessMessage = data.message
              this.popup.sucesspopup();
              this.router.navigate(['admin/currency'])
            }
            else {
              this.spinner.hide();
              
            this.popup.failureMessage = data.message
            this.popup.failurepopup()

            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();

                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            });
        }
        else {
          if (this.isEdit) {
            this.spinner.show();
            debugger
            const uploadData = new FormData();
           
            let fileName = "dummy";
            // if (this.selectedFile == null) {
            //   fileName = this.imageSrc.replace(this.s3FilePath, '');
            // }

            var edit_body = { "status_id": +this.f.status.value, "title": this.f.currency.value, "country_id": +this.f.country.value, "currency_id": this.currencyId, "default": +this.f.default.value,"currency_label":this.f.symbol.value, "image": fileName, currency_code: this.f.currency_code.value };
            var update_url = "update_currency?currency_id=" + this.currencyId + "&title=" + `${this.f.currency.value}` + "&status_id=" + `${this.f.status.value}` + "&country_id=" + `${this.f.country.value}` + "&default=" + `${this.f.default.value}` + "&image=" + `${fileName}`;
            this.api.detailsAddFetch(edit_body, update_url, this.session_id).subscribe((data: any) => {
              debugger
              console.log(data)
              if (data.status == "TRUE") {
                this.spinner.hide();

                this.currencyForm.reset();
                this.submitted = false;

                this.popup.sucessMessage = data.message
                this.popup.sucesspopup()
                this.router.navigate(['admin/currency'])

              }
              else {
                this.spinner.hide();

                this.popup.failureMessage = data.message
                this.popup.failurepopup()
              }
            },
              (err: HttpErrorResponse) => {
                if (err.status == 403) {
                  this.spinner.hide();

                  localStorage.clear()
                  this.router.navigate(['admin/login'])
                }
              });
          }
        }
      // }
    // });
  }

}
