import { BrowserModule, Title } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { DashboardModule } from "./dashboard/dashboard.module"
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { PopupService } from './services/popup.service';
// import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatMenuModule } from '@angular/material/menu';
import { WebModule } from './web/web.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CarouselModule } from "ngx-owl-carousel-o";
import { CsvModule } from '@ctrl/ngx-csv';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,


    //  ChangepasswordComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatMenuModule,
    WebModule,
    MatDialogModule,
    MatBadgeModule,
    CarouselModule,
    NgMultiSelectDropDownModule.forRoot(),
    CsvModule,
    
  ],
  providers: [HttpClientModule, ApiService, PopupService, Title,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
