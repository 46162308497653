import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from 'src/app/services/constants.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UploadService } from 'src/app/services/upload.service';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { EnumVariableService } from 'src/app/services/enum-variable.service';
import { PopupService } from '../../../../services/popup.service';
import { EMPTY, Subscription } from "rxjs";
import { Validatespace } from "src/app/validation/spacevalidation";
import * as moment from "moment";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-myclasses',
  templateUrl: './myclasses.component.html',
  styleUrls: ['./myclasses.component.css'],
})
export class MyclassesComponent implements OnInit {
  videoItems = [
    {
      name: '',
      src: '',
      type: '',
    },
  ];

  activeIndex = 0;
  currentVideo = this.videoItems[this.activeIndex];
  data;
  progresValue = 0;
  s3CourseVideoFileName: any;
  isProgresBar: boolean = false;
  currentCopyUrlIndex = -1;

  actionDetails: any = [];

  constructor(
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private ToasterService: ToasterService,
    private rote: ActivatedRoute,
    private constantService: ConstantsService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    public enumvariable: EnumVariableService,
    private popup: PopupService,
    private _sanitizer: DomSanitizer
  ) {
    this.resourceForm = this.formBuilder.group({
      title: ['', Validators.required],
      subject: [''],
      chapter: [''],
      concept: [''],
      module: [''],
      videoFile: [''],
      unlockAfter: ['', Validators.required],
      transcript: [''],
      // duration: ['', Validators.required],
      textDescription: [''],
      isFreeOnTrial: [''],
    });
  }

  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'indeterminate';
  myClassDetails: any;
  myCourseOverviewDetails: any;
  notes: any = [];
  enrollmentId: any;
  enrollmentCourseContentId: any;
  videoSlug: string;
  session_id: any;
  S3FILEPATH: string;
  PDFFILE_PATH: string;
  resourceForm: FormGroup;
  selectedFile: any = [];
  pdfSrc: any;
  fileArray: any = [];
  assignmentSubmissions: any = [];
  clickEventsubscription: Subscription;
  userId: any;
  courseContentId: any;
  submittedDate: any;
  safeURL;
  displayModal: boolean = false;

  ngOnInit(): void {
    this.rote.params.subscribe((params) => {
      this.enrollmentId = params['enrollment_id'];
      this.enrollmentCourseContentId = params['enrollment_course_content_id'];
      this.videoSlug = params['video_slug'];

    });
    this.session_id = localStorage.getItem('access_token');
    this.S3FILEPATH = this.constantService.s3FilePath;
    this.PDFFILE_PATH = this.constantService.COURSE_PDF_FILE_PATH;
    this.userId = localStorage["user_Id"];
    if (!this.userId) {
      this.router.navigate(['/login'])
    } else {
      this.enumvariable;
      this.getmyClass();
    }
    this.clickEventsubscription = this.LocaldataService.getAssessmentFunction().subscribe(() => {
      this.reLoad();
    })
  }

  getmyClass() {

    this.spinner.show();
    let param = {
      enrollment_id: this.enrollmentId,
      enrollment_course_content_id: this.enrollmentCourseContentId,
      slug: this.videoSlug,
    };
    let fetch_url = 'get_enrollment_content';
    this.api.detailsUploadFetch(param, fetch_url, this.session_id).subscribe(
      (data: any) => {
        if (data.status == 'true' && data.contents) {

          this.courseContentId = data.contents.course_content_id;
          this.myClassDetails = data.contents;
          debugger
          this.myCourseOverviewDetails = data.related_course_contents.course_overview;

          if (this.myClassDetails.course_content_type_id == this.enumvariable.COURSE_CONTENT_TYPE_ASSESSMENT_5) {
            this.actionDetails = data;
          } else if (this.myClassDetails.course_content_type_id == this.enumvariable.COURSE_CONTENT_TYPE_DOCUMENT_7) {
            this.pdfSrc = this.S3FILEPATH + data.contents.content;
          } else if (this.myClassDetails.course_content_type_id == this.enumvariable.COURSE_CONTENT_TYPE_ASSIGNMENT_4) {
            this.assignmentSubmissions = data.submissions;
            for(let item of this.assignmentSubmissions){
              debugger
              // this.submittedDate = item.submitted_time + item.submitted_date
              this.submittedDate =  moment(item.utc_submitted_date + '+00:00').local().format("DD-MM-YYYY hh:mm A");
            }
          }
           //vimeo

           else if (this.myClassDetails.course_content_type_id == this.enumvariable.COURSE_CONTENT_TYPE_VIMEO_9) {
               /* for iframe data uncomment below code and use iframe in html */
                // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl( data.contents.content);

                /* for loading innerHTML content use below code */
              this.safeURL = this._sanitizer.bypassSecurityTrustHtml( data.contents.content);

            this.displayModal = true;

          }
           else {
            this.notes = data.notes;
            this.videoItems = [
              {
                name: data.contents.content,
                src: this.S3FILEPATH + data.contents.content,
                type: 'video/mp4',
              },
            ];
            this.startPlaylistVdo(this.videoItems[0], 0);
          }

          //  if (this.myClassDetails.course_content_type_id == (this.enumvariable.COURSE_CONTENT_TYPE_VIDEO_1))
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'warning');
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          //localStorage.clear()
          this.router.navigate(['']);
        }
      }
    );
  }

  reLoad() {
    const link = document.createElement("a");
    link.href = "mycourses/myClass/" + this.enrollmentId + '/' + this.enrollmentCourseContentId + '/' + this.videoSlug;
    link.setAttribute("visibility", "hidden");
    link.click();
  }

  videoPlayerInit(data) {
    this.data = data;

    this.data
      .getDefaultMedia()
      .subscriptions.loadedMetadata.subscribe(this.initVdo.bind(this));
    this.data
      .getDefaultMedia()
      .subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }

  nextVideo() {
    this.activeIndex++;

    if (this.activeIndex === this.videoItems.length) {
      this.activeIndex = 0;
    }

    this.currentVideo = this.videoItems[this.activeIndex];
  }

  initVdo() {
    this.data.play();
  }

  startPlaylistVdo(item, index: number) {

    this.activeIndex = index;
    this.currentVideo = item;
  }

  addNote(event) {

    this.data.pause();
    const dialogRef = this.dialog.open(myclassesFeedbackComponent, {
      width: '800px',
      data: {
        title: '',
        course_content_id: 0,
        enrollment_course_content_id: this.enrollmentCourseContentId,
        isNote: true
      },
    });

    dialogRef.afterClosed().subscribe((result) => {

      if (result) {
        this.spinner.show();
        let param = {
          enrollment_content_id: this.enrollmentCourseContentId,
          notes: result,
          time: this.data.time.current
        };
        let fetch_url = 'save_enrollment_content_note';
        this.api.detailsUploadFetch(param, fetch_url, this.session_id).subscribe(
          (data: any) => {
            if (data.status == 'true' && data) {
              this.spinner.hide();
              this.ToasterService.openSnackBar(data.message, '', 'success');
              //this.data.play();
            } else {
              this.spinner.hide();
              this.ToasterService.openSnackBar(data.message, '', 'warning');
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              //localStorage.clear()
              this.router.navigate(['']);
            }
          }
        );
      }
    });

  }

  deleteNote(enrollment_content_note_id) {

    this.spinner.show();

    let param = {
      enrollment_content_note_id: enrollment_content_note_id
    };
    let fetch_url = 'delete_enrollment_content_note';
    this.api.detailsUploadFetch(param, fetch_url, this.session_id).subscribe(
      (data: any) => {
        if (data.status == 'true' && data) {
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'success');
        } else {
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'warning');
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          //localStorage.clear()
          this.router.navigate(['']);
        }
      }
    );
  }

  toStartVideo(course_content) {
    // if (course_content.unlock_status && this.videoSlug != course_content.slug) {
    if (course_content.unlock_status && this.enrollmentCourseContentId != course_content.enrollment_course_content_id) {

      if (this.data) {
        this.data.pause();
      }

      this.router.navigate(['mycourses/myClass/' + this.enrollmentId + '/' + course_content.enrollment_course_content_id + '/' + course_content.slug,]);
      this.enrollmentCourseContentId = course_content.enrollment_course_content_id;
      this.videoSlug = course_content.slug;
      this.getmyClass();

    } else if (this.enrollmentCourseContentId == course_content.enrollment_course_content_id) {

      this.ToasterService.openSnackBar(course_content.course_content_type_name + ' Same as selected', '', 'warning');
    }
    else {
      this.ToasterService.openSnackBar(course_content.course_content_type_name + ' content  will be available after ' + course_content.unlock_date, '', 'warning');
    }
  }

  async onActivity(course_content) {
    if (course_content.unlock_status) {
      this.router.navigate(['mycourses/myClass/' + this.enrollmentId + '/' + course_content.enrollment_course_content_id + '/' + course_content.slug,]);
      this.enrollmentCourseContentId = course_content.enrollment_course_content_id;
      this.videoSlug = course_content.slug;
      if (course_content.course_content_type_id === 5){
        this.reLoad()
      }
      this.getmyClass();
    } else {
      this.ToasterService.openSnackBar(
        course_content.course_content_type_name + ' content  will be available after ' + course_content.unlock_date,
        '',
        'warning'
      );
    }
  }

  async onDocumentActivity(course_content) {
    if (course_content.unlock_status) {
      this.router.navigate(['mycourses/myClass/' + this.enrollmentId + '/' + course_content.enrollment_course_content_id + '/' + course_content.slug,]);
      this.enrollmentCourseContentId = course_content.enrollment_course_content_id;
      this.videoSlug = course_content.slug;
      this.getmyClass();

      // await this.getAssessment(course_content.course_content_id, course_content.slug);
      this.myClassDetails = course_content;

    } else {
      this.ToasterService.openSnackBar(
        course_content.course_content_type_name + ' content is locked',
        '',
        'warning'
      );
    }
  }

  markAsCompleted() {

    const dialogRef = this.dialog.open(myclassesFeedbackComponent, {
      width: '800px',
      data: {
        title: '',
        course_content_id: this.courseContentId,
        enrollment_course_content_id: this.enrollmentCourseContentId,
        isNote: false
      },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  onVideoFileChanged(event) {
    event.target.files

    if (event.target.files && event.target.files.length) {
      var file = event.target.files[0];
      // var mimeType = file.type;
      // var extn = mimeType.split("/");
     var extn_ =  file.name.split('.').pop();
     let d = this.myClassDetails.submission_format.includes(extn_)
     if (d){
      this.isProgresBar = true;
      this.mode = 'indeterminate';
      this.progresValue = 0;
      this.uploadFile(event, this.PDFFILE_PATH);
     } else {
      this.ToasterService.openSnackBar("Invalid File Format", '', 'warning');
       return
     }

    }
  }

  uploadFile(event, folderPath) {
    const reader = new FileReader();
    this.selectedFile.push(event.target.files[0]);
    reader.onload = () => {
      // this.imageSrc = reader.result as string;
    };
    reader.readAsDataURL(this.selectedFile[this.selectedFile.length - 1]);
    // if (this.s3CourseVideoFileName) {
    //   var s3CoursVideoFileNamedelete = this.uploadService.fileDelete(
    //     this.s3CourseVideoFileName,
    //     folderPath
    //   );
    // }
    if (this.selectedFile[this.selectedFile.length - 1] != null) {
      this.s3CourseVideoFileName = null;
      this.uploadService
        .fileUpload(
          this.selectedFile[this.selectedFile.length - 1],
          folderPath
        )
        .then((res) => {

          if (res) {
            this.s3CourseVideoFileName = res;
            this.fileArray.push({
              "filename": this.s3CourseVideoFileName
            })
            this.mode = 'determinate';
            this.progresValue = 100;
          }
        });
    }
  }

  onSubmit_() {
    this.spinner.show();

    var fetch_url = 'activity_submission';
    let param = { files: this.fileArray, enrollment_course_content_id: this.myClassDetails.enrollment_course_content_id, content_type_id: 4 };
    this.api
      .detailsAddFetch(param, fetch_url, this.session_id)
      .subscribe(
        (data: any) => {

          if (data.status == 'true') {
            this.fileArray = [];
            this.selectedFile = [];
            this.ToasterService.openSnackBar(data.message, '', 'success');
            this.getmyClass();
            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.popup.failureMessage = err.message
          this.popup.failurepopup()
          if (err.status == 403) {
            this.spinner.hide();
            localStorage.clear();
            this.router.navigate(['']);
          }
        }
      );
  }

  onFileDelete(index) {

    var s3CoursVideoFileNamedelete = this.uploadService.fileDelete(
      this.fileArray[index],
      this.PDFFILE_PATH
    );

    this.selectedFile.splice(index, 1);
    this.fileArray.splice(index, 1);
  }

  onCopyUrl(link, index) {
    if (this.currentCopyUrlIndex != -1) {
      let oldDom = document.getElementById("" + this.currentCopyUrlIndex);
      if (oldDom) {
        oldDom.innerHTML = "Copy Url";
      }
    }
    let newDom = document.getElementById(index);
    if (newDom) {
      newDom.innerHTML = "Copied";
    }
    this.currentCopyUrlIndex = index;
    var dummy = document.createElement("input"),
      text =  link;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

}

@Component({
  selector: 'app-myclasses-feedback',
  templateUrl: 'myclasses.feedback.component.html',
})
export class myclassesFeedbackComponent {
  mycourcefeedbackForm: FormGroup;

  submitted: boolean = false;
  session_id: any;
  ratingVal: any = 0;

  constructor(
    public dialogRef: MatDialogRef<myclassesFeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDatamyclassesFeedback,
    private formBuilder: FormBuilder,
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private ToasterService: ToasterService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.mycourcefeedbackForm = this.formBuilder.group({
      // rating: ['', Validators.required],
      comment: ['',[Validatespace]],
    });
    this.session_id = localStorage.getItem('access_token');
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  get f() {
    return this.mycourcefeedbackForm.controls;
  }

  updateFeedBack() {
    debugger

    this.submitted = true;
    if (this.mycourcefeedbackForm.invalid) {
      return;
    }
    // if (this.ratingVal == 0) {
    //   return;
    // }
    if(this.ratingVal == 0 && this.mycourcefeedbackForm.value.comment =="" ){
      return;

    }

    this.spinner.show();
    debugger

    let param = {
      // rating: this.mycourcefeedbackForm.value.rating,
      rating: this.ratingVal,
      comments: this.mycourcefeedbackForm.value.comment,
      course_content_id: this.data.course_content_id,
      enrollment_course_content_id: this.data.enrollment_course_content_id,
    };
    let fetch_url = 'content_feedback';
    this.api.detailsUploadFetch(param, fetch_url, this.session_id).subscribe(
      (data: any) => {

        if (data.status == 'true' && data) {
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'success');
          window.location.reload();
          this.dialogRef.close();

        } else {
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'warning');
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          //localStorage.clear()
          this.router.navigate(['']);
        }
      }
    );
  }

  updateNotes() {

    this.submitted = true;
    if (this.mycourcefeedbackForm.invalid) {
      return;
    }
    this.dialogRef.close(this.mycourcefeedbackForm.value.comment);
  }


  closepopup(): void {
    this.dialogRef.close();
  }
  ratingValue(val) {
    this.ratingVal = val;

    this.cdr.detectChanges();

  }
  skipFeedback(){

    this.spinner.show();
    let url="content_feedback";
    let param = { course_content_id: this.data.course_content_id, enrollment_course_content_id: this.data.enrollment_course_content_id }
    this.api.detailsUploadFetch(param, url, this.session_id).subscribe((data: any) =>{
      if(data.status == "true" && data.message){

        this.spinner.hide();
        this.ToasterService.openSnackBar(data.message, '', 'success');
          window.location.reload();
          this.dialogRef.close();

        } else {
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'warning');
        }

    },
    (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        //localStorage.clear()
        this.router.navigate(['']);
      }
    })

  }
}




export interface DialogDatamyclassesFeedback {
  title: string;
  course_content_id: number;
  enrollment_course_content_id: number;
  isNote: boolean;
}
