import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnumVariableService {
  constructor() {}

  readonly COURSE_CONTENT_TYPE_VIDEO_1 = 1;
  readonly COURSE_CONTENT_TYPE_AUDIO_2 = 2;
  readonly COURSE_CONTENT_TYPE_READING_3 = 3;
  readonly COURSE_CONTENT_TYPE_ASSIGNMENT_4 = 4;
  readonly COURSE_CONTENT_TYPE_ASSESSMENT_5 = 5;
  readonly COURSE_CONTENT_TYPE_EXERCISE_6 = 6;
  readonly COURSE_CONTENT_TYPE_DOCUMENT_7 = 7;
  readonly COURSE_CONTENT_TYPE_LIVE_CLASS_8 = 8;
  readonly COURSE_CONTENT_TYPE_VIMEO_9 = 9;
}
