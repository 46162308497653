<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li *ngIf="courseId" class="breadcrumb-item"><a routerLink="/admin/course/editcourse/{{courseId}}">Course Detail</a></li>
            <!-- <li class="breadcrumb-item"><a (click)="activityShow()">Resource</a></li> -->
            <li class="breadcrumb-item active">{{activityHeadding}}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>{{activityHeadding}}</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3">
              <div class="p-3 col-12 border-bottom" *ngIf=" isEdit == true && courseContentTypeId == 5">
                <a routerLink="/admin/course/activity/{{courseContentId}}/questions"
                  class="btn btn-secondary btn-dark float-left" (click)="onEditQuestion()">Edit Questions</a>
              </div>
              
                <div class="col-md-6 float-left">
                  <form [formGroup]="activityForm" (ngSubmit)="onSubmit()" role="form" class="p-3 width_ add_course_">
                  <div class="form-group">
                    <label>Title *</label>
                    <input type="text" class="form-control" placeholder="Title" formControlName="title">
                    <div *ngIf="submitted && f.title.errors" class="text-danger">
                      <small *ngIf="f.title.errors.required">Title is
                        required
                      </small>
                      <small *ngIf="f.title.errors.validspace">Invalid Title
                      </small>
                    </div>
                  </div>
                  <div class="form-group float-left">
                    <label>Subject *</label>
                    <select class="form-control" style="width: 100%;" formControlName="subject"
                      (change)="subjectChange()">
                      <option value="">--Select Subject--</option>
                      <option [ngValue]="option.id" *ngFor="let option of subjects">{{option.title}}</option>
                    </select>
                    <div *ngIf="submitted && f.subject.errors" class="text-danger">
                      <small *ngIf="f.subject.errors.required">Subject is
                        required
                      </small>
                    </div>
                  </div>
                  <div class="form-group float-left" *ngIf="isChapter">
                    <label>Chapter</label>
                    <select class="form-control" style="width: 100%;" formControlName="chapter"
                      (change)="chapterChange()">
                      <option value="">--Select Chapter--</option>
                      <option [ngValue]="option.id" *ngFor="let option of chapter">{{option.title}}</option>
                    </select>
                  </div>
                  <div class="form-group float-left" *ngIf="isConcept">
                    <label>Concept</label>
                    <select class="form-control" style="width: 100%;" formControlName="concept"
                      (change)="conceptChange()">
                      <option value="">--Select Concept--</option>
                      <option [ngValue]="option.id" *ngFor="let option of concept">{{option.title}}</option>
                    </select>
                  </div>
                  <div class="form-group float-left" *ngIf="isModule">
                    <label>Module</label>
                    <select class="form-control" style="width: 100%;" formControlName="module"
                      (change)="moduleChange()">
                      <option value="">--Select Module--</option>
                      <option [ngValue]="option.id" *ngFor="let option of module">{{option.title}}</option>
                    </select>
                  </div>
                  <div class="form-group" *ngIf="courseContentTypeId == 4 || courseContentTypeId == 6">
                    <label>Content *</label>
                    <ckeditor formControlName="textContent" [config]="ckeConfig"></ckeditor>
                    <div *ngIf="submitted && f.textContent.errors" class="text-danger">
                      <small *ngIf="f.textContent.errors.required">Content is
                        required
                      </small>
                    </div>
                  </div>
                  <div class="form-group float-left w-100">
                    <label>Day *</label>
                    <!-- <input type="text" class="form-control float-left" placeholder=" number of days "
                      formControlName="unlockAfter" style="width:90%;">
                    <span class="p-2 float-left">Days</span> -->
                    <select class="form-control" formControlName="unlockAfter" [attr.disabled]="courseCountentDetails && courseCountentDetails.active_enrollment_exist == true ? true : null">
                      <option value="">-- Select Day --</option>
                      <option *ngFor="let day of dayWeekLists" value="{{day.value}}">
                        {{day.value}}
                      </option>
                    </select>
                    <div *ngIf="submitted && f.unlockAfter.errors" class="text-danger">
                      <small *ngIf="f.unlockAfter.errors.required">Day is
                        required
                      </small>
                    </div>
                  </div>
                  <!--  <div class="form-group">
                      <label>Transcript </label>
                      <textarea class="form-control" rows="3" placeholder="Enter ..." autocomplete="off"></textarea>
                    </div> -->
                  <ng-container *ngIf="courseContentTypeId == 4">
                    <!--  <div class="form-group">
                      <label>Duration</label>
                      <input type="text" class="form-control" placeholder="in days " formControlName="duration">
                    </div> -->

                    <div class="form-group">
                      <label>Maximum grade *</label>
                      <input type="number" min="0" class="form-control" placeholder=" " formControlName="maximumGrade">
                      <div *ngIf="submitted && activityForm.get('maximumGrade').invalid "> <small
                        *ngIf="hasError('maximumGrade','required')" class="text-danger"> Maximum grade is
                        required </small>  
                        <small *ngIf="f.maximumGrade.errors.pattern" class="text-danger">Invalid Maximum grade
                        </small>
                       </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="courseContentTypeId == 5">
                    <div class="form-group">
                      <label>Grade Per Question *</label>
                      <input type="number" min="0" class="form-control" placeholder=" " formControlName="gradePerQuestion">
                      <div *ngIf="submitted && activityForm.get('gradePerQuestion').invalid "> 
                        <small class="text-danger" *ngIf="f.gradePerQuestion.errors.required"> Grade Per Question is required </small>  
                        <small *ngIf="f.gradePerQuestion.errors.pattern" class="text-danger">The Grade Per Question may not be 0 and greater than 15
                        </small>
                       </div>
                    </div>

                    <div class="form-group">
                      <label>Number of Questions *</label>
                      <input type="number" min="1" class="form-control" placeholder=" " formControlName="numberofQuestions">
                      <div *ngIf="submitted && activityForm.get('numberofQuestions').invalid "> <small
                        *ngIf="hasError('numberofQuestions','required')" class="text-danger"> Number of Questions is
                        required </small>
                        <small *ngIf="f.numberofQuestions.errors.pattern" class="text-danger">Invalid Number of Questions
                        </small>
                       </div>
                    </div>
                    <div class="form-group">
                      <label>Time Limit (In minutes) *</label>
                      <input type="number" min="0" class="form-control" placeholder=" " formControlName="timeLimit">
                      <div *ngIf="submitted && activityForm.get('timeLimit').invalid "> <small
                        *ngIf="hasError('timeLimit','required')" class="text-danger"> Time Limit is
                        required </small> 
                        <small *ngIf="f.timeLimit.errors.pattern" class="text-danger">Invalid Time Limit
                        </small> 
                       </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="courseContentTypeId != 6">
                    <div class="form-group">
                      <label>Minimum Grade Percentage *</label>
                      <input type="number" min="0" class="form-control" placeholder=" " formControlName="minimumGradePercentage">
                      <div *ngIf="submitted && activityForm.get('minimumGradePercentage').invalid "> <small
                        *ngIf="hasError('minimumGradePercentage','required')" class="text-danger"> Minimum Grade Percentage is
                        required</small>  
                        <small *ngIf="f.minimumGradePercentage.errors.pattern" class="text-danger">Invalid Minimum Grade Percentage
                        </small>
                       </div>
                    </div>
                    <div class="form-group">
                      <label>Success Feedback *</label>
                      <!-- <ckeditor formControlName="textSuccessFeedback" [config]="ckeConfig"></ckeditor> -->
                      <textarea type="text" class="form-control selact_" formControlName="textSuccessFeedback"
                      placeholder="Success Feedback"></textarea>
                      <div *ngIf="submitted && activityForm.get('textSuccessFeedback').invalid "> <small
                        *ngIf="hasError('textSuccessFeedback','required')" class="text-danger"> Success Feedback is
                        required </small> 
                        <small *ngIf="f.textSuccessFeedback.errors.validspace" class="text-danger">Invalid Success Feedback
                        </small> 
                       </div>
                    </div>

                    <div class="form-group">
                      <label>Failure Feedback *</label>
                      <!-- <ckeditor formControlName="textFailureFeedback" [config]="ckeConfig"></ckeditor> -->
                      <textarea type="text" class="form-control selact_" formControlName="textFailureFeedback"
                      placeholder="Failure Feedback"></textarea>
                      <div *ngIf="submitted && activityForm.get('textFailureFeedback').invalid "> <small
                        *ngIf="hasError('textFailureFeedback','required')" class="text-danger"> Failure Feedback is
                        required </small>  
                        <small *ngIf="f.textFailureFeedback.errors.validspace" class="text-danger">Invalid Failure Feedback 
                        </small>
                       </div>
                    </div>
                  </ng-container>

                  <!-- <ng-container *ngIf="courseContentTypeId == 4">
                    <div class="form-group">
                      <label>Submission Format *</label>
                       <select class="form-control select2 selact_" style="width: 100%;"
                        formControlName="submissionFormat">
                      
                        <option *ngFor="let item of submissionFormats" [value]="item.id">{{item.submission_format}}</option>
                      </select>
                    </div> -->


                  <!-- <div class="custom-control custom-checkbox  mr-3" >

                      <label *ngFor="let order of submissionFormats; let i = index">
                        {{order.check}}
                        <input type="checkbox" (change)="onChangeCategory($event, order)" [formControlName]="i" >
                        {{order.submission_format}}
                      </label>
                    </div> -->

                  <!-- <div class="form-check" *ngFor="let cat of submissionFormats">
                          {{cat.check}}
                       <input class="form-check-input" [(ngModel)]="cat.check" name="{{ cat.submission_format }}" type="checkbox" >
                     
                  </div>-->

                  <ng-container *ngIf="courseContentTypeId == 4">
                    <div class="form-group">
                      <label>Maximum files for submission *</label>
                      <input type="number" min="1" class="form-control" placeholder=" "
                        formControlName="maximumFilesForSubmission">
                        <div *ngIf="submitted && activityForm.get('maximumFilesForSubmission').invalid "> <small
                          *ngIf="hasError('maximumFilesForSubmission','required')" class="text-danger"> Maximum files for submission is
                          required </small>  
                          <small *ngIf="f.maximumFilesForSubmission.errors.pattern" class="text-danger">Invalid Maximum files for submission
                          </small>
                         </div>
                    </div>
                  </ng-container>
                </form>

                  <ng-container *ngIf="courseContentTypeId == 4">
                    <div class="form-group">
                      <label>Submission Format *</label>
                      <!-- <select class="form-control select2 selact_" style="width: 100%;"
                    formControlName="submissionFormat">
                  
                    <option *ngFor="let item of submissionFormats" [value]="item.id">{{item.submission_format}}</option>
                    </select> -->
                    </div>

                    <div class="form-check" *ngFor="let cat of submissionFormats">
                      <input class="form-check-input"  (change)="onChangesubmissionFormat()" [(ngModel)]="cat.check" name="{{ cat.submission_format }}"
                        type="checkbox">{{cat.submission_format}}

                    </div>
                    <div *ngIf="submitted && activityForm.get('submissionFormat').invalid "> <small
                      *ngIf="hasError('submissionFormat','required')" class="text-danger"> Submission Format is
                      required </small>  
                     </div>


                    <!-- <div class="custom-control custom-checkbox  mr-3" >

                    <label *ngFor="let order of submissionFormats; let i = index">
                    {{order.check}}
                    <input type="checkbox" (change)="onChangeCategory($event, order)" [formControlName]="i" >
                    {{order.submission_format}}
                   </label>
                    </div> -->

                    <!-- <div class="form-check" *ngFor="let cat of submissionFormats">
                      {{cat.check}}
                   <input class="form-check-input" [(ngModel)]="cat.check" name="{{ cat.submission_format }}" type="checkbox" >
                 
                   </div> -->


                   
                  </ng-container>
                </div>
              
                <div class="clearfix">  
                </div>
                  <div class="p-3">
                    <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right" (click)="onSubmit()">Save</button>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->

  </section>
  <!-- /.content -->
</div>