<section class="bread_sec shadow-sm">
    <!-- <div class="container">
      <div class="row">
        <div class="col-md-12">
            <h1> {{quicklinkTitle}} </h1>
        </div>
      </div>
    </div> -->
  </section>
  <section class="courses_pages instructor_page">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <hr class="py-2">
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-4">
          <h1>{{quicklinkTitle}}</h1>
        </div>
        <div class="col-md-12 mb-4">
            <div [innerHTML]="quicklinkDiscription"></div>
          </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="py-2">
        </div>
      </div>
    
    </div>
  </section>
  
