import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CurrencyComponent } from './currency.component';
import { CurrencyRoutingModule } from './currency-routing.module';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [CurrencyComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    CurrencyRoutingModule,NgxPaginationModule,NgxDatatableModule
   ]
})
export class CurrencyModule { }
