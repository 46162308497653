import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionSubComponent } from './question-sub.component';


const routes: Routes = [

  {
    path: '', component: QuestionSubComponent
},


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuestionSubRoutingModule { }
