<body class="hold-transition login-page">
    <div class="login-box">
        <!-- /.login-logo -->
        <div class="card border_login">
            <div class="card-body login-card-body">
                <div class="login-logo"> <a href="#"><img src="../../assets/img/logo_login.png"></a> </div>
                <p class="login-box-msg">Forgot your password ?</p>
                <form [formGroup]="forgotPwdForm" (ngSubmit)="onSubmit()">
                    <div class="input-group mb-3">
                        <div class="input-group-append">
                            <div class="input-group-text"> <span class="far fa-user"></span> </div>
                        </div>
                        <input type="email" formControlName="email_id" class="form-control" placeholder="Email">
                    </div>
                    <div class="row">
                        <div class="col-12">
                        <div *ngIf="isSubmitted && g.email_id.errors" class="error">
                            <div *ngIf="isSubmitted && g.email_id.errors.required" class="error"> Email required. 
                            </div>
                            <div *ngIf="isSubmitted && g.email_id.errors.email" class="error">Invalid Email</div>

                        </div>
                                <p *ngIf="isMessage" class="notification_message">{{message}} </p>
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-secondary btn-login">Continue <span
                                    class="fas fa-angle-right float-right mt-1 mr-3"></span></button>
                                    <span class="back-login"><a [routerLink]="['/admin/login']" >Back to Login</a></span>
                                     
                        </div>
                        <!-- /.col -->
                    </div>
                </form>
                <!-- /.login-card-body -->
            </div>
        </div>
        <!-- /.login-box -->
    </div>

</body>