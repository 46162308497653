import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from './api.service';
import { Router, NavigationEnd, Route } from '@angular/router';
import { PopupService } from './popup.service';
import { NgxSpinnerService } from "ngx-spinner";
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogin: boolean;
  sessionid: string;
  uid: any;
  username: any;
  constructor(private api: ApiService,private router: Router,private popup: PopupService,private spinner: NgxSpinnerService) {

   
   }
   logout() {
    this.spinner.show()
    this.sessionid = localStorage.getItem('JWT_TOKEN')
    this.uid = +localStorage.getItem('user_id')
    this.api.logout(this.sessionid, this.uid).subscribe((data: any) => {
      this.spinner.hide()
      localStorage.clear()
      this.isLogin = false
      this.router.navigate(['admin/login'])
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide()
        localStorage.clear()
        this.isLogin = false
        this.router.navigate(['admin/login'])
      });

    }
}
