// import { Component, OnInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toaster.service';
import { MatDialog } from '@angular/material/dialog';
import { ConstantsService } from '../../../../../services/constants.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {

  enrollmentId: any;
  session_id: any;
  s3FilePath: string;
  crtDetials: any;

  constructor(
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private rote: ActivatedRoute,
    private ToasterService: ToasterService,
    public dialog: MatDialog,
    private constantService: ConstantsService
  ) { }

  // getCertificate?enrollment_id=38
  ngOnInit(): void {
    this.rote.params.subscribe((params) => {
      this.enrollmentId = params['enrollment_id'];
    });
    this.session_id = localStorage.getItem('access_token');
    this.s3FilePath = this.constantService.s3FilePath;
    this.getCertificate();
  }

  getCertificate() {
    this.spinner.show();
    var list_fetch_url = "getCertificate?enrollment_id=" + this.enrollmentId;
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        this.crtDetials = data.certificate_details;
        debugger
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        // this.popup.failureMessage = data.message
        // this.popup.failurepopup();
        this.router.navigate(['/course'])
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate([''])
      }
    });
  }

  generateCertificate() {
    this.spinner.show();
    let param = {
      enrollment_id: this.enrollmentId
    };
    let fetch_url = 'certificate_generate';
    this.api
      .detailsUploadFetch(param, fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true') {
            this.getCertificate();
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'warning');
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }

}
