import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Title, Meta } from "@angular/platform-browser";
import { Validatespace } from "src/app/validation/spacevalidation";


declare var $: any;
@Component({
  selector: 'app-stream-add',
  templateUrl: './stream-add.component.html',
  styleUrls: ['./stream-add.component.css']
})
export class StreamAddComponent implements OnInit {
  session_id: string;
  streamList: any = [];
  isEdit = false;
  streamId: any;
  qualificationList:any=[];
  constructor(public api: ApiService, private router: Router, private formBuilder: FormBuilder, private popup: PopupService,private route: ActivatedRoute,private spinner: NgxSpinnerService, private title: Title, private meta: Meta) { }
  streamForm: FormGroup;
  submitted = false;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.streamForm = this.formBuilder.group({
      qualification: ['', Validators.required],
      stream: ['', [Validators.required, Validatespace]],
      status: ['', Validators.required],
      // description: ['', Validators.required],

    });
    
    this.streamForm.reset();
    this.streamForm.get('qualification').setValue('0');
    this.streamForm.get('status').setValue('1');
    this.init();

    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;

        this.streamId = +params.dist;
        this.spinner.show();
         var single_url = "stream_edit?stream_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          debugger
          console.log(data)
          if (data.status == "true" && data.stream[0]) {
            this.spinner.hide();

            this.isEdit = true;
            // $("html, body").animate({ scrollTop: 0 });
            this.streamForm.get('qualification').setValue(data.stream[0].qualification_id);
            this.streamForm.get('status').setValue(data.stream[0].status_id);
            this.streamForm.get('stream').setValue(data.stream[0].title);

            // this.streamForm.get('description').setValue(data.stream[0].description);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
    
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
          this.title.setTitle("Edit Stream | Admin | Al Jamia");
          this.meta.addTag({
          name: "description",
          content: "Edit Stream | Admin",
        });
    
      }
      else {
        // this.spinner.hide();

        this.isEdit = false;
        this.title.setTitle("Add Stream | Admin | Al Jamia");
          this.meta.addTag({
          name: "description",
          content: "Add Stream | Admin",
        });
      }
    }
    );
  }
  init() {
    this.qualificationList = [];
    var qualification_fetch_url = "qualification?status_id=1";
    this.spinner.show();
    this.api.detailsGet(qualification_fetch_url).subscribe((data: any) => {
      if (data.status == "true" && data.qualifications[0]) {
        this.spinner.hide();

        this.qualificationList = data.qualifications;
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  get f() { return this.streamForm.controls; }
  onSubmit() {
     this.submitted = true;
    console.log(this.submitted)
    if (this.streamForm.invalid) {
      return;
    }
    if (this.f.qualification.value == 0 ) {
      debugger
      return;
      
    }
    if (!this.isEdit) {
      console.log("FFf")
      this.spinner.show();

      var body = { "qualification_id": +this.f.qualification.value, "title": this.f.stream.value, "status_id": +this.f.status.value};
      var url = "add_stream?qualification_id=" + `${this.f.qualification.value}` + "&title=" + `${this.f.stream.value}` + "&status_id=" + `${this.f.status.value}`;
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "True") {
          this.spinner.hide();

          this.streamForm.reset();


          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup()
          this.router.navigate(['admin/stream']);

        }
        else {
          this.spinner.hide();

          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else{
      if(this.isEdit){
        this.spinner.show();
        debugger

        var edit_body ={ "status_id": +this.f.status.value, "title": this.f.stream.value,"stream_id": this.streamId,"qualification_id": +this.f.qualification.value };
        var update_url = "update_stream?stream_id=" + `${this.f.status.value}` + "&title=" + `${this.f.qualification.value}`  + "&status_id=" + `${this.f.status.value}`;
        this.api.detailsAddFetch(edit_body, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "True") {
            this.spinner.hide();

            this.streamForm.reset();
            this.submitted = false;

             this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/stream']);

          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }

    }
  }
}
