<section class="home_banner">
  <!-- <div id="demo" class="carousel slide" data-ride="carousel">
    <ul class="carousel-indicators">
      <ng-container *ngFor="let banner of banners; let i = index">
        <li data-target="#demo" data-slide-to="i" class="{{i == 0 ? 'active' : ''}}"></li>
      </ng-container>
    </ul>
    <div class="carousel-inner">
      <ng-container *ngFor="let banner of banners; let i = index">
        <div class="carousel-item {{i == 0 ? 'active' : ''}}">
           <img src="{{s3FilePath}}{{banner.image}}"
          alt="al_jamia" width="1100" height="500">
         
          <div class="carousel-caption d-none d-sm-block text-left mb-5  text-black-50 bg_banner">
           
              <div [innerHTML]="banner.description"></div>
            <a href="#" class="btn btn-dark btn-lg text-uppercase">Learn More</a> <a href="#"
              class="btn btn-white btn-lg text-uppercase">Get in Touch</a>
          </div>
        </div>
      </ng-container>
    </div>
  </div> -->
  <!-- <div class="carousel slide">
    <div class="carousel-inner"> -->
  <owl-carousel-o [options]="customOptions">

    <ng-container *ngFor="let banner of banners; let i = index">
      <ng-template carouselSlide>
        <img src="{{s3FilePath}}{{banner.image}}" alt="al_jamia" width="1100" height="500">
        <!-- <div class="carousel-caption d-none d-sm-block text-left mb-5  text-black-50 bg_banner">
          <div [innerHTML]="banner.description"></div>
          <a href="{{banner.link_text}}" class="btn btn-dark btn-lg text-uppercase">Learn More</a>
        </div> -->
      </ng-template>

    </ng-container>

  </owl-carousel-o>
  <!-- </div>
   
  </div> -->

  <!-- <div>Some tags after</div> -->

</section>

<section class="about">
  <div class="container">
    <div class="row align-items-center" *ngIf="whyAlJamia">
      <!-- <div class="col-md-6">
        <div class="image_about"> <img src="../../../assets/web/assets/images/about_image.png" class="img-fluid"
            alt="aljamia"></div>
      </div>
      <div class="col-md-6">
        <h1>Why Al Jamia ?</h1>
        <h2>Al Jamia Al Islamiya</h2>
        <h3>{{whyAlJamia}}
        </h3>
        <div class="vision_mission_home"> <img src="../../../assets/web/assets/images/vis_hom.png"
            class="img-fluid float-left mr-3">
          <h2>Our Vision</h2>
          <p>To produce a dedicated pool of dynamic scholarly leaders capable of leading the society from the front...
          </p>
        </div>
        <div class="vision_mission_home"> <img src="../../../assets/web/assets/images/mis_hom.png"
            class="img-fluid float-left mr-3">
          <h2>Our Mission</h2>
          <p>Design and develop cohesive, contextually relevant and comprehensive curriculum combining the best of...
          </p>
        </div>
        <div class="clearfix"></div>
        <a [routerLink]="['page/about-us']" class="cour-button mt-4">View More About Al Jamia</a>
      </div>
      <div class="clearfix"></div> -->
      <div [innerHTML]="whyAlJamia.description"></div>
    </div>
  </div>
</section>
<section class="courses">
  <div class="container">
    <div class="row text-center">
      <div class="col-12 text-left">
        <h1>Our Courses <span>- We offer a wide range of courses, find the perfect one</span></h1>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="courses.length==0">
        <div class="text-center"><img src='../../../assets/web/assets/images/no_data/no_courses.png'
            class="img-fluid mt-5" alt="no data"> </div>
      </ng-container>
      <ng-container *ngIf="courses.length > 0">
        <div class="col-md-12">
          <ul class="nav nav-tabs tab-card-header" role="tablist">
            <ng-container *ngFor="let course of courses; let i = index">
              <li class="nav-item"> <a (click)="catogoryChnge(course)" class="nav-link {{i === 0 ? 'active' : ''}}"
                  data-toggle="tab" href="#category{{i}}">{{course.category_name}}</a>
              </li>
            </ng-container>
          </ul>
        </div>



        <div class="tab-content pt-3 pb-3">
          <ng-container *ngFor="let course of courses; let i = index">
            <div id="category{{i}}" class="tab-pane {{i === 0 ? 'active' : 'fade'}}">
              <ng-container *ngIf="course.courses.length==0">
                <div class="text-center"><img src='../../../assets/web/assets/images/no_data/no_courses.png'
                    class="img-fluid mt-5" alt="no data"> </div>
              </ng-container>
              <ng-container *ngFor="let cours of course.courses; let j = index">
                <div class="col-lg-4 col-md-4 col-sm-12 mb-2 float-left" *ngIf="3 > j"> <a class="card shadow-sm courses_card">
                    <div class="image_cou" (click)="courseSelect(cours)">
                      <div class="price rounded">
                        <ng-container *ngIf="cours.subscription_type == 1">Free</ng-container>
                        <ng-container *ngIf="cours.subscription_type == 2">
                          <ng-container *ngFor="let cost of cours.cost; let k = index">

                            <ng-container *ngIf="cost.currency_id == currencyId">

                              <!-- <img src="{{cost.image}}"> -->
                              {{cost.currency_label}} {{cost.amount}}
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>
                      <img src="{{s3FilePath}}{{cours.image}}"
                        onerror="this.src = '../../../assets/web/assets/images/courses-01.svg'" class="card-img-top"
                        alt="c">
                    </div>
                    <div class="card-body" (click)="courseSelect(cours)">
                      <h2>{{cours.name}}</h2>
                      <p>{{cours.short_description}}</p>
                    </div>
                    <div class="course_in" (click)="smeSelect(cours)">{{cours.sme_name}}</div>

                  </a> </div>
              </ng-container>
              <div class="clearfix"></div>
              <div class="col-12 text-center pt-4" *ngIf="course.courses.length > 3"> <a
                  (click)="courseListByCatogory()" class="cour-button">View All</a>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- <div class="col-12 text-center pt-4"> <a (click)="courseListByCatogory()" class="cour-button">View All</a>
        </div> -->
      </ng-container>
    </div>
  </div>
</section>
<!-- <section class="instra">
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <h1>Our Instructors</h1>
        <h2>Highly experienced teachers</h2>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="instructors.length==0">
        <div class="text-center"><img src='../../../assets/web/assets/images/no_data/no_instructors_available.png'
            class="img-fluid mt-5" alt="no data"> </div>
      </ng-container>
      <ng-container *ngIf="instructors.length > 0">
        <div class="col-md-12 mt-5">
          <div class="owl-carousel instr_list pb-5">
            <ng-container *ngFor="let instructor of instructors">
              <div class="item"> <a (click)="smeSelect(instructor)" class="card border-0 bg-transparent ">
                  <div class="image-in rounded-circle"><img src="{{s3FilePath}}{{instructor.profile_picture}}"
                      onerror="this.src = '../../../assets/web/assets/images/instructors.svg'"
                      class="card-img-top rounded-circle" alt="instructors"></div>
                  <div class="card-body bg-white text-center shadow-sm">
                    <h3>{{instructor.name}}</h3>
                    <p>{{instructor.degree}}</p>
                  </div>
                </a> </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section> -->
<section class="instra">
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <h1>Our Instructors</h1>
        <h2>Highly Experienced Mentors</h2>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="instructors.length==0">
        <div class="text-center col-md-12"><img src='../../../assets/web/assets/images/no_data/no_instructors_available.png'
            class="img-fluid mt-5" alt="no data"> </div>
      </ng-container>
      <ng-container *ngIf="instructors.length > 0">
        <div class="col-md-12 mt-5">
          <owl-carousel-o [options]="instructOptions">

            <ng-container *ngFor="let instructor of instructors">
              <ng-template carouselSlide>
                <div class="item"> <a (click)="smeSelect(instructor)" class="card border-0 shadow-none bg-transparent ">
                  <div class="image-in rounded-circle"><img src="{{s3FilePath}}{{instructor.profile_picture}}"
                      onerror="this.src = '../../../assets/web/assets/images/instructors.svg'"
                      class="card-img-top rounded-circle" alt="instructors"></div>
                  <div class="card-body bg-white text-center shadow-sm">
                    <h3>{{instructor.name}}</h3>
                    <p>{{instructor.degree}}</p>
                  </div>
                </a> </div>
              </ng-template>
        
            </ng-container>
        
          </owl-carousel-o>
        </div>
      </ng-container>
    </div>
  </div>
</section>

<!-- <section class="categories">
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <h1>Explore</h1>
        <h2>Accessible from anywhere, anytime, from the comfort of their home through its virtual learning platform </h2>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="courseCategoriesWithCounts.length==0">
        <div class="text-center"><img src='../../../assets/web/assets/images/no_data/no_categories_available.png'
            class="img-fluid mt-5" alt="no data"> </div>
      </ng-container>
      <ng-container *ngIf="courseCategoriesWithCounts.length > 0">
        <div class="col-md-12  mt-5">
          <owl-carousel-o [options]="exploreOptions">

            <ng-container *ngFor="let courseCategoriesWithCount of courseCategoriesWithCounts">
              <ng-template carouselSlide>
                <div class="item"> <a (click)="courseCategorySelection(courseCategoriesWithCount)"
                  class="rounded-circle text-center">
                  <div class="cat_image"> <img src="../../../assets/web/assets/images/diploma-01.svg"
                      onerror="this.src = '../../../assets/web/assets/images/diploma-01.svg'" class="card-img-left"
                      class="card-img-left" alt="categories"> </div>
                  <h3>{{courseCategoriesWithCount.title}}<br>
                    {{courseCategoriesWithCount.description}}</h3>
                  <h4>{{courseCategoriesWithCount.course_count}}</h4>
                </a> </div>
              </ng-template>
        
            </ng-container>
        
          </owl-carousel-o>
        </div>
      </ng-container>
    </div>
  </div>
</section> -->

<section class="students">
  <div class="container">
    <div class="row  align-items-center">
      <div class="col-md-4">
        <h2>What Students are Saying</h2>
        <h1>Student Testimonial</h1>
      </div>
      <div class="col-md-8">
        <div class=" student_sa pt-3 pb-5">
          <ng-container *ngIf="testimonials.length==0">
            <div class="text-center"><img
                src='../../../assets/web/assets/images/no_data/currently_no_testimonial_available.png'
                class="img-fluid mt-5" alt="no data"> </div>
          </ng-container>
          
            <!-- <ng-container *ngFor="let testimonial of testimonials">
              <div class="item">
                <div class="card shadow-sm">
                  <div class="student_img "><img src="{{testimonial.image}}"
                      onerror="this.src = '../../../assets/web/assets/images/student.svg'"
                      class="rounded-circle img-fluidt" alt="students"></div>
                  <div class="card-body text-center">
                    <p>"{{testimonial.description}}"</p>
                    <h5>{{testimonial.title}}</h5>
                  </div>
                </div>
              </div>
            </ng-container> -->
            <ng-container *ngIf="testimonials.length > 0">
            <owl-carousel-o [options]="testimonialOptions">
              <ng-container *ngFor="let testimonial of testimonials">
                <ng-template carouselSlide>
                  <div class="item">
                    <div class="card shadow-sm">
                      <div class="student_img "><img src="{{testimonial.image}}"
                          onerror="this.src = '../../../assets/web/assets/images/student.svg'"
                          class="rounded-circle img-fluidt" alt="students"></div>
                      <div class="card-body text-center">
                        <p>"{{testimonial.description}}"</p>
                        <h5>{{testimonial.title}}</h5>
                      </div>
                    </div>
                  </div>
                </ng-template>
          
              </ng-container>
          
            </owl-carousel-o>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="team">
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <h1>Our Team</h1>
        <h2>Highly dedicated and Responsive</h2>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="teams.length==0">
        <div class="text-center col-md-12"><img src='../../../assets/web/assets/images/no_data/currently_no_team_available.png'
            class="img-fluid mt-5" alt="no data"> </div>
      </ng-container>
      <ng-container *ngIf="teams.length > 0">
        <!-- <div class="col-md-12  mt-5">
          <div class="owl-carousel team_c pb-5">
            <ng-container *ngFor="let team of teams">
              <div class="item"> <a class="card border-0 bg-transparent">
                  <div class="image-in rounded-circle"><img src="{{s3FilePath}}{{team.image}}"
                      onerror="this.src = '../../../assets/web/assets/images/team.svg'"
                      class="card-img-top rounded-circle" alt="instructors"></div>
                  <div class="card-body bg-white text-center shadow-sm">
                    <h3>{{team.name}}</h3>
                    <p>{{team.position}}</p>
                  </div>
                </a> </div>
            </ng-container>


          </div>
        </div> -->
        <div class="col-md-12  mt-5 team_c pb-5">
        
        <owl-carousel-o [options]="teamsOptions">

          <ng-container *ngFor="let team of teams">
            <ng-template carouselSlide>
              <div class="item"> <a (click)="teamSelect(team)" class="card border-0 bg-transparent">
                <div class="image-in rounded-circle"><img src="{{s3FilePath}}{{team.image}}"
                    onerror="this.src = '../../../assets/web/assets/images/team.svg'"
                    class="card-img-top rounded-circle" alt="instructors"></div>
                <div class="card-body bg-white text-center shadow-sm">
                  <h3>{{team.name}}</h3>
                  <p>{{team.position}}</p>
                </div>
              </a> </div>
            </ng-template>
      
          </ng-container>
      
        </owl-carousel-o>
         
        </div>
      </ng-container>
    </div>
  </div>
</section>
