import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { QuestionMCQAddComponent } from './question-mcq-add.component';
import { QuestionMCQAddRoutingModule } from './question-mcq-add-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';



@NgModule({
  declarations: [QuestionMCQAddComponent, FieldErrorDisplayComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    QuestionMCQAddRoutingModule,
    NgxDatatableModule,
    NgxPaginationModule
   ]
})
export class QuestionMCQAddModule { }
