<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/course/liveClassList']">Live Class</a></li>
            <li class="breadcrumb-item"> View Live Class</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <section class="content">
    <div class="card">

      <div class="card-header">
        <h1>View Live Class</h1>

      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary p-3 pb-5 add_contry_">
            <form [formGroup]="viewLiveClassForm" role="form" class="p-3 width_ add_course_">
              <div class="col-md-6 float-left">
                <div class="form-group">
                  <label>Course Name</label>
                  <input type="text" class="form-control" placeholder="Class Link" formControlName="courseName" disabled>
                </div>
                <div class="form-group">
                  <label>Batch Start Date</label>
                  <textarea class="form-control" placeholder="Batch Start Date" formControlName="batchStartDate" disabled></textarea>
                </div>
                <div class="form-group">
                  <label>URL</label>
                  <input type="text" class="form-control" placeholder="URL" formControlName="classLink" disabled>
                </div>
                <div class="form-group">
                  <label>URL Created Date</label>
                  <input type="text" class="form-control" placeholder="URL" formControlName="createdDate" disabled>
                </div>
                <div class="form-group">
                  <label>Comment</label>
                  <textarea class="form-control" rows="3" placeholder="Description ..." autocomplete="off"
                    formControlName="comment" disabled></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
