<section class="bread_sec shadow-sm">
    <div class="container">
      <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page"><a (click)="login()">Log in</a></li>
          <li class="breadcrumb-item active" aria-current="page">Forgot Password</li>
        </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="sub_page_content team_content">
    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card card-signin my-5 border-0 shadow-sm">
            <div class="card-body">
             <!-- <div class="alert alert-danger text-center" role="alert"> Your email incorrect </div>
              <div class="alert alert-success text-center" role="alert"> We are sending instructions to reset your password. Please check your email. </div> -->
              <h2 class="text-center">Forgot Password</h2>
              <div class="alert alert-light" role="alert">Please enter email address you used to register, then click the submit button.</div>
              <form class="form-forgot-pas" [formGroup]="forgotformGroup" (ngSubmit)="onSubmit()">
                <div class="form-label-group mb-2">
                  <input type="email" id="inputEmail" formControlName="emailaddress" class="form-control" placeholder="Email address">
                </div>
                <div *ngIf="issubmitted && forgotformGroup.controls.emailaddress.errors" class="error_signup"> 
                  <div *ngIf="issubmitted && forgotformGroup.controls.emailaddress.errors.required"> Email is required. </div>
                  <div *ngIf="issubmitted && forgotformGroup.controls.emailaddress.errors.pattern"> Invalid Email Address. </div>
                </div>
                <button class="btn btn-dark btn-sm btn-block text-uppercase" type="submit">Submit</button>
              </form>
              <hr class="my-3">
              <div class="mt-2">
                <div class="d-flex justify-content-center links"> You remember your password ? <a (click)="login()" class="ml-2">Log in</a> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
