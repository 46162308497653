<section class="bread_sec shadow-sm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <ol class="breadcrumb mb-0 pl-0">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
            <li class="breadcrumb-item" aria-current="page"><a [routerLink]="['/mycourses']">My Courses</a></li>
            <!-- <li class="breadcrumb-item" aria-current="page" *ngIf="myClassDetails.course_slug"><a routerLink="/mycourses/{{myClassDetails.course_slug}}">Courses Detail</a></li> -->
            <li class="breadcrumb-item active" aria-current="page">Courses OverView</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  
  <div class="d-flex" id="wrapper">
  
      <mat-drawer-container class="example-container" hasBackdrop="false">
      <mat-drawer #drawer mode="side" opened="true">
        <ul class="list-group list-group-flush">
          
          <li><a routerLink="/mycourses/overview/detail/{{enrollmentId}}" routerLinkActive=" active_menu" class="list-group-item list-group-item-action bg-light">Overview</a></li>
          <!-- <li><a class="list-group-item list-group-item-action bg-light" routerLinkActive=" active_menu">Live sessions</a></li>
          <li><a routerLink="/mycourses/overview/grade/1" routerLinkActive=" active_menu" class="list-group-item list-group-item-action bg-light">Grades</a></li> -->
          <li><a routerLink="/mycourses/overview/discuss_forum/{{enrollmentId}}" class="list-group-item list-group-item-action bg-light"  routerLinkActive=" active_menu" >Discussion Forums</a></li>
          <li><a routerLink="/mycourses/overview/messages/{{enrollmentId}}" class="list-group-item list-group-item-action bg-light" routerLinkActive=" active_menu">Messages</a></li>
          <li><a  routerLink="/mycourses/overview/courseinfo/{{enrollmentId}}" class="list-group-item list-group-item-action bg-light" routerLinkActive=" active_menu">Course Info</a> </li>
          <li><a  routerLink="/mycourses/overview/certificate/{{enrollmentId}}" class="list-group-item list-group-item-action bg-light" routerLinkActive=" active_menu">Certificate</a> </li>
          
        </ul>
      </mat-drawer>
      <mat-drawer-content>
        <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom responsive_side_menu">
          <a class="list-group-item list-group-item-action bg-light" (click)="drawer.toggle()"><span class="navbar-toggler-icon"></span></a>
      </nav>
        <router-outlet></router-outlet>

      </mat-drawer-content>
    </mat-drawer-container>
  
  </div>

