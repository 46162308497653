import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { StudentEnrollModel } from '../../../models/questionDto';
import html2canvas from "html2canvas";
// import * as jspdf from "jspdf";
import jspdf from 'jspdf';

@Component({
  selector: 'app-enroll-student-import',
  templateUrl: './enroll-student-import.component.html',
  styleUrls: ['./enroll-student-import.component.css']
})
export class EnrollStudentImportComponent implements OnInit {

  spinnerEnabled = false;
  keys: string[];
  dataSheet = new Subject();
  @ViewChild('inputFile') inputFile: ElementRef;
  isExcelFile: boolean;
  isBlankExcelFile: boolean;
  selectedFile: string='';
  session_id: any;
  qualificationId: any;
  isInvalidExcelFile: boolean = false;
  courseList: any = [];
  studentList: any = [];
  templateCourseList: any = [];


  studentModel: Array<StudentEnrollModel> = [];

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService) { }
  studentImportForm: FormGroup;
  submitted = false;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')

    this.studentImportForm = this.formBuilder.group({
      fileData: ['', Validators.required]
    });
    this.getCourseList();
    this.getStudentlist();
  }
  get f() { return this.studentImportForm.controls; }

  onChange(evt) {
    debugger
    this.studentModel = [];
    this.selectedFile = '';
    let data, header;
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.isBlankExcelFile = false;
    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
    this.selectedFile = target.files[0].name;
    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }
    if (this.isExcelFile) {
      this.spinnerEnabled = true;
      this.studentImportForm.controls['fileData'].setErrors(null);
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        debugger
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        data = XLSX.utils.sheet_to_json(ws);
        if (data.length == 0) {
          this.isBlankExcelFile = true;
          this.studentImportForm.controls['fileData'].setErrors({ 'incorrect': true });
          this.dataSheet = new Subject();
        }else {
          this.isBlankExcelFile = false;
          
        }
        for (let i = 0; i < data.length; i++) {
          debugger
          console.log(data[i]);
            if(data[i].user_id == undefined)
              {
              this.studentImportForm.controls['fileData'].setErrors({ 'incorrect': true });
              this.isInvalidExcelFile = true;
              this.dataSheet = new Subject();
              return;
            }
            if(data[i].course_id == undefined)
              {
              this.studentImportForm.controls['fileData'].setErrors({ 'incorrect': true });
              this.isInvalidExcelFile = true;
              this.dataSheet = new Subject();
              return;
            }
            // if(data[i].email == undefined)
            //   {
            //   this.studentImportForm.controls['fileData'].setErrors({ 'incorrect': true });
            //   this.isInvalidExcelFile = true;
            //   this.dataSheet = new Subject();
            //   return;
            // }
           
          let model: StudentEnrollModel = {
            user_id: data[i].user_id,
            course_id: data[i].course_id,
            email: data[i].email,

          }

          this.studentModel.push(model);
        };

      };

      reader.readAsBinaryString(target.files[0]);

      reader.onloadend = (e) => {
        this.spinnerEnabled = false;
        this.keys = Object.keys(data[0]);
        this.dataSheet.next(data)
      }
    } else {
      this.inputFile.nativeElement.value = '';
      this.studentImportForm.controls['fileData'].setErrors({ 'incorrect': true });
      return;
    }
  }

  onSubmit() {
    debugger
    this.submitted = true;
    console.log(this.studentModel);
    if (this.studentImportForm.invalid) {
      return;
    }
    
      this.spinner.show();
      let url = "import_enrollments";
      let body = {"Sheet1" : this.studentModel}
      console.log(body);
      
      this.api.detailsUploadFetch(body, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        debugger
        if (data.status == "true") {
          this.spinner.hide();
          this.studentImportForm.reset();
          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup()
          this.router.navigate(['admin'])

        }
        else {
          this.spinner.hide();
          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    
  }

   excelldownload(type) {
    debugger
    var title
    var content
    if(type == 'course'){
      title = "Course List.xlsb";
      content = this.templateCourseList
    }else{
      title = "Student List.xlsb";
      content = this.studentList;
    }
   
const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(content);
const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

// Process Data (add a new row)
var ws = workbook.Sheets["Sheet1"];
XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});

// Package and Release Data (`writeFile` tries to write and save an XLSB file)
XLSX.writeFile(workbook, title);
  }

  getCourseList(){
    this.spinner.show();
    var course_fetch_url = "getCourselist";
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.course) {
        debugger
        this.courseList = data.course;
        this.courseList =  this.courseList.filter((x)=>
        x.status_id == 1);
        this.courseList.sort((a,b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
        console.log(this.courseList);
        
        for(let item of this.courseList){
          let value = {
            "id": item.id,
            "course_name": item.name
          }
          this.templateCourseList.push(value);
        }
        console.log(this.templateCourseList);
        this.spinner.hide();
       
      }
      else {
        console.log(data.message)
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getStudentlist(){
    var student_fetch_url = "student_list";
    this.api.detailsGetFetch(student_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        debugger
        this.studentList = data.students;
        console.log(this.studentList);

      }
      else {
        console.log(data.message);
      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

}




