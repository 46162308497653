<section class="bread_sec shadow-sm">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item" aria-current="page"><a [routerLink]="['/mycourses']">My Courses</a></li>
          <!-- <li class="breadcrumb-item" aria-current="page" *ngIf="myClassDetails.course_slug"><a routerLink="/mycourses/{{myClassDetails.course_slug}}">Courses Detail</a></li> -->
          <li class="breadcrumb-item" aria-current="page" *ngIf="myClassDetails"><a
              routerLink="/mycourses/overview/detail/{{enrollmentId}}" title="{{myClassDetails.course_name}}">{{myClassDetails.course_name|slice:0:30}}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Class Room</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<div id="wrapper-right" class="toggled">

  <!-- Sidebar -->

  <div id="sidebar-wrapper">
    <div class="heading_side_menu">
      <h4>Overview</h4>
    </div>
    <div class="accordion_scrool">
      <div class="accordion overview_list overview_list_side_menu" id="accordionExample">
        <ng-container *ngFor="let myCourseOverviewDetail of myCourseOverviewDetails; let i = index">
          <div class="card shadow-none">
            <div class="card-header bg-white p-0" id="headingOne{{i}}">
              <h3 class="mb-0">
                <button class="btn btn-block text-left py-1 px-2 btn_bg" type="button" data-toggle="collapse"
                  [attr.data-target]="'#collapseOne' + i" aria-expanded="true" aria-controls="collapseOne{{i}}">
                  {{myCourseOverviewDetail.course_week_title}}</button>
              </h3>
            </div>

            <div id="collapseOne{{i}}" class="collapse fade" aria-labelledby="headingOne{{i}}"
              data-parent="#accordionExample">
              <div class="card-body">

                <!-- Video -->
                <ul class="overview_listing col-lg-12 col-xl-12  col-md-12 col-sm-12 m-0 p-0">
                  <ng-container *ngFor="let course_content of myCourseOverviewDetail.course_contents; let j = index">
                    <li *ngIf="course_content.course_content_type_id === 1">
                      <a (click)="toStartVideo(course_content)" class="btn btn-success video_a text-left"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">
                        {{course_content.title}}
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container>
                          &nbsp;
                          <!-- 1h 20m Left -->
                        </span>
                      </a>
                    </li>
                    <!-- Reading  -->

                    <li *ngIf="course_content.course_content_type_id === 3">
                      <a class="btn reading_a text-left" (click)="onActivity(course_content)"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container> &nbsp;
                          <!-- 1h 20m Left -->
                        </span>
                      </a>
                    </li>
                    <li *ngIf="course_content.course_content_type_id === 6">
                      <a class="btn exeric_a text-left"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">Practice
                        Exercises
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container> &nbsp;
                          <!-- 1h 20m Left -->
                        </span>
                      </a>
                    </li>
                    <!-- live session -->
                    <li *ngIf="course_content.course_content_type_id === 8">
                      <a class="btn live_a text-left" (click)="onActivity(course_content)"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container> &nbsp;
                          <!-- 1h 20m Left -->
                        </span>
                      </a>
                    </li>

                    <!-- Audio  -->
                    <li *ngIf="course_content.course_content_type_id === 2">
                      <a (click)="toStartVideo(course_content)" class="btn audio_a text-left"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container> &nbsp;
                          <!-- 1h 20m Left -->
                        </span>
                      </a>
                    </li>

                    <!-- Document  -->
                    <li *ngIf="course_content.course_content_type_id === 7">
                      <a class="btn doc_a text-left"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}"
                        (click)="onActivity(course_content)">{{course_content.title}}
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container> &nbsp;
                        </span>
                      </a>
                    </li>


                    <li *ngIf="course_content.course_content_type_id === 9">
                      <a class="btn ref_a text-left"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">References
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container> &nbsp;
                        </span>
                      </a>
                    </li>

                    <!-- Assignment -->
                    <li *ngIf="course_content.course_content_type_id === 4">
                      <a class="btn assign_a text-left"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}"
                        (click)="onActivity(course_content)">{{course_content.title}}
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container> &nbsp;
                        </span>
                      </a>
                    </li>

                    <!-- Assessment  -->
                    <li *ngIf="course_content.course_content_type_id === 5">
                      <a class="btn asses_a text-left"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}"
                        (click)="onActivity(course_content)">{{course_content.title}}
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container> &nbsp;
                        </span>
                      </a>
                    </li>
                    <!-- Vimeo -->
                    <li *ngIf="course_content.course_content_type_id === 9">
                      <a (click)="toStartVideo(course_content)" class="btn btn-success video_a text-left"
                        [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">
                        {{course_content.title}}
                        <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                        <span class="float-right pl-3">
                          <ng-container *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_">
                          </ng-container>
                          &nbsp;
                        </span>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- /#sidebar-wrapper -->

  <!-- Page Content -->
  <div id="page-content-wrapper">
    <div class="container-fluid py-4 overview_course course_resource">
      <div class="right-menu"><a data-target="#menu-toggle-right" id="menu-toggle-right" data-toggle="collapse1"
          class="navbar-brand float-right menu-righ-side"><span class="navbar-toggler-icon"></span></a></div>
      <div class="discussion_box " [ngClass]=" myClassDetails && (myClassDetails.course_content_type_id == enumvariable.COURSE_CONTENT_TYPE_VIMEO_9 ) ? 'myclass-videoplay' : ''">
        <div class="discussion_content">
          <div class="col-sm-12 col-md-10 col-lg-10 mx-auto" content_type_name *ngIf=" myClassDetails && (myClassDetails.course_content_type_id == enumvariable.COURSE_CONTENT_TYPE_VIDEO_1 ||
            myClassDetails.course_content_type_id == enumvariable.COURSE_CONTENT_TYPE_AUDIO_2)">
            <div class="card  my-3 border-0 shadow-sm">
              <div class="card-body">
                <h1>{{myClassDetails.title}}</h1>
                <hr class="py-2">
                <!-- <div class="embed-responsive embed-responsive-21by9"> -->
                <!-- <iframe class="embed-responsive-item" controlsList="nodownload" #video></iframe>  -->
                <!-- <video width="320" height="240" controls controlsList="nodownload" #video>
                      <source src="{{vediosrc}}" type="video/mp4">
                    </video>  -->
                <div class="video-player-wrapper mb-2">

                  <vg-player (onPlayerReady)="videoPlayerInit($event)">
                    <vg-overlay-play></vg-overlay-play>
                    <vg-buffering></vg-buffering>

                    <vg-scrub-bar>
                      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                    </vg-scrub-bar>

                    <vg-controls>
                      <vg-play-pause></vg-play-pause>
                      <vg-playback-button></vg-playback-button>

                      <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

                      <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

                      <!-- <button ion-button (click)="addNote($event)" id="playButton"
                        class="note_ btn-sm"><span>Note</span></button> -->

                      <vg-mute></vg-mute>
                      <vg-volume></vg-volume>

                      <vg-fullscreen></vg-fullscreen>

                    </vg-controls>

                    <video #media [vgMedia]="media" [src]="currentVideo.src" id="singleVideo" preload="false"
                      crossorigin>
                    </video>
                  </vg-player>

                  <ul class="player-list">
                    <li *ngFor="let vdo of videoItems; let $index = index" (click)="startPlaylistVdo(vdo, $index)"
                      [class.selected]="vdo === currentVideo">
                      <!-- {{ vdo.name }} --> {{myClassDetails.title}}
                    </li>
                  </ul>

                </div>
                <!-- </div> -->
                <div class="pt-3 pb-3 mt-4 text-left border-top border-bottom">
                  <!--  <div class="download_file float-left">
                    <button type="button" class="btn btn-dark dropdown-toggle file_download" data-toggle="dropdown">
                      File Download</button>
                    <ul class="dropdown-menu file_download_menu">
                      <li><a href="#">Introduction to the University.pdf</a></li>
                      <li><a href="#">Course.xl</a></li>
                      <li><a href="#">Introduction.mp3</a></li>
                    </ul>
                  </div>-->

                  <div *ngIf="myClassDetails && myClassDetails.completed_status === 1"
                    class="completed float-right btn">Complete
                  </div>
                  <button *ngIf="myClassDetails && myClassDetails.completed_status === 2"
                    class="btn btn-dark float-right ml-1" (click)="markAsCompleted()" type="button">Mark as
                    completed</button>
                  <!-- <button class="btn btn-dark float-right ml-1" type="submit">Next item</button> -->
                  <div class="clearfix"></div>
                </div>

                <div class="content_resource">

                  <mat-tab-group>

                    <mat-tab label="Transcript">
                      <div *ngIf="myClassDetails.transcript == null"> No data</div>
                      <div class="paragraph py-3">
                        <!-- <button type="button" class="timestamp float-left" aria-labelledby=""><span id=""
                            class="sr-only">
                            <span>Play video starting at ::8 and follow transcript</span></span>0:08</button> -->
                        <div class="phrases float-left">{{myClassDetails.transcript}}</div>


                      </div>

                    </mat-tab>
                    <!-- <mat-tab label="Notes">
                      <div class="col-sm-12 col-md-12 col-lg-12 note_container mx-auto">
                        <div *ngIf="notes.length == 0"> No data</div>
                        <div class="paragraph py-3 border-bottom" *ngFor="let note of notes; let i = index">
                          <button type="button" class="timestamp float-left mb-3" aria-labelledby=""><span id=""
                              class="sr-only">
                              <span>Play video starting at ::8 and follow transcript</span></span>{{note.time}}</button>
                          <div class="phrases pr-2 pl-2 pb-2 float-left">
                            {{note.notes}}
                          </div>

                          <button type="button" class="btn-close close_button_ float-right"
                            (click)="deleteNote(note.enrollment_content_note_id)" aria-label="Close"></button>
                        </div>
                      </div>
                    </mat-tab> -->
                    <mat-tab label="References">
                      <div *ngIf="myClassDetails.reference_material.length == 0"> No data</div>
                      <div class="paragraph py-3"
                        *ngFor="let reference of myClassDetails.reference_material; let i = index">

                        <div class="phrases float-left">
                          <h3>{{i + 1}} : {{reference.text}}</h3>
                          <div class="clearfix"></div>
                          <ng-container *ngFor="let item of reference.contents; let j = index">
                            <ng-container *ngIf="reference.type == 2"> <a href="{{item.content}}" target="_blank"
                                rel="noopener noreferrer" class="btn btn-info btn-sm mr-1 mb-1 text_ref"><i
                                  class="fas fa-align-left mr-1"></i> view Link <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a> </ng-container>
                            <ng-container *ngIf="reference.type == 1"> <a href="{{S3FILEPATH}}{{item.content}}"
                                class="btn btn-danger btn-sm mr-1 mb-1 pdf_raf" target="_blank"
                                rel="noopener noreferrer"><i class="far fa-file-pdf mr-1"></i> view File <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </mat-tab>

                  </mat-tab-group>


                </div>

              </div>
            </div>
          </div>

          <!-- Assignment -->
          <div class="col-sm-12 col-md-10 col-lg-10 mx-auto"
            *ngIf="myClassDetails && myClassDetails.course_content_type_id == enumvariable.COURSE_CONTENT_TYPE_ASSIGNMENT_4">
            <div class="card  my-3 border-0 shadow-sm">
              <div class="card-body">
                <div class="content_resource">
                  <h1>{{myClassDetails.title}}</h1>
                  <hr class="py-2">
                  <div [innerHTML]="myClassDetails.content"> </div>

                  <div class="accordion overview_list assignment_list" id="accordionExample3"
                    *ngIf="assignmentSubmissions.length !=0">
                    <div class="card">
                      <div class="card-header bg-white p-0" id="headingThree">
                        <h3 class="mb-0">
                          <button class="btn btn-block text-left p-3 pl-5 btn_bg" type="button" data-toggle="collapse"
                            [attr.data-target]="'#collapseThree'" aria-expanded="true" aria-controls="collapseThree">
                            Submissions</button>
                        </h3>
                      </div>
                      <div id="collapseThree" class="collapse fade" aria-labelledby="headingThree"
                        data-parent="#accordionExample3">
                        <div class="card-body">
                          <!-- <div class="accordion overview_list assignment_list" id="accordionExample2">
                            <ng-container *ngFor="let mySubmission of assignmentSubmissions; let i = index">
                              <div class="card">
                                <div class="card-header bg-white p-0" id="headingTwo{{i}}">
                                  <h3 class="mb-0">
                                    <button class="btn btn-block text-left p-3 pl-5 btn_bg" type="button"
                                      data-toggle="collapse" [attr.data-target]="'#collapseTwo' + i"
                                      aria-expanded="true" aria-controls="collapseTwo{{i}}">
                                      {{mySubmission.submitted_date}} {{mySubmission.submitted_time}}</button>
                                  </h3>
                                </div>

                                <div id="collapseTwo{{i}}" class="collapse fade" aria-labelledby="headingTwo{{i}}"
                                  data-parent="#accordionExample2">
                                  <div class="card-body">
                                    <div> file : <ng-container
                                        *ngFor="let fle of mySubmission.submitted_docs; let j = index">
                                        <a href='{{S3FILEPATH}}{{fle}}' target="_blank" download>
                                          <i class="far fa-eye"></i>View File ({{j}})
                                        </a>
                                      </ng-container>
                                    </div>
                                    <div> Status : {{mySubmission.submission_status}}</div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div> -->
                          <ng-container *ngFor="let mySubmission of assignmentSubmissions; let i = index">
                            <div aria-labelledby="headingTwo{{i}}">
                              <div class="card mb-2">
                                <div class="card-header bg-white p-0" id="headingTwo{{i}}" style = "text-transform:capitalize;">
                                  <div class="my-2 text-left p-3 float-left status_subm"> <i
                                      class="far fa-eye-slash mr-2"></i> Status : {{mySubmission.submission_status}}
                                  </div>
                                  <div class="my-2 text-left p-3 float-left score_subm"><i class="far fa-star mr-2"></i>
                                    Score
                                    : {{mySubmission.score}}</div>
                                  <div class="my-2 text-left p-3 float-right status_date"><i
                                      class="far fa-clock mr-2"></i>

                                    {{submittedDate}}
                                  </div>
                                </div>
                                <div class="card-body">
                                  <div class="col-md-12 p-0 float-left">

                                    <ng-container *ngFor="let fle of mySubmission.submitted_docs; let j = index">

                                      <div class="mb-0 text-left p-1 float-left download_sub"> <a
                                          href='{{s3FilePath}}{{fle}}' class="btn btn-block btn-outline-success"
                                          target="_blank" download><i class="fas fa-file-download mr-2"></i> Download
                                          ({{j + 1}}) </a></div>

                                    </ng-container>
                                  </div>

                                  <div class="col-md-12 p-0 float-left" *ngIf="mySubmission.evaluate_comment">
                                    <div class="my-2">
                                      <h3>Comments </h3>
                                      <div class="clearfix"></div>
                                    </div>
                                    <p>{{mySubmission.evaluate_comment}}</p>
                                  </div>


                                </div>
                              </div>
                            </div>
                          </ng-container>


                        </div>
                      </div>

                    </div>
                  </div>



                  <div class="w-100 py-5 mt-4 border-top uplode_assig " *ngIf="myClassDetails.attempt">
                    <form [formGroup]="resourceForm" (ngSubmit)="onSubmit()" role="form" class="p-3 width_ add_course_">
                      <div class="col-md-12">
                        <ng-container *ngFor="let file of selectedFile; let k = index">
                          <div class=" float-left col-md-3 pb-2 assig_cont">
                            <div class="assignment-box p-3 shadow-sm">
                              <button (click)="onFileDelete(k)" class="delete_assi"></button>
                              <span>{{file.name}}</span><br>
                            </div>
                          </div>
                        </ng-container>
                        <div class="clearfix"></div>
                      </div>
                      <div class="file-field form-label-group mb-2 mt-3 col-md-5 mx-auto assignm_button"
                        *ngIf="selectedFile.length != myClassDetails.maximum_files_submit">
                        <div class='file-input'>
                          <input type="file" class="custom-file-input" id="exampleInputFile" formControlName="videoFile"
                            (change)="onVideoFileChanged($event)" accept="{{allowedImageTypes}}"
                            [attr.disabled]="selectedFile.length == myClassDetails.maximum_files_submit ? true : null">

                          <!-- <label class="custom-file-label" for="exampleInputFile"
                            accept="allowedImageTypes">{{selectedFile==null?'Choose file':selectedFile.name}}</label> -->

                          <span class='button'>Upload Assignment</span>
                          <!-- <span class='label' data-js-label>No file
                            selected</span> -->
                        </div>
                        <small class="text-warning">* Only <span
                            *ngFor="let item of myClassDetails.submission_format; let i = index"> <span
                              *ngIf="i == 0">{{item}}</span> <span *ngIf="i != 0">, {{item}}</span></span> file types
                          are accept</small>
                        <mat-progress-bar *ngIf="isProgresBar" class="example-margin" [mode]="mode" [value]="value"
                          [bufferValue]="bufferValue">
                        </mat-progress-bar>
                        <!-- <label class="small text-right pl-2 pr-2 mb-0 pb-0 w-100">Attach image/pdf</label>-->
                      </div>

                    </form>

                  </div>
                </div>
                <div class="pt-3 pb-3 mt-4 text-left border-top border-bottom" *ngIf="myClassDetails.attempt">
                  <button class="btn btn-dark float-right ml-1" (click)="onSubmit_()"
                    [disabled]="fileArray.length === 0">Submit</button>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-10 col-lg-10 mx-auto"
            *ngIf="myClassDetails && myClassDetails.course_content_type_id == enumvariable.COURSE_CONTENT_TYPE_ASSESSMENT_5">

            <app-assessment style="width:100%;" class="borderbtm" [assessmentDetail]="actionDetails">
            </app-assessment>
          </div>

          <div class="col-sm-12 col-md-10 col-lg-10 mx-auto"
            *ngIf="myClassDetails && myClassDetails.course_content_type_id == enumvariable.COURSE_CONTENT_TYPE_DOCUMENT_7">
            <div class="card  my-3 border-0 shadow-sm">
              <div class="card-body">
                <h1>{{myClassDetails.title}}</h1>
                <hr class="py-2">
                <div class="video-player-wrapper mb-2">
                  <pdf-viewer [src]="pdfSrc" [original-size]="false" [show-borders]="true"  ></pdf-viewer>
                </div>

                <div class="pt-3 pb-3 mt-4 text-left border-top border-bottom">
                  <div *ngIf="myClassDetails && myClassDetails.completed_status === 1"
                    class="completed float-right btn">Complete
                  </div>
                  <button *ngIf="myClassDetails && myClassDetails.completed_status === 2"
                    class="btn btn-dark float-right ml-1" (click)="markAsCompleted()" type="button">Mark as
                    completed</button>
                  <!-- <button class="btn btn-dark float-right ml-1" type="submit">Next item</button> -->
                  <div class="clearfix"></div>
                </div>
                <div class="content_resource">

                  <mat-tab-group>

                    <mat-tab label="References">
                      <div *ngIf="myClassDetails.reference_material.length == 0"> No data</div>
                      <div class="paragraph py-3"
                        *ngFor="let reference of myClassDetails.reference_material; let i = index">

                        <div class="phrases float-left">
                          <h3>{{i + 1}} : {{reference.text}}</h3>
                          <div class="clearfix"></div>
                          <ng-container *ngFor="let item of reference.contents; let j = index">
                            <ng-container *ngIf="reference.type == 2"> <a href="{{item.content}}" target="_blank"
                                rel="noopener noreferrer" class="btn btn-info btn-sm mr-1 mb-1 text_ref"><i
                                  class="fas fa-align-left mr-1"></i> view Link <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a> </ng-container>
                            <ng-container *ngIf="reference.type == 1"> <a href="{{S3FILEPATH}}{{item.content}}"
                                class="btn btn-danger btn-sm mr-1 mb-1 pdf_raf" target="_blank"
                                rel="noopener noreferrer"><i class="far fa-file-pdf mr-1"></i> view File <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </mat-tab>

                  </mat-tab-group>


                </div>
              </div>
            </div>
            <!-- myCourseOverviewDetails -->

          </div>

          <div class="col-sm-12 col-md-10 col-lg-10 mx-auto"
            *ngIf="myClassDetails && myClassDetails.course_content_type_id == enumvariable.COURSE_CONTENT_TYPE_READING_3">

            <div class="card  my-3 border-0 shadow-sm">
              <div class="card-body">
                <div class="content_resource">
                  <h1>{{myClassDetails.title}}</h1>
                  <hr class="py-2">
                  <div [innerHTML]="myClassDetails.content"> </div>

                  <div class="pt-3 pb-3 mt-4 text-left border-top border-bottom">
                    <div *ngIf="myClassDetails && myClassDetails.completed_status === 1"
                      class="completed float-right btn">Complete
                    </div>
                    <button *ngIf="myClassDetails && myClassDetails.completed_status === 2"
                      class="btn btn-dark float-right ml-1" (click)="markAsCompleted()" type="button">Mark as
                      completed</button>
                    <!-- <button class="btn btn-dark float-right ml-1" type="submit">Next item</button> -->
                    <div class="clearfix"></div>
                  </div>
                  <div class="content_resource">

                    <mat-tab-group>

                      <mat-tab label="References">
                        <div *ngIf="myClassDetails.reference_material.length == 0"> No data</div>
                        <div class="paragraph py-3"
                          *ngFor="let reference of myClassDetails.reference_material; let i = index">

                          <div class="phrases float-left">
                            <h3>{{i + 1}} : {{reference.text}}</h3>
                            <div class="clearfix"></div>
                            <ng-container *ngFor="let item of reference.contents; let j = index">
                              <ng-container *ngIf="reference.type == 2"> <a href="{{item.content}}" target="_blank"
                                  rel="noopener noreferrer" class="btn btn-info btn-sm mr-1 mb-1 text_ref"><i
                                    class="fas fa-align-left mr-1"></i> view Link <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a> </ng-container>
                              <ng-container *ngIf="reference.type == 1"> <a href="{{S3FILEPATH}}{{item.content}}"
                                  class="btn btn-danger btn-sm mr-1 mb-1 pdf_raf" target="_blank"
                                  rel="noopener noreferrer"><i class="far fa-file-pdf mr-1"></i> view File
                                  <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a></ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </mat-tab>

                    </mat-tab-group>


                  </div>

                </div>
              </div>
            </div>

          </div>

          <div class="col-sm-12 col-md-10 col-lg-10 mx-auto"
            *ngIf="myClassDetails && myClassDetails.course_content_type_id == enumvariable.COURSE_CONTENT_TYPE_LIVE_CLASS_8">

            <div class="card  my-3 border-0 shadow-sm">
              <div class="card-body">
                <div class="content_resource">
                  <h1>{{myClassDetails.title}}</h1>
                  <hr class="py-2">
                  <p>{{myClassDetails.content}} </p>
                  <ng-container *ngIf="myClassDetails.live_session_data != null">
                    <div class="clearfix"></div>
                    <div class="alert alert-info text-center massage_box_list my-3 py-4 px-4 col-md-12">
                      <ng-container>
                        <div class="input-group col-md-6 col-sm-12 mx-auto">
                          <input type="text" name="cpybox" class="form-control"
                            value="{{myClassDetails.live_session_data.live_url}}" disabled>
                          <div class="input-group-append">
                            <button class="waiting_stud_ btn btn-info" value="Copy Url" type="button"
                              (click)="onCopyUrl(myClassDetails.live_session_data.live_url, 1)" id=1>Copy Url</button>
                          </div>
                        </div>
                      </ng-container>
                      <div class="clearfix"></div>
                      <hr class="my-3">
                      <p class="messages_copy_">{{myClassDetails.live_session_data.comments}}</p>
                    </div>

                  </ng-container>
                  <div class="clearfix"></div>

                  <div class="pt-3 pb-3 mt-4 text-left border-top border-bottom">
                    <div *ngIf="myClassDetails && myClassDetails.completed_status === 1"
                      class="completed float-right btn">Complete
                    </div>
                    <button *ngIf="myClassDetails && myClassDetails.completed_status === 2"
                      class="btn btn-dark float-right ml-1" (click)="markAsCompleted()" type="button">Mark as
                      completed</button>
                    <div class="clearfix"></div>
                  </div>
                  <div class="content_resource">

                    <mat-tab-group>

                      <mat-tab label="References">
                        <div *ngIf="myClassDetails.reference_material.length == 0"> No data</div>
                        <div class="paragraph py-3"
                          *ngFor="let reference of myClassDetails.reference_material; let i = index">

                          <div class="phrases float-left">
                            <h3>{{i + 1}} : {{reference.text}}</h3>
                            <div class="clearfix"></div>
                            <ng-container *ngFor="let item of reference.contents; let j = index">
                              <ng-container *ngIf="reference.type == 2"> <a href="{{item.content}}" target="_blank"
                                  rel="noopener noreferrer" class="btn btn-info btn-sm mr-1 mb-1 text_ref"><i
                                    class="fas fa-align-left mr-1"></i> view Link <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a> </ng-container>
                              <ng-container *ngIf="reference.type == 1"> <a href="{{S3FILEPATH}}{{item.content}}"
                                  class="btn btn-danger btn-sm mr-1 mb-1 pdf_raf" target="_blank"
                                  rel="noopener noreferrer"><i class="far fa-file-pdf mr-1"></i> view File
                                  <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a></ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </mat-tab>

                    </mat-tab-group>


                  </div>

                </div>
              </div>
            </div>

          </div>

            <!-- Vimeo -->

            <div class="col-sm-12 col-md-10 col-lg-10 mx-auto" content_type_name *ngIf=" myClassDetails && (myClassDetails.course_content_type_id == enumvariable.COURSE_CONTENT_TYPE_VIMEO_9 )">

              <div class="card  my-3 border-0 shadow-sm">
                <div class="card-body">
                  <h1>{{myClassDetails.title}}</h1>
                  <hr class="py-2">

                  <div class="video-player-wrapper mb-2">

                    <!-- to load iframe use below code and also change line 159 in the ts file -->
                      <!-- <iframe [src]='safeURL' title="Vimeo video player"
                       width="100%" height="500" frameborder="0"
                        allowfullscreen></iframe> -->

                      <!-- to load innerHTML use below code and also change line 162 in the ts file -->
                      <div style="width: 100%; height: 500;" [innerHTML]="safeURL "></div>
                  </div>

                    <div *ngIf="myClassDetails && myClassDetails.completed_status === 1"
                      class="completed float-right btn">Complete
                    </div>
                    <button *ngIf="myClassDetails && myClassDetails.completed_status === 2"
                      class="btn btn-dark float-right ml-1" (click)="markAsCompleted()" type="button">Mark as
                      completed</button>
                  <div class="content_resource">
                    <mat-tab-group>

                      <mat-tab label="Transcript">
                        <div *ngIf="myClassDetails.transcript == null"> No data</div>
                        <div class="paragraph py-3">
                          <div class="phrases float-left">{{myClassDetails.transcript}}</div>


                        </div>

                      </mat-tab>

                      <mat-tab label="Notes">
                        <div class="col-sm-12 col-md-12 col-lg-12 note_container mx-auto">
                          <div *ngIf="notes.length == 0"> No data</div>
                          <div class="paragraph py-3 border-bottom" *ngFor="let note of notes; let i = index">
                            <button type="button" class="timestamp float-left mb-3" aria-labelledby=""><span id=""
                                class="sr-only">
                                <span>Play video starting at ::8 and follow transcript</span></span>{{note.time}}</button>
                            <div class="phrases pr-2 pl-2 pb-2 float-left">
                              {{note.notes}}
                            </div>

                            <button type="button" class="btn-close close_button_ float-right"
                              (click)="deleteNote(note.enrollment_content_note_id)" aria-label="Close"></button>
                          </div>
                        </div>
                      </mat-tab>

                      <mat-tab label="References">
                        <div *ngIf="myClassDetails.reference_material.length == 0"> No data</div>
                        <div class="paragraph py-3"
                          *ngFor="let reference of myClassDetails.reference_material; let i = index">

                          <div class="phrases float-left">
                            <h3>{{i + 1}} : {{reference.text}}</h3>
                            <div class="clearfix"></div>
                            <ng-container *ngFor="let item of reference.contents; let j = index">
                              <ng-container *ngIf="reference.type == 2"> <a href="{{item.content}}" target="_blank"
                                  rel="noopener noreferrer" class="btn btn-info btn-sm mr-1 mb-1 text_ref"><i
                                    class="fas fa-align-left mr-1"></i> view Link <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a> </ng-container>
                              <ng-container *ngIf="reference.type == 1"> <a href="{{S3FILEPATH}}{{item.content}}"
                                  class="btn btn-danger btn-sm mr-1 mb-1 pdf_raf" target="_blank"
                                  rel="noopener noreferrer"><i class="far fa-file-pdf mr-1"></i> view File <ng-container  *ngIf="reference.contents.length > 1">  ({{j + 1}})</ng-container></a>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </div>
              </div>
            </div>


        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!-- /#page-content-wrapper -->

  </div>
