import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PopupService } from '../../../services/popup.service';
import { ConstantsService } from 'src/app/services/constants.service';
import Swal from 'sweetalert2';
import * as moment from "moment";


@Component({
  selector: 'app-view-forum',
  templateUrl: './view-forum.component.html',
  styleUrls: ['./view-forum.component.css']
})
export class ViewForumComponent implements OnInit {
  session_id: any;
  threadId: any;
  // viewForm: FormGroup;
  title: any;
  description: any;
  comments: any = [];
  // comment_by:any;
  // comment_time:any;
  threadCommentAddFormGroup: FormGroup;
  threadCommentEditFormGroup: FormGroup;
  isCommentAddSubmitted: any = false;
  isCommentUpdateSubmitted: any = false
  commentDetails: any;

  correspondingEditCommentId: any = '';
  s3FilePath: any;
  threadCommentLimitedList: any = [];
  threadCommentViewMoreButtonActive: boolean = false;
  threadCommentCountInPage = 5;
  userId: any;
  profilePic: any;

  constructor(public api: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private popup: PopupService,
    private constantService: ConstantsService,) {
    this.threadCommentAddFormGroup = formBuilder.group({
      comment: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
    });
    this.threadCommentEditFormGroup = formBuilder.group({
      comment: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      comment_id: [''],
    });
  }

  getThreadCommentAddParams() {
    return { thread_id: null, comment: null };
  }

  getThreadCommentEditParams() {
    return { comment_id: null, comment: null };
  }

  ngOnInit(): void {
    this.correspondingEditCommentId = "";
    this.userId = localStorage.getItem('user_id');
    this.s3FilePath = this.constantService.s3FilePath;
    this.session_id = localStorage.getItem('JWT_TOKEN')

    debugger
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.threadId) {
        debugger
        this.threadId = params.threadId;
        this.getThreadDetials();
      }
      else {

        this.spinner.hide();

      }
    });

  }

  getThreadDetials() {
    this.spinner.show();
    this.correspondingEditCommentId = ""
    var fetch_thread_url = "getAllthreadById?thread_id=" + this.threadId
    this.api.detailsGetFetch(fetch_thread_url, this.session_id).subscribe((data: any) => {
      console.log(data)
      debugger
      if (data.status == "true") {
        debugger
        this.spinner.hide();
        this.title = data.thread.title;
        this.description = data.thread.description;
        this.profilePic = data.thread.user_image;
        this.comments = data.thread.comments;
        
        for(let item of this.comments){
          item.utc_created_at.date = moment(item.utc_created_at.date + '+00:00').local().format("DD-MM-YYYY hh:mm A");
        }

        if (this.comments.length > this.threadCommentCountInPage) {
          this.threadCommentViewMoreButtonActive = true;
          this.threadCommentLimitedList = this.comments.slice(
            0,
            this.threadCommentCountInPage
          );
        } else {
          this.threadCommentViewMoreButtonActive = false;
          this.threadCommentLimitedList = this.comments.slice(
            0,
            this.comments.length
          );
        }
        // this.comment_by=data.thread.comments[0].comment_by;
        // this.comment_time=data.thread.comments[0].comment_time;

        //   this.viewForm.get('name').setValue(data.name);
        //   this.viewForm.get('title').setValue(data.title);
        //   this.viewForm.get('comment').setValue(data.comment);
        //   this.viewForm.get('course_name').setValue(data.course_name);
        //   this.viewForm.get('date').setValue(data.date);
        //   this.viewForm.get('time').setValue(data.time);
        //  console.log(this.viewForm);
        //   console.log(this.viewForm);
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup()

      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }

  threadCommentAddAction() {
    this.isCommentAddSubmitted = true;
    // this.correspondingEditCommentId = '';
    // this.isThreadEdit = false;

    if (this.threadCommentAddFormGroup.status == 'VALID') {
      this.spinner.show();
      let param = this.getThreadCommentAddParams();
      param.comment = this.threadCommentAddFormGroup.value.comment;
      param.thread_id = this.threadId;

      let add_thread_comment_url = 'post_comment';
      this.api
        .detailsAddFetch(param, add_thread_comment_url, this.session_id)
        .subscribe(
          (data: any) => {
            debugger;
            if (data.status == 'True' && data) {
              this.spinner.hide();
              this.threadCommentAddFormGroup.controls['comment'].setValue('');
              this.threadCommentAddFormGroup.controls[
                'comment'
              ].updateValueAndValidity();
              this.threadCommentAddFormGroup.reset();
              this.getThreadDetials();
            } else {
              this.spinner.hide();
              this.getThreadDetials();
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              //localStorage.clear()
              //this.router.navigate([''])
            }
          }
        );
    }
  }

  async onThreadCommentEdit(id) {
    this.isCommentUpdateSubmitted = false;

    var thread_url = 'getAllCommentById?comment_id=' + id;
    this.correspondingEditCommentId = id;
    await this.api.detailsGetFetch(thread_url, this.session_id).subscribe(
      (data: any) => {
        if (data.status == 'true' && data.comment) {
          this.spinner.hide();
          this.commentDetails = data.comment;
          this.threadCommentEditFormGroup.controls['comment_id'].setValue(id);
          this.threadCommentEditFormGroup.controls['comment'].setValue(
            this.commentDetails.comment
          );
          this.threadCommentEditFormGroup.updateValueAndValidity();
        } else {
          this.spinner.hide();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear();
          this.router.navigate(['']);
        }
      }
    );
  }

  threadCommentViewMore() {
    this.correspondingEditCommentId = '';

    let count = this.threadCommentLimitedList.length;
    if (count + this.threadCommentCountInPage < this.comments.length) {
      this.threadCommentLimitedList = this.comments.slice(
        0,
        count + this.threadCommentCountInPage
      );
    } else {
      this.threadCommentLimitedList = this.comments.slice(
        0,
        this.comments.length
      );
    }
    if (this.threadCommentLimitedList.length < this.comments.length) {
      this.threadCommentViewMoreButtonActive = true;
    } else {
      this.threadCommentViewMoreButtonActive = false;
    }
  }

  threadCommentEditAction() {
    this.isCommentUpdateSubmitted = true;

    if (this.threadCommentEditFormGroup.status == 'VALID') {
      this.spinner.show();
      let param = this.getThreadCommentEditParams();
      param.comment = this.threadCommentEditFormGroup.value.comment;
      param.comment_id = this.threadCommentEditFormGroup.value.comment_id;

      let edit_thread_comment_url = 'update_sme_comment';
      this.api
        .detailsAddFetch(param, edit_thread_comment_url, this.session_id)
        .subscribe(
          (data: any) => {
            if (data.status == 'True' && data) {
              this.spinner.hide();
              this.threadCommentEditFormGroup.controls['comment'].setValue('');
              this.threadCommentEditFormGroup.controls[
                'comment'
              ].updateValueAndValidity();
              this.correspondingEditCommentId = '';
              this.getThreadDetials();
            } else {
              this.correspondingEditCommentId = '';
              this.spinner.hide();
              this.getThreadDetials();
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              //localStorage.clear()
              //this.router.navigate([''])
            }
          }
        );
    }
  }

  async onThreadCommentDelete(id) {
    this.correspondingEditCommentId = '';

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.correspondingEditCommentId = '';
        var thread_url = 'deleteAllCommentbyId';
        let param: any = { comment_id: id };
        this.api.detailsAddFetch(param, thread_url, this.session_id).subscribe(
          (data: any) => {
            if (data.status == 'true' && data) {
              this.spinner.hide();
              this.getThreadDetials();
            } else {
              this.spinner.hide();
              this.getThreadDetials();
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              //localStorage.clear()
              //this.router.navigate([''])
            }
          }
        );
      }
    });
  }

}
