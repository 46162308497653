import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from '../../services/popup.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-web-forgotpassword',
  templateUrl: './web-forgotpassword.component.html',
  styleUrls: ['./web-forgotpassword.component.css']
})
export class WebForgotpasswordComponent implements OnInit {

  constructor(public router:Router, private fb: FormBuilder, private spinner: NgxSpinnerService, private api: ApiService, private popup: PopupService) {
    this.forgotformGroup = fb.group({
      emailaddress: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(80), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
    });
   }

   forgotformGroup: FormGroup;
   issubmitted = false;

  ngOnInit(): void {
    
  }


  login(){
    this.router.navigate(['login']);
  }

  onSubmit(){
  this.issubmitted = true;
  if (this.forgotformGroup.status == "INVALID") {
      return;
    }
    this.spinner.show();
    let param = { email: this.forgotformGroup.value.emailaddress };
      let list_fetch_url = 'student/forgot_password';
      this.api.postDetails(list_fetch_url,param).subscribe((data: any) => {
        if(data.status == "true" && data.message){
          this.spinner.hide();
          this.popup.sucessMessage = data.message;
          this.popup.sucesspopup();
          this.router.navigate(['']);
        }else{
          this.spinner.hide();
          this.popup.failureMessage = data.message;
          this.popup.failurepopup();
        }
      },(err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          this.router.navigate(['login']);
        }
      }
      );

  }

}
