import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
declare var $: any;
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-faq-add',
  templateUrl: './faq-add.component.html',
  styleUrls: ['./faq-add.component.css']
})
export class FaqAddComponent implements OnInit {
  session_id: string;
  categoryList: any = [];
  isEdit: boolean = false;
  faqId: any;
  ckeConfig: any;
  invalidDropdown: boolean = false;
  constructor(public api: ApiService, private router: Router, private formBuilder: FormBuilder, private popup: PopupService, private route: ActivatedRoute, private spinner: NgxSpinnerService) {
    this.ckeConfig = {
      allowedContent: true,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        // { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About,Styles,Font,FontSize'
      // removePlugins: 'horizontalrule,about,list,others',
      // removeButtons: 'Save,NewPage,Preview,Print,Templates,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Find,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,PageBreak,Iframe,Font,TextColor,BGColor,ShowBlocks,Cut,Copy,Paste,Table,Image,Format,Maximize,Styles,Anchor,SpecialChar,PasteFromWord,PasteText,Scayt,Undo,Redo,Strike,RemoveFormat,Indent,Outdent,Blockquote,Link,Unlink,Subscript,Superscript'
    };
  }
  faqForm: FormGroup;
  submitted = false;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.faqForm = this.formBuilder.group({
      category: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.required],
    });
    this.faqForm.reset();
    this.faqForm.get('category').setValue('0');

    this.init();
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;

        this.faqId = +params.dist;
        this.spinner.show();

        var single_url = "edit_faq";
        var edit_body = { "faq_id": this.faqId };
        this.api.detailsAddFetch(edit_body, single_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "true" && data.Faq[0]) {
            this.spinner.hide();

            this.isEdit = true;
            // $("html, body").animate({ scrollTop: 0 });

            this.faqForm.get('category').setValue(data.Faq[0].category_id);
            this.faqForm.get('question').setValue(data.Faq[0].question);
            this.faqForm.get('answer').setValue(data.Faq[0].answer);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });

      }
      else {
        // this.spinner.hide();

        this.isEdit = false;
      }
    }
    );
  }
  get f() { return this.faqForm.controls; }

  init() {
    this.spinner.show();

    this.categoryList = [];
    var category_fetch_url = "faq_category";
    this.api.detailsGetFetch(category_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.faq_category[0]) {
        this.categoryList = data.faq_category;
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSubmit() {
    debugger
    this.submitted = true;
    if (this.faqForm.invalid) {
      return;
    }
    if (this.f.category.value == 0) {
      debugger
      this.invalidDropdown = true;
      return;
      
    }
    if (!this.isEdit) {
      this.spinner.show();

      var body = { "category_id": this.f.category.value, "question": this.f.question.value, "answer": this.f.answer.value };
      var url = "add_faq";
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "TRUE") {
          this.spinner.hide();


          this.faqForm.reset();
          this.categoryList = [];
          this.submitted = false;
          this.popup.sucessMessage = data.message;
          this.popup.sucesspopup();
          this.router.navigate(['admin/faq']);

        }
        else {
          this.spinner.hide();
          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else {
      if (this.isEdit) {
        this.spinner.show();

        var update_body = { "faq_id": this.faqId, "question": this.f.question.value, "answer": this.f.answer.value, "category_id": this.f.category.value };
        var update_url = "update_faq";
        this.api.detailsAddFetch(update_body, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.faqForm.reset();
            this.submitted = false;

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/faq'])

          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
    
  }
}
