import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionComponent } from './question.component';
import { QuestionRoutingModule } from './question-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [QuestionComponent ],
  imports: [
    CommonModule,
    QuestionRoutingModule,
    FormsModule, ReactiveFormsModule, NgxPaginationModule, NgxDatatableModule,
    DialogModule,
    ButtonModule,
    MatTooltipModule
   ]
})
export class QuestionModule { }
