<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item">Enroll Student</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>Enroll Student</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">



              <div class="col-md-6 float-left">
                <form [formGroup]="enrollStudentForm">
                  <div class="form-group">

                    <label>Course *</label>
                    <select formControlName="course_id" class="form-control select2 selact_" style="width: 100%;">
                      <option value="">--Select Course--</option>
                      <option [ngValue]="option.id" *ngFor="let option of courseList">{{option.name}}</option>
                    </select>
                    <div *ngIf="issubmitted && enrollStudentForm.controls.course_id.errors" class="error_signup">
                      <small *ngIf="enrollStudentForm.controls.course_id.errors.required">Course is
                        required
                      </small>
                    </div>

                  </div>

                  <div class="form-group">
                    <label>Student *</label>
                    <select formControlName="student_id" class="form-control select2 selact_" style="width: 100%;">
                      <option value="">--Select Student--</option>
                      <option [ngValue]="option.student_id" *ngFor="let option of studentList">{{option.first_name}}
                        (Student Id: {{option.student_id}}, Email: {{option.email}})</option>
                    </select>

                    <div *ngIf="issubmitted && enrollStudentForm.controls.student_id.errors" class="error_signup">
                      <small *ngIf="enrollStudentForm.controls.student_id.errors.required">Student is
                        required
                      </small>
                    </div>
                  </div>
                </form>

              </div>
              <div class="form-group">
                <div class="input-group">
                  <a routerLink="/admin/enroll-student/import">
                    <i class="fas fa-download"></i> Import Enrollments
                  </a>
                </div>
              </div>

              <div class="clearfix"></div>
              <div class="p-3">
                <button type="button" (click)="onSubmit()"
                  class="btn btn-secondary btn-dark bor-rad_ float-right mr-2">Enroll</button>
              </div>

            </div>
            <div class="clearfix"></div>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>
