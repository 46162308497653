<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item active">Discussion Forums</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    
    <section class="content">
        <div class="card">
    
          <div class="card-header">
            <h1>Discussion Forums</h1>
          </div>
    
    
    
          <form [formGroup]="discussionForumForm" (ngSubmit)="onSearch()" class="form-inline filter_list">
            <div class="card-body pb-0">
              <span class="col-md-2 col-sm-4 float-left pl-0">
                <input type="text" formControlName="search" class="form-control selact_" placeholder="Search text">
              </span>
              <span class="col-md-2 col-sm-4 float-left pl-0">
                <button class="btn btn-dark ml-2 float-left" type="submit"> <i class="fas fa-search mr-1"></i> Search
                </button>
              </span>
              <!-- <a [routerLink]="['/admin/discussion-forum/addforum']" class="btn btn-secondary btn-dark float-right add_country">
                <i class="fas fa-plus mr-2"></i>Add Forum
              </a> -->
            </div>
          </form>


           <div class="card-body table-responsive">
    
            <ngx-datatable  class="material striped" [rows]="forumDetais" [columnMode]="'force'"
              [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [sorts]="[]"
                [externalPaging]="true"
              [count]="page.total" [offset]="page.current_page" [limit]="page.per_page" (page)="setPagetest($event)" [selected]="selected" [selectionType]="'checkbox'"
              (select)="onSelect($event)">
              <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                [resizeable]="false">  
              </ngx-datatable-column>
              <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                    [draggable]="false" [resizeable]="false">
                <ng-template ngx-datatable-header-template let-value="value"
                             let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                  <input type="checkbox" [checked]="allRowsSelected"
                         (change)="selectFn(!allRowsSelected)" />
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                             let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Title" prop="title">
                <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="widthnew" title="{{row.title}}">{{row.title}}</div>  
                </ng-template>
              </ngx-datatable-column>
              
              
              <ngx-datatable-column  name="Name" prop="name">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="widthnew" title="{{row.name}}">{{row.name}}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="CourseName" prop="course_name">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="widthnew" title="{{row.course_name}}"> {{row.course_name}}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column  name="Date" prop="created_date">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.created_date}}
                </ng-template>
              </ngx-datatable-column>  

              <!-- <ngx-datatable-column name="Time" prop="time">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.time}}
                </ng-template>
              </ngx-datatable-column> -->

              <ngx-datatable-column name="Comments" prop="no_of_comments">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="align-center" >{{row.no_of_comments}}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Action" [width]="150" [canAutoResize]="false" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a href="Javascript:void(0);" title="View Forum" (click)="viewForum(row.id)"class="active_button mr-2"><i class="far fa-eye"></i></a> 
                  <!-- <a (click)="editForum(row.id)" class="edit_button mr-2"><i class="far fa-edit"></i></a>  -->
                  <a href="Javascript:void(0);" (click)="deleteForum(row.id)" class="close_button"><i class="far fa-times-circle"></i></a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
            <a (click)="deleteMultipleItem()" class="btn btn-secondary btn-dark float-right add_country" style="margin-top:2%">
              Delete Selected Items
             </a>
          </div>
        </div>
      </section>
  </div>
  