<header id="myHeader" class="shadow-sm">
    <div class="header_top">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="note_header text-truncate">In 1955, the erstwhile Islamiya College Santhapuram marked a new
              beginning</div>
          </div>
          <div class="col-md-6">
            <ng-container *ngIf="!islogin">
              <a (click)="signUp()" class="sign_up">Sign Up</a> <a (click)="logIn()" class="log_in">Log in</a>
            </ng-container>
  
            <div class="profile_head mt-1 float-right align-items-center" *ngIf="islogin">
              <div class="profile_img rounded-circle"><img src="{{profilePic}}" onerror="this.src = '../../../assets/web/assets/images/te_2.jpg'" alt="name">
              </div>
              <div class="ml-2 mr-2 profile_name text-truncate">
  
                <button mat-button [matMenuTriggerFor]="userProfile">{{username}}</button>
                <mat-menu #userProfile="matMenu">
                  <button mat-menu-item (click)="gotoMyCourses()">My Courses</button>
                  <button  mat-menu-item *ngIf="islogin" [routerLink]="['/notifications']">Notifications</button>
                  <button  mat-menu-item *ngIf="islogin" [routerLink]="['/subscription']">Subscriptions</button>
                  <button mat-menu-item (click)="editProfile()" >Edit Profile</button>
                  <button mat-menu-item *ngIf="islogin" [routerLink]="['/profile/change_password']">Change Password</button>
                  <button mat-menu-item *ngIf="islogin" [routerLink]="['/referralpoints']">Referral Points</button>
                  <!-- <button mat-menu-item>Ask for Support</button> -->
                  <button mat-menu-item (click)="logOut()">Logout</button>
                </mat-menu>
              </div>
            </div>
  
  
            <!-- <form class="float-right">
              <div class="form-group currency">
                <mat-form-field appearance="fill">
                  <mat-select [formControl]="formCurrencyList" (selectionChange)="currencyListChange()">
                    <mat-option *ngFor="let items of currencyList" [value]="items">{{items.currency_code}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </form> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6"> <a (click)="gotoHome()" class="float-left logo"> <img
              src="../../../assets/web/assets/images/logo.png" alt="logo"> </a> </div>
        <div class="col-md-6">
          <div class="input-group search_ float-right">
            <input type="text" class="form-control" placeholder="Search Courses" (keyup.enter)="courseSearch()"
              [formControl]="formCourseSearch">
            <div class="input-group-append">
              <button class="btn btn-dark" (click)="courseSearch()"><img
                  src="../../../assets/web/assets/images/search.svg"> </button>
            </div>
          </div>
          <div class="navigation float-xl-right float-md-right float-right">
  
  
  
            <button mat-button [matMenuTriggerFor]="Categories">Categories</button>
            <mat-menu #Categories="matMenu">
              <ng-container *ngIf="courseList.length==0">
                No content to display
              </ng-container>
              <ng-container *ngIf="courseList.length>0">
                <button mat-menu-item (click)="courseListByCatogory('all')">All</button>
              <ng-container *ngFor="let courseGroup of courseList">
                <!-- <ng-container *ngFor="let sub_categories of courseGroup.sub_categories">
                  <button mat-menu-item [matMenuTriggerFor]="sub_menu"
                    (click)="courseListByCatogory(courseGroup)">{{ courseGroup.title }}</button>
                    
                </ng-container> -->
                <ng-container *ngIf="courseGroup.sub_categories.length !=0">
                  <button mat-menu-item [matMenuTriggerFor]="sub_menu"
                    (click)="courseListByCatogory(courseGroup)">{{ courseGroup.title }}</button>
                    
                </ng-container>
                <ng-container *ngIf="courseGroup.sub_categories.length ==0">
                  <button mat-menu-item (click)="courseListByCatogory(courseGroup)">{{ courseGroup.title }}</button>
                </ng-container>
                <!-- (click)="courseSelect(sub_categories)" -->
                <mat-menu #sub_menu="matMenu">
                  <button *ngFor="let sub_categories of courseGroup.sub_categories" mat-menu-item courseSelect
                    (click)="courseListBySubCatogory(sub_categories)">{{ sub_categories.title }}</button>
                </mat-menu>
              </ng-container>
            </ng-container>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </header>
  <xng-breadcrumb hidden="true"></xng-breadcrumb>
  <div>
    <router-outlet (activate)="onActivate($event)" *ngIf="loadComponent"></router-outlet>
  </div>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <h3>Quick links</h3>
          <ul class="menu">
            <li><a [routerLink]="['course']" >Our Courses</a></li>
            <li><a [routerLink]="['page/about-us']" >About Us</a></li>
            <!-- <li><a [routerLink]="['page/contact-us']">Contact us</a></li>
            <li><a [routerLink]="['page/help-and-support']">Help and Support</a></li> -->
            <li><a [routerLink]="['page/terms-conditions-1']">Terms and Conditions</a></li>
            <!-- <li><a [routerLink]="['page/Sitemap']" >Sitemap</a></li> -->
            <li><a [routerLink]="['support']" >Help and Support</a></li>
          </ul>
        </div>
        <div class="col-md-4">
          <h3>App download</h3>
          <a href="#" class="play float-left"></a> <a href="#" class="apple float-left ml-1"></a>
        </div>
        <div class="col-md-4">
          <h3>Keep in Touch with Us</h3>
          <ul class="keep_in_touch">
            <li class="location"> {{address}}</li>
            <li class="tell_number">{{phone}}</li>
            <!-- <li class="email_a"><a href="{{email}}">{{email}}</a></li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <p class="text-white text-left">© 2022 Al Jamia. All Rights Reserved. </p>
          </div>
          <div class="col-md-4">
            <div class="social_link">
            <a href="{{facebookLink}}" target="_blank" class="sc_fb social_footer float-left"></a>
            <a href="{{twitterLink}}" target="_blank" class="sc_in social_footer float-left"></a>
            <a href="{{youtubeLink}}" target="_blank" class="sc_youtube social_footer float-left"></a>
            <a href="{{instaLink}}" target="_blank" class="sc_insta social_footer float-left"></a>
          </div>
          </div>
          <div class="col-md-4">
            <p class="float-right">Powered by <a href="https://www.seeroo.com/" target="_blank"><img
                  src="../../../assets/web/assets/images/seeroo.png"></a></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  