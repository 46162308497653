import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from '../../../models/pageDto';
 import html2canvas from "html2canvas";
// // import * as jspdf from "jspdf";
import jspdf from 'jspdf';
import { CsvModule } from '@ctrl/ngx-csv';

@Component({
  selector: 'app-paymentreport',
  templateUrl: './paymentreport.component.html',
  styleUrls: ['./paymentreport.component.css']
})
export class PaymentreportComponent implements OnInit {
  paymentReportForm: FormGroup;

  @ViewChild('myTable') table: PaymentreportComponent;
  session_id: any;
  searchTerm: any = '';
  selected = [];
  temp = [];
  paymentList: any = [];
  page: Page;
  todayDate: any;
  submitted: any = false;
  csvData: any[] = [];

  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private popup: PopupService,
    private spinner: NgxSpinnerService) {
    this.paymentReportForm = this.formBuilder.group({
      from_date: ['', [Validators.required]],
      to_date: ['', [Validators.required]]
    });
  }

  async ngOnInit() {
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    window.scroll(0, 0);
    this.todayDate = await this.convert();
    this.session_id = localStorage.getItem('JWT_TOKEN')

    this.setPage({ offset: 0 });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.paymentReportForm
      .get(controlName)
      .hasError(errorName);
  };

  onSearch() {
    this.submitted = true;
    if (this.paymentReportForm.valid) {
      if (new Date(this.paymentReportForm.value.from_date) > new Date((this.paymentReportForm.value.to_date))) {
        this.paymentReportForm
          .get("to_date")
          .setErrors({ dateError: true });
      }
      if (this.paymentReportForm.valid) {
        this.setPage({ offset: 0 });
      }
    }

  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  setPage(pageInfo) {
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.paymentList = [];
    let value: any = {
      per_page_count: this.page.per_page,
      page: this.page.current_page

    }
    if (this.paymentReportForm.valid) {
      value = {
        from_date: this.paymentReportForm.value.from_date,
        to_date: this.paymentReportForm.value.to_date,
        per_page_count: this.page.per_page,
        page: this.page.current_page

      }
    }
    var payment_fetch_url = "get_payment_list";

    this.api.detailsUploadFetch(value, payment_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.payments) {
        this.temp = data.payments;
        this.paymentList = data.payments;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
        // this.csvData = [
        //   { firstname: 'Ahmed', lastname: 'Tomi', email: 'ah@smthing.co.com' },
        //   { firstname: 'Raed', lastname: 'Labes', email: 'rl@smthing.co.com' },
        //   { firstname: 'Yezzi', lastname: 'Min l3b', email: 'ymin@cocococo.com' },
        // ];
        // this.csvData = data.payments;
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  convert() {
    var date = new Date(),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  async pdfdownload(order_id) {
      var data = document.getElementById("contentToConvert");
      html2canvas(data).then((canvas) => {
        // Few necessary setting options
        var imgWidth = 208;
        var pageHeight = 310;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
  
        const contentDataURL = canvas.toDataURL("image/png");
        let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
        var position = 0;
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        pdf.save("aljamia-invoice-" + order_id + ".pdf"); // Generated PDF
      });
    }

    ngAfterViewInit(){
      this.getDownloadData();
    }

    getDownloadData(){
      debugger
    this.page.current_page = 0;
    this.paymentList = [];
    let value: any = {
      // per_page_count: 100,
      // page: this.page.current_page

    }
    if (this.paymentReportForm.valid) {
      value = {
        from_date: this.paymentReportForm.value.from_date,
        to_date: this.paymentReportForm.value.to_date,
        // per_page_count: this.page.per_page,
        // page: this.page.current_page

      }
    }
    var payment_fetch_url = "get_payment_list";

    this.api.detailsUploadFetch(value, payment_fetch_url, localStorage.getItem('JWT_TOKEN')).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.payments) {
        debugger
      
        this.csvData = data.payments;
      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
    }

}
