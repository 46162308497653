<!-- <body class="hold-transition sidebar-mini"> -->
  <div class="wrapper">
    <app-header></app-header>

    <app-sidebar></app-sidebar>
    <div>
      <section></section>
    </div>
    <router-outlet></router-outlet>

    <footer class="main-footer"> <strong>AL JAMIA WORLD CAMPUS &copy; </strong></footer>

  </div>

<!-- </body> -->