<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page"> Instructor Details </li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="instructor_page instra instra_name_sec">
  <div class="container">
    <div class="row instru_det align-items-center mt-1 mb-1">
      <div class="col-md-3 mx-auto">
        <div class="image-in rounded-circle"><img src="{{s3FilePath}}{{instructorProfilePic}}"
            onerror="this.src = '../../../assets/web/assets/images/instructors.svg'" class="card-img-top rounded-circle"
            alt="instructors"></div>
      </div>
      <div class="col-md-12 pt-3 text-center">
        <h5>{{instructorFirstName}} {{instructorLastName}}</h5>
        <p>{{instructorEmail}}</p>
        <p>{{instructorQualification}}</p>



      </div>
    </div>
</div>
</section>
    <section class="courses_pages instructor_page">
      <div class="container">
       <div class="row">
      <div class="col-md-12 mb-4">
        <h1>Bio</h1>
      </div>
      <div class="col-md-12 mb-4">
        <!-- <p > -->
          <div [innerHTML]="bio" class="my-3"></div>
          <!-- {{bio}} -->
        <!-- </p> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr class="py-2">
      </div>
    </div>
    <div class="instructor_page_coursr_list">
    <div class="row">
      <div class="col-md-12 mb-4">
        <h1>Courses</h1>
      </div>

      <ng-container *ngIf="courses.length==0">
        <div class="text-center"><img src='../../../assets/web/assets/images/no_data/no_courses.png'
            class="img-fluid mt-5" alt="no data"> </div>
      </ng-container>

      <ng-container *ngIf="courses.length > 0">
        <ng-container *ngFor="let course of courses">
          <div class="col-lg-4 col-md-6 mb-2 float-left"> <a class="card shadow-sm courses_card">
              <div class="image_cou" (click)="courseSelect(course)">
                <div class="price rounded">
                  <ng-container *ngIf="course.subscription_type_id == 1">Free</ng-container>
                  <ng-container *ngIf="course.subscription_type_id == 2">
                   {{course.cost[0].currency_label}} {{course.cost[0].amount}}
                  </ng-container>

                </div>
                <!-- <img src="../../../assets/web/assets/images/image5.jpg" class="card-img-top" alt="c"> -->
                <img src="{{s3FilePath}}{{course.image}}"
                  onerror="this.src = '../../../assets/web/assets/images/courses-01.svg'" class="card-img-top" alt="c">
              </div>
              <div class="card-body" (click)="courseSelect(course)">
                <h2>{{course.course_name}}</h2>
                <p>{{course.short_description}}</p>

              </div>
            </a>

          </div>
        </ng-container>
      </ng-container>


      <!-- <div class="col-lg-4 col-md-6 mb-2 float-left"> <a href="#" class="card shadow-sm courses_card">
          <div class="image_cou">
            <div class="price rounded">INR 10000</div>
            <img src="../../../assets/web/assets/images/image6.jpg" class="card-img-top img-fluid" alt="c">
          </div>
          <div class="card-body">
            <h2>Uloom Al Qura'n</h2>
            <p>An Introduction to the Science of Qura'n...</p>

          </div>
        </a> </div>
      <div class="col-lg-4 col-md-6 mb-2 float-left"> <a href="#" class="card shadow-sm courses_card">
          <div class="image_cou">
            <div class="price rounded">INR 10000</div>
            <img src="../../../assets/web/assets/images/image7.jpg" class="card-img-top img-fluid" alt="c">
          </div>
          <div class="card-body">
            <h2>Uloom Al Hadees</h2>
            <p>An introduction to the science of Prophetic tradition...</p>

          </div>
        </a> </div>
      <div class="col-lg-4 col-md-6 mb-2 float-left"> <a href="#" class="card shadow-sm courses_card">
          <div class="image_cou">
            <div class="price rounded">INR 10000</div>
            <img src="../../../assets/web/assets/images/image9.jpg" class="card-img-top img-fluid" alt="c">
          </div>
          <div class="card-body">
            <h2>Islamic Finance</h2>
            <p>A course designed to equip the students with a general understanding of the Islamic Finance, banking and
              related topics.</p>

          </div>
        </a> </div>
      <div class="col-lg-4 col-md-6 mb-2 float-left"> <a href="#" class="card shadow-sm courses_card">
          <div class="image_cou">
            <div class="price rounded">INR 10000</div>
            <img src="../../../assets/web/assets/images/image10.jpg" class="card-img-top img-fluid" alt="c">
          </div>
          <div class="card-body">
            <h2>Communicative Arabic</h2>
            <p>The course helps to acquire proficiency in Communicative Arabic at an intermediate level.</p>

          </div>
        </a> </div>
      <div class="col-lg-4 col-md-6 mb-2 float-left"> <a href="#" class="card shadow-sm courses_card">
          <div class="image_cou">
            <div class="price rounded">INR 10000</div>
            <img src="../../../assets/web/assets/images/image9.jpg" class="card-img-top img-fluid" alt="c">
          </div>
          <div class="card-body">
            <h2>Communicative Arabic</h2>
            <p>The course helps to acquire proficiency in Communicative Arabic at an intermediate level.</p>

          </div>
        </a> </div> -->
      <div class="clearfix"></div>

    </div>
  </div>
  </div>
</section>
