import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UploadService } from 'src/app/services/upload.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AddcourseWeekPopupComponent } from '../addcourse-week-popup/addcourse-week-popup.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private rote: ActivatedRoute,
    private constantService: ConstantsService,
    private uploadService: UploadService,
    private spinner: NgxSpinnerService,
    private ToasterService: ToasterService,
    public dialog: MatDialog) {
    this.activityForm = this.formBuilder.group({
      title: ['', [Validators.required, Validatespace]],
      subject: ['', Validators.required],
      chapter: [''],
      concept: [''],
      module: [''],
      textContent: ['', Validators.required],
      unlockAfter: ['', Validators.required],
      textSuccessFeedback: ['', [Validators.required, Validatespace]],
      textFailureFeedback: ['', [Validators.required, Validatespace]],
      duration: [''],
      maximumGrade: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      minimumGradePercentage: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]?$|^100$/)]],
      submissionFormat: ['', Validators.required],
      maximumFilesForSubmission: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      gradePerQuestion: ['', [Validators.required, Validators.pattern(/(^[1-9]$)|(^1[0-5]$)/)]],
      numberofQuestions: ['', [Validators.required, Validators.pattern(/^([1-9][0-9]{0,1}|100)$/)]],
      timeLimit: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      liveClassDate: [''],
      liveClassTime: [''],
    });


  }

  courseId: any;
  resourceType: any;
  courseContentTypeId: any;
  courseContentId: any;
  weekId: any;
  activityForm: FormGroup;
  submitted: boolean = false;
  session_id: any;
  subjects: any;
  chapters: any;
  chapter: any;
  isChapter: boolean = false;
  concepts: any;
  concept: any;
  isConcept: boolean = false;
  modules: any;
  module: any;
  isModule: boolean = false;
  course_element_type: number = 0;
  activities: any;
  activityHeadding: any;
  contentTypeName: any;
  course_content_type_id: any;
  ckeConfig: any;
  isEdit: boolean = false;
  courseCountentDetails: any;
  s3FilePath: any;
  VIDEOFILE_PATH: any;
  REFERANCEFILE_PATH: any;
  submissionFormats: any = [];
  tempArr: any = { "brands": [] };

  dayWeekLists: any = [
    { value: 1, title: "Sunday" },
    { value: 2, title: "Monday" },
    { value: 3, title: "Tuesday" },
    { value: 4, title: "Wednesday" },
    { value: 5, title: "Thursday" },
    { value: 6, title: "Friday" },
    { value: 7, title: "Saturday" },
  ];

  ngOnInit(): void {
    
    this.rote.params.subscribe(params => {
      this.courseId = params['courseId'];
      this.courseContentTypeId = params['courseContentTypeId'];
      //this.resourceType = this.rote.snapshot.paramMap.get("resource_type");
      this.weekId = params['week_id'];

      this.courseContentId = params['course_content_id'];
      this.session_id = localStorage.getItem('JWT_TOKEN')
      this.getSubmissionType();
      this.getCourseelements();
      this.getContentType();
    });

    this.s3FilePath = this.constantService.s3FilePath;
    this.VIDEOFILE_PATH = this.constantService.VIDEOFILE_PATH;
    this.REFERANCEFILE_PATH = this.constantService.REFERANCEFILE_PATH;


    this.ckeConfig = {
      allowedContent: true,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        // { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About,Styles,Font,FontSize'
      // removePlugins: 'horizontalrule,about,list,others',
      // removeButtons: 'Save,NewPage,Preview,Print,Templates,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Find,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,PageBreak,Iframe,Font,TextColor,BGColor,ShowBlocks,Cut,Copy,Paste,Table,Image,Format,Maximize,Styles,Anchor,SpecialChar,PasteFromWord,PasteText,Scayt,Undo,Redo,Strike,RemoveFormat,Indent,Outdent,Blockquote,Link,Unlink,Subscript,Superscript'
    };
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.activityForm.get(controlName).hasError(errorName);
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  getSubmissionType() {
    
    this.spinner.show();
    var submission_fetch_url = "getSubmissionformats";
    this.api.detailsGetFetch(submission_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.submission_formats) {
        
        this.submissionFormats = data.submission_formats;
        this.submissionFormats.forEach(element => {
          var newNum = "check";
          var newVal = false;
          element[newNum] = newVal;
        });

        this.spinner.hide();

      } else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  getCourseContentObj() {
    return {
      course_content_id: null, resource_type: null, course_element_type: null, course_id: null, week_id: null, course_content_type_id: null, title: null, subject_id: null, chapter_id: null, concept_id: null, module_id: null, content: null,
      unlock_after: null, transcript: null, free_on_trial: null, references: [{ type: null, contents: [{ content: null }], text: null }], grade_per_question: null, number_of_questions: null, time_limit: null, minimum_grade_percentage: null,
      gradePerQuestion: null, final_assessment: null, maximum_grade: null, submission_format: [], maximum_files_submit: null, success_feedback: null, failure_feedback: null
    };
  }

  getCourseelements() {
    this.spinner.show();
    var fetch_url = "getCourseelements";
    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {
      
      if (data.status == "true" && data.course_elements) {
        //this.subject = data.course_elements
        this.subjects = data.course_elements.filter(option => option.type_id === 1 && option.status_id === 1);
        this.chapters = data.course_elements.filter(option => option.type_id === 2 && option.status_id === 1);
        this.concepts = data.course_elements.filter(option => option.type_id === 3 && option.status_id === 1);
        this.modules = data.course_elements.filter(option => option.type_id === 4 && option.status_id === 1);
        //this.activityForm.get('subject').setValue(this.subjects[0]);
        this.subjectChange();
        if (this.courseContentId) {
          this.isEdit = true;
          this.getCourseCountentDetails();
        }
        //this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  allowedImageTypes: any;
  getContentType() {
    
    this.spinner.show();
    var fetch_url = "get_content_types";
    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.activities) {
        this.activities = data.activities;
        this.setActivityTypes();
        //this.courseForm.get('courceCategory').setValue(data.course_categories[0].id);
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }

  setActivityTypes() {
    this.activities.forEach(element => {
      if (this.courseContentTypeId == element.id) {
        this.activityHeadding = element.content_type; // 'Add ' +
        this.contentTypeName = element.media_type
        this.course_content_type_id = element.id;
        this.resourceType = element.type;
        console.log(this.course_content_type_id);
        this.allowedImageTypes = [];
      }
    });
  }

  getCourseCountentDetails() {
    
    this.spinner.show();
    var fetch_url = "get_course_content_detail?course_content_id=" + this.courseContentId;
    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {
      
      if (data.status == "true" && data.course_content_detail) {
        
        this.courseCountentDetails = data.course_content_detail;
        this.courseId = this.courseCountentDetails.course_id;
        this.weekId = this.courseCountentDetails.week_id;
        this.courseContentTypeId = this.courseCountentDetails.course_content_type_id;
        this.setActivityTypes();
        this.activityForm.get('title').setValue(this.courseCountentDetails.title);
        if (this.courseCountentDetails.subject_id) {
          this.activityForm.get('subject').setValue(this.courseCountentDetails.subject_id);
          this.subjectChange();
        }
        if (this.courseCountentDetails.chapter_id) {
          this.activityForm.get('chapter').setValue(this.courseCountentDetails.chapter_id);
          this.chapterChange();
        }
        if (this.courseCountentDetails.concept_id) {
          this.activityForm.get('concept').setValue(this.courseCountentDetails.concept_id);
          this.conceptChange();
        }
        if (this.courseCountentDetails.module_id) {
          this.activityForm.get('module').setValue(this.courseCountentDetails.module_id);
          this.moduleChange();
        }
        this.activityForm.get('unlockAfter').setValue(this.courseCountentDetails.unlock_after);

        this.activityForm.get('textContent').setValue(this.courseCountentDetails.content);
        this.activityForm.get('gradePerQuestion').setValue(this.courseCountentDetails.grade_per_question);
        this.activityForm.get('numberofQuestions').setValue(this.courseCountentDetails.number_of_questions);
        this.activityForm.get('timeLimit').setValue(this.courseCountentDetails.time_limit);
        this.activityForm.get('minimumGradePercentage').setValue(this.courseCountentDetails.minimum_grade_percentage);
        this.activityForm.get('textSuccessFeedback').setValue(this.courseCountentDetails.success_feedback);
        this.activityForm.get('textFailureFeedback').setValue(this.courseCountentDetails.failure_feedback);

        this.activityForm.get('maximumGrade').setValue(this.courseCountentDetails.maximum_grade);
        this.activityForm.get('duration').setValue(this.courseCountentDetails.duration);
        this.activityForm.get('submissionFormat').setValue(this.courseCountentDetails.submission_format);
        
        if (this.courseCountentDetails.submission_format != undefined) {
          this.courseCountentDetails.submission_format.forEach(element1 => {
            
            this.submissionFormats.forEach(element => {
              
              if (element1.id == element.id) {
                element.check = true;
              }

            });

          });
        }

        // console.log(this.submissionFormats);


        this.activityForm.get('maximumFilesForSubmission').setValue(this.courseCountentDetails.maximum_files_submit);

        //this.activityForm.get('textContent').setValue(this.courseCountentDetails.content);


        // if (this.courseCountentDetails) {
        //   this.activityForm.get('isFreeOnTrial').setValue(true);
        // } else {
        //   this.activityForm.get('isFreeOnTrial').setValue(false);
        // }

        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  get f() {
    return this.activityForm.controls;
  }

  subjectChange() {

    this.isChapter = false;
    this.isConcept = false;
    this.isModule = false;
    this.course_element_type = 1;
    if (this.activityForm.value.subject) {
      this.chapter = this.chapters.filter(option => option.parent_id === this.activityForm.value.subject);
      //this.activityForm.get('chapter').setValue(this.chapter[0]);
      
      if (this.chapter.length > 0) {
        this.isChapter = true;
        this.activityForm.get('chapter').setValue("");
        this.activityForm.get('concept').setValue("");
        this.activityForm.get('module').setValue("");
      }
      //this.chapterChange()
    }
  }
  chapterChange() {
    this.isConcept = false;
    this.isModule = false;
    this.course_element_type = 2;
    if (this.activityForm.value.chapter) {
      this.concept = this.concepts.filter(option => option.parent_id === this.activityForm.value.chapter);
      // this.activityForm.get('concept').setValue(this.concept[0]);
      
      if (this.concept.length > 0) {
        this.isConcept = true;
        this.activityForm.get('concept').setValue("");
        this.activityForm.get('module').setValue("");
      }
      // this.conceptChange();
    }
  }
  conceptChange() {
    this.isModule = false;
    this.course_element_type = 3;
    if (this.activityForm.value.concept) {
      this.module = this.modules.filter(option => option.parent_id === this.activityForm.value.concept);
      //this.activityForm.get('module').setValue(this.module[0]);
      if (this.module.length > 0) {
        this.isModule = true;
        this.activityForm.get('module').setValue("");
      }
    }
  }
  moduleChange() {
    this.course_element_type = 4;
  }

  onSubmit() {
    if (this.courseContentTypeId == 4) {

      this.activityForm
        .get("gradePerQuestion")
        .setErrors(null);
      this.activityForm
        .get("numberofQuestions")
        .setErrors(null);
      this.activityForm
        .get("timeLimit")
        .setErrors(null);

    } else {
      
      this.activityForm
        .get("maximumGrade")
        .setErrors(null);
      this.activityForm
        .get("maximumFilesForSubmission")
        .setErrors(null);
      this.activityForm
        .get("textContent")
        .setErrors(null);
        this.activityForm
        .get("submissionFormat")
        .setErrors(null);



    }
    this.submitted = true;
    this.activityForm
    
    let addObj = this.getCourseContentObj();
    if (this.activityForm.invalid) {
      return;
    }

    // if(this.progresValue != 100 && (this.courseContentTypeId != '3' || this.courseContentTypeId != '7')){
    //   this.ToasterService.openSnackBar('File not completely uploaded', '', 'warning');
    //   return;
    // }
    this.spinner.show();
    addObj.course_content_id = this.courseContentId;
    addObj.resource_type = this.resourceType;
    addObj.course_element_type = this.course_element_type;
    addObj.course_id = this.courseId;
    addObj.week_id = this.weekId;
    addObj.content = this.weekId;
    addObj.course_content_type_id = this.course_content_type_id;
    addObj.title = this.activityForm.value.title;
    addObj.subject_id = this.activityForm.value.subject;
    addObj.chapter_id = this.activityForm.value.chapter;
    addObj.concept_id = this.activityForm.value.concept;
    addObj.module_id = this.activityForm.value.module;
    if (this.courseContentTypeId == 4) {
      
      addObj.content = this.activityForm.value.textContent;
      this.submissionFormats.forEach(element => {
        if (element.check == true) {
          
          addObj.submission_format.push(element.id);
        }
      });
      console.log(addObj.submission_format);


    } else {
      // addObj.content = "content";
    }
    addObj.unlock_after = this.activityForm.value.unlockAfter;
    // addObj.du = this.activityForm.value.unlockAfter;
    addObj.minimum_grade_percentage = this.activityForm.value.minimumGradePercentage;
    addObj.maximum_grade = this.activityForm.value.maximumGrade;
    addObj.grade_per_question = this.activityForm.value.gradePerQuestion;
    // addObj.submission_format = this.activityForm.value.submissionFormat;
    addObj.maximum_files_submit = this.activityForm.value.maximumFilesForSubmission;
    addObj.success_feedback = this.activityForm.value.textSuccessFeedback;
    addObj.failure_feedback = this.activityForm.value.textFailureFeedback;
    addObj.final_assessment = this.courseContentTypeId;
    addObj.number_of_questions = this.activityForm.value.numberofQuestions;
    addObj.time_limit = this.activityForm.value.timeLimit;


    var url = "";
    if (this.isEdit) {
      url = "update_course_content";
    } else {
      url = "add_course_content";
    }

    console.log(JSON.stringify(addObj))

    this.api.detailsAddFetch(addObj, url, this.session_id).subscribe((data: any) => {
      
      if (data.status == "true") {
        this.spinner.hide();
        this.ToasterService.openSnackBar(data.message, '', 'success');
        this.router.navigate(['admin/course/editcourse/' + this.courseId]);
      }
      else {
        this.ToasterService.openSnackBar(data.message, '', 'warning');
        this.spinner.hide();
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();
          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });

  }
  activityShow() {
    
    const dialogRef = this.dialog.open(AddcourseWeekPopupComponent, {
      height: '400px',
      width: '600px',
      data: { title: 'Add Activity or Resources', Week: null, isActivityResourse: true }
    });
    dialogRef.afterClosed().subscribe(result => {
      
      if (result) {
        if (result.isResource) {
          this.router.navigate(['admin/course/resource/' + this.courseId + '/' + result.resource.id + '/' + this.weekId]);
        } else {
          this.router.navigate(['admin/course/activity/' + this.courseId + '/' + result.activity.id + '/' + this.weekId]);
        }
      }
    });
  }
  onEditQuestion() {
    this.router.navigate(['admin/course/activity/' + this.courseContentId + '/questions'])
  }
  onChangeCategory($event, cat: any) {
    
    if ($event.currentTarget.checked) {
      cat.check = true;
      // this.tempArr.brands.push(cat);
      console.log(cat.check);
    }
    else {
      cat.check = false;
      console.log(cat.check);
    }

  }

  onChangesubmissionFormat(){
    let arr =this.submissionFormats.filter((x)=> x.check == true)
  
    if (arr.length > 0){
      this.activityForm
      .get("submissionFormat")
      .setErrors(null);
    } else {
      this.activityForm.controls.submissionFormat.setErrors({ required: true });
    }

  }

}
