import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseCategoryAddComponent } from './course-category-add.component';
import { CourseCategoryAddRoutingModule } from './course-category-add-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [CourseCategoryAddComponent],
  imports: [
    CommonModule,
    CourseCategoryAddRoutingModule,
    FormsModule, ReactiveFormsModule,NgxPaginationModule,NgxDatatableModule
   ]
})
export class CourseCategoryAddModule { }
