import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import {SharedModule} from '../shared/shared.module';
import { CountryModule} from './country/country.module';
import {StateModule} from './state/state.module';
import {BatchesModule} from './batches/batches.module';
import {DistrictModule} from './district/district.module';
import {DistrictAddModule} from './district-add/district-add.module';
import {StateAddModule}from  './state-add/state-add.module';
import {CountryAddModule} from './country-add/country-add.module';
import {QualificationAddModule} from './qualification-add/qualification-add.module';
import {NgxPaginationModule} from 'ngx-pagination';
import {QualificationModule } from './qualification/qualification.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {StreamModule} from './stream/stream.module';
import {StreamAddModule} from './stream-add/stream-add.module';
import {ChangepasswordModule}from './changepassword/changepassword.module';
import { ProfileAddModule } from './profile-add/profile-add.module';
import { FaqModule } from './faq/faq.module';
import {FaqAddModule} from './faq-add/faq-add.module';
import {CategoryModule} from './category/category.module';
import { CourseCategoryAddModule } from './course-category-add/course-category-add.module';
import { CurrencyModule } from './currency/currency.module';
import { CurrencyAddModule } from './currency-add/currency-add.module';
import { QuestionModule } from './question/question.module';
import { QuestionAddModule } from './question-add/question-add.module';
import { QuestionImportModule } from './question-import/question-import.module';
import { QuestionMCQAddModule } from './question-mcq-add/question-mcq-add.module';
import { QuestionCategoryModule } from './question-category/question-category.module';
import { CourseCategoryModule } from './course-category/course-category.module';
import { CourseElementModule } from './course-element/course-element.module';
import { CourseElementAddModule } from './course-element-add/course-element-add.module';
import { SmeModule } from './sme/sme.module';
import { SmeAddModule } from './sme-add/sme-add.module';
import { StudentModule } from './student/student.module';
import { StudentViewModule } from './student-view/student-view.module';
import { QuestionSubModule } from './question-sub/question-sub.module';
import { PageModule } from './page/page.module';
import { PageAddModule } from './page-add/page-add.module';
import { TeamModule } from './team/team.module';
import { TeamAddModule } from './team-add/team-add.module';
import { BannerModule } from './banner/banner.module';
import { BannerAddModule } from './banner-add/banner-add.module';
import { TestimonialModule } from './testimonial/testimonial.module';
import { TestimonialAddModule } from './testimonial-add/testimonial-add.module';
import { QuestionComponent } from './question/question.component';
import { CourseModule } from './course/course.module';
import { CourseElementComponent } from './course-element/course-element.component';
import { DiscussionForumModule } from './discussion-forum/discussion-forum.module';
import { ListEligibilityTestComponent } from './Eligibility-Test/list-eligibility-test/list-eligibility-test.component';
import { AddEligibilityTestComponent } from './Eligibility-Test/add-eligibility-test/add-eligibility-test.component';
import { EditEligibilityTestComponent } from './Eligibility-Test/edit-eligibility-test/edit-eligibility-test.component';
import { ListTestQuestionsComponent } from './Eligibility-Test/Questions/list-test-questions/list-test-questions.component';
import {ReportsModule} from './reports/reports.module';
import {MessagesModule} from './messages/messages.module'

import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MatBadgeModule } from '@angular/material/badge';
import { ListEnrollmentsComponent } from './Enrollments/list-enrollments/list-enrollments.component';
import { EnrollmentDetailsComponent } from './Enrollments/enrollment-details/enrollment-details.component';
import { from } from 'rxjs';
import { SettingModule } from './setting/setting.module';
import { HomeComponent } from './home/home.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CsvModule } from '@ctrl/ngx-csv';
import { EnrollmentScoreComponent } from './Enrollments/enrollment-score/enrollment-score.component';
import { StudentResetPasswordComponent } from './student-reset-password/student-reset-password.component';
import { EnrollStudentsModule } from './enroll-students/enroll-students.module'
import { DatePipe } from '@angular/common';



// import { NumberDirective } from '../helpers/numeric-only.directive';

@NgModule({
  declarations: [DashboardComponent, ListEligibilityTestComponent, AddEligibilityTestComponent, EditEligibilityTestComponent, ListTestQuestionsComponent, ListEnrollmentsComponent, EnrollmentDetailsComponent, HomeComponent, NotificationsComponent,StudentResetPasswordComponent,EnrollmentScoreComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    DashboardRoutingModule,
    CountryModule,
    StateModule,
    BatchesModule,
    DistrictModule,
    SharedModule,
    DistrictAddModule,
    StateAddModule,
    CountryAddModule,
    QualificationAddModule,
    NgxPaginationModule,
    QualificationModule,
    NgxDatatableModule,
    StreamModule,
    StreamAddModule,
    ChangepasswordModule,
    ProfileAddModule,
    FaqModule,
    FaqAddModule,
    CategoryModule,
    CourseCategoryAddModule,
    CurrencyModule,
    CurrencyAddModule,
    QuestionAddModule,
    QuestionImportModule,
    QuestionMCQAddModule,
    QuestionCategoryModule,
    CourseCategoryModule,
    CourseElementModule,
    CourseElementAddModule,
    SmeModule,
    SmeAddModule,
    StudentModule,
    StudentViewModule,
    QuestionModule,
    QuestionSubModule,
    PageModule,
    PageAddModule,
    TeamModule,
    TeamAddModule,
    BannerModule,
    BannerAddModule,
    TestimonialModule,
    TestimonialAddModule,
    CourseModule,
    DiscussionForumModule,
    DialogModule,
    ButtonModule,
    MatBadgeModule,
    ReportsModule,
    SettingModule,
    MessagesModule,
    CsvModule,
    EnrollStudentsModule
    
    
  ],
  entryComponents: [
    QuestionComponent,
    CourseElementComponent
  ],
  providers: [DatePipe]

})
export class DashboardModule { }
