import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { required } from '@rxweb/reactive-form-validators';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';
import { Validatespace } from 'src/app/validation/spacevalidation';

@Component({
  selector: 'app-enroll-student-add',
  templateUrl: './enroll-student-add.component.html',
  styleUrls: ['./enroll-student-add.component.css']
})
export class EnrollStudentAddComponent implements OnInit {
  // notificationAddForm: FormGroup;
  enrollStudentForm: FormGroup;
  issubmitted: boolean = false;
  session_id: any;
  notificationId: any;
  selectedStudents: any = [];
  // studentList: any = [];
  studentList: Array<any> = [];

  dropdownList = [];
  selectedItems: any = [];
  dropdownSettings = {};
  isEdit: boolean = false;
  courseList: any = [];
  title: any;
  notificationData: any;
  isSent: boolean = false;


  constructor(private fb: FormBuilder,public api: ApiService, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService, private tabTitle: Title,
    private meta: Meta,) { 

    this.enrollStudentForm = fb.group({
      course_id: ['', [Validators.required]],
      student_id: ['', [Validators.required]],

    }
    );
  }

  async ngOnInit() {
    debugger
    this.spinner.show();
    this.session_id = localStorage.getItem('JWT_TOKEN');
    await this.getStudentlist();
    await this.getCourseList();
    this.spinner.hide();

         this.tabTitle.setTitle("Enroll Student | Admin | Quran Study Centre");
        this.meta.addTag({
          name: "description",
          content: "Enroll Student | Admin",
        });

    // this.route.params.subscribe(params => {
    //   if (params.id) {
    //     this.notificationId = params.id;
    //     this.isEdit = true;
    //     this.title = "Edit";
    //     this.tabTitle.setTitle("Edit Notification | Admin | Quran Study Centre");
    //     this.meta.addTag({
    //       name: "description",
    //       content: "Edit Notification | Admin",
    //     });
    //     this.spinner.hide();

    //   }
    //   else {
    //     this.title = "Add"
    //     this.spinner.hide();
    //     this.tabTitle.setTitle("Add Notification | Admin | Quran Study Centre");
    //     this.meta.addTag({
    //       name: "description",
    //       content: "Add Notification | Admin",
    //     });

    //   }
    // }
    // );
  
  }

 
  getStudentlist(){
    var student_fetch_url = "student_list";
    this.api.detailsGetFetch(student_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        debugger
        this.studentList = data.students;
        console.log(this.studentList);

      }
      else {
        console.log(data.message);
      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getCourseList(){
    this.spinner.show();
    var course_fetch_url = "getCourselist";
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.course) {
        debugger
        this.courseList = data.course;
        console.log(this.courseList);
        this.courseList =  this.courseList.filter((x)=>
        x.status_id == 1);
        console.log(this.courseList);
        // str.toUpperCase()
        this.courseList.sort((a,b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
        console.log(this.courseList)
       
      }
      else {
        console.log(data.message)
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  onSubmit(){
    debugger
    this.issubmitted = true;
    if (this.enrollStudentForm.invalid) {
      return;
    }

    
    this.spinner.show();
  
    debugger
   
    
        let fetch_url = "enroll_course_by_admin";
        let param = this.enrollStudentForm.value;
        console.log(param);
     
      
       
    
    
    this.api.detailsUploadFetch(param, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      console.log(data)
      if (data.status == "true") {
        this.spinner.hide();
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        // this.router.navigate(['admin/notifications'])
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup()

      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }

}
