        <div class="container-fluid py-4 overview_course">
          <div class="pb-3">
            <h1>Add Thread</h1>
          </div>
          <div class="discussion_box">
            <div class="discussion_header">
              <a routerLink="/mycourses/overview/discuss_forum/{{enrollmentId}}"
                class="btn btn-dark float-right">Back</a>
            </div>
            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
              <div class="card  my-5 border-0 shadow-sm">
                <div class="card-body">
                  <!-- <div class="alert alert-danger text-center" role="alert"> Add Title</div> -->

                  <form class="form-signup" [formGroup]="threadAddformGroup" (ngSubmit)="threadAddAction()">
                    <!-- <div class="form-label-group mb-2">
                                    <label>Course *</label>
                                    <select formControlName="course_id" class="form-control select2 selact_"
                                        style="width: 100%;">
                                        <option value="">-- select course --</option>
                                        <option [ngValue]="course.course_id" *ngFor="let course of myCourseDetails">
                                            {{course.name}}</option>
                                    </select>
                                    <div *ngIf="isSubmitted && threadAddformGroup.controls.course_id.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="threadAddformGroup.controls.course_id.errors.required">
                                            Course is
                                            required
                                        </div>
                                    </div>
                                </div> -->

                    <div class="form-label-group mb-2">
                      <label>Title *</label>
                      <input type="text" id="title" class="form-control" formControlName="title" placeholder="Title"
                        required>
                      <div *ngIf="isSubmitted && threadAddformGroup.controls.title.errors" class="text-danger">
                        <div *ngIf="isSubmitted && threadAddformGroup.controls.title.errors.required">
                          Thread title is required. </div>
                        <div
                          *ngIf="!threadAddformGroup.controls.title.errors.required && threadAddformGroup.controls.title.errors.minlength">
                          The minimum 3 characters required for thread title. </div>
                        <div *ngIf="threadAddformGroup.controls.title.errors.validspace && !threadAddformGroup.controls.title.errors.minlength">
                          Invalid Thread title </div>
                      </div>
                    </div>

                    <div class="form-label-group mb-2">
                      <label>Description *</label>

                      <textarea id="description" formControlName="description" cols="30" rows="3" class="form-control"
                        placeholder="Description"></textarea>

                      <div *ngIf="isSubmitted && threadAddformGroup.controls.description.errors" class="text-danger">
                        <div *ngIf="isSubmitted && threadAddformGroup.controls.description.errors.required">
                          Description is required. </div>
                        <div
                          *ngIf="!threadAddformGroup.controls.description.errors.required && threadAddformGroup.controls.description.errors.minlength">
                          The minimum 3 characters required for description. </div>
                          <div *ngIf="threadAddformGroup.controls.description.errors.validspace && !threadAddformGroup.controls.description.errors.minlength">
                            Invalid Description </div>
                      </div>
                    </div>
                    <button class="btn btn btn-dark btn-primary float-right text-uppercase"
                      type="submit">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
