<div class="wrapper">
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item active">Messsage</li>

            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="card ml-5 mr-5 mt-5" *ngIf="chatList.length == 0">
        <!-- <div class="card-header"> -->
          <h1 class="ml-5">Chat List Not Found</h1> 
        <!-- </div> -->
        </div>
      <div class="d-flex" id="wrapper" *ngIf="chatList.length > 0">
        <mat-drawer-container class="example-container inbox_msg" hasBackdrop="false">
          <mat-drawer #drawer mode="side" opened="true" class="mat-drawer mat-drawer-side">
            <ul class="list-group list-group-flush chat-people-adm">
              <li *ngFor="let item of chatList">
                <a routerLink="/admin/message/{{item.userID}}" routerLinkActive=" active_menu"
                  class="list-group-item list-group-item-action bg-light">
                  <div class="chat_img rounded-circle"> <img src="{{item.profilePic}}" alt="name"> </div>
                  <div class="chat_ib">
                    <h5>{{item.user_name}} <span class="chat_date">{{item.time}} | {{item.date}}</span></h5>
                  </div>
                </a>
              </li>
            </ul>

          </mat-drawer>


          <mat-drawer-content class="mat-drawer-content">
            <nav class="navbar border-bottom pb-2 pt-0 mb-2">
              <a class="mt-0 mb-2" (click)="drawer.toggle()"><span class="navbar-toggler-icon"></span></a>
            </nav>
            <ng-container>
              <router-outlet></router-outlet>
            </ng-container>


          </mat-drawer-content>





        </mat-drawer-container>

      </div>

    </section>
  </div>
</div>
