<div class="container-fluid py-4 overview_course">
    <div class="pb-3">
        <h1>Discussion Forums</h1>
    </div>
    <div class="discussion_box">
        <div class="discussion_header">
            <form [formGroup]="threadForm" (ngSubmit)="onSearch()">

                <div class="card-body pb-0 pl-1 pr-1 pt-1">

                    <div class="col-md-4 col-sm-12 float-left pl-0 pr-0 py-1">
                        <div class="input-group">
                        <input type="text" formControlName="search" class="form-control selact_"
                            placeholder="Search text">
                     <div class="input-group-append">
                        <button class="btn btn-dark btn-serch-thread" type="submit"> <i class="fas fa-search mr-1"></i></button>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-8 col-sm-12 float-left pr-0 pl-0 py-1">
                    <a routerLink="/mycourses/overview/thread_add/{{enrollmentId}}"
                        class="btn btn-secondary btn-dark float-right add_banner">
                        <i class="fas fa-plus mr-2"></i>Add Thread
                    </a>
                    </div>
                </div>
            </form>
        </div>

        <div class="discussion_content">
            <ng-container *ngIf="threadList.length == 0">
                <div class="thred_content col-lg-10 col-md-10 col-sm-12 pt-2 mt-2"><p>No contents to display</p></div></ng-container>
            <ng-container *ngFor="let thread of threadList">
                <div class="thred_list align-items-center">
                    <div class="thred_content col-lg-10 col-md-10 col-sm-12">
                        <p class="text-truncate">{{thread.title}}</p>
                        <h6 class="replay_">{{thread.no_of_comments}} Replies</h6>
                        <div class="clearfix"></div>
                        <h5 class="user_name_">{{thread.name}}</h5>
                        <span class="time_date"> {{ thread.time }} |
                            {{ thread.created_date | date }}</span>
                    </div>
                    <div class="view_thred col-lg-2 col-md-2 col-sm-12">
                        <a routerLink="/mycourses/overview/thread_details/{{thread.id}}/{{enrollmentId}}" class="btn btn-sm mx-auto mt-3"><i class="far fa-eye"></i> View</a>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-12" *ngIf="threadList.length > 6">
            <nav aria-label="Page navigation" class="float-right">
                <ul class="pagination  pt-2">
                    <li class="page-item"> <a class="page-link" (click)="previousPageSelect()" aria-label="Previous">
                            <span aria-hidden="previousShow">&laquo;</span> <span class="sr-only">Previous</span> </a>
                    </li>
                    <ng-container *ngFor="let number of numberpages">
                        <li class="page-item" id="pages{{number}}"><a class="page-link"
                                (click)="pageselect(number)">{{number}}</a></li>
                    </ng-container>
                    <li class="page-item"> <a class="page-link" (click)="nextPageSelect()" aria-label="Next"> <span
                                aria-hidden="nextShow">&raquo;</span> <span class="sr-only">Next</span> </a> </li>
                </ul>
            </nav>
        </div>
    </div>
</div>