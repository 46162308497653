import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { EMPTY, Subscription } from "rxjs";
import { ConstantsService } from '../../../../services/constants.service'
import { lessThanValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent implements OnInit {
  IntervalId: any;

  courseId: any;
  userId: any;
  session_id: any;
  assessmentTestDetails: any;
  isTestStart: boolean = false;
  isOptionType: boolean = false;
  isMatchingOptions: boolean = false;
  isFillinBlanks: boolean = false;
  isDragandDrop: boolean = false;
  isNext: boolean = true;
  isPrevious: boolean = false;
  isFinishTest: boolean = false;
  istestPass: boolean = true;
  elapsedTime: any;
  questions: any;
  selectedQuestion: any;
  selectedQuestionIndex: number = 1;
  totalQuestions: number = 0;
  user_data: any = [];
  assessmentTestObj: any = [];
  assessmentSubmissions: any = [];
  assessmentForm: FormGroup;
  testResults: any;
  draganddropCout: number = 0;
  clickEventsubscription: Subscription;
  s3FilePath: string;
  fibOption: any = [];
  minNumber: any;

  formTestOption = new FormControl('');
  // formTextOption1 = new FormControl('');

  getAssessmentTestObj() {
    return {
      assessment_test_id: null,
      user_data: [
        { question_id: null, option_id: null, type: null },
        {
          question_id: null,
          type: null,
          child_data: [{ question_id: null, option_id: null }],
        },
        {
          question_id: null,
          type: null,
          child_data: [{ option_id: null, group: null }],
        },
        {
          question_id: null,
          type: null,
          child_data: [{ group: null, answer_text: null }],
        },
      ],
    };
  }

  @Input() assessmentDetail: any;

  constructor(
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private rote: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ToasterService: ToasterService,
    private ref: ChangeDetectorRef,
    private constantService: ConstantsService
  ) {
    this.assessmentForm = this.formBuilder.group({
      formTestOption: [''],
    });
  }

  ngOnInit(): void {
    this.s3FilePath = this.constantService.s3FilePath;
    if (this.assessmentDetail != null) {
      this.setDetails();
    }
    this.session_id = localStorage.getItem('access_token');
    debugger
  }

  setDetails() {
    if (this.IntervalId) {
      clearInterval(this.IntervalId);
    }
    this.assessmentForm.reset();
    this.assessmentTestDetails = this.assessmentDetail.asessment_details;
    this.elapsedTime = this.assessmentTestDetails.time_limit;
    this.minNumber = this.assessmentTestDetails.minimum_question_on_attempt;
    this.questions = this.assessmentDetail.questions;
    this.assessmentSubmissions = this.assessmentDetail.submissions;
    this.assessmentTestObj = this.getAssessmentTestObj();
    this.assessmentTestObj.enrollment_course_content_id = this.assessmentDetail.contents.enrollment_course_content_id;
    this.assessmentTestObj.content_type_id = this.assessmentDetail.contents.course_content_type_id;
    this.assessmentTestObj.user_data = [];
    this.questions.forEach((element) => {
      if (element.question_type_id === 1) {
        //'MCQ'
        let testObj = {
          question_id: element.question_id,
          option_id: null,
          type: element.question_type_id,
        };
        this.assessmentTestObj.user_data.push(testObj);
      } else if (element.question_type_id === 3) {
        //'Match the following'
        let testObj = {
          question_id: element.question_id,
          type: element.question_type_id,
          child_data: [{ question_id: null, option_id: null }],
        };
        testObj.child_data = [];
        // element.child_questions.forEach((ele, index) => {
        //   let option = { question_id: ele.id, option_id: element.options[index].option_id };
        //   testObj.child_data.push(option)
        // });
        this.assessmentTestObj.user_data.push(testObj);
      } else if (element.question_type_id === 4) {
        //'Fill in the blanks'
        let testObj = {
          question_id: element.question_id,
          type: element.question_type_id,
          child_data: [{ group: null, answer_text: null }],
        };
        testObj.child_data = [];
        // element.child_data.forEach((element, index) => {
        //   let option = {group: element.group,  answer_text: null};
        //   testObj.child_data.push(option)
        // });
        this.assessmentTestObj.user_data.push(testObj);
      } else if (element.question_type_id === 2) {
        //'drag and drop'
        let testObj = {
          question_id: element.question_id,
          type: element.question_type_id,
          child_data: [{ option_id: null, group: null }],
        };
        testObj.child_data = [];
        // element.options.forEach((element, index) => {
        //   let option = {option_id: element.option_id,  group: null};
        //   testObj.child_data.push(option)
        // });
        this.assessmentTestObj.user_data.push(testObj);
      }
    });
    this.totalQuestions = this.assessmentDetail.questions.length;

  }

  ngOnDestroy() {
    if (this.IntervalId) {
      clearInterval(this.IntervalId);
    }
  }

  async startTest() {
    debugger
    if (this.IntervalId) {
      clearInterval(this.IntervalId);
    }
    this.isTestStart = true;
    this.selectedQuestion = this.questions[this.selectedQuestionIndex - 1];
    debugger
    await this.generateQuestionType();
    this.ref.detectChanges();
    let seconts = 1;
    let minute = this.elapsedTime;
    this.IntervalId = setInterval(() => {
      seconts = seconts - 1;
      this.elapsedTime = minute + ' : ' + seconts;
      if (seconts === 0) {
        seconts = 60;
        minute = minute - 1;
      }
      if (minute === -1) {
        if (this.IntervalId) {
          clearInterval(this.IntervalId);
        }
        this.finishTest();
      }
    }, 1000);

    if (this.totalQuestions == 1) {
      this.isNext = false;
    }
  }

  nextTest() {
    this.spinner.show();
    debugger;
    if (this.isNext) {
      this.generateQuestionData();
    }

    if (this.totalQuestions - 1 == this.selectedQuestionIndex) {
      this.isNext = false;
    }
    if (this.totalQuestions > this.selectedQuestionIndex) {
      this.selectedQuestionIndex = this.selectedQuestionIndex + 1;
      this.selectedQuestion = this.questions[this.selectedQuestionIndex - 1];
      this.isPrevious = true;
    }

    this.generateQuestionType();
    this.spinner.hide();
  }
  previousTest() {
    this.spinner.show();
    debugger;
    this.isNext = true;
    this.selectedQuestionIndex = this.selectedQuestionIndex - 1;
    this.selectedQuestion = this.questions[this.selectedQuestionIndex - 1];
    if (this.selectedQuestionIndex == 1) {
      this.isPrevious = false;
    }
    this.generateQuestionType();
    this.spinner.hide();
  }
  te: any;
  async generateQuestionType() {

    if (this.selectedQuestion.question_type_id === 1) {
      debugger
      //'MCQ'
      this.isOptionType = true;
      this.isMatchingOptions = false;
      this.isFillinBlanks = false;
      this.isDragandDrop = false;
      debugger
    } else if (this.selectedQuestion.question_type_id === 3) {
      //'Match the following'
      this.isOptionType = false;
      this.isMatchingOptions = true;
      this.isFillinBlanks = false;
      this.isDragandDrop = false;

      this.assessmentTestObj.user_data.forEach((element, index) => {
        if (element.question_id === this.selectedQuestion.question_id) {
          element.child_data = [];
          this.selectedQuestion.child_questions.forEach((ele, index) => {
            let option = { question_id: ele.id, option_id: this.selectedQuestion.options[index].option_id };
            element.child_data.push(option);
          });

        }
      });

      debugger
    } else if (this.selectedQuestion.question_type_id === 4) {
      //'Fill in the blanks'
      debugger
      var res = this.selectedQuestion.question_text.split('%blank');
      let i = 0;
      this.te = [];
      let blankCount = 1;
      this.draganddropCout = 0;
      res.forEach((element) => {
        // let cont = 'formTextOption' + i;
        // // if (i >= 1) {
        // //   this.te = this.te + '<input type="text" id="text" class="form-control fill_ans" style="width:150px;" (input)="finishTest()" formControlName="' + cont + '" placeholder=""> <div class="ans_cnt">' + element + '</div>';
        // //   // this.divID.nativeElement.innerHTML = this.te;
        // //   // this.assessmentForm.addControl(cont, this.formBuilder.control(''));
        // // }

        // if (element != '') {
        //   if (element.includes('-' + blankCount + '%')) {
        //     element = element.replace('-' + blankCount + '%', '');
        //     blankCount++;
        //     this.te =
        //       this.te +
        //       '<input type="text" id="text" class="form-control fill_ans" style="width:150px;" (input)="finishTest()" formControlName="' +
        //       cont +
        //       '" placeholder=""> <div class="ans_cnt">' +
        //       element +
        //       '</div>';
        //   } else {
        //     this.te = this.te + '<div class="ans_cnt">' + element + '</div>';
        //   }
        // }

        if (element != '') {
          if (element.includes('-' + blankCount + '%')) {
            element = element.replace('-' + blankCount + '%', '');

            // if (this.draganddropCout >= 1) {
            this.te.push({ qs: '@@@@@', optionNumber: blankCount });
            //}
            // this.te.push(element);
            blankCount++;
            this.te.push({ qs: element, option: null });
          } else {
            this.te.push({ qs: element, option: null });
          }
        }

        i++;
      });
      //this.te = this.selectedQuestion.question_text.replaceAll('[%]', `<input type="text" id="text" class="form-control" style="width:150px;" [formControl]="formTextOption" placeholder="" required>`);
      this.te
      this.isOptionType = false;
      this.isMatchingOptions = false;
      this.isFillinBlanks = true;
      this.isDragandDrop = false;
      debugger

    } else if (this.selectedQuestion.question_type_id === 2) {
      //'drag and drop'
      debugger
      var res = this.selectedQuestion.question_text.split('%blank');
      this.draganddropCout = 0;
      let blankCount = 1;
      debugger
      this.te = [];
      res.forEach((element) => {
        /*if (this.draganddropCout >= 1) {
          this.te.push(element);

          // this.assessmentForm.addControl(cont, this.formBuilder.control(''));
          // this.te = this.te +'<span #div2 id="div2" (drop)="onDrop($event)" (dragover)="allowDrop($event)"  class="form-control drg_drop_qst">  </span> <span class="cnt_answer_fill"> ' +  element + '</span>'
        }*/

        if (element != '') {
          if (element.includes('-' + blankCount + '%')) {
            element = element.replace('-' + blankCount + '%', '');
            blankCount++;
            // if (this.draganddropCout >= 1) {
            this.te.push('@@@@@');
            //}
            this.te.push(element);
          } else {
            this.te.push(element);
          }
        }

        this.draganddropCout++;
      });
      //this.divID.nativeElement.hidden = false;
      // this.divID.nativeElement.innerHTML = this.te;

      this.isOptionType = false;
      this.isMatchingOptions = false;
      this.isFillinBlanks = false;
      this.isDragandDrop = true;
      this.te
      debugger
    }
  }

  onOptionadd(value: string, index: any) {
    let index_ = index - 1;
    this.fibOption[index_] = value;
  }

  generateQuestionData() {
    debugger;
    if (this.selectedQuestion.question_type_id === 2) {
      this.assessmentTestObj.user_data.forEach((element, index) => {
        if (element.question_id === this.selectedQuestion.question_id) {
          element.child_data = [];
          let k = 0;
          for (let i = 0; i < this.te.length; i++) {
            if (this.te[i] == '@@@@@') {
              let te = document.getElementById('div2' + i);
              if (te && te.childNodes.length > 0) {
                let chdata = {
                  option_id: (te.childNodes[0] as any).value,
                  group: k + 1,
                };
                element.child_data.push(chdata);
              } else {
                element.child_data.push(null);
              }
              k++
            }
          }
        }
      });
    } else if (this.selectedQuestion.question_type_id === 4) {
      this.fibOption;
      debugger
      this.assessmentTestObj.user_data.forEach((element, index) => {
        if (element.question_id === this.selectedQuestion.question_id) {
          element.child_data = [];
          for (let i = 0; i < this.fibOption.length; i++) {
            element.child_data.push({ group: i + 1, answer_text: this.fibOption[i] })
          }

        }
      });
    }
  }

  drag(ev) {
    ev.dataTransfer.setData('text', ev.target.id);
  }
  allowDrop(ev) {
    ev.preventDefault();
  }
  onDrop(ev) {
    // this.selectedQuestion;
    // ev.preventDefault();
    // var data = ev.dataTransfer.getData('text');
    // if (ev.target.textContent) {
    //   //ev.target.appendChild(document.getElementById(ev.target.id));
    //   // ev.target.id.originalPosition = {top:0, left:0}
    //   ev.target.textContent = '';
    // }
    // ev.target.appendChild(document.getElementById(data));
    // this.selectedQuestion;

    ev.preventDefault();
    var b = ev.currentTarget.id;
    var data = ev.dataTransfer.getData('text');
    ev.currentTarget.appendChild(document.getElementById(data));
    if (b != null && ev.currentTarget.childNodes.length > 1) {
      var doc = document.getElementById('drag_options');
      let c = ev.currentTarget.childNodes[0].id;
      doc.appendChild(document.getElementById(c));
      // ev.currentTarget.removeChild(document.getElementById(c));
    }
  }

  finishTest() {
    this.generateQuestionData();
    let minchk = this.checkMinNumber();
    if (!minchk) {
      if (this.elapsedTime != "0 : 0"){
        this.ToasterService.openSnackBar("You should answer minimum " + this.minNumber + " questions for test submission", '', 'Warning');
        return
      }
      if (this.elapsedTime == "0 : 0"){
        this.ToasterService.openSnackBar("Your time is over.  You have attended only " + this.minNumber + " questions. Try again later", '', 'Warning');
        if (this.IntervalId) {
          clearInterval(this.IntervalId);
        }
        // this.isTestStart = !this.isTestStart;
        // this.isFinishTest = !this.isFinishTest
        this.setDetails();
        this.LocaldataService.triggerAssessmentFunction();
        return
      }
      
    }
    Swal.fire({
      title: 'Do you want to submit?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Submit!',
    }).then((result) => {
      debugger;
      if (result.value) {
        this.spinner.show();
        if (this.IntervalId) {
          clearInterval(this.IntervalId);
        }
        //this.divID.nativeElement.children[0].value
        var fetch_url = 'activity_submission';
        let param = { testObj: this.assessmentTestObj, user_id: this.userId };
        this.api
          .detailsAddFetch(this.assessmentTestObj, fetch_url, this.session_id)
          .subscribe(
            (data: any) => {
              debugger;
              if (data.status == 'true' && data.result) {
                this.isFinishTest = true;
                this.istestPass = data.result.is_passed;
                debugger
                this.testResults = data.result;
                this.ToasterService.openSnackBar(data.message, '', 'success');
                this.spinner.hide();
              } else {
                this.spinner.hide();
              }
            },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
                localStorage.clear();
                this.router.navigate(['']);
              }
            }
          );
      } else {
        if (this.elapsedTime == "0 : 0") {
          if (this.IntervalId) {
            clearInterval(this.IntervalId);
          }
          this.isTestStart = !this.isTestStart;
          this.isFinishTest = !this.isFinishTest
          this.setDetails();
          this.LocaldataService.triggerAssessmentFunction();
        }
      }
    })

  }

  optionObj: any = [];
  optionChange() {
    this.assessmentTestObj.user_data.forEach((element, index) => {
      if (element.question_id === this.selectedQuestion.question_id) {
        element.option_id = this.formTestOption.value.option_id;
        element.type = this.selectedQuestion.question_type_id;
      }
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.assessmentTestObj.user_data.forEach((element, index) => {
      if (element.question_id === this.selectedQuestion.question_id) {
        element.child_data = [];
        this.selectedQuestion.child_questions.forEach((elem, inde) => {
          let chdata = {
            option_id: event.container.data[inde].option_id,
            question_id: elem.id,
          };
          element.child_data.push(chdata);
        });
      }
    });
  }

  restartCourse() {
    this.isNext = true;
    this.isPrevious = false;
    this.isFinishTest = false;
    this.isTestStart = false;
    this.selectedQuestionIndex = 1;
    this.setDetails();
  }
  backToAssessment() {
    this.isTestStart = !this.isTestStart;
    this.isFinishTest = !this.isFinishTest
    this.setDetails();
    this.LocaldataService.triggerAssessmentFunction();
  }

  checkMinNumber() {
    let cnt = 0;
    for (let item of this.assessmentTestObj.user_data) {
      if (item.type == 1) {
        if (item.option_id != null) {
          cnt++
        }
      } else {
        if (item.child_data.length != 0) {
          cnt++
        }
      }
    }
    cnt;
    debugger
    if (this.minNumber > cnt) {
      return false;
    } else {
      return true
    }


  }

}
