<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/question']">Questions</a></li>
            <li class="breadcrumb-item active">Add Question</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>Add Question</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3">
              <form [formGroup]="questionMCQForm" (ngSubmit)="onSubmit()">
                <div class="col-md-8 float-left">
                  <div class="form-group">
                    <label>Question Title</label>
                    <input type="text" formControlName="questionTitle" class="form-control selact_"
                           placeholder="Enter Title" [ngClass]="{ 'is-invalid': submitted && f.questionTitle.errors }">
                    <div *ngIf="submitted && f.questionTitle.errors" class="invalid-feedback">
                      <div *ngIf="f.questionTitle.errors.required">
                        Question Title is
                        required
                      </div>
                    </div>
                   
                  </div>
                  <div class="form-group">
                    <label>Question Text</label>
                    <textarea class="form-control selact_" formControlName="questionText"
                              placeholder="Enter Question Text"
                              [ngClass]="{ 'is-invalid': submitted && f.questionText.errors }"></textarea>
                    <div *ngIf="submitted && f.questionText.errors" class="invalid-feedback">
                      <div *ngIf="f.questionText.errors.required">
                        Question Text is
                        required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Answer Explanation</label>
                    <textarea class="form-control selact_" formControlName="answerExplanation"
                              placeholder="Enter Answer Explanation"
                              [ngClass]="{ 'is-invalid': submitted && f.answerExplanation.errors }"></textarea>
                    <div *ngIf="submitted && f.answerExplanation.errors" class="invalid-feedback">
                      <div *ngIf="f.answerExplanation.errors.required">
                        Answer Explanation is
                        required
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Question Category</label>
                    <select name="questionCategory" formControlName="questionCategory" size="1"
                            class="form-control select2 selact_" style="width: 100%;" placeholder="Select Question Category"
                            [ngClass]="{ 'is-invalid': submitted && f.questionCategory.errors }">
                      <option *ngFor="let item of questionCategoryList" [value]="item.id">{{item.title}}</option>

                    </select>

                    <div *ngIf="submitted && f.questionCategory.errors" class="invalid-feedback">
                      <div *ngIf="f.questionCategory.errors.required">
                        Question Category is
                        required
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Difficulty Level</label>
                      <select name="difficultyLevel" formControlName="difficultyLevel" size="1"
                              class="form-control select2 selact_" style="width: 100%;" placeholder="Select Difficulty Level"
                              [ngClass]="{ 'is-invalid': submitted && f.difficultyLevel.errors }">
                        <option *ngFor="let item of difficultyLevelList" [value]="item.id">{{item.level}}</option>
                      </select>
                      <div *ngIf="submitted && f.difficultyLevel.errors" class="invalid-feedback">
                        <div *ngIf="f.difficultyLevel.errors.required">
                          Difficulty Level is
                          required
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Status</label>
                      <select class="form-control selact_" formControlName="status" style="width: 100%;" placeholder="Select Status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                        <option value="1">Active</option>
                        <option value="2">Disabled</option>
                      </select>
                      <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                        <div *ngIf="f.status.errors.required">
                          Status is
                          required
                        </div>
                      </div>
                    </div>

                    <div class="form-group mt-4">
                      <h4>Add Files</h4>

                      <table id="myTable" class=" table order-list_1 mb-0">
                        <thead>
                          <tr>
                            <td>No.</td>
                            <td>Options</td>
                            <td>File</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of questionModel.media_files; let i = index">
                            <td><span class="pt-3">{{ i+1 }}</span></td>
                            <td class="col-md-3" style="width:20%;">
                              <div class="form-group mb-0">
                                <select class="form-control select2 selact_" style="width: 100%;" (change)="onMediaFileOptionsChanged(item.id)" formControlName="mediaFileOptions{{item.id}}">
                                  <option value='1' selected="selected">Image</option>
                                  <option value='2'>Audio</option>
                                  <option value='3'>Video</option>
                                </select>
                              </div>
                            </td>
                            <td class="col-md-4" style="width:70%;">
                              <div class="form-group  mb-0">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text" id="">
                                      <i class="fas" [ngClass]="{
      'fa-image':selectedMediaFileOptions[item.id] === '1' ,
      'fa-volume-up':selectedMediaFileOptions[item.id] === '2',
      'fa-video':selectedMediaFileOptions[item.id] === '3'
    }"></i>
                                    </span>
                                  </div>
                                  <div class="custom-file">
                                    <input type="file" class="custom-file-input" formControlName="mediaFileData{{item.id}}" accept={{selectedMediaFileType[item.id]}}
                                           id="exampleInputFile1" (change)="onMediaFileChanged($event, item.id)"
                                           [ngClass]="{ 'is-invalid': submitted  }">
                                    <label class="custom-file-label" for="exampleInputFile" accept="">{{item.path==null?'Choose file':item.path}}</label>
                                  </div>
                                  <app-field-error-display [displayError]="isFieldValid('mediaFileData'+item.id)" errorMsg="File is required">
                                  </app-field-error-display>
                                  <!--<div *ngIf="submitted && item.path==null" class="invalid-feedback">
    <div *ngIf="item.path==null">
      Image is
      required
    </div>
  </div>-->
                                </div>
                              </div>
                            </td>
                            <td class="col-sm-2"><button (click)="deleteMediaRow(item.id)" type="button" class="ibtnDel btn btn-md btn-danger float-right"><i class="far fa-trash-alt"></i></button></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="5" style="text-align: left;"><button type="button" class="btn btn-default btn-sm float-right" id="addrow_1" value="Add Row" (click)="incrementMediaFileCounter(1)">Add New</button></td>
                          </tr>
                          <tr> </tr>
                        </tfoot>
                      </table>
                    </div>

                    <div class="form-group">
                      <h4>Add Options</h4>
                      <table id="myTable" class=" table order-list_1 mb-0">
                        <thead>
                          <tr>
                            <td>No.</td>
                            <td>Options</td>
                            <td>File</td>
                            <td>Is Correct</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of questionModel.options; let j = index">
                            <td><span class="pt-3">{{ j+1 }}</span></td>
                            <td class="col-md-3" style="width:25%;">
                              <div class="form-group mb-0">
                                <select class="form-control select2 selact_" style="width: 100%;" (change)="onOptionFileOptionsChanged(item.id)" formControlName="optionFileOptions{{item.id}}">
                                  <option value='1'>Image</option>
                                  <option value='2'>Audio</option>
                                  <option value='3'>Video</option>
                                  <option value='4' selected="selected">Text</option>
                                </select>
                              </div>
                            </td>
                            <td class="col-md-4" style="width:60%;">
                              <div class="form-group  mb-0">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text" id="">
                                      <i class="fas" [ngClass]="{
      'fa-image':selectedOptionFileOptions[item.id] === '1',
      'fa-volume-up':selectedOptionFileOptions[item.id] === '2',
      'fa-video':selectedOptionFileOptions[item.id] === '3',
      'fa-keyboard':selectedOptionFileOptions[item.id] === '4'
    }"></i>
                                    </span>
                                  </div>

                                  <div *ngIf="selectedOptionFileOptions[item.id] != '4'" class="custom-file">
                                    <input type="file" class="custom-file-input" formControlName="optionFileData{{item.id}}" accept={{selectedOptionFileType[item.id]}}
                                           id="exampleInputFile1" (change)="onOptionFileChanged($event, item.id)"
                                           [ngClass]="{ 'is-invalid': submitted  }">
                                    <label class="custom-file-label" for="exampleInputFile" accept="">{{item.option==null?'Choose file':item.option}}</label>

                                  </div>
                                  <app-field-error-display [displayError]="isFieldValid('optionFileData'+item.id)" errorMsg="File is required">
                                  </app-field-error-display>
                                  <div *ngIf="selectedOptionFileOptions[item.id] == '4'" class="custom-file">
                                    <input type="text" class="form-control" formControlName="optionText{{item.id}}" placeholder="Enter..."
                                           (change)="onOptionTextChanged($event, item.id)">
                                    <app-field-error-display [displayError]="isFieldValid('optionText'+item.id)" errorMsg="Text is required">
                                    </app-field-error-display>
                                  </div>
                                  <!--<div *ngIf="submitted && f.optionFileData.errors" class="invalid-feedback">
    <div *ngIf="f.optionFileData.errors.required">
      Image is
      required
    </div>
  </div>-->
                                </div>
                              </div>
                            </td>


                            <td class="col-sm-3" style="width:25%;">
                              <div class="icheck-primary d-inline float-left col-1">
                                <input type="checkbox" id="checkboxPrimary1{{item.id}}" formControlName="optionIsAnswer{{item.id}}"
                                       (change)="onOptionIsAnswerChanged($event, item.id)">
                                <label for="checkboxPrimary1{{item.id}}"> </label>



                              </div>
                            </td>
                            <td class="col-sm-2"><button (click)="deleteOptionRow(item.id)" type="button" class="ibtnDel btn btn-md btn-danger float-right"><i class="far fa-trash-alt"></i></button></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="5" style="text-align: left;"><button type="button" class="btn btn-default btn-sm float-right" id="addrow_1" value="Add Row" (click)="incrementOptionFileCounter(4)">Add New</button></td>
                          </tr>
                          <tr> </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit"
                          class="btn btn-secondary btn-dark bor-rad_ float-right">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

  </section>
  <!-- /.content -->
</div>
