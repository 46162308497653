<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">My Courses</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="sub_banner team_banner text-left">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>My Courses</h1>
      </div>
    </div>
  </div>
</section>
<section class="courses_pages">
  <div class="container">
    <div class="row">
      <ng-container *ngIf="myCourseDetails.length==0">
        <div class="col-md-12 text-center mt-5 mb-3 ng-star-inserted"><img src='../../../assets/web/assets/images/no_data/no_courses.png'
          class="img-fluid mt-5" alt="no data"> </div>
      </ng-container>
      <ng-container *ngIf="myCourseDetails.length > 0">
        <ng-container *ngFor="let myCourseDetail of myCourseDetails">

          <div class="col-lg-4 col-md-6 mb-2 float-left">

            <div class="card shadow-sm courses_card">
              <div class="image_cou">
                <img src="{{s3FilePath}}{{myCourseDetail.image}}" class="card-img-top" alt="c"
                  onerror="this.src = '../../../assets/web/assets/images/in-1_.jpg'">
              </div>
              <div class="card-body my_courses_button">
                <h2>{{myCourseDetail.name}}</h2>
                <p>{{myCourseDetail.short_description}}</p>
                <div class="button_sec">
                <a class="btn btn-dark my_courses" (click)="courseOverview(myCourseDetail)">Go To Course</a>
              </div>
              </div>
              <div class="cource_sta text-right border-top">
                <div class="progress">
                  <mat-progress-bar mode="determinate" value="{{myCourseDetail.completed_percentage}}">
                  </mat-progress-bar>
                </div>
                <h6>{{myCourseDetail.completed_percentage}}% - Completed</h6>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

</section>