import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DistrictAddComponent } from './district-add.component';
import { DistrictAddRoutingModule } from './district-add-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DistrictAddComponent],
  imports: [
    CommonModule,
    DistrictAddRoutingModule,
    FormsModule, ReactiveFormsModule
   ]
})
export class DistrictAddModule { }
