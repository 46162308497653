import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StateComponent } from './state.component';
import { StateRoutingModule } from './state-routing.module';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@NgModule({
  declarations: [StateComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    StateRoutingModule,NgxPaginationModule,NgxDatatableModule
  ]
})
export class StateModule { }
