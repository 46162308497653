import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscussionForumComponent } from './discussion-forums/discussion-forum.component';
import {DiscussionRoutingModule} from './discussion-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddForumComponent } from './add-forum/add-forum.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewForumComponent } from './view-forum/view-forum.component';




@NgModule({
  declarations: [DiscussionForumComponent, AddForumComponent, ViewForumComponent],
  imports: [
    CommonModule,
    DiscussionRoutingModule,
    NgxPaginationModule,
    NgxDatatableModule,
    FormsModule, 
    ReactiveFormsModule
  ]
})
export class DiscussionForumModule { }
