<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/eligibility-test/list']">Eligibility Test</a></li>
            <li class="breadcrumb-item active">Add Eligibility Test</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>Add Eligibility Test</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="eligibilityForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-md-6 float-left">
                    <div class="form-group">
                      <label>Course *</label>
                      <select class="form-control select2 selact_" formControlName="course_id"
                        style="width: 100%;" [ngClass]="{ 'is-invalid': submitted && f.course_id.errors }">
                        <option value="">Select Course</option>
                        <option *ngFor="let cource of CourceList" value="{{cource.id}}">{{cource.course_name}}</option>

                      </select>
                      <div *ngIf="submitted && f.course_id.errors" class="invalid-feedback">
                        <div *ngIf="f.course_id.errors.required">Course is
                          required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 float-right">
                    <div class="form-group">
                      <label>Title *</label>
                      <input type="text" maxlength = "50" formControlName="title" class="form-control selact_" placeholder="Title"
                        [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                      <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required">Title is
                          required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

               <div class="row">
                <div class="col-md-6 float-left">
                  <div class="form-group">
                    <label>Welcome Text *</label>
                    <textarea formControlName="welcome_text" class="form-control selact_" placeholder="Welcome Text"
                      [ngClass]="{ 'is-invalid': submitted && f.welcome_text.errors }"></textarea>
                    <div *ngIf="submitted && f.welcome_text.errors" class="invalid-feedback">
                      <div *ngIf="f.welcome_text.errors.required">Welcome Text
                        required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 float-right">
                  <div class="form-group">
                    <label>No. of Questions *</label>
                    <input type="number" formControlName="no_of_questions" class="form-control selact_" placeholder="No. of Questions"
                      [ngClass]="{ 'is-invalid': submitted && f.no_of_questions.errors }">
                    <div *ngIf="submitted && f.no_of_questions.errors" class="invalid-feedback">
                      <div *ngIf="f.no_of_questions.errors.required">No. of questions
                        required
                      </div>
                      <div *ngIf="f.no_of_questions.errors.pattern">Invalid No.of questions
                      </div>
                    </div>
                  </div>
                </div>
               </div>

                <div class="row">
                  <div class="col-md-6 float-left">
                    <div class="form-group">
                      <label>Grade Per Question *</label>
                      <input type="number" formControlName="grade_per_question" class="form-control selact_" placeholder="Grade Per Question"
                        [ngClass]="{ 'is-invalid': submitted && f.grade_per_question.errors }">
                      <div *ngIf="submitted && f.grade_per_question.errors" class="invalid-feedback">
                        <div *ngIf="f.grade_per_question.errors.required">Grade Per Question
                          required
                        </div>
                        <div *ngIf="f.grade_per_question.errors.pattern">Invalid Grade Per Question
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 float-right">
                    <div class="form-group">
                      <label>Pass Percentage *</label>
                      <input type="number" formControlName="pass_percentage" class="form-control selact_" placeholder="Pass Percentage"
                        [ngClass]="{ 'is-invalid': submitted && f.pass_percentage.errors }">
                      <div *ngIf="submitted && f.pass_percentage.errors" class="invalid-feedback">
                        <div *ngIf="f.pass_percentage.errors.required">Pass Percentage
                          required
                        </div>
                        <div *ngIf="f.pass_percentage.errors.pattern">Invalid Pass Percentage
                        </div>
                        <div *ngIf="f.pass_percentage.errors.max">Pass Percentage should not be greater than 100
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 float-left">
                    <div class="form-group">
                      <label>Success Feedback *</label>
                      <textarea formControlName="success_feedback" class="form-control selact_" placeholder="Success Feedback"
                        [ngClass]="{ 'is-invalid': submitted && f.success_feedback.errors }"></textarea>
                      <div *ngIf="submitted && f.success_feedback.errors" class="invalid-feedback">
                        <div *ngIf="f.success_feedback.errors.required">Success Feedback
                          required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 float-right">
                    <div class="form-group">
                      <label>Failure Feedback *</label>
                      <textarea formControlName="failure_feedback" class="form-control selact_" placeholder="Failure Feedback"
                        [ngClass]="{ 'is-invalid': submitted && f.failure_feedback.errors }"></textarea>
                      <div *ngIf="submitted && f.failure_feedback.errors" class="invalid-feedback">
                        <div *ngIf="f.failure_feedback.errors.required">Failure Feedback
                          required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 float-left">
                    <div class="form-group">
                      <label>Time Limit (In minutes) *</label>
                      <input type="number" formControlName="time_limit" class="form-control selact_" placeholder="Time Limit"
                        [ngClass]="{ 'is-invalid': submitted && f.time_limit.errors }">
                      <div *ngIf="submitted && f.time_limit.errors" class="invalid-feedback">
                        <div *ngIf="f.time_limit.errors.required">Time Limit
                          required
                        </div>
                        <div *ngIf="f.time_limit.errors.pattern">Invalid Time Limit
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 float-right">
                    <div class="form-group">
                      <label>Status *</label>
                      <select class="form-control select2 selact_" formControlName="status_id"
                        style="width: 100%;" [ngClass]="{ 'is-invalid': submitted && f.status_id.errors }">
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="2">Disabled</option>
                      </select>
                      <div *ngIf="submitted && f.status_id.errors" class="invalid-feedback">
                        <div *ngIf="f.status_id.errors.required">Status is
                          required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
</div>
