<div class="wrapper">
  <!-- Navbar -->

  <!-- /.navbar -->
  <!-- Main Sidebar Container -->

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item active">FAQ</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="card">
        <div class="card-header">
          <h1>FAQ</h1>
        </div>
        <!-- /.card-header -->
        <!-- <div class="card-body">

          <div class="clearfix"></div>
          
          <span class="col-md-3 float-left"><label>Search:</label><input type="text" placeholder="Type to filter"
              class="form-control form-control-sm" (keyup)="updateFilter($event)"></span>
          <a [routerLink]="['/add-faq']" class=" btn btn-secondary btn-dark float-right add_country"><i
              class="fas fa-plus mr-2"></i>Add FAQ</a>
          
        </div> -->
        <!-- <div class="card-body pb-0">
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <input type="text" placeholder="Type to filter" class="form-control form-control-sm"
              (keyup)="updateFilter($event)" autocomplete="off">
          </span>
          <a [routerLink]="['/admin/add-faq']" class="btn btn-secondary btn-dark float-right add_country"><i
              class="fas fa-plus mr-2"></i>Add FAQ</a>
        </div> -->
        <form [formGroup]="faqForm">

          <div class="card-body pb-0">
            <!-- <span class="col-md-2 col-sm-4 float-left pl-0">
              <input type="text" formControlName="search" class="form-control selact_"
                     placeholder="Search text">
            </span>
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <button class="btn btn-dark ml-2 float-left" type="submit"> <i class="fas fa-search mr-1"></i> Search </button>
            </span> -->
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <select class="form-control select2  ml-1" formControlName="categoryType" (change)="onCategoryTypeFilter()" style="width:100%;">
                <option selected="selected" [value]="0">Select Category</option>
                <option *ngFor="let items of categoryList" [value]="items.category_id">{{items.category_name}}</option>
              </select>
            </span>
            <a [routerLink]="['/admin/add-faq']" class="btn btn-secondary btn-dark float-right add_country">
              <i class="fas fa-plus mr-2"></i>Add FAQ
            </a>
          </div>
        </form>
        <div class="card-body table-responsive">

          <ngx-datatable #myTable class="material striped" [rows]="faqList" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [rowHeight]="50" [limit]="10" [sorts]="[{prop: 'name', dir: 'desc'}]"
            [selected]="selected" [selectionType]="'checkbox'" (select)="onSelect($event)" (page)="setPagetest($event)">
            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
              [resizeable]="false">
              <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn">
                <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
              </ng-template>
              <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn">
                <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Sl No" [sortable]="false">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="300" name="Question" prop="question">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.question}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Category" prop="category_name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.category_name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Action" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                 <a href="Javascript:void(0);" (click)="editFaq(row.id)"
                  class="edit_button mr-2"><i class="far fa-edit"></i></a> <a href="Javascript:void(0);" (click)="deleteFaq(row.id)"
                  class="close_button"><i class="far fa-times-circle"></i></a></ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</div>
