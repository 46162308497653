
<body class="hold-transition login-page">
    <div class="login-box"> 
      <!-- /.login-logo -->
      <div class="card border_login">
        <div class="card-body login-card-body">
          <div class="login-logo"> <a href="#"><img src="../../assets/img/logo_login.png"></a> </div>
          <p class="login-box-msg">Login</p>
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="input-group mb-3">
              <div class="input-group-append">
                <div class="input-group-text"> <span class="far fa-user"></span> </div>
              </div>
              <input type="email" class="form-control"  formControlName="username" placeholder="Email">
            </div>
            <div class="input-group mb-3 margin-bottom_">
              <div class="input-group-append">
                <div class="input-group-text"> <span class="fas fa-lock"></span> </div>
              </div>
              <input type="password" class="form-control" formControlName="password" placeholder="Password">
            </div>
            <div class="row">
              <div class="col-12">
                <div class="icheck-primary">
                  <p class="mb-1"> <a  [routerLink]="['/admin/login/forgotpassword']">Forgot Password</a> </p>
                </div>
              </div>
              <div *ngIf="isError" class="col-12">
                <p class="error"> Please enter your email ID/Password  </p>
              </div>
              <div *ngIf="isInvalid" class="col-12">
                <p class="error"> {{errormessage}} </p>
              </div>
              <!-- /.col -->
              <div class="col-12">
                <button type="submit" class="btn btn-secondary btn-login">Login <span class="fas fa-angle-right float-right mt-1 mr-3"></span></button>
              </div>
              <!-- /.col --> 
            </div>
          </form>
        </div>
        <!-- /.login-card-body --> 
      </div>
    </div>
    </body>
    