import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConstantsService } from 'src/app/services/constants.service';
import { LocaldataService } from 'src/app/services/localdata.service'
import { EMPTY, Subscription } from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user_id: string;
  s3FilePath: string;
  profile_pic: any;
  clickEventsubscription: Subscription;

  constructor(public authService: AuthService, private router: Router, private LocaldataService: LocaldataService, private constantService: ConstantsService) { }

  ngOnInit(): void {
    this.getUser();
    
    this.clickEventsubscription = this.LocaldataService.getUpdatedUser().subscribe(() => {
      this.getUser();
    })
  }

  getUser() {
    this.s3FilePath = this.constantService.s3FilePath;
    this.authService.username = localStorage.getItem('username');
    this.profile_pic = this.s3FilePath + localStorage.getItem('profile_pic');
    
    this.user_id = localStorage.getItem('user_id');
  }

  logout() {
    this.authService.logout();
  }
  editProfile() {
    this.router.navigate(['admin/add-Profile', { dist: `${this.user_id}` }]);

  }
}
