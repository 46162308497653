import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EnumVariableService } from 'src/app/services/enum-variable.service';

@Component({
  selector: 'app-overviews',
  templateUrl: './overviews.component.html',
  styleUrls: ['./overviews.component.css'],
})
export class OverviewsComponent implements OnInit {
  constructor(
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private ToasterService: ToasterService,
    private rote: ActivatedRoute,
    public enumvariable: EnumVariableService
  ) { }

  enrollmentId: any;
  session_id: any;
  myCourseOverviewDetails: any;
  course_name: any;

  ngOnInit(): void {
    this.rote.params.subscribe((params) => {
      this.enrollmentId = params['enrollment_id'];
    });
    this.session_id = localStorage.getItem('access_token');
    this.getmyCourseOverview();
  }

  getmyCourseOverview() {
    
    this.spinner.show();
    let param = { enrollment_id: this.enrollmentId };
    let mycourse_fetch_url = 'enroll_course_overview';
    this.api
      .detailsUploadFetch(param, mycourse_fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true' && data.course_overview) {
            
            this.course_name = data.course_name;
            this.myCourseOverviewDetails = data.course_overview;
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'warning');
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }

  toStartVideo(course_content) {
    
    if (course_content.unlock_status) {
      this.router.navigate(['mycourses/myClass/' + this.enrollmentId + '/' + course_content.enrollment_course_content_id + '/' + course_content.slug,]);
    } else {
      this.ToasterService.openSnackBar(
        course_content.course_content_type_name + ' content  will be available after ' + course_content.unlock_date,
        '',
        'warning'
      );
    }
  }

  onStartAssessment(course_content){
    this.router.navigate(['mycourses/myClass/' + this.enrollmentId + '/' + course_content.enrollment_course_content_id + '/' + course_content.slug,]);
  }

  onStart(content) {
    
    if (content.unlock_status) {
      this.router.navigate(['mycourses/myClass/' + this.enrollmentId + '/' + content.enrollment_course_content_id + '/' + content.slug,]);
    } else {
      this.ToasterService.openSnackBar(
        content.course_content_type_name + ' content  will be available after ' + content.unlock_date,
        '',
        'warning'
      );
    }
  }
}
