import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from '../../../models/pageDto';

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.css']
})
export class BatchListComponent implements OnInit {
  batchForm: FormGroup;

  @ViewChild('myTable') table: BatchListComponent;
  session_id: any;
  searchTerm: any = '';
  selected = [];
  temp = [];
  batchList: any = [];
  page: Page;
  courseList: any = [];
  dropdownSettings: any = {};
  deleteList: any = [];

  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private popup: PopupService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.batchForm = this.formBuilder.group({
      search: ['']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    this.dropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "course_name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: "All",
      allowSearchFilter: true,
    };
    this.getCourses();
    this.setPage({ offset: 0 });
    //this.init();
  }

  onSearch() {
    this.searchTerm = this.batchForm.get('search').value;
    debugger
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }


  setPage(pageInfo) {
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.batchList = [];
    var batch_fetch_url = "get_batches?per_page_count=" + this.page.per_page + "&page=" + this.page.current_page;
    if (this.searchTerm.length > 0){
      debugger
      batch_fetch_url += "&course_id=" + this.searchTerm[0].id;
    }
      
    this.api.detailsGetFetch(batch_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.batches) {
        this.temp = data.batches;
        this.batchList = data.batches;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
        debugger
        this.spinner.hide();

      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSelect({ selected }) {

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  deletebatch(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        var body = { "batch_id": +id };
        var del_url = "delete_batch?batch_id=" + `${id}`;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup();
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }

  deleteMultipleItem(){
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteList.push(element.id);
      });
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          var body = { "ids": this.deleteList };
          var del_url = "batch_multi_delete";
          this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
            debugger
            this.deleteList = [];
            if (data.status == "True") {
              this.spinner.hide();
              this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
              this.setPage({ offset: 0 });
            }
            else {
              this.spinner.hide();
              this.popup.failureMessage = data.message
              this.popup.failurepopup()
            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
  
                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            }); 
        }else{
          this.deleteList = [];
        }
      })
    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    } 
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || status_name;
    });

    this.batchList = temp;
  }
  editbatch(id) {
    this.router.navigate(['admin/batch/edit/' + id]);

  }

  getCourses() {
    this.courseList = [];
    var batch_fetch_url = "getBatchWiseCourselist";
    this.api.detailsGetFetch(batch_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data) {
        this.courseList = data.courses;
        debugger
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }

  onItemSelect(item: any) {
    console.log("onItemSelect", item);
  }

  onSelectAll(items: any) {
    console.log("onSelectAll", items);
  }

}
