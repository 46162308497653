import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-instructor-details',
  templateUrl: './instructor-details.component.html',
  styleUrls: ['./instructor-details.component.css']
})
export class InstructorDetailsComponent implements OnInit {

  constructor(public api: ApiService, private spinner: NgxSpinnerService, private LocaldataService: LocaldataService, private router: Router, private rote: ActivatedRoute, private constantService: ConstantsService) { }

  instructorSlug: any;
  instructorFirstName: any;
  instructorLastName: any;
  instructorEmail: any;
  instructorProfilePic: any;
  instructorQualification: any;
  courseList: any = [];
  currencyObj: any;
  currencyId: number = null;
  selectedpage: number = 1;
  courses = [];
  s3FilePath: string;
  bio: string;

  ngOnInit(): void {
    this.spinner.show();
    this.instructorSlug = this.rote.snapshot.paramMap.get("instructor_slug");
    this.s3FilePath = this.constantService.s3FilePath;
    this.getInstructorDetailById();
    // this.getCourses();

  }

  getInstructorDetailById() {
    var list_fetch_url = "student/getInstructor?slug=" + this.instructorSlug;
    this.api.detailsGet(list_fetch_url).subscribe((data: any) => {
      if (data.status == "true" && data) {
        this.spinner.hide();
        debugger
        this.instructorFirstName = data.instructor.first_name
        this.instructorLastName = data.instructor.last_name;
        this.instructorEmail = data.instructor.email;
        this.instructorQualification = data.instructor.qualification;
        this.instructorProfilePic = data.instructor.profile_pic;

        var str = data.instructor.sme_description;
        var re = /\n/gi; 
        var newstr = str.replace(re, "<br />"); 
        console.log(newstr)
        this.bio = newstr;
        
        this.courses = data.instructor.courses;
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });

  }
  getCourceParam() {
    return { currency_id: null, per_page_count: null, page: null, category_id: null, category_slug: null }
  }
  getCourceList() {
    return { id: null, title: null, description: null }
  }
  getCourses() {
    debugger
    this.spinner.show();
    var list_fetch_url = "student/course_list?";
    let searchTerm = this.getCourceParam();
    this.currencyObj = this.LocaldataService.getcurrencyInfo();
    this.currencyId = this.currencyObj.id;
    searchTerm.currency_id = this.currencyId;
    searchTerm.per_page_count = 6
    searchTerm.page = this.selectedpage;
    // searchTerm.category_slug= this.categorySlug;

    // searchTerm.category_id = this.categoryId;

    this.api.postDetails(list_fetch_url, searchTerm).subscribe((data: any) => {
      if (data.status == "true" && data) {
        debugger
        this.courses = data.courses;
        let i;
        for (i = 0; i < this.courses.length - 1; i++)
          console.log(this.courses[i].cost)
        // this.totalPages = data.pagination.total_pages;
        // this.initialselectedPage = data.pagination.current_page;
        // this.startIndex = 1;
        // if (data.pagination.total > this.endIndex) {

        //   // this.startIndex = data.pagination.total - this.endIndex;

        // }
        // this.generatePageNumber(this.startIndex, data.pagination.total)
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        // localStorage.clear()
        // this.router.navigate([''])

      }
    });
  }

  courseSelect(course) {
    debugger
    this.router.navigate(['course/' + course.slug])
    // if (course.slug) {

    //   let course_selecting_url = 'course/' + course.slug;
    //   window.open(course_selecting_url, '_blank')
    // }

  }



}
