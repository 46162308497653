import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateAddComponent } from './state-add.component';
import { StateAddRoutingModule } from './state-add-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 
@NgModule({
  declarations: [StateAddComponent],
  imports: [
    CommonModule,
    StateAddRoutingModule,
    FormsModule, ReactiveFormsModule
   ]
})
export class StateAddModule { }
