import { Component, OnInit,ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, Route ,ActivatedRoute, Event,  NavigationEnd} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { Page } from '../../models/pageDto';
declare var $: any;
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.css']
})
export class DistrictComponent implements OnInit {
  @ViewChild('myTable') table: DistrictComponent;

  session_id: string;
  selected=[];
  temp=[];
  countryList:any=[];
  stateList:any=[];
  p: number = 1;
  districtList:any=[];
  countryVal:any;
  stateVal: any;
  searchTerm: any = '';
  filterCountry: any = ''
  filterState: any = ''
  page: Page;
  deleteList: any = [];
  constructor(public api: ApiService, private router: Router, private formBuilder: FormBuilder, private popup: PopupService,private spinner: NgxSpinnerService) { }
  districtForm: FormGroup;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.getStates();
    // this.getCountry();
    this.districtForm = this.formBuilder.group({
      search: [''],
      stateType: ['0'],
      countryType: ['0']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    this.setPage({ offset: 0 });
  }
  get g() { return this.districtForm.controls; }

  onSearch() {
    this.searchTerm = this.districtForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

   onStateTypeFilter() {
     debugger
    this.filterState = this.districtForm.get('stateType').value > 0 ? this.districtForm.get('stateType').value.toString() : '';
    this.setPage({ offset: 0 });
  }

  

  // onCountryTypeFilter() {
  //   this.filterCountry = this.districtForm.get('countryType').value > 0 ? this.districtForm.get('countryType').value.toString() : '';
  //   this.setPage({ offset: 0 });
  // }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  setPage(pageInfo) {
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.districtList = [];

    var state_fetch_url = "district?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;

    // if (this.filterCountry.length > 0)
    //   state_fetch_url += "&country_id=" + this.filterCountry;

    if (this.filterState.length > 0)
      state_fetch_url += "&state_id=" + this.filterState + "&status_id=1";

    if (this.searchTerm.length > 0)
      state_fetch_url += "&searchTerm=" + this.searchTerm;

    this.api.detailsGetFetch(state_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.districts[0]) {
        debugger
        this.spinner.hide();

        this.temp = [...data.districts];
        this.districtList = data.districts;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getStates() {
    this.spinner.show();
    this.stateList = [];
    var list_fetch_url = "state?status_id=1";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.states[0]) {
        this.stateList = data.states;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate([''])
      }
    });
  }

  // getCountry(){
  //   this.spinner.show();
  //   this.countryList = [];
    
  //   var countries_fetch_url = "country";
  //   this.api.detailsGet(countries_fetch_url).subscribe((data: any) => {
  //     if (data.status == "true" && data.countries[0]) {
  //       this.countryList = data.countries;
  //       this.spinner.hide();

  //     }
  //     else {
  //       this.spinner.hide();
  //     }
  //   }, (err: HttpErrorResponse) => {
  //     if (err.status == 403) {
  //       this.spinner.hide();
  //       localStorage.clear()
  //       this.router.navigate([''])
  //     }
  //   });
  // }

  //getState(event){
  //  console.log(event.target.value)
  //  var body = { "state_id": event.target.value };
  //  var search_url = "state?country_id=" + `${event.target.value}`;
  //  this.stateList = [];
  //  this.spinner.show();

  //  this.api.detailsFetch(body, search_url).subscribe((data: any) => {
  //    console.log(data)
  //    if (data.status == "true") {
  //      this.spinner.hide();

  //      this.stateList = data.State;
  //    }
  //    else {
  //      this.spinner.hide();

  //    }
  //  },
  //    (err: HttpErrorResponse) => {
  //      if (err.status == 403) {
  //        this.spinner.hide();

  //        localStorage.clear()
  //        this.router.navigate(['login'])
  //      }
  //    });

  //}
  //onSearch(){
  //  if (this.searchForm.invalid) {
  //    return;
  //  }
  //  this.spinner.show();

  //  var body = { "state_id": +this.g.state.value };
  //  this.countryVal=+this.g.country.value;
  //  this.stateVal=+this.g.state.value;
  //  var search_url = "district?state_id=" + `${this.g.state.value}`;
  //  this.districtList = [];
  //  this.api.detailsFetch(body, search_url).subscribe((data: any) => {
  //    console.log(data)
  //    if (data.status == "true") {
  //      this.spinner.hide();
  //      this.temp=data.District;
  //      this.districtList = data.District;
  //    }
  //    else {
  //      this.spinner.hide();

  //    }
  //  },
  //    (err: HttpErrorResponse) => {
  //      if (err.status == 403) {
  //        this.spinner.hide();

  //        localStorage.clear()
  //        this.router.navigate(['login'])
  //      }
  //    });

  //}
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  deleteDistrict(id) {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        var body = { "course_category_id": +id };
        var search_url = "delete_district?district_id=" + id;
        this.api.detailsFetchDel(body, search_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
 
            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
             this.districtList = [];
            this.setPage({ offset: 0 });
        
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }

  deleteMultipleItem(){
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteList.push(element.id);
      });
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          var body = { "ids": this.deleteList };
          var del_url = "district_multi_delete";
          this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
            debugger
            this.deleteList = [];
            if (data.status == "True") {
              this.spinner.hide();
              this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
              this.setPage({ offset: 0 });
            }
            else {
              this.spinner.hide();
              this.popup.failureMessage = data.message
              this.popup.failurepopup()
            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
  
                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            }); 
        }else{
          this.deleteList = [];
        }
      })
    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    } 
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      const state_name = d.state_name.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || status_name || state_name;
    });

    // update the rows
    this.districtList = temp;
    // this.table.offset = 0;
  }

  editDistrict(id){
    this.router.navigate(['admin/add-district',{dist:`${id}`}]);
  }
}
