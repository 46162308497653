import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title, Meta } from "@angular/platform-browser";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ConstantsService } from '../../../services/constants.service';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.css']
})
export class ChatWindowComponent implements OnInit {

  messageForm: FormGroup;
  message: any = null;
  session_id: string;
  userId: any;
  student_id: any;
  chatHistory: any = [];
  chatWithProfilePic: any;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private constantService: ConstantsService,
    private spinner: NgxSpinnerService
  ) {
    this.messageForm = this.formBuilder.group({
      message: ['', [Validators.required, Validatespace]]

    });
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.onSubmit();
  }

  ngOnInit() {

    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.userId = localStorage.getItem('user_id');

    this.route.params.subscribe(params => {
      this.student_id = params['student_id'];
      this.messageForm.reset();
      this.getChatHistory();
    });

  }

  getChatHistory() {
    this.spinner.show();
    let param = {
      userID: this.userId, chatWithID: this.student_id
    };
    let mycourse_fetch_url = 'getChatHistory';
    this.api
      .detailsUploadFetch(param, mycourse_fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true') {
            this.chatWithProfilePic = data.chatWithProfilePic;
            this.chatHistory = data.chatHistory;
            this.spinner.hide();
          } else {
            this.spinner.hide();

          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  onSubmit() {
    debugger
    if (this.messageForm.invalid) {
      return;
    }
    this.spinner.show();
    let param = {
      userID: this.userId, chatWithID: this.student_id, message: this.messageForm.value.message
    };
    let mycourse_fetch_url = 'saveChatMessage';
    this.api
      .detailsUploadFetch(param, mycourse_fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true') {
            this.messageForm.reset();
            this.getChatHistory();
            this.spinner.hide();
          } else {
            this.spinner.hide();

          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            this.router.navigate(['']);
          }
        }
      );
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

}
