<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/banner']">Banners</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add Banner</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit Banner</li>

          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add Banner</h1>
        <h1 *ngIf="isEdit">Edit Banner</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="bannerForm" (ngSubmit)="onSubmit()">
                <div class="col-md-6 float-left">
                  <div class="form-group">
                    <label>Banner *</label>
                    <input type="text" formControlName="bannerText" class="form-control selact_" placeholder="Banner"
                      [ngClass]="{ 'is-invalid': submitted && f.bannerText.errors }">
                    <div *ngIf="submitted && f.bannerText.errors">
                      <small class="text-danger">
                        Banner is required
                      </small>
                    </div>
                    <!-- <div *ngIf="submitted && f.bannerText.errors" class="invalid-feedback">
                                    <div *ngIf="f.bannerText.errors.required">
                                      Banner is
                                      required
                                    </div>
                                  </div> -->
                  </div>
                  <div class="form-group">
                    <label>Link *</label>
                    <input type="text" formControlName="link" class="form-control selact_" placeholder="Link"
                      [ngClass]="{ 'is-invalid': submitted && f.link.errors }">
                    <!-- <div *ngIf="submitted && f.link.errors" class="invalid-feedback">
                                    <div *ngIf="f.link.errors.required">
                                      Link is
                                      required
                                    </div>
                                  </div> -->
                    <div *ngIf="submitted && bannerForm.get('link').invalid ">
                      <small *ngIf="hasError('link','required')" class="text-danger">
                        Link is required
                      </small>
                      <small *ngIf="submitted && hasError('link','whitespace')" class="text-danger">
                        Link is required
                      </small>
                      <small *ngIf="hasError('link','pattern')" class="text-danger">
                        Invalid URL (eg : https://www.google.com/)
                      </small>
                      <small *ngIf="hasError('link','invalidLink')" class="text-danger">
                        Please add valid url
                      </small>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Description *</label>
                    <!-- <angular-editor [config]="editorConfig" formControlName="description"
                                                  placeholder="Enter Description"
                                                  [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></angular-editor> -->
                    <ckeditor formControlName="description" [config]="ckeConfig"></ckeditor>
                    <div *ngIf="submitted && f.description.errors" class="text-danger">
                      <small *ngIf="f.description.errors.required">
                        Description is
                        required
                      </small>
                      <small *ngIf="f.description.errors.validspace">
                       Invalid Description
                      </small>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Status *</label>
                    <select formControlName="status" class="form-control select2 selact_" style="width: 100%;"
                      [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                      <option value="1">Active</option>
                      <option value="2">Disabled</option>
                    </select>
                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                      <div *ngIf="f.status.errors.required">
                        Status is
                        required
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="clearfix"></div> -->
                <div class="col-md-4 float-left">
                  <div class="form-group">
                    <label>Web Image *</label>
                    <div class="uploadedImg">
                      <input type="file" accept="image/*" class="mediaFile" formControlName="webFileData"
                        id="exampleInputFile" (change)="onWebFileChanged($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.webFileData.errors }">
                      <span class="up_img">Upload Web image</span>

                      <img [src]="webImageSrc" *ngIf="webImageSrc">
                    </div>
                    <div *ngIf="submitted && f.webFileData.errors" class="text-danger">
                      <small *ngIf="f.webFileData.errors.required">Web Image is
                        required
                      </small>
                    </div>
                    <div class="note_a p-3">
                      <label>* Preffered image resolution 1600x640</label>
                    </div>
                  </div>

                </div>
                <div class="col-md-4 float-left">
                  <div class="form-group">
                    <label>Mobile Image *</label>
                    <div class="uploadedImg">
                      <input type="file" accept="image/*" class="mediaFile" formControlName="mobileFileData"
                        id="exampleInputFile" (change)="onMobileFileChanged($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.mobileFileData.errors }">
                      <span class="up_img">Upload Mobile image</span>

                      <img [src]="mobileImageSrc" *ngIf="mobileImageSrc">
                    </div>
                    <div *ngIf="submitted && f.mobileFileData.errors" class="text-danger">
                      <small *ngIf="f.mobileFileData.errors.required">Mobile Image is
                        required
                      </small>
                    </div>
                    <div class="note_a p-3">
                      <label>* preffered image resolution 1600x640</label>
                    </div>
                  </div>

                </div>


                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>
