import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../guards/auth.guard';

import { BatchEditComponent } from './batch-edit/batch-edit.component';
import { BatchListComponent } from './batch-list/batch-list.component';
import { BatchAddComponent } from './batch-add/batch-add.component';

const routes: Routes = [
  {
    path: '',
    component: BatchListComponent,
    canActivate : [AuthGuard],
    data: { title: 'Batches | Admin ' }
  },
  {
    path: 'add',
    component:  BatchAddComponent,
    canActivate : [AuthGuard],
    data: { title: 'Add Batches | Admin ' }
  },
  {
    path: 'edit/:id',
    component: BatchEditComponent,
    canActivate : [AuthGuard],
    data: { title: 'Edit Batches | Admin ' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BatchesRoutingModule { }
