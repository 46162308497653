<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-sm-left">
                        <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
                        <li class="breadcrumb-item active"><a>Teams</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>
    <!-- Main content -->

    <!-- /.content -->
    <section class="content">
      <div class="card">
        <div class="card-header">
          <h1>Teams</h1>
        </div>

        <form [formGroup]="teamForm" (ngSubmit)="onSearch()">

          <div class="card-body pb-0">
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <input type="text" formControlName="search" class="form-control selact_"
                     placeholder="Search text">
            </span>
            <span class="col-md-2 col-sm-4 float-left pl-0">
              <button class="btn btn-dark ml-2 float-left" type="submit"> <i class="fas fa-search mr-1"></i> Search </button>
            </span>
            <a [routerLink]="['/admin/add-team']" class="btn btn-secondary btn-dark float-right add_team">
              <i class="fas fa-plus mr-2"></i>Add Team
            </a>
          </div>
        </form>

    
        <div class="card-body table-responsive">

          <div>

            <ngx-datatable #myTable class="material striped" [rows]="teamList" [columnMode]="'force'"
                           [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" 
                            [selected]="selected" [selectionType]="'checkbox'"
                           (select)="onSelect($event)" [externalPaging]="true" [count]="page.total" [offset]="page.current_page" [limit]="page.per_page" (page)="setPagetest($event)">
              <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                    [draggable]="false" [resizeable]="false">
                <ng-template ngx-datatable-header-template let-value="value"
                             let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                  <input type="checkbox" [checked]="allRowsSelected"
                         (change)="selectFn(!allRowsSelected)" />
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                             let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Sl No" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                  {{rowIndex+1}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="250" name="Team" prop="name">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.name}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="250" name="Position" prop="position">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.position}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Image" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>

                  <img class="imglen" src="{{s3FilePath + row.image}}" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Sort Order" prop="sort_order">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.sort_order}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Status" prop="status">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.status}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Action" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                 <a href="Javascript:void(0);" (click)="editTeam(row.id)" class="edit_button mr-2">
                    <i class="far fa-edit"></i>
                  </a> <a href="Javascript:void(0);" (click)="deleteTeam(row.id)"
                          class="close_button"><i class="far fa-times-circle"></i></a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
            <a (click)="deleteMultipleItem()" class="btn btn-secondary btn-dark float-right add_country" style="margin-top:2%">
              Delete Selected Items
             </a>
          </div>
        </div>
        <!-- <pagination-controls style="text-align:center;" (pageChange)="p = $event">
  </pagination-controls> -->
        <!-- /.card-body -->
      </div>
        <!-- /.card -->
    </section>
</div>
