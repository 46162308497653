import { Component, OnInit } from '@angular/core';
import { Title, Meta } from "@angular/platform-browser";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ConstantsService } from '../../../services/constants.service';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.css']
})
export class ChatListComponent implements OnInit {

  session_id: string;
  s3FilePath: string;
  studentId: any;
  userId:any;
  chatList: any = [];

  constructor(
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private constantService: ConstantsService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.studentId = params['id'];
    });
    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.s3FilePath = this.constantService.s3FilePath;
    this.userId = localStorage.getItem('user_id');

    this.getChatList();

    this.title.setTitle("Chat | Admin | Al Jamia");
    this.meta.addTag({
      name: "description",
      content: "Chat | Admin",
    });
  }

  getChatList(){
    this.spinner.show();
    let param = { userID: this.userId };
    let fetch_url = 'getChatList';
    this.api
      .detailsUploadFetch(param, fetch_url, this.session_id)
      .subscribe(
        (data: any) => {
          if (data.status == 'true' && data.chatList) {
            this.chatList = data.chatList
            
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();
            //localStorage.clear()
            // this.router.navigate(['']);
          }
        }
      );
  }

}
