<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"> <a [routerLink]="['/admin/state']">States</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add State</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit State</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add State</h1>
        <h1 *ngIf="isEdit">Edit State</h1>

      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="stateForm" (ngSubmit)="onSubmit()" name="myform" id="myForm">
                <div class="col-md-6 float-left">
                  <div class="form-group">
                    <label>State *</label>

                    <input type="text" formControlName="state" class="form-control selact_"
                      placeholder="Enter State Name" >
                    <div *ngIf="submitted && f.state.errors" class="text-danger">
                      <div *ngIf="f.state.errors.required">
                        <small>
                        State is
                        required
                        </small>
                      </div>
                      <div *ngIf="f.state.errors.pattern">
                        <small>
                        Valid state name is
                        required
                        </small>
                    </div>
                    <div *ngIf="f.state.errors.validspace">
                      <small>
                      Invalid state name 
                      </small>
                    </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Country *</label>
                    <select name="country" formControlName="country" id="countySel" size="1"
                      class="form-control select2 selact_" style="width: 100%;" placeholder="Select Country"
                      >
                      <option [value]="0">--Select Country--</option>
                      <option *ngFor="let item of countryList" [ngValue]="item.id">{{item.title}}</option>

                    </select>
                    <!-- <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                      <div *ngIf="f.country.errors.required">Country is
                        required
                      </div>
                    </div> -->
                    <div class="text-danger" *ngIf="submitted && f.country.value==0">
                      <small>
                      Country is
                      required
                      </small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Status *</label>
                    <select class="form-control selact_" formControlName="status" style="width: 100%;"
                      placeholder="Select Status" >
                      <option value="1">Active</option>
                      <option value="2">Disabled</option>
                    </select>
                    <div *ngIf="submitted && f.status.errors" class="text-danger">
                      <div *ngIf="f.status.errors.required">
                        <small>
                        Status is
                        required
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="form-group">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>>
