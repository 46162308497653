import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EnrollStudentsRoutingModule } from './enroll-students-routing.module';
import { EnrollStudentAddComponent } from './enroll-student-add/enroll-student-add.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnrollStudentImportComponent } from './enroll-student-import/enroll-student-import.component';


@NgModule({
  declarations: [EnrollStudentAddComponent, EnrollStudentImportComponent],
  imports: [
    CommonModule,
    EnrollStudentsRoutingModule,
    FormsModule, ReactiveFormsModule,
  ]
})
export class EnrollStudentsModule { }
