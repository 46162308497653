<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12">

      </div>
    </div>
  </div>
</section>
<section class="sub_page_content team_content sign_up_container">
  <div class="container">
    <div class="row">

      <div class="col-md-12 card  my-5 border-0 shadow-sm">
        <div class="card-body">
          <!-- <div class="alert alert-danger text-center" role="alert"> Passwords not match</div>-->
          <h2 class="card-title text-center">{{headingText}}</h2>
          <form class="form-signup" name="signupform" [formGroup]="signUpformGroup" (ngSubmit)="signUp()">
            <!--    <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Sign up with Google</button>
                <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Sign up with Facebook</button>-->
            <hr class="my-3 mt-4 pb-1">
            <div class="col-md-8 float-left">
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Full Name (As in passport or Aadhaar card)*</label>
                <input type="text" id="name" class="form-control" formControlName="userName" placeholder="Name"
                  required>
                <div *ngIf="issubmitted && signUpformGroup.controls.userName.errors" class="error_signup">
                  <div *ngIf="issubmitted && signUpformGroup.controls.userName.errors.required"> Name is required.
                  </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.userName.errors.pattern"> Invalid Name. </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.userName.errors.validspace"> Invalid Name. </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.userName.errors.maxlength"> Invalid Name. </div>
                </div>
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Email Address *</label>
                <input type="email" id="inputEmail" class="form-control" formControlName="emailAddress"
                  placeholder="Email Address">
                <div *ngIf="issubmitted && signUpformGroup.controls.emailAddress.errors" class="error_signup">
                  <div *ngIf="issubmitted && signUpformGroup.controls.emailAddress.errors.required"> Email address is
                    required. </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.emailAddress.errors.pattern"> Invalid Email
                    Address. </div>
                </div>
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Mobile Number (with country code)*</label>
                <input id="phone" name="phone" type="tel" class="form-control" formControlName="phoneNumber"
                  placeholder="eg:91XXXXXXXXXX" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>

                <div *ngIf="issubmitted  && signUpformGroup.get('phoneNumber').invalid ">
                  <small *ngIf="signUpformGroup.get('phoneNumber').errors?.required" class="text-danger">Mobile
                    Number is required</small>
                  <small
                    *ngIf="!signUpformGroup.get('phoneNumber').errors.invalidNumber && (signUpformGroup.get('phoneNumber').minLength || !signUpformGroup.get('phoneNumber').maxLength)"
                    class="text-danger">Mobile number should be between 8-12 numbers</small>

                  <small
                    *ngIf="!signUpformGroup.get('phoneNumber').errors?.required && signUpformGroup.get('phoneNumber').errors.invalidNumber"
                    class="text-danger">Invalid
                    Mobile Number</small>
                </div>
                <!-- <div *ngIf="issubmitted && signUpformGroup.controls.phoneNumber.errors" class="error_signup">
                    <div *ngIf="issubmitted && signUpformGroup.controls.phoneNumber.errors.required"> Phone Number is required. </div>
                    <div *ngIf="issubmitted && signUpformGroup.controls.phoneNumber.errors.minlength"> Invalid Phone Number. Minimum 8 Digit required. </div>
                    
                    <div *ngIf="issubmitted && signUpformGroup.controls.phoneNumber.errors.maxlength"> Invalid Phone Number. Maximum 13 Digit is Possible. </div>
                    <div *ngIf="issubmitted && signUpformGroup.controls.phoneNumber.errors.pattern"> Invalid Phone Number. </div>
                  </div> -->
              </div>
              <!-- <div class="clearfix"></div> -->
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>WhatsApp Number (with country code)</label>
                <input id="whatsAppNumber" name="whatsAppNumber" type="tel" class="form-control" formControlName="whatsAppNumber"
                  placeholder="eg:91XXXXXXXXXX" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>

                <div *ngIf="issubmitted  && signUpformGroup.get('whatsAppNumber').invalid ">
                  <small *ngIf="signUpformGroup.get('whatsAppNumber').errors?.required" class="text-danger">WhatsApp
                    Number is required</small>
                  <small
                    *ngIf="!signUpformGroup.get('whatsAppNumber').errors.invalidNumber && (signUpformGroup.get('whatsAppNumber').minLength || !signUpformGroup.get('whatsAppNumber').maxLength)"
                    class="text-danger">WhatsApp number should be between 8-12 numbers</small>

                  <small
                    *ngIf="!signUpformGroup.get('whatsAppNumber').errors?.required && signUpformGroup.get('whatsAppNumber').errors.invalidNumber"
                    class="text-danger">Invalid
                    WhatsApp Number</small>
                </div>
              </div>
              <div class="clearfix"></div>
              

              <div class="form-label-group mb-2 col-md-6 float-left">
                <label>Date of Birth *</label>
                <input type="date" [max]=todayDate id="birthDate" class="form-control" formControlName="dateOfBirth"
                  placeholder="Date of Birth">
                <div *ngIf="issubmitted && signUpformGroup.controls.dateOfBirth.errors" class="error_signup"> 
                  <div *ngIf="issubmitted && signUpformGroup.controls.dateOfBirth.errors.required"> Date of Birth is required. </div>
                  <div *ngIf="signUpformGroup.get('dateOfBirth').errors?.dateError">
                    Date of Birth must be 10 year lesser than the current date
                  </div>
                </div>
                <!-- <div *ngIf="issubmitted && signUpformGroup.get('dateOfBirth').invalid">
                  
                  
                </div> -->
              </div>

              <div class="form-label-group mb-2  col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Educational Qualification *</label>
                <!-- <select class="form-control" formControlName="qualification" (change)="qualificationsChange()">
                  <option value="">--Select Educational Qualification--</option>
                  <option value="{{option.id}}" *ngFor="let option of qualifications">{{option.title}}</option>
                </select> -->
                <input type="text" id="qualification" class="form-control" formControlName="qualification" placeholder="Qualification"
                required>
                <div *ngIf="issubmitted && signUpformGroup.controls.qualification.errors" class="error_signup">
                  Qualification is required.
                </div>
                <!-- <div *ngIf="issubmitted && signUpformGroup.controls.qualification.value==0" class="error_signup"> Select
                  Qualification. </div> -->
              </div>


              <!-- <div class="clearfix"></div>
              <hr class="mt-4 pb-1">
              <div class="clearfix"></div> -->
             
              <!-- <div class="form-label-group mb-2  col-md-6 float-left" *ngIf="!isOtherQualification">
                <label>Stream *</label>
                <select class="form-control" formControlName="stream" (change)="streamChange()">
                  <option value="">--Select Stream--</option>
                  <option value="{{option.id}}" *ngFor="let option of filterstreams">{{option.title}}</option>
                </select>
                <div *ngIf="issubmitted && signUpformGroup.controls.stream.errors" class="error_signup"> Stream is
                  required.
                </div>
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left" *ngIf="isOtherQualification">

                <label>Enter Your Qualification and Stream *</label>
                <input type="text" id="name" class="form-control" formControlName="otherQualification" placeholder="Qualification Name"
                  required>
                <div *ngIf="issubmitted && signUpformGroup.controls.otherQualification.errors" class="error_signup">
                  <div *ngIf="issubmitted && signUpformGroup.controls.otherQualification.errors.required">Qualification and Stream is required.
                  </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.otherQualification.errors.validspace"> Invalid Qualification and Stream. </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.otherQualification.errors.maxlength"> Invalid Qualification and Stream. </div>
                </div>
              </div> -->

              <!-- <div class="clearfix"></div>
              <div class="form-label-group mb-2  col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Percentage Score *</label>
                <input type="number" min="0" id="score" class="form-control" formControlName="score" placeholder="Score"
                  required>
                <div *ngIf="issubmitted && signUpformGroup.controls.score.errors" class="error_signup"> 
                  <div *ngIf="issubmitted && signUpformGroup.controls.score.errors.required"> Score is required. </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.score.errors.pattern"> Invalid Percentage Score </div>
                </div>
              </div> -->

              <hr class="mt-4 pb-1">
              <div class="form-label-group mb-2  col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Postal Address *</label>
                <textarea id="address" name="address" cols="30" rows="3" class="form-control" placeholder="Postal Address"
                  formControlName="address"></textarea>
                <div *ngIf="issubmitted && signUpformGroup.controls.address.errors" class="error_signup">
                  <div *ngIf="issubmitted && signUpformGroup.controls.address.errors.required">Postal Address is required.
                  </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.address.errors.validspace"> Invalid Postal Address.
                  </div>
                </div>
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Nationality </label>
                <select class="form-control" formControlName="nationality">
                  <option [ngValue]="0">--Select Nationality--</option>
                  <option [ngValue]="option" *ngFor="let option of countries">{{option.country_name}}</option>
                </select>
                <!-- <div *ngIf="issubmitted && signUpformGroup.controls.nationality.errors" class="error_signup"> Nationality is
                  required.
                </div>
                <div *ngIf="issubmitted && signUpformGroup.controls.nationality.value==0" class="error_signup"> Select
                  Nationality. </div> -->
              </div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Country of Residence *</label>
                <select class="form-control" formControlName="country">
                  <option [ngValue]="0">--Select Country--</option>
                  <option [ngValue]="option" *ngFor="let option of countries">{{option.country_name}}</option>
                </select>
                <div *ngIf="issubmitted && signUpformGroup.controls.country.errors" class="error_signup"> Country is
                  required.
                </div>
                <div *ngIf="issubmitted && signUpformGroup.controls.country.value==0" class="error_signup"> Select
                  Country. </div>
              </div>
              <!-- <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>State </label>
                <select class="form-control" formControlName="state" (change)="stateChange()">
                  <option [ngValue]="0">--Select State/Emirate/Province--</option>
                  <option [ngValue]="option" *ngFor="let option of filterstates">{{option.state_name}}</option>
                </select>
               
              </div>
              <div class="clearfix"></div>
              <div class="form-label-group mb-2 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>District </label>
                <select class="form-control" formControlName="district" (change)="districtChange()">
                  <option [ngValue]="0">--Select District--</option>
                  <option [ngValue]="option" *ngFor="let option of filterdistricts">{{option.title}}</option>
                </select>
                
              </div> -->
              <hr class="mt-4 pb-1">
              <div class="form-label-group mb-3 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Password *</label>
                <div class="input-group mb-0" id="show_hide_password">
                  <input type="password" [type]="passwordHide ? 'password':'text'" class="form-control"
                    placeholder="Password" formControlName="Password">
                  <div class="input-group-append"> <span class="input-group-text" id="basic-addon2"
                      (click)="passwordHide = !passwordHide"><img
                        src="../../../assets/web/assets/images/password.svg"></span> </div>
                </div>
                <label>Note: You can select any password of your choice, not necessarily the password used for your official email.</label>
                <div *ngIf="issubmitted && signUpformGroup.controls.Password.errors" class="error_signup">
                  <div *ngIf="issubmitted && signUpformGroup.controls.Password.errors.required"> Password is required.
                  </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.Password.errors.minlength"> Minimum 6 Characters
                    required. </div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.Password.errors.maxlength"> Maximum 20 Characters
                    is Possible. </div>
                </div>
              </div>

              <div class="form-label-group mb-3 col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Confirm Password *</label>
                <div class="input-group mb-0" id="show_hide_password">
                  <input type="password" [type]="confirmPasswordHide ? 'password':'text'" class="form-control"
                    placeholder="Confirm password" formControlName="confirmPassword">
                  <!-- <div class="input-group-append"> <span class="input-group-text" id="basic-addon2" (click)="confirmPasswordHide = !confirmPasswordHide"><img
                          src="../../../assets/web/assets/images/password.svg"></span> </div> -->
                </div>
                <div *ngIf="issubmitted && signUpformGroup.controls.confirmPassword.errors" class="error_signup">
                  <div *ngIf="issubmitted && signUpformGroup.controls.confirmPassword.errors.required"> Confirm Password
                    is required. </div>

                  <div *ngIf="issubmitted && signUpformGroup.controls.confirmPassword.errors.mustMatch">Password and
                    Confirm Password is not Matching. </div>
                </div>
              </div>

              <!-- <div class="clearfix"></div>
              <hr class="mt-4 pb-1">
              <div class="clearfix"></div>
              <div *ngIf="!isedit" class="form-label-group mb-2  col-sm-12 col-md-6 col-lg-6 float-left">

                <div class="custom-control custom-checkbox  mr-3">

                  <input class="custom-control-input" type="checkbox" id="terms_and_condition"
                    formControlName="terms_and_condition" value="terms_and_condition">
                  <label for="terms_and_condition" class="custom-control-label pt-1">I agree with the <a routerLink="/page/terms-conditions-1" routerLinkActive="active"
                  target="_blank">terms and conditions</a></label>
                  <div *ngIf="issubmitted && !signUpformGroup.controls.terms_and_condition.value" class="error_signup">
                    accept the terms and conditions to proceed
                  </div>

                </div>

              </div> -->


            </div>

            <div class="col-md-4 float-left mb-2">
              <div class="col-md-12 float-left">
                <div class="form-group">
                  <label>Profile Image</label>
                  <label class="text-warning"> Accept only jpg, png, jpeg extensions (Maximum size: 3 MB)</label>
                  <div class="uploadedImg profile_pic profile-image-uplode text-center">
                    <input type="file" class="mediaFile" formControlName="photo" id="exampleInputFile"
                      (change)="onImageFileChange($event)" accept="image/jpeg, image/png, image/jpg">
                    <span class="up_img">Upload image</span>
                    

                    <img [src]="imageSrc" *ngIf="imageSrc">
                  </div>
                 <div *ngIf="imageSrc">
                 <label > <a class="under_line_blue_link" (click)="removeSelectedImage('photo')">Remove Selected Profile Image</a></label>
                 </div>
               
                  <!-- <div *ngIf="issubmitted && signUpformGroup.controls.photo.errors" class="text-danger">
                    <small *ngIf="issubmitted && signUpformGroup.controls.photo.errors">Profile Image is
                      required
                    </small>
                  </div> -->
                </div>
              </div>



              <!-- <div class="file-field form-label-group mb-2  col-sm-12 col-md-6 col-lg-6 float-left">
                <label>Photo</label>
                <div class='file-input'>
                  <input type='file' formControlName="photo" accept="image/*" (change)="onImageFileChange($event)">
                  <span class='button'>Choose</span>
                  <span class='label' *ngIf="imagePath" data-js-label>{{imagePath}}</span>
                  <span class='label' *ngIf="!imagePath" data-js-label>No file selected</span> </div>
                <div class="error_signup"><label>Note: Maximum size 20kb and resolution 50x50</label></div>
                
                <div class="col-sm-12 mt-1 p-0 card text-center border-colors"> <img [src]="imageSrc" *ngIf="imageSrc"
                    class="img-thumbnail border-0">
                </div>
              </div> -->


              <div class="col-md-12 mt-2 float-left">
                <div class="form-group">
                  <label>Identity Proof</label>
                  <label class="text-warning"> Accept only jpg, png, jpeg extensions (Maximum size: 3 MB)</label>
                  <div class="uploadedImg id_pic profile-id-uplode text-center">
                    <input type="file" class="mediaFile" formControlName="identityProof" id="exampleInputFile"
                      (change)="onIdProofFileChange($event)"
                      [ngClass]="{ 'is-invalid': issubmitted && signUpformGroup.controls.identityProof.errors }"
                      accept="image/jpeg, image/png, image/jpg">
                    <span class="up_img">Upload File</span>

                    <img [src]="idImageSrc" *ngIf="idImageSrc">
                  </div>
                  <div *ngIf="idImageSrc">
                  <label>
                  <a class="under_line_blue_link" (click)="removeSelectedImage('identityProof')">Remove Selected Identity Proof</a>
                </label>
                </div>
                  <!-- <div *ngIf="issubmitted && signUpformGroup.controls.identityProof.errors" class="text-danger">
                    <small *ngIf="issubmitted && signUpformGroup.controls.identityProof.errors">ID proof Image is
                      required
                    </small>
                  </div> -->
                </div>
              </div>


                            <!-- <div class="file-field form-label-group mb-2  col-sm-12 col-md-6 col-lg-6 float-left">
                  <label>Upload Identity Proof *</label>
                  <div class='file-input'>
                    <input type='file' accept="image/*,.pdf" formControlName="identityProof" (change)="onIdProofFileChange($event)" >
                    <span class='button'>Choose</span>
                    <span class='label' *ngIf="idProofPath" data-js-label>{{idProofPath}}</span>
                    <span class='label' *ngIf="!idProofPath" data-js-label>No file selected</span>
                    </div>
                  <div class="error_signup"><label>Note: Maximum size 20kb and resolution 50x50</label></div>
                  <div *ngIf="issubmitted && signUpformGroup.controls.identityProof.errors" class="error_signup"> Identity Proof is required.
                    <div *ngIf="issubmitted && signUpformGroup.controls.identityProof.errors.required"> Identity Proof is required. </div>
                  </div>
                    <div class="col-sm-12 mt-1 p-0 card text-center border-colors"> <img [src]="idImageSrc" *ngIf="idImageSrc"
                      class="img-thumbnail border-0">
                  </div> 
                </div> -->

              <div class="clearfix"></div>
              <div class="col-md-12">
                <div class="note_a p-3">
                  <label>* Preffered resolution 50x50</label>
                </div>
              </div>

            </div>

            <!-- <div class="form-label-group mb-3">
                <label>Confirm Password *</label>
                <input type="password" id="inputpassword-c" class="form-control" placeholder="Confirm password"
                  formControlName="confirmPassword">
                  
                  <div *ngIf="issubmitted && signUpformGroup.controls.confirmPassword.errors" class="error_signup"> Confirm Password is required.
                    <div *ngIf="issubmitted && signUpformGroup.controls.confirmPassword.errors.required"> Confirm Password is required. </div>
                  </div>
              </div> -->
              <div class="clearfix"></div>
              <div class="col-md-12">
                <div class="clearfix"></div>
                <div *ngIf="!isedit" class="form-label-group mb-2  col-sm-12 col-md-6 col-lg-6 float-left">
  
                  <div class="custom-control custom-checkbox  mr-3">
  
                    <input class="custom-control-input" type="checkbox" id="terms_and_condition"
                      formControlName="terms_and_condition" value="terms_and_condition">
                    <label for="terms_and_condition" class="custom-control-label pt-1">I agree with the <a routerLink="/page/terms-conditions-1" routerLinkActive="active"
                    target="_blank">terms and conditions</a></label>
                    <div *ngIf="issubmitted && !signUpformGroup.controls.terms_and_condition.value" class="error_signup">
                      accept the terms and conditions to proceed
                    </div>
  
                  </div>
  
                </div>

              </div>

            <div class="clearfix"></div>
            <div class="col-md-12">
              <button class="btn btn-dark sign_up_submit btn-lg btn-primary float-right text-uppercase"
                type="submit">{{submitButtonText}}</button>
            </div>
            <div class="clearfix"></div>
          </form>
          <div class="clearfix"></div>
          <hr class="my-3">
          <div class="mt-2" *ngIf="isedit==false">
            <div class="d-flex justify-content-center links"> You have an account ? <a (click)="logIn()"
                class="ml-2">Log in</a>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</section>
