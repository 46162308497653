<section class="bread_sec shadow-sm" *ngIf="!instaMojoTrigger">
  <div class="container" >
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="breadcrumb_section">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
              <li class="breadcrumb-item" *ngIf="courseDetail"><a
                  routerLink="/course/{{courseDetail.course_slug}}">{{courseDetail.course_name|slice:0:30}}</a></li>
              <li class="breadcrumb-item active">Payment</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sub_banner team_banner text-left" *ngIf="!instaMojoTrigger && courseDetail">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>{{courseDetail.course_name}}</h1>
      </div>
    </div>
  </div>
</section>
<section class="courses_pages">
  <form [formGroup]="paymentForm">
  <div class="container tech_planafter_boxes_sec">
    <div class="row plan_aftercomplete_left1" *ngIf="!instaMojoTrigger && courseDetail">
      <div class="col-lg-4 col-md-6 col-12">
        <div class="plan_aftercomplete_left  ">
          <div class="tech_planafter_icon"> <img src="{{s3FilePath}}{{courseDetail.course_image}}"
              onerror="this.src = '../../../../assets/web/assets/images/courses-01.svg'" class="card-img-top" alt="c" />
          </div>
          <a>{{courseDetail.short_description}}</a>
          <div class="planafter_feature_">
            <ul>
              <li *ngIf="courseDetail.sme_name != null">Instructor: {{courseDetail.sme_name}}</li>
              <li *ngIf="courseDetail.batch_start_date != null">Batch Start Date: {{courseDetail.batch_start_date}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-6 col-12">
        <div class="plan_aftercomplete_right">
          <div class="row">
            <div class="col-md-8">
              <div class="updatecareerinfo">
              </div>
            </div>
          </div>

          <div class="subscriptionplan3_billing">
            <div class="promocode_enter_details">
              <div class="promocode_enter_inner pt_0 pb_0">
                <!-- <div class="promocode_enter_left">
                      <div  class="newform">
                        <div class="float-label">
                          <div  class="control">
                            <input  class="form-control" name="title" placeholder="Enter Promo Code" required type="text">
                            </div>
                        </div>
                      </div>
                      <div class="from_buttons_">
                        <input  class="bluebutton btn btn-dark " type="button" value="Apply">
                      </div>
                    </div>
                    <div  class="clearfix"></div>

                    <div  class="promocode_show fullwidth">
                      <div >
                        <p >No promocode applied</p>
                      </div>
                    </div> -->
                <div class="clearfix"></div>
                <div class="col-md-12 promocode_btm_details pl-0">
                  <!-- <span >
                      <label >Selected Plan</label>
                      <div  class="semifont_size"> PLAN B CLASS 5</div>
                      </span> -->
                  <span>
                    <label>Date</label>
                    {{ dateNow | date:'fullDate'}} {{dateNow | date:'shortTime'}}</span></div>
                <div class="col-md-12 promocode_btm_details  pl-0"> <span>
                    <label>Price</label>
                    <div class="semifont_size">{{courseDetail.course_price |currency:courseDetail.currency_code}} </div>
                  </span><span>
                    <label>Discount</label>
                    {{courseDetail.discount |currency:courseDetail.currency_code}} </span>
                  <!-- <span >
                      <label >Offer</label>
                      0 %</span><span >
                      <label > Promo Discount</label>
                      ₹0.00 </span><span >
                      <label >GST(18%) </label>
                      ₹324.00</span> -->
                  <br>
                  <span>
                    <label>Amount to be Paid</label>
                    {{courseDetail.net_price |currency:courseDetail.currency_code}}</span>
                  <div class="clearfix"></div>
                  <!---->
                  <!---->
                  <!-- <div class="form-group">
                    <label>Payment Method *</label>
                    <select formControlName="paymentMethod" class="form-control select2 selact_" style="width: 100%;" >
                      <option value="1">Instamojo</option>
                      <option value="2">Freecharge</option>
                    </select>
                  </div> -->
                  <div class="from_buttons_ float-right">
                    <input class="bluebutton bigbuttonfont  btn btn-dark float-right" (click)="proceedpaymentFreeCharge()"
                      type="button" value="Pay &amp; Complete your subscription">
                  </div>
                  <!---->
                  <div class="clearfix"></div>
                  <!---->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <iframe *ngIf="instaMojoTrigger" [src]="loadingUrl | safeUrl" scrolling="no" frameBorder="0"
      style="height: auto;min-height: 700px; width:90%; overflow:hidden;">
    </iframe>
  </div>
</form>
</section>




<section hidden>
  <!doctype html>
<html>
<script language="javascript" type="text/javascript">
function removeSpaces(string) {
 return string.split(' ').join('');
}
</script>
<head>
 <title>Checkout</title>
 </head>
<body>
<form enctype='application/json'
action="https://checkout.freecharge.in/api/v1/co/pay/init" id="
searchForm" #myDiv1 name="searchForm"
method="post">
<h1>Client Order Form</h1>
 <fieldset>
<legend>Merchant / USER details</legend>
<table style="width:100%">
 <tr>
 <td>FieldName </td>
 <td>Value</td>
 </tr>


 <tr>
 <td><label>merchantId</label></td>
 <td>
<input id="merchantId" value="" #merchantId name="merchantId" type="text">
</td>

 </tr>
 <tr>
 <td><label>merchantTxnId</label></td>
 <td><input id="merchantTxnId" value="" #merchantTxnId name="merchantTxnId"
type="text" required onblur="this.value=removeSpaces(this.value);"></td>
 </tr>

 <tr>
 <td><label>amount</label></td>
 <td> <input id="amount" value="" #amount name="amount" type="number"
required onblur="this.value=removeSpaces(this.value);" ></td>
 </tr>
<tr>
 <td><label>furl</label></td>
 <td> <input id="furl" name="furl" #furl type="text" value="" required
onblur="this.value=removeSpaces(this.value);" ></td>
 </tr>
 <tr>
 <td><label>surl</label></td>
 <td> <input id="surl" name="surl" #surl type="text" value="" required
onblur="this.value=removeSpaces(this.value);" ></td>
 </tr>
 <tr>
 <td><label>checksum</label></td>
 <td> <input id="checksum" name="checksum" #checksum type="text" value=""
onblur="this.value=removeSpaces(this.value);" ></td>
 </tr>
 <tr>
 <td><label>channel</label></td>
 <td> <input list="channel" name="channel" #channel />
 <datalist id="channel">
 <option value="WEB">
 <option value="WAP">
 <option value="ANDROID">
 <option value="IOS">
 <option value="WINDOWS">
 </datalist>
 </td>
 </tr>
<!-- this checksum field is to be calculated by the steps mentioned -->
</table>
 </fieldset>
 <button id="checkout" class="btn btn-lg btn-block signin">Checkout</button>
 <p id="msg"></p>
</form>
</body>
</html>

</section>


  

