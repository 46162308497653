import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from '../../models/pageDto';

declare var $: any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @ViewChild('myTable') table: FaqComponent;
  session_id: any;
  p: number = 1;
 selected=[];
  faqList: any = [];
  temp = [];
  searchTerm: any ='';
  faqForm: FormGroup;
  page: Page;
  filterCategory: any ='';
  categoryList: any=[];

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.faqForm = this.formBuilder.group({
      search: [''],
      categoryType: ['0']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    // this.setPage({ offset: 0 });
    this.getCategory();
    this.init();
  }

  init() {
    this.spinner.show();
    // this.faqForm.get('categoryType').setValue('0');

    this.faqList = [];
    var faq_fetch_url = "faq";
    this.api.detailsGetFetch(faq_fetch_url,this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.faq[0]) {
        this.spinner.hide();
       
        this.temp = [...data.faq];
        this.faqList = data.faq;
       }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
   
  deleteFaq(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        var body = { "faq_id": +id };
        var del_url = "delete_faq";
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
             this.init();
          }
          else {
            this.spinner.hide();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.question.toLowerCase().indexOf(val) !== -1 || !val;
      const category_name = d.category_name.toLowerCase().indexOf(val) !== -1 || !val;
       return title_name || category_name;
    });

    // update the rows
    this.faqList = temp;
    // this.table.offset = 0;
  }
  editFaq(id){
    this.router.navigate(['admin/add-faq',{dist:`${id}`}]);

  }
  // onSearch() {
  //   this.searchTerm = this.faqForm.get('search').value.toString();
  //   this.setPage({ offset: 0 });
  // }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  setPage(pageInfo) {
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.faqList = [];
    

    // var state_fetch_url = "pages?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;

    if (this.filterCategory.length > 0)
    var state_fetch_url = "faq?category_id=" + this.filterCategory;



    // if (this.searchTerm.length > 0)
    //   state_fetch_url += "&searchTerm=" + this.searchTerm;

    this.api.detailsGetFetch(state_fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.faq[0]) {
        debugger
        this.spinner.hide();

        this.temp = [...data.faq];
        this.faqList = data.faq;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onCategoryTypeFilter(){
    debugger
    console.log(this.faqForm.get('categoryType').value);
    var categoryValue=this.faqForm.get('categoryType').value;
    if(categoryValue>0){
      this.filterCategory=categoryValue;
    }else
    {
      this.filterCategory='';
    }

    //  this.filterCategory = this.faqForm.get('categoryType').value > 0 ? this.faqForm.get('countryType').value : '';
    this.setPage({ offset: 0 });
  }
  getCategory(){
    this.spinner.show();
    var fetch_category_url="faq_category";
    this.api.detailsGetFetch(fetch_category_url, this.session_id).subscribe((data:any)=>{
      if(data.status=="true" && data.faq_category[0]){
        this.categoryList=data.faq_category;
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
}
