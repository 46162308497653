import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseRoutingModule } from './course-routing.module';
import { CourseComponent } from './course.component';
import { AddcourseComponent } from './addcourse/addcourse.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { CKEditorModule } from 'ng2-ckeditor';
import { CoursesComponent } from './courses/courses.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ResourcesComponent } from './resources/resources.component';
import { ActivityComponent } from './activity/activity.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { AddcourseWeekPopupComponent } from './addcourse-week-popup/addcourse-week-popup.component';
import { QuestionsComponent } from './activity/questions/questions.component';

import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ListLiveClassComponent } from './resources/list-live-class/list-live-class.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ViewLiveClassComponent } from './resources/view-live-class/view-live-class.component';





@NgModule({
  declarations: [
    CourseComponent,
    AddcourseComponent,
    CoursesComponent,
 
    ResourcesComponent,
    ActivityComponent,
    AddcourseWeekPopupComponent,
    QuestionsComponent,
    ListLiveClassComponent,
    ViewLiveClassComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CourseRoutingModule,
    MatTabsModule,
    DragDropModule,
    MatIconModule,
    CKEditorModule,
    NgxPaginationModule,
    NgxDatatableModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    DialogModule,
    MatProgressBarModule,
    NgMultiSelectDropDownModule

  ]
})
export class CourseModule { }
