<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/batch']">Batch List</a></li>
              <li class="breadcrumb-item active">Edit Batch</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="card elevation-0">
        <div class="card-header">
          <h1>Edit Batch</h1>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-primary p-3 pb-5 add_contry_">
                <form [formGroup]="batchEditFormGroup" (ngSubmit)="onSubmit()">
                  <div class="col-md-6 float-left">
                    <div class="form-group">
                      <label>course *</label>
                      <!-- <input type="text" formControlName="course_id" class="form-control selact_" placeholder="Course"
                        [ngClass]="{ 'is-invalid': submitted && batchEditFormGroup.get('course_id').errors?.required }"> -->
                        <ng-multiselect-dropdown name="Course" [placeholder]="'Select Course'" [data]="courseList"
                            formControlName="course_id" [(ngModel)]="courseArray" [settings]="dropdownSettings"
                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                          </ng-multiselect-dropdown>
                      <div *ngIf="submitted && batchEditFormGroup.get('course_id').invalid">
                        <small *ngIf="batchEditFormGroup.get('course_id').errors?.required" class="text-danger">Course
                          is
                          required</small>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Start Date *</label>
                      <input type="date" [min]=todayDate data-date-format="YYYY MMMM DD" formControlName="start_date"
                        class="form-control selact_" placeholder="Start Date"
                        [ngClass]="{ 'is-invalid': submitted && batchEditFormGroup.get('start_date').errors?.required }">
                      <div *ngIf="submitted && batchEditFormGroup.get('start_date').invalid">
                        <small *ngIf="batchEditFormGroup.get('start_date').errors?.required" class="text-danger">Start
                          Date
                          is
                          required</small>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>End Date *</label>
                      <input type="date" [min]=todayDate data-date-format="YYYY MMMM DD" formControlName="end_date"
                        class="form-control selact_" placeholder="End Date"
                        [ngClass]="{ 'is-invalid': ((submitted && batchEditFormGroup.get('end_date').errors?.required) || batchEditFormGroup.get('end_date').errors?.dateError) }">
                      <div *ngIf="submitted && batchEditFormGroup.get('end_date').invalid">
                        <small *ngIf="batchEditFormGroup.get('end_date').errors?.required" class="text-danger">End Date
                          is
                          required</small>
                        <small *ngIf="batchEditFormGroup.get('end_date').errors?.dateError" class="text-danger">
                          End date must be after Start date
                        </small>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Status *</label>
                      <select formControlName="status_id" class="form-control select2 selact_" style="width: 100%;">
                        <option value="1">Active</option>
                        <option value="2">Disabled</option>
                      </select>
                    </div>
                  </div>
  
                  <div class="clearfix"></div>
                  <div class="p-3">
                    <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                  </div>
  
  
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </section>
  </div>
  