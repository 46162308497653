import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CourseComponent } from './course.component';
import { AddcourseComponent } from './addcourse/addcourse.component';
import { CoursesComponent } from './courses/courses.component';
import { ResourcesComponent } from './resources/resources.component';
import { ActivityComponent } from './activity/activity.component';
import { QuestionsComponent } from './activity/questions/questions.component';
import { ListLiveClassComponent } from './resources/list-live-class/list-live-class.component';
import { ViewLiveClassComponent } from './resources/view-live-class/view-live-class.component';

const routes: Routes = [
  {
    path: '', component: CourseComponent,
    children: [
      { path: 'courseList', component: CoursesComponent, data: { title: 'List Course | Admin  ' }, },
      { path: 'addcourse', component: AddcourseComponent, data: { title: 'Add Course | Admin  ' },},
      { path: 'editcourse/:courseId', component: AddcourseComponent, data: { title: 'Edit Course | Admin  ' }, },
      { path: 'resource/:courseId/:courseContentTypeId/:week_id', component: ResourcesComponent, data: { title: 'Course Resources | Admin  ' }, },
      { path: 'resource/:course_content_id', component: ResourcesComponent, data: { title: 'Course Resources | Admin  ' }, },
      { path: 'activity/:courseId/:courseContentTypeId/:week_id', component: ActivityComponent, data: { title: 'Course Activity | Admin  ' },},
      { path: 'activity/:course_content_id', component: ActivityComponent, data: { title: 'Course Activity | Admin  ' },},
      { path: 'activity/:cource_content_id/questions', component: QuestionsComponent, data: { title: 'Activity Questions | Admin  ' }},
      { path: 'liveClassList', component: ListLiveClassComponent, data: { title: 'List Live Class | Admin  ' }, },
      { path: 'viewLiveClass/:Id', component: ViewLiveClassComponent, data: { title: 'View Live Class | Admin  ' }, },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CourseRoutingModule { }
