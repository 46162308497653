import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { NgxSpinnerService } from "ngx-spinner";
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  message:any;
  constructor(private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthService,private spinner: NgxSpinnerService, private popup: PopupService) { }
  forgotPwdForm:FormGroup;
  isSubmitted=false;
  isMessage:boolean=false;
  ngOnInit(): void {
    this.forgotPwdForm = this.formBuilder.group({
      
      email_id: ['', [Validators.required, RxwebValidators.email()]]
    });
  }
  get g() { return this.forgotPwdForm.controls; }
  onSubmit(){
    debugger
    this.isSubmitted=true;
    console.log(this.g)
    if (this.forgotPwdForm.invalid) {
        return;
    }
    this.spinner.show();
    var url = "forgotpassword";
    let param = { email: this.g.email_id.value };
    this.apiService.postDetails(url,param).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.message) {
        this.spinner.hide();
        this.popup.sucessMessage = data.message;
        this.popup.sucesspopup();
        this.forgotPwdForm.reset();
         this.isSubmitted = false;
       }
      else{
        this.spinner.hide();
        this.popup.failureMessage = data.message;
        this.popup.failurepopup();
       }
    });
  }
}
