import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { QuestionModel, MediaFiles, Options, ChildQuestion } from '../../models/questionDto';
@Component({
  selector: 'app-question-import',
  templateUrl: './question-import.component.html',
  styleUrls: ['./question-import.component.css']
})
export class QuestionImportComponent implements OnInit {

  spinnerEnabled = false;
  keys: string[];
  dataSheet = new Subject();
  @ViewChild('inputFile') inputFile: ElementRef;
  isExcelFile: boolean;
  isBlankExcelFile: boolean;
  selectedFile: string='';
  isEdit: boolean = false;
  session_id: any;
  qualificationId: any;

  questionModel: Array<QuestionModel> = [];

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService) { }
  questionImportForm: FormGroup;
  submitted = false;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')

    this.questionImportForm = this.formBuilder.group({
      fileData: ['', Validators.required]
    });
  }
  get f() { return this.questionImportForm.controls; }

  onChange(evt) {
    debugger
    this.questionModel = [];
    this.selectedFile = '';
    let data, header;
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.isBlankExcelFile = false;
    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
    this.selectedFile = target.files[0].name;
    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }
    if (this.isExcelFile) {
      this.spinnerEnabled = true;
      this.questionImportForm.controls['fileData'].setErrors(null);
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        data = XLSX.utils.sheet_to_json(ws);
        if (data.length == 0) {
          this.isBlankExcelFile = true;
          this.questionImportForm.controls['fileData'].setErrors({ 'incorrect': true });
          this.dataSheet = new Subject();
        }else {
          this.isBlankExcelFile = false;
          
        }
        for (let i = 0; i < data.length; i++) {
          debugger
          console.log(data[i]);
          if(!(data[i].Question_Title)){
            data[i].Question_Title = ""
          }
          if(!(data[i].Question_Text)){
            data[i].Question_Text = ""
          }
          if(!(data[i].Answer_Explanation)){
            data[i].Answer_Explanation = ""
          }
          if(!(data[i].Question_Category_Id)){
            data[i].Question_Category_Id = ""
          }
          console.log(data[i]);
          let model: QuestionModel = {

            title: data[i].Question_Title,
            question_text: data[i].Question_Text,
            answer_explanation: data[i].Answer_Explanation,
            question_category_id: data[i].Question_Category_Id,
            difficulty_level: this.getDifficultyLevelId(data[i].Difficulty_Level),
            status_id: 1,
            question_type: this.getQuestionTypeId(data[i].Question_Type),
            media_files: [],
            options: []
          }
          for (let j = 1; j < 10; j++) {
            debugger
            let dataX = data[i];
            
            if (dataX['Option' + j] != undefined) {
              let opt : any
              if (dataX['group' + j] != undefined) {
                debugger
                if(dataX['Is_Answer'  + j]){
                var is_Answer = dataX['Is_Answer' + j] == 'Yes' ? 1 : 0;
              }else{
                var is_Answer = 1 ;
              }
               opt = {
                option: dataX['Option' + j],
                // id: null,
                // file: null,
                option_media_type: 4, 
                is_answer: is_Answer,
                group: parseInt(dataX['group' + j]),
                question_text: dataX['question_text' + j],
                question_media_type: 4,
                // question_id: null
              }
            }
            if (dataX['group' + j] == undefined) {
              debugger
              if(dataX['Is_Answer'  + j]){
                var is_Answer = dataX['Is_Answer' + j] == 'Yes' ? 1 : 0;
              }else{
                var is_Answer = 1 ;
              }
               opt = {
                option: dataX['Option' + j],
                // id: null,
                // file: null,
                option_media_type: 4,
                is_answer: is_Answer,
                // group: parseInt(dataX['group' + j]),
                question_text: dataX['question_text' + j],
                question_media_type: 4,
                // question_id: null
              }
            }
              model.options.push(opt);
            }
          }
          this.questionModel.push(model);
        };

      };

      reader.readAsBinaryString(target.files[0]);

      reader.onloadend = (e) => {
        this.spinnerEnabled = false;
        this.keys = Object.keys(data[0]);
        this.dataSheet.next(data)
      }
    } else {
      this.inputFile.nativeElement.value = '';
      this.questionImportForm.controls['fileData'].setErrors({ 'incorrect': true });
      return;
    }
  }

  getQuestionTypeId(typeName: any) {
    debugger
    let typeId: any = "0";
    switch (typeName) {
      case 'MCQ': {
        typeId = 1;
        break;
      }
      case 'Drag and drop': {
        typeId = 2;
        break;
      }
      case 'Match the following': {
        typeId = 3;
        break;
      }
      case 'Fill in the blanks': {
        typeId = 4;
        break;
      }
    }
    return typeId
  }

  getDifficultyLevelId(difficultyLevel: any) {
    let levelId: any = "1";
    switch (difficultyLevel) {
      case 'Easy': {
        levelId = "1";
        break;
      }
      case 'Normal': {
        levelId = "2";
        break;
      }
      case 'Hard': {
        levelId = "3";
        break;
      }
    }
    return levelId
  }


  onSubmit() {
    debugger
    this.submitted = true;
    console.log(this.f)

    if (this.questionImportForm.invalid) {
      return;
    }
    if (!this.isEdit) {
      this.spinner.show();
      console.log(this.questionModel);

      //var body = { "status_id": +this.f.status.value, "title": this.f.qualification.value, "description": this.f.description.value, };
      var url = "importQuestions";
      
      this.api.detailsUploadFetch(this.questionModel, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "TRUE") {
          this.spinner.hide();
          this.questionImportForm.reset();
          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup()
          this.router.navigate(['admin/question'])

        }
        else {
          this.spinner.hide();

          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
  }

}
