import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service'
import { LocaldataService } from 'src/app/services/localdata.service';
import { Validatespace } from "src/app/validation/spacevalidation";
import { phoneNumberValidator } from "src/app/validation/phone-validator";

@Component({
  selector: 'app-profile-add',
  templateUrl: './profile-add.component.html',
  styleUrls: ['./profile-add.component.css']
})
export class ProfileAddComponent implements OnInit {
  selectedFile: File;
  isEdit: boolean = false;
  isSme: boolean = false;
  session_id: any;
  user_id: any;
  imageSrc: any;
  profileId: any;
  fileName = '';
  p: number = 1;
  s3FilePath: string;
  s3FileName: any;
  profile_image: any;
  user_role: any;
  namePrefix: any = [];

  profileForm: FormGroup;
  submitted = false;

  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private LocaldataService: LocaldataService,
    private popup: PopupService,
    private spinner: NgxSpinnerService,
    private uploadService: UploadService,
    private constantService: ConstantsService) {
    this.profileForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validatespace, Validators.pattern(/^[a-zA-Z ._-]*$/), Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validatespace, Validators.pattern(/^[a-zA-Z ._-]*$/)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone_number: ['', [
        Validators.required,
        Validatespace,
        phoneNumberValidator,
        Validators.minLength(8),
        Validators.maxLength(12),
      ]],
      description: ['', [Validators.required, Validatespace]],
      degree: [''],
      fileData: ['', Validators.required],
      namePrefixes: ['',Validators.required]
    });
  }


  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;
    this.user_role = localStorage.getItem('user_role');
    if (this.user_role == 'sme'){
      this.isSme = true;
      this.getNamePrefix();
    }

    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.selectedFile = null;
        this.profileId = params.dist;
        this.spinner.show();

        var single_url = "edit_profile?user_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          debugger
          console.log(data)
          if (data.status == "true" && data.user_data[0]) {
            this.spinner.hide();
            this.user_id = data.user_data[0].id;
            this.profileForm.get('namePrefixes').setValue(data.user_data[0].name_prefix);
            this.profileForm.get('firstName').setValue(data.user_data[0].first_name);
            this.profileForm.get('lastName').setValue(data.user_data[0].last_name);
            this.profileForm.get('email').setValue(data.user_data[0].email);
            this.profileForm.get('phone_number').setValue(data.user_data[0].phone_number);
            if (data.user_data[0].degree !== undefined)
              this.isSme = true;
            this.profileForm.get('description').setValue(data.user_data[0].description);
            this.profileForm.get('degree').setValue(data.user_data[0].degree);
            this.profileForm.get('fileData').setValidators(null);
            this.profileForm.get('fileData').setValue("");
            this.imageSrc = this.s3FilePath + data.user_data[0].profile_pic;
            this.profile_image = data.user_data[0].profile_pic;
            console.log(this.profileForm);
            console.log(this.profileForm);
          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
      else {
        this.isEdit = false;
        this.spinner.hide();

      }
    }
    );
  }



  get f() { return this.profileForm.controls; }
  onFileChanged(event) {
    debugger
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      console.log(this.selectedFile);

      this.selectedFile = event.target.files[0];
      console.log(this.selectedFile.name);
      let imagePath_ = this.selectedFile.name;
      var ext = imagePath_.split('.').pop();
      if (ext == "png" || ext == "jpg" || ext == "jpeg") {

      reader.onload = () => {

        this.imageSrc = reader.result as string;


      }
      reader.readAsDataURL(this.selectedFile);
      console.log(this.selectedFile)
    } else {
      this.profileForm.get('fileData').setValue('');
      this.profileForm.get('fileData').setErrors({ required: true });
      this.imageSrc = ""
    }

    }
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.f)

    if (this.user_role != 'sme'){
      this.profileForm
        .get("phone_number")
        .setErrors(null);
        this.profileForm
        .get("description")
        .setErrors(null);
        this.profileForm
        .get("namePrefixes")
        .setErrors(null);
    }

    this.profileForm
    debugger
    if (this.profileForm.invalid) {
      return;
    }
    this.spinner.show();
    const uploadData = new FormData();
    if(this.isSme){
      uploadData.append('name_prefix', this.f.namePrefixes.value);
    }
    uploadData.append('first_name', this.f.firstName.value);
    uploadData.append('last_name', this.f.lastName.value);
    uploadData.append('email', this.f.email.value);
    if (this.isSme) {
      uploadData.append('phone_number', this.f.phone_number.value);
      uploadData.append('description', this.f.description.value);
      uploadData.append('degree', this.f.degree.value);
    }
    if (this.selectedFile == null) {
      uploadData.append('profile_pic', this.imageSrc.replace(this.s3FilePath, ''));
      this.addEditSubmit(uploadData);
    }
    else {
      this.uploadService.fileUpload(this.selectedFile, 'profile').then(res => {
        if (res) {
          this.s3FileName = res
          uploadData.append('profile_pic', this.s3FileName);
          this.addEditSubmit(uploadData);
        }
      });
    }
  }
  addEditSubmit(uploadData) {
    var fetch_url = null;
    if (this.isEdit) {
      fetch_url = "update_profile";
      uploadData.append('userID', this.profileId);
    }
    else {
      fetch_url = "add_profile";
    }
    this.api.detailsUploadFetch(uploadData, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      console.log(data)
      if (data.status == "true") {
        this.spinner.hide();
        localStorage.setItem("username", this.f.firstName.value + " " + this.f.lastName.value);
        if (this.selectedFile == null) {
          localStorage.setItem("profile_pic", this.profile_image);
        }
        else {
          localStorage.setItem("profile_pic", this.s3FileName);
        }
        // this.imageSrc = "";
        // this.profileForm.reset();
        this.submitted = false;
        this.popup.sucessMessage = data.message
        this.LocaldataService.updateUser();
        this.popup.sucesspopup();

        // this.router.navigate(['admin']);
        // window.location.reload();

      }
      else {
        this.popup.failureMessage = data.message
        this.popup.failurepopup()
        this.spinner.hide();

      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });


  }
  getNamePrefix(){
    var url = "getNamePrefixes";
    this.api.detailsGet(url).subscribe((data : any) =>{
      if(data.status == "true" && data.name_prefixes){
        this.namePrefix = data.name_prefixes;
      }
    },
    (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }
}
