<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/admin/enrollments/list"> Enrollment</a></li>
            <li class="breadcrumb-item active">Enrollment detail</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>Enrollment Details</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3">
              <form role="form" class="p-3 width_ add_course_">
                <div class="col-md-2 float-left">
                  <div class="form-group">
                    <!--<label>Photo</label>-->
                    <div class="uploadedImg mx-auto">
                      <a href="dist/img/user2-160x160.jpg" data-toggle="lightbox" data-title="sample 2 - black">
                        <img src="{{s3FilePath }}{{studentDetails.student_image}}" alt="User Image">
                      </a>

                    </div>
                    <div class="form-group">
                      <div class="text-center">
                        <a href="{{s3FilePath }}{{studentDetails.identity_proof}}"
                          class="btn btn-outline-secondary btn-sm mx-auto mt-3" data-toggle="lightbox"
                          data-title="sample 2 - black" target="_blank" download>
                          <!--<img src="{{s3FilePath }}{{studentDetails.identity_proof}}" alt="User Image">-->
                          <i class="far fa-id-badge mr-1"></i> ID Proof
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-10 float-left enrollment_det">
                  <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Student name</small></label>
                    <div class="text-muted"><strong>{{studentDetails.name}}</strong></div>
                  </div>
                  <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Country of Residence</small></label>
                    <div class="text-muted"><strong>{{studentDetails.country}}</strong></div>
                  </div>
                  <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Nationality </small></label>
                    <div class="text-muted">
                      <strong *ngIf="studentDetails.nationality != null">{{studentDetails.nationality}}</strong>
                      <strong *ngIf="studentDetails.nationality == null">Not Updated</strong>
                    </div>
                  </div>
                  <!-- <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>State</small></label>
                    <div class="text-muted"><strong>{{studentDetails.state}}</strong></div>
                  </div>
                  <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>District</small></label>
                    <div class="text-muted"><strong>{{studentDetails.district}}</strong></div>
                  </div> -->
                  <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Student email</small></label>
                    <div class="text-muted1"><strong>{{studentDetails.email}}</strong></div>
                  </div>
                  <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Student Mobile number</small></label>
                    <div class="text-muted"><strong>{{studentDetails.phone_number}}</strong></div>
                  </div>
                  <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Student WhatsApp number</small></label>
                    <div class="text-muted">
                      <strong *ngIf="studentDetails.whatsapp_number != null">{{studentDetails.whatsapp_number}}</strong>
                      <strong *ngIf="studentDetails.whatsapp_number == null">Not Updated</strong>
                    </div>
                  </div>
                  <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>DOB</small></label>
                    <div class="text-muted"><strong>{{studentDetails.date_of_birth | date}}</strong></div>
                  </div>
                  <div *ngIf="!isOtherQualification" class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Qualification</small></label>
                    <div class="text-muted"><strong>{{studentDetails.qualification}}</strong></div>
                  </div>
                  <!-- <div *ngIf="!isOtherQualification" class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Stream</small></label>
                    <div class="text-muted"><strong>{{studentDetails.stream}}</strong></div>
                  </div>
                  <div *ngIf="isOtherQualification" class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Qualification and Stream</small></label>
                    <div class="text-muted"><strong>{{studentDetails.other_qualification_stream}}</strong></div>
                  </div> -->
                  <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Postal Address</small></label>
                    <div class="text-muted">
                      <address>
                        <strong> {{studentDetails.address}}</strong>
                      </address>
                    </div>
                  </div>
                  <!-- <div class="form-group col-sm-4 invoice-col float-left">
                    <label><small>Score</small></label>
                    <div class="text-muted"><strong class="score_hi badge bg-danger">{{studentDetails.score}}</strong>
                    </div>
                  </div> -->


                </div>

              </form>
              <!--<div class="form-group col-sm-12 invoice-col float-left">
                <hr class="mx-2">
              </div>-->
              <form role="form" class="p-3 width_ add_course_ course_det">
                <div class="row">
                  <div class="col-12 ">
                    <div class="form-group col-sm-4 invoice-col float-left">
                      <label><small>Course Name</small></label>
                      <div class="text-muted"><strong>{{enrollmentDetails.course_name}}</strong></div>
                    </div>
                    <!-- <div class="form-group col-sm-3 invoice-col float-left">
                      <label><small>Stream</small></label>
                      <div class="text-muted"><strong>{{studentDetails.stream}}</strong></div>
                    </div>
                    <div class="form-group col-sm-3 invoice-col float-left">
                      <label><small>Score</small></label>
                      <div class="text-muted"><strong class="score_hi badge bg-danger">{{studentDetails.score}}</strong>
                      </div>
                    </div> -->


                    <div class="form-group col-sm-4 invoice-col float-left">
                      <label><small>Instructor</small></label>
                      <div class="text-muted"><strong>{{instructorDetails.name}}</strong></div>
                    </div>

                    <div class="form-group col-sm-4 invoice-col float-left">
                      <label><small>enrollment ID</small></label>
                      <div class="text-muted"><strong>{{enrollmentDetails.enrol_ID}}</strong></div>
                    </div>

                    <!-- <div class="form-group col-sm-3 invoice-col float-left">
                      <label><small>Batch</small></label>
                      <div class="text-muted"><strong>1</strong></div>
                    </div> -->

                    <div class="form-group col-sm-4 invoice-col float-left">
                      <label><small>Completed Status</small></label>
                      <div class="text-muted"><strong>{{enrollmentDetails.enrollment_status}}</strong></div>
                    </div>
                    <div class="form-group col-sm-4 invoice-col float-left"
                      *ngIf="enrollmentDetails.enrollment_status == 'Completed'">
                      <label><small>Certificate</small></label>
                      <div class="text-muted"><a *ngIf="enrollmentDetails && enrollmentDetails.generate_button"
                          class="btn cour-button" (click)="generateCertificate()">Generate Certificate</a>

                        <a *ngIf="enrollmentDetails && !enrollmentDetails.generate_button && enrollmentDetails && enrollmentDetails.certificate_download_link != null"
                          href="{{enrollmentDetails.certificate_download_link}}" target="_blank" download
                          class="btn cour-button">Download</a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <!-- <div class="form-group col-sm-12 invoice-col float-left">
                <hr class="mx-2">
              </div>-->
              <div class="col-12 mt-3 table-responsive">
                <h3>Course Overview</h3>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Status</th>
                      <th>Score</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let week of courseOverviewDetails">
                    <tr *ngFor="let content of week.course_contents">
                      <!-- 1 -->
                      <td>
                        {{week.course_week_title}} : <strong>{{content.course_content_type_name}}</strong> <br>
                        <small>{{content.title}}</small>
                      </td>
                      <!-- 2 -->
                      <td
                        *ngIf="content.course_content_type_id == 3 || content.course_content_type_id == 1 || content.course_content_type_id == 2 || content.course_content_type_id == 7 || content.course_content_type_id == 8">
                        <span [class.text-success]="content.completed_status == 1"
                          [class.text-danger]="content.completed_status != 1">
                          {{content.completed_status == 1 ? 'Completed' : 'Due'}}
                        </span> <br>
                        <small class="text-success" *ngIf="content.completed_status == 1">Date :
                          {{content.completed_status_at | date}}</small>
                      </td>
                      <td *ngIf="content.course_content_type_id == 5">
                        <span [class.text-success]="content.activity_last_submission_status == 'passed'"
                          [class.text-danger]="content.activity_last_submission_status != 'passed'">
                          {{content.activity_last_submission_status}}
                        </span> ( {{content.attempts.length}} attempts) <br>
                        <small class="text-success" *ngIf="content.activity_last_submission_status == 'passed'">Date :
                          {{content.completed_status_at | date}}</small>
                      </td>
                      <td *ngIf="content.course_content_type_id == 4" style="text-transform:capitalize;">
                        <span [class.text-success]="content.activity_last_submission_status == 'passed'"
                          [class.text-danger]="content.activity_last_submission_status != 'passed'">
                          {{content.activity_last_submission_status}}
                        </span>({{content.submissions.length}} submissions) <br>

                      </td>
                      <!-- <td></td>
                      <td></td> -->
                      <!-- 3 -->
                      <td>
                        <a *ngIf="content.course_content_type_id == 4 && content.submissions.length !=0"
                          class="btn btn-outline-success score_button">{{content.submissions[0].score}}
                          / {{content.maximum_grade}}</a>
                        <a *ngIf="content.course_content_type_id == 5 && content.attempts.length !=0"
                          class="btn btn-outline-success score_button">{{content.attempts[0].score}}
                          / {{content.total_marks}}</a>
                        <small *ngIf="content.course_content_type_id == 4">{{content.enrollment_feedback}}</small>
                        <small *ngIf="content.course_content_type_id != 4 && content.course_content_type_id != 5"
                          class="btn btn-outline-success score_button"> N/A</small>
                      </td>
                      <!-- 4 -->
                      <td>
                        <span
                          *ngIf="((content.course_content_type_id == 3 || content.course_content_type_id == 1 || content.course_content_type_id == 2 || content.course_content_type_id == 7 || content.course_content_type_id == 8) && content.enrollment_feedback != null)">
                          <span class="star_icons rating_a btn btn-outline-warning btn-sm float-left mr-1"> <i
                              *ngFor="let fullstar of [].constructor(content.enrollment_feedback.rating)"
                              class="fa fa-star ratefont" aria-hidden="true"></i> <i
                              *ngIf="(content.enrollment_feedback.rating%1)!=0" class="fa fa-star-half-o ratefont"
                              aria-hidden="true"></i> <i
                              *ngFor="let emptystar of [].constructor(5 - content.enrollment_feedback.rating) "
                              class="fa fa-star-o ratefont" aria-hidden="true"></i> </span>
                          <!-- rating: {{content.enrollment_feedback.rating}} -->
                          <!-- <div> {{content.enrollment_feedback.comments|slice:0:10}} <span *ngIf="content.enrollment_feedback.comments.length>10"> ...<a (click)="openDialog(content.enrollment_feedback.rating, content.enrollment_feedback.comments)">View More</a></span></div> -->
                          <div> <a
                              (click)="openDialog(content.enrollment_feedback.rating, content.enrollment_feedback.comments)"
                              class="btn btn-primary btn-sm feedback_det float-left mr-1">View Feedback</a> </div>
                        </span>

                        <span
                          *ngIf="(content.course_content_type_id == 4 && content.submissions.length !=0) || (content.course_content_type_id == 5 && content.attempts.length !=0)">
                          <a (click)="openDetailDialog(content)" class="btn btn-info btn-sm view_det float-left">View
                            detail</a>
                        </span>
                        <!-- <span *ngIf="content.course_content_type_id == 5">
                          <a href="#" class="btn btn-info btn-sm">View detail</a>
                        </span> -->
                      </td>
                    </tr>


                  </tbody>
                </table>
                <ng-container *ngIf="enrollmentDetails.enrollment_status != 'Completed'">
                  <a class="btn btn-secondary btn-dark float-right add_country" (click)="markAsComplete()">Mark course completed</a>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

  </section>
  <!-- /.content -->
</div>


<ng-template #detailPage let-modal>
  <div class="enrollment_Detail_popup">
  <div class="content-wrapper ml-0">
    <div class="modal-header">
      <h2 class="modal-title mb-0" *ngIf="selectedContent.course_content_type_id == 4">Assignment</h2>
      <h2 class="modal-title mb-0" *ngIf="selectedContent.course_content_type_id == 5">Assessment</h2>
      <button type="button" class="close" aria-describedby="modal-title" matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body scrool_sction">
      <div class="card  my-3 border-0 shadow-sm">
        <div class="card-body">
          <div class="content_resource_a" *ngIf="selectedContent.course_content_type_id == 4">
            <h2>{{selectedContent.title}}</h2>
            <hr class="py-2">
            <div [innerHTML]="selectedContent.content"> </div>

            <div class="accordion overview_list assignment_list" id="accordionExample3"
              *ngIf="selectedContent.submissions.length !=0">
              <div>
                <div>
                  <h3>
                    Submissions
                  </h3>
                </div>
                <div>
                  <div>
                    <ng-container *ngFor="let mySubmission of selectedContent.submissions; let i = index">

                      <div aria-labelledby="headingTwo{{i}}">
                        <div class="card mb-2">
                          <div class="card-header bg-white p-0" style="text-transform:capitalize;" id="headingTwo{{i}}">
                            <div class="my-2 text-left p-3 float-left status_subm"> <i
                                class="far fa-eye-slash mr-2"></i> Status : {{mySubmission.submission_status}}</div>
                            <div class="my-2 text-left p-3 float-left score_subm"><i class="far fa-star mr-2"></i> Score
                              : {{mySubmission.score}}</div>
                            <div class="my-2 text-left p-3 float-left score_subm"><i
                                class="far fa-star mr-2"></i>Maximum Score
                              : {{selectedContent.maximum_grade}}</div>
                            <div class="my-2 text-left p-3 float-left score_subm"><i
                                class="far fa-star mr-2"></i>Minimum Grade Percentage
                              : {{selectedContent.minimum_grade_percentage}}</div>
                            <div class="my-2 text-left p-3 float-right status_date"><i class="far fa-clock mr-2"></i>
                              {{mySubmission.submitted_date}} {{mySubmission.submitted_time}} </div>
                          </div>
                          <div class="card-body">
                            <div class="col-md-12 p-0 float-left">

                              <ng-container *ngFor="let fle of mySubmission.submitted_docs; let j = index">

                                <div class="mb-0 text-left p-1 float-left download_sub"> <a href='{{s3FilePath}}{{fle}}'
                                    class="btn btn-block btn-outline-success" target="_blank" download><i
                                      class="fas fa-file-download mr-2"></i> Download ({{j + 1}}) </a></div>

                              </ng-container>
                            </div>
                            <div class="col-md-3 p-0 float-left" *ngIf="mySubmission.validation_status || isEdit">
                              <div class="form-group">
                                <label>Score</label>
                                <input type="number" min="0" placeholder="Score" class="form-control"
                                  [(ngModel)]="scoreAndComment.score">
                              </div>
                              <div class="clearfix"></div>
                              <div class="form-group">
                                <label>Comments</label>
                                <textarea class="form-control" rows="3" placeholder="Enter ..." autocomplete="off"
                                  [(ngModel)]="scoreAndComment.comment"></textarea>
                              </div>
                              <div class="clearfix"></div>
                              <div class="p-3">
                                <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right"
                                  [disabled]="!scoreAndComment.score"
                                  (click)="onSubmit(mySubmission.enrollment_activity_id)">Submit</button>
                              </div>

                            </div>

                            <div class="col-md-12 p-0 float-left" *ngIf="!(mySubmission.validation_status) && !isEdit">
                              <div class="my-2">
                                <!-- selectedContent.submissions.length -1 -->
                                <h3>Comments <a (click)="onEdit(mySubmission.score, mySubmission.sme_comment)"
                                    class="btn btn-outline-primary btn-xs float-right" *ngIf="0 == i"><i
                                      class="far fa-edit mr-2"></i> Edit</a> </h3>
                                <div class="clearfix"></div>
                              </div>
                              <p>{{mySubmission.sme_comment}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="content_resource_a" *ngIf="selectedContent.course_content_type_id == 5">
            <!-- <h1>{{selectedContent.title}}</h1>
            <hr class="py-2">
            <div class="accordion overview_list assignment_attempt" id="accordionExample2">
              <ng-container *ngFor="let mySubmission of selectedContent.attempts; let i = index">
                <div class="card">
                  <div class="card-header bg-white p-0" id="headingTwo{{i}}">
                    <h3 class="mb-0">
                      <div class=" text-left p-3">
                        <div class="attempt float-left">
                         {{mySubmission.attempt}}</div>
                        <div class="float-left py-2 px-2 my-2 ml-3 score_assignment_sec">Score : {{mySubmission.score}}
                        </div>
                        <div class="float-left py-2 px-2 my-2 ml-1 attempt_failed"
                          *ngIf="mySubmission.evaluation_test_result == 2"> Status : <span>Failed</span></div>
                        <div class="float-left py-2 px-2 my-2 ml-1 attempt_success"
                          *ngIf="mySubmission.evaluation_test_result != 2"> Status : <span>Pass</span></div>
                        <div class="float-right py-2 my-2 mx-1 attempt_date">{{mySubmission.attempted_date}} </div>
                        <div class="clearfix"></div>
                      </div>
                    </h3>
                  </div>
                </div>
              </ng-container>
            </div> -->

            <ng-container *ngIf="examResultData.length>0">
              <div class="accordion_scrool">
                <div class="accordion overview_list " id="accordionExample">
                  <ng-container *ngFor="let mySubmission of examResultData; let i = index">
                    <div class="card shadow-none">
                      <div class="card-header bg-white p-0" id="headingOne{{i}}">
                        <h3 class="mb-0">
                          <button class="btn btn-block text-left py-1 px-2" type="button" data-toggle="collapse"
                            [attr.data-target]="'#collapseOne' + i" aria-expanded="true"
                            aria-controls="collapseOne{{i}}">
                            Attempt {{mySubmission.attempt_no}}</button>
                        </h3>
                      </div>

                      <div id="collapseOne{{i}}" class="collapse fade" aria-labelledby="headingOne{{i}}"
                        data-parent="#accordionExample">
                        <div class="card-body popup_innerscroll">


                          <ul class="overview_listing col-lg-12 col-xl-12  col-md-12 col-sm-12 m-0 p-0">
                            <div class="card">
                              <div class="card-header bg-white p-0" id="headingTwo{{i}}">
                                <h3 class="mb-0">
                                  <div class=" text-left p-3">
<!--score_assignment_sec-->

<div class="float-left my-1 mr-2 ml-1 score_assignment_sec borderright">Score :
                                      {{mySubmission.total_marks_obtained}}
                                    </div>
 <!--attempt_failed-->                                   
<div class="float-left my-1 mr-2 ml-1  attempt_failed borderright"
                                      *ngIf="mySubmission.examination_status == 'Failed'"> Status : <span>{{mySubmission.examination_status}}</span>
                                    </div>
  <!--attempt_failed-->                                    
 <div class="float-left my-1 mr-2 ml-1  attempt_success borderright"
                                      *ngIf="mySubmission.examination_status != 'Failed'"> Status : <span>{{mySubmission.examination_status}}</span></div>
                          
<div class="float-left my-1 mr-2 ml-1 borderright">Grade per question: {{mySubmission.grade_per_question}} </div>
<div class="float-left my-1 mr-2 ml-1 borderright">Minimum grade percentage: {{mySubmission.minimum_grade_percentage}}  </div>     
<div class="float-left my-1 mr-2 ml-1 borderright">No of correct answers: {{mySubmission.no_of_correct_answers}}  </div>
<div class="float-left my-1 mr-2 ml-1 borderright">No of questions attended: {{mySubmission.no_of_questions_attended}} </div>
<div class="float-left my-1 mr-2 ml-1 ">Total no of questions: {{mySubmission.total_no_of_questions}} </div>

                                    <div class="clearfix"></div>

    <h3 class="q_title">Questions</h3>

                                    <ng-container *ngFor="let examResult of mySubmission.result; let i = index">
                                      <ng-container *ngIf="examResult.question_type == '1'">
                                       <div [innerHTML]="examResult.question"> </div>
                                       <div > 
                                        Answer Choosed: <span [innerHTML]="examResult.chosen_option"></span>
                                       </div>
                                       <div > 
                                        Correct Answer: <span [innerHTML]="examResult.correct_answer"></span>
                                       </div>
                                       <br/>
                                       <div class="clearfix"></div>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </h3>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</ng-template>


<ng-template #feedBack let-modal>
  <div class="content-wrapper full_width">
    <div class="modal-header">

      <h2 class="modal-title mb-0">Feedback</h2>
      <button type="button" class="close" aria-describedby="modal-title" matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>

    </div>
    <div class="modal-body">
      <p><span class="star_icons rating_a btn btn-outline-warning btn-smns st_p"> <i
            *ngFor="let fullstar of [].constructor(ratings)" class="fa fa-star ratefont" aria-hidden="true"></i> <i
            *ngIf="(ratings%1)!=0" class="fa fa-star-half-o ratefont" aria-hidden="true"></i>
          <i *ngFor="let emptystar of [].constructor(5 - ratings) " class="fa fa-star-o ratefont"
            aria-hidden="true"></i>
        </span></p>
      <hr class="my-3">
      <p>{{comments}} </p>


    </div>
    <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" matDialogClose >Cancel</button>
    <button type="button" class="btn btn-danger" >Register</button>
  </div> -->
  </div>
</ng-template>
