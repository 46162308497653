<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/page']">Page Managements</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add Page</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit Page</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add Page</h1>
        <h1 *ngIf="isEdit">Edit Page</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="pageForm" (ngSubmit)="onSubmit()">
                <div class="form-group col-md-3 float-left">
                  <label>Title *</label>
                  <input type="text" formControlName="title" class="form-control selact_"
                         placeholder="Enter Title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                  <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                    <div *ngIf="f.title.errors.required">
                      Title is
                      required
                    </div>
                  </div>
                </div>

                <div class="form-group col-md-3 float-left">
                  <label>Status *</label>
                  <select class="form-control selact_" formControlName="status" style="width: 100%;" placeholder="Select Status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                    <option value="1">Active</option>
                    <option value="2">Disabled</option>
                  </select>
                  <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                    <div *ngIf="f.status.errors.required">
                      Status is
                      required
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="form-group col-md-6 float-left">
                  <label>Meta Keyword *</label>
                  <input type="text" formControlName="metakeyword" class="form-control selact_"
                         placeholder="Enter Meta Keyword" [ngClass]="{ 'is-invalid': submitted && f.metakeyword.errors }">
                  <div *ngIf="submitted && f.metakeyword.errors" class="invalid-feedback">
                    <div *ngIf="f.metakeyword.errors.required">
                      Meta Keyword is
                      required
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="form-group col-md-6 float-left">
                  <label>Meta Description *</label>
                  <textarea class="form-control selact_" formControlName="metadescription"
                            placeholder="Enter Meta Description"
                            [ngClass]="{ 'is-invalid': submitted && f.metadescription.errors }"></textarea>
                  <div *ngIf="submitted && f.metadescription.errors" class="invalid-feedback">
                    <div *ngIf="f.metadescription.errors.required">
                      Meta Description is
                      required
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="form-group col-md-6 float-left">
                  <label>Description</label>
                  <!-- <angular-editor [config]="editorConfig" formControlName="description"
                                  placeholder="Enter Description"
                                  [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></angular-editor> -->
                                  <ckeditor formControlName="description"  [config]="ckeConfig"></ckeditor>
                  <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                    <div *ngIf="f.description.errors.required">
                      Description is
                      required
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit"
                          class="btn btn-secondary btn-dark bor-rad_ float-right">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
</div>
