import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service';
import { Title, Meta } from "@angular/platform-browser";
import { Validatespace } from "../../validation/spacevalidation";
import { phoneNumberValidator } from "../../validation/phone-validator";

@Component({
  selector: 'app-sme-add',
  templateUrl: './sme-add.component.html',
  styleUrls: ['./sme-add.component.css']
})
export class SmeAddComponent implements OnInit {
  selectedFile: File;
  isEdit: boolean = false;
  session_id: any;
  imageSrc: any;
  smeId: any;
  fileName = '';
  p: number = 1;
  s3FilePath: string;
  namePrefix: any = [];
  

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService, private uploadService: UploadService, private constantService: ConstantsService, private title: Title, private meta: Meta) { }
  smeForm: FormGroup;
  submitted = false;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;
    // (?!.*?[^aeiou]{5})(?!.*?[aeiou]{5})
    this.smeForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validatespace,  Validators.pattern(/^[a-zA-Z ._-]*$/),  Validators.minLength(2)]],
      last_name: ['', [Validators.required, Validatespace, Validators.pattern(/^[a-zA-Z ._-]*$/)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validatespace]],
      phone_number: ['',
      [
        Validators.required,
        Validatespace,
        phoneNumberValidator,
        Validators.minLength(8),
        Validators.maxLength(12),
      ]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      status: ['1', Validators.required],
      description: ['', [Validators.required, Validatespace]],
      fileData: ['', Validators.required],
      namePrefixes:['0', Validators.required],
    });
    this.getNamePrefix();
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.id) {
        this.isEdit = true;
        this.selectedFile = null;
        this.smeId = params.id;
        this.spinner.show();

        var single_url = "edit_sme?sme_id=" + `${params.id}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          debugger
          console.log(data)
          if (data.status == "TRUE" && data.SME) {
            this.spinner.hide();

            this.smeForm.get('namePrefixes').setValue(data.SME.name_prefix);
            this.smeForm.get('first_name').setValue(data.SME.first_name);
            this.smeForm.get('last_name').setValue(data.SME.last_name);
            this.smeForm.get('email').setValue(data.SME.email);
            this.smeForm.get('phone_number').setValue(data.SME.phone_number);
            this.smeForm.get('password').setValue(data.SME.password);
            this.smeForm.get('description').setValue(data.SME.description);
            this.smeForm.get('status').setValue(data.SME.status_id);
            this.smeForm.get('fileData').setValidators(null);
            this.smeForm.get('fileData').setValue("");
            this.imageSrc = this.s3FilePath + data.SME.profile_pic;
            console.log(this.smeForm);
            console.log(this.smeForm);

            //remove password validation in edit mode
            this.smeForm.controls['password'].clearValidators();
            this.smeForm.controls['password'].updateValueAndValidity();

          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
          this.title.setTitle("Edit SME | Admin | Al Jamia ");
          this.meta.addTag({
          name: "description",
          content: "Edit SME | Admin ",
        });
      }
      else {
        this.isEdit = false;
        this.spinner.hide();
        this.title.setTitle("Add SME | Admin | Al Jamia ");
        this.meta.addTag({
          name: "description",
          content: "Add SME | Admin ",
        });

      }
    }
    );
  }


  public hasError = (controlName: string, errorName: string) => {
    return this.smeForm.get(controlName).hasError(errorName);
  };



  get f() { return this.smeForm.controls; }
  onFileChanged(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      console.log(this.selectedFile);

      this.selectedFile = event.target.files[0];
      console.log(this.selectedFile.name);
      let imagePath_ = this.selectedFile.type;
      var ext = imagePath_.split('/');
      if (ext[0] == "image") {

      reader.onload = () => {

        this.imageSrc = reader.result as string;


      }
      reader.readAsDataURL(this.selectedFile);
      console.log(this.selectedFile)
    } else {
      this.smeForm.get('fileData').setValue('');
      this.smeForm.get('fileData').setErrors({ required: true });
      this.imageSrc = ""
    }

    }
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.f)
    debugger
    var s3FileName;
    const invalid = [];
    const controls = this.smeForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    if (this.smeForm.invalid) {
      return;
    }
    if(this.f.namePrefixes.value == 0){
      return;
    }
    this.spinner.show();

    const uploadData = new FormData();
    debugger
    uploadData.append('first_name', this.f.first_name.value);
    uploadData.append('last_name', this.f.last_name.value);
    uploadData.append('email', this.f.email.value);
    uploadData.append('phone_number', this.f.phone_number.value);
    uploadData.append('password', this.f.password.value);
    uploadData.append('description', this.f.description.value);
    uploadData.append('status_id', this.f.status.value);
    uploadData.append('name_prefix', this.f.namePrefixes.value);
    if (this.selectedFile == null) {
      debugger
      uploadData.append('profile_pic', this.imageSrc.replace(this.s3FilePath, ''));
      this.addEditSubmit(uploadData);
    }
    else {
      // this.uploadService.fileDelete(file, 'sme')

      this.uploadService.fileUpload(this.selectedFile, 'sme').then(res => {
        debugger
        if (res) {
          debugger
          s3FileName = res;
          uploadData.append('profile_pic', s3FileName);
          this.addEditSubmit(uploadData);

        }
      });
    }
  }
  addEditSubmit(uploadData) {
    debugger

    var fetch_url = null;
    if (this.isEdit) {
      fetch_url = "update_sme";
      uploadData.append('sme_id', this.smeId);
    }
    else {
      fetch_url = "add_sme";
    }
    this.api.detailsUploadFetch(uploadData, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      console.log(data)
      if (data.status == "TRUE") {
        this.spinner.hide();
        this.imageSrc = "";
        this.smeForm.reset();
        this.submitted = false;
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        this.router.navigate(['admin/sme'])
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup()

      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }
  getNamePrefix(){
    var url = "getNamePrefixes";
    this.api.detailsGet(url).subscribe((data : any) =>{
      if(data.status == "true" && data.name_prefixes){
        this.namePrefix = data.name_prefixes;
      }
    },
    (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }
}
