import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validatespace } from "src/app/validation/spacevalidation";
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-support-enquiry',
  templateUrl: './support-enquiry.component.html',
  styleUrls: ['./support-enquiry.component.css']
})
export class SupportEnquiryComponent implements OnInit {

  constructor(private spinner:NgxSpinnerService, public api:ApiService, private router:Router, private fb: FormBuilder, private ToasterService: ToasterService,) {
    this.supportformGroup = fb.group({
      title : ['', [Validators.required, Validators.maxLength(60), Validators.pattern('[a-zA-Z ]*'), Validatespace]],
      message : ['', [Validators.required, Validators.maxLength(80), Validatespace]],
    });
   }

  session_id: any;
  supportformGroup:FormGroup;
  issubmitted : boolean = false;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('access_token');
  }

  onSubmit(){
    this.issubmitted = true;
    if(this.supportformGroup.invalid){
      return;
    }
    else{
      this.spinner.show();
      var body = {problem_description:this.supportformGroup.value.message, title: this.supportformGroup.value.title}
      var url ="ask_for_support";
      this.api.detailsUploadFetch(body,url,this.session_id).subscribe((data:any)=>{
        if(data.status == "true"){
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'success');
          this.router.navigate([''])
        }else{
          this.spinner.hide();
          this.ToasterService.openSnackBar(data.message, '', 'warning');
        }

      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate([''])
        }
      });
    }
  }

}
