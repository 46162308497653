import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from '../../services/constants.service'
import { Page } from '../../models/pageDto';
import { PrimeNGConfig } from 'primeng/api';

declare var $: any;



@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
 
})
export class QuestionComponent implements OnInit {
  display: boolean = false;

  showDialog() {
    this.display = true;
  }
  @ViewChild('myTable') table: QuestionComponent;
  session_id: any;
  searchTerm: any = '';
  filterQuestionType: any = ''
  filterQuestionCategory: any = ''
  filterDifficultyLevel: any = ''
  questionTypeList: any = [];
  questionCategoryList: any = [];
  difficultyLevelList: any = [];
  p: number = 1;
  selected = [];
  s3FilePath: string; 
  questionList: any = [];
  temp = [];
  page: Page;
  deleteList: any = [];
  

  //constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService, private constantService: ConstantsService) { }

  constructor(public primengConfig: PrimeNGConfig, public api: ApiService, private formBuilder: FormBuilder, private router: Router,  private popup: PopupService, private spinner: NgxSpinnerService, private constantService: ConstantsService) { }
  questionForm: FormGroup;
  ngOnInit() {
    this.primengConfig.ripple = true;
    
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.init();
    this.questionForm = this.formBuilder.group({
      search: [''],
      questionType: ['0'],
      questionCategory: ['0'],
      difficultyLevel: ['0']

    });
    this.s3FilePath = this.constantService.s3FilePath;
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page:10
    };
    this.setPage({ offset: 0 });
    //this.init();
  }
  displayModal: boolean;

  showModalDialog() {
    this.displayModal = true;
  }

  init() {
    this.spinner.show();
    this.getQuestionTypes();
    this.getQuestionCategories();
    this.getDifficultyLevels();
  }

  getQuestionTypes() {
    this.questionTypeList = [];
    var list_fetch_url = "getQuestiontypes";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.question_types[0]) {
        this.questionTypeList = data.question_types;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getQuestionCategories() {
    this.questionCategoryList = [];
    var list_fetch_url = "getQuestioncategories";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.question_categories[0]) {
        this.questionCategoryList = data.question_categories;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getDifficultyLevels() {
    this.difficultyLevelList = [];
    var list_fetch_url = "getDifficultylevels";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.difficulty_levels[0]) {
        this.difficultyLevelList = data.difficulty_levels;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  onSearch() {
    this.searchTerm = this.questionForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

  onQuestionTypeFilter() {
    this.filterQuestionType = this.questionForm.get('questionType').value > 0 ? this.questionForm.get('questionType').value.toString() : '';
    this.setPage({ offset: 0 });
  }

  onQuestionCategoryFilter() {
    this.filterQuestionCategory = this.questionForm.get('questionCategory').value > 0 ? this.questionForm.get('questionCategory').value.toString() : '';
    this.setPage({ offset: 0 });
  }

  onDifficultyLevelFilter() {
    this.filterDifficultyLevel = this.questionForm.get('difficultyLevel').value > 0 ? this.questionForm.get('difficultyLevel').value.toString() : '';
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }
  //init() {
  setPage(pageInfo) {
    
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.questionList = [];

    var questions_fetch_url = "getQuestions?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page ;
    if (this.searchTerm.length > 0)
      questions_fetch_url += "&searchTerm=" + this.searchTerm;

    if (this.filterQuestionType.length > 0)
      questions_fetch_url += "&type=" + this.filterQuestionType;

    if (this.filterQuestionCategory.length > 0)
      questions_fetch_url += "&category_id=" + this.filterQuestionCategory;

    if (this.filterDifficultyLevel.length > 0)
      questions_fetch_url += "&difficulty_level=" + this.filterDifficultyLevel;

    this.api.detailsGetFetch(questions_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.questions[0]) {
        this.spinner.hide();
        
        this.temp = [...data.questions];
        this.questionList = data.questions;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
       }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  
  deleteQuestion(id) {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        var body = { "question_id": +id };
        var del_url = "delete_question";
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            //this.init();
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup()
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }

  deleteMultipleItem(){
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteList.push(element.id);
      });
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          var body = { "question_ids": this.deleteList };
          var del_url = "question_multi_delete";
          this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
            debugger
            this.deleteList = [];
            if (data.status == "True") {
              this.spinner.hide();
              this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
              this.setPage({ offset: 0 });
            }
            else {
              this.spinner.hide();
              this.popup.failureMessage = data.message
              this.popup.failurepopup()
            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
  
                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            }); 
        }else{
          this.deleteList = [];
        }
      })
    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    } 
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
     const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || status_name ;
    });

    // update the rows
    this.questionList = temp;
    // this.table.offset = 0;
  }
  editQuestion(id) {
    this.router.navigate(['admin/question-add', { id: `${id}` }]);

  }
}
