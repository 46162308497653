 <div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/student']">Students</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">View Student</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">View Student</li>

          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1>View Student</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="studentForm">
                <div class="col-md-6 float-left">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" formControlName="name" class="form-control selact_" disabled>
                  </div>
                  <div class="form-group">
                    <label>Email Address</label>
                    <input type="text" formControlName="email" class="form-control selact_" disabled>
                  </div>
                  <div class="form-group">
                    <label>Postal Address</label>
                    <!-- <input type="text" formControlName="address" class="form-control selact_" disabled> -->
                    <textarea class="form-control" rows="3" formControlName="address" autocomplete="off" disabled>
                                  </textarea>
                  </div>
                  <div class="form-group">
                    <label>Date of Birth</label>
                    <input type="text" formControlName="date_of_birth" class="form-control selact_" disabled>
                  </div>
                  <div class="form-group">
                    <label>Mobile Number</label>
                    <input type="text" formControlName="phone_number" class="form-control selact_" disabled>
                  </div>
                  <div class="form-group">
                    <label>WhatsApp Number</label>
                    <input type="text" formControlName="whatsapp_number" class="form-control selact_" disabled>
                  </div>
                  <div class="form-group">
                    <label>Qualification</label>
                    <input type="text" formControlName="qualification" class="form-control selact_" disabled>
                  </div>
                  <!-- <div class="form-group" *ngIf="!isOtherQualification">
                    <label>Stream</label>
                    <input type="text" formControlName="stream" class="form-control selact_" disabled>
                  </div>
                  <div class="form-group" *ngIf="isOtherQualification">
                    <label>Qualification and Stream</label>
                    <input type="text" formControlName="otherQualification" class="form-control selact_" disabled>
                  </div> -->
                  <div class="form-group">
                    <label>Nationality</label>
                    <input type="text" formControlName="nationality" class="form-control selact_" disabled>
                  </div>
                  <!-- <div class="form-group">
                                  <label>District</label>
                                  <input type="text" formControlName="district" class="form-control selact_" disabled>
                                </div>
                                <div class="form-group">
                                  <label>State</label>
                                  <input type="text" formControlName="state" class="form-control selact_" disabled>
                                </div> -->
                                <div class="form-group">
                                  <label>Country of Residence</label>
                                  <input type="text" formControlName="country" class="form-control selact_" disabled>
                                </div>
                  <div class="form-group">
                    <label>Status</label>
                    <select formControlName="status" class="form-control select2 selact_" style="width: 100%;" disabled>
                      <option value="1">Active</option>
                      <option value="2">Disabled</option>
                    </select>
                    <!-- <input type="text" formControlName="status" class="form-control selact_" disabled> -->
                  </div>
                </div>
                <!-- <div class="clearfix"></div> -->
                <div class="col-md-4 float-left">
                  <div class="form-group wid_75">
                    <label>Student Image</label>
                    <div class="uploadedImg student-image-profile student_image_a">
                      <!-- <input type="file" class="mediaFile" formControlName="fileData" id="exampleInputFile"
                        [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }" accept="image/*" disabled> -->
                      <!--<span class="up_img">Upload image</span>-->

                      <img [src]="imageSrc" *ngIf="imageSrc">
                      <div class="clearfix"></div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="form-group wid_75">
                    <!-- <label>Student Image</label> -->
                    <!-- <div class="uploadedImg">
                                          view
                                      </div> -->
                    <div class="form-group">
                      <div class="text-center student_id">
                        <a href="{{s3FilePath }}{{idProof}}" class="btn btn-outline-secondary btn-sm mx-auto mt-3"
                          data-toggle="lightbox" data-title="sample 2 - black" target="_blank" download>
                          <!--<img src="{{s3FilePath }}{{studentDetails.identity_proof}}" alt="User Image">-->
                          <i class="far fa-id-badge mr-1"></i> ID Proof
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

               <!-- <div class="col-md-4 float-left"> 
                

                </div>-->
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>
