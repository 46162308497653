<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/courseCategory']">Course Categories</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add Course Category</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit Course Category</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add Course Category</h1>
        <h1 *ngIf="isEdit">Edit Course Category</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
                <div class="col-md-6">

                  <div class="form-group ">
                    <label>Category *</label>

                    <input type="text" formControlName="category" class="form-control selact_ c"
                      placeholder="Category Name" [ngClass]="{ 'is-invalid': submitted && f.category.errors }">

                    <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                      <div *ngIf="f.category.errors.required">Category Name is
                        required
                      </div>
                      <div *ngIf="f.category.errors.validspace"> Invalid Category Name
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Parent Category </label>

                    <select name="parentCategory" class="form-control select2 selact_" formControlName="parentCategory"
                      style="width: 100%; " [ngClass]="{ 'is-invalid': submitted && f.parentCategory.errors }">
                      <option value="" > --Select Parent Category-- </option>
                      <option value="0" > None </option>
                      <option *ngFor="let items of categoryList" [value]="items.id">
                        {{items.title}}
                      </option>

                    </select>
                    <div *ngIf="submitted && f.parentCategory.errors" class="invalid-feedback">
                      <div *ngIf="f.parentCategory.errors.required">Parent Category is
                        required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Status *</label>
                    <select class="form-control select2 selact_" formControlName="status" style="width: 100%;"
                      [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                      <option value="1">Active</option>
                      <option value="2">Disabled</option>
                    </select>
                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                      <div *ngIf="f.status.errors.required">Status is
                        required
                      </div>
                    </div>
                  </div>
                  <!-- <div class="clearfix"></div> -->
                  <!-- <div class="form-group">
                                        <label>Description</label>
                                        <textarea class="form-control selact_" formControlName="description"
                                            placeholder="Description"
                                            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                            <div *ngIf="f.description.errors.required">Description is
                                                required
                                            </div>
                                        </div>
                                    </div> -->
                </div>
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
</div>
