import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseElementComponent } from './course-element.component';
import { CourseElementRoutingModule } from './course-element-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';  
@NgModule({
  declarations: [CourseElementComponent ],
  imports: [
    CommonModule,
    CourseElementRoutingModule,
    FormsModule, ReactiveFormsModule, NgxPaginationModule, NgxDatatableModule,
    DialogModule,
    ButtonModule
   ]
})
export class CourseElementModule { }
