import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StreamAddComponent } from './stream-add.component';
import { StreamAddRoutingModule } from './stream-add-routing.module';
 

@NgModule({
  declarations: [StreamAddComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    StreamAddRoutingModule,
 
   ]
})
export class StreamAddModule { }
