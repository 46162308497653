import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageAddComponent } from './page-add.component';
import { PageAddRoutingModule } from './page-add-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ng2-ckeditor';

@NgModule({
  declarations: [PageAddComponent],
  imports: [
    CommonModule,
    PageAddRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    CKEditorModule
  ]
})
export class PageAddModule { }
