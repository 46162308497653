import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service'
import { Title, Meta } from "@angular/platform-browser";
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-country-add',
  templateUrl: './country-add.component.html',
  styleUrls: ['./country-add.component.css']
})
export class CountryAddComponent implements OnInit {
  selectedFile: File;
  isEdit: boolean = false;
  session_id: any;
  imageSrc: any;
  countryId: any;
  fileName = '';
  p: number = 1;
  s3FilePath: string;

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute,
    private popup: PopupService, private spinner: NgxSpinnerService, private uploadService: UploadService,
    private constantService: ConstantsService, private title: Title, private meta: Meta,) { }
  countryForm: FormGroup;
  submitted = false;

  ngOnInit(): void {
    debugger
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.s3FilePath = this.constantService.s3FilePath;


    this.countryForm = new FormGroup({
      countryName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*'), Validatespace]),
      status: new FormControl('', [Validators.required])
      // fileData: ['']
    });
    this.countryForm.get('status').setValue('1');
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.selectedFile = null;
        this.countryId = params.dist;
        this.spinner.show();

        var single_url = "country_edit?country_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          debugger
          if (data.status == "true" && data.country[0]) {
            debugger
            this.spinner.hide();

            this.countryForm.get('countryName').setValue(data.country[0].title);
            this.countryForm.get('status').setValue(data.country[0].status_id);
            // this.countryForm.get('fileData').setValidators(null);
            // this.countryForm.get('fileData').setValue("");
            // this.imageSrc = data.country[0].country_pic;
            console.log(this.countryForm);
            console.log(this.countryForm);
          }
          else {
            this.spinner.hide();
            debugger

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
        this.title.setTitle("Edit Country | Admin");
        this.meta.addTag({
          name: "description",
          content: "Edit Country | Admin | Al Jamia",
        });
      }
      else {
        this.isEdit = false;
        this.title.setTitle("Add Country | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Add Country | Admin",
        });
        this.spinner.hide();

      }
    }
    );
  }
  get f() { return this.countryForm.controls; }
  // onFileChanged(event) {
  //   const reader = new FileReader();
  //   if (event.target.files && event.target.files.length) {
  //     console.log(this.selectedFile);

  //     this.selectedFile = event.target.files[0];
  //     console.log(this.selectedFile.name);

  //     reader.onload = () => {

  //       this.imageSrc = reader.result as string;


  //     }
  //     reader.readAsDataURL(this.selectedFile);
  //     console.log(this.selectedFile)

  //   }
  // }
  onSubmit() {
    this.submitted = true;
    console.log(this.f)

    if (this.countryForm.invalid) {
      return;
    }

    // const file = this.selectedFile;

    // if (file != null)
    //   var s3FileName
    // this.uploadService.fileUpload(file, 'country').then(res => {
    //   if (res) {
    //     s3FileName = res

    if (!this.isEdit) {
      this.spinner.show();
      const uploadData = new FormData();
      // uploadData.append('country_pic', s3FileName);
      uploadData.append('title', this.f.countryName.value);
      uploadData.append('status_id', this.f.status.value);

      // var body = { "title": this.f.countryName.value, "status_id": +this.f.status.value, "country_pic": uploadData };
      var url = "add_country";
      this.api.detailsUploadFetch(uploadData, url, this.session_id).subscribe((data: any) => {
        debugger

        console.log(data)
        if (data.status == "TRUE") {
          this.spinner.hide();

          // this.imageSrc = "";
          this.countryForm.reset();
          this.submitted = false;
          this.popup.sucessMessage = data.message
          this.popup.sucesspopup();
          this.router.navigate(['admin/country'])
        }
        else {
          this.spinner.hide();
          this.popup.failureMessage = data.message
          this.popup.failurepopup();
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else {
      if (this.isEdit) {
        this.spinner.show();

        const uploadData = new FormData();
        console.log(this.selectedFile)
        // if (this.selectedFile == null) {
        //   uploadData.append('country_pic', this.imageSrc.replace(this.s3FilePath, ''));
        // }
        // else {
        //   uploadData.append('country_pic', s3FileName);
        // }
        uploadData.append('country_id', this.countryId);
        uploadData.append('title', this.f.countryName.value);
        uploadData.append('status_id', this.f.status.value);

        var update_url = "update_country";
        this.api.detailsUploadFetch(uploadData, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.imageSrc = "";
            this.countryForm.reset();
            this.submitted = false;

            this.popup.sucessMessage = data.message;
            this.popup.sucesspopup();
            this.router.navigate(['admin/country'])
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message;
            this.popup.failurepopup();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
  }
  //     });
  //   }
}
