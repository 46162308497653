import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CurrencyAddComponent } from './currency-add.component';
import { CurrencyAddRoutingModule } from './currency-add-routing.module';


@NgModule({
  declarations: [CurrencyAddComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    CurrencyAddRoutingModule,
   ]
})
export class CurrencyAddModule { }
