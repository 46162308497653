<div class="card  my-3 border-0 shadow-sm">
  <!-- Page Content -->
  <div class="card-body">
    <form [formGroup]="assessmentForm" role="form" class="content_resource">
      <!-- <div class="discussion_content"> -->
      <!-- <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div class="card  my-5 border-0 shadow-none"> -->
      <ng-container *ngIf="assessmentTestDetails && !isFinishTest">
        <div class="card-body quiz_assign">
          <h1>{{assessmentDetail.contents.title}}</h1>
          <ng-container *ngIf="!isTestStart">
            <hr class="py-1">
            <!-- <div class="quiz_start pb-5">
              <p>{{assessmentDetail.contents.title}}</p>
            </div> -->
            <div class="accordion overview_list assignment_attempts_list" id="accordionExample3"
              *ngIf="assessmentSubmissions.length != 0">
              <div class="card">
                <div class="card-header bg-white p-0" id="headingThree">
                  <h3 class="mb-0">
                    <button class="btn btn-block text-left p-3 pl-5 btn_bg" type="button" data-toggle="collapse"
                      [attr.data-target]="'#collapseThree'" aria-expanded="true" aria-controls="collapseThree">
                      Attempts</button>
                  </h3>
                </div>
                <div id="collapseThree" class="collapse fade" aria-labelledby="headingThree"
                  data-parent="#accordionExample3">
                  <div class="card-body">
                    <div class="accordion overview_list assignment_attempt" id="accordionExample2">
                      <ng-container *ngFor="let mySubmission of assessmentSubmissions; let i = index">
                        <div class="card">
                          <div class="card-header bg-white p-0" id="headingTwo{{i}}">
                            <h3 class="mb-0">
                              <div class=" text-left p-3 pl-5 btn_bg">
                                <div class="attempt float-left">
                                  <!--Attempt :-->{{mySubmission.attempt}}</div>
                                <div class="float-left py-2 px-2 my-2 ml-3 score_assignment_sec">Score :
                                  {{mySubmission.score}}</div>
                                <div class="float-left py-2 px-2 my-2 ml-1 attempt_failed"
                                  *ngIf="mySubmission.evaluation_test_result == 'Failed'"> Status :
                                  <span>{{mySubmission.evaluation_test_result}}</span></div>
                                <div class="float-left py-2 px-2 my-2 ml-1 attempt_success"
                                  *ngIf="mySubmission.evaluation_test_result != 'Failed'"> Status :
                                  <span>{{mySubmission.evaluation_test_result}}</span></div>
                                <div class="float-right py-2 my-2 mx-1 attempt_date">{{mySubmission.submitted_date}}
                                  {{mySubmission.submitted_time}}</div>
                                <div class="clearfix"></div>
                              </div>
                            </h3>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ng-container>

          <div class="w-100 py-1 mt-1 quiz_a_ border-top uplode_assig " *ngIf="assessmentTestDetails.attempt">
            <ul class="px-3 width_ add_course_ quiz_list_d">
              <ng-container *ngIf="!isTestStart">
                <li class="qst_number">QUESTIONS : {{assessmentTestDetails.number_of_questions}}</li>
              </ng-container>
              <li class="points_q">TOTAL POINTS :
                {{ assessmentTestDetails.number_of_questions * assessmentTestDetails.grade_per_question}}</li>
              <ng-container *ngIf="!isTestStart">
                <li class="time_q">QUIZ TIME : {{assessmentTestDetails.time_limit}} Min</li>
              </ng-container>
              <li class="total_q">TO PASS {{assessmentTestDetails.pass_percentage}}% or higher</li>
              <ng-container *ngIf="isTestStart">
                <li class="qst_number">QUESTIONS : {{selectedQuestionIndex}} /
                  {{assessmentTestDetails.number_of_questions}}
                </li>
              </ng-container>
              <ng-container *ngIf="isTestStart">
                <li class="time_q">ELAPSED TIME : {{elapsedTime}} Min</li>
              </ng-container>
            </ul>

            <div class="clearfix"></div>

          </div>

          <div class="qiz_img w-100 text-center pt-5" *ngIf="!isTestStart && assessmentTestDetails.attempt">
            <img src="../../../assets/web/assets/images/q_ab_.png" width="150" class="mx-auto img-fluid mt-4 pt-3" />

            <div class="pt-3 pb-3 mt-0 text-left text-center">
              <a class="btn btn-dark mx-auto btn-qui" (click)="startTest()">Quiz Start</a>
              <div class="clearfix"></div>
            </div>
          </div>


          <ng-container *ngIf="isTestStart">
            <div class="question_container py-4">

              <ng-container *ngIf="isOptionType">
                <div class="col-md-12">
                  <label id="example-radio-group-label" class="test_question float-left"> {{selectedQuestionIndex}} :
                    {{selectedQuestion.question_text}}</label></div>
                <div class="clearfix"></div>
                <ng-container *ngFor="let item of selectedQuestion.media_files">
                  <ng-container *ngIf="item.media_type == 1">
                    <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                      <span class="rounded text-center">
                        <img src="{{s3FilePath}}{{item.path}}" class="mx-auto image_quz img-fluid"></span></div>
                  </ng-container>
                  <ng-container *ngIf="item.media_type == 2">
                    <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                      <span class="rounded">
                        <audio controls class="audio_quz audio_q_t">
                          <source src="{{s3FilePath}}{{item.path}}" width="100%" type="audio/mpeg">
                        </audio>
                      </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.media_type == 3">
                    <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                      <span class="rounded">
                        <video width="100%" height="125" controls>
                          <source src="{{s3FilePath}}{{item.path}}">
                        </video>
                      </span>
                    </div>
                  </ng-container>
                </ng-container>
                <div class="clearfix"></div>
                <mat-radio-group aria-labelledby="example-radio-group-label" (change)="optionChange()"
                  [formControl]="formTestOption" class="example-radio-group radio_btn_">
                  <mat-radio-button class="example-radio-button" *ngFor="let option of selectedQuestion.options"
                    [value]="option">
                    <ng-container *ngIf="option.option_media_type == 4">
                      <div class="col-md-12 col-sm-12 float-left mb-1 qst_type_">
                      <div class="float-left"> {{option.option}} </div>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="option.option_media_type == 1">
                      <div class="col-md-12 col-sm-12 float-left mb-1 qst_type_">
                        <span class="rounded text-center">
                      <img src="{{s3FilePath}}{{option.option}}" class="mx-auto image_quz img-fluid image_quz_ans">
                      </span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="option.option_media_type == 2">
                      <div class="col-md-12 col-sm-12 float-left mb-1 qst_type_ qust_audio_a">
                        <span class="rounded">
                          <audio controls class="my-4">
                        <source src="{{s3FilePath}}{{option.option}}" type="audio/mpeg">
                      </audio>
                      </span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="option.option_media_type == 3">
                      <div class="col-md-12 col-sm-12 float-left mb-1 qst_type_">
                        <span class="rounded">
                          <video width="100%" height="125" controls>
                        <source src="{{s3FilePath}}{{option.option}}">
                      </video>
                      </span>
                      </div>
                    </ng-container>
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>


              <ng-container *ngIf="isMatchingOptions">
                <label id="example-radio-group-label" class="test_question float-left"> {{selectedQuestionIndex}} :
                  {{selectedQuestion.question_text}}</label>
<div class="clearfix"></div>
                  <ng-container *ngFor="let item of selectedQuestion.media_files">
                    <ng-container *ngIf="item.media_type == 1">
                      <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                        <span class="rounded text-center">
                      <img src="{{s3FilePath}}{{item.path}}" class="mx-auto image_quz img-fluid">
                      </span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="item.media_type == 2">
                      <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                        <span class="rounded">
                          <audio controls class="audio_quz audio_q_t">
                        <source src="{{s3FilePath}}{{item.path}}" type="audio/mpeg">
                      </audio>
                      </span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="item.media_type == 3">
                      <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                        <span class="rounded">
                          <video width="100%" height="125" controls>
                        <source src="{{s3FilePath}}{{item.path}}">
                      </video>
                      </span>
                      </div>
                    </ng-container>
                  </ng-container>

                  <div class="clearfix"></div>

                <div cdkDropListGroup>
                  <div class="row">
                    <div class="example-container col-xs-6 col-sm-6 col-lg-6 match_the_following">
                      <h2></h2>
                      <div cdkDropList class="example-list">
                        <div class="example-box" *ngFor="let option of selectedQuestion.child_questions" cdkDrag
                          [cdkDragDisabled]="true" [value]="option">
                          {{option.question_text}}
                        </div>
                      </div>


                    </div>


                    <div class="example-container col-xs-6 col-sm-6 col-lg-6 match_the_following_ans">
                      <h2></h2>

                      <div cdkDropList [cdkDropListData]="selectedQuestion.options" class="example-list"
                        (cdkDropListDropped)="drop($event)">
                        <div class="example-box" *ngFor="let option of selectedQuestion.options" cdkDrag
                          [value]="option">{{option.option}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                
              </ng-container>


              <ng-container *ngIf="isFillinBlanks">

                <label id="example-radio-group-label" class="test_question float-left qust_number_"> {{selectedQuestionIndex}} :
                </label>

                <!--<hr class="mx-1">-->
                <ng-container *ngFor="let option of te; let i = index">

                  <input *ngIf="option.qs=='@@@@@'" type="text" class="form-control drg_drop_qst" style="width:150px;"
                    (input)="onOptionadd($event.target.value, option.optionNumber)">

                  <span *ngIf="option.qs!='@@@@@'" class="cnt_answer_fill"> {{option.qs}} </span>


                </ng-container>
                <div class="clearfix"></div>

                <ng-container *ngFor="let item of selectedQuestion.media_files">
                  <ng-container *ngIf="item.media_type == 1">
                    <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                      <span class="rounded text-center">
                    <img src="{{s3FilePath}}{{item.path}}" class="mx-auto image_quz img-fluid">
                    </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.media_type == 2">
                    <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                      <span class="rounded">
                        <audio controls class="audio_quz audio_q_t">
                      <source src="{{s3FilePath}}{{item.path}}" type="audio/mpeg">
                    </audio>
                    </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.media_type == 3">
                    <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                      <span class="rounded">
                        <video width="100%" height="125" controls>
                      <source src="{{s3FilePath}}{{item.path}}">
                    </video>
                    </span>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="isDragandDrop">

                <label id="example-radio-group-label" class="test_question float-left qust_number_b"> {{selectedQuestionIndex}} :
                </label>


                
                <!--<h3 class="my-2">Options</h3>-->
                <ul class="qust_list_drg mb-2 mt-0 border-0">
                  <div id="drag_options">
                    <ng-container *ngFor="let option of selectedQuestion.options; let j = index">
                      <li id="div1{{j}}" class="drg_dro_ans" draggable="true" value="{{option.option_id}}"
                        (dragstart)="drag($event)">
                        {{option.option}}
                      </li>


                    </ng-container>
                  </div>
                </ul>
                <div class="clearfix"></div>
                <hr class="mx-1">
                <ng-container *ngFor="let option of te; let i = index">

                  <span *ngIf="option=='@@@@@'" id="div2{{i}}" (drop)="onDrop($event)" (dragover)="allowDrop($event)"
                    class="form-control drg_drop_qst">
                  </span>
                  <span *ngIf="option!='@@@@@'" class="cnt_answer_fill"> {{option}} </span>


                </ng-container>
                <div class="clearfix"></div>

                <ng-container *ngFor="let item of selectedQuestion.media_files">
                  <ng-container *ngIf="item.media_type == 1">
                    <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                      <span class="rounded text-center">
                    <img src="{{s3FilePath}}{{item.path}}" class="m-2 float-left rounded image_quz">
                    </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.media_type == 2">
                    <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                      <span class="rounded">
                        <audio controls class="audio_quz audio_q_t">
                      <source src="{{s3FilePath}}{{item.path}}" type="audio/mpeg">
                    </audio>
                    </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.media_type == 3">
                    <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                      <span class="rounded">
                        <video width="100%" height="125" controls>
                      <source src="{{s3FilePath}}{{item.path}}">
                    </video>
                    </span>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <div #divID></div>

            </div>
            <div class="pt-3 pb-3 mt-4 text-left border-top btns float-left widht_100">
              <div class="clearfix"></div>
              <a class="btn-lg btn-dark float-right ml-1" (click)="finishTest()">Finish</a>
              <a class="btn-lg btn-dark float-right ml-1" *ngIf="isNext" (click)="nextTest()">Next</a>
              <a class="btn-lg btn-dark float-right ml-1" *ngIf="isPrevious" (click)="previousTest()">Previous</a>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="isFinishTest">


        <div class="card   border-0 shadow-none">
          <div class="card-body">
            <h1>Result</h1>
            <hr class="py-2">
            <div class="quiz_start pb-5" *ngIf="istestPass">
              <div class="col-12 image_qui_finish text-center pass_l"><img
                  src="../../../assets/web/assets/images/passed.svg" class="mx-auto">
                <h5 [innerHTML]="testResults.feedback"></h5>
                <!-- <h6>Your POINTS :
                              {{this.testResults.no_of_correct_answers}}/{{this.testResults.total_number_of_questions}} </h6> -->
                <h6>{{testResults.percentage}} %</h6>
                <div class="qiz_det_a mt-3 mb-3">
                <p>Total marks obtained :<strong>{{ testResults.total_marks_obtained}}</strong></p>
                <p>Number of correct answers :<strong>{{ testResults.no_of_correct_answers}}</strong></p>
                <p>Number of wrong answers :<strong>{{ testResults.no_of_wrong_answers}}</strong></p>
              </div>
              </div>
<div class="clearfix"></div>
             <div class="w-100 text-center">
                <a class="btn btn-dark mt-2 mx-auto button_text" (click)="backToAssessment()">Ok</a>
              </div>
            </div>
            <div class="quiz_start pb-5" *ngIf="!istestPass">

              <div class="col-12 image_qui_finish text-center didnt_pass"><img
                  src="../../../assets/web/assets/images/fail.svg" class="mx-auto">
                <h5>{{testResults.feedback}}</h5>
                <div class="qiz_det_a mt-3 mb-3">
                  <p>Total marks obtained :<strong>{{ testResults.total_marks_obtained}}</strong></p>
                  <p>Number of correct answers :<strong>{{ testResults.no_of_correct_answers}}</strong></p>
                  <p>Number of wrong answers :<strong>{{ testResults.no_of_wrong_answers}}</strong></p>
                </div>
                <!-- <h6>Your POINTS :
                              {{this.testResults.no_of_correct_answers}}/{{this.testResults.total_number_of_questions}} </h6> -->

                <!-- <a class="btn btn-danger ml-1 mx-auto retest_btn" (click)="restartCourse()">Retest</a> -->
              </div>
              <div class="w-100 text-center">
                <a class="btn btn-dark mt-2 mx-auto button_text" (click)="backToAssessment()">Ok</a>
              </div>
            </div>

            <!-- <div class="pt-3 pb-3 mt-4 text-left border-top btns">
                          <a class="btn btn-dark float-right ml-1" (click)="backTocourse()">Back to course</a>
  
                        </div> -->
          </div>
        </div>
      </ng-container>
      <!-- </div>
              </div> -->

    </form>
  </div>
</div>
