import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import { PopupService } from '../../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from '../../../services/upload.service';
import { ConstantsService } from '../../../services/constants.service'

@Component({
  selector: 'app-batch-edit',
  templateUrl: './batch-edit.component.html',
  styleUrls: ['./batch-edit.component.css']
})
export class BatchEditComponent implements OnInit {
  batchEditFormGroup: FormGroup;
  submitted: any = false;
  todayDate: any;
  dropdownSettings: any = {};
  courseList: any = [];
  session_id: any;
  batch_id: any;
  courseArray: any = [];

  constructor(public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private popup: PopupService,
    private spinner: NgxSpinnerService,
    private uploadService: UploadService,
    private constantService: ConstantsService,
    private cdr: ChangeDetectorRef) {
    this.batchEditFormGroup = this.formBuilder.group({
      course_id: ["", [Validators.required]],
      start_date: ["", [Validators.required]],
      end_date: ["", [Validators.required]],
      status_id: [1],
    });
  }


  async ngOnInit() {
    this.spinner.show();
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.batch_id = this.route.snapshot.paramMap.get("id");
    this.dropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "course_name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: "All",
      allowSearchFilter: true,
    };

    await this.getCourses();
    this.todayDate = await this.convert();
    await this.getBatchDetail();

  }



  public hasError = (controlName: string, errorName: string) => {
    return this.batchEditFormGroup
      .get(controlName)
      .hasError(errorName);
  };

  onSubmit() {
    this.submitted = true;
    this.batchEditFormGroup;
    if (this.batchEditFormGroup.valid) {
      if (new Date(this.batchEditFormGroup.value.start_date) > new Date((this.batchEditFormGroup.value.end_date))) {
        this.batchEditFormGroup
          .get("end_date")
          .setErrors({ dateError: true });
      }
      if (this.batchEditFormGroup.valid) {

        let value = {
          course_id: this.batchEditFormGroup.value.course_id[0].id,
          start_date: this.batchEditFormGroup.value.start_date,
          end_date: this.batchEditFormGroup.value.end_date,
          status_id: this.batchEditFormGroup.value.status_id,
          batch_id: this.batch_id

        }
        var url = "update_batch";
        this.api.detailsUploadFetch(value, url, this.session_id).subscribe((data: any) => {
          debugger

          console.log(data)
          if (data.status == "true") {
            this.spinner.hide();
            this.batchEditFormGroup.reset();
            this.submitted = false;
            this.popup.sucessMessage = data.message
            this.popup.sucesspopup();
            this.router.navigate(['admin/batch'])
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup();
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
    debugger
  }

  getCourses() {
    this.courseList = [];
    var batch_fetch_url = "getBatchWiseCourselist";
    this.api.detailsGetFetch(batch_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data) {
        this.courseList = data.courses;
        debugger
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }

  async getBatchDetail() {
    this.courseList = [];
    var batch_fetch_url = "edit_batch?batch_id=" + this.batch_id;
    await this.api.detailsGetFetch(batch_fetch_url, this.session_id).subscribe(async (data: any) => {
      if (data.status == "true" && data) {
        let batchData = data.batch;

        await this.setData(batchData);
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }

  async setData(data) {
    let fitedData = await this.courseList.filter((x) => x.id === data.course_id)[0];
    debugger
    await this.courseArray.push(fitedData);
    this.batchEditFormGroup
      .get("course_id")
      .setValue(this.courseArray);
    this.batchEditFormGroup
      .get("start_date")
      .setValue(data.start_date);
    this.batchEditFormGroup
      .get("end_date")
      .setValue(data.end_date);
    this.batchEditFormGroup
      .get("status_id")
      .setValue(data.status);
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 1000);


  }

  convert() {
    var date = new Date(),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  onItemSelect(item: any) {
    console.log("onItemSelect", item);
  }

  onSelectAll(items: any) {
    console.log("onSelectAll", items);
  }


}
