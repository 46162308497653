import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SmeComponent } from './sme.component';
import { SmeRoutingModule } from './sme-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [SmeComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    SmeRoutingModule,
    NgxDatatableModule,
    NgxPaginationModule
   ]
})
export class SmeModule { }  
