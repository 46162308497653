import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsGeneralComponent } from './settings-general/settings-general.component';
import { SettingRoutingModule } from './setting-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';



@NgModule({
  declarations: [SettingsGeneralComponent],
  imports: [
    CommonModule,
    SettingRoutingModule,
    FormsModule, ReactiveFormsModule,
    MatTabsModule,
    MatBadgeModule,
  ]
})
export class SettingModule { }
