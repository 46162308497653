import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
    import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { Page } from '../../models/pageDto';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.css']
})
export class StreamComponent implements OnInit {
  session_id:any;
   qualificationList:any=[];
   searchKey:any;
   streamList:any=[];
   selected:any=[];
  temp = []
  searchTerm: any = '';
  filterQualification: any = ''
  page: Page;
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router,private popup: PopupService,private spinner: NgxSpinnerService) { }
  streamForm: FormGroup;

  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.getQualification();
    this.streamForm = this.formBuilder.group({
      search: [''],
      qualificationType: ['0']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };

    this.setPage({ offset: 0 });
  }

  onSearch() {
    this.searchTerm = this.streamForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

  onQualificationTypeFilter() {
    this.filterQualification = this.streamForm.get('qualificationType').value > 0 ? this.streamForm.get('qualificationType').value.toString() : '';
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  setPage(pageInfo) {
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.streamList = [];

    var state_fetch_url = "stream?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;

    if (this.filterQualification.length > 0)
      state_fetch_url += "&qualification_id=" + this.filterQualification;

    if (this.searchTerm.length > 0)
      state_fetch_url += "&searchTerm=" + this.searchTerm;

    this.api.detailsGetFetch(state_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.streams[0]) {
        this.spinner.hide();

        this.temp = [...data.streams];
        this.streamList = data.streams;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate([''])
      }
    });
  }
  get g() { return this.streamForm.controls; }

  getQualification() {
    this.spinner.show();
    this.qualificationList = [];
    var list_fetch_url = "qualification?status_id=1";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.qualifications[0]) {
        this.qualificationList = data.qualifications;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  //onSearch() {
  //  if (this.searchForm.invalid) {
  //    return;
  //  }
  //  this.spinner.show();

  //  this.searchKey = +this.g.qualification.value;
  //  var body = { "qualification_id": +this.g.qualification.value };
  //  var search_url = "stream?qualification_id=" + `${this.g.qualification.value}`;
  //  this.streamList = [];
  //  this.api.detailsFetch(body, search_url).subscribe((data: any) => {
  //    console.log(data)
  //    if (data.status == "true") {
  //      this.spinner.hide();
  //      this.temp=data.Stream;
  //      this.streamList = data.Stream;
  //    }
  //    else {
  //      this.spinner.hide();

  //    }
  //  },
  //    (err: HttpErrorResponse) => {
  //      if (err.status == 403) {
  //        this.spinner.hide();

  //        localStorage.clear()
  //        this.router.navigate(['login'])
  //      }
  //    });
  //}
  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      const name = d.qualification.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || status_name || name;
    });

    // update the rows
     this.streamList =temp;
    // this.table.offset = 0;
  }
  deleteStream(id) {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var body = { "stream_id": +id };
        var del_url = "delete_stream?stream_id="+ id;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          debugger
          console.log(data)
          if (data.status == "True") {
            this.popup.sucessMessage = data.message;
            this.popup.sucesspopup();
            this.spinner.show();
            this.setPage({ offset: 0 });
             var body = { "qualification_id": +this.searchKey};
            var search_url = "stream?qualification_id=" + `${this.searchKey}`;
            this.streamList = [];
            this.api.detailsFetch(body, search_url).subscribe((data: any) => {
              debugger
              console.log(data)
              if (data.status == "true") {
                this.spinner.hide();
                this.temp=[];
                this.temp=data.Stream;
                this.streamList = data.Stream;
              }
              else {
                this.spinner.hide();
        
              }
            },
              (err: HttpErrorResponse) => {
                if (err.status == 403) {
                  this.spinner.hide();
        
                  localStorage.clear()
                  this.router.navigate(['admin/login'])
                }
              });
          }
          else {
            this.spinner.hide();
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }
  editStream(id){
    this.router.navigate(['admin/add-stream',{dist:`${id}`}]);
  }
}
