import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-edit-eligibility-test',
  templateUrl: './edit-eligibility-test.component.html',
  styleUrls: ['./edit-eligibility-test.component.css']
})
export class EditEligibilityTestComponent implements OnInit {

  session_id: string;
  CourceList: any = [];
  stateList: any = [];
  testId: any;
  constructor(public api: ApiService, private router: Router, private formBuilder: FormBuilder,
    private popup: PopupService, private route: ActivatedRoute,private spinner: NgxSpinnerService) {
      this.session_id = localStorage.getItem('JWT_TOKEN');
      this.init();
      this.route.paramMap.subscribe((params : ParamMap)=> {
        let id = params.get('testId');
        this.testId = id;
        this.getTestDetails();
      })
   }
  eligibilityForm: FormGroup;
  submitted=false;


  ngOnInit(): void {

    this.eligibilityForm = this.formBuilder.group({
      course_id: ['', Validators.required],
      title: ['', Validators.required],
      welcome_text: ['', Validators.required],
      no_of_questions: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      grade_per_question: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      pass_percentage: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]?$|^100$/)]],
      success_feedback: ['', Validators.required],
      failure_feedback: ['', Validators.required],
      re_attempt: ['0'],
      time_limit: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      status_id: ['', Validators.required],
    });

  }

  fetchData(data : any) : void{
    this.eligibilityForm.get('course_id').setValue(data.course_id);
    this.eligibilityForm.get('title').setValue(data.title);
    this.eligibilityForm.get('welcome_text').setValue(data.welcome_text);
    this.eligibilityForm.get('no_of_questions').setValue(data.no_of_questions);
    this.eligibilityForm.get('grade_per_question').setValue(data.grade_per_question);
    this.eligibilityForm.get('pass_percentage').setValue(data.pass_percentage);
    this.eligibilityForm.get('success_feedback').setValue(data.success_feedback);
    this.eligibilityForm.get('failure_feedback').setValue(data.failure_feedback);
    this.eligibilityForm.get('re_attempt').setValue(data.re_attempt);
    this.eligibilityForm.get('time_limit').setValue(data.time_limit);
    this.eligibilityForm.get('status_id').setValue(data.status_id);
    console.log(this.eligibilityForm.value)
    debugger
  }

  get f() { return this.eligibilityForm.controls; }

  init() {
    this.spinner.show();

    this.CourceList = [];
    var course_fetch_url = "getCourseListWithEligibilityTest";
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.courses[0]) {
        this.CourceList = data.courses;
        debugger
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getTestDetails() : void{
    this.spinner.show();
    var course_fetch_url = "getEligibilitytestById?eligibility_test_id="+this.testId;
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.eligibility_test[0]) {
        this.fetchData(data.eligibility_test[0])
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  onSubmit() {
   this.submitted=true;
   this.eligibilityForm
   debugger
    if (this.eligibilityForm.invalid) {
      return;
    }

     this.spinner.show();

      var body = {"eligibility_test_id": this.testId,"course_id": this.f.course_id.value, "title": this.f.title.value, "welcome_text": this.f.welcome_text.value,
       'no_of_questions': this.f.no_of_questions.value, 'grade_per_question': this.f.grade_per_question.value,
       "pass_percentage": this.f.pass_percentage.value, "success_feedback": this.f.success_feedback.value, "failure_feedback": this.f.failure_feedback.value,
       "re_attempt": this.f.re_attempt.value, "time_limit": this.f.time_limit.value, "status_id": this.f.status_id.value};

       console.log(body);

       var url = "update_eligibility_test";
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        console.log(data)
        if (data.status == "TRUE") {
          this.spinner.hide();
          this.eligibilityForm.reset();
          this.stateList = [];
          this.submitted=false;
          this.popup.sucessMessage = data.message;
          this.popup.sucesspopup();
          this.router.navigate(['/admin/eligibility-test/list']);

        }
        else {
          this.spinner.hide();
          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }

}
