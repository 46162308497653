import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
declare var $: any;
import { NgxSpinnerService } from "ngx-spinner";
import { Title, Meta } from "@angular/platform-browser";
import { Validatespace } from "src/app/validation/spacevalidation";

@Component({
  selector: 'app-district-add',
  templateUrl: './district-add.component.html',
  styleUrls: ['./district-add.component.css']
})
export class DistrictAddComponent implements OnInit {

  districtForm: FormGroup;
  session_id: string;
  countryList: any = [];
  stateList: any = [];
  isEdit: boolean = false;
  districtId: any;
  submitted = false;
  invalidStateDropdown: boolean = false;


  constructor(public api: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private popup: PopupService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private title: Title,
    private meta: Meta,) { 
      this.districtForm = this.formBuilder.group({
        country: ['', Validators.required],
        state: ['', Validators.required],
        district: ['', [Validators.required, Validatespace]],
        status: ['1', Validators.required],
      });
    }

  ngOnInit(): void {

    this.session_id = localStorage.getItem('JWT_TOKEN');
    
    debugger
    // this.districtForm.get('country').setValue('0');
    // this.districtForm.get('state').setValue('0');
    // this.districtForm.get('status').setValue('1');

    // this.districtForm.reset();
    
    debugger
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.dist) {
        this.isEdit = true;
        this.init();
        this.spinner.show();
        this.districtId = params.dist;
        var single_url = "district_edit?district_id=" + `${params.dist}`
        this.api.detailsGetFetch(single_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "true") {
            debugger
            this.districtForm.get('country').setValue(data.district[0].country_id);
            this.districtForm.get('status').setValue(data.district[0].status_id);
            this.districtForm.get('district').setValue(data.district[0].title);
            let stateId = data.district[0].state_id;
            var body_edit = { "state_id": data.district[0].country_id };
            var edit_url = "state?country_id=" + `${data.district[0].country_id}`;
            this.stateList = [];
            this.api.detailsFetch(body_edit, edit_url).subscribe((data: any) => {
              console.log(data)
              if (data.status == "true") {
                this.stateList = data.states;
                this.districtForm.get('state').setValue(stateId);
                this.spinner.hide();
              }
              else {
                this.spinner.hide();


              }
            },
              (err: HttpErrorResponse) => {
                if (err.status == 403) {
                  this.spinner.hide();

                  localStorage.clear()
                  this.router.navigate(['admin/login'])
                }
              });
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
        this.title.setTitle("Edit District | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Edit District | Admin",
        });
      }
      else {
        // this.spinner.hide();

        this.isEdit = false;
        this.init();
        this.title.setTitle("Add District | Admin | Al Jamia");
        this.meta.addTag({
          name: "description",
          content: "Add District | Admin",
        });
      }
    }
    );
  }
  get f() { return this.districtForm.controls; }

  init() {
    this.spinner.show();

    this.countryList = [];
    if(!this.isEdit){
    var countries_fetch_url = "country?status_id=1";
  }else{
    var countries_fetch_url = "country";
  }
    this.api.detailsGet(countries_fetch_url).subscribe((data: any) => {
      if (data.status == "true" && data.countries[0]) {
        this.countryList = data.countries;
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  getState(event) {
    this.spinner.show();
    this.stateList = [];
    this.districtForm.get('state').setValue('');
    var body = { "country_id": event.target.value };
    var search_url = "state?country_id=" + `${event.target.value}` + "&status_id=1";
    this.api.detailsFetch(body, search_url).subscribe((data: any) => {
      debugger
      console.log(data)
      if (data.status == "true") {
        this.spinner.hide();

        this.stateList = data.states;
      }
      else {
        this.spinner.hide();

      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });

  }
  onSubmit() {
    this.submitted = true;
    if (this.districtForm.invalid) {
      return;
    }
    if (this.f.country.value == 0) {
      debugger
      return;

    }
    if (this.f.state.value == 0) {
      debugger
      this.invalidStateDropdown = true;
      return;

    }
    if (!this.isEdit) {
      this.spinner.show();
      debugger
      var body = { "title": this.f.district.value, "status_id": +this.f.status.value, "state_id": this.f.state.value };
      var url = "add_district?state_id=" + `${this.f.state.value}` + "&title=" + + `${this.f.district.value}` + "&status_id=" + `${this.f.status.value}`;
      this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        debugger
        console.log(data)
        if (data.status == "TRUE") {
          this.spinner.hide();


          this.districtForm.reset();
          this.stateList = [];
          this.submitted = false;
          this.popup.sucessMessage = data.message;
          this.popup.sucesspopup();
          this.router.navigate(['admin/district']);

        }
        else {
          this.spinner.hide();
          this.popup.failureMessage = data.message
          this.popup.failurepopup()
        }
      },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.spinner.hide();

            localStorage.clear()
            this.router.navigate(['admin/login'])
          }
        });
    }
    else {
      if (this.isEdit) {
        this.spinner.show();
        debugger

        var edit_body = { "district_id": +this.districtId, "title": this.f.district.value, "status_id": this.f.status.value, "state_id": this.f.state.value };
        var update_url = "update_district?district_id=" + this.districtId + "&title=" + `${this.f.district.value}`;
        this.api.detailsAddFetch(edit_body, update_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.districtForm.reset();
            this.submitted = false;

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.router.navigate(['admin/district'])

          }
          else {
            this.spinner.hide();

            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });
      }
    }
  }

}
