<body class="hold-transition login-page">
    <div class="login-box">
    <!-- /.login-logo -->
    <div class="card border_login">
      <div class="card-body login-card-body">
          <div class="login-logo"> <a href="#"><img src="../../assets/img/logo_login.png"></a> </div>
          <form action="recover-password.html" method="post" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
            <!-- <div class="input-group mb-3">
              <div class="input-group-append">
                <div class="input-group-text"> <span class="fas fa-lock"></span> </div>
              </div>
              <input type="password" class="form-control" placeholder="Current password">
            </div> -->
               <div class="input-group mb-3">
              <div class="input-group-append">
                <div class="input-group-text"> <span class="fas fa-lock"></span> </div>
              </div>
              <input type="password" class="form-control" placeholder="New password" formControlName="newPassword">
            </div>
               <div class="input-group mb-3">
              <div class="input-group-append">
                <div class="input-group-text"> <span class="fas fa-lock"></span> </div>
              </div>
              <input type="text" class="form-control" placeholder="Confirm password" formControlName="confirmPassword">
            </div>
            <div class="row">
              <div class="col-12" *ngIf="isError">
                <p class="error">Password not match </p>
                <!-- <p class="notification_message"> Password successfully updated.</p> -->
              </div>
              <div class="col-12">
                <button type="submit" class="btn btn-secondary btn-login">Continue <span class="fas fa-angle-right float-right mt-1 mr-3"></span></button>
              </div>
              <!-- /.col --> 
            </div>
          </form>
        <!-- /.login-card-body --> 
      </div>
    </div>
    <!-- /.login-box --> 
    </div>
    <!-- jQuery --> 
    <script src="plugins/jquery/jquery.min.js"></script> 
    <!-- Bootstrap 4 --> 
    <script src="plugins/bootstrap/js/bootstrap.bundle.min.js"></script> 
    <!-- AdminLTE App --> 
    <script src="dist/js/adminlte.min.js"></script>
    </body>