<div class="container-fluid py-4 overview_course">
  <div class="pb-3">
    <h1>{{course_name}}</h1>
  </div>

  <div class="accordion" id="accordionExample">
    <ng-container *ngFor="let myCourseOverviewDetail of myCourseOverviewDetails; let i = index">
      <div class="card">
        <div class="card-header bg-white p-0" id="headingOne{{i}}">
          <h3 class="mb-0">
            <button class="btn btn-block text-left p-3 pl-5 btn_bg" type="button" data-toggle="collapse"
              [attr.data-target]="'#collapseOne' + i" aria-expanded="true" aria-controls="collapseOne{{i}}">
              {{myCourseOverviewDetail.course_week_title}}</button>
          </h3>
        </div>

        <div id="collapseOne{{i}}" class="collapse fade" aria-labelledby="headingOne{{i}}"
          data-parent="#accordionExample">
          <div class="card-body">

            <ul class="overview_listing col-lg-6 col-xl-6  col-md-12 col-sm-12 m-0 p-0">
              <ng-container *ngFor="let course_content of myCourseOverviewDetail.course_contents; let j = index">
                <!-- Video  -->
                <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_VIDEO_1">
                  <a (click)="toStartVideo(course_content)" class="btn video_a text-left" [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                   <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
                <!-- Reading  -->
                <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_READING_3">
                  <a class="btn reading_a text-left" (click)="onStart(course_content)" [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                    <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
                <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_EXERCISE_6">
                  <a class="btn exeric_a text-left" [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">Practice Exercises
                    <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
                 <!-- Live Class  -->
                <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_LIVE_CLASS_8">
                  <a class="btn live_a text-left" (click)="onStart(course_content)"  [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                    <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
                <!-- Audio  -->
                <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_AUDIO_2">
                  <a (click)="toStartVideo(course_content)" class="btn audio_a text-left" [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                    <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
                <!-- Document  -->
                <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_DOCUMENT_7">
                  <a class="btn doc_a text-left" (click)="onStart(course_content)" [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                    <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
                <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_REFERENCES_9">
                  <a class="btn ref_a text-left" [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">References
                    <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
                <!-- Assignment  -->
                <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_ASSIGNMENT_4">
                  <a class="btn assign_a text-left" (click)="onStart(course_content)" [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                    <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
                <!-- Assessment  -->
                <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_ASSESSMENT_5">
                  <a class="btn asses_a text-left" (click)="onStart(course_content)" [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                    <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
                 <!-- Vimeo  -->
                 <li *ngIf="course_content.course_content_type_id === enumvariable.COURSE_CONTENT_TYPE_VIMEO_9">
                  <a class="btn live_a text-left" (click)="onStart(course_content)"  [ngClass]="{ 'btn-success': course_content.unlock_status == true, 'btn-dark': course_content.unlock_status == false}">{{course_content.title}}
                    <div [ngClass]="{ 'course_finish float-right': course_content.completed_status == 1 }"></div>
                    <span *ngIf="!course_content.unlock_status" class="float-right pl-3 locked_"></span>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>