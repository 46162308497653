// import { Component, OnInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toaster.service';
import { MatDialog } from '@angular/material/dialog';
import { ConstantsService } from '../../../../../services/constants.service';
import { EMPTY, Subscription } from "rxjs";

@Component({
  selector: 'app-course-info',
  templateUrl: './course-info.component.html',
  styleUrls: ['./course-info.component.css']
})
export class CourseInfoComponent implements OnInit {

  constructor(public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private rote: ActivatedRoute,
    private ToasterService: ToasterService,
    public dialog: MatDialog,
    private constantService: ConstantsService) { }

    enrollmentId: any;
  about_course: any = this.getaboutcourse();
  course: any = this.getcourseObj();
  instructor: any = this.getinstructorObj();
  syllabuses: any = this.getsyllabusesObj();
  currencyId: null;
  priceData: any;
  subscribeLocaldataService: any;
  batchs: any;
  selectedBatchId: any;
  userId: any;
  session_id: any;
  courseDescription: string;
  s3FilePath: string;
  clickEventsubscription: Subscription;

  getaboutcourse() {
    return { course_overview: null, course_structure_description: null, description: null, learn_from_course: null, short_description: null };
  }
  getcourseObj() {
    return { course_id: null, slug: null, course_name: null, learning_type: null, subscription_type: null, category_id: null, course_category: null, sme_name: null, batch: [{ batch_id: null, start_date: null, end_date: null }], cost: [{ currency_id: null, amount: null }], duration: null, image: null };
  }
  getinstructorObj() {
    return { profile_picture: null, sme_degree: null, sme_id: null, sme_name: null };
  }
  getsyllabusesObj() {
    return { course_objectives: null }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.LocaldataService.updateUser();
    this.session_id = localStorage.getItem('access_token');
    this.enrollmentId = this.rote.snapshot.paramMap.get("enrollment_id");
    this.userId = localStorage["user_Id"];
    this.s3FilePath = this.constantService.s3FilePath;
    if (!this.userId) {
      this.router.navigate([''])
    } else {
      this.getCourseDetails();
    }
  }

  getCourseDetails() {
    var list_fetch_url = "student/get_course";
    let param = { enrollment_id : this.enrollmentId, user_id: this.userId };
    this.api.postDetails(list_fetch_url, param).subscribe(async (data: any) => {
      debugger
      if (data.status == "true" && data.contents) {
        this.about_course = data.contents.about_course;
        this.course = data.course[0];
        this.instructor = data.contents.instructor;
        this.syllabuses = this.s3FilePath + data.contents.syllabus;
        this.courseDescription = data.contents.about_course.description;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate([''])
      }
    });

  }

}
