<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/admin/stream']">Streams</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add Stream</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit Stream</li>

          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add Stream</h1>
        <h1 *ngIf="isEdit">Edit Stream</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="streamForm" (ngSubmit)="onSubmit()">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Stream *</label>
                    <input type="text" formControlName="stream" class="form-control selact_" placeholder="Stream"
                      [ngClass]="{ 'is-invalid': submitted && f.stream.errors }">
                    <div *ngIf="submitted && f.stream.errors" class="invalid-feedback">
                      <div *ngIf="f.stream.errors.required">Stream is
                        required
                      </div>
                      <div *ngIf="f.stream.errors.validspace"> Invalid Stream
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Qualification *</label>
                    <select name="state" formControlName="qualification" id="countySel" size="1"
                      class="form-control select2 selact_" style="width: 100%;" placeholder="Select Country"
                      [ngClass]="{ 'is-invalid': submitted && f.qualification.value==0 }">
                      <option [value]="0">--Select Qualification--</option>
                      <option *ngFor="let item of qualificationList" [ngValue]="item.id">{{item.title}}</option>
                    </select>
                    <!-- <div *ngIf="submitted && f.qualification.errors" class="invalid-feedback">
                      <div *ngIf="f.qualification.errors.required">Qualification is
                        required
                      </div>
                    </div> -->
                    <div class="invalid-feedback" *ngIf="submitted && f.qualification.value==0">
                      Qualification is
                      required
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Status *</label>
                    <select class="form-control selact_" formControlName="status" style="width: 100%;"
                      placeholder="Select Status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                      <option value="1">Active</option>
                      <option value="2">Disabled</option>
                    </select>
                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                      <div *ngIf="f.status.errors.required">Status is
                        required
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group col-md-6 float-left">
                  <label>Description</label>
                  <textarea class="form-control selact_" formControlName="description"
                    [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                  <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                    <div *ngIf="f.description.errors.required">Description is
                      required
                    </div>
                  </div>
                </div> -->
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
</div>
