import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TeamAddComponent } from './team-add.component';
import { TeamAddRoutingModule } from './team-add-routing.module';
import { StrictNumberOnlyDirective } from './StrictNumberOnlyDirective';

@NgModule({
  declarations: [TeamAddComponent,StrictNumberOnlyDirective],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    TeamAddRoutingModule,
   ]
})
export class TeamAddModule { }
