import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Page } from 'src/app/models/pageDto';
import { NgxSpinnerService } from 'ngx-spinner';
import { ListTestQuestionsComponent } from 'src/app/dashboard/Eligibility-Test/Questions/list-test-questions/list-test-questions.component';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';
import { ToasterService } from 'src/app/services/toaster.service';
import Swal from 'sweetalert2';
import { Validatespace } from "../../../../validation/spacevalidation";

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  @ViewChild('myTable') table: ListTestQuestionsComponent;
  @ViewChild('qTable') qtable: ListTestQuestionsComponent;
  session_id: any;
  searchTerm: any = '';
  selected = [];
  deleteList = [];
  temp = [];
  questionList: any = [];
  assesmentId: any;
  page: Page;

  courceName: any;
  testName: any;
  minimum_qstns: any;

  msg = "";

  questionTypeList: any = [];
  questionCategoryList: any = [];
  difficultyLevelList: any = [];

  filterQuestionType: any = ''
  filterQuestionCategory: any = ''
  filterDifficultyLevel: any = ''
  submitted: boolean;
  submitted_: boolean;
  displayModal_: boolean;

  qPage: Page;

  public displayModel: boolean;
  public questionPool: any = [];
  private poolTemp = [];

  questionForm: FormGroup;
  randomQuestionForm: FormGroup;

  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute,
    private popup: PopupService, private spinner: NgxSpinnerService, private ToasterService: ToasterService) {

    this.randomQuestionForm = this.formBuilder.group({
      question_type: ["", Validators.required],
      no_of_questions: ["", [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]]
    });

    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };

    this.qPage = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };

    this.session_id = localStorage.getItem('JWT_TOKEN');
    this.displayModel = false;

    this.route.paramMap.subscribe((parama: ParamMap) => {
      let id = parama.get('cource_content_id');
      this.assesmentId = id;

      this.setPage({ offset: 1 });
    })
  }
  eligibilityListForm: FormGroup;
  ngOnInit(): void {

    this.questionForm = this.formBuilder.group({
      search: [''],
      questionType: ['0'],
      questionCategory: ['0'],
      difficultyLevel: ['0']

    });

    // this.loadAllQuestions({ offset: 1 });
    this.getQuestionTypes();
    this.getQuestionCategories();
    this.getDifficultyLevels();
  }

  getQuestionTypes() {
    this.questionTypeList = [];
    var list_fetch_url = "getQuestiontypes";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.question_types[0]) {
        this.questionTypeList = data.question_types;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getQuestionCategories() {
    this.questionCategoryList = [];
    var list_fetch_url = "getQuestioncategories";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.question_categories[0]) {
        this.questionCategoryList = data.question_categories;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  getDifficultyLevels() {
    this.difficultyLevelList = [];
    var list_fetch_url = "getDifficultylevels";
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.difficulty_levels[0]) {
        this.difficultyLevelList = data.difficulty_levels;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  onSearch() {
    this.searchTerm = this.questionForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  qPageNav(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.loadAllQuestions(pageInfo);
  }

  setPage(pageInfo) {
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.questionList = [];
    var course_fetch_url = "assessment_questions_list?course_content_id=" + this.assesmentId + "&page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    if (this.searchTerm.length > 0)
      course_fetch_url += "&searchTerm=" + this.searchTerm;
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {

      //  debugger
      if (data.status == "TRUE" && data.activity_questions[0]) {
        this.temp = data.activity_questions;
        this.questionList = data.activity_questions;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  bulkDelete(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var body = { "assessment_question_ids": this.deleteList };
        var del_url = "assessment_question_delete";
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          debugger
          if (data.status == "true") {
            this.deleteList = [];
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'success');
            this.setPage({ offset: 1 });
          }
          else {
            this.spinner.hide();
            this.ToasterService.openSnackBar(data.message, '', 'failed');
          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });

      }
    })
  }

  onSelectCheckbox(id): void {
    let found: boolean = false;
    for (let i = 0; i < this.selected.length; i++) {
      if (this.selected[i] == id) {
        this.selected.splice(i, 1);
        found = true
      }
    }

    if (!found) {
      this.selected.push(id)
    }
    console.log(this.selected);
  }

  onDeleteCheckbox(id): void {
    let found: boolean = false;
    for (let i = 0; i < this.deleteList.length; i++) {
      if (this.deleteList[i] == id) {
        this.deleteList.splice(i, 1);
        found = true
      }
    }

    if (!found) {
      this.deleteList.push(id)
    }
    console.log(this.deleteList);
  }

  deleteCourse(id) {
    this.deleteList.push(id);
    this.bulkDelete();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || status_name;
    });

    this.questionList = temp;
  }

  showModel(): void {
    this.displayModel = true;
  }

  closeModel(): void {
    this.displayModel = false;
    this.selected = [];
  }

  loadAllQuestions(pageInfo): void {

    this.spinner.show();
    this.qPage.current_page = pageInfo.offset;
    this.questionPool = [];
    var fetch_url = "getQuestions?page=" + this.qPage.current_page + "&per_page_count=" + this.qPage.per_page + "&status_id=1";

    if (this.filterQuestionType.length > 0)
      fetch_url += "&type=" + this.filterQuestionType;

    if (this.filterQuestionCategory.length > 0)
      fetch_url += "&category_id=" + this.filterQuestionCategory;

    if (this.filterDifficultyLevel.length > 0)
      fetch_url += "&difficulty_level=" + this.filterDifficultyLevel;
    debugger
    this.api.detailsGetFetch(fetch_url, this.session_id).subscribe((data: any) => {

      if (data.status == "true" && data.questions[0]) {
        debugger
        this.poolTemp = data.questions;
        this.questionPool = data.questions;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.qPage = data.pagination;
        this.showModel();
        this.hideSpinner();

      }
      else {
        this.spinner.hide();
        this.hideSpinner();
        this.popup.sucessMessage = data.message;
        this.popup.failurepopup();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        this.hideSpinner();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  hideSpinner() {
    this.spinner.hide();
  }

  saveQuestions() {
    this.submitted = true;
    if (this.selected.length <= 0) {
      // this.ToasterService.openSnackBar("Select any question", '', 'failed');
      return;
    }

    // console.log(this.eligibilityForm.value);
    this.spinner.show();
    let count: number = 0;

    var body = { "course_content_id": this.assesmentId, 'question_ids': this.selected };
    var url = "select_from_question_bank";
    this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
      debugger
      console.log(data)
      count++;
      if (data.status == "true") {
        this.closeModel();
        this.spinner.hide();
        this.selected = [];
        this.submitted = false;
        this.popup.sucessMessage = data.message;
        this.popup.sucesspopup();
        this.setPage({ offset: 1 });

      }
      else {
        this.spinner.hide();
        this.closeModel();
        this.selected = [];
        this.submitted = false;
        this.popup.failureMessage = data.message
        this.popup.failurepopup()
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }

  onQuestionTypeFilter() {
    this.filterQuestionType = this.questionForm.get('questionType').value > 0 ? this.questionForm.get('questionType').value.toString() : '';
    this.loadAllQuestions({ offset: 0 });
  }

  onQuestionCategoryFilter() {
    this.filterQuestionCategory = this.questionForm.get('questionCategory').value > 0 ? this.questionForm.get('questionCategory').value.toString() : '';
    this.loadAllQuestions({ offset: 0 });
  }

  onDifficultyLevelFilter() {
    this.filterDifficultyLevel = this.questionForm.get('difficultyLevel').value > 0 ? this.questionForm.get('difficultyLevel').value.toString() : '';
    this.loadAllQuestions({ offset: 0 });
  }

  viewQuestion(id) {
    // this.router.navigate(['admin/question-add', { id: `${id}` }]);
    window.open('admin/question-add;id='+id, '_blank');
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.randomQuestionForm.get(controlName).hasError(errorName);
  };

  showModalDialog() {
    this.displayModal_ = true;
    this.submitted_ = false;
    this.randomQuestionForm.reset();
    this.randomQuestionForm.get('question_type').setValue('');
  }
  async getQsPoolAndSaveRandomQs() {
    this.submitted_ = true;
    if (this.randomQuestionForm.invalid) {
      return true;
    }
    this.spinner.show();
    let body = {
      course_content_id: this.assesmentId,
      question_type: this.randomQuestionForm.value.question_type,
      no_of_questions: this.randomQuestionForm.value.no_of_questions
    }
    var url = "add_random_questions";

    this.api.detailsAddFetch(body, url, this.session_id).subscribe(async (data: any) => {

      if (data.status == "true") {
        this.displayModal_ = false;
        this.randomQuestionForm.reset();
        this.popup.sucessMessage = data.message;
        this.popup.sucesspopup();
        this.setPage({ offset: 1 });
        this.hideSpinner();
      }
      else {
        this.spinner.hide();
        this.displayModal_ = false;
        this.hideSpinner();
        this.popup.failureMessage = data.message;
        this.popup.failurepopup();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        this.hideSpinner();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });

  }

}
