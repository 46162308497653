<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-sm-left">
                        <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/admin/courseElement']">Course Element</a></li>
                        <li *ngIf="!isEdit" class="breadcrumb-item active">Add {{ courseElementTypeName }}</li>
                        <li *ngIf="isEdit" class="breadcrumb-item active">Edit {{ courseElementTypeName }}</li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
        <div class="card elevation-0">
            <div class="card-header">
              <h1 *ngIf="!isEdit">Add {{ courseElementTypeName }}</h1>
              <h1 *ngIf="isEdit">Edit {{ courseElementTypeName }}</h1>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-primary p-3 pb-5 add_contry_">
                            <form [formGroup]="courseElementForm" (ngSubmit)="onSubmit()">
                              <div class="col-md-6">
                                <div class="form-group" *ngIf="courseElementType==2 || courseElementType==3 || courseElementType==4" >
                                  <label>Subject *</label>
                                  <select class="form-control select2 selact_" (change)="onSubjectChanged($event)" formControlName="subject"
                                          style="width: 100%;"
                                          >
                                    <option value="">--Select Subject--</option>
                                    <option *ngFor="let items of subjectList" value="{{items.id}}">
                                      {{items.title}}
                                    </option>

                                  </select>
                                  <div *ngIf="submitted && f.subject.errors" class="text-danger">
                                    <small *ngIf="submitted && f.subject.errors.required">
                                      Subject is
                                      required
                                    </small>
                                  </div>
                                </div>
                                <div class="form-group" *ngIf="courseElementType==3 || courseElementType==4 " >
                                  <label>Chapter *</label>
                                  <select class="form-control select2 selact_" (change)="onChapterChanged($event)" formControlName="chapter"
                                          style="width: 100%;"
                                          >
                                          <option value="">--Select Chapter--</option>
                                    <option *ngFor="let items of chapterFilteredList" value="{{items.id}}">
                                      {{items.title}}
                                    </option>

                                  </select>
                                  <div *ngIf="submitted && f.chapter.errors" class="text-danger"> 
                                    <small *ngIf="submitted && f.chapter.errors.required">                  
                                      Chapter is
                                      required
                                    </small>
                                  </div>
                                </div>
                                <div class="form-group" *ngIf="courseElementType==4 ">
                                  <label>Concept *</label>
                                  <select class="form-control select2 selact_" formControlName="concept"
                                          style="width: 100%;"
                                          >
                                    <option value="">--Select Concept--</option>
                                    <option *ngFor="let items of conceptFilteredList" value="{{items.id}}">
                                      {{items.title}}
                                    </option>

                                  </select>
                                  <div *ngIf="submitted && f.concept.errors" class="text-danger">
                                    <small *ngIf="submitted && f.concept.errors.required">
                                      Concept is
                                      required
                                     </small>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>Title *</label>
                                  <input type="text" formControlName="title" class="form-control selact_"
                                         placeholder="Title"
                                         >
                                  <div *ngIf="submitted && f.title.errors" class="text-danger">
                                    <div *ngIf="f.title.errors.required">
                                      <small>
                                      Title is
                                      required
                                      </small>
                                    </div>
                                    <div *ngIf="f.title.errors.validspace">
                                      <small> 
                                      Invalid Title
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>Status *</label>
                                  <select class="form-control select2 selact_" formControlName="status"
                                          style="width: 100%;"
                                          >
                                    <option value="1">Active</option>
                                    <option value="2">Disabled</option>
                                  </select>
                                  <div *ngIf="submitted && f.status.errors" class="text-danger">
                                    <div *ngIf="f.status.errors.required">
                                      <small>
                                      Status is
                                      required
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="clearfix"></div> -->
                              </div>
                                <div class="clearfix"></div>
                                <div class="p-3">
                                    <button type="submit"
                                        class="btn btn-secondary btn-dark bor-rad_ float-right">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </section>
    <!-- /.content -->
</div>
