import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ReportsRoutingModule } from './reports-routing.module';
import { PaymentreportComponent } from './paymentreport/paymentreport.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { CsvModule } from '@ctrl/ngx-csv';


@NgModule({
  declarations: [PaymentreportComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxDatatableModule,
    CsvModule
  ]
})
export class ReportsModule { }
