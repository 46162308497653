import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvoiceComponent } from './invoice/invoice.component';
import { PaymentListComponent } from './payment-list/payment-list.component';

const routes: Routes = [
  {path: '', component: PaymentListComponent},
  {path: 'invoice/:id', component: InvoiceComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserReportsRoutingModule { }
