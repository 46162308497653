<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item">Live Class</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="card">
  
        <div class="card-header">
          <h1>Live Class</h1>
          
        </div>
  
        
        <div class="card-body table-responsive">

            <div>
  
              <ngx-datatable #myTable class="material striped" [rows]="liveClassList" [columnMode]="'force'"
                             [headerHeight]="50" [footerHeight]="50" [rowHeight]="50"
                             [sorts]="[{prop: 'name', dir: 'desc'}]" [selected]="selected" [selectionType]="'checkbox'"
                             [externalPaging]="true" [count]="page.total" [offset]="page.current_page" [limit]="page.per_page"
                             (select)="onSelect($event)" (page)="setPagetest($event)">
                <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                      [draggable]="false" [resizeable]="false">
                  <ng-template ngx-datatable-header-template let-value="value"
                               let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                    <input type="checkbox" [checked]="allRowsSelected"
                           (change)="selectFn(!allRowsSelected)" />
                  </ng-template>
                  <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                               let-onCheckboxChangeFn="onCheckboxChangeFn">
                    <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
                  </ng-template>
                </ngx-datatable-column>
            
                <ngx-datatable-column [width]="300" name="Course Name" prop="name">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.course_name}}content_title
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [width]="300" name="Course Content Title" prop="name">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.content_title}}
                  </ng-template>
                </ngx-datatable-column>
  
  
                <ngx-datatable-column name="Batch Start Date" prop="start_date">
                  <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                    {{batchesArray[rowIndex]
                    }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="URL" prop="url">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.live_url
                      }}
                    </ng-template>
                  </ngx-datatable-column>

                <ngx-datatable-column name="Action" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <a title="View Live Class" href="Javascript:void(0);" (click)="viewLiveClass(row.id)"
                    class="edit_button mr-2"><i class="far fa-eye"></i></a>
                     <a href="Javascript:void(0);" (click)=" deleteCourse(row.id)"
                            class="close_button"><i class="far fa-times-circle"></i></a>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
              <a (click)="deleteMultipleItem()" class="btn btn-secondary btn-dark float-right add_country" style="margin-top:2%">
                Delete Selected Items
               </a>
            </div>
          </div>
  
        <!-- <div class="card-body">
          <div class="d-flex justify-content-between">
            <div>
              <p>{{deleteList.length}} Questions selected</p>
            </div>
            <div class="p-2">
              <button (click)="bulkDelete()" [disabled]="deleteList.length==0" type="button"
                class="btn btn-secondary btn-dark float-right add_country">Delete</button>
            </div>
          </div>
        </div> -->
  
      </div>
      <!-- /.card -->
    </section>
    <!-- /.content -->
  </div>
  