import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebRoutingModule } from './web-routing.module';
import { WebComponent } from './web.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HomeComponent } from './home/home.component';
import { CoursesListComponent } from './course/courses-list/courses-list.component';
import { CoursesDetailsComponent } from './course/courses-details/courses-details.component';
import { InstructorDetailsComponent } from './instructor/instructor-details/instructor-details.component';
import { RegisterationComponent } from './registeration/registeration.component';
import { WebloginComponent } from './weblogin/weblogin.component';
import { CourseOverviewComponent } from './course/mycourse/courseoverviews/course-overview/course-overview.component';
import { MycoursesComponent } from './course/mycourse/mycourses/mycourses.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EligibilityTestComponent } from './course/eligibility-test/eligibility-test.component';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { MyclassesComponent, myclassesFeedbackComponent } from './course/mycourse/myclasses/myclasses.component';
import { GradesComponent } from './course/mycourse/courseoverviews/grades/grades.component';
import { OverviewsComponent } from './course/mycourse/courseoverviews/overviews/overviews.component';
import { CarouselModule } from "ngx-owl-carousel-o";
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { ThreadAddComponent } from './course/mycourse/courseoverviews/discussion-forum/thread-add/thread-add.component';
import { DiscussionForumListComponent } from './course/mycourse/courseoverviews/discussion-forum/discussion-forum-list/discussion-forum-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { ThreadViewComponent } from './course/mycourse/courseoverviews/discussion-forum/thread-view/thread-view.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PaymentComponent } from './course/payments/payment/payment.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { WebForgotpasswordComponent } from './web-forgotpassword/web-forgotpassword.component';
import { WebResetpasswordComponent } from './web-resetpassword/web-resetpassword.component';
import { ReferalcodePopupComponent } from './referalcode-popup/referalcode-popup.component';
import { CoursePriceDetailComponent } from './course/course-price-detail/course-price-detail.component';
import { CoursePaymentStatusComponent } from './course/course-payment-status/course-payment-status.component';
import { AssessmentComponent } from './course/mycourse/assessment/assessment.component';
import { ProfileModule } from './profile/profile.module';
import { UserReportsModule } from './user-reports/user-reports.module';
import { CourseInfoComponent } from './course/mycourse/courseoverviews/course-info/course-info.component';
import { MessagesComponent } from './course/mycourse/courseoverviews/messages/messages.component';
import { CertificateComponent } from './course/mycourse/courseoverviews/certificate/certificate.component';
import { NotificationsModule } from './notifications/notifications.module';
import { TeamDetailsComponent } from './team-details/team-details.component';
import { ReferralPointComponent } from './referral-point/referral-point.component';
import { SupportEnquiryComponent } from './support-enquiry/support-enquiry.component';
import { CoursePaymentStatusFreeChargeComponent } from './course/course-payment-status-free-charge/course-payment-status-free-charge.component';

@NgModule({
  declarations: [
    WebComponent,
    HomeComponent,
    CoursesListComponent,
    CoursesDetailsComponent,
    InstructorDetailsComponent,
    RegisterationComponent,
    WebloginComponent,
    CourseOverviewComponent,
    MycoursesComponent,
    EligibilityTestComponent,
    QuickLinksComponent,
    MyclassesComponent,
    myclassesFeedbackComponent,
    GradesComponent,
    OverviewsComponent,
    ThreadAddComponent,
    DiscussionForumListComponent,
    ThreadViewComponent,
    PaymentComponent,
    WebForgotpasswordComponent,
    WebResetpasswordComponent,
    ReferalcodePopupComponent,
    CoursePriceDetailComponent,
    CoursePaymentStatusComponent,
    AssessmentComponent,
    CourseInfoComponent,
    MessagesComponent,
    CertificateComponent,
    TeamDetailsComponent,
    ReferralPointComponent,
    SupportEnquiryComponent,
    CoursePaymentStatusFreeChargeComponent,
  ],
  imports: [
    CommonModule,
    WebRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    RouterModule,
    BreadcrumbModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTabsModule,
    MatRadioModule,
    DragDropModule,
    MatInputModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxPaginationModule,
    NgxDatatableModule,
    AngularEditorModule,
    MatSidenavModule,
    PdfViewerModule,
    ProfileModule,
    UserReportsModule,
    CarouselModule,
    NotificationsModule
  ],
})
export class WebModule { }
