<aside class="main-sidebar sidebar-dark-primary">
    <!-- Brand Logo -->
    <a class="brand-link" [routerLink]="['/admin']" > <img src="../../assets/img/logo.png" alt="AdminLTE Logo" class="brand-image img-circle"
            style="opacity: .8"> <span class="brand-text font-weight-bolder">Al
            Jamia</span> </a>
    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul id="sideView" class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <!-- Add icons to the links using the .nav-icon class
       with font-awesome or any other icon font library -->
                <li class="nav-item has-treeview"> <a class="nav-link"> <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p> Dashboard <i class="right fas fa-angle-left"></i> </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item "> <a [routerLink]="['/admin']" class="nav-link"> <i class="far fa-circle nav-icon"></i>
                                <p>Home</p>
                            </a> </li>
                    </ul>
                </li>
                <!-- <li class="nav-item has-treeview"> <a class="nav-link"> <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p> System <i class="right fas fa-angle-left"></i> </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item has-treeview"> <a class="nav-link"> <i
                                    class="nav-icon fas fa-tachometer-alt"></i>
                                <p> System <i class="right fas fa-angle-left"></i> </p>
                            </a>

                        </li>
                    </ul>
                </li> -->

                <li class="nav-item has-treeview" *ngIf="user_role != 'sme'"> <a class="nav-link"> <i class="nav-icon fas fa-globe-asia"></i>
                        <p>Localization <i class="right fas fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="['/admin/country']" routerLinkActive="active" class="nav-link"> <i class="nav-icon far fa-circle"></i>
                                <p> Countries </p>
                            </a>
                        </li>
                        <li class="nav-item"> <a [routerLink]="['/admin/state']" routerLinkActive="active"
                                class="nav-link"> <i class="nav-icon far fa-circle"></i>
                                <p> States </p>
                            </a></li>
                        <li class="nav-item"><a [routerLink]="['/admin/district']" routerLinkActive="active" class="nav-link"> <i
                                    class="nav-icon far fa-circle"></i>
                                <p> Districts </p>
                            </a></li>

                        <!-- <li class="nav-item"><a [routerLink]="['/admin/qualification']" routerLinkActive="active" class="nav-link"> <i
                                    class="far fa-circle nav-icon"></i>
                                <p> Qualifications</p>
                            </a></li>

                        <li class="nav-item"><a [routerLink]="['/admin/stream']" routerLinkActive="active" class="nav-link"> <i
                                    class="far fa-circle nav-icon"></i>
                                <p>Course Streams</p>
                            </a></li> -->
                        <!-- <li class="nav-item"><a [routerLink]="['/admin/currency']" routerLinkActive="active"
                                class="nav-link"> <i class="nav-icon far fa-circle"></i>
                                <p> Currencies </p>
                            </a></li> -->
                    </ul>
                </li>
                <!-- <li class="nav-item"> <a class="nav-link"> <i class="far fa-copy nav-icon"></i>
                        <p>Settings</p>
                    </a> </li> -->
                <li class="nav-item  has-treeview" *ngIf="user_role != 'sme'"> <a class="nav-link"> <i class="fab fa-buffer nav-icon"></i>
                        <p>Settings<i class="right fas fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="['/admin/setting']" routerLinkActive="active" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Settings</p>
                            </a>
                        </li>
                    </ul>
                    
                </li>
                <!-- <li class="nav-item has-treeview"> <a class="nav-link"> <i class="nav-icon fas fa-chess-queen"></i>
                        <p> Qualification <i class="fas fa-angle-left right"></i> </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item"> </li>
                    </ul>
                </li> -->
                <li class="nav-item has-treeview"> <a class="nav-link"> <i class="nav-icon fas fa-th"></i>
                        <p> Courses <i class="right fas fa-angle-left"></i> </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item"> <a [routerLink]="['/admin/courseCategory']" routerLinkActive="active" class="nav-link"> <i
                                    class="far fa-circle nav-icon "></i>
                                <p> Course Categories </p>
                            </a> </li>
                        <li class="nav-item">
                          <a [routerLink]="['/admin/courseElement']" routerLinkActive="active" class="nav-link">
                            <i class="far fa-circle nav-icon "></i>
                            <p> Course Elements </p>
                          </a>
                        </li>
                        <li class="nav-item"> <a [routerLink]="['/admin/course/courseList']" routerLinkActive="active" class="nav-link"> <i
                            class="far fa-circle nav-icon "></i>
                        <p> Course Lists </p>
                    </a> </li>
                    <li class="nav-item"> <a [routerLink]="['/admin/course/liveClassList']" routerLinkActive="active" class="nav-link"> <i
                        class="far fa-circle nav-icon "></i>
                    <p> Live Class </p>
                </a> </li>
                    <li class="nav-item"> <a [routerLink]="['/admin/batch']" routerLinkActive="active" class="nav-link"> <i
                        class="far fa-circle nav-icon "></i>
                    <p> Batches </p>
                </a> </li>
                            <!-- <li class="nav-item"> <a [routerLink]="['/admin/course/addcourse']" routerLinkActive="active" class="nav-link"> <i
                                class="far fa-circle nav-icon "></i>
                            <p> Add Courses </p>
                        </a> </li> -->
                    </ul>
                </li>

                <li class="nav-item has-treeview"> <a class="nav-link"> <i class="nav-icon fas fa-th"></i>
                  <p> Eligibility Test <i class="right fas fa-angle-left"></i> </p>
              </a>
              <ul class="nav nav-treeview">

                  <li class="nav-item"> <a [routerLink]="['/admin/eligibility-test/list']" routerLinkActive="active" class="nav-link"> <i
                      class="far fa-circle nav-icon "></i>
                  <p> Eligibility Test Lists </p>
              </a> </li>

              </ul>
            </li>

                <li class="nav-item has-treeview"> <a class="nav-link"> <i class="nav-icon fab fa-buffer"></i>
                        <p> Questions <i class="fas fa-angle-left right"></i> </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item"> <a [routerLink]="['/admin/questionCategory']"
                            routerLinkActive="active" class="nav-link"> <i
                                    class="nav-icon far fa-circle"></i>
                                <p> Question Categories</p>
                            </a> </li>
                    </ul>
                    <ul class="nav nav-treeview">
                        <li class="nav-item"> <a [routerLink]="['/admin/question']" routerLinkActive="active" class="nav-link"> <i class="far fa-circle nav-icon"></i>
                                <p>Questions</p>
                            </a> </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" *ngIf="user_role != 'sme'">
                  <a class="nav-link">
                    <i class="nav-icon fas fa-table"></i>
                    <p> CMS <i class="fas fa-angle-left right"></i> </p>
                  </a>
                  <ul class="nav nav-treeview">
                    <li class="nav-item">
                      <a [routerLink]="['/admin/page']" routerLinkActive="active" class="nav-link">
                        <i class="far fa-file nav-icon"></i>
                        <p>Page Managements</p>
                      </a>
                    </li>
                  </ul>
                  <ul class="nav nav-treeview">
                    <li class="nav-item">
                      <a [routerLink]="['/admin/testimonial']" routerLinkActive="active" class="nav-link">
                        <i class="far fa-comments nav-icon"></i>
                        <p>Testimonials</p>
                      </a>
                    </li>
                  </ul>
                  <ul class="nav nav-treeview">
                    <li class="nav-item">
                      <a [routerLink]="['/admin/banner']" routerLinkActive="active" class="nav-link">
                        <i class="far fa-flag nav-icon"></i>
                        <p>Banners</p>
                      </a>
                    </li>
                  </ul>
                  <ul class="nav nav-treeview">
                    <li class="nav-item">
                      <a [routerLink]="['/admin/team']" routerLinkActive="active" class="nav-link">
                        <i class="far fa-cubes nav-icon"></i>
                        <p>Teams</p>
                      </a>
                    </li>
                  </ul>

                  <!-- <ul class="nav nav-treeview"> -->
                    <!-- <li class="nav-item"><a [routerLink]="['/faq']" class="nav-link  active"> <i
                class="nav-icon fas fa fa-question-circle"></i>
            <p> FAQ</p>
        </a></li> -->
                    <!-- <li class="nav-item">
                      <a [routerLink]="['/admin/faq']" routerLinkActive="active" class="nav-link">
                        <i class="nav-icon fas fa fa-question-circle"></i>
                        <p> FAQ</p>
                      </a>
                    </li>
                  </ul> -->

                </li>
                <li class="nav-item has-treeview"> <a class="nav-link"> <i class="nav-icon fas fa-table"></i>
                        <p> Enrollments <i class="fas fa-angle-left right"></i> </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item"> <a class="nav-link" [routerLink]="['/admin/enrollments/list']" routerLinkActive="active"> <i class="far fa-circle nav-icon"></i>
                                <p>Enrollments</p>
                            </a> </li>
                    </ul>
                    <ul class="nav nav-treeview" *ngIf="user_role != 'sme'">
                        <li class="nav-item"> <a class="nav-link"> <i class="far fa-circle nav-icon"></i>
                                <p>Support Enquiries</p>
                            </a> </li>
                    </ul>
                    <ul class="nav nav-treeview">
                        <li class="nav-item"> <a class="nav-link" [routerLink]="['/admin/discussion-forum']" routerLinkActive="active"> <i class="far fa-circle nav-icon"></i>
                                <p>Discussion Forums</p>
                            </a> </li>
                    </ul>
                    <ul class="nav nav-treeview" *ngIf="user_role == 'sme'">
                        <li class="nav-item"> <a class="nav-link" [routerLink]="['/admin/message']" routerLinkActive="active"> <i class="far fa-circle nav-icon"></i>
                                <p>Messages</p>
                            </a> </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" *ngIf="user_role != 'sme'"> <a class="nav-link" > <i class="nav-icon fas fa-table"></i>
                    <p> Enroll New <i class="fas fa-angle-left right"></i> </p>
                </a>
                <ul class="nav nav-treeview">
                    <li class="nav-item"> <a [routerLink]="['/admin/enroll-student/add']" routerLinkActive="active" class="nav-link"> <i class="far fa-circle nav-icon"></i>
                            <p>Enroll New</p>
                        </a> </li>
                </ul>
            </li>
                <li class="nav-item has-treeview" *ngIf="user_role != 'sme'"> <a class="nav-link"> <i class="nav-icon fas fa-table"></i>
                        <p> Users <i class="fas fa-angle-left right"></i> </p>
                    </a>
                    <!-- <ul class="nav nav-treeview">
                        <li class="nav-item"> <a class="nav-link"> <i class="far fa-circle nav-icon"></i>
                                <p>Roles</p>
                            </a> </li>
                    </ul> -->
                    <ul class="nav nav-treeview">
                        <li class="nav-item"> <a [routerLink]="['/admin/sme']" routerLinkActive="active" class="nav-link"> <i class="far fa-circle nav-icon"></i>
                                <p>SME</p>
                            </a> </li>
                    </ul>
                    <ul class="nav nav-treeview">
                        <li class="nav-item"> <a [routerLink]="['/admin/student']" routerLinkActive="active" class="nav-link"> <i class="far fa-circle nav-icon"></i>
                                <p>Students</p>
                            </a> </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" *ngIf="user_role != 'sme'"> <a class="nav-link"> <i class="nav-icon far fa-calendar-alt"></i>
                        <p> Reports <i class="right fas fa-angle-left"></i> </p>
                    </a> 
                    <ul class="nav nav-treeview">

                        <li class="nav-item"> <a [routerLink]="['/admin/reports/paymentreport']" routerLinkActive="active" class="nav-link"> <i
                            class="far fa-circle nav-icon "></i>
                        <p> Payment Reports </p>
                    </a> </li>
      
                    </ul>
                </li>

            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>
