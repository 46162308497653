import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../helpers/must-match.validator';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupService } from '../services/popup.service';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  isError: boolean = false;
  isInvalid: boolean = false;
  tokenValue: any;

  constructor(private formBuilder: FormBuilder, public router: Router, private route: ActivatedRoute, private popup: PopupService, public api: ApiService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', Validators.required ],
      confirmPassword: ['', Validators.required]
    },
    {
      validator: MustMatch('newPassword', 'confirmPassword')
    });

    this.route.params.subscribe(params=>{
      console.log(params);
      if(params.token){
        this.tokenValue=params.token;
      }else
      {
        this.popup.failureMessage = "Unauthorized entry";
        this.popup.failurepopup()
      }

    });
  }

  

  resetPassword() {
    debugger
    if (this.resetPasswordForm.invalid) {
      this.isError = true;
      return;
    }
    this.spinner.show();
    var fetch_url= "reset_password";
    let param = { password: this.resetPasswordForm.value.newPassword,
      // password_confirmation: this.resetPasswordForm.value.confirmPassword,
      token: this.tokenValue
       }
       debugger
     this.api.postDetails(fetch_url,param).subscribe((data: any) => {
       debugger
       if(data.status == "true" && data){
        this.spinner.hide();
        this.popup.sucessMessage = data.message;
        this.popup.sucesspopup();
        localStorage.clear(); 
        this.router.navigate(['admin/login']);
          
       }
       else {
        this.spinner.hide();
        this.popup.failureMessage = data.message;
        this.popup.failurepopup()
        // this.WebComponent.islogin = false;
      }
     });
    
  }

}
