import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Page } from 'src/app/models/pageDto';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';
import { ToasterService } from 'src/app/services/toaster.service';
import Swal from 'sweetalert2';
import { ListEligibilityTestComponent } from '../../Eligibility-Test/list-eligibility-test/list-eligibility-test.component';
import { CsvModule } from '@ctrl/ngx-csv';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-list-enrollments',
  templateUrl: './list-enrollments.component.html',
  styleUrls: ['./list-enrollments.component.css']
})
export class ListEnrollmentsComponent implements OnInit {

  @ViewChild('myTable') table: ListEligibilityTestComponent;
  session_id: any;
  searchTerm: any = '';
  selected = [];
  temp = [];
  enrollmentList: any = [];
  page: Page;
  csvData: any[] = [];
  downLoadData = [];
  deleteList: any = [];
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router, private popup: PopupService, private spinner: NgxSpinnerService, private ToasterService: ToasterService,public datepipe: DatePipe) { }
  enrollmentListForm: FormGroup;
  enrollmentListFilterForm: FormGroup;
  // deleteList: any = [];
  courseList: any = [];
  filterTerm: any = '';
  todayDate: any;
  
  startDateFrom: any;
  // csvData: any[] = [];
  startDateTo: any;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.enrollmentListForm = this.formBuilder.group({
      search: ['']
    });
    this.enrollmentListFilterForm = this.formBuilder.group({
      course: ['0'],
      enrollmentFrom: [''],
      enrollmentTo: [''],
      status: ['0'],
     
    });
    
    
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page: 10
    };
    var date = new Date();
    this.todayDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    var fromStartDate = "2022-01-01";
    this.startDateFrom = this.datepipe.transform(fromStartDate, 'yyyy-MM-dd');
    this.startDateTo = this.startDateFrom; 
    this.setPage({ offset: 1 });
    this.getCourseList();
    
  }
  setToStartDate(){
    var toDate = this.datepipe.transform(this.enrollmentListFilterForm.value.enrollmentFrom, 'yyyy-MM-dd');
    this.startDateTo = toDate;
  }

  onFilter(){
    this.filterTerm = '';
    if(this.enrollmentListFilterForm.value.course != 0 ){
      this.filterTerm += "&course_id=" + this.enrollmentListFilterForm.value.course;
    }
    if(this.enrollmentListFilterForm.value.enrollmentFrom != '' ){
      this.filterTerm += "&from_date=" + this.enrollmentListFilterForm.value.enrollmentFrom;
    }
    if(this.enrollmentListFilterForm.value.enrollmentTo != '' ){
      this.filterTerm += "&to_date=" + this.enrollmentListFilterForm.value.enrollmentTo;
    }
    if(this.enrollmentListFilterForm.value.status != 0 ){
      this.filterTerm += "&status_id=" + this.enrollmentListFilterForm.value.status;
    }
    if(this.filterTerm != ''){
    this.setPage({ offset: 1 });
    
    }

  }

  onReset(){
    this.enrollmentListFilterForm.patchValue({
      course: '0',
      enrollmentFrom: '',
      enrollmentTo: '',
      status: '0'
    });
    this.filterTerm = '';
    this.setPage({ offset: 1 })
  }
  
  

  onSearch() {
    this.searchTerm = this.enrollmentListForm.get('search').value.toString();
    debugger
    this.setPage({ offset: 1 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  getNumber(data): any{
    return Number.parseInt(data);
  }

  setPage(pageInfo) {
    debugger
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.enrollmentList = [];
    var course_fetch_url = "getEnrollments?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    if (this.searchTerm.length > 0)
      course_fetch_url += "&searchTerm=" + this.searchTerm;
      if (this.filterTerm != '')
      course_fetch_url +=  this.filterTerm;
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
    //  debugger
      if (data.status == "true" && data.enrollments) {
        this.temp = data.enrollments;
        this.enrollmentList = data.enrollments;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

  onSelect({ selected }) {

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  // deleteCourse(id) {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.spinner.show();
  //       var body = { "eligibility_test_id": +id };
  //       var del_url = "delete_eligibility_test?eligibility_test_id=" + `${id}`;
  //       this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
  //         debugger
  //         if (data.status == "TRUE") {
  //           this.spinner.hide();
  //           this.ToasterService.openSnackBar(data.message, '', 'success');
  //           this.setPage({ offset: 0 });
  //         }
  //         else {
  //           this.spinner.hide();

  //         }
  //       },
  //         (err: HttpErrorResponse) => {
  //           if (err.status == 403) {
  //             this.spinner.hide();

  //             localStorage.clear()
  //             this.router.navigate(['admin/login'])
  //           }
  //         });


  //     }
  //   })
  // }

  deleteEnrollments() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var body = { "enrollment_ids": this.deleteList };
        var del_url = "delete_multiple_enrollments";
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          this.deleteList = [];
          if (data.status == "true") {
            this.spinner.hide();
            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.setPage({ offset: 0 });
          }
          else {
            this.spinner.hide();
            this.popup.failureMessage = data.message
            this.popup.failurepopup()

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }else{
        this.deleteList = [];
      }
    })
  }

  deleteMultipleItem(){
    debugger
    console.log(this.selected);
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteList.push(element.id);
      });
      this.deleteEnrollments();

    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    }
  }

  deleteSingleItem(id){
    this.deleteList.push(id);
    this.deleteEnrollments();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
      return title_name || status_name;
    });

    this.enrollmentList = temp;
  }

  editTest(id) {
    this.router.navigate(['/admin/eligibility-test/edit/'+id]);
  }
  getCourseList() {
    debugger
    console.log(this.enrollmentListForm.value.range)
    this.spinner.show();
    this.courseList = [];
    var course_fetch_url = "getCourselist";
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      if (data.status == "true" && data.course[0]) {
        this.courseList = data.course;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  } 

  ngAfterViewInit(){
    this.getDownloadData();
  }

  getDownloadData(){
    debugger
    this.spinner.show();
    // this.page.current_page = pageInfo.offset;
    // var course_fetch_url = "getEnrollments?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    var course_fetch_url = "getEnrollments";
    if (this.searchTerm.length > 0)
      course_fetch_url += "&searchTerm=" + this.searchTerm;
    this.api.detailsGetFetch(course_fetch_url, this.session_id).subscribe((data: any) => {
     debugger
      if (data.status == "true" && data.enrollments) {
        this.csvData = data.enrollments;
        // this.downLoadData = data.enrollments;
        // for (let i = 0; i < data.enrollments.length; i++) {
        //   debugger
        //   let item = {
        //     name: this.downLoadData[i].name,
        //     course_name: this.downLoadData[i].course_name,
        //     learning_type:this.downLoadData[i].value,
        //     enrolled_date:this.downLoadData[i].enrolled_date,
        //     complete_percentage: this.downLoadData[i].complete_percentage,
        //     status:this.downLoadData[i].status

        //   }
        //   this.downLoadData.push(item)

        // }
        
        // this.csvData = this.downLoadData;
        // console.log(this.csvData);
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }

}
