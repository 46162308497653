<div class="container-fluid py-4 overview_course">
  <div class="pb-3">
    <h1>Certificate</h1>
  </div>

  <div class="certificate bg-course-nofinish bg-course-finish p-3">

    <div class=" col-md-5 card mt-5 certificate-box p-3 mx-auto text-center"
      *ngIf="crtDetials && crtDetials.complete_status == 'Undergoing'">
      <span class="p-3">

        <h2 class="mb-0">{{crtDetials.certificate_message}}</h2>
        <!-- <h3>3 of 23 complete</h3> -->

      </span>
    </div>


    <div class=" col-md-5 card mt-5 certificate-box p-3 mx-auto text-center" *ngIf="crtDetials && crtDetials.complete_status == 'Completed' && crtDetials.certificate_download_link == null">
      <span class="p-3">


        <h2 class="mb-0">Congratulations.</h2>
        <h3>Your course is completed.</h3>


        <div class="cer-downlode col-md-12 py-2 mt-2">

          <a (click)="generateCertificate()" class="btn cour-button">Generate Your Certificate</a>

        </div>
      </span>
    </div>


    <div class=" col-md-5 card mt-5 certificate-box p-3 mx-auto text-center" *ngIf="crtDetials && crtDetials.complete_status == 'Completed' && crtDetials.generate_button == false && crtDetials.certificate_download_link != null">
      <span class="p-3">


        <img src="../../../assets/web/assets/images/maqasi-al-sharia_certificate.jpg" class="img-fluid">

        <div class="cer-downlode col-md-12 py-2 mt-2">
          <a href="{{crtDetials.certificate_download_link}}" target="_blank" download class="btn cour-button">Download</a>
        </div>
      </span>
    </div>

  </div>
</div>
