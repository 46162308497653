import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StreamAddComponent } from './stream-add.component';


const routes: Routes = [

  {
    path: '', component: StreamAddComponent,
    data: { title: 'Add Stream | Admin' }
  },


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StreamAddRoutingModule { }
