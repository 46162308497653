<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item active">Eligibility Tests</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <section class="content">
    <div class="card">

      <div class="card-header">
        <h1>Eligibility Tests</h1>
      </div>



      <form [formGroup]="eligibilityListForm" (ngSubmit)="onSearch()" class="form-inline filter_list">
        <div class="card-body pb-0">
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <input type="text" formControlName="search" class="form-control selact_ w-100" placeholder="Search text">
          </span>
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <button class="btn btn-dark ml-2 float-left" type="submit"> <i class="fas fa-search mr-1"></i> Search
            </button>
          </span>
          <a [routerLink]="['/admin/eligibility-test/add']" class="btn btn-secondary btn-dark float-right add_country">
            <i class="fas fa-plus mr-2"></i>Add Eligibility Test
          </a>
        </div>
      </form>
      <div class="card-body table-responsive">

        <ngx-datatable #myTable class="material striped" [rows]="eligibilityList" [columnMode]="'force'"
          [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [sorts]="[{prop: 'name', dir: 'desc'}]"
          [externalPaging]="true" [count]="page.total" [offset]="page.current_page" [limit]="page.per_page"
          (page)="setPagetest($event)" [selected]="selected" [selectionType]="'checkbox'" (select)="onSelect($event)" >
          <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false">
            <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
              let-selectFn="selectFn">
              <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
            </ng-template>
            <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn">
              <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
            </ng-template>
          </ngx-datatable-column>
          <!-- <ngx-datatable-column name="Sl No" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
              {{rowIndex+1}}
            </ng-template>
          </ngx-datatable-column> -->
          <ngx-datatable-column [width]="300" name="Title" prop="title">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.title}} <span class="m-3"
                *ngIf="getNumber(row.no_of_questions) > getNumber(row.no_of_questions_added)" matBadge="!"
                matBadgeColor="warn"></span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [width]="300" name="Course" prop="course_name">
            <ng-template title="row.course_name" let-row="row" ngx-datatable-cell-template>
              <div class="widthnew" title="{{row.course_name}}">{{row.course_name}}</div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Status" prop="status">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.status}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Action" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a matTooltip="Questions" title="View Questions"
                routerLink="/admin/eligibility-test/questions/list/{{row.id}}" class="active_button mr-2"><i
                  class="far fa-eye"></i></a>
              <a matTooltip="Edit" title="Edit" href="Javascript:void(0);" (click)="editTest(row.id)"
                class="edit_button mr-2"><i class="far fa-edit"></i></a>
              <a matTooltip="Delete" title="Delete" href="Javascript:void(0);" (click)="deleteCourse(row.id)"
                class="close_button"><i class="far fa-times-circle"></i></a>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <a (click)="deleteMultipleItem()" class="btn btn-secondary btn-dark float-right add_country" style="margin-top:2%">
          Delete Selected Items
         </a>
      </div>
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
</div>
