<div id="wrapper" class="eligibility_test">
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <button type="button" class="btn-close close_button_ float-right" (click)="closepopup()"
      aria-label="Close"></button>
    <form [formGroup]="eligibilityForm" role="form" class="width_ test_q add_course_">
      <div class="container-fluid overview_course divID">
        <div class="discussion_box">
          <div class="discussion_content">
            <div class="col-sm-12 col-md-12 col-lg-12 p-0 mx-auto">
              <div class="card border-0 shadow-none">
                <ng-container *ngIf="eligibilityTestDetails && !isFinishTest">
                  <div class="card-body p-0">
                    <h1>{{eligibilityTestDetails.title}}</h1>
                    <ul class="quiz_list_d">
                      <ng-container *ngIf="!isTestStart">
                        <li class="qst_number">QUESTIONS : {{eligibilityTestDetails.no_of_questions}}</li>
                      </ng-container>
                      <li class="points_q">TOTAL MARK :
                        {{ eligibilityTestDetails.no_of_questions * eligibilityTestDetails.grade_per_question}}</li>
                      <ng-container *ngIf="!isTestStart">
                        <li class="time_q">TIME LIMIT : {{eligibilityTestDetails.time_limit}} Min</li>
                      </ng-container>
                      <li class="total_q">TO PASS {{eligibilityTestDetails.pass_percentage}}% or higher</li>
                      <ng-container *ngIf="isTestStart">
                        <li class="qst_number">QUESTIONS : {{selectedQuestionIndex}} /
                          {{eligibilityTestDetails.no_of_questions}}
                        </li>
                      </ng-container>
                      <ng-container *ngIf="isTestStart">
                        <li class="time_q">ELAPSED TIME : {{elapsedTime}} Min</li>
                      </ng-container>
                    </ul>
                    <ng-container *ngIf="!isTestStart">
                      <!--<hr class="py-2">-->
                      <div class="quiz_start pb-5 question_container">
                        <p>{{eligibilityTestDetails.welcome_text}}</p>
                      </div>
                      <div class="clearfix"></div>
                      <div class="pt-3 pb-3 mt-4 text-left border-top btns">
                        <a class="btn-lg btn-dark float-right ml-1" (click)="startTest()">Quiz Start</a>

                      </div>
                    </ng-container>


                    <ng-container *ngIf="isTestStart">
                      <div class="question_container pb-1 pt-2">

                        <ng-container *ngIf="isOptionType">
                          <label id="example-radio-group-label" class="test_question float-left">
                            {{selectedQuestionIndex}} :
                            {{selectedQuestion.question_text}}</label>

                            <!-- allow next line
                               <label id="example-radio-group-label" class="test_question float-left">
                              {{selectedQuestionIndex}} :
                              <span [innerHtml]="selectedQuestion.question_text"></span>
                              </label> -->
                            

                          <ng-container *ngFor="let item of selectedQuestion.media_files">
                            <ng-container *ngIf="item.media_type == 1">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded text-center">
                                  <img src="{{s3FilePath}}{{item.path}}" class="mx-auto image_quz img-fluid">
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.media_type == 2">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded">
                                  <audio controls class="audio_quz audio_q_t">
                                    <source src="{{s3FilePath}}{{item.path}}" type="audio/mpeg">
                                  </audio>
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.media_type == 3">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded">
                                  <video width="100%" height="125" controls>
                                    <source src="{{s3FilePath}}{{item.path}}">
                                  </video>
                                </span>
                              </div>
                            </ng-container>
                          </ng-container>
                          <div class="clearfix"></div>
                          <mat-radio-group aria-labelledby="example-radio-group-label" (change)="optionChange()"
                            [formControl]="formTestOption" class="example-radio-group radio_btn_">
                            <mat-radio-button class="example-radio-button"
                              *ngFor="let option of selectedQuestion.options" [value]="option">
                              <ng-container *ngIf="option.option_media_type == 4">
                                <div class="mx-2 my-0 float-left"> {{option.option}} </div>
                              </ng-container>
                              <ng-container *ngIf="option.option_media_type == 1">
                                <div class="col-md-12 col-sm-12 float-left mb-1 qst_type_">
                                  <span class="rounded text-center">
                                    <img src="{{s3FilePath}}{{option.option}}" class="mx-auto image_quz img-fluid">
                                  </span>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="option.option_media_type == 2">
                                <div class="col-md-12 col-sm-12 float-left mb-1 qst_type_">
                                  <span class="rounded">
                                    <audio controls class="my-4">
                                      <source src="{{s3FilePath}}{{option.option}}" type="audio/mpeg">
                                    </audio>
                                  </span>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="option.option_media_type == 3">
                                <div class="col-md-12 col-sm-12 float-left mb-1 qst_type_">
                                  <span class="rounded">
                                    <video width="100%" height="125" controls>
                                      <source src="{{s3FilePath}}{{option.option}}">
                                    </video>
                                  </span>
                                </div>
                              </ng-container>
                            </mat-radio-button>
                          </mat-radio-group>
                        </ng-container>


                        <ng-container *ngIf="isMatchingOptions">
                          <label id="example-radio-group-label" class="test_question float-left">
                            {{selectedQuestionIndex}} :
                            {{selectedQuestion.question_text}}</label>
                            <div class="clearfix"></div>

                          <ng-container *ngFor="let item of selectedQuestion.media_files">
                            <ng-container *ngIf="item.media_type == 1">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded text-center">
                                  <img src="{{s3FilePath}}{{item.path}}" class="mx-auto image_quz img-fluid">
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.media_type == 2">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded">
                                  <audio controls class="audio_quz audio_q_t">
                                    <source src="{{s3FilePath}}{{item.path}}" type="audio/mpeg">
                                  </audio>
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.media_type == 3">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded">
                                  <video width="100%" height="125" controls>
                                    <source src="{{s3FilePath}}{{item.path}}">
                                  </video>
                                </span>
                              </div>
                            </ng-container>
                          </ng-container>

                          <div class="clearfix"></div>

                          <div cdkDropListGroup>
                            <div class="row">
                              <div class="example-container col-xs-6 col-sm-6 col-lg-6 match_the_following">
                                <h2></h2>
                                <div cdkDropList class="example-list">
                                  <div class="example-box" *ngFor="let option of selectedQuestion.child_questions"
                                    cdkDrag [cdkDragDisabled]="true" [value]="option">
                                    {{option.question_text}}
                                  </div>
                                </div>


                              </div>


                              <div class="example-container col-xs-6 col-sm-6 col-lg-6 match_the_following_ans">
                                <h2></h2>

                                <div cdkDropList [cdkDropListData]="selectedQuestion.options" class="example-list"
                                  (cdkDropListDropped)="drop($event)">
                                  <div class="example-box" *ngFor="let option of selectedQuestion.options" cdkDrag
                                    [value]="option">{{option.option}}</div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </ng-container>


                        <ng-container *ngIf="isFillinBlanks">

                          <label id="example-radio-group-label" class="test_question float-left qust_number_">
                            {{selectedQuestionIndex}} :
                          </label>




                          <!-- <div class="clearfix"></div>-->
                          <!--<hr class="mx-1">-->
                          <ng-container *ngFor="let option of te; let i = index">

                            <input *ngIf="option.qs=='@@@@@'" type="text" class="form-control drg_drop_qst"
                              style="width:150px;" (input)="onOptionadd($event.target.value, option.optionNumber)">

                            <span *ngIf="option.qs!='@@@@@'" class="cnt_answer_fill" > {{option.qs}} </span>

                            <!-- allow next line -->
                              <!-- <span *ngIf="option.qs!='@@@@@'" class="cnt_answer_fill" [innerHtml]="option.qs" ></span> -->


                          </ng-container>
                          <div class="clearfix"></div>

                          <ng-container *ngFor="let item of selectedQuestion.media_files">
                            <ng-container *ngIf="item.media_type == 1">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded text-center">
                                  <img src="{{s3FilePath}}{{item.path}}" class="mx-auto image_quz img-fluid">
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.media_type == 2">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded">
                                  <audio controls class="audio_quz audio_q_t">
                                    <source src="{{s3FilePath}}{{item.path}}" type="audio/mpeg">
                                  </audio>
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.media_type == 3">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded">
                                  <video width="100%" height="125" controls>
                                    <source src="{{s3FilePath}}{{item.path}}">
                                  </video>
                                </span>
                              </div>
                            </ng-container>
                          </ng-container>

                        </ng-container>

                        <ng-container *ngIf="isDragandDrop">

                          <label id="example-radio-group-label" class="test_question float-left qust_number_b">
                            {{selectedQuestionIndex}} :
                          </label>


                          <!--<div class="clearfix"></div>-->
                          <!--<h3 class="my-2">Options</h3>-->
                          <ul class="qust_list_drg mb-2 mt-0 border-0">
                            <div id="drag_options">
                              <ng-container *ngFor="let option of selectedQuestion.options; let j = index">
                                <li id="div1{{j}}" class="drg_dro_ans" draggable="true" value="{{option.option_id}}"
                                  (dragstart)="drag($event)">
                                  {{option.option}}
                                </li>


                              </ng-container>
                            </div>
                          </ul>
                          <div class="clearfix"></div>
                          <hr class="mx-1">
                          <ng-container *ngFor="let option of te; let i = index">

                            <span *ngIf="option=='@@@@@'" id="div2{{i}}" (drop)="onDrop($event)"
                              (dragover)="allowDrop($event)" class="form-control drg_drop_qst">
                            </span>
                            <span *ngIf="option!='@@@@@'" class="cnt_answer_fill"> {{option}} </span>


                          </ng-container>
                          <div class="clearfix"></div>

                          <ng-container *ngFor="let item of selectedQuestion.media_files">
                            <ng-container *ngIf="item.media_type == 1">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded text-center">
                                  <img src="{{s3FilePath}}{{item.path}}" class="mx-auto image_quz img-fluid">
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.media_type == 2">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded">
                                  <audio controls class="audio_quz audio_q_t">
                                    <source src="{{s3FilePath}}{{item.path}}" type="audio/mpeg">
                                  </audio>
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.media_type == 3">
                              <div class="col-md-4 col-sm-4 float-left mb-1 qst_type_">
                                <span class="rounded">
                                  <video width="100%" height="125" controls>
                                    <source src="{{s3FilePath}}{{item.path}}">
                                  </video>
                                </span>
                              </div>
                            </ng-container>
                          </ng-container>

                        </ng-container>
                        <div #divID></div>

                      </div>
                      <div class="pt-3 pb-3 mt-4 text-left border-top btns float-left widht_100">
                        <div class="clearfix"></div>
                        <a class="btn-lg btn-dark float-right ml-1" (click)="finishTest()">Finish</a>
                        <a class="btn-lg btn-dark float-right ml-1" *ngIf="isNext" (click)="nextTest()">Next</a>
                        <a class="btn-lg btn-dark float-right ml-1" *ngIf="isPrevious"
                          (click)="previousTest()">Previous</a>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="isFinishTest">


                  <div class="card   border-0 shadow-none">
                    <div class="card-body m-0 p-0">
                      <h1>Result</h1>
                      <hr class="py-1">
                      <div class="quiz_start pb-3" *ngIf="istestPass">
                        <div class="col-12 image_qui_finish text-center pass_l">
                          <img src="../../../assets/web/assets/images/passed.svg" class="mx-auto">
                          <h5>{{testResults.feedback}}</h5>
                          <h6>Your POINTS :
                            {{this.testResults.no_of_correct_answers}}/{{this.testResults.total_no_of_questions}} </h6>
                          <div class="clearfix"></div>
                          <div class="mx-auto text-center">
                            <div class="col-md-12 float-left">
                              <p>No. of correct answers : {{this.testResults.no_of_correct_answers}} </p>
                            </div>
                            <div class="col-md-12 float-left">
                              <p>No. of wrong answers : {{this.testResults.no_of_wrong_answers}}</p>
                            </div>
                            <div class="col-md-12 float-left">
                              <p>Minimum percentage :  {{this.testResults.minimum_grade_percentage}}%</p>
                            </div>
                            <div class="col-md-12 float-left">
                              <p>Total questions : {{this.testResults.total_no_of_questions}}</p>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                      <div class="quiz_start pb-3" *ngIf="!istestPass">

                        <div class="col-12 image_qui_finish text-center didnt_pass">
                          <img src="../../../assets/web/assets/images/fail.svg" class="mx-auto">
                          <h5>{{testResults.feedback}}</h5>
                          <h6>Your POINTS :
                            {{this.testResults.no_of_correct_answers}}/{{this.testResults.total_no_of_questions}} </h6>
                          <div class="clearfix"></div>
                          <div class="mx-auto text-center">
                            <div class="col-md-12 float-left">
                              <p>No. of correct answers : {{this.testResults.no_of_correct_answers}} </p>
                            </div>
                            <div class="col-md-12 float-left">
                              <p>No. of wrong answers : {{this.testResults.no_of_wrong_answers}}</p>
                            </div>
                            <div class="col-md-12 float-left">
                              <p>Minimum percentage : {{this.testResults.minimum_grade_percentage}}%</p>
                            </div>
                            <div class="col-md-12 float-left">
                              <p>Total questions : {{this.testResults.total_no_of_questions}}</p>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <a class="btn btn-danger ml-1 mx-auto retest_btn" (click)="restartCourse()">Retest</a>
                        </div>
                      </div>

                      <!--<div class="pt-3 pb-3 mt-2 text-left border-top btns">
                        <a class="btn btn-dark float-right ml-1" (click)="backTocourse()">Back to course</a>

                      </div>-->
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
