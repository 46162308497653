import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { PopupService } from 'src/app/services/popup.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UploadService } from 'src/app/services/upload.service';
import Swal from 'sweetalert2';
import { Validatespace } from "src/app/validation/spacevalidation";
import * as moment from "moment";
@Component({
  selector: 'app-thread-view',
  templateUrl: './thread-view.component.html',
  styleUrls: ['./thread-view.component.css'],
})
export class ThreadViewComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private PopupService: PopupService,
    private uploadService: UploadService,
    private ToasterService: ToasterService,
    private route: ActivatedRoute,
    private constantService: ConstantsService
  ) {
    this.threadCommentAddFormGroup = fb.group({
      comment: [
        '',
        Validators.compose([Validators.required,  Validatespace]),
      ],
    });
    this.threadCommentEditFormGroup = fb.group({
      comment: [
        '',
        Validators.compose([Validators.required,  Validatespace]),
      ],
      comment_id: [''],
    });

    this.threadEditFormGroup = fb.group({
      thread_id: [''],
      course_id: [''],
      title: ['', [Validators.required, Validators.minLength(3), Validatespace]],
      description: ['', [Validators.required, Validators.minLength(3), Validatespace]],
    });
  }

  threadId: any = '';
  session_id: any = '';
  userId: any = '';
  threadDetails: any;
  threadCommentAddFormGroup: FormGroup;
  threadCommentEditFormGroup: FormGroup;
  threadEditFormGroup: FormGroup;

  isCommentAddSubmitted: any = false;
  isCommentUpdateSubmitted: any = false;
  isThreadUpdateSubmitted: any = false;

  commentDetails: any;
  correspondingEditCommentId: any = '';

  threadCommentList: any = [];
  threadCommentLimitedList: any = [];
  threadCommentViewMoreButtonActive: boolean = false;
  threadCommentCountInPage = 5;

  isThreadEdit: boolean = false;
  threadData: any;
  s3FilePath: any;
  enrollmentId: any;


  async ngOnInit() {
    this.s3FilePath = this.constantService.s3FilePath;
    this.correspondingEditCommentId = '';
    this.isThreadEdit = false;
    this.isCommentAddSubmitted = false;
    this.isCommentUpdateSubmitted = false;
    this.route.params.subscribe((params) => {
      this.threadId = params['threadid'];
      this.enrollmentId = params['enrollment_id'];
    });
    this.session_id = localStorage.getItem('access_token');
    this.userId = localStorage.getItem('user_Id');
    await this.getThreadDetails();
  }

  async getThreadDetails() {
    this.correspondingEditCommentId = '';
    this.isThreadEdit = false;

    var thread_url = 'getThreadById?thread_id=' + this.threadId;

    await this.api.detailsGetFetch(thread_url, this.session_id).subscribe(
      (data: any) => {
        if (data.status == 'true' && data.thread) {
          debugger;
          this.spinner.hide();
          this.isCommentAddSubmitted = false;
          this.isCommentUpdateSubmitted = false;
          this.threadDetails = data.thread;
          debugger
              
              this.threadDetails.utc_created_date.date = moment(this.threadDetails.utc_created_date.date + '+00:00').local().format("DD-MM-YYYY hh:mm A");
              console.log(this.threadDetails.utc_created_date);         
              console.log(this.threadDetails);

          // this.threadDetails.created_date = moment( this.threadDetails.utc_created_date + '+00:00').local().format("MM-DD-YYYY");
          // this.threadDetails.time = moment( this.threadDetails.utc_created_date + '+00:00').local().format("hh:mm A");
          this.threadCommentList = this.threadDetails.comments;
          debugger
            
            for(let item of this.threadCommentList){
              // this.chatDate = item.time + item.date;
              item.utc_created_date.date = moment(item.utc_created_date.date + '+00:00').local().format("DD-MM-YYYY hh:mm A");
              console.log(item.utc_created_date)
            }
            console.log(this.threadCommentList)

          if (this.threadCommentList.length > this.threadCommentCountInPage) {
            this.threadCommentViewMoreButtonActive = true;
            this.threadCommentLimitedList = this.threadCommentList.slice(
              0,
              this.threadCommentCountInPage
            );
          } else {
            this.threadCommentViewMoreButtonActive = false;
            this.threadCommentLimitedList = this.threadCommentList.slice(
              0,
              this.threadCommentList.length
            );
          }
        } else {
          this.spinner.hide();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear();
          this.router.navigate(['']);
        }
      }
    );
  }

  getThreadCommentAddParams() {
    return { thread_id: null, comment: null };
  }

  getThreadCommentEditParams() {
    return { comment_id: null, comment: null };
  }

  getThreadEditParams() {
    return { thread_id: null, course_id: null, title: null, description: null };
  }

  threadCommentAddAction() {
    this.isCommentAddSubmitted = true;
    this.correspondingEditCommentId = '';
    this.isThreadEdit = false;

    if (this.threadCommentAddFormGroup.status == 'VALID') {
      this.spinner.show();
      let param = this.getThreadCommentAddParams();
      param.comment = this.threadCommentAddFormGroup.value.comment;
      param.thread_id = this.threadId;

      let add_thread_comment_url = 'add_threadcomments';
      this.api
        .detailsAddFetch(param, add_thread_comment_url, this.session_id)
        .subscribe(
          (data: any) => {
            debugger;
            if (data.status == 'True' && data) {
              this.spinner.hide();
              this.threadCommentAddFormGroup.controls['comment'].setValue('');
              this.threadCommentAddFormGroup.controls[
                'comment'
              ].updateValueAndValidity();
              this.threadCommentAddFormGroup.reset();
              this.getThreadDetails();
            } else {
              this.spinner.hide();
              this.getThreadDetails();
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              //localStorage.clear()
              //this.router.navigate([''])
            }
          }
        );
    }
  }

  threadCommentEditAction() {
    this.isCommentUpdateSubmitted = true;
    this.isThreadEdit = false;

    if (this.threadCommentEditFormGroup.status == 'VALID') {
      this.spinner.show();
      let param = this.getThreadCommentEditParams();
      param.comment = this.threadCommentEditFormGroup.value.comment;
      param.comment_id = this.threadCommentEditFormGroup.value.comment_id;

      let edit_thread_comment_url = 'update_comment';
      this.api
        .detailsAddFetch(param, edit_thread_comment_url, this.session_id)
        .subscribe(
          (data: any) => {
            if (data.status == 'True' && data) {
              this.spinner.hide();
              this.threadCommentEditFormGroup.controls['comment'].setValue('');
              this.threadCommentEditFormGroup.controls[
                'comment'
              ].updateValueAndValidity();
              this.correspondingEditCommentId = '';
              this.getThreadDetails();
            } else {
              this.correspondingEditCommentId = '';
              this.spinner.hide();
              this.getThreadDetails();
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              //localStorage.clear()
              //this.router.navigate([''])
            }
          }
        );
    }
  }

  async onThreadCommentEdit(id) {
    this.isCommentUpdateSubmitted = false;
    this.isThreadEdit = false;
    var thread_url = 'getComment?comment_id=' + id;
    this.correspondingEditCommentId = id;
    await this.api.detailsGetFetch(thread_url, this.session_id).subscribe(
      (data: any) => {
        if (data.status == 'true' && data.comment) {
          this.spinner.hide();
          this.commentDetails = data.comment;
          this.threadCommentEditFormGroup.controls['comment_id'].setValue(id);
          this.threadCommentEditFormGroup.controls['comment'].setValue(
            this.commentDetails.comment
          );
          this.threadCommentEditFormGroup.updateValueAndValidity();
        } else {
          this.spinner.hide();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear();
          this.router.navigate(['']);
        }
      }
    );
  }

  async onThreadCommentDelete(id) {
    this.isThreadEdit = false;
    this.correspondingEditCommentId = '';

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.correspondingEditCommentId = '';
        var thread_url = 'delete_comment';
        let param: any = { comment_id: id };
        this.api.detailsAddFetch(param, thread_url, this.session_id).subscribe(
          (data: any) => {
            if (data.status == 'true' && data) {
              this.spinner.hide();
              this.getThreadDetails();
            } else {
              this.spinner.hide();
              this.getThreadDetails();
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              //localStorage.clear()
              //this.router.navigate([''])
            }
          }
        );
      }
    });
  }

  threadCommentViewMore() {
    this.isThreadEdit = false;
    this.correspondingEditCommentId = '';

    let count = this.threadCommentLimitedList.length;
    if (count + this.threadCommentCountInPage < this.threadCommentList.length) {
      this.threadCommentLimitedList = this.threadCommentList.slice(
        0,
        count + this.threadCommentCountInPage
      );
    } else {
      this.threadCommentLimitedList = this.threadCommentList.slice(
        0,
        this.threadCommentList.length
      );
    }
    if (this.threadCommentLimitedList.length < this.threadCommentList.length) {
      this.threadCommentViewMoreButtonActive = true;
    } else {
      this.threadCommentViewMoreButtonActive = false;
    }
  }

  async onThreadEdit(id) {
    this.isThreadUpdateSubmitted = false;
    this.correspondingEditCommentId = '';
    var thread_url = 'getThread?thread_id=' + id;
    await this.api.detailsGetFetch(thread_url, this.session_id).subscribe(
      (data: any) => {
        debugger;
        if (data.status == 'true' && data.thread) {
          this.isThreadEdit = true;
          this.spinner.hide();
          this.threadData = data.thread;
          this.threadEditFormGroup.controls['thread_id'].setValue(
            this.threadData.id
          );
          this.threadEditFormGroup.controls['course_id'].setValue(
            this.threadData.course_id
          );
          this.threadEditFormGroup.controls['title'].setValue(
            this.threadData.title
          );
          this.threadEditFormGroup.controls['description'].setValue(
            this.threadData.description
          );
          this.threadEditFormGroup.updateValueAndValidity();
        } else {
          this.spinner.hide();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear();
          this.router.navigate(['']);
        }
      }
    );
  }

  onThreadDelete(id) {
    this.isThreadEdit = false;
    this.correspondingEditCommentId = '';

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        var thread_url = 'delete_thread';
        let param: any = { thread_id: id };
        this.api.detailsAddFetch(param, thread_url, this.session_id).subscribe(
          (data: any) => {
            if (data.status == 'true' && data) {
              this.spinner.hide();
              this.ToasterService.openSnackBar(data.message, '', 'success');
              this.router.navigateByUrl('/mycourses/overview/discuss_forum/' + this.enrollmentId);
            } else {
              this.spinner.hide();
              this.ToasterService.openSnackBar(data.message, '', 'failed');
              this.router.navigateByUrl('/mycourses/overview/discuss_forum/' + this.enrollmentId);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              //localStorage.clear()
              //this.router.navigate([''])
            }
          }
        );
      }
    });
  }

  threadEditAction() {
    this.isThreadUpdateSubmitted = true;
    this.isCommentUpdateSubmitted = true;
    this.correspondingEditCommentId = '';

    if (this.threadEditFormGroup.status == 'VALID') {
      this.spinner.show();
      let param = this.getThreadEditParams();
      param.course_id = this.threadEditFormGroup.value.course_id;
      param.thread_id = this.threadEditFormGroup.value.thread_id;
      param.description =this.threadEditFormGroup.value.description;
      param.title =this.threadEditFormGroup.value.title;

      let edit_thread_url = 'update_thread';
      this.api
        .detailsAddFetch(param, edit_thread_url, this.session_id)
        .subscribe(
          (data: any) => {
            if (data.status == 'True' && data) {
              this.spinner.hide();
              this.threadCommentEditFormGroup.controls['comment'].setValue('');
              this.threadCommentEditFormGroup.controls[
                'comment'
              ].updateValueAndValidity();
              this.getThreadDetails();
            } else {
              this.spinner.hide();
              this.getThreadDetails();
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              //localStorage.clear()
              //this.router.navigate([''])
            }
          }
        );
    }
  }
}
