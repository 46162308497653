<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li *ngIf="!isEdit" class="breadcrumb-item active">Add Profile</li>
            <li *ngIf="isEdit" class="breadcrumb-item active">Edit Profile</li>

          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="card elevation-0">
      <div class="card-header">
        <h1 *ngIf="!isEdit">Add Profile</h1>
        <h1 *ngIf="isEdit">Edit Profile</h1>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary p-3 pb-5 add_contry_">
              <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                <div class="col-md-6 float-left">
                  <div class="form-group float-left" *ngIf="isSme" style="width: 30%;">
                    <label>Prefix *</label>
                    <select class="form-control" formControlName="namePrefixes"
                      [ngClass]="{ 'is-invalid': submitted && f.namePrefixes.errors }">
                      <option [value]="">--Select Prefix--</option>
                      <option [ngValue]="option.title" *ngFor="let option of namePrefix">{{option.title}}</option>
                    </select>
                    <div *ngIf="submitted && f.namePrefixes.errors" class="invalid-feedback">

                      Prefix is
                      required

                    </div>
                  </div>

                  <div class="form-group float-left pl-2"  *ngIf="isSme" style="width: 70%;">
                    <label>First Name *</label>
                    <input type="text" formControlName="firstName" class="form-control selact_"
                           placeholder="First Name"
                           [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                      Enter A Valid First Name
                      <!-- <div *ngIf="f.firstName.errors.required">
                        First Name is
                        required
                      </div> -->
                    </div>
                  </div>
                  <div class="form-group"  *ngIf="!isSme" >
                    <label>First Name *</label>
                    <input type="text" formControlName="firstName" class="form-control selact_"
                           placeholder="First Name"
                           [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                      Enter A Valid First Name
                      <!-- <div *ngIf="f.firstName.errors.required">
                        First Name is
                        required
                      </div> -->
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Last Name *</label>
                    <input type="text" formControlName="lastName" class="form-control selact_"
                           placeholder="Last Name"
                           [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                      Enter A Valid Last Name
                      <!-- <div *ngIf="f.lastName.errors.required">
                        Last Name is
                        required
                      </div> -->
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Email Address *</label>
                    <input type="text" formControlName="email" class="form-control selact_"
                           placeholder="Email Address"
                           [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">
                        Email Address is
                        required
                      </div>
                      <div *ngIf="f.email.errors.email">
                        Email must be a valid email address
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isSme" class="form-group">
                    <label>Phone Number *</label>
                    <input type="text" formControlName="phone_number" class="form-control selact_"
                           placeholder="Phone Number"
                           [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }">
                    <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                      <div *ngIf="f.phone_number.errors.required">
                        Phone Number is
                        required
                      </div>
                      <div
                        *ngIf="!profileForm.get('phone_number').errors.invalidNumber && (profileForm.get('phone_number').minLength || !profileForm.get('phone_number').maxLength)"
                        class="text-danger">Phone number should be between 8-12 numbers</div>

                      <div
                        *ngIf="!profileForm.get('phone_number').errors?.required && profileForm.get('phone_number').errors.invalidNumber"
                        class="text-danger">Invalid
                        Phone Number</div>
                    </div>
                  </div>
                  <div *ngIf="isSme" class="form-group">
                    <label>Description *</label>
                    <textarea class="form-control selact_" formControlName="description"
                              placeholder="Description"
                              [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                      <div *ngIf="f.description.errors.required">
                        Description is
                        required
                      </div>
                      <div *ngIf="f.description.errors.validspace">
                        Invalid Description
                      </div>
                    </div>
                  </div>
                  <!-- <div *ngIf="isSme" class="form-group">
                    <label>Degree</label>
                    <textarea class="form-control selact_" formControlName="degree"
                              placeholder="Degree"
                              [ngClass]="{ 'is-invalid': submitted && f.degree.errors }"></textarea>
                    <div *ngIf="submitted && f.degree.errors" class="invalid-feedback">
                      <div *ngIf="f.degree.errors.required">
                        Degree is
                        required
                      </div>
                    </div>
                  </div> -->
                </div>
               
                <!-- <div class="clearfix"></div> -->
                <div class="col-md-4 float-left">
                  <div class="form-group course_img">
                    <label>Profile Image</label>
                    <div class="uploadedImg course_image_">
                      <input type="file" class="mediaFile" formControlName="fileData" accept="image/*"
                             id="exampleInputFile" (change)="onFileChanged($event)"
                             [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }">
                      <span class="up_img">Upload image</span>

                      <img [src]="imageSrc" *ngIf="imageSrc">
                    </div>
                    <div *ngIf="submitted && f.fileData.errors" class="text-danger">
                      <small *ngIf="f.fileData.errors.required">
                        Profile Image is
                        required
                      </small>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="p-3">
                  <button type="submit"
                          class="btn btn-secondary btn-dark bor-rad_ float-right">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>
