<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
            <li class="breadcrumb-item active"><a>Page Managements</a></li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- Main content -->
  <!-- /.content -->
  <section class="content">
    <div class="card">
      <div class="card-header">
        <h1>Page Managements</h1>
      </div>
      <!-- /.card-header -->
      <!-- <div class="card-body">
        
        <span>
          <label>Search:</label>
          <input type="text" class="form-group" placeholder="Type to filter"
                 (keyup)="updateFilter($event)" />
        </span>
        <span class="col-md-2 col-sm-4 float-left pl-0">
          <button class="btn btn-dark ml-2 float-left" type="submit"> <i class="fas fa-search mr-1"></i> Search </button>
        </span>
        <a [routerLink]="['/admin/add-page']" class=" btn btn-secondary btn-dark float-right add_country">
          <i class="fas fa-plus mr-2"></i>Add
          Page
        </a>

      </div> -->

      <form [formGroup]="pageForm" (ngSubmit)="onSearch()">

        <div class="card-body pb-0">
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <input type="text" formControlName="search" class="form-control selact_"
                   placeholder="Search text">
          </span>
          <span class="col-md-2 col-sm-4 float-left pl-0">
            <button class="btn btn-dark ml-2 float-left" type="submit"> <i class="fas fa-search mr-1"></i> Search </button>
          </span>
          <!-- <a [routerLink]="['/admin/add-page']" class="btn btn-secondary btn-dark float-right add_country">
            <i class="fas fa-plus mr-2"></i>Add Page
          </a> -->
        </div>
      </form>

      <div class="card-body">

        <div>

          <ngx-datatable #myTable class="material striped" [rows]="pageList" [columnMode]="'force'"
                         [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10"
                         [sorts]="[{prop: 'name', dir: 'desc'}]" [selected]="selected" [selectionType]="'checkbox'"
                         (select)="onSelect($event)" (page)="setPagetest($event)">
            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                  [draggable]="false" [resizeable]="false">
              <!-- <ng-template ngx-datatable-header-template let-value="value"
                           let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                <input type="checkbox" [checked]="allRowsSelected"
                       (change)="selectFn(!allRowsSelected)" />
              </ng-template> -->
              <!-- <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                           let-onCheckboxChangeFn="onCheckboxChangeFn">
                <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
              </ng-template> -->
            </ngx-datatable-column>
            <ngx-datatable-column name="Sl No" [sortable]="false">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="200" name="Title" prop="title">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.title}}
              </ng-template>
            </ngx-datatable-column>
             <ngx-datatable-column [width]="200" name="Meta Keyword" prop="meta_keyword">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.meta_keyword}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="200" name="Meta Description" prop="meta_description">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.meta_description}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status_id">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.status}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Action" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                 <a href="Javascript:void(0);" (click)="editPage(row.id)" class="edit_button mr-2">
                  <i class="far fa-edit"></i>
                </a> 
                <!-- <a href="Javascript:void(0);" (click)="deletePage(row.id)"
                        class="close_button"><i class="far fa-times-circle"></i></a> -->
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
      <!-- <pagination-controls style="text-align:center;" (pageChange)="p = $event">
      </pagination-controls> -->
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </section>
</div>
