import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { QuestionSubComponent } from './question-sub.component';
import { QuestionSubRoutingModule } from './question-sub-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [QuestionSubComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    QuestionSubRoutingModule,
    NgxDatatableModule,
    NgxPaginationModule
   ]
})
export class QuestionSubModule { }
