import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component'
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WebComponent } from './web/web.component';
import { WebloginComponent } from './web/weblogin/weblogin.component';
import { RegisterationComponent } from './web/registeration/registeration.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';

const routes: Routes = [
  // {
  //   path: '', redirectTo: '/home', pathMatch: 'full'
  // },
  {
    path: 'admin/login',
    component: LoginComponent,
    data: {
      title: 'Login '
    }  
  },
  
  {
    path: 'admin/login/forgotpassword', component: ForgotpasswordComponent, data: { title: 'Admin Forgot Password ' }

  },
  {
    path: 'admin/login/resetpassword/:token', component: ResetpasswordComponent, data: { title: 'Reset Password ' }

  },
  {
    path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard ' },
    children: [
      {
        path: 'admin', loadChildren: './dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard], data: { title: 'Admin' }
      }
    ]

  },


  {
    path: '', component: WebComponent,  data: { title: ' Home ' },
    children: [
      {
        path: '', loadChildren: './web/web.module#WebModule',
      },
    ]

  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
