import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocaldataService } from 'src/app/services/localdata.service';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.css']
})
export class CoursesListComponent implements OnInit {

  constructor(public api: ApiService, private spinner: NgxSpinnerService, private LocaldataService: LocaldataService, private router: Router, private rote: ActivatedRoute, private breadcrumbService: BreadcrumbService, private constantService: ConstantsService) { }

  courseList: any = [];
  selectedCourses: any[];
  subscribeLocaldataServicecurrencyChange: any;
  subscribeLocaldataServiceCourseSearch: any;
  currencyObj: any;
  formCourseSelection = new FormControl('');
  initialselectedPage: number = 1;
  totalPages: number = 1;
  startIndex: number = 1;
  endIndex: number = 3;
  selectedpage: number = 1;
  numberpages = []
  previousShow = true;
  nextShow = false;
  courses = [];
  searchitem: string = "";
  isSearch: boolean = false;
  title: string = "Our Courses";
  currencyId: number = null;
  categoryId: any = null;
  coursesInfo: any;
  categorySlug: any;
  s3FilePath: string;



  getCourceParam() {
    return { currency_id: null, per_page_count: null, page: null, category_id: null, category_slug: null }
  }
  getCourceList() {
    return { id: null, title: null, description: null }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.s3FilePath = this.constantService.s3FilePath;
    this.subscribeLocaldataServiceCourseSearch = this.LocaldataService.CourseSearch.subscribe(selectedData => {
      this.searchitem = selectedData;
      this.headdingseting();
    });

    this.subscribeLocaldataServicecurrencyChange = this.LocaldataService.CurrencyChange.subscribe(selectedData => {
      debugger
      this.currencychange(selectedData)
    });
    this.LocaldataService.updateUser();
    //this.generatePageNumber(this.initialselectedPage, this.endIndex);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.searchitem && !this.categoryId) {
        if (this.LocaldataService.getcurrencyInfo()) {
          let currency = this.LocaldataService.getcurrencyInfo();
          this.currencyId = currency.id
        }
        this.headdingseting()
      } else {
        this.headdingseting()
      }
    }, 5);
  }

  ngOnDestroy() {
    // You can also do whatever you need here
    this.subscribeLocaldataServicecurrencyChange.unsubscribe();
    this.subscribeLocaldataServiceCourseSearch.unsubscribe();
  }
  headdingseting() {
    debugger
    this.searchitem = this.rote.snapshot.paramMap.get("search_data");
    this.categoryId = this.rote.snapshot.paramMap.get("category_slug");
    this.categorySlug = this.rote.snapshot.paramMap.get("category_slug");
    let currency = this.LocaldataService.getcurrencyInfo();
    this.currencyId = currency.id
    debugger
    if (this.searchitem) {
      this.title = "showing search results for " + `"` + this.searchitem + `"`;
      this.getCourses();
    } else if (this.categoryId) {
      let filtervalu = null;
      if (this.LocaldataService.getHomeInfo()) {
        let homeInfo = this.LocaldataService.getHomeInfo();
        this.coursesInfo = homeInfo.courses
        filtervalu = this.coursesInfo.filter(stateOption => stateOption.slug == this.categoryId);
      }

      if (filtervalu[0] && filtervalu[0].category_name) {
        this.title = filtervalu[0].category_name;
        this.breadcrumbService.set('@categoryname', this.title);
      }

      this.getCourses();
    }
    else {
      this.title = "Our Courses";
      this.getCourses();
    }
  }
  currencychange(selectedcurrency) {
    this.currencyId = selectedcurrency.id
    this.getCourses()
  }
  courseSearch(selectedDataData) {
    this.searchitem = selectedDataData
    this.getCourses();
  }


  getCourseList() {
    this.spinner.show();
    var list_fetch_url = "student/categories";
    this.api.detailsGet(list_fetch_url).subscribe((data: any) => {
      if (data.status == "true" && data.course_categories) {
        data.course_categories.forEach(element => {
          let courseLis = this.getCourceList();
          courseLis.id = element.id;
          courseLis.title = element.title;
          courseLis.description = element.description;
          this.courseList.push(courseLis)
          element.sub_categories.forEach(element1 => {
            let courseLis = this.getCourceList();
            courseLis.id = element1.id;
            courseLis.title = element1.title;
            courseLis.description = element1.description;
            this.courseList.push(courseLis)
          });
        });
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });

  }

  courseSelectionChanged() {
    this.selectedCourses = this.formCourseSelection.value;
  }
  deleteSelectedCourse(deletedCourse) {
    const index = this.selectedCourses.indexOf(deletedCourse);
    this.selectedCourses.splice(index, 1);
    this.formCourseSelection.setValue([]);
    this.formCourseSelection.setValue(this.selectedCourses);
  }

  previousPageSelect() {
    if (this.selectedpage > 1) {
      setTimeout(() => {
        if (document.getElementById("pages" + this.selectedpage)) {
          if (this.selectedpage <= this.totalPages) {
            this.nextShow = true;
            document.getElementById("pages" + this.selectedpage).classList.remove("selected_page");
          } else {
            this.nextShow = false;
          }
          this.selectedpage = this.selectedpage - 1;
          if (this.selectedpage < this.startIndex) {
            this.startIndex = this.startIndex - this.endIndex
            this.generatePageNumber(this.startIndex, this.endIndex);
          }
          setTimeout(() => {
            document.getElementById("pages" + this.selectedpage).className = "selected_page";
          }, 7);
        }
        this.getCourses();
      }, 2);
    }
  }
  nextPageSelect() {
    if (this.selectedpage < this.totalPages) {
      setTimeout(() => {
        if (document.getElementById("pages" + this.selectedpage)) {
          if (this.selectedpage >= this.totalPages) {
            this.nextShow = true;
          } else {
            this.nextShow = false;
            document.getElementById("pages" + this.selectedpage).classList.remove("selected_page");
          }
          this.selectedpage = this.selectedpage + 1;
          if (this.selectedpage >= this.startIndex + this.endIndex) {
            this.startIndex = this.startIndex + this.endIndex
            this.generatePageNumber(this.startIndex, this.endIndex);
          }
          setTimeout(() => {
            document.getElementById("pages" + this.selectedpage).className = "selected_page";
          }, 7);
        }
        this.getCourses();
      }, 2);
    }
  }
  pageselect(selectedpage) {
    if (document.getElementById("pages" + this.selectedpage)) {
      document.getElementById("pages" + this.selectedpage).classList.remove("selected_page");
    }
    this.selectedpage = selectedpage;
    setTimeout(() => {
      if (document.getElementById("pages" + this.selectedpage)) {
        document.getElementById("pages" + this.selectedpage).className = "selected_page";
      }
    }, 5);
    this.getCourses();
  }
  generatePageNumber(startIndex, endIndex) {
    this.numberpages = [];
    if (startIndex + endIndex > this.totalPages) {
      endIndex = this.totalPages - startIndex + 1;
    }
    for (let i = startIndex; i < startIndex + endIndex; i++) {
      this.numberpages.push(i);
    }
    setTimeout(() => {
      if (document.getElementById("pages" + this.selectedpage)) {
        document.getElementById("pages" + this.selectedpage).className = "selected_page";
      }
    }, 5);
  }

  getCourses() {
    debugger
    this.spinner.show();
    var list_fetch_url = "student/course_list?";
    if (this.searchitem) {
      list_fetch_url = "student/course_list?searchTerm=" + this.searchitem;
    }
    let searchTerm = this.getCourceParam();
    this.currencyObj = this.LocaldataService.getcurrencyInfo();
    searchTerm.currency_id = this.currencyId;
    searchTerm.per_page_count = 6
    searchTerm.page = this.selectedpage;
    searchTerm.category_slug = this.categorySlug;
    // searchTerm.category_id = this.categoryId;
    this.api.postDetails(list_fetch_url, searchTerm).subscribe((data: any) => {
      if (data.status == "true" && data) {
        debugger
        if (data.current_category != undefined) {
          this.title = data.current_category.category;
          this.breadcrumbService.set('@categoryname', this.title);

        }
        debugger
        this.courses = data.courses;
        this.totalPages = data.pagination.total_pages;
        this.initialselectedPage = data.pagination.current_page;
        this.startIndex = 1;
        if (data.pagination.total > this.endIndex) {

          // this.startIndex = data.pagination.total - this.endIndex;
        }
        this.generatePageNumber(this.startIndex, data.pagination.total)
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        //localStorage.clear()
        //this.router.navigate([''])
      }
    });
  }

  courseSelect(course) {
    // let course_selecting_url = 'course/' + course.slug;
    // // this.router.navigate(['course/' + course.slug])
    // window.open(course_selecting_url, '_blank')
    this.router.navigate(['course/' + course.slug])

  }
  smeSelect(course) {
    this.router.navigate(['instructor/' + 107])
  }

}