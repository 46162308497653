<section class="bread_sec shadow-sm">
    <div class="container">
      <div class="row">
      <div class="col-md-12">
        <!--<ol class="breadcrumb mb-0 pl-0">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page"><a href="#">Log in</a></li>
          <li class="breadcrumb-item active" aria-current="page">Forgot Password</li>
        </ol>-->
        </div>
      </div>
    </div>
  </section>
  <section class="sub_page_content team_content">
    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card card-signin my-5 border-0 shadow-sm">
            <div class="card-body">
              <h2 class="card-title text-center">Reset your password</h2>
              <form class="form-reset-pas" [formGroup]="webResetPasswordForm" (ngSubmit)="webResetPassword()">
                <div class="form-label-group mb-2">
                  <input type="password" id="password-new" class="form-control" placeholder="Enter a new password" formControlName="newPassword">
                </div>
                <div *ngIf="isSubmit && webResetPasswordForm.controls.newPassword.errors" class="error_signup"> 
                  <div *ngIf="isSubmit && webResetPasswordForm.controls.newPassword.errors.required"> New Password is required. </div></div>
                <div class="form-label-group mb-2">
                  <input type="password" id="re-password-new" class="form-control" placeholder="Confirm your new password" formControlName="confirmPassword">
                </div>
                <div *ngIf="isSubmit && webResetPasswordForm.controls.confirmPassword.errors" class="error_signup"> 
                  <div *ngIf="isSubmit && webResetPasswordForm.controls.confirmPassword.errors.required"> Confirm Password is required. </div></div>
                  <div class="form-label-group mb-2">
                <button class="btn btn-dark btn-sm btn-block text-uppercase" type="submit">Reset Password</button>
                </div>
                <!-- <div class="alert alert-danger text-center" role="alert" *ngIf="isSubmit && webResetPasswordForm.controls.confirmPassword.errors.mustMatch"> Passwords not match</div> -->
              <!-- <div class="alert alert-success text-center" role="alert"> Your password successfully updated. </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>