import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CourseCategoryComponent } from './course-category.component';


const routes: Routes = [

  {
    path: '', component: CourseCategoryComponent,
    data:{title:'Course Categories | Admin '}
},


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CourseCategoryRoutingModule { }
