import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocaldataService {

  constructor() { }

  currencyObj: any;
  homeInfoObj: any;

  private userUpdate = new Subject<any>();
  updateIsPaymentScreen_ = new Subject<any>();
  triggerAssessmentFunction_ = new Subject<any>();
  triggerEligibilityPopUpClose = new Subject<any>();

  @Output() CurrencyChange: EventEmitter<any> = new EventEmitter();
  @Output() AfterHomeDataLoad: EventEmitter<any> = new EventEmitter();
  @Output() CourseSearch: EventEmitter<any> = new EventEmitter();

  currencyChange(currencyObj) {
    this.setcurrencyInfo(currencyObj);
    this.CurrencyChange.emit(currencyObj);
  }
  afterHomeDataLoad(homeInfoObj) {
    this.setHomeInfo(homeInfoObj);
    this.AfterHomeDataLoad.emit(homeInfoObj);
  }
  courseSearch(searchValue) {
    this.CourseSearch.emit(searchValue);
  }


  setcurrencyInfo(currencyObj) {
    this.currencyObj = currencyObj;
  }
  getcurrencyInfo() {
    return this.currencyObj;
  }

  setHomeInfo(homeInfoObj) {
    this.homeInfoObj = homeInfoObj;
  }
  getHomeInfo() {
    return this.homeInfoObj;
  }

  updateUser() {
    this.userUpdate.next();
  }
  getUpdatedUser(): Observable<any> {
    return this.userUpdate.asObservable();
  }

  getIsPaymentScreen() {
    return this.updateIsPaymentScreen_.asObservable();
  }

  updateIsPaymentScreen() {
    this.updateIsPaymentScreen_.next();
  }

  getAssessmentFunction() {
    return this.triggerAssessmentFunction_.asObservable();
  }

  triggerAssessmentFunction() {
    this.triggerAssessmentFunction_.next();
  }

  elegibiltyTestPopUpCloseUpdate() {
    return this.triggerEligibilityPopUpClose.asObservable();
  }

  elegibiltyTestPopUpCloseGet() {
    this.triggerEligibilityPopUpClose.next();
  }

}
