import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-student-reset-password',
  templateUrl: './student-reset-password.component.html',
  styleUrls: ['./student-reset-password.component.css']
})
export class StudentResetPasswordComponent implements OnInit {
  studentResetPasswordForm: FormGroup;
  issubmitted: boolean = false;
  session_id: any;
  studentId: any;
  studentName: string;


  constructor(private fb: FormBuilder,public api: ApiService, private router: Router, private route: ActivatedRoute, private popup: PopupService, private spinner: NgxSpinnerService,) { 
    this.studentResetPasswordForm = fb.group({
      Password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20),]],
      confirmPassword: ['', [Validators.required]],
    },
      { validator: MustMatch('Password', 'confirmPassword') }
    );
  }

  ngOnInit(): void {
    this.spinner.show();
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.route.params.subscribe(params => {
      console.log(params)
      if (params.id) {
        debugger
        this.studentId = params.id;
        this.studentName = params.name;
        this.spinner.hide();
        
      }
      else {
        this.spinner.hide();

      }
    }
    );
  }

  onSubmit(){
    debugger
    this.issubmitted = true;
    if (this.studentResetPasswordForm.invalid) {
      return;
    }
    
    this.spinner.show();
    const uploadData = new FormData();
    debugger
    uploadData.append('password', this.studentResetPasswordForm.value.confirmPassword);
    uploadData.append('student_id', this.studentId);
    let fetch_url = "reset_student_password_by_admin";
    this.api.detailsUploadFetch(uploadData, fetch_url, this.session_id).subscribe((data: any) => {
      debugger
      console.log(data)
      if (data.status == "true") {
        this.spinner.hide();
        this.popup.sucessMessage = data.message
        this.popup.sucesspopup();
        this.router.navigate(['admin/student'])
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup()

      }
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.spinner.hide();

          localStorage.clear()
          this.router.navigate(['admin/login'])
        }
      });
  }

}
