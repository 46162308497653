import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonialComponent } from './testimonial.component';
import { TestimonialRoutingModule } from './testimonial-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [TestimonialComponent],
  imports: [
    CommonModule,
    TestimonialRoutingModule,
    FormsModule, ReactiveFormsModule,NgxPaginationModule,NgxDatatableModule
   ]
})
export class TestimonialModule { }
