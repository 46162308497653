import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { PopupService } from 'src/app/services/popup.service';
import { UploadService } from 'src/app/services/upload.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { param } from 'jquery';
import { ConstantsService } from 'src/app/services/constants.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReferalcodePopupComponent } from '../referalcode-popup/referalcode-popup.component';
import { WebComponent } from '../web.component';
import { Validatespace } from "src/app/validation/spacevalidation";
import { phoneNumberValidator } from "../../validation/phone-validator";
import Swal from 'sweetalert2';

const PROFILE_PIC_PATH: string = 'aljamia-media-files/profileImage';
const IDPROOF_PATH: string = 'aljamia-media-files/idproof';
@Component({
  selector: 'app-registeration',
  templateUrl: './registeration.component.html',
  styleUrls: ['./registeration.component.css']
})
export class RegisterationComponent implements OnInit {

  constructor(private fb: FormBuilder, public api: ApiService, private spinner: NgxSpinnerService, private LocaldataService: LocaldataService, private router: Router, private PopupService: PopupService, private uploadService: UploadService, private ToasterService: ToasterService, private rote: ActivatedRoute, private constantService: ConstantsService, public dialog: MatDialog, private WebComponent: WebComponent) {

    this.signUpformGroup = fb.group({
      userName: ['', [Validators.required, Validators.maxLength(60), Validators.pattern('[a-zA-Z ]*'), Validatespace]],
      phoneNumber: ['',
        [
          Validators.required,
          Validatespace,
          phoneNumberValidator,
          Validators.minLength(8),
          Validators.maxLength(12),
        ]],
      emailAddress: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(80), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
      dateOfBirth: ['', [Validators.required, Validators.maxLength(10)]],
      photo: [''],
      identityProof: [''],
      qualification: [''],
      // stream: ['', [Validators.required]],
      // otherQualification: ['', [Validators.maxLength(60), Validatespace]],
      // score: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]?$|^100$/)]],
      address: ['', [Validators.required, Validators.maxLength(250), Validatespace]],
      country: ['', [Validators.required]],
      // state: [''],
      // district: [''],
      Password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20),]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(20)]],
      terms_and_condition: [''],
      whatsAppNumber: [''],
      nationality: [0]
    },
      { validator: MustMatch('Password', 'confirmPassword') }
    );
  }

  signUpformGroup: FormGroup;
  registrationDropdowns: any;
  qualifications: any
  streams: any;
  countries: any;
  states: any;
  districts: any;
  filterstreams: any;
  filterstates: any;
  filterdistricts: any;
  issubmitted: boolean = false;
  selectedFile: any;
  imageSrc: any;
  idImageSrc: any;
  ImageFileName: any;
  idProofFileName: any;
  userId: any;
  session_id: any;
  isedit: boolean = false;
  headingText: string = 'Sign Up';
  submitButtonText: string = 'SIGN UP';
  s3FilePath: string;
  idProofPath: any = "";
  imagePath: any = "";
  user_Id: any;
  user_Name: any;
  access_Token: any;
  refresh_Token: any;
  passwordHide: boolean = true;
  confirmPasswordHide: boolean = true;
  todayDate: any;
  profile_pic: any;
  refferal_Code: any;
  isOtherQualification: boolean = false;

  getRegistrationParam() {
    return { name: null, country_code: null, phone_number: null, email: null, date_of_birth: null, photo: null, identity_proof: null, qualification: null, address: null, country_id: null, password: null, password_confirmation: null, refferal_code: null,
    whatsapp_number: null, nationality: null }
  }

  async ngOnInit() {
    this.spinner.show();
    debugger
    this.s3FilePath = this.constantService.s3FilePath;
    this.getregistrationDropdowns();
    this.todayDate = await this.convert();
    this.rote.params.subscribe(params => {
      debugger
      this.refferal_Code = params.refferalCode;
    })

  }

  getregistrationDropdowns() {
    var list_fetch_url = "student/fillregistrationDropdowns";
    this.api.detailsGet(list_fetch_url).subscribe((data: any) => {
      if (data.status == "true" && data) {
        debugger
        this.registrationDropdowns = data.data;
        this.qualifications = this.registrationDropdowns.qualifications;
        var others = {id: "0", title: "Others"};
        this.qualifications.push(others);
        this.streams = this.registrationDropdowns.streams;
        this.countries = this.registrationDropdowns.countries;
        this.states = this.registrationDropdowns.states;
        this.districts = this.registrationDropdowns.districts;
        this.signUpformGroup.patchValue({
          // qualification: 0,
          // stream: 0,
          country: 0,
          state: 0,
          district: 0,
          
        })
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });
  }

  qualificationsChange() {
    debugger
    if(this.signUpformGroup.value.qualification == "0"){
      this.isOtherQualification = true;
      this.signUpformGroup.controls['otherQualification'].setValidators([Validators.required,Validators.maxLength(60), Validatespace]);
      this.signUpformGroup.get('stream').setErrors(null);
      
    }else{
      this.isOtherQualification = false;
      this.signUpformGroup.get("otherQualification").setErrors(null);
      this.signUpformGroup.get('stream').setErrors({ required: true });
    this.filterstreams = this.streams.filter(option => option.qualification_id == this.signUpformGroup.value.qualification);
    // this.signUpformGroup.patchValue({
    //   stream: 0,
    // })
    this.signUpformGroup.get('stream').setValue('');
  }

  }
  streamChange() {
    debugger

  }
  countryChange() {
    debugger
    this.filterstates = this.states.filter(option => option.country_id == this.signUpformGroup.value.country.id);
    this.signUpformGroup.patchValue({
      state: 0,
      district: 0
    })
    this.filterdistricts = null;

  }
  stateChange() {
    debugger
    this.filterdistricts = this.districts.filter(option => option.state_id == this.signUpformGroup.value.state.id);
    this.signUpformGroup.patchValue({
      district: 0
    })

  }
  districtChange() {

  }

  signUp() {
    debugger

    this.issubmitted = true;
    if (new Date(this.signUpformGroup.value.dateOfBirth) > new Date((this.todayDate))) {
      this.signUpformGroup
        .get("dateOfBirth")
        .setErrors({ dateError: true });

    }

    if (!this.signUpformGroup.value.terms_and_condition) {
      return;
    }
    

   
    // if(this.isOtherQualification==false && this.signUpformGroup.value.stream == "0")
    // {
    //   return;
    // }
    
    if (this.signUpformGroup.status == "VALID") {
      debugger



      let param = this.getRegistrationParam();
      param.name = this.signUpformGroup.value.userName;
      // param.country_code = 91;
      param.phone_number = this.signUpformGroup.value.phoneNumber;
      param.whatsapp_number = this.signUpformGroup.value.whatsAppNumber;
      param.email = this.signUpformGroup.value.emailAddress;
      param.date_of_birth = this.signUpformGroup.value.dateOfBirth;
      param.photo = this.ImageFileName;
      // param.photo = this.signUpformGroup.value.photo;
      // param.photo = "test.jpg";
      param.identity_proof = this.idProofFileName;
      // param.identity_proof = this.signUpformGroup.value.identityProof;
      // param.identity_proof = "test1.jpg";
      // if(this.signUpformGroup.value.qualification == "0"){
      //   param.qualification_id = null;
      //   param.stream_id = null;
      //   param.other_qualification_stream = this.signUpformGroup.value.otherQualification;
      // }else{
      //   param.qualification_id = this.signUpformGroup.value.qualification; 
      //   param.stream_id = this.signUpformGroup.value.stream;
      //   param.other_qualification_stream = null;
      // }
      // param.score = this.signUpformGroup.value.score;
      param.qualification = this.signUpformGroup.value.qualification; 
      param.address = this.signUpformGroup.value.address;
      param.nationality = this.signUpformGroup.value.nationality.id;
      param.country_id = this.signUpformGroup.value.country.id;
      // param.state_id = this.signUpformGroup.value.state.id;
      // param.district_id = this.signUpformGroup.value.district.id;
      param.password = this.signUpformGroup.value.Password;
      param.password_confirmation = this.signUpformGroup.value.confirmPassword;
      this.saveData(param);




    }
  }

  saveData(param) {
    debugger
    this.spinner.show();
    debugger
    let list_fetch_url = 'student/student_registration'
    this.api.postDetails(list_fetch_url, param).subscribe((data: any) => {
      debugger
      if (data.status == "TRUE" && data) {
        debugger
        this.user_Id = data.user_details[0].id;
        this.user_Name = data.user_details[0].first_name;
        this.access_Token = data.access_token;
        this.refresh_Token = data.refresh_token
        this.profile_pic = data.user_details[0].profile_pic
        this.spinner.hide();
        if (!this.refferal_Code) {
          debugger
          this.addRefferalCode();
        } else {
          this.spinner.show();
          let fetch_url = 'student/addReferralcode';
          var body = { "user_id": this.user_Id, "referral_code": this.refferal_Code }
          this.api.postDetails(fetch_url, body).subscribe((data: any) => {
            debugger
            if (data.status == "True" && data) {
              debugger
              this.spinner.hide();
              debugger
              localStorage.setItem('user_Id', this.user_Id);
              localStorage.setItem('user_name', this.user_Name);
              localStorage.setItem('access_token', this.access_Token);
              localStorage.setItem('refresh_token', this.refresh_Token);
              this.ToasterService.openSnackBar('Refferal Code added and Registered Succefully', '', 'success');
              this.router.navigate(['']);
            }
            else {
              this.spinner.hide();

              Swal.fire({
                title: data.message,
                text: "Enter valid refferal code",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.value) {
                  this.addRefferalCode();
                  
                }
              })


              // this.ToasterService.openSnackBar(data.message, '', 'warning');
              // this.addRefferalCode();
              // localStorage.setItem('user_Id', this.user_Id);
              // localStorage.setItem('user_name', this.user_Name);
              // localStorage.setItem('access_token', this.access_Token);
              // localStorage.setItem('refresh_token', this.refresh_Token);
              // localStorage.setItem('profilepic',this.profile_pic);
              // this.WebComponent.islogin = true;
              // this.ToasterService.openSnackBar('Registration Succefull', '', 'success');
              // this.router.navigate(['']);
            }
          }, (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();
              this.router.navigate([''])
            }
          });
          // this.ToasterService.openSnackBar(data.message, '', 'success');
          // this.router.navigate([''])
        }



      }
      else {
        this.spinner.hide();
        // this.PopupService.failureMessage = data.message[0]
        // this.PopupService.failurepopup()
        this.ToasterService.openSnackBar(data.message[0], '', 'warning');
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        //localStorage.clear()
        this.router.navigate([''])
      }
    });
  }

  logIn() {
    this.router.navigate(['login'])
  }
  onImageFileChange(event) {
    this.imageFileUpload(event, PROFILE_PIC_PATH);
  }
  onIdProofFileChange(event) {
    debugger
    this.idProofFileUpload(event, IDPROOF_PATH)
  }

  imageFileUpload(event, folderPath) {
    debugger
    var reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size < 3000000) {
        this.selectedFile = event.target.files[0];
        this.imagePath = this.selectedFile.type;
        var ext = this.imagePath.split('/');
        if (ext[1] == "jpeg" || ext[1] == "png"|| ext[1] == "jpg") {
          reader.onload = () => {
            this.imageSrc = reader.result as string;

          }
          reader.readAsDataURL(this.selectedFile);
          if (folderPath === PROFILE_PIC_PATH) {
            if (this.selectedFile != null) {

              this.ImageFileName = this.uploadService.fileUpload(this.selectedFile, folderPath).then(res => {
                if (res) {
                  this.ImageFileName = res;
                }
              });
            }
          }
        } else {
          this.ToasterService.openSnackBar('Unsupported file format', '', 'warning');
          this.signUpformGroup.get('photo').setValue('');
        // this.signUpformGroup.get('photo').setErrors({ required: true });
        this.imageSrc = "";

        }
      }
      else {
        this.ToasterService.openSnackBar('Size limit exceeded', '', 'warning');
        this.signUpformGroup.get('photo').setValue('');
        // this.signUpformGroup.get('photo').setErrors({ required: true });
        this.imageSrc = "";
      }
    }
  }
  idProofFileUpload(event, folderPath) {
    debugger
    var reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.selectedFile = event.target.files[0];
      // reader.onload = () => {
      //   this.idProofSrc = reader.result as string;
      // }
      if (event.target.files[0].size < 3000000) {
        this.idProofPath = this.selectedFile.type;
        var ext = this.idProofPath.split('/');
        if (ext[1] == "jpeg" || ext[1] == "png"|| ext[1] == "jpg") {
          reader.onload = () => {
            debugger
            this.idImageSrc = reader.result as string;
          }
          reader.readAsDataURL(this.selectedFile);
          if (folderPath === IDPROOF_PATH) {
            if (this.selectedFile != null) {
              this.idProofFileName = this.uploadService.fileUpload(this.selectedFile, folderPath).then(res => {
                if (res) {
                  this.idProofFileName = res;
                }
              });
            }
          }
        } else {
          this.ToasterService.openSnackBar('Unsupported file format', '', 'warning');
          this.signUpformGroup.get('identityProof').setValue('');
        // this.signUpformGroup.get('identityProof').setErrors({ required: true });
        this.idImageSrc = "";
        }
      }
      else {
        this.ToasterService.openSnackBar('Size limit exceeded', '', 'warning');
        this.signUpformGroup.get('identityProof').setValue('');
        // this.signUpformGroup.get('identityProof').setErrors({ required: true });
        this.idImageSrc = "";
      }
    }
  }
  
  addRefferalCode() {
    debugger
    const dialogRef = this.dialog.open(ReferalcodePopupComponent, {
      height: '400px',
      width: '600px',
      data: { title: 'Refferal Code', userId: this.user_Id }
    });
    dialogRef.afterClosed().subscribe(result => {
      debugger
      console.log(result);
      this.spinner.show();
      localStorage.setItem('user_Id', this.user_Id);
      localStorage.setItem('user_name', this.user_Name);
      localStorage.setItem('access_token', this.access_Token);
      localStorage.setItem('refresh_token', this.refresh_Token);
      localStorage.setItem('profilepic', this.profile_pic);
      this.WebComponent.islogin = true;
      // this.spinner.hide();
      if (result) {
        this.ToasterService.openSnackBar('Refferal Code added and Registered successfully', '', 'success');
        this.router.navigate(['']);
      } else {
        this.ToasterService.openSnackBar('Registered successfully', '', 'success');
        this.router.navigate(['']);
      }

      //  this.resourse.push(result);
    });

  }
  convert() {
    var date = new Date(),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear() - 10, mnth, day].join("-");
  }

  removeSelectedImage(imageField: string){
    debugger
    if(imageField == 'photo'){
    this.signUpformGroup.get('photo').setValue('');
    this.imageSrc = "";
    this.ImageFileName = "";
  }else if(imageField == 'identityProof'){
    this.signUpformGroup.get('identityProof').setValue('');
    this.idImageSrc = "";
    this. idProofFileName = "";
  }
  }


}
export interface DialogData {
  title: string;
  Week: { status: null, message: null, title: null };
  isActivityResourse: boolean;
  userId: null;

}

