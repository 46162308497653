<section class="bread_sec shadow-sm">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb mb-0 pl-0">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page"> <a [routerLink]="['/course']">Courses</a> </li>
          <li class="breadcrumb-item active" *ngIf="course" aria-current="page">{{course.course_name|slice:0:30}}</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="sub_banner team_banner text-left course_det_banner" >
  <div class="container">
    <div class="row">
      <div class="col-md-6 text-white pt-3 pb-3">
        <h1>{{course.course_name}}</h1>
        <div class="course_inst mb-4">{{instructor.sme_name}}</div>
        <div class="course_cata">{{course.course_category}}</div>
        <div class="price_detail">
          <ng-container *ngIf="course.subscription_type === 1">
            Free Course
          </ng-container>
          <ng-container *ngIf="course.subscription_type === 2">
            <ng-container *ngFor="let price of course.cost; let k = index">
              <ng-container *ngIf="price.currency_id === currencyId">
                {{price.amount | currency:price.currency_code}} /-
              </ng-container>
            </ng-container>
            <!-- {{course.learning_type_name}} -->
          </ng-container>
        </div>
        <div class="duration">{{course.duration}} Weeks</div>
      </div>


      <div class="col-md-6">
        <div class="col-sm-12 col-md-10 col-lg-10 mx-auto">
          <div class="card my-5 border-0 shadow-sm">
            <div class="card-body">
              <div class="mt-2">
                <!-- <div class="d-flex justify-content-center"> The deadline to enroll is January 29, 2021. No application
                  required. </div> -->
                <div class="text-left batch_" *ngIf="course.learning_type === 1 && batchs.length">
                  <div class="py-2 mt-2">
                    <h6>Select Batch</h6>
                  </div>
                  <form>
                    <ng-container *ngFor="let batch of batchs; let k = index">
                      <label class="rounded" *ngIf="k==0">
                        <input type="radio" name="band" (click)="batchchange(batch)" checked><span>
                          {{batch.start_date}}</span>
                      </label>
                      <label class="rounded" *ngIf="k>=1">
                        <input type="radio" name="band" (click)="batchchange(batch)"><span>
                          {{batch.start_date}} </span>
                      </label>
                    </ng-container>
                  </form>
                </div>
              </div>
              <hr class="my-3" *ngIf="course.learning_type === 1 && batchs.length != 0">
              <a (click)="goToCourse()" *ngIf="course.enroll_status==true"
                class="btn btn-dark btn-lg btn-block text-uppercase" type="submit">Go to Course</a>

              <a (click)="enrollNow()"
                *ngIf="(course.learning_type == 1 && !course.enroll_status && batchs.length != 0) || (course.learning_type === 2 && course.enroll_status == false)"
                class="btn btn-dark btn-lg btn-block text-uppercase" type="submit">{{enrollButton}}</a>
              <button *ngIf="course.learning_type === 1 && batchs.length == 0 && course.enroll_status !=true" class="btn btn-dark btn-lg btn-block text-uppercase" disabled> currently no Batches available. </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="course_image_bg" style="background-image:url({{s3FilePath}}{{course.image}});"></div>
</section>
<section class="courses_pages">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs tab-card-header" role="tablist">
          <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#courses_det_1">About Course</a>
          </li>
          <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#courses_det_2">Syllabus</a> </li>
          <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#courses_det_3">About Instructor</a> </li>
        </ul>
        <div class="tab-content pt-3 pb-3">
          <div id="courses_det_1" class="tab-pane active">
            <!-- <h2>Certificate Course in {{course.course_name}}</h2> -->
            <!-- <p>{{about_course.description}}</p> -->
            <div [innerHTML]="courseDescription" class="my-3"></div>
            <!-- <h3>Course Overview</h3>
            <p>{{about_course.course_overview}}</p>
            <h3>What you learn from the course ?</h3>
            <p>At the end of the course, students should:</p>
            <ul>
              {{about_course.learn_from_course}}
            </ul> -->
          </div>
          <div id="courses_det_2" class="tab-pane fade">
            <!-- <h3>Course Objectives</h3>-->
            <!-- <ul>
              {{syllabuses.course_objectives}}
            </ul> -->
            <div class="syllabus_sec py-5 px-3 my-3">
              <div class="col-md-3 col-sm-10 mx-auto text-center py-3 border_grad">
                <img src="../../../assets/web/assets/images/syllabus.png" class="img-fluid px-3"
                  alt="syllabus download">
                <a href="{{syllabuses}}" target="_blank" download class="btn btn-dark">Download</a>
              </div>
            </div>

          </div>
          <div id="courses_det_3" class="tab-pane fade">
            <div class="row instru_det align-items-center my-3">
              <div class="col-md-3">
                <div class="image-in rounded-circle"><img src="{{s3FilePath}}{{instructor.profile_picture}}"
                    onerror="this.src = '../../../assets/web/assets/images/in-1_.jpg'"
                    class="card-img-top rounded-circle" alt="instructors"></div>
              </div>
              <div class="col-md-9">
                <h5>{{instructor.sme_name}}</h5>
                <h6>{{instructor.sme_degree}}</h6>
                <!-- <p>{{instructor.sme_description}}</p> -->
                <p [innerHTML]="smeDescription"></p>
              </div>
              <div>
                <!-- <p>{{instructor.sme_description}}</p>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
