<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item"> <a class="nav-link" data-widget="pushmenu" role="button"><i class="fas fa-bars"></i></a>
    </li>
  </ul>
  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <!-- Notifications Dropdown Menu -->
    <!-- <li class="nav-item dropdown"> <a class="nav-link" data-toggle="dropdown"> <i class="far fa-bell pt-2"></i>
        <span class="badge badge-warning navbar-badge">4</span> </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right menu_n"> <span
          class="dropdown-item dropdown-header">4 Notifications</span>
        <div class="dropdown-divider"></div>
        <a href="Javascript:void(0);" class="dropdown-item" [routerLink]="['notifications']"> <i class="fas fa-envelope mr-2"></i> 4 new messages <span
            class="float-right text-muted text-sm">3 mins</span> </a>
        <div class="dropdown-divider"></div>
        <a href="Javascript:void(0);" class="dropdown-item dropdown-footer" [routerLink]="['notifications']">See All Notifications</a>
      </div>
    </li> -->
    <li class="nav-item dropdown"> <a class="nav-link" data-toggle="dropdown">
        <div class="user-panel d-flex">
          <div class="image"> <img src="{{profile_pic}}" class="img-circle" alt="User Image"> </div>
          <div class="info"> <span class="d-block">{{authService.username}} <i class="fas fa-angle-down ml-1 mt-2"></i>
            </span> </div>
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <div class="dropdown-menu-header-inner bg-info p-2">
          <div class="media"> <img src="{{profile_pic}}" alt="User Avatar" class="img-size-50 mr-3 img-circle">
            <div class="media-body">
              <h3 class="dropdown-item-title mt-3 text-truncate width_profile_"> {{authService.username}} </h3>
            </div>
          </div>
        </div>
        <!-- <span class="dropdown-item  user-footer float-left">
                    <div class="float-left"> <a class="btn btn-default btn-flat">Profile</a> </div>
                    <div class="float-right"> <a (click)="logout()" class="btn btn-default btn-flat">Sign out</a> </div>

                </span><br>
                <span>
                    <div>
                        <a [routerLink]="['/changePassword']"  class="btn btn-default btn-flat">Change Password</a>
                    </div>
                </span> -->
        <span class="dropdown-item  user-footer float-left p-0">
          <div class="float-left col-12 p-0"><a (click)="editProfile()"
              class="btn btn-block btn-default btn-flat text-left">Profile</a></div>
          <div class="float-left col-12 p-0"><a [routerLink]="['changePassword']"
              class="btn btn-block btn-default btn-flat text-left">Change Password</a></div>
          <div class="float-left col-12 p-0"><a (click)="logout()"
              class="btn btn-block btn-default btn-flat text-left">Log out</a></div>

        </span>
        <div class="dropdown-divider"></div>
      </div>
    </li>
  </ul>
</nav>
