<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/enroll-student/add']">Enroll Students</a></li>
              <li class="breadcrumb-item active">Import Enrollments</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
      <div class="card elevation-0">
        <div class="card-header">
          <h1>Import Enrollments</h1>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-primary p-3 pb-5 add_contry_">
                <form [formGroup]="studentImportForm" (ngSubmit)="onSubmit()">
                  <div class="col-md-12 float-left">
  
                    <div class="form-group">
                      <div class="input-group">

                        <a class="mr-2" href='assets/templates/StudentEnrollmentTemplate.xlsx' download>
                          <i class="fas fa-download"></i> Import Enrollment Template
                        </a>

                        <a class="mr-2" href="Javascript:void(0);" (click)="excelldownload('course')">
                          <i class="fas fa-download"></i> Course List
                        </a>

                        <a class="mr-2" href="Javascript:void(0);" (click)="excelldownload('student')">
                          <i class="fas fa-download"></i> Student List
                        </a>

                      </div>

                   
                    </div>
                    <div class="form-group mb-0">
                      <label>File</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="">
                            <i class="fas fa-file-excel"></i>
                          </span>
                        </div>
                        <div class="custom-file col-md-4">
                          <input type="file" class="custom-file-input" formControlName="fileData"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            id="exampleInputFile" (change)="onChange($event)" #inputFile
                            [ngClass]="{ 'is-invalid': submitted && f.fileData.errors }">
                          <label class="custom-file-label" for="exampleInputFile"
                            accept="">{{selectedFile==''?'Choose file':selectedFile}}</label>
  
                        </div>
                        <div class="clearfix"></div> <br>
                        <div *ngIf="submitted && f.fileData.errors" class="text-danger w-100">
                          <small *ngIf="f.fileData.errors.required">
                            File is
                            required
                          </small>
                        </div>
                        <small *ngIf="isExcelFile === false" class="text-danger w-100">
                          This is not an Excel file
                        </small>
                        <small *ngIf="isBlankExcelFile === true" class="text-danger w-100">
                          There is no data to import from the Excel file
                        </small>
                        <small *ngIf="isInvalidExcelFile === true" class="text-danger w-100">
                          Invalid data in the Excel file
                        </small>
                        <span *ngIf="spinnerEnabled" class="k-i-loading k-icon"></span>
  
                        <div style="width: 100%;overflow-x:auto; ">
                          <table>
                            <th *ngFor="let key of keys">
                              {{key}}
                            </th>
                            <tr *ngFor="let item of dataSheet | async">
                              <td *ngFor="let key of keys">
                                {{item[key]}}
                              </td>
                            </tr>
                          </table>
                        </div>
  
                      </div>
                    </div>
                  </div>
  
  
                  <div class="clearfix"></div>
                  <div class="p-3">
                    <button type="submit" class="btn btn-secondary btn-dark bor-rad_ float-right">
                      Submit
                    </button>
                  </div>
  
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </section>
    <!-- /.content -->
  </div>


 
  