import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from 'src/app/services/constants.service';
import { PopupService } from '../../../services/popup.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-course-price-detail',
  templateUrl: './course-price-detail.component.html',
  styleUrls: ['./course-price-detail.component.css']
})
export class CoursePriceDetailComponent implements OnInit {

  course_slug: any = "";
  userId: any;
  courseDetials: any;
  course_id: any;
  batch_id: any;
  course_curency_price_id: any;
  session_id: any;
  courseDetail: any;
  dateNow: Date = new Date();
  s3FilePath: string;
  instaMojoTrigger: boolean = false;
  loadingUrl: any;
  @ViewChild('myDiv1') myDiv1: any;
  @ViewChild('merchantId') merchantId: any;
  @ViewChild('merchantTxnId') merchantTxnId: any;
  @ViewChild('furl') furl: any;
  @ViewChild('surl') surl: any;
  @ViewChild('checksum') checksum: any;
  @ViewChild('channel') channel: any;
  @ViewChild('amount') amount: any;
  paymentForm: FormGroup;
  constructor(
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private LocaldataService: LocaldataService,
    private router: Router,
    private rote: ActivatedRoute,
    private ToasterService: ToasterService,
    private constantService: ConstantsService,
    private popup: PopupService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.s3FilePath = this.constantService.s3FilePath;
    this.session_id = localStorage.getItem('access_token');
    this.course_id = this.rote.snapshot.paramMap.get("course_id");
    this.batch_id = this.rote.snapshot.paramMap.get("batch_id");
    this.course_curency_price_id = this.rote.snapshot.paramMap.get("course_curency_price_id");
    this.userId = localStorage["user_Id"];
    if (!this.userId) {
      this.router.navigate(['/login'])
    }
    debugger
    // this.paymentForm = this.formBuilder.group({
    //   paymentMethod: ['1', Validators.required]
    // });
    this.getCourseDetails();
    this.LocaldataService.updateUser();
  }

  getCourseDetails() {
    this.spinner.show();
    var list_fetch_url = "getUserCourseFee?course_id=" + this.course_id + "&batch_id=" + this.batch_id + "&course_curency_price_id=" + this.course_curency_price_id;
    this.api.detailsGetFetch(list_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true" && data.payment_details) {
        debugger
        this.courseDetail = data.payment_details
        debugger
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        this.popup.failureMessage = data.message
        this.popup.failurepopup();
        this.router.navigate(['/course'])
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        //this.router.navigate([''])
      }
    });

  }

  proceedpaymentInstamojo() {
    var list_fetch_url = "createRequest";
    let param = { amount: this.courseDetail.net_price, course_id: this.course_id, course_curency_price_id: this.course_curency_price_id, batch_id: this.batch_id };
    this.api.detailsUploadFetch(param, list_fetch_url, this.session_id).subscribe(async (data: any) => {
      if (data.status == "true") {
        debugger
        //  this.loadingUrl = data.longurl;
        //  this.instaMojoTrigger = true
        const link = document.createElement("a");
        link.href = data.message.payment_request.longurl;
        link.setAttribute("visibility", "hidden");
        link.click()
        
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate([''])
      }
    });

  }


  proceedpaymentFreeCharge() {
    debugger
    var list_fetch_url = "create_freecharge_request";
    let param = { amount: this.courseDetail.net_price+"", course_id: this.course_id, course_curency_price_id: this.course_curency_price_id, batch_id: this.batch_id, channel: "WEB" };
    this.api.detailsUploadFetch(param, list_fetch_url, this.session_id).subscribe(async (data: any) => {
      if (data.status == "true") {
        debugger
        this.amount.nativeElement.value = data.result.amount;
        this.channel.nativeElement.value = data.result.channel;
        this.checksum.nativeElement.value = data.result.checksum;
        this.furl.nativeElement.value = data.result.furl;
        this.merchantId.nativeElement.value = data.result.merchantId;
        this.merchantTxnId.nativeElement.value = data.result.merchantTxnId;
        this.surl.nativeElement.value = data.result.surl;
        this.myDiv1.nativeElement.submit(); 

      }
      else {
        this.spinner.hide();
        this.saveError("error from the api, message: "  + data.message);
        
      }
    }, (err: HttpErrorResponse) => {
      this.saveError("Message: "+ err.message +
      ", Name: "+err.name+
      ", status: "+err.status+
      ", statusText: " +err.statusText+
      ", url: "+ err.url);
      if (err.status == 403) {
        this.spinner.hide();
        localStorage.clear()
        this.router.navigate([''])
      }
    });
    

  }
  proceedpayment(){
    debugger
    if(this.paymentForm.value.paymentMethod == "1"){
      this.proceedpaymentInstamojo();
    }else{
      this.proceedpaymentFreeCharge();
    }
  }

  saveError(error){
    debugger
    let url = "save_error" ;
      var body = { "description": error };
        this.api.detailsAddFetch(body, url, this.session_id).subscribe((data: any) => {
        debugger
        console.log(data);
          if(data.status == "true"){
          debugger
          console.log(data.message);
          
          
        }else{
          console.log(data.message)
          this.spinner.hide();
        }
          
      }, (err: HttpErrorResponse) => {
        debugger
        if (err.status == 403) {
         
        }
      });
  }
  

}
