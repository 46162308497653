<section class="sub_banner team_banner text-left course_det_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-6 text-white pt-3 pb-3">
        <h1>{{course.course_name}}</h1>
        <div class="course_inst mb-4">{{instructor.sme_name}}</div>
        <div class="course_cata">{{course.course_category}}</div>
        <!-- <div class="price_detail">
            <ng-container *ngIf="course.subscription_type === 1">
              Free Course
            </ng-container>
            <ng-container *ngIf="course.subscription_type === 2">
              <ng-container *ngFor="let price of course.cost; let k = index">
                <ng-container *ngIf="price.currency_id === currencyId">
                  {{price.amount | currency:price.currency_code}} /-
                </ng-container>
              </ng-container>
            </ng-container>
          </div> -->
        <div class="duration">{{course.duration}} Weeks</div>
      </div>


      <div class="col-md-6">
        <div class="col-sm-12 col-md-10 col-lg-10 mx-auto">

        </div>
      </div>
    </div>
  </div>
  <div class="course_image_bg" style="background-image:url({{s3FilePath}}{{course.image}});"></div>
</section>
<section class="courses_pages">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs tab-card-header" role="tablist">
          <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#courses_det_1">About Course</a>
          </li>
          <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#courses_det_2">Syllabus</a> </li>
          <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#courses_det_3">About Instructor</a> </li>
        </ul>
        <div class="tab-content pt-3 pb-3">
          <div id="courses_det_1" class="tab-pane active">
            <!-- <h2>Certificate Course in {{course.course_name}}</h2> -->
            <!-- <p>{{about_course.description}}</p> -->
            <div [innerHTML]="courseDescription" class="my-3"></div>
            <!-- <h3>Course Overview</h3>
              <p>{{about_course.course_overview}}</p>
              <h3>What you learn from the course ?</h3>
              <p>At the end of the course, students should:</p>
              <ul>
                {{about_course.learn_from_course}}
              </ul> -->
          </div>
          <div id="courses_det_2" class="tab-pane fade">
            <!-- <h3>Course Objectives</h3>-->
            <!-- <ul>
                {{syllabuses.course_objectives}}
              </ul> -->
            <div class="syllabus_sec py-5 px-3 my-3">
              <div class="col-md-3 col-sm-10 mx-auto text-center py-3 border_grad">
                <img src="../../../assets/web/assets/images/syllabus.png" class="img-fluid px-3"
                  alt="syllabus download">
                <a href="{{syllabuses}}" target="_blank" download class="btn btn-dark">Download</a>
              </div>
            </div>

          </div>
          <div id="courses_det_3" class="tab-pane fade">
            <div class="row instru_det align-items-center my-3">
              <div class="col-md-3">
                <div class="image-in rounded-circle"><img src="{{instructor.profile_picture}}"
                    onerror="this.src = '../../../assets/web/assets/images/in-1_.jpg'"
                    class="card-img-top rounded-circle" alt="instructors"></div>
              </div>
              <div class="col-md-9">
                <h5>{{instructor.sme_name}}</h5>
                <h6>{{instructor.sme_degree}}</h6>
                <p>{{instructor.sme_description}}</p>
              </div>
              <div>
                <!-- <p>{{instructor.sme_description}}</p>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
