<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-sm-left">
                        <li class="breadcrumb-item"><a [routerLink]="['/admin']"><i class="fas fa-home mr-2"></i>Home</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/admin/qualification']">Qualifications</a></li>
                        <li *ngIf="!isEdit" class="breadcrumb-item active">Add Qualification</li>
                        <li *ngIf="isEdit" class="breadcrumb-item active">Edit Qualification</li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>
    <!-- Main content -->
    <section class="content">
        <div class="card elevation-0">
            <div class="card-header">
                <h1 *ngIf="!isEdit">Add Qualification</h1>
                <h1 *ngIf="isEdit">Edit Qualification</h1>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-primary p-3 pb-5 add_contry_">
                            <form [formGroup]="qualificationForm" (ngSubmit)="onSubmit()">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Qualification *</label>
                                        <input type="text" formControlName="qualification" class="form-control selact_"
                                            placeholder="Qualification"
                                            [ngClass]="{ 'is-invalid': submitted && f.qualification.errors }">
                                        <div *ngIf="submitted && f.qualification.errors" class="invalid-feedback">
                                            <div class="error_signup" *ngIf="f.qualification.errors.required">Qualification is
                                                required
                                            </div>
                                            <div *ngIf="f.qualification.errors.validspace"> Invalid Qualification
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Status *</label>
                                        <select class="form-control select2 selact_" formControlName="status"
                                            style="width: 100%;"
                                            [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                            <option value="1">Active</option>
                                            <option value="2">Disabled</option>
                                        </select>
                                        <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                            <div *ngIf="f.status.errors.required">Status is
                                                required
                                            </div>
                                        </div>
                                    </div>

                                    <!--<div class="form-group">
                                        <label>Description</label>
                                        <textarea class="form-control selact_" formControlName="description"
                                            placeholder="Description"
                                            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                            <div *ngIf="f.description.errors.required">Description is
                                                required
                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                                <div class="clearfix"></div>
                                <div class="p-3">
                                    <button type="submit"
                                        class="btn btn-secondary btn-dark bor-rad_ float-right">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </section>
    <!-- /.content -->
</div>
