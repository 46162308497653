import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, Route } from '@angular/router';
    import Swal from 'sweetalert2';
import { PopupService } from '../../services/popup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Page } from '../../models/pageDto';

@Component({
  selector: 'app-sme',
  templateUrl: './sme.component.html',
  styleUrls: ['./sme.component.css']
})
export class SmeComponent implements OnInit {
  @ViewChild('myTable') table: SmeComponent;
  session_id: any;
  searchTerm: any = '';
  selected=[];
  temp=[];
  smeList:any=[];
  page: Page;
  deleteList:any=[];
  constructor(public api: ApiService, private formBuilder: FormBuilder, private router: Router,private popup: PopupService,private spinner: NgxSpinnerService) { }
  smeForm: FormGroup;
  ngOnInit(): void {
    this.session_id = localStorage.getItem('JWT_TOKEN')
    this.smeForm = this.formBuilder.group({
      search: ['']
    });
    this.page = {
      current_page: 0,
      count: 0,
      total: 0,
      total_pages: 0,
      per_page:10
    };
    this.setPage({ offset: 0 });
    //this.init();
  }

  onSearch() {
    this.searchTerm = this.smeForm.get('search').value.toString();
    this.setPage({ offset: 0 });
  }

  setPagetest(pageInfo) {
    pageInfo.offset = pageInfo.offset + 1;
    this.setPage(pageInfo);
  }

  setPage(pageInfo) {
    this.spinner.show();
    this.page.current_page = pageInfo.offset;
    this.smeList = [];
    var sme_fetch_url = "sme_list?page=" + this.page.current_page + "&per_page_count=" + this.page.per_page;
    if (this.searchTerm.length > 0)
      sme_fetch_url += "&searchTerm=" + this.searchTerm;
    this.api.detailsGetFetch(sme_fetch_url, this.session_id).subscribe((data: any) => {
      if (data.status == "true") {
        this.temp = data.sme;
        this.smeList = data.sme;
        data.pagination.current_page = data.pagination.current_page - 1;
        this.page = data.pagination;
        this.spinner.hide();

      }
      else {
        this.spinner.hide();

      }

    }, (err: HttpErrorResponse) => {
      if (err.status == 403) {
        this.spinner.hide();

        localStorage.clear()
        this.router.navigate(['admin/login'])
      }
    });
  }
  onSelect({ selected }) {
 
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
 
  deleteSme(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        var body = { "sme_id": +id };
        var del_url = "delete_sme?sme_id=" + `${id}`;
        this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
          console.log(data)
          if (data.status == "TRUE") {
            this.spinner.hide();

            this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
            this.setPage({ offset: 0 });
          }
          else {
            this.popup.failureMessage = data.message
            this.popup.failurepopup();
            this.spinner.hide();

          }
        },
          (err: HttpErrorResponse) => {
            if (err.status == 403) {
              this.spinner.hide();

              localStorage.clear()
              this.router.navigate(['admin/login'])
            }
          });


      }
    })
  }

  deleteMultipleItem(){
    debugger
    console.log(this.selected);
    if(this.selected.length>0){
      this.selected.forEach(element => {
        this.deleteList.push(element.user_id);
      });
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          var body = { "ids": this.deleteList };
          var del_url = "sme_multi_delete";
          this.api.detailsFetchDel(body, del_url, this.session_id).subscribe((data: any) => {
            debugger
            this.deleteList = [];
            if (data.status == "TRUE") {
              this.spinner.hide();
              this.popup.sucessMessage = data.message
            this.popup.sucesspopup()
              this.setPage({ offset: 0 });
            }
            else {
              this.spinner.hide();
              this.popup.failureMessage = data.message
              this.popup.failurepopup()
            }
          },
            (err: HttpErrorResponse) => {
              if (err.status == 403) {
                this.spinner.hide();
  
                localStorage.clear()
                this.router.navigate(['admin/login'])
              }
            }); 
        }else{
          this.deleteList = [];
        }
      })
    }else{
      this.popup.failureMessage = "Select atleast one Enrollment";
      this.popup.failurepopup()
    } 
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      const title_name = d.title.toLowerCase().indexOf(val) !== -1 || !val;
      const status_name = d.status.toLowerCase().indexOf(val) !== -1 || !val;
       return title_name || status_name;
    });

    this.smeList = temp;
   }
   editSme(id){
     this.router.navigate(['admin/smeEdit',{id:`${id}`}]);

  }
}
