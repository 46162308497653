


 <!-- <div class="pdfviewer_report" id="reportArea"></div>  -->

<!-- <div #dataContainer></div> -->

<!-- <div [innerHTML]="announcementContent | safeUrl| safeHtml">
</div> -->

<!-- <iframe width="1000" height="515" src="https://www.google.co.in/" frameborder="0" allowfullscreen></iframe> -->

<!-- <iframe width="420" height="315" src="https://www.youtube.com/watch?v=b4jWrZ13UB0" frameborder="0" allowfullscreen></iframe> -->

<iframe  src="https://stackoverflow.com/questions/6666423/overcoming-display-forbidden-by-x-frame-options | safeUrl" scrolling="no" frameBorder="0"
style="height: auto;min-height: 700px; width:90%; overflow:hidden;">
</iframe>